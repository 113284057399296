import {
  GroupedMaterialKnowledgeDTO,
  UpdateMaterialKnowledgeDTO,
  MaterialKnowledgeDTO,
  CreateMaterialKnowledgeDTO,
  UpdateSortMaterialKnowledgeElementsDTO
} from 'dto/materialKnowledge';
import { MaterialLikeIdOrPackageIdDTO } from 'dto/material';
import { get, patch, del, post, put } from './common';

import { validate, validateBool, validateArray } from './validation';

/**
 * getKnowledge is special because it accepts the packageId not the templateId. This is because of
 * needed support to load the synonyms of the package as a chapter.
 * @param id an object which contains either the materialId or the packageId
 */
export const getKnowledge = async (id: MaterialLikeIdOrPackageIdDTO): Promise<GroupedMaterialKnowledgeDTO[]> => {
  const knowledgeGroups = await get('/knowledge-elements', {
    materialId: id.materialId,
    packageId: id.packageId,
    templateId: id.templateId,
    materialSetId: id.materialSetId
  }).then(response => response.data);
  return validateArray(GroupedMaterialKnowledgeDTO, knowledgeGroups);
};

export const updateKnowledgeElement = async (knowledgeUpdateFields: UpdateMaterialKnowledgeDTO): Promise<MaterialKnowledgeDTO> => {
  const updatedKnowledgeElement = await patch(`/knowledge-element`, knowledgeUpdateFields).then(response => response.data);
  return validate(MaterialKnowledgeDTO, updatedKnowledgeElement);
};

export const deleteKnowledgeElement = async (materialKnowledgeElementId: string) => {
  return del('/knowledge-element', { materialKnowledgeElementId }).then(response => validateBool(response.data));
};

export const createKnowledgeElement = async (newKnowledge: CreateMaterialKnowledgeDTO) => {
  const newKnowledgeElement = await post('/knowledge-element', newKnowledge).then(response => response.data);
  return validate(MaterialKnowledgeDTO, newKnowledgeElement);
};

export const reorderKnowledgeElements = async (reorderedKnowledgeElements: UpdateSortMaterialKnowledgeElementsDTO[]) => {
  return put('/knowledge-elements/positions', reorderedKnowledgeElements).then(response => validateBool(response.data));
};
