import React, { createRef, useState } from 'react';
import { DropzoneRef } from 'react-dropzone';

import { FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';

import { FileUploadFunc } from 'api/common';
import DropzoneContainer, { OnDropCallback } from 'components/DropzoneContainer';
import GetCameraImageButton from 'components/GetCameraImageButton';
import ThumbnailList from 'components/ThumbnailList';
import { ContentType, ProcessingStatus } from 'dto/file';
import { getFileExtension, getFileName, getMediaType } from 'util/fileUtils';
import HelperImageDragAndDrop from '../HelperImageDragAndDrop';
import { MixedContentElementMediaDTO } from './MixedContentElementMediaDTO';

interface Props {
  meta: FormikProps<FormikValues>;
  name: string;
  files: MixedContentElementMediaDTO[];
  saveFile: FileUploadFunc;
  reorderContentElementMedia: (reorderContentElementMedia: MixedContentElementMediaDTO[]) => void;
  accept?: ContentType[];
}

export default ({ name, meta, files, saveFile, reorderContentElementMedia, accept }: Props) => {
  const { t } = useTranslation('itemFormFlyOut');
  const [uploadedFiles, setUploadedFiles] = useState<MixedContentElementMediaDTO[]>(files);
  const gotImage = (fakeId: string, file: MixedContentElementMediaDTO) => {
    meta.setFieldValue(name, [...meta.values[name], file]);
    meta.setTouched({
      ...meta.touched,
      [name]: true
    });
    setUploadedFiles(current => [
      ...current.map(oldFile => {
        if (oldFile.fileId === fakeId) {
          return file;
        }
        return oldFile;
      })
    ]);
  };

  const onChange = (updatedFiles: MixedContentElementMediaDTO[]) => {
    meta.setFieldValue(name, [...updatedFiles]);
    meta.setTouched({
      ...meta.touched,
      [name]: true
    });
    setUploadedFiles([...updatedFiles]);
  };

  const onSaveFile = async (file: File) => {
    if (file === undefined) {
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    // add random number to fix multiple file uploads with the same lastModified
    const progressFileId = (file.lastModified + Math.random() * 100).toString();

    const response = await saveFile(bodyFormData, e => {
      setUploadedFiles(current => {
        const extension = getFileExtension(file.name);
        const fileInProgress = current.find(f => f.fileId === progressFileId);
        if (fileInProgress) {
          const updatedFiles = current.map(f => {
            if (f.fileId === progressFileId) {
              return {
                ...f,
                progress: Math.round((e.loaded / e.total) * 100)
              };
            }
            return f;
          });
          meta.setFieldValue(name, [...updatedFiles]);
          return [...updatedFiles];
        }
        const newFile: MixedContentElementMediaDTO = {
          ...file,
          contentType: getMediaType(extension),
          progress: Math.round((e.loaded / e.total) * 100),
          fileId: progressFileId,
          status: ProcessingStatus.Uploading,
          fullUrl: '',
          originalFileName: ''
        };
        meta.setFieldValue(name, [...uploadedFiles, newFile]);
        return [...uploadedFiles, newFile];
      });
    });

    const title = getFileName(file.name, file.type);
    if (response) {
      gotImage(progressFileId, {
        ...response,
        title
      });
    }
  };

  const onDrop: OnDropCallback = async acceptedFiles => {
    const file = acceptedFiles[0];
    await onSaveFile(file);
  };

  const dropzoneRef = createRef<DropzoneRef>();

  const onDragEnd = (reorderedFiles: MixedContentElementMediaDTO[]) => {
    meta.setTouched({
      ...meta.touched,
      [name]: true
    });
    if (reorderContentElementMedia) {
      reorderContentElementMedia([...reorderedFiles]);
      setUploadedFiles([...reorderedFiles]);
    }
  };

  return (
    <>
      <div>{t('label.media')}</div>
      <DropzoneContainer ref={dropzoneRef} onDrop={onDrop} className="div-block-41" accept={accept}>
        <div className="div-block-54">
          <GetCameraImageButton
            onSaveFile={onSaveFile}
            buttonStyle="circle"
            isButtonVisible
            description={t('uploadFiles.imageButton')}
            isAddable
          />
          <HelperImageDragAndDrop title={t('uploadFiles.title')} description={t('uploadFiles.description')} />
        </div>
      </DropzoneContainer>
      <ThumbnailList saveFile={saveFile} onReorder={onDragEnd} onChange={onChange} files={uploadedFiles} />
    </>
  );
};
