import React from 'react';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import Popover from 'components/Popover';
import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { SettingsItemTypes } from 'stores/settingsStore';
import { SurgeryRoomDTO } from 'dto/surgeryRoom';
import { observer } from 'mobx-react';
import './SurgeryRoomListItem.css';

interface Props {
  surgeryRoom: SurgeryRoomDTO;
  hover: boolean;
  setShowSurgeryRoomFunctionalAreas: React.Dispatch<React.SetStateAction<boolean>>;
  isSelected: boolean;
}

const SurgeryRoomsListItem = observer(({ surgeryRoom, hover, setShowSurgeryRoomFunctionalAreas, isSelected }: Props) => {
  const { t } = useTranslation('settings');
  const { settingsStore } = useStores();

  const onRename = (name: string, surgeryRoomId: string) => {
    settingsStore.setSelectedItem({
      name,
      itemId: surgeryRoomId,
      type: SettingsItemTypes.surgeryRoom
    });
    settingsStore.setItemFormFlyoutTitles(t('formFlyout.surgeryRoomUpdateTitle'), t('formFlyout.surgeryRoomCreateTitle'));
    settingsStore.setItemFormFlyoutOpen(true);
  };

  const onLoadSurgeryRoomFunctionalAreas = () => {
    settingsStore.setSelectedSurgeryRoom(surgeryRoom);
    if (settingsStore.selectedSurgeryRoom) {
      settingsStore.loadSurgeryRoomFunctionalAreas();
      setShowSurgeryRoomFunctionalAreas(true);
    }
  };

  return (
    <div
      className={`list_item_name lagerort surgery-room-list-item ${isSelected ? 'is-selected' : ''} `}
      onClick={onLoadSurgeryRoomFunctionalAreas}
    >
      <div className="image_wrapper_50">
        <div className={`image_border image-border ${isSelected ? 'is-selected' : ''} ${hover ? 'is-hover' : ''}`} />
        <img src="images/picture-room.jpg" width="50" alt="" className="image_circle_50" />
      </div>
      <div className="item_name">{surgeryRoom.name}</div>
      <div className="btn_show_detail btn-detail-position">
        <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
          {({ handleClose, isOpen }) => (
            <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
              <ItemMenuButton
                label={t('item.menu.rename')}
                icon="icon_background_16.svg"
                isInPopover
                handleClick={() => onRename(surgeryRoom.name, surgeryRoom.surgeryRoomId)}
              />
            </ItemMenu>
          )}
        </Popover>
      </div>
    </div>
  );
});

export default SurgeryRoomsListItem;
