import React from 'react';
import MaterialKnowledge from 'components/MaterialKnowledge';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import { BasicMaterialSetDTO, SetType } from 'dto/materialSet';
import { useTranslation } from 'react-i18next';
import DrawerStorageLocationContent from '../../DrawerStorageLocationContent';

interface Props {
  materialSet?: BasicMaterialSetDTO;
}

const Content = observer(({ materialSet }: Props) => {
  const { guideDetailDrawerStore } = useStores();
  const { t } = useTranslation('storageLocationItems');

  return materialSet ? (
    <div className="material_content">
      <div className="material-name">{materialSet.name}</div>
      {materialSet.type !== SetType.Medicals ? (
        <DrawerStorageLocationContent showFirstAsFake erpLocationPathHeadline={t('locationPathTitleERP')} />
      ) : (
        <></>
      )}
      <MaterialKnowledge
        isCollapsible
        materialLikeId={{ materialSetId: materialSet.materialSetId }}
        onCreateSynonym={() => guideDetailDrawerStore.openCreateMaterialSynonymFlyout()}
      />
    </div>
  ) : (
    <></>
  );
});

export default Content;
