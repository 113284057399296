import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import { SurgeryProcedureDTO } from 'dto/surgeryGuideProcedure';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';

interface Props {
  surgeryProcedure: SurgeryProcedureDTO;
  isOpen: boolean;
  handleClose: () => void;
  handleSwitchChecklistGuideProcedure: () => void;
}

const SurgeryProcedureMenu = ({ surgeryProcedure, isOpen, handleClose, handleSwitchChecklistGuideProcedure }: Props) => {
  const { surgeryGuideStore, procedureStore, animationStore } = useStores();
  const { t } = useTranslation('briefing');
  const handleOpenUpdateProcedureFlyout = (event: React.MouseEvent) => {
    event.preventDefault();
    surgeryGuideStore.setSelectedSurgeryProcedure(surgeryProcedure);
    procedureStore.setIsProcedureFormFlyoutOpen(true);
    animationStore.resetElementIdToShake();
  };

  const onSwitchChecklistGuideProcedure = () => {
    handleSwitchChecklistGuideProcedure();
    handleClose();
  };

  return (
    <ItemMenu isOpen={isOpen} handleClose={handleClose}>
      {surgeryProcedure.content.isCheckable ? (
        <ItemMenuButton
          label={t('briefingProcedures.procedureItem.menu.checkListFunc')}
          strongLabel={t('briefingProcedures.procedureItem.menu.checked')}
          icon="icon_c-check_16.svg"
          isInPopover
          handleClick={onSwitchChecklistGuideProcedure}
        />
      ) : (
        <ItemMenuButton
          label={t('briefingProcedures.procedureItem.menu.checkListFunc')}
          strongLabel={t('briefingProcedures.procedureItem.menu.unchecked')}
          icon="icon_c-check_16_blue.svg"
          isInPopover
          handleClick={onSwitchChecklistGuideProcedure}
        />
      )}
      <ItemMenuButton
        label={t('briefingProcedures.procedureItem.menu.edit')}
        icon="icon_i-edit_16.svg"
        isInPopover
        handleClick={handleOpenUpdateProcedureFlyout}
      />
    </ItemMenu>
  );
};

export default SurgeryProcedureMenu;
