import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Form/Button';
import './LoginForgotUsername.css';

interface Props {
  show: boolean;
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
  setShowForgotUsername: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginForgotUsername = ({ show = false, setShowLogin, setShowForgotUsername }: Props) => {
  const { t: translationLogin } = useTranslation('login');

  const backToLogin = () => {
    setShowForgotUsername(false);
    setShowLogin(true);
  };

  return (
    <div className={`div-block-170 ${!show ? 'hide' : ''}`}>
      <div className="div-block-178">
        <div className="div-block-171">
          <img src="images/logo_klinik-puls.svg" alt="" />
          <div className="txt_logo-copy">
            <strong>{translationLogin('logoTextFirstPart')}</strong>
            {translationLogin('logoTextSecondPart')}
          </div>
        </div>
        <div className="div-block-181">
          <div className="pw_form">
            <div className="headline-login">{translationLogin('forgotUsername.headline')}</div>
            <div className="h3">{translationLogin('forgotUsername.instructions')}</div>
            <ul className="list-2">
              <li className="list-item">{translationLogin('forgotUsername.instruction1')}</li>
              <li className="list-item">{translationLogin('forgotUsername.instruction2')}</li>
              <li className="list-item">{translationLogin('forgotUsername.instruction3')}</li>
            </ul>
          </div>
          <div className="button_bar-copy">
            <Button className="btn_big_cancel" onClick={backToLogin}>
              {translationLogin('forgotUsername.backToLogin')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForgotUsername;
