import React from 'react';
import { useTranslation } from 'react-i18next';

import { subject } from '@casl/ability';
import { useInvalidateAnyGuide } from 'api/invalidate';
import { Can, actions, subjectArea } from 'casl/setupCaslAbility';
import SideMenuIconButton from 'components/SideMenuIconButton';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';

export interface Props {
  onAddMaterial: () => void;
}

const GuideMaterialRightMenu: React.FC<Props> = observer(({ onAddMaterial }) => {
  const { guideStore, domainStore, postStore, flyoutStore } = useStores();
  const { t } = useTranslation('guideMaterials');
  const invalidateGuide = useInvalidateAnyGuide();

  const handleGetMaterialClick = async () => {
    const currentDepartmentId = domainStore.currentDepartment.id;
    if (guideStore.selectedGuide && currentDepartmentId && domainStore.departmentsByCurrentFunctionArea) {
      const departmentsIds = domainStore.departmentsByCurrentFunctionArea.departments.map(department => department.departmentId);
      guideStore.setGuideMaterialsToClone(guideStore.selectedGuide.guideId);
      guideStore.getGuidesByDepartmentsClone(departmentsIds);
      guideStore.setShowCloneGuideMaterialFlyout(true, invalidateGuide);
    }
  };

  const menuButtonClickHandlerWrapper = (event: React.MouseEvent, handler: (event: React.MouseEvent) => void) => {
    handler(event);
    event.preventDefault();
  };

  const onShowHistoryFlyout = async () => {
    if (guideStore.selectedGuide) {
      await guideStore.getHistory(guideStore.selectedGuide.guideId);
    }
    flyoutStore.setIsHistoryFlyoutOpen(true);
  };

  const onCommentsFlyoutOpen = async () => {
    if (guideStore.selectedGuide?.guideId) {
      await postStore.loadGuidePosts(guideStore.selectedGuide?.guideId);
      postStore.setIsChatFlyoutOpen(true);
    }
  };

  return (
    <>
      <Can I={actions.add} this={subject(subjectArea.guideMaterial, { departmentId: guideStore.selectedGuide?.departmentId })}>
        {!domainStore.isMobile && (
          <SideMenuIconButton handleClick={onAddMaterial} label={t('button.addMaterial')} iconPath="icon_f-add_16.svg" />
        )}
        <SideMenuIconButton
          handleClick={handleGetMaterialClick}
          label={`${t('button.getMaterial')}`}
          iconPath="icon_archive-e-download_16.svg"
        />
      </Can>
      <SideMenuIconButton handleClick={onCommentsFlyoutOpen} label={t('button.allComments')} iconPath="icon_icon_f-comment_16_blue.svg" />
      <Can I={actions.update} this={subject(subjectArea.guideMaterial, { departmentId: guideStore.selectedGuide?.departmentId })}>
        <SideMenuIconButton
          handleClick={event =>
            menuButtonClickHandlerWrapper(event, () => {
              if (guideStore.selectedGuide) {
                // guideStore.setSelectedGuide({ guideId: guideStore.selectedGuide.guideId, name: guideStore.selectedGuide.name });
                guideStore.setShowGuideFlyout(true);
              }
            })
          }
          label={t('button.editGuide')}
          iconPath="icon_background_16.svg"
        />
      </Can>
      <SideMenuIconButton handleClick={onShowHistoryFlyout} label={t('button.showMaterialHistory')} iconPath="icon_time-clock_16.svg" />
      {!domainStore.isMobile && (
        <SideMenuIconButton handleClick={() => guideStore.exportGuide()} label={t('button.export')} iconPath="icon_share_16.svg" />
      )}
      <Can I={actions.remove} this={subject(subjectArea.guide, { departmentId: guideStore.selectedGuide?.departmentId })}>
        <SideMenuIconButton
          handleClick={() => {
            guideStore.showDeleteGuideFlyout(guideStore.selectedGuide?.guideId);
          }}
          red
          label={t('button.deleteGuide')}
          iconPath="icon_bin_16.svg"
        />
      </Can>
    </>
  );
});

export default GuideMaterialRightMenu;
