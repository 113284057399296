import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomInput from 'components/CustomInput';
import { useStores } from 'util/mobx/stores';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Button from 'components/Form/Button';
import NavSectionButton from 'components/NavSection/NavSectionButton';
import { StorageLocationDTO } from 'dto/storageLocation';
import Picture from 'components/Picture';
import SideMenuIconButton from 'components/SideMenuIconButton';
import StorageLocationItems from '../StorageLocationItems';
import './StorageLocationDetails.css';

interface Props {
  inDrawer?: boolean;
  isMaterialLikeView?: boolean;
  selectedStorageLocation: StorageLocationDTO;
}

const StorageLocationDetails = ({ inDrawer = false, isMaterialLikeView = false, selectedStorageLocation }: Props) => {
  const { t } = useTranslation('storageLocations');
  const { storageLocationStore, flyoutStore } = useStores();
  const [locationName, setLocationName] = useState(selectedStorageLocation?.name || '');
  useEffect(() => {
    setLocationName(selectedStorageLocation.name);
  }, [selectedStorageLocation]);

  // close MoveStorageLocationFlyout on unmount
  useEffect(() => {
    return () => {
      flyoutStore.setIsMoveStorageLocationFlyoutOpen(false);
    };
  });

  const onInputBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedStorageLocation) return;
    const storageLocation = {
      name: event.target.value,
      storageLocationId: selectedStorageLocation.storageLocationId
    };
    storageLocationStore.updateStorageLocation(
      storageLocation,
      storageLocationStore.selectedStorageLocationIndex,
      selectedStorageLocation.parentStorageLocationId
    );
  };
  const handleAddImagesToView = () => {
    if (storageLocationStore.selectedStorageLocation?.picture) {
      flyoutStore.setImagesToView(storageLocationStore.selectedStorageLocation.picture);
    }
  };

  const onUpdateFile = async (fileId: string) => {
    if (selectedStorageLocation) {
      const storageLocation = {
        storageLocationId: selectedStorageLocation.storageLocationId,
        pictureFileId: fileId
      };
      await storageLocationStore.updateStorageLocation(
        storageLocation,
        storageLocationStore.selectedStorageLocationIndex,
        selectedStorageLocation.parentStorageLocationId
      );
    }
  };

  const handleCreate = async () => {
    storageLocationStore.setSelectedParentId(selectedStorageLocation.storageLocationId);
    storageLocationStore.setSelectedIndexToCreate(storageLocationStore.selectedStorageLocationIndex + 1);
    storageLocationStore.setIsCreateFlyoutOpen(true);
  };

  const handleDelete = async () => storageLocationStore.setIsDeleteFlyoutOpen(true);

  return (
    <div
      className={
        storageLocationStore.isDetailDrawerOpen && inDrawer
          ? 'w-layout-grid grid-5 storage-location-drawer-styled'
          : 'location-administration-container colum_right hidden right'
      }
    >
      <div className={storageLocationStore.isDetailDrawerOpen && inDrawer ? 'div-block-147' : 'umkleide'}>
        <h1>{t('detailsTitle')}</h1>
        <CustomInput
          value={locationName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLocationName(event.target.value)}
          containerClassName="input_field_settings-copy"
          placeholder=""
          onBlur={onInputBlur}
        />
        <div className="div-block-101-copy storage-upload-image-container">
          <div className="image_post_wide in_data" onClick={handleAddImagesToView}>
            {selectedStorageLocation?.picture ? (
              <Picture alt="" src={selectedStorageLocation.pictureSmall} />
            ) : (
              <GetCameraImageButton
                buttonStyle="detailForm"
                onUpdateFile={onUpdateFile}
                description=""
                picturesOnly
                isAddable
                withLoadingBar
              />
            )}
          </div>
          <GetCameraImageButton
            isButtonVisible
            description={selectedStorageLocation?.pictureFileId ? t('button.toChange') : t('button.toAdd')}
            onUpdateFile={onUpdateFile}
            buttonStyle="square"
            picturesOnly
            isAddable
            withLoadingBar
          />
        </div>
        <div className="line _100" />
        <StorageLocationItems selectedStorageLocation={selectedStorageLocation} />
      </div>
      {inDrawer && (
        <div className="drawer-buttons div-block-146">
          <div className="nav_overview max_height">
            <div className="div-block-148">
              {!isMaterialLikeView && (
                <>
                  <NavSectionButton
                    onClick={handleCreate}
                    className="btn_material_detail-copy w-inline-block"
                    icon="icon_f-add_16_grey"
                    label={t('rightMenu.createNew', { locationName: selectedStorageLocation.name })}
                  />
                  {storageLocationStore.selectedStorageLocation && (
                    <SideMenuIconButton
                      className="mobile-only btn_material_detail-copy w-inline-block"
                      disabled={
                        !storageLocationStore.selectedStorageLocation ||
                        storageLocationStore.selectedStorageLocation.storageItems.length === 0
                      }
                      handleClick={() => {
                        flyoutStore.setIsMoveStorageLocationFlyoutOpen(true);
                      }}
                      label={t('rightMenu.moveToOtherStorageLocation')}
                      iconPath="move.svg"
                    />
                  )}
                  <NavSectionButton
                    onClick={handleDelete}
                    className="btn_material_detail_delete w-inline-block"
                    icon="icon_bin_16"
                    label={t('rightMenu.delete', { locationName: selectedStorageLocation.name })}
                  />
                </>
              )}
            </div>
            <Button className="btn_big_cancel active margin-left" onClick={() => storageLocationStore.setIsDetailDrawerOpen(false)}>
              {t('button.cancel')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StorageLocationDetails;
