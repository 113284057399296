import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { AppBarHeaderText } from 'components/AppLayout/AppBar';

const MaterialSetHeaderText = observer(() => {
  const { materialSetsStore } = useStores();
  return <AppBarHeaderText headline={materialSetsStore.materialSetDetail?.name || ''} />;
});
export default MaterialSetHeaderText;
