/**
 * File debouncer.ts contains a Debouncer class which can be used to debounce api requests for
 * frequently occurring events.
 */

/**
 * DebouncerOptions defines all options you can pass to the debouncer.
 *
 * The context is the data you can cumulate on each action which can then be used on the final dispatch.
 *
 * For example you can define as context an array of request data which you finally send in onDispatch all at once.
 * @param T defines the context type.
 * @param U defines the type of data on a new event which can be used to modify the context.
 */
export interface DebouncerOptions<T, U> {
  /**
   * onPostponedDispatch gets called when a dispatch gets postponed because it was called too frequently.
   * You can use it to save the data of the action into the context for later use in onDispatch.
   *
   * You have to return a context. It can be just the old one, a completely new one or null.
   */
  onPostponedDispatch?: (ctx: T | null, newData: U) => Promise<T | null>;

  /**
   * onDispatch gets called when an actual dispatch should be done. For example an api-request.
   * You get the context which was returned by the last onPostponedDispatch call. It may contain the data
   * to do your api-request.
   */
  onDispatch?: (ctx: T | null) => Promise<void>;

  /**
   * afterFinalDispatch gets called after onDispatch if no new action was done since onDispatch was called.
   *
   * If a new action was called since the onDispatched, afterFinalDispatch doesn't get called immediately
   * as we have to wait for the next actual dispatch.
   *
   * You can use this to reload for example your page.
   *
   * @param getLastActiontTime is a callback to retrieve the last action-time. It can be used to check after e.g.
   * a refresh if a new action was done while it was running. If it is still null, nothing should have happened since then.
   */
  afterFinalDispatch?: (getLastActiontTime: () => number | null) => Promise<void>;

  /**
   * ms is the milliseconds without any action to wait until the dispatch is done. It defaults to 1000 ms.
   */
  ms?: number;
}

/**
 * Debouncer can be used to debounce frequent, similar api requests.
 *
 * You can freely define actions to be done. So you can use this just for a simple debounce or
 * you can even cumulate all data to be done all at once in one call.
 *
 * @param T defines the context type.
 * @param U defines the type of data on a new event which can be used to modify the context.
 */
export default class Debouncer<T, U> {
  private ctx: T | null = null;

  private onPostponedDispatch: (ctx: T | null, newData: U) => Promise<T | null>;

  private onDispatch: (ctx: T | null) => Promise<void>;

  private afterFinalDispatch: (getLastActiontTime: () => number | null) => Promise<void>;

  private ms: number;

  private last: number | null = null;

  private timerId: number | null = null;

  constructor(options: DebouncerOptions<T, U>) {
    this.onPostponedDispatch = options.onPostponedDispatch || (() => Promise.resolve(null));
    this.onDispatch = options.onDispatch || (() => Promise.resolve());
    this.afterFinalDispatch = options.afterFinalDispatch || (() => Promise.resolve());
    this.ms = options.ms || 1000;
  }

  private async dispatch() {
    this.last = null;
    const currentCtx = this.ctx;
    this.ctx = null;
    await this.onDispatch(currentCtx);
    if (this.last === null) {
      await this.afterFinalDispatch(() => this.last);
    }
  }

  /**
   * do dispatches a new action but only if it doesn't get dispatched too often.
   * @param newData is the data which will be passed to onPostponedDispatch.
   */
  public async do(newData: U) {
    this.last = Date.now();

    if (this.timerId !== null) {
      clearTimeout(this.timerId);
    }

    this.ctx = await this.onPostponedDispatch(this.ctx, newData);

    const handler: TimerHandler = async () => {
      this.timerId = null;
      await this.dispatch();
    };
    this.timerId = setTimeout(handler, this.ms);
  }
}
