import { GuideMaterialLikeFlatDTO } from 'dto/guide';
import React from 'react';
import { useUsedMaterialContext } from './UsedMaterialContext';

interface Props {
  guideMaterial: GuideMaterialLikeFlatDTO;
}

export const GuideCountCheck = ({ guideMaterial }: Props) => {
  const { isTemplateComplete } = useUsedMaterialContext();
  const checked = isTemplateComplete(guideMaterial.guideMaterialId, guideMaterial.amount);

  return (
    <div className="guide-check guide-count-check">
      <img src={checked ? 'images/check-green.svg' : 'images/checked-icon-empty.svg'} alt="" />
    </div>
  );
};
