import { observable, runInAction, computed, action } from 'mobx';
import { OptionTypeBase } from 'react-select';
import { updateDepartment } from 'api/account';
import { AccountDTO } from 'dto/user';
import { GroupedDepartmentsDTO, DepartmentDTO } from 'dto/department';
import { getAllDepartments } from 'api/departments';
import { isMobile } from 'util/mobile/mobileUtils';
import { Ability } from '@casl/ability';
import { getFiles } from 'api/file';

import { FileMetadataDTO, ProcessingStatus } from 'dto/file';
import LoadingStore from 'stores/loadingStore';
import { getDepartmentMaintainerLead } from 'api/departmentMaintainer';
import { DepartmentMaintainerDTO } from 'dto/departmentMaintainer';

type OptionsType<OptionType extends OptionTypeBase> = OptionType[];

export interface Domain {
  id: string;
  name: string;
  shortName: string;
}

function emptyDomain() {
  return {
    id: '',
    name: '',
    shortName: ''
  };
}

export default class DomainStore {
  @observable
  private loadingStore: LoadingStore;

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;
    this.init();
  }

  @observable
  ability?: Ability;

  @observable
  isSwitchDomainFlyoutOpen = false;

  @observable
  isDrawerSwitchDomainFlyoutOpen = false;

  @observable
  isMobile = false;

  @observable
  isMobileReady = false;

  /**
   * files to query periodically for refreshing the state of all file-components based on the state
   */
  @observable
  private filesToQuery: Set<string> = new Set();

  /**
   * the files queried in the last round
   */
  @observable
  queriedFiles: Map<string, FileMetadataDTO> = new Map();

  @action
  init = async () => {
    this.isMobile = await isMobile();
    this.isMobileReady = true;

    setInterval(() => {
      this.loadFilesToQuery();
    }, 30000);
  };

  @computed
  get getIsMobile() {
    if (this.isMobileReady) {
      return this.isMobile;
    }
    return false;
  }

  @observable
  currentDepartment: Domain = emptyDomain();

  @observable
  currentFunctionalArea: Domain = emptyDomain();

  @observable
  currentLocation: Domain = emptyDomain();

  @observable
  currentDomainActive = false;

  @observable
  departments: GroupedDepartmentsDTO[] = [];

  @observable
  currentDepartmentLead?: DepartmentMaintainerDTO;

  @observable
  playingVideoId?: string;

  @computed
  get departmentsDropdownOptions() {
    const groupedOptions: OptionsType<OptionTypeBase> = [];

    this.departments.forEach(departmentGroup => {
      const groupLabel = `${departmentGroup.locationShortName} | ${departmentGroup.functionalAreaShortName}`;

      groupedOptions.push({
        label: groupLabel,
        options: departmentGroup.departments.map(d => {
          return {
            value: d.departmentId,
            label: d.name,
            group: groupLabel
          };
        })
      });
    });

    return groupedOptions;
  }

  @computed
  get departmentsDropdownOptionsFilteredByCurrentFunctionalArea() {
    const groupedOptions: OptionsType<OptionTypeBase> = [];

    this.departments.forEach(departmentGroup => {
      const groupLabel = `${departmentGroup.locationShortName} | ${departmentGroup.functionalAreaShortName}`;
      if (departmentGroup.functionalAreaId === this.currentFunctionalArea.id) {
        groupedOptions.push({
          label: groupLabel,
          options: departmentGroup.departments.map(d => {
            return {
              value: d.departmentId,
              label: d.name,
              group: groupLabel
            };
          })
        });
      }
    });

    return groupedOptions;
  }

  @computed
  get currentDepartmentDropdownOption() {
    return this.getDepartmentDropdownOptionById(this.currentDepartment.id);
  }

  @computed
  get departmentsByCurrentFunctionArea() {
    const departments = this.departments.find(
      department =>
        department.functionalAreaShortName === this.currentFunctionalArea.shortName &&
        department.locationShortName === this.currentLocation.shortName
    );
    return departments;
  }

  addFileToQuery = (...files: string[]) => {
    files.forEach(f => {
      runInAction(() => {
        this.filesToQuery.add(f);
      });
    });
  };

  loadFilesToQuery = async () => {
    if (this.filesToQuery.size === 0) {
      // no files which need querying
      return;
    }

    // query files
    const ids = Array.from(this.filesToQuery.keys());
    const files = await getFiles(ids);

    const newFiles = new Map<string, FileMetadataDTO>();

    files.forEach(f => {
      newFiles.set(f.fileId, f);
      // remove files finished now from next query
      if (f.status !== ProcessingStatus.Pending && f.status !== ProcessingStatus.Processing) {
        runInAction(() => {
          this.filesToQuery.delete(f.fileId);
        });
      }
    });

    // set the new loaded files
    runInAction(() => {
      this.queriedFiles = newFiles;
    });
  };

  @action
  setShowSwitchDomainFlyout = (isOpen: boolean) => {
    this.isSwitchDomainFlyoutOpen = isOpen;
  };

  @action
  setShowDrawerSwitchDomainFlyout = (isOpen: boolean) => {
    this.isDrawerSwitchDomainFlyoutOpen = isOpen;
  };

  @action
  setCurrentUnits = (account: AccountDTO) => {
    // Check if the current account is in anything disabled.
    // This info is used to show the selection dialog if anything is disabled.
    this.currentDomainActive = !(
      account.department.disabled ||
      account.department.functionalArea.disabled ||
      account.department.functionalArea.location.disabled
    );

    this.currentDepartment = {
      id: account.department.departmentId,
      name: account.department.name,
      shortName: ''
    };

    this.currentFunctionalArea = {
      id: account.department.functionalArea.functionalAreaId,
      name: account.department.functionalArea.shortName,
      shortName: account.department.functionalArea.shortName
    };

    this.currentLocation = {
      id: account.department.functionalArea.location.locationId,
      name: account.department.functionalArea.location.shortName,
      shortName: account.department.functionalArea.location.shortName
    };
  };

  @action
  setCurrentDepartment = (department: DepartmentDTO) => {
    this.currentDepartment = {
      id: department.departmentId,
      name: department.name,
      shortName: ''
    };
  };

  async handleSwitchDepartment(departmentId: string) {
    const account = await this.loadingStore.withLoadingBar(() => updateDepartment(departmentId));
    if (account) {
      this.setCurrentUnits(account);
    }
  }

  async loadAllDepartments() {
    const departments = await this.loadingStore.withLoadingBar(() => getAllDepartments());
    runInAction(() => {
      this.departments = departments;
    });
  }

  getDepartmentDropdownOptionById(departmentId: string) {
    let result: OptionTypeBase = [];
    this.departmentsDropdownOptions.forEach(group => {
      group.options.forEach((option: OptionTypeBase) => {
        if (option.value === departmentId) {
          result = option;
        }
      });
    });

    return result;
  }

  async getDepartmentMaintainerLeadForDepartment(departmentId: string) {
    const departmentMaintainers = await this.loadingStore.withLoadingBar(() => getDepartmentMaintainerLead(departmentId));
    runInAction(() => {
      this.currentDepartmentLead = departmentMaintainers;
    });
  }

  @action
  setPlayingVideoId = (playingVideoId: string) => {
    this.playingVideoId = playingVideoId;
  };
}
