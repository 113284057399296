/* eslint-disable max-classes-per-file */
import { createGroupMaterial, deleteGroupMaterial } from 'api/groupMaterial';
import { getMaterialLikeUsageCount } from 'api/materialLike';
import {
  createMaterialSet,
  getMaterialSet,
  getMaterialSetCheckItems,
  getMaterialSetChecks,
  getMaterialSetGuides,
  getMaterialSetsV2,
  postMaterialSetCheckItems,
  updateMaterialSet
} from 'api/materialSet';
import { generateQRCodePDF } from 'api/qr';
import { createSetGroup, deleteSetGroup, updateSetGroup } from 'api/setGroup';
import { createTag, deleteTag, updateTag } from 'api/tag';
import { IsString, IsUUID } from 'class-validator';
import { CreateGroupMaterialDTO } from 'dto/groupMaterial';
import { GroupedMaterialGuidesDTO, MaterialType } from 'dto/material';
import { GroupedMaterialKnowledgeDTO } from 'dto/materialKnowledge';
import {
  CheckStatus,
  CreateCheckedGroupMaterialDTO,
  CreateCheckedMaterialSetGroupsDTO,
  CreateMaterialSetDTO,
  DepartmentMaterialSetsV2DTO,
  MaterialSetDTO,
  SetCheckDTO,
  SetType,
  UpdateMaterialSetDTO
} from 'dto/materialSet';
import { QRSize } from 'dto/qr';
import { CreateSetGroupDTO, SetGroupWithTagsDTO, UpdateSetGroupDTO } from 'dto/setGroup';
import { TagDTO } from 'dto/tag';
import { action, computed, observable, runInAction } from 'mobx';
import { fromPromise } from 'util/mobx/utils';
import LoadingStore from './loadingStore';
import { AnyMaterial } from './searchStore';

export class QRUrlOptions {
  @IsString()
  groupType!: string;

  @IsUUID()
  materialSetId!: string;
}

export default class MaterialSetsStore {
  @observable
  private loadingStore: LoadingStore;

  @observable
  groupedMaterialSets: DepartmentMaterialSetsV2DTO[] = [];

  @observable
  materialSetDetail?: MaterialSetDTO;

  @observable
  materialSetId = '';

  @observable
  knowledge: GroupedMaterialKnowledgeDTO[] = [];

  @observable
  guides: GroupedMaterialGuidesDTO[] = [];

  @observable
  chapterIdForNewKnowledge = '';

  @observable
  newKnowledgePosition?: number = undefined;

  @observable
  isSetGroupFormFlyoutOpen = false;

  @observable
  selectedSetGroup?: SetGroupWithTagsDTO;

  @observable
  isCheckListVisible = false;

  @observable
  isCheckListFlyoutOpen = false;

  @observable
  materialSetCheckList: SetCheckDTO[] = [];

  @observable
  selectedSetCheck?: SetCheckDTO;

  @observable
  checkedSetGroupsToCreate: CreateCheckedMaterialSetGroupsDTO[] = [];

  @observable
  private lastMaterialSetsOptions?: {
    functionalAreaId: string;
    groupType: SetType;
  };

  @observable
  private onReload?: () => Promise<void>;

  @computed
  get deleteDisabled() {
    if (this.materialSetDetail?.materialSetId) {
      return fromPromise(
        true,
        getMaterialLikeUsageCount({
          materialSetId: this.materialSetDetail.materialSetId
        }).then(res => {
          return res.count !== 0;
        })
      );
    }
    return () => true;
  }

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;
  }

  async loadMaterialSets(functionalAreaId: string, groupType: SetType) {
    const groupedMaterialSets = await this.loadingStore.withLoadingBar(() => getMaterialSetsV2({ functionalAreaId, type: groupType }));
    runInAction(() => {
      this.groupedMaterialSets = groupedMaterialSets;
      this.lastMaterialSetsOptions = {
        functionalAreaId,
        groupType
      };
    });
  }

  async refreshMaterialSets() {
    if (this.onReload) {
      await this.onReload();
    }

    if (!this.lastMaterialSetsOptions) {
      return;
    }
    await this.loadMaterialSets(this.lastMaterialSetsOptions.functionalAreaId, this.lastMaterialSetsOptions.groupType);
  }

  @action
  registerReload(onReload?: () => Promise<void>) {
    this.onReload = onReload;
  }

  @action
  clearMaterialSets() {
    this.groupedMaterialSets = [];
  }

  @action
  async loadMaterialSet(materialSetId: string, noReset = false) {
    if (!noReset) {
      this.materialSetDetail = undefined;
    }

    const materialSetDetail = await this.loadingStore.withLoadingBar(() => getMaterialSet(materialSetId));
    runInAction(() => {
      this.materialSetDetail = materialSetDetail;
      this.materialSetId = materialSetId;
    });
  }

  async refreshMaterialSet() {
    if (this.onReload) {
      await this.onReload();
    }

    if (!this.materialSetId) {
      return;
    }
    this.loadMaterialSet(this.materialSetId, true);
  }

  async updateMaterialSet(materialSet: UpdateMaterialSetDTO, setMaterialSetDetail = true) {
    const materialSetDetail = await this.loadingStore.withLoadingBar(() => updateMaterialSet(materialSet));
    runInAction(async () => {
      if (!setMaterialSetDetail) {
        return;
      }
      this.materialSetDetail = materialSetDetail;
    });

    await this.refreshMaterialSets();
  }

  async createMaterialSet(materialSet: CreateMaterialSetDTO, functionalAreaId: string) {
    await this.loadingStore.withLoadingBar(() => createMaterialSet(materialSet));
    this.loadMaterialSets(functionalAreaId, materialSet.type);
  }

  async loadGuides(materialSetId: string) {
    const guides = await this.loadingStore.withLoadingBar(() => getMaterialSetGuides(materialSetId));
    runInAction(() => {
      this.guides = guides;
    });
  }

  async createSetGroup(group: CreateSetGroupDTO) {
    await this.loadingStore.withLoadingBar(() => createSetGroup(group));
    this.refreshMaterialSet();
  }

  async updateSetGroup(group: UpdateSetGroupDTO) {
    await this.loadingStore.withLoadingBar(() => updateSetGroup(group));
    this.refreshMaterialSet();
    this.refreshMaterialSets();
  }

  async deleteSetGroup(groupId: string) {
    await this.loadingStore.withLoadingBar(() => deleteSetGroup(groupId));
    this.refreshMaterialSet();
  }

  async deleteGroupMaterial(groupMaterialId: string) {
    await this.loadingStore.withLoadingBar(() => deleteGroupMaterial(groupMaterialId));
  }

  async createGroupMaterial(groupMaterial: CreateGroupMaterialDTO) {
    return this.loadingStore.withLoadingBar(() => createGroupMaterial(groupMaterial));
  }

  async loadMaterialSetChecks(materialSetId: string) {
    const materialSetCheckList = await this.loadingStore.withLoadingBar(() => getMaterialSetChecks(materialSetId));
    runInAction(() => {
      this.materialSetCheckList = materialSetCheckList;
    });
  }

  async submitMaterialSetChecks() {
    await this.loadingStore.withLoadingBar(() =>
      postMaterialSetCheckItems({
        materialSetId: this.materialSetId,
        setGroups: this.checkedSetGroupsToCreate
      })
    );
    this.setIsCheckListVisible(false);
  }

  async loadSelectedSetCheck(setCheckId: string) {
    const selectedSetCheck = await this.loadingStore.withLoadingBar(() => getMaterialSetCheckItems(setCheckId));
    // WORKAROUND: load without using the store as the store values may not be the newest, as the set-list has it's local state.
    const materialSetDetail = await this.loadingStore.withLoadingBar(async () => {
      if (!this.materialSetDetail) {
        return undefined;
      }
      return getMaterialSet(this.materialSetDetail.materialSetId);
    });
    runInAction(() => {
      if (materialSetDetail) {
        this.checkedSetGroupsToCreate = materialSetDetail.setGroups.map(group => {
          const groupMaterials: CreateCheckedGroupMaterialDTO[] = group.groupMaterials.map(groupMaterial => {
            const selectedGroupMaterial = selectedSetCheck.groupMaterialChecks.get(groupMaterial.groupMaterialId);
            return {
              groupMaterialId: groupMaterial.groupMaterialId,
              status: selectedGroupMaterial ? selectedGroupMaterial.status : CheckStatus.Missing
            };
          });
          const selectedSetGroup = selectedSetCheck.setGroupChecks.get(group.setGroupId);
          return {
            setGroupId: group.setGroupId,
            status: selectedSetGroup ? selectedSetGroup.status : CheckStatus.Missing,
            groupMaterials
          };
        });
      }
      this.selectedSetCheck = selectedSetCheck;
      this.isCheckListVisible = true;
    });
  }

  async createSetGroupTag(setGroupId: string, name: string) {
    const tag = await this.loadingStore.withLoadingBar(() => createTag({ setGroupId, name }));
    return tag;
  }

  async createGroupMaterialTag(groupMaterialId: string, name: string) {
    const tag = await this.loadingStore.withLoadingBar(() => createTag({ groupMaterialId, name }));
    return tag;
  }

  async updateTag(tag: TagDTO) {
    const updatedTag = await this.loadingStore.withLoadingBar(() => updateTag(tag));
    return updatedTag;
  }

  async deleteTag(tagId: string) {
    const result = await this.loadingStore.withLoadingBar(() => deleteTag(tagId));
    return result;
  }

  async getQRCodePDF(
    translation: {
      headingTop: string;
      description: string;
      heading2: string;
      fileName: string;
      size: QRSize;
    }[]
  ): Promise<{ url: string; fileName: string } | undefined> {
    const localUrl = await this.loadingStore.withLoadingBar(async () => {
      if (!this.materialSetDetail) {
        return undefined;
      }

      const type = SetType[this.materialSetDetail.type].toString().toLowerCase();

      const payload: QRUrlOptions = {
        groupType: type,
        materialSetId: this.materialSetDetail.materialSetId
      };

      const mappedTranslation = translation.map(t => {
        return {
          headingTop: t.headingTop,
          payload: JSON.stringify(payload),
          heading: this.materialSetDetail?.name || 'NONAME',
          description: t.description,
          heading2: t.heading2,
          size: t.size
        };
      });
      return generateQRCodePDF(mappedTranslation).then(pdf => {
        const blob = new Blob([pdf], { type: 'octet/stream' });
        return window.URL.createObjectURL(blob);
      });
    });

    const fileName = translation[0].fileName;
    if (localUrl) {
      return { url: localUrl, fileName };
    }

    return undefined;
  }

  @action
  setMaterialSetId(materialSetId: string) {
    this.materialSetId = materialSetId;
  }

  @action
  setIsSetGroupFormFlyoutOpen(status: boolean, selectedSetGroup?: SetGroupWithTagsDTO) {
    this.isSetGroupFormFlyoutOpen = status;
    if (selectedSetGroup) {
      this.selectedSetGroup = selectedSetGroup;
    }
    if (!status) {
      this.selectedSetGroup = undefined;
    }
  }

  @action
  setIsCheckListVisible(isCheckListVisible: boolean) {
    if (isCheckListVisible) {
      this.generateCheckItems();
    }

    runInAction(() => {
      this.isCheckListVisible = isCheckListVisible;
      this.selectedSetCheck = undefined;
    });
  }

  @action
  setIsCheckListFlyoutOpen(isCheckListFlyoutOpen: boolean) {
    this.isCheckListFlyoutOpen = isCheckListFlyoutOpen;
  }

  async generateCheckItems() {
    // WORKAROUND: load without using the store as the store values may not be the newest, as the set-list has it's local state.
    const materialSetDetail = await this.loadingStore.withLoadingBar(async () => {
      if (!this.materialSetDetail) {
        return undefined;
      }
      return getMaterialSet(this.materialSetDetail.materialSetId);
    });

    if (!materialSetDetail) {
      return;
    }

    if (materialSetDetail) {
      const checked = materialSetDetail.setGroups.map(group => {
        const groupMaterials: CreateCheckedGroupMaterialDTO[] = group.groupMaterials.map(groupMaterial => ({
          groupMaterialId: groupMaterial.groupMaterialId,
          status: CheckStatus.Missing
        }));
        return {
          setGroupId: group.setGroupId,
          status: CheckStatus.Missing,
          groupMaterials
        };
      });

      runInAction(() => {
        if (checked) {
          this.checkedSetGroupsToCreate = checked;
        }
      });
    }
  }

  @action
  addSetGroupCheckItem(status: CheckStatus, setGroupId: string) {
    this.checkedSetGroupsToCreate = this.checkedSetGroupsToCreate.map(setGroup => {
      if (setGroup.setGroupId === setGroupId) {
        return {
          ...setGroup,
          status
        };
      }
      return setGroup;
    });
  }

  @action
  addGroupMaterialCheckItem(status: CheckStatus, groupMaterialId: string) {
    this.checkedSetGroupsToCreate = this.checkedSetGroupsToCreate.map(setGroup => {
      const groupMaterials: CreateCheckedGroupMaterialDTO[] = setGroup.groupMaterials.map(groupMaterial => {
        if (groupMaterial.groupMaterialId === groupMaterialId) {
          return {
            ...groupMaterial,
            status
          };
        }
        return groupMaterial;
      });
      return {
        ...setGroup,
        groupMaterials
      };
    });
  }

  addMaterialToMaterialSet(materialLike: AnyMaterial, setGroupId: string) {
    const groupMaterialToCreate: CreateGroupMaterialDTO = {
      setGroupId,
      position: 0
    };

    if (materialLike.material && materialLike.material.materialType === MaterialType.material) {
      groupMaterialToCreate.materialId = materialLike.material.materialId;
    } else if (materialLike.pack) {
      groupMaterialToCreate.templateId = materialLike.pack.templateId;
    } else {
      return Promise.resolve(undefined);
    }

    return this.createGroupMaterial(groupMaterialToCreate);
  }
}
