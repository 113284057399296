import React from 'react';
import { useTranslation } from 'react-i18next';

const LoginRightImage = () => {
  const { t } = useTranslation('login');
  return (
    <div className="div-block-174">
      <div className="div-block-175">
        <div className="headline-login-copy">{t('pictureHeadline')}</div>
        <img src="images/image_3.png" loading="lazy" width="959" sizes="100vw" alt="" className="image-13" />
      </div>
    </div>
  );
};

export default LoginRightImage;
