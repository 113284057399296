import ReactPlayer from 'react-player';
import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import './Video.css';
import { ProcessingStatus } from 'dto/file';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import ThumbnailMediaProgressTag from 'components/ThumbnailList/ThumbnailMediaProgressTag';
import { useTranslation } from 'react-i18next';
import { useSecureURL } from 'util/hooks';
import { IsBrowser } from 'util/browser';
import OptionalLazyLoad from 'components/OptionalLazyLoad';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import Picture from '../Picture';

interface Props extends OptionalLazyLoadProps {
  file: ContentElementMediaDTO;
  small?: boolean;
  uploadProgress?: number;
  onMediaLabelChange?: (mediaId: string, text: string) => void;
  onDeleteImage?: (imageId: string) => void;
  viewOnly?: boolean;
}

const videoThumbnail = 'images/video_thumbnail.jpg';
const playIcon = 'images/icon_play_circle_filled_24px.svg';

const unmountVideo = (player: ReactPlayer) => {
  const videoTag = player.getInternalPlayer() as HTMLVideoElement;
  if (videoTag && videoTag.pause) {
    videoTag.pause();
    videoTag.removeAttribute('src');
    videoTag.load();
  }
};

const InternalVideo = observer(({ file, small, uploadProgress, onMediaLabelChange, onDeleteImage, viewOnly = false }: Props) => {
  const { t } = useTranslation('itemFormFlyOut');
  const [currentFile, setCurrentFile] = useState(file);
  const { domainStore } = useStores();
  const playerRef = useRef<ReactPlayer>(null);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onMediaLabelChange) {
      onMediaLabelChange(file.fileId, event.target.value);
    }
  };

  useEffect(() => {
    if (currentFile.status === ProcessingStatus.Pending || currentFile.status === ProcessingStatus.Processing) {
      domainStore.addFileToQuery(file.fileId);
    }

    const current = domainStore.queriedFiles.get(file.fileId);
    if (current !== undefined) {
      setCurrentFile(current);
    }
  }, [domainStore, file, domainStore.queriedFiles, currentFile.status]);

  useEffect(() => {
    // return to preview display when another video is started
    if (playerRef.current && domainStore.playingVideoId && domainStore.playingVideoId !== file.fileId) {
      unmountVideo(playerRef.current);
      playerRef.current.showPreview();
    }
  }, [domainStore.playingVideoId, file.fileId]);
  useEffect(() => {
    // cleanup the html video element when the player is removed from DOM
    return () => {
      if (playerRef.current) {
        // eslint-disable-next-line
        unmountVideo(playerRef.current);
      }
    };
  }, []);

  const currentThumbnailURL = useSecureURL(currentFile.thumbnailUrl);

  if (viewOnly) {
    return <Picture src={file.thumbnailUrl} />;
  }

  let width: number;
  let height: number;

  if (domainStore.isMobile && small) {
    width = 138;
    height = 105;
  } else if (small && !domainStore.isMobile) {
    width = 122;
    height = 110;
  } else {
    width = 240;
    height = 180;
  }
  return (
    <>
      <div className={onMediaLabelChange || onDeleteImage ? 'image_post_wide' : ''}>
        {currentFile.status === ProcessingStatus.Finished ? (
          <ReactPlayer
            ref={playerRef}
            onPlay={() => domainStore.setPlayingVideoId(file.fileId)}
            width={width}
            height={height}
            light={currentThumbnailURL || videoThumbnail}
            className="react-player"
            url={currentFile.fullUrl}
            playIcon={<img alt="play" src={playIcon} height="48" className="video_play_icon" />}
            controls
            playing
            config={{
              file: {
                forceHLS: IsBrowser.chrome(),
                hlsOptions: {
                  debug: false
                }
              }
            }}
          />
        ) : (
          <>
            <ThumbnailMediaProgressTag
              status={currentFile.status}
              uploadProgress={uploadProgress}
              contentType={currentFile.contentType}
              small={small}
            />
            <Picture src={videoThumbnail} />
          </>
        )}
      </div>

      {onMediaLabelChange && file.status !== ProcessingStatus.Uploading ? (
        <input
          type="text"
          className="txt_post_image in_flyout post-image-input"
          placeholder={t('uploadFiles.imageDescription')}
          onChange={onChange}
          value={file.title}
        />
      ) : (
        <></>
      )}
      {onDeleteImage && file.status !== ProcessingStatus.Uploading ? (
        <div className="post_image_info">
          <div className="btn_remove_media" onClick={() => onDeleteImage(file.fileId)}>
            <img src="images/icon_bin_16.svg" alt="" className="image_function" />
            <div>{t('uploadFiles.deleteVideo')}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
});

const Video = ({ lazyLoadScrollContainer, ...props }: Props) => {
  return (
    <OptionalLazyLoad offset={100} lazyLoadScrollContainer={lazyLoadScrollContainer}>
      <InternalVideo {...props} />
    </OptionalLazyLoad>
  );
};

export default Video;
