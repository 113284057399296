import React from 'react';
import './Button.css';

interface Props {
  children?: React.ReactNode;
  onClick?: () => void;
}

export default ({ children, onClick }: Props) => {
  return (
    <div className="button_normal" onClick={onClick}>
      {children}
    </div>
  );
};
