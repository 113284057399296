import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';

import ReportGuidesContent from './ReportGuidesContent';

const ReportsGuides = observer(() => {
  const { reportStore, domainStore } = useStores();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function loadData() {
      setLoading(true);
      await reportStore.loadLocationReport(domainStore.currentLocation.id);
      setLoading(false);
    }
    loadData();
  }, [domainStore.currentLocation.id, reportStore]);
  if (!reportStore.locationReport || loading) {
    return null;
  }
  const reportData = reportStore.locationReport.functionalAreas.find(f => f.functionalAreaId === domainStore.currentFunctionalArea.id);
  if (!reportData) {
    return null;
  }
  return <ReportGuidesContent key={domainStore.currentFunctionalArea.id} reportData={reportData.departments} />;
});

export default ReportsGuides;
