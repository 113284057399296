/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { Type } from 'class-transformer';
import { IsDate, IsEnum, IsInt, IsOptional, IsString, IsUUID, ValidateNested } from 'class-validator';
import 'reflect-metadata';
import { OptionalPictureDTO } from './file';
import { FullSetGroupDTO, SetGroupWithTagsDTO, SimpleSetGroupDTO } from './setGroup';
import { UserDTO } from './user';

export enum SetType {
  Implants,
  Sets,
  Medicals,
  RentalMaterials
}

export enum CheckStatus {
  Missing,
  Ok
}

/**
 * BasicMaterialSetDTO is used only as basis for the simple and not simple materialSetDTO as they use different setGroup types.
 */
export class BasicMaterialSetDTO extends OptionalPictureDTO {
  @IsUUID()
  materialSetId!: string;

  @IsEnum(SetType)
  type!: SetType;

  @IsString()
  name!: string;

  @IsString()
  departmentId!: string;

  @IsOptional()
  @IsDate()
  @Type(() => Date)
  lastCheck?: Date;

  @IsDate()
  @Type(() => Date)
  createdAt!: Date;
}

export class SimpleMaterialSetDTO extends BasicMaterialSetDTO {
  @ValidateNested({ each: true })
  @Type(() => SimpleSetGroupDTO)
  setGroups!: SimpleSetGroupDTO[];
}

export class MaterialSetDTO extends BasicMaterialSetDTO {
  @IsInt()
  @IsOptional()
  usageCountInGuides?: number;

  @ValidateNested({ each: true })
  @Type(() => FullSetGroupDTO)
  setGroups!: FullSetGroupDTO[];

  @ValidateNested()
  @Type(() => UserDTO)
  user!: UserDTO;
}

export class MaterialSetV2DTO extends BasicMaterialSetDTO {
  @IsInt()
  @IsOptional()
  usageCountInGuides?: number;

  @ValidateNested({ each: true })
  @Type(() => SetGroupWithTagsDTO)
  setGroups!: SetGroupWithTagsDTO[];

  @ValidateNested()
  @Type(() => UserDTO)
  user!: UserDTO;
}

export class CreateMaterialSetDTO {
  @IsEnum(SetType)
  type!: SetType;

  @IsString()
  name!: string;

  /**
   * if not set, the current department of the user is used
   */
  @IsOptional()
  @IsString()
  departmentId?: string;

  @IsUUID()
  @IsOptional()
  pictureFileId?: string;
}

export class GetMaterialSetsDTO {
  @IsUUID()
  @IsOptional()
  functionalAreaId?: string;

  @IsEnum(SetType)
  @IsOptional()
  type?: SetType;
}

export class DepartmentMaterialSetsDTO {
  @IsUUID()
  departmentId!: string;

  @ValidateNested({ each: true })
  @Type(() => MaterialSetDTO)
  materialSets!: MaterialSetDTO[];
}

export class DepartmentMaterialSetsV2DTO {
  @IsUUID()
  departmentId!: string;

  @ValidateNested({ each: true })
  @Type(() => MaterialSetDTO)
  materialSets!: MaterialSetV2DTO[];
}

export class MaterialSetIdDTO {
  @IsUUID()
  materialSetId!: string;
}

export class UpdateMaterialSetDTO {
  @IsUUID()
  materialSetId!: string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsUUID()
  @IsOptional()
  departmentId?: string;

  @IsUUID()
  @IsOptional()
  pictureFileId?: string;
}

// Create CheckMaterialSet DTO's:
export class CreateCheckedGroupMaterialDTO {
  @IsUUID()
  groupMaterialId!: string;

  @IsEnum(CheckStatus)
  status!: CheckStatus;
}
export class CreateCheckedMaterialSetGroupsDTO {
  @IsUUID()
  setGroupId!: string;

  @IsEnum(CheckStatus)
  status!: CheckStatus;

  @ValidateNested({ each: true })
  @Type(() => CreateCheckedGroupMaterialDTO)
  groupMaterials!: CreateCheckedGroupMaterialDTO[];
}
export class CreateCheckMaterialSetDTO {
  @IsUUID()
  materialSetId!: string;

  @ValidateNested({ each: true })
  @Type(() => CreateCheckedMaterialSetGroupsDTO)
  setGroups!: CreateCheckedMaterialSetGroupsDTO[];
}

// Get CheckMaterialSet DTO's:
export class MaterialSetGroupCheckItemDTO {
  @IsUUID()
  setGroupItemId!: string;

  @IsUUID()
  setGroupId!: string;

  @IsUUID()
  setCheckId!: string;

  @IsEnum(CheckStatus)
  status!: CheckStatus;
}

export class GroupMaterialsCheckDTO {
  @IsUUID()
  groupItemId!: string;

  @IsUUID()
  groupMaterialId!: string;

  @IsUUID()
  setCheckId!: string;

  @IsEnum(CheckStatus)
  status!: CheckStatus;
}
export class MaterialSetCheckDTO extends MaterialSetGroupCheckItemDTO {
  @ValidateNested({ each: true })
  @Type(() => GroupMaterialsCheckDTO)
  groupMaterialChecks!: GroupMaterialsCheckDTO[];
}

export class SetCheckDTO {
  @IsUUID()
  setCheckId!: string;

  @IsUUID()
  materialSetId!: string;

  @ValidateNested({ each: true })
  @Type(() => UserDTO)
  user!: UserDTO;

  @IsDate()
  @Type(() => Date)
  createdAt!: Date;
}

export class SimpleMaterialSetCheckItemDTO {
  @IsUUID()
  setCheckItemId!: string;

  @IsEnum(CheckStatus)
  status!: CheckStatus;
}

export class FullSetCheckDTO extends SetCheckDTO {
  @ValidateNested({ each: true })
  @Type(() => SimpleMaterialSetCheckItemDTO)
  setGroupChecks!: Map<string, SimpleMaterialSetCheckItemDTO>;

  @ValidateNested({ each: true })
  @Type(() => SimpleMaterialSetCheckItemDTO)
  groupMaterialChecks!: Map<string, SimpleMaterialSetCheckItemDTO>;
}

export class FullSetCheckWithoutMapsDTO extends SetCheckDTO {
  @ValidateNested({ each: true })
  @Type(() => SimpleMaterialSetCheckItemDTO)
  setGroupChecks!: Record<string, SimpleMaterialSetCheckItemDTO>;

  @ValidateNested({ each: true })
  @Type(() => SimpleMaterialSetCheckItemDTO)
  groupMaterialChecks!: Record<string, SimpleMaterialSetCheckItemDTO>;
}
