import { GroupedDepartmentsDTO, CreateDepartmentDTO, DepartmentDTO, UpdateDepartmentDTO } from 'dto/department';
import { get, post, patch, del } from './common';
import { validateArray, validate, validateBool } from './validation';

/**
 * getAllDepartments returns the departments grouped by the department
 */
export const getAllDepartments = async () => {
  const departments = await get('/departments').then(response => response.data);
  return validateArray(GroupedDepartmentsDTO, departments);
};

export const createDepartment = async (getDepartmentsOptions: CreateDepartmentDTO) => {
  const department = await post('/department', getDepartmentsOptions).then(response => response.data);
  return validate(DepartmentDTO, department);
};

export const updateDepartment = async (getDepartmentsOptions: UpdateDepartmentDTO) => {
  const department = await patch('/department', getDepartmentsOptions).then(response => response.data);
  return validate(DepartmentDTO, department);
};

export const deleteDepartment = async (departmentId: string) => {
  return del('/guides/material', { departmentId }).then(response => validateBool(response.data));
};
