import React from 'react';
import ItemMenuButton from 'components/ItemMenuButton';
import { useTranslation } from 'react-i18next';
import { GroupedMaterialKnowledgeDTO } from 'dto/materialKnowledge';
import { ChapterType } from 'dto/materialChapter';

interface Props {
  materialKnowledge: GroupedMaterialKnowledgeDTO;
  onEditClick?: () => void;
  onDeleteClick: () => void;
}

const MaterialKnowledgeMenu = ({ materialKnowledge, onEditClick = () => undefined, onDeleteClick }: Props) => {
  const { t } = useTranslation('material');

  if (materialKnowledge.chapterType === ChapterType.Synonym) {
    return (
      <>
        <ItemMenuButton
          label={t('materialGlobalFunctions.postItem.synonym.delete')}
          icon="icon_bin_16.svg"
          warning
          isInPopover
          handleClick={onDeleteClick}
        />
      </>
    );
  }
  return (
    <>
      <ItemMenuButton
        label={t('materialGlobalFunctions.postItem.element.edit')}
        icon="icon_i-edit_16.svg"
        isInPopover
        handleClick={onEditClick}
      />
      <ItemMenuButton
        label={t('materialGlobalFunctions.postItem.element.delete')}
        icon="icon_bin_16.svg"
        warning
        isInPopover
        handleClick={onDeleteClick}
      />
    </>
  );
};

export default MaterialKnowledgeMenu;
