import { useQuery } from '@tanstack/react-query';
import { LoadingType } from 'stores/loadingStore';
import { useStores } from 'util/mobx/stores';
import { ApiQueryOptions } from './query';
import { getSetGroups } from './setGroup';

export const useGetSetGroups = (materialSetId: string, options: ApiQueryOptions = {}) => {
  const { loadingStore } = useStores();
  const { loadingType, ...queryOptions } = options;
  return useQuery({
    queryKey: ['material-like', 'material-set', { materialSetId }],
    queryFn: async () => {
      return loadingStore.withSpecificLoadingBar(loadingType || LoadingType.DEFAULT, () => getSetGroups(materialSetId));
    },
    ...queryOptions
  });
};
