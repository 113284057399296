import React from 'react';
import { Formik, FormikValues, FormikProps, FormikHelpers, FormikTouched } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react';

interface Metadata {
  [key: string]: unknown;
}

interface Props extends React.HTMLProps<HTMLFormElement> {
  children: (data: FormikProps<FormikValues>) => React.ReactNode;
  initialValues: Metadata | any;
  bindSubmitForm?(func: () => Promise<void | unknown>): void;
  bindAllowedToSubmit?(isDirty: boolean, values: FormikValues, touched: FormikTouched<FormikValues>): void;
  onSubmit?(values: unknown, form?: FormikHelpers<FormikValues>): void;
  validationSchema?: Yup.ObjectSchema;
  isOnlyChangedValues?: boolean;
  onValuesChange?: (values: FormikValues) => void;
}

const getChangedValues = (initialValues: Metadata, values: Metadata) => {
  const changedValues: Metadata = {};
  Object.keys(initialValues).map((key: string) => {
    if (initialValues[key] !== values[key]) {
      changedValues[key] = values[key];
    }
    return null;
  });
  return changedValues;
};

const Form = observer(
  ({
    children,
    initialValues,
    bindSubmitForm,
    bindAllowedToSubmit,
    onSubmit,
    validationSchema,
    isOnlyChangedValues = true,
    onValuesChange,
    ...restProps
  }: Props) => {
    return (
      <Formik
        onSubmit={(values, form) => {
          const changedValues: Metadata = getChangedValues(initialValues, values);
          if (onSubmit) {
            onSubmit(isOnlyChangedValues ? changedValues : values, form);
          }
          form.setSubmitting(false);
          form.resetForm(initialValues);
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitForm, ...rest }: FormikProps<FormikValues>) => {
          if (bindSubmitForm) {
            bindSubmitForm(submitForm);
          }
          if (bindAllowedToSubmit) {
            bindAllowedToSubmit(rest.dirty, rest.values, rest.touched);
          }
          if (onValuesChange) {
            onValuesChange(rest.values);
          }
          return (
            <form onSubmit={handleSubmit} {...restProps}>
              {children({ submitForm, handleSubmit, ...rest })}
            </form>
          );
        }}
      </Formik>
    );
  }
);

export default Form;
