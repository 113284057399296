import React from 'react';
import ListItemText from 'components/ListItemText';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation('material');

  return (
    <>
      <ListItemText text={t('manuallyCreated')} isSelected />
      {/* <ListItemText text={t('lastSeen')} isSelected /> */}
    </>
  );
};
