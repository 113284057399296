import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import './ChatFlyoutContainer.css';
import { useStores } from 'util/mobx/stores';

interface Props {
  title?: string;
  isOpen: boolean;
  children: JSX.Element[] | JSX.Element;
  icon: JSX.Element;
  withMinimizeButton?: boolean;
  isSingleContent?: boolean;
  isCollapsible?: boolean;
  buttonCloseLabel?: string;
  buttonSubmitLabel?: string;
  onClose?: () => void;
  onSubmit?: () => void;
}

const getFlyoutWrapperClasses = (
  withMinimizeButton: boolean,
  isCollapsed: boolean | null,
  isSingleContent: boolean,
  isKeyboardOpen: boolean
) => {
  let classes = '';
  if (isCollapsed === null || isSingleContent || !withMinimizeButton) {
    classes = '';
  } else if (isCollapsed) {
    classes = isKeyboardOpen ? 'collapsed' : 'collapsed';
  } else {
    classes = isKeyboardOpen ? 'expanded' : 'expanded';
  }

  if (isKeyboardOpen) {
    classes += ' with-keyboard';
  }
  return classes;
};

const ChatFlyoutContainer = ({
  isOpen,
  children,
  title,
  icon,
  withMinimizeButton = false,
  buttonCloseLabel,
  buttonSubmitLabel,
  onClose,
  onSubmit,
  isSingleContent = false,
  isCollapsible = false
}: Props) => {
  const { t } = useTranslation('chat');
  const [isFlyoutCollapsed, setIsFlyoutCollapsed] = useState<boolean | null>(null);
  const { appNavigationStore } = useStores();
  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setIsFlyoutCollapsed(false);
      }, 500);
    }
  }, [isOpen]);

  const extraClasses = getFlyoutWrapperClasses(withMinimizeButton, isFlyoutCollapsed, isSingleContent, appNavigationStore.isKeyboardOpen);

  return (
    <CSSTransition in={isOpen} timeout={500} classNames="chat-flyout" unmountOnExit>
      <div className={`flylout-wrapper flylout-chat-wrapper flylout-wrapper-above-nav ${extraClasses}`}>
        <div className={`flyout_board_messages flyout-board-messages ${extraClasses}`}>
          <div className="flyout_header_absolut board" onClick={() => setIsFlyoutCollapsed(false)}>
            <div className="div-block-22">
              <div>{icon}</div>
              <div>{title}</div>
            </div>
            {isFlyoutCollapsed && withMinimizeButton && (
              <div>
                <img src="images/icon_full-screen_16.svg" alt="" className="icon_fullscreen icon-fullscreen" />
              </div>
            )}
          </div>
          <div className="flyout_line_absolut board" />
          <div
            className={`flyout_wrapper_messages ${isCollapsible ? '' : 'not-collapsible'} ${
              isSingleContent ? 'auto_content_height auto-content-height' : 'flyout-wrapper-messages'
            }`}
          >
            <div className="flyout_box">{children}</div>
          </div>
          <div className="button_bar_absolut">
            {withMinimizeButton && (
              <div className="btn_msg_minimize" onClick={() => setIsFlyoutCollapsed(true)}>
                <div>{t('flyout.minimize')}</div>
              </div>
            )}
            {buttonCloseLabel && (
              <div className="btn_comment_close" onClick={onClose}>
                <div>{buttonCloseLabel}</div>
              </div>
            )}
            {buttonSubmitLabel && onSubmit && (
              <div className={`${isCollapsible ? 'btn_msg_write' : 'btn_goto'}`} onClick={onSubmit}>
                <div>{buttonSubmitLabel}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default ChatFlyoutContainer;
