import React from 'react';
import { DepartmentMaintainerDTO } from 'dto/departmentMaintainer';

interface Props {
  departmentMaintainerOf: DepartmentMaintainerDTO[];
  headline: string;
  onChange?: () => void;
  onChangeButtonText?: string;
}

const DepartmentList = ({ departmentMaintainerOf, headline, onChange, onChangeButtonText }: Props) => {
  return (
    <>
      <div>{headline}</div>

      {departmentMaintainerOf.map(dep => (
        <div className="div-block-183" key={dep.department.departmentId}>
          <div className="text-block-29">{`${dep.department.locationShortName} | ${dep.department.functionalAreaShortName}: ${dep.department.name}`}</div>
        </div>
      ))}

      {onChange && (
        <div className="btn_small_overview" onClick={onChange}>
          <div className="txt_dropout_1">{onChangeButtonText}</div>
        </div>
      )}
    </>
  );
};

export default DepartmentList;
