import { useEffect, useState } from 'react';
import { fetchSecure } from './download';

/**
 * useSecureURL uses fetchSecure to download the given url using the authentication.
 * It then just returns the local object url (or undefined, if it the download is not finished yet).
 *
 * This can be used like every other hook in React components. It uses a state internally.
 * @example <img src={useSecure("mysecureurl.de/file/xyz.png")} />
 */
export function useSecureURL(url?: string): string | undefined {
  const [secureURL, setSecureURL] = useState<string>();

  useEffect(() => {
    return () => {
      if (secureURL) {
        window.URL.revokeObjectURL(secureURL);
      }
    };
  }, [secureURL]);

  useEffect(() => {
    if (url) {
      fetchSecure(url, true).then(newUrl => {
        if (secureURL) {
          window.URL.revokeObjectURL(secureURL);
        }
        setSecureURL(newUrl);
      });
    }

    // Ignore the react-hooks/exhaustive-deps rules because I explicitly want to ignore changes
    // on secureUrl and just revoke the url. Else this would lead to a loop.
    // eslint-disable-next-line
  }, [url]);

  return secureURL;
}
