import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';

import MenuIcon from 'components/animationIcons/MenuIcon';
import ClearedButton from 'components/ClearedButton';

interface Props {
  className: string;
}

const AppBarMenuButton = observer((props: Props) => {
  const [direction, setDirection] = useState(true);

  const { appNavigationStore } = useStores();

  const toggleMenu = () => {
    appNavigationStore.toggleMenu();
    setDirection(!direction);
  };

  return (
    <ClearedButton onClick={toggleMenu} className={props.className}>
      <MenuIcon direction={!appNavigationStore.isMenuOpen} />
    </ClearedButton>
  );
});

export default AppBarMenuButton;
