import { subject } from '@casl/ability';
import { Can, actions, subjectArea } from 'casl/setupCaslAbility';
import GetCameraImageButton from 'components/GetCameraImageButton';
import InfoBox from 'components/InfoBox';
import ListItemAdd from 'components/ListItemAdd';
import MaterialValueTags from 'components/MaterialValueTags';
import Picture from 'components/Picture';
import Popover from 'components/Popover';
import Tags from 'components/Tags/Tags';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import { CheckStatus } from 'dto/materialSet';
import { FullSetGroupDTO } from 'dto/setGroup';
import { TagDTO } from 'dto/tag';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { AnyMaterial, SelectableType } from 'stores/searchStore';
import { IsBrowser } from 'util/browser';
import { getItemStyle } from 'util/dragAndDrop';
import { useStores } from 'util/mobx/stores';
import EditableGroupMaterialListItem from '../EditableGroupMaterialListItem';
import CheckListItemLottie from '../EditableGroupMaterialListItem/CheckListItemLottie';
import ListItemDropdownAsFunction from '../ListItemDropdownAsFunction';
import SetGroupListItemMenu from './SetGroupListItemMenu';

interface Props {
  setGroup: FullSetGroupDTO;
  index: number;
  groupType: string;
}

const EditableSetGroupListItem = observer(({ setGroup, index, groupType }: Props) => {
  const [groupMaterials, setGroupMaterials] = useState(setGroup.groupMaterials);
  const { t: materialSetTranslation } = useTranslation(groupType);
  const { t: searchTranslation } = useTranslation('materialSearch');
  const { searchStore, materialSetsStore, domainStore } = useStores();

  const onGroupMaterialAdd = useCallback(
    async (m: AnyMaterial) => {
      const groupMaterial = await materialSetsStore.addMaterialToMaterialSet(m, setGroup.setGroupId);
      if (groupMaterial) {
        setGroupMaterials([...groupMaterials, groupMaterial]);
      }
    },
    [groupMaterials, materialSetsStore, setGroup.setGroupId]
  );

  const onGroupMaterialDelete = useCallback(
    async (groupMaterialId: string) => {
      setGroupMaterials([...groupMaterials.filter(gm => gm.groupMaterialId !== groupMaterialId)]);
    },
    [groupMaterials]
  );

  const handleCreateGroupMaterials = useCallback(() => {
    searchStore.openSearchPanel(
      m => onGroupMaterialAdd(m),
      searchTranslation('searchItem.addItem'),
      SelectableType.Material,
      SelectableType.Package
    );
  }, [onGroupMaterialAdd, searchStore, searchTranslation]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !groupMaterials) return;
    const groupMaterialToBeRemoved = groupMaterials.find(groupMaterial => groupMaterial.groupMaterialId === result.draggableId);
    const oldGroupMaterials = groupMaterials.filter(groupMaterial => groupMaterial.groupMaterialId !== result.draggableId);
    if (groupMaterialToBeRemoved) {
      oldGroupMaterials.splice(result.destination.index, 0, groupMaterialToBeRemoved);
      setGroupMaterials([...oldGroupMaterials]);
    }
  };

  useEffect(() => {
    setGroupMaterials(setGroup.groupMaterials);
  }, [setGroup.groupMaterials]);

  const renderList = (isListExpanded: null | boolean) => (
    <div className={`content_holder no_padding padding_left instruments-list ${isListExpanded ? '' : 'display-none'}`}>
      {!materialSetsStore.isCheckListVisible && (
        <Can I={actions.add} this={subject(subjectArea.groupMaterial, { departmentId: domainStore.currentDepartment.id })}>
          <ListItemAdd className="list_item_material_add in_content" onClick={handleCreateGroupMaterials}>
            <InfoBox label={materialSetTranslation('groupsList.button.createGroupMaterials')} />
          </ListItemAdd>
        </Can>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={setGroup.setGroupId} type="subItem">
          {provided => (
            <div ref={provided.innerRef}>
              {groupMaterials.map((groupMaterial, materialIndex) => {
                return (
                  <Draggable
                    draggableId={groupMaterial.groupMaterialId}
                    index={materialIndex}
                    key={groupMaterial.groupMaterialId}
                    isDragDisabled={!!materialSetsStore.isCheckListVisible}
                  >
                    {(draggableProvided, snapshot) => {
                      const style = getItemStyle(snapshot.isDragging, draggableProvided.draggableProps.style);

                      if (IsBrowser.chrome() && snapshot.isDragging) {
                        // Hacky Hack https://github.com/atlassian/react-beautiful-dnd/issues/1881#issuecomment-695772970
                        style.left = (draggableProvided.draggableProps.style as { offsetLeft: number })?.offsetLeft;
                        style.top = (draggableProvided.draggableProps.style as { offsetTop: number })?.offsetTop;
                      }

                      return (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={style}
                        >
                          <EditableGroupMaterialListItem
                            groupMaterial={groupMaterial}
                            groupType={groupType}
                            key={`${groupMaterial.groupMaterialId}`}
                            onGroupMaterialDelete={onGroupMaterialDelete}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );

  const onChange = () => {
    materialSetsStore.setIsSetGroupFormFlyoutOpen(true, setGroup);
  };

  const onDelete = () => materialSetsStore.deleteSetGroup(setGroup.setGroupId);

  const onTagCreate = async (tagName: string) => {
    return materialSetsStore.createSetGroupTag(setGroup.setGroupId, tagName);
  };

  const onTagDelete = (tagId: string) => {
    return materialSetsStore.deleteTag(tagId);
  };

  const onTagUpdate = (tag: TagDTO) => {
    return materialSetsStore.updateTag(tag);
  };

  const onAddToCheckList = (status: CheckStatus) => {
    materialSetsStore.addSetGroupCheckItem(status, setGroup.setGroupId);
  };

  const onUpdateFile = async (fileId: string) => {
    await materialSetsStore.updateSetGroup({ setGroupId: setGroup.setGroupId, pictureFileId: fileId });
  };

  return (
    <ListItemDropdownAsFunction
      classPrefix="package"
      listElement={renderList}
      isExpandedByDefault={materialSetsStore.isCheckListVisible}
      key={setGroup.setGroupId + materialSetsStore.isCheckListVisible}
      style={{ zIndex: 1000 - index }}
    >
      {({ setIsListExpanded, isListExpanded }) => (
        <Draggable
          index={index}
          draggableId={setGroup.setGroupId}
          key={setGroup.setGroupId}
          isDragDisabled={!!materialSetsStore.isCheckListVisible}
        >
          {(draggableProvided, snapshot) => (
            <div
              className="list_item_material"
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
              style={getItemStyle(snapshot.isDragging, draggableProvided.draggableProps.style)}
            >
              <div className="image_wrapper_50">
                <div className="image_border image-border" onClick={() => setIsListExpanded(!isListExpanded)} />
                {setGroup.picture ? (
                  <Picture
                    src={setGroup.pictureThumbnail}
                    width={50}
                    alt=""
                    className="image_circle_50"
                    onClick={() => setIsListExpanded(!isListExpanded)}
                  />
                ) : (
                  <GetCameraImageButton
                    buttonStyle="icon"
                    onUpdateFile={onUpdateFile}
                    description=""
                    picturesOnly
                    isAddable
                    withLoadingBar
                  />
                )}

                <CheckListItemLottie
                  setGroupId={setGroup.setGroupId}
                  onStatusChanged={onAddToCheckList}
                  notEditable={!!materialSetsStore.selectedSetCheck}
                />
              </div>
              <div className="material_info w-inline-block package-info" onClick={() => setIsListExpanded(!isListExpanded)}>
                <div className="material_text material-text">
                  <div className="link-block">{setGroup.name}</div>
                  <Tags
                    tags={setGroup.tags}
                    editable={!materialSetsStore.isCheckListVisible}
                    onTagCreate={onTagCreate}
                    onTagUpdate={onTagUpdate}
                    onTagDelete={onTagDelete}
                  />
                </div>
                <MaterialValueTags setGroupItemCount={setGroup.groupMaterials.length} fullInfo />
                {!materialSetsStore.isCheckListVisible && (
                  <Can I={actions.see} this={subject(subjectArea.setGroupMenu, { departmentId: domainStore.currentDepartment.id })}>
                    <div style={{ zIndex: 1000 }}>
                      <Popover trigger={<ArrowDotsVerticalIcon dotsOnly />}>
                        {({ handleClose, isOpen }) => (
                          <SetGroupListItemMenu
                            handleClose={handleClose}
                            isOpen={!!isOpen}
                            handleChange={onChange}
                            handleDelete={onDelete}
                            groupType={groupType}
                          />
                        )}
                      </Popover>
                    </div>
                  </Can>
                )}
              </div>
            </div>
          )}
        </Draggable>
      )}
    </ListItemDropdownAsFunction>
  );
});

export default EditableSetGroupListItem;
