/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsString, IsUUID, IsEnum, IsBoolean, IsOptional } from 'class-validator';
import 'reflect-metadata';

export enum ChapterType {
  Synonym,
  Knowledge,
  ProductInformation,
  StorageLocation
}

export class MaterialChapterDTO {
  @IsUUID()
  materialChapterId!: string;

  @IsString()
  name!: string;

  @IsEnum(ChapterType)
  chapterType!: ChapterType;

  @IsBoolean()
  disabled!: boolean;

  /**
   * isGenerated indicates if the chapter is used for special content and not normal knowledge elements.
   * e.g. for the synonym chapter.
   * These chapters cannot be deleted, just renamed and moved.
   */
  @IsBoolean()
  isGenerated!: boolean;
}

export class GetMaterialChapterDTO {
  @IsBoolean()
  @IsOptional()
  includeDisabled?: boolean;
}

export class UpdateMaterialChapterDTO {
  @IsUUID()
  materialChapterId!: string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsBoolean()
  @IsOptional()
  disabled?: boolean;
}

export class CreateMaterialChapterDTO {
  @IsString()
  name!: string;
}

export class UpdateSortMaterialChaptersDTO {
  @IsUUID(undefined, { each: true })
  materialChapterIds!: string[];
}
