import React from 'react';
import { GuideDTO } from 'dto/guide';
import BookListItem from './BookListItem';

interface Props {
  guideList: GuideDTO[];
}

const BookList = (props: Props) => {
  const routePath = (guideId: string) => {
    return `/guide/materials?guideId=${guideId}`;
  };

  return (
    <>
      {props.guideList.map(guide => (
        <BookListItem key={guide.guideId} guide={guide} routePath={routePath(guide.guideId)} />
      ))}
    </>
  );
};

export default BookList;
