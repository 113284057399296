import React from 'react';
import { OptionTypeBase, OptionsType } from 'react-select';
import { Field } from 'formik';
import Input from '../Input';
import GroupInput from './GroupInput';

interface Props extends React.HTMLProps<HTMLInputElement> {
  component?: React.ComponentType<any>;
  name: string;
  placeholder?: string;
  className?: string;
  viewOnly?: boolean;
  mappedOptions?: OptionsType<OptionTypeBase>;
  isExpanded?: boolean;
  isGrouped?: boolean;
}

const InputField = ({ component = Input, ...rest }: Props) => (
  <Field {...rest} component={GroupInput} innerComponent={component} maxLength={255} />
);
export default InputField;
