import ArrowIcon from 'components/ArrowIcon';
import { LocationDTO } from 'dto/location';
import { observer } from 'mobx-react';
import React from 'react';
import { useStores } from 'util/mobx/stores';
import './LocationsListItem.css';

interface Props {
  hover: boolean;
  location: LocationDTO;
  setShowSurgeryRooms: React.Dispatch<React.SetStateAction<boolean>>;
  isSelected?: boolean;
}

const LocationsListItem = observer(({ hover, location, setShowSurgeryRooms, isSelected = false }: Props) => {
  const { settingsStore } = useStores();

  const onLoadSurgeryRooms = () => {
    settingsStore.setSelectedLocation(location);
    setShowSurgeryRooms(true);
    if (settingsStore.selectedLocation) {
      settingsStore.loadSurgeryRooms();
    }
  };

  return (
    <div className={`list_item_name lagerort location-list-item ${isSelected ? 'is-selected' : ''}`} onClick={onLoadSurgeryRooms}>
      <div className="image_wrapper_50">
        <div className={`image_border image-border ${isSelected ? 'is-selected' : ''} ${hover ? 'is-hover' : ''}`} />
        <img src="images/picture-building.jpg" width="50" alt="" className="image_circle_50" />
      </div>
      <div className="item_name">{location.name}</div>
      <ArrowIcon />
    </div>
  );
});

export default LocationsListItem;
