import { observable, action, runInAction, computed } from 'mobx';
import {
  FullSurgeryGuideDTO,
  GroupedSurgeryGuideMaterialsDTO,
  CreateSurgeryGuideMaterialDTO,
  UpdateSurgeryGuideMaterialDTO,
  UpdateSortSurgeryGuideMaterialsDTO,
  SurgeryGuideMaterialLikeDTO
} from 'dto/surgeryGuide';
import { SetUsedMaterialDTO, UsedMaterialDTO } from 'dto/usedMaterial';
import {
  GroupedSurgeryGuideProcedureDTO,
  SurgeryProcedureDTO,
  CreateSurgeryGuideProcedureDTO,
  UpdateSurgeryGuideProcedureDTO,
  UpdateSortSurgeryGuideProceduresDTO
} from 'dto/surgeryGuideProcedure';
import {
  getSurgeryGuide,
  getSurgeryGuideProcedures,
  createSurgeryGuideProcedure,
  updateSurgeryGuideProcedure,
  reorderSurgeryGuideProcedures,
  reorderSurgeryGuideMaterials,
  getSurgeryGuideMaterials,
  createSurgeryGuideMaterial,
  deleteSurgeryGuide,
  updateSurgeryGuideMaterial,
  setUsedSurgeryGuideMaterials
} from 'api/surgeryGuide';
import { BriefingDTO } from 'dto/briefing';
import { MaterialType } from 'dto/material';
import { getSurgeryBriefing } from 'api/surgery';
import Debouncer from 'util/debouncer';
import DomainStore from './domainStore';
import LoadingStore from './loadingStore';
import { AnyMaterial } from './searchStore';

export default class SurgeryGuideStore {
  @observable
  private domainStore: DomainStore;

  @observable
  private loadingStore: LoadingStore;

  @observable
  surgeryGuide?: FullSurgeryGuideDTO;

  @observable
  briefing?: BriefingDTO;

  @observable
  groupedGuideProcedures: GroupedSurgeryGuideProcedureDTO[] = [];

  @observable
  checkableGuideProcedures: SurgeryProcedureDTO[] = [];

  @observable
  guideMaterials: GroupedSurgeryGuideMaterialsDTO[] = [];

  @observable
  selectedSurgeryProcedure?: SurgeryProcedureDTO;

  @observable
  isProcedureDrawerOpen = false;

  @observable
  isEditLotNumberFlyoutOpen = false;

  @observable
  procedureIndexInView = 0;

  @observable
  selectedProcedureChapter?: string;

  @observable
  selectedSurgeryGuideMaterialToEdit?: SurgeryGuideMaterialLikeDTO;

  @observable
  selectedUsedMaterialToEdit?: UsedMaterialDTO;

  @observable
  onLotNumberSubmit?: (lotNumber: string | undefined) => Promise<void>;

  @observable
  private usedMaterialDebouncer = new Debouncer<SetUsedMaterialDTO[], SetUsedMaterialDTO>({
    onDispatch: async ctx => {
      if (ctx === null) {
        return;
      }
      await this.loadingStore.withOnlyLoadingBar(() => setUsedSurgeryGuideMaterials(ctx));
    },
    onPostponedDispatch: async (ctx, newData) => {
      let currentCtx = ctx;
      if (currentCtx === null) {
        currentCtx = [];
      }

      currentCtx.push(newData);

      return currentCtx;
    },
    afterFinalDispatch: async (/* getLastActionTime */) => {
      if (this.surgeryGuide) {
        // Don't reload as it would collapse everything
        // await this.loadGuideMaterials(this.surgeryGuide.surgeryGuideId, () => getLastActionTime() === null);
      }
    }
  });

  constructor(domainStore: DomainStore, loadingStore: LoadingStore) {
    this.domainStore = domainStore;
    this.loadingStore = loadingStore;
  }

  @computed
  get selectedGroup(): GroupedSurgeryGuideProcedureDTO | null {
    const group = this.groupedGuideProcedures.find(
      groupedGuideProcedure => groupedGuideProcedure.chapter === this.selectedProcedureChapter
    );

    if (!group) {
      return null;
    }

    group.surgeryGuideProcedures = group.surgeryGuideProcedures.filter(surgeryGuideProcedure => surgeryGuideProcedure.content.isCheckable);

    return group;
  }

  async createSurgeryGuideProcedure(newGuideProcedure: CreateSurgeryGuideProcedureDTO) {
    const procedure = await this.loadingStore.withFlyoutLoadingBar(() => createSurgeryGuideProcedure(newGuideProcedure));
    if (this.surgeryGuide) {
      this.loadGuideProcedures(this.surgeryGuide.surgeryGuideId);
    }
    return procedure;
  }

  async updateSurgeryGuideProcedure(guideProcedure: UpdateSurgeryGuideProcedureDTO) {
    const procedure = await this.loadingStore.withFlyoutLoadingBar(() => updateSurgeryGuideProcedure(guideProcedure));
    if (this.surgeryGuide) {
      this.loadGuideProcedures(this.surgeryGuide.surgeryGuideId);
    }
    return procedure;
  }

  async loadGuide(surgeryGuideId: string) {
    await this.loadingStore.withLoadingBar(async () => {
      // set selectedSurgeryGuide to undefined so the old surgeryGuide wont show up for a short time
      this.surgeryGuide = undefined;
      const surgeryGuide = await getSurgeryGuide(surgeryGuideId);
      runInAction(() => {
        this.surgeryGuide = surgeryGuide;
      });

      const briefing = await getSurgeryBriefing({ briefingId: surgeryGuide.briefingId, includeStatistic: false });
      runInAction(() => {
        this.briefing = briefing;
      });
    });
  }

  async loadGuideProcedures(surgeryGuideId: string) {
    const groupedGuideProcedures: GroupedSurgeryGuideProcedureDTO[] = await this.loadingStore.withOnlyLoadingBar(() =>
      getSurgeryGuideProcedures({ surgeryGuideId })
    );
    let checkableGuideProcedures: SurgeryProcedureDTO[] = [];
    groupedGuideProcedures.forEach(groupedGuideProcedure => {
      checkableGuideProcedures = [
        ...checkableGuideProcedures,
        ...groupedGuideProcedure.surgeryGuideProcedures.filter(surgeryGuideProcedure => surgeryGuideProcedure.content.isCheckable)
      ];
    });
    runInAction(() => {
      this.groupedGuideProcedures = groupedGuideProcedures;
      this.checkableGuideProcedures = checkableGuideProcedures;
    });
  }

  /**
   * loadGuideMaterials refreshes the guideMaterials by calling the api.
   * @param surgeryGuideId
   * @param shouldStillUpdate If this callback exists and returns false after the api was called, the guideMaterials do NOT get updated.
   */
  async loadGuideMaterials(surgeryGuideId: string, shouldStillUpdate?: () => boolean) {
    const guideMaterials = await this.loadingStore.withOnlyLoadingBar(() => getSurgeryGuideMaterials(surgeryGuideId));
    if (shouldStillUpdate && !shouldStillUpdate()) {
      return;
    }
    runInAction(() => {
      this.guideMaterials = guideMaterials;
    });
  }

  async refreshGuideMaterials() {
    await this.loadingStore.withLoadingBar(async () => {
      if (this.surgeryGuide) {
        const surgeryGuideMaterials = await getSurgeryGuideMaterials(this.surgeryGuide.surgeryGuideId);
        runInAction(() => {
          this.guideMaterials = surgeryGuideMaterials;
        });
      }
    });
  }

  async updateProcedure(procedure: UpdateSurgeryGuideProcedureDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => updateSurgeryGuideProcedure(procedure));
    if (this.surgeryGuide) {
      this.loadGuideProcedures(this.surgeryGuide.surgeryGuideId);
    }
  }

  async reorderProcedures(positions: UpdateSortSurgeryGuideProceduresDTO[]) {
    await this.loadingStore.withLoadingBar(() => reorderSurgeryGuideProcedures(positions));
    if (this.surgeryGuide) {
      this.loadGuideProcedures(this.surgeryGuide.surgeryGuideId);
    }
  }

  async reorderMaterials(positions: UpdateSortSurgeryGuideMaterialsDTO[]) {
    await this.loadingStore.withLoadingBar(() => reorderSurgeryGuideMaterials(positions));
    if (this.surgeryGuide) {
      this.loadGuideMaterials(this.surgeryGuide.surgeryGuideId);
    }
  }

  async addMaterialToGuide(materialLike: AnyMaterial) {
    if (!this.surgeryGuide) {
      return Promise.resolve();
    }
    const categoryId = this.guideMaterials[0].categoryId;
    let guideMaterial: CreateSurgeryGuideMaterialDTO | undefined;

    // check which materialLike --> if materialSet different way of adding (see SearchMaterialSetItem)
    if (materialLike.material) {
      // checks if searchresult is a package or only a single material
      if (materialLike.material.materialType === MaterialType.instrument) {
        throw new Error('instruments cannot be added as material');
      } else {
        guideMaterial = {
          surgeryGuideId: this.surgeryGuide.surgeryGuideId,
          materialId: materialLike.material.materialId,
          guideMaterialCategoryId: categoryId,
          amount: 1,
          notes: {
            unpackOnInstruction: false
          }
        };
      }
    }
    if (materialLike.pack) {
      guideMaterial = {
        surgeryGuideId: this.surgeryGuide.surgeryGuideId,
        templateId: materialLike.pack.templateId,
        guideMaterialCategoryId: categoryId,
        amount: 1,
        notes: {
          unpackOnInstruction: false
        }
      };
    }
    if (materialLike.materialSet)
      guideMaterial = {
        surgeryGuideId: this.surgeryGuide.surgeryGuideId,
        materialSetId: materialLike.materialSet.materialSetId,
        guideMaterialCategoryId: categoryId,
        amount: 1,
        notes: {
          unpackOnInstruction: false
        }
      };

    if (guideMaterial && this.surgeryGuide) {
      await createSurgeryGuideMaterial(guideMaterial);
      this.loadGuideMaterials(this.surgeryGuide.surgeryGuideId);
    }

    return Promise.resolve();
  }

  async deleteSurgeryGuide(surgeryGuideId: string) {
    await this.loadingStore.withLoadingBar(() => deleteSurgeryGuide(surgeryGuideId));
    if (this.surgeryGuide) {
      this.loadGuideMaterials(this.surgeryGuide.surgeryGuideId);
    }
  }

  async updateSurgeryGuide(surgeryGuide: UpdateSurgeryGuideMaterialDTO) {
    await this.loadingStore.withLoadingBar(() => updateSurgeryGuideMaterial(surgeryGuide));
    if (this.surgeryGuide) {
      this.loadGuideMaterials(this.surgeryGuide.surgeryGuideId);
    }
  }

  async setUsedMaterial(usedMaterial: SetUsedMaterialDTO) {
    this.usedMaterialDebouncer.do(usedMaterial);
  }

  @action
  setSelectedSurgeryProcedure(selectedSurgeryProcedure?: SurgeryProcedureDTO) {
    this.selectedSurgeryProcedure = selectedSurgeryProcedure;
  }

  @action
  clearSelectedSurgeryProcedure() {
    this.selectedSurgeryProcedure = undefined;
  }

  @action
  setIsProcedureDrawerOpen(status: boolean) {
    this.isProcedureDrawerOpen = status;
  }

  @action
  setIsEditLotNumberFlyoutOpen(status: boolean, surgeryGuide?: SurgeryGuideMaterialLikeDTO, usedMaterial?: UsedMaterialDTO) {
    this.isEditLotNumberFlyoutOpen = status;
    this.selectedSurgeryGuideMaterialToEdit = surgeryGuide;
    this.selectedUsedMaterialToEdit = usedMaterial;
  }

  @action
  setIsEditLotNumberFlyoutOpenV2(
    status: boolean,
    usedMaterial?: UsedMaterialDTO,
    onSubmit?: (lotNumber: string | undefined) => Promise<void>
  ) {
    this.isEditLotNumberFlyoutOpen = status;
    this.selectedUsedMaterialToEdit = usedMaterial;
    this.onLotNumberSubmit = onSubmit;
  }

  @action
  setProcedureIndexInView(procedureIndexInView: number) {
    this.procedureIndexInView = procedureIndexInView;
  }

  @action
  setOnLotNumberSubmit(onSubmit: (lotNumber: string | undefined) => Promise<void>) {
    this.onLotNumberSubmit = onSubmit;
  }

  @action
  selectProcedureChapter(chapter: string) {
    if (this.selectedProcedureChapter === chapter) {
      return;
    }
    this.selectedProcedureChapter = chapter;
    this.procedureIndexInView = 0;
  }

  @action
  clearProcedures() {
    this.groupedGuideProcedures = [];
  }

  @action
  clearMaterials() {
    this.guideMaterials = [];
  }
}
