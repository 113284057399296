import React from 'react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import SideMenuIconButton from 'components/SideMenuIconButton';

interface Props {
  groupType: string;
  checkListDisabled: boolean;
}

const MaterialSetGroupsRightMenuMobile = observer(({ groupType, checkListDisabled }: Props) => {
  const { t } = useTranslation(groupType);

  const { materialSetsStore } = useStores();

  return (
    <>
      {!materialSetsStore.isCheckListVisible && !checkListDisabled && (
        <SideMenuIconButton
          disabled={materialSetsStore.isCheckListVisible}
          handleClick={() => materialSetsStore.setIsCheckListFlyoutOpen(true)}
          label={t('groupsList.rightMenu.showControls')}
          iconPath="a-check.svg"
        />
      )}
    </>
  );
});

export default MaterialSetGroupsRightMenuMobile;
