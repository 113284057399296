import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import PopoverV2 from 'components/PopoverV2/PopoverV2';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onOpenKnowledge?: () => void;
  onOpenKnowledgeAndAttitudes?: () => void;
  onAddLotNumber?: () => void;
  onChangePicture?: () => void;
  onDelete?: () => void;
  dotsOnly?: boolean;
  hoverContainerId?: string;
}

export const GuideMaterialMenu = ({
  onOpenKnowledge,
  onOpenKnowledgeAndAttitudes,
  onChangePicture,
  onAddLotNumber,
  onDelete,
  dotsOnly = false,
  hoverContainerId
}: Props) => {
  const { t } = useTranslation('guide');

  if (!onOpenKnowledge && !onOpenKnowledgeAndAttitudes && !onChangePicture && !onAddLotNumber && !onDelete) {
    return <></>;
  }

  return (
    <PopoverV2 hoverContainerId={hoverContainerId} customTrigger={dotsOnly ? <ArrowDotsVerticalIcon dotsOnly /> : undefined}>
      {({ handleClose, isOpen }) => (
        <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
          {onOpenKnowledge && (
            <ItemMenuButton
              propagate
              isInPopover
              label={t('guideMenu.openKnowledge')}
              icon="icon_background_16.svg"
              handleClick={onOpenKnowledge}
            />
          )}

          {onOpenKnowledgeAndAttitudes && (
            <ItemMenuButton
              propagate
              isInPopover
              label={t('guideMenu.knowledgeAndAttitudes')}
              icon="icon_search-content_16.svg"
              handleClick={onOpenKnowledgeAndAttitudes}
            />
          )}

          {onChangePicture && (
            <ItemMenuButton
              propagate
              isInPopover
              label={t('guideMenu.changePicture')}
              icon="icon_background_16.svg"
              handleClick={onChangePicture}
            />
          )}

          {onAddLotNumber && (
            <ItemMenuButton
              propagate
              isInPopover
              label={t('guideMenu.addLotNumber')}
              icon="icon_i-edit_16.svg"
              handleClick={onAddLotNumber}
            />
          )}

          {onDelete && (
            <ItemMenuButton
              isInPopover
              label={t('guideMenu.delete')}
              icon="icon_bin_16.svg"
              warning
              handleClick={() => {
                handleClose();
                onDelete();
              }}
            />
          )}
        </ItemMenu>
      )}
    </PopoverV2>
  );
};
