import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorageLocationPathDTO, LocationSource } from 'dto/storageLocation';
import Button from 'components/Form/Button';
import { useStores } from 'util/mobx/stores';
import LocationBox from '../LocationBox';

interface Props {
  materialStorageLocations: StorageLocationPathDTO[];
}

const StorageLocationPathInMaterial = ({ materialStorageLocations }: Props) => {
  const { storageLocationStore, guideDetailDrawerStore, guideStore, domainStore } = useStores();
  const [locations, setLocations] = useState<JSX.Element[]>([]);
  const { t } = useTranslation('storageLocationItems');

  const onFlyoutOpen = async () => {
    const { selectedMaterialSet, selectedMaterial } = guideDetailDrawerStore;

    storageLocationStore.openSelectMaterialLocationFlyout({
      materialId: guideDetailDrawerStore?.selectedGuideItem?.material?.materialId || selectedMaterial?.materialId,
      materialSetId: guideDetailDrawerStore?.selectedGuideItem?.materialSet?.materialSetId || selectedMaterialSet?.materialSetId
    });
  };

  useEffect(() => {
    // First filter the paths by not ERP and correct functionalArea.
    // For this we only look at the first location of the path, as they should all have the same.
    const filteredPaths = materialStorageLocations.filter(path => {
      return (
        path.storageLocations.length > 0 &&
        path.storageLocations[0].locationSource !== LocationSource.ERP &&
        path.storageLocations[0].functionalAreaId === (guideStore.selectedGuide?.functionalAreaId || domainStore.currentFunctionalArea.id)
      );
    });

    if (filteredPaths.length > 0) {
      // Then, if there are still paths, transform the first path into an array of location boxes.
      setLocations(
        filteredPaths[0].storageLocations.map((location, i) => {
          return (
            <LocationBox
              storageLocation={location}
              key={location.storageLocationId}
              last={i === filteredPaths[0].storageLocations.length - 1}
            />
          );
        })
      );
    } else {
      setLocations([]);
    }
  }, [materialStorageLocations, domainStore.currentFunctionalArea.id, guideStore.selectedGuide]);

  if (locations.length === 0) {
    return <></>;
  }

  return (
    <div>
      <div className="post_item no_margin">
        <div className="post_box material_detail">
          <div className="flex_horizontal">
            <img src="images/icon_pennant_16_grey.svg" alt="" className="icon_big_flag" />
            <div>{t('materialPathTitle')}</div>
          </div>
          <div className="field_location top_margin location-boxes-container">{locations}</div>
          {!(
            guideDetailDrawerStore.selectedSurgeryGuideItem?.template ||
            guideDetailDrawerStore.selectedGuideItem?.template ||
            guideDetailDrawerStore.selectedPackage
          ) && (
            <Button className="btn_small margin_bottom" type="button" onClick={onFlyoutOpen}>
              <div className="txt_dropout_1">{t('buttons.showAll')}</div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StorageLocationPathInMaterial;
