/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsString, IsUUID, Length, IsOptional, ValidateNested, IsNotEmptyObject, IsDate } from 'class-validator';
import 'reflect-metadata';
import { Type } from 'class-transformer';
import { UserDTO } from './user';
import { GuideChapterDTO } from './guideChapter';
import { SimpleSurgeryGuideMaterialLikeDTO } from './surgeryGuide';

export enum HistoryActionType {
  create = 'c',
  update = 'u',
  delete = 'd',
  notify = 'n'
}

export class HistoryDTO {
  @IsUUID()
  historyId!: string;

  /**
   * gives information on where the changes will be made f.E: guide
   */
  @IsString()
  domain!: string;

  /**
   * what has been made (u: Update, c: Create, d: Delete)
   */
  @IsString()
  @Length(1, 1)
  action!: HistoryActionType;

  /**
   * which Material was changed, created, deleted
   */
  @IsString()
  targetName!: string;

  /**
   * who made this change
   */
  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => UserDTO)
  user!: UserDTO;

  /**
   *  what field (fE: amount, guideCategory) of the targetName ( fE: Material) was changed
   */
  @IsOptional()
  @IsString()
  field?: string;

  /**
   *  value of the field that has been changed
   */
  @IsOptional()
  @IsString()
  dataValue?: string;

  /**
   *  the value the field had before the change
   */
  @IsOptional()
  @IsString()
  previousDataValue?: string;

  /**
   * in which where the changes made .. (fE:guideId)
   */
  @IsUUID()
  objectId!: string;

  @IsDate()
  @Type(() => Date)
  createdAt!: Date;
}

export class GetBriefingHistoryDTO {
  @IsUUID()
  briefingId!: string;
}

export class SurgeryGuideMaterialHistoryDTO {
  @ValidateNested()
  @Type(() => SimpleSurgeryGuideMaterialLikeDTO)
  surgeryGuideMaterial!: SimpleSurgeryGuideMaterialLikeDTO;

  @ValidateNested({ each: true })
  @Type(() => HistoryDTO)
  materialHistories!: HistoryDTO[];
}

export class SurgeryGuideProcedureHistoryDTO {
  @ValidateNested()
  @Type(() => GuideChapterDTO)
  guideChapter!: GuideChapterDTO;

  @ValidateNested({ each: true })
  @Type(() => HistoryDTO)
  procedureHistories!: HistoryDTO[];
}

export class SurgeryGuideHistoryDTO {
  @IsUUID()
  surgeryGuideId!: string;

  @ValidateNested({ each: true })
  @Type(() => SurgeryGuideMaterialHistoryDTO)
  materialHistories!: SurgeryGuideMaterialHistoryDTO[];

  @ValidateNested({ each: true })
  @Type(() => SurgeryGuideProcedureHistoryDTO)
  procedureHistories!: SurgeryGuideProcedureHistoryDTO[];
}

export class BriefingHistoryDTO {
  /**
   * briefingHistories includes the histories relative to a briefing.
   * For example the guides added / guides removed.
   */
  @ValidateNested({ each: true })
  @Type(() => HistoryDTO)
  briefingHistories!: HistoryDTO[];

  /**
   * guideHistories includes the histories for the guides.
   */
  @ValidateNested({ each: true })
  @Type(() => SurgeryGuideHistoryDTO)
  surgeryGuideHistories!: SurgeryGuideHistoryDTO[];
}
