import React from 'react';
import { observer } from 'mobx-react';
import { UserDTO } from 'dto/user';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import Popover from 'components/Popover';
import ItemMenu from 'components/ItemMenu';
import ImageCircle from 'components/ImageCircle';
import { PictureDTO, OptionalPictureDTO } from 'dto/file';
import GetCameraImageButton from 'components/GetCameraImageButton';
import { IconType } from 'dto/procedure';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import PostMediaList from './PostMediaList';
import { MaterialLikeIdDTO } from '../../dto/material';

import './PostItem.css';

interface Props extends OptionalLazyLoadProps {
  title?: string;
  children: JSX.Element | JSX.Element[];
  files?: ContentElementMediaDTO[];
  menu?: JSX.Element | JSX.Element[] | false;
  user?: UserDTO;
  picture?: PictureDTO | OptionalPictureDTO;
  isCheckable?: boolean;
  menuClassName?: string;
  radioButton?: JSX.Element;
  iconType: IconType;
  onImageClick?: () => void;
  materialType?: MaterialLikeIdDTO;
  withUserInfoBoxLeft?: boolean;
}

const PostItem = observer(
  ({
    title,
    children,
    files = [],
    menu,
    user,
    isCheckable,
    menuClassName = '',
    radioButton,
    picture,
    iconType = IconType.UserPicture,
    onImageClick,
    withUserInfoBoxLeft = false,
    lazyLoadScrollContainer
  }: Props) => {
    const postItemClassName = !radioButton ? 'post_item post-item' : 'post_item post-item in_flyout';
    const postBoxClassName = !radioButton ? 'post_box' : 'post_box in_flyout';

    const renderPictureComp = () => {
      if (iconType === IconType.None) {
        return <></>;
      }
      if (picture?.picture === undefined && iconType === IconType.MaterialPicture) {
        return <GetCameraImageButton buttonStyle="postItem" description="" picturesOnly openDetail={onImageClick} />;
      }
      if ((user || picture) && iconType === IconType.UserPicture) {
        return (
          <ImageCircle
            lazyLoadScrollContainer={lazyLoadScrollContainer}
            user={user}
            highlight={false}
            size="40"
            className="image_circle_40_post"
            noWrapper
            pictures={picture && [picture]}
            withUserInfoBoxLeft={withUserInfoBoxLeft}
            onClick={onImageClick}
          />
        );
      }
      return (
        <ImageCircle
          lazyLoadScrollContainer={lazyLoadScrollContainer}
          highlight={false}
          size="40"
          className="image_circle_40_post"
          noWrapper
          pictures={picture && [picture]}
          onClick={onImageClick}
        />
      );
    };

    return (
      <div className={postItemClassName}>
        <div className="dotted-line" />
        {radioButton && <div className="flyout_checkbox_prozedur">{radioButton}</div>}
        <div className={`${postBoxClassName} ${menuClassName}`}>
          {renderPictureComp()}
          {isCheckable && <img src="images/icon_checkable_22.svg" alt="" className="icon_checkable" />}
          {menu && (
            <div className={`btn_show_detail_post ${menuClassName}`}>
              <Popover trigger={<img src="images/icon_menu_dots.svg" alt="" className="image_dots_post image-dots-post" />}>
                {({ handleClose, isOpen }) => (
                  <ItemMenu handleClose={handleClose} isOpen={!!isOpen}>
                    {menu}
                  </ItemMenu>
                )}
              </Popover>
            </div>
          )}
          <div className="h2">{title}</div>
          <div className="txt_content">{children}</div>
          <PostMediaList lazyLoadScrollContainer={lazyLoadScrollContainer} files={files} />
        </div>
      </div>
    );
  }
);

export default PostItem;
