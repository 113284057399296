import React, { useEffect, useState } from 'react';
import GetCameraImageButton from 'components/GetCameraImageButton';
import { FileMetadataDTO, ContentType } from 'dto/file';
import { useTranslation } from 'react-i18next';
import HelperImageDragAndDrop from 'components/HelperImageDragAndDrop';
import DropzoneContainer, { OnDropCallback } from 'components/DropzoneContainer';
import PreviewImage from 'components/PreviewImage';

interface Props {
  setImageId: (filed: string) => void;
  saveFile: (formData: FormData) => Promise<any>;
  cameraImageButtonDescription: string;
  cameraImageButtonDescriptionAlready: string;
  picturesOnly?: boolean;
  accept?: ContentType[];
  imageSrc?: string;
  withoutHelper?: boolean;
  withoutGet?: boolean;
  userButtonStyle?: boolean;
}

export default ({
  setImageId,
  saveFile,
  cameraImageButtonDescription,
  cameraImageButtonDescriptionAlready,
  picturesOnly,
  accept,
  imageSrc,
  withoutHelper = false,
  withoutGet = false,
  userButtonStyle
}: Props) => {
  const [image, setImage] = useState<FileMetadataDTO>();
  const { t } = useTranslation('itemFormFlyOut');

  useEffect(() => {
    return () => {
      if (image && image.fullUrl) {
        window.URL.revokeObjectURL(image.fullUrl);
      }
    };
  });

  const onSaveFile = async (file: File) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const response = await saveFile(bodyFormData);
    if (response) {
      setImageId(response.fileId);

      const fileUrl = window.URL.createObjectURL(file);

      if (image && image.fullUrl) {
        window.URL.revokeObjectURL(image.fullUrl);
      }

      if (withoutGet) {
        setImage({
          ...response,
          thumbnailUrl: fileUrl,
          fullUrl: fileUrl
        });
        return;
      }

      setImage(response);
    }
  };

  const onDrop: OnDropCallback = async acceptedFiles => {
    const file = acceptedFiles[0];
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const response = await saveFile(bodyFormData);
    if (response) {
      setImage(response);
      setImageId(response.fileId);
    }
  };

  const buttonStyle = userButtonStyle ? 'user' : 'circle';

  return (
    <DropzoneContainer onDrop={onDrop} className="div-block-41" accept={accept}>
      <>{image && <PreviewImage src={image.thumbnailUrl ? image.thumbnailUrl : '#'} />}</>
      <>{!image && imageSrc && <PreviewImage src={imageSrc} />}</>

      <GetCameraImageButton
        picturesOnly={picturesOnly}
        buttonStyle={buttonStyle}
        isButtonVisible={!image && !imageSrc}
        description={image ? cameraImageButtonDescriptionAlready : cameraImageButtonDescription}
        onSaveFile={onSaveFile}
        isAddable
      />
      {!withoutHelper ? (
        <HelperImageDragAndDrop
          title={t('uploadFiles.title')}
          description={picturesOnly ? t('uploadFiles.descriptionForPicturesOnly') : t('uploadFiles.description')}
        />
      ) : (
        <></>
      )}
    </DropzoneContainer>
  );
};
