import React from 'react';
import { useTranslation } from 'react-i18next';
import { PostDTO } from 'dto/post';
import { getDateWording } from 'util/date';
import ImageCircle from 'components/ImageCircle';
import PostMediaList from 'components/PostItem/PostMediaList';

import './CommentItem.css';

interface Props {
  comment: PostDTO;
  withDate?: boolean;
}

const CommentItem = ({ comment, withDate = false }: Props) => {
  const date = getDateWording(comment.createdAt, useTranslation('date'));
  return (
    <>
      {withDate && <div className="txt_chapter_flyout">{date}</div>}
      <div className="post_item in_flyout">
        <div className="post_box in_flyout_board">
          <ImageCircle
            className="image_circle_40_post"
            size="40"
            user={comment?.contentElement.user}
            highlight={false}
            noWrapper
            withUserInfoBoxLeft
          />
          <div className="txt_content">{comment?.contentElement.description}</div>
          <PostMediaList files={comment.contentElement.contentElementMedias} />
        </div>
      </div>
    </>
  );
};

export default CommentItem;
