import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import Video from 'components/Video/Video';
import { ContentType } from 'dto/file';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';

import './PreviewMediaBox.css';
import ImageEditor from 'components/ImageEditor';
import PreviewPictureBox from '../PreviewPictureBox';

interface Props {
  file: ContentElementMediaDTO;
  onDeleteImage: (imageId: string) => void;
  updateImageLabeling?: (fileId: string, pictureLabelling: string, newFile: Blob) => void;
  index?: number;
  onMediaLabelChange?: (mediaId: string, text: string) => void;
  progress?: number;
}

export default ({ file, onDeleteImage, index = 1, updateImageLabeling, onMediaLabelChange, progress }: Props) => {
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);

  return (
    <>
      {file.contentType === ContentType.Picture ? (
        <>
          <PreviewPictureBox
            file={file}
            onDeleteImage={onDeleteImage}
            index={index}
            updateImageLabeling={updateImageLabeling}
            onMediaLabelChange={onMediaLabelChange}
            progress={progress}
          />
        </>
      ) : (
        <Draggable draggableId={file.fileId} index={index}>
          {provided => (
            <>
              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <div className="post_image post-image">
                  <Video file={file} uploadProgress={progress} onMediaLabelChange={onMediaLabelChange} onDeleteImage={onDeleteImage} />
                </div>
              </div>
            </>
          )}
        </Draggable>
      )}

      {updateImageLabeling && (
        <ImageEditor
          image={file}
          isOpen={isImageEditorOpen}
          onClose={() => setIsImageEditorOpen(false)}
          updateImageLabeling={updateImageLabeling}
        />
      )}
    </>
  );
};
