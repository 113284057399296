import { GetGuideMaterialsDTO } from 'dto/guide';
import { useStores } from 'util/mobx/stores';
import { useQuery } from '@tanstack/react-query';
import { LoadingType } from 'stores/loadingStore';
import { ApiQueryOptions } from './query';
import { getGuideMaterialsV2 } from './guide';

export const useGetGuideMaterialsV2 = (params: GetGuideMaterialsDTO, options: ApiQueryOptions = {}) => {
  const { loadingStore } = useStores();
  const { loadingType, ...queryOptions } = options;
  return useQuery({
    queryKey: ['any-guide', 'guide', 'materials', params],
    queryFn: async () => {
      return loadingStore.withSpecificLoadingBar(loadingType || LoadingType.DEFAULT, () => getGuideMaterialsV2(params));
    },
    ...queryOptions
  });
};
