/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { observer } from 'mobx-react';
import { FullGuideDTO } from 'dto/guide';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';

import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import PopoverV2 from 'components/PopoverV2/PopoverV2';

interface Props {
  guide: FullGuideDTO;
  actions?: JSX.Element;
}

const SingleInstrumentActions = observer(({ guide }: Props) => {
  const { guideStore, searchStore, instrumentStore } = useStores();
  const { t } = useTranslation('singleInstruments');
  const { t: searchTranslation } = useTranslation('materialSearch');

  const handleSwitchSearchClick = async () => {
    searchStore.openSearchPanel(m => {
      if (instrumentStore.fullPackageItem?.template.templateId) {
        guideStore.replaceMaterialInGuide(m, { templateId: instrumentStore.fullPackageItem.template.templateId }).then(() => {
          if (instrumentStore.fullPackageItem) {
            instrumentStore.loadTemplateGuides(instrumentStore.fullPackageItem?.template.templateId);
          }
        });
      }
    }, searchTranslation('searchItem.replace'));
  };

  const handleDeleteGuideMaterialClick = async () => {
    if (instrumentStore.fullPackageItem?.template.templateId) {
      await guideStore.deleteGuideMaterials({
        guideId: guide.guideId,
        templateId: instrumentStore.fullPackageItem?.template.templateId
      });

      instrumentStore.loadTemplateGuides(instrumentStore.fullPackageItem?.template.templateId);
    }
  };

  return (
    <PopoverV2 customTrigger={<img src="images/icon_menu_dots.svg" alt="" className="image_dots_post image-dots-post" />}>
      {({ handleClose, isOpen }) => (
        <>
          <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
            <ItemMenuButton
              label={t('globalFunctions.dropdownMenu.replaceMaterial')}
              icon="icon_find_16.svg"
              isInPopover
              handleClick={handleSwitchSearchClick}
            />
            <ItemMenuButton
              label={t('globalFunctions.dropdownMenu.deleteMaterial')}
              icon="icon_bin_16.svg"
              warning
              isInPopover
              handleClick={handleDeleteGuideMaterialClick}
            />
          </ItemMenu>
        </>
      )}
    </PopoverV2>
  );
});

export default SingleInstrumentActions;
