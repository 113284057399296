import React from 'react';
import Button from 'components/Form/Button';
import './FlyoutButtonBar.css';

interface Props {
  closePopUp: () => void;
  onSubmit?: () => void;
  cancelLabel?: string;
  submitLabel?: string;
  isAllowedToSubmit?: boolean;
  warning?: boolean;
  closeAsSubmit?: boolean;
  tip?: JSX.Element;
  isLoading?: boolean;
}

export default (props: Props) => {
  const generateClassName = (): string => {
    if (!props.isAllowedToSubmit) {
      return 'btn_big_done_inactive';
    }
    if (props.warning) {
      return 'btn_big_done-copy';
    }
    return 'btn_big_done no-margin-left';
  };

  return (
    <div className="button_bar button-bar">
      {props.cancelLabel && (
        <Button className={props.closeAsSubmit ? generateClassName() : 'btn_big_cancel'} type="button" onClick={() => props.closePopUp()}>
          {props.cancelLabel}
        </Button>
      )}
      {props.submitLabel && (
        <Button
          isLoading={props.isLoading}
          style={{ display: 'block' }}
          className={generateClassName()}
          type="button"
          disabled={!props.isAllowedToSubmit}
          onClick={() => props.onSubmit && props.onSubmit()}
        >
          {props.submitLabel}
        </Button>
      )}
      {props.tip && props.tip}
    </div>
  );
};
