import { AccountDTO } from 'dto/user';
import { patch } from './common';
import { validate } from './validation';

export const updateDepartment = async (departmentId: string): Promise<AccountDTO | false> => {
  const account = await patch('/account/department', { departmentId }).then(response => response.data);
  if (account === false) {
    return false;
  }
  return validate(AccountDTO, account);
};
