import { ConfigType } from './configType';

const config: ConfigType = {
  apiPath: 'https://dev-backend.klinik-puls.de:443/api',
  strict: true,
  jwt: {
    refreshInMilliSeconds: 360000
  },
  devMode: false
};


export default config;
