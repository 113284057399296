import React from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import LinkWrapper from 'components/LinkWrapper';
import Picture from 'components/Picture';
import { MaterialDTO } from 'dto/material';
import MaterialValueTags from 'components/MaterialValueTags';
import GetCameraImageButton from 'components/GetCameraImageButton';

interface Props {
  material: MaterialDTO;
  routePath: string;
  lazyLoadScrollContainer?: string;
}

const MaterialListItem = observer(({ material, routePath, lazyLoadScrollContainer }: Props) => {
  const { materialStore, guideStore, domainStore } = useStores();
  const onUpdateFile = async (fileId: string) => {
    await materialStore.updateMaterial({ materialId: material.materialId, pictureFileId: fileId });
    await materialStore.loadLatestManualMaterials(domainStore.currentDepartment.id);
    if (guideStore.selectedGuide) {
      guideStore.loadGuideMaterials(guideStore.selectedGuide.guideId);
    }
  };
  const onClickHandler = () => {
    materialStore.loadMaterial(material.materialId);
  };
  return (
    <>
      <div className="list-item list_item_material">
        <div className="item_dropdown">
          <img src="images/icon_s-edit_17.svg" width={16} alt="" className="image_arrow_dropdown hidden" />
        </div>
        <div className="image_wrapper_50">
          <div className="image_border image-border" />
          {material.picture ? (
            <Picture
              src={material.pictureThumbnail}
              width={50}
              alt=""
              lazyLoadScrollContainer={lazyLoadScrollContainer}
              className="image_circle_50"
            />
          ) : (
            <GetCameraImageButton buttonStyle="icon" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable withLoadingBar />
          )}
        </div>
        <LinkWrapper onClick={onClickHandler} routePath={routePath} fullWidth>
          <div className="material_info">
            <div className="material_text">
              <div>{material.name}</div>
            </div>
            <MaterialValueTags material={material} />
            <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-arrow-list" />
          </div>
        </LinkWrapper>
      </div>
    </>
  );
});

export default MaterialListItem;
