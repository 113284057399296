import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import NavSectionButton from 'components/NavSection/NavSectionButton';
import { useStores } from 'util/mobx/stores';

const SurgeryNavigation = observer(() => {
  const { t } = useTranslation('surgery');
  const { surgeryStore, domainStore } = useStores();

  const { shortName: functionalAreaShortName } = domainStore.currentFunctionalArea;

  const goPrevious = () => surgeryStore.goToPreviousSurgery();

  const goNext = () => surgeryStore.goToNextSurgery();

  const onOpenPlaner = () => surgeryStore.setIsPlannerDrawerOpen(true);

  const selectedSurgeryIndex = surgeryStore.getSelectedSurgeryIndex();

  return (
    <>
      {selectedSurgeryIndex !== undefined && surgeryStore.surgeriesInSelectedRoom && (
        <>
          <NavSectionButton
            disabled={selectedSurgeryIndex === 0}
            icon="icon_check-out_16"
            className="btn_section auto_width disable-hover"
            label={t('menu.previous')}
            onClick={goPrevious}
          />
          <NavSectionButton
            disabled={selectedSurgeryIndex >= surgeryStore.surgeriesInSelectedRoom.length - 1}
            icon="icon_calender_dayafter_16"
            className="btn_section auto_width disable-hover"
            label={t('menu.next')}
            onClick={goNext}
          />
        </>
      )}

      <NavSectionButton
        icon="icon_calendar-date-2_16"
        className="btn_section auto_width"
        label={t('menu.openPlaner', { functionalAreaShortName })}
        onClick={onOpenPlaner}
      />
    </>
  );
});

export default SurgeryNavigation;
