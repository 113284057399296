import React from 'react';
import { DateDTO } from 'dto/surgery';
import Button from 'components/Form/Button';
import { useTranslation } from 'react-i18next';
import { parseFromDateTime, dateFormat, getDayName } from 'util/date';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';

interface Props {
  date: DateDTO;
}

const DateFilterButton = observer(({ date }: Props) => {
  const { t } = useTranslation('date');
  const { surgeryStore } = useStores();

  const parsedDate = parseFromDateTime(date);
  const isButtonActive = () => {
    const { selectedFilterDate: d } = surgeryStore;
    if (!d) return false;
    if (d.dayInMonth === date.dayInMonth && d.month === date.month && d.year === date.year) {
      return true;
    }
    return false;
  };
  return (
    <Button className={`btn_filter in_op-plan ${isButtonActive() ? 'active' : ''}`} onClick={() => surgeryStore.selectFilterDate(date)}>
      <div>
        <strong>{getDayName(t, date)}</strong>
        <br />
        {parsedDate?.format(dateFormat)}
      </div>
    </Button>
  );
});

export default DateFilterButton;
