import { updateMaterial } from 'api/material';
import MaterialValueTags from 'components/MaterialValueTags/MaterialValueTags';
import { PackageDTO } from 'dto/package';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { ItemPicture } from '..';
import { ListItemRendererProps } from '../MaterialListItem';
import { TemplateMenuRenderer } from './TemplateMenuRenderer';

interface Props<T> {
  parentEntity: T;
  ListItemRenderer: React.FC<ListItemRendererProps<T>>;

  level?: number;

  prefix?: React.ReactElement;
  pack: PackageDTO;
  onClick?: (parent: T, id: { packageId?: string; materialId?: string }) => void;
  onReload?: () => Promise<void>;

  MenuRenderer?: TemplateMenuRenderer<T>;
}

export function GenericSinglePackageItem<T extends { currentPackageId?: string; currentInstrumentId?: string }>({
  parentEntity,
  ListItemRenderer,
  pack,
  level = 1,
  prefix,
  onClick,
  onReload,
  MenuRenderer
}: Props<T>) {
  const { t } = useTranslation('guideMaterials'); // TODO: move translation
  const instrument = pack.instruments[0];

  const { loadingStore } = useStores();

  const handlePackageClick = useCallback(() => {
    if (!onClick) {
      return;
    }
    onClick(parentEntity, { packageId: pack.packageId });
  }, [onClick, pack.packageId, parentEntity]);

  const handleUpdateFile = useCallback(
    async (fileId: string) => {
      if (!instrument) {
        return;
      }
      await loadingStore.withLoadingBar(() => updateMaterial({ materialId: instrument.material.materialId, pictureFileId: fileId }));
      if (onReload) {
        await onReload();
      }
    },
    [instrument, loadingStore, onReload]
  );

  return instrument ? (
    <ListItemRenderer
      parentEntity={parentEntity}
      level={level}
      noExpandIconSpace
      amount={instrument?.amount || 0}
      name={instrument?.material.name || t('missingInstrument')}
      picture={<ItemPicture picture={instrument?.material} onUpdateFile={handleUpdateFile} />}
      onClick={instrument && handlePackageClick}
      end={<MaterialValueTags pack={pack} />}
      prefix={prefix}
      menu={MenuRenderer && <MenuRenderer parent={parentEntity} specificId={{ packageId: pack.packageId }} />}
    />
  ) : null;
}
