import React from 'react';
import FlyoutContainer from 'components/FlyoutContainer';
import { useStores } from 'util/mobx/stores';
import { DepartmentDTO } from 'dto/department';
import { useTranslation } from 'react-i18next';
import './BaseSetDepartmentFlyout.css';
import { observer } from 'mobx-react';

export interface BaseSetDepartmentFlyoutProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Props extends BaseSetDepartmentFlyoutProps {
  flyoutHeadline?: string;

  hasOtherLeaderClass: string;
  isLeaderClass: string;
  isMaintainerClass: string;

  hasOtherLeaderText: string;
  isLeaderText: string;
  isMaintainerText: string;

  onSelectDepartment: (departmentId: string, isLeader: boolean, isMaintainer: boolean, hasOtherLeader: boolean) => void;
}

const BaseSetDepartmentFlyout = observer(
  ({
    isOpen,
    onClose,
    flyoutHeadline,
    hasOtherLeaderClass,
    isLeaderClass,
    isMaintainerClass,
    hasOtherLeaderText,
    isLeaderText,
    isMaintainerText,
    onSelectDepartment
  }: Props) => {
    const { domainStore, userStore } = useStores();
    const { t } = useTranslation('accountManagement');

    function renderDepartmentList(department: DepartmentDTO) {
      if (userStore.selectedUser === undefined) {
        return <></>;
      }

      const userId = userStore.selectedUser.userId;

      const isLeader = department.departmentMaintainers.filter(dM => dM.userId === userId && dM.isLead).length !== 0;
      const isMaintainer = department.departmentMaintainers.filter(dM => dM.userId === userId && !dM.isLead).length !== 0;
      const hasOtherLeader = department.departmentMaintainers.filter(dM => dM.userId !== userId && dM.isLead).length !== 0;

      return (
        <>
          {department.departmentMaintainers && (
            <div
              onClick={() => onSelectDepartment(department.departmentId, isLeader, isMaintainer, hasOtherLeader)}
              key={department.departmentId}
              className={`btn_in_list space-items ${isLeader ? isLeaderClass : ''} ${isMaintainer ? isMaintainerClass : ''} ${
                hasOtherLeader ? hasOtherLeaderClass : ''
              }`}
            >
              <div>{department.name}</div>
              <div>
                {isLeader ? isLeaderText : ''}
                {isMaintainer ? isMaintainerText : ''}
                {hasOtherLeader ? hasOtherLeaderText : ''}
              </div>
            </div>
          )}
        </>
      );
    }

    return (
      <FlyoutContainer
        className="switch-domain-flyout"
        isOpen={isOpen}
        closePopUp={onClose}
        title={flyoutHeadline}
        icon={<img src="images/icon_edit_fachbereich.jpg" width="50" alt="" className="image_circle_40" />}
        cancelLabel={t('userDetail.button.cancel')}
      >
        <div className="flyout_box">
          <div>{t('userDetail.setDepartmentCharge.label.department')}</div>
          <div className="menu_list" style={{ overflow: 'auto', maxHeight: 320 }}>
            {domainStore.departments.map(departmentsByLocation => (
              <React.Fragment key={`${departmentsByLocation.functionalAreaId}${departmentsByLocation.locationId}`}>
                <div className="txt_section_nav">
                  {departmentsByLocation.locationShortName} | {departmentsByLocation.functionalAreaShortName}
                </div>
                {departmentsByLocation.departments.map(renderDepartmentList)}
              </React.Fragment>
            ))}
          </div>
        </div>
      </FlyoutContainer>
    );
  }
);

export default BaseSetDepartmentFlyout;
