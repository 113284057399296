export const GUIDE = '/guide';
export const GUIDES = '/guides';
export const STARTPOINT = GUIDES;
export const TEST = '/test';
export const ACCOUNTMANAGEMENT = '/accountManagement/users';
export const SETTINGS = '/settings/administration';
export const USERSETTINGS = '/user-settings/data';
export const REPORTS = '/reports';
export const MATERIAL = '/material';
export const INSTRUMENTS = '/instruments';
export const PACKAGE = '/package';
export const INSTRUMENT = '/instrument';
export const SINGLE_INSTRUMENT = '/singleInstrument';
export const GROUP = '/group';
export const ADMINISTRATION_LOCATION = '/administration/location';
export const SURGERY_BRIEFING = '/surgery/briefing';
export const SURGERY_GUIDE = '/surgery-guide';
