import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import LinkWrapper from 'components/LinkWrapper';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Button from 'components/Form/Button';
import { AbilityContext, actions, subjectArea } from 'casl/setupCaslAbility';
import './MaterialDrawerDetailNav.css';
import HoverWrapper from 'components/HoverWrapper';
import { observer } from 'mobx-react';
import { subject } from '@casl/ability';
import { useAbility } from '@casl/react';

interface Props {
  onShowSettings?: () => void;
  detailLink: string;
  knowledgeLink: string;
  onUpdateFile: (fileId: string) => Promise<void>;
  isGuideMaterial: boolean;
  isSurgeryGuideMaterial: boolean;
}

const MaterialDrawerDetailNav = observer(
  ({ onShowSettings = () => null, detailLink, knowledgeLink, onUpdateFile, isGuideMaterial, isSurgeryGuideMaterial }: Props) => {
    const { t } = useTranslation('guideMaterialSearchDetail');
    const { guideDetailDrawerStore, knowledgeStore, searchStore, domainStore } = useStores();
    const closeDrawer = () => {
      guideDetailDrawerStore.setIsOpen(false);
      searchStore.closeSearchPanel();
    };

    const onCreateKnowledgeFlyoutOpen = useCallback(() => {
      knowledgeStore.setDataForNewMaterialKnowledge(undefined, 0);
      knowledgeStore.setIsMaterialKnowledgeFormFlyoutOpen(true);

      guideDetailDrawerStore.setIsOpen(false);
      searchStore.closeSearchPanel();
    }, [knowledgeStore, guideDetailDrawerStore, searchStore]);

    const ability = useAbility(AbilityContext);

    const settingsEnabled =
      isSurgeryGuideMaterial ||
      (isGuideMaterial &&
        ability.can(actions.see, subject(subjectArea.materialSettings, { departmentId: domainStore.currentDepartment.id })));

    return (
      <div className="div-block-23">
        <LinkWrapper routePath={detailLink} className="btn_material_detail btn-material-detail w-inline-block" onClick={closeDrawer}>
          <img src="images/icon_search-content_16_grey.svg" alt="" className="image_mat_detail image-mat-detail" />
          <Button>{t('button.moreAboutMaterial')}</Button>
        </LinkWrapper>
        <LinkWrapper
          routePath={knowledgeLink}
          className="btn_material_detail btn-material-detail w-inline-block"
          onClick={onCreateKnowledgeFlyoutOpen}
        >
          <img src="images/icon_f-add_16_grey.svg" alt="" className="image_mat_detail image-mat-detail" />
          <div>{t('button.addKnowledge')}</div>
        </LinkWrapper>
        <HoverWrapper>
          {({ hover }) => (
            <>
              <GetCameraImageButton
                isButtonVisible
                buttonStyle="menu"
                description={t('button.changePicture')}
                onUpdateFile={onUpdateFile}
                picturesOnly
                isAddable
                hover={hover}
              />
            </>
          )}
        </HoverWrapper>

        {settingsEnabled && (
          <Button className="btn_material_detail btn-material-detail mobile_only w-inline-block mobile-only" onClick={onShowSettings}>
            <img src="images/icon_settings-gear_16.svg" alt="" className="image_mat_detail image-mat-detail" />
            <div>{t('button.materialSettings')}</div>
          </Button>
        )}
      </div>
    );
  }
);

export default MaterialDrawerDetailNav;
