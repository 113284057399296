import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import CommentInput from 'components/Form/CommentInput';
import { CreatePostContentElementDTO, OptionalContentElementDTO } from 'dto/contentElement';
import { useStores } from 'util/mobx/stores';
import ImageCircle from 'components/ImageCircle';
import { FormikProps, FormikValues } from 'formik';
import { observer } from 'mobx-react';
import { MixedContentElementMediaDTO } from 'components/UploadMultipleFiles/MixedContentElementMediaDTO';

import { ProcessingStatus } from 'dto/file';
import { UpdatePostDTO } from 'dto/post';
import PictureHelper from '../PictureHelper';
import GetCameraImageButtonWrapper from '../GetCameraImageButtonWrapper';

interface Props {
  placeholder: string;
  onCreate?: (post: CreatePostContentElementDTO) => void;
  onUpdate?: (post: UpdatePostDTO) => void;
}

interface InternalChatFormProps extends FormikProps<OptionalContentElementDTO> {
  placeholder: string;
  onChatClose: (form?: FormikProps<FormikValues>) => void;
}

const InternalChatForm = observer(({ placeholder, onChatClose, ...form }: InternalChatFormProps) => {
  const { t } = useTranslation('chat');
  const { postStore, authStore } = useStores();
  const [uploadedFiles, setUploadedFiles] = useState<MixedContentElementMediaDTO[]>([]);

  useEffect(() => {
    if (postStore.isChatBarExpanded) {
      form.resetForm({
        values: {
          description: postStore.selectedPost?.contentElement.description || '',
          contentElementMedias: postStore.selectedPost?.contentElement.contentElementMedias || []
        }
      });
    } else {
      form.resetForm({
        values: {
          description: '',
          contentElementMedias: []
        }
      });
    }
    // including 'form' leads to a cycle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postStore.isChatBarExpanded, postStore.selectedPost, form.resetForm]);

  const onChatOpen = () => {
    postStore.setIsChatBarExpanded(true);
    postStore.setIsChatFlyoutOpen(true);
    postStore.setIsChatFlyoutCollapsible(true);
  };

  return (
    <>
      <PictureHelper />
      <div className="div-block-56">
        <ImageCircle className="image_circle_chat_avatar image-circle-chat-avatar" user={authStore.user} size="40" highlight={false} />
        <GetCameraImageButtonWrapper meta={form} isExpanded={postStore.isChatBarExpanded} isAddable />
      </div>
      <div className="div-block-73 chat-bar-form-container">
        <CommentInput
          meta={form}
          name="description"
          isExpanded={postStore.isChatBarExpanded}
          placeholder={placeholder}
          onInputClick={onChatOpen}
          setUploadedFiles={setUploadedFiles}
          uploadedFiles={uploadedFiles}
        />
        <div className="div-block-74">
          <Button className="btn_msg_cancel" type="button" onClick={() => onChatClose(form)}>
            {t('inputForm.buttons.abort')}
          </Button>

          <Button
            disabled={
              !form.dirty ||
              !form.values.description ||
              (uploadedFiles && uploadedFiles.filter(uF => uF.status === ProcessingStatus.Uploading).length > 0)
            }
            className="btn_msg_send"
          >
            {t('inputForm.buttons.publish')}
          </Button>
        </div>
      </div>
    </>
  );
});

const ChatForm = observer(({ placeholder, onCreate, onUpdate }: Props) => {
  const { postStore } = useStores();

  const onChatClose = (form?: FormikProps<FormikValues>) => {
    postStore.setIsChatBarExpanded(false);
    postStore.setIsChatFlyoutOpen(false);
    if (form) form.resetForm();
    postStore.resetSelectedPost();
    setTimeout(() => {
      postStore.setIsChatFlyoutCollapsible(false);
    }, 600);
  };

  const handleSubmit = async ({ description, contentElementMedias }: CreatePostContentElementDTO) => {
    const content = {
      description,
      contentElementMedias: contentElementMedias ? contentElementMedias.map(e => ({ fileId: e.fileId, title: e.title })) : []
    };
    if (onUpdate && postStore.selectedPost) {
      await onUpdate({
        postId: postStore.selectedPost.postId,
        content
      });
    } else if (onCreate) {
      await onCreate(content);
    }

    onChatClose();
  };

  return (
    <Form
      initialValues={{
        description: '',
        contentElementMedias: []
      }}
      onSubmit={handleSubmit}
    >
      {form => <InternalChatForm {...form} placeholder={placeholder} onChatClose={onChatClose} />}
    </Form>
  );
});

export default ChatForm;
