import { useGetGroupMaterials } from 'api/groupMaterialHooks';
import { updateSetGroup } from 'api/setGroup';
import { Note } from 'components/Instructions/Instructions';
import MaterialValueTags from 'components/MaterialValueTags/MaterialValueTags';
import { GroupMaterialDTO } from 'dto/groupMaterial';
import { GuideMaterialLikeFlatDTO } from 'dto/guide';
import { PackagingType } from 'dto/package';
import { SetGroupWithTagsDTO } from 'dto/setGroup';
import { useGuideMaterialListContext } from 'modules/guideMaterial/guideMaterialListContext';
import { MultiTemplateItem } from 'modules/materialSet/ListItems/Instrument/MultiTemplateItem';
import { SingleTemplateItem } from 'modules/materialSet/ListItems/Instrument/SingleTemplateItem';
import { MaterialSetListContext, MaterialSetListState } from 'modules/materialSet/materialSetListContext';
import React, { useCallback, useMemo, useState } from 'react';
import { LoadingType } from 'stores/loadingStore';
import { useStores } from 'util/mobx/stores';
import { ItemPicture } from '../../../material/ListItems';
import { GuideMaterialListItem } from '../GuideMaterialListItem';
import { MaterialItem } from '../Material/MaterialItem';
import { GuideMaterialCheckPrefixRenderer } from '../checkRenderer';
import './SetGroupItem.css';

interface Props {
  setGroup: SetGroupWithTagsDTO;
  guideMaterial: GuideMaterialLikeFlatDTO;
  onAddLotNumber?: () => void;
  GroupMaterialPrefixRenderer?: GuideMaterialCheckPrefixRenderer;
}

export const SetGroupItem = ({ setGroup, guideMaterial, onAddLotNumber, GroupMaterialPrefixRenderer }: Props) => {
  const { onReload, onGuideMaterialClick } = useGuideMaterialListContext();
  const { loadingStore } = useStores();
  const [enabled, setEnabled] = useState(false);

  const handleExpand = useCallback(() => {
    setEnabled(true);
  }, []);

  const handleGroupMaterialClick = useCallback(
    (groupMaterial: GroupMaterialDTO, overrideSpecific?: { packageId?: string; materialId?: string }) => () => {
      if (!onGuideMaterialClick) {
        return;
      }
      if (overrideSpecific?.materialId) {
        onGuideMaterialClick(guideMaterial, { materialId: overrideSpecific.materialId });
        return;
      }

      if (overrideSpecific?.packageId) {
        onGuideMaterialClick(guideMaterial, { packageId: overrideSpecific.packageId });
        return;
      }

      if (groupMaterial.material) {
        onGuideMaterialClick(guideMaterial, { materialId: groupMaterial.material.materialId });
      } else {
        throw new Error('NOT IMPLEMENTED');
      }
    },
    [guideMaterial, onGuideMaterialClick]
  );

  const setListState = useMemo(
    (): MaterialSetListState => ({
      checksEnabled: false,
      groupType: 'unknown',
      readonly: true,
      onClickItem: (groupMaterial: GroupMaterialDTO, specific?: { packageId?: string; materialId?: string }) => {
        handleGroupMaterialClick(groupMaterial, specific)();
      }
    }),
    [handleGroupMaterialClick]
  );

  const { data, isInitialLoading } = useGetGroupMaterials(setGroup.setGroupId, {
    enabled,
    loadingType: LoadingType.ONLY_LOADING
  });

  const groupMaterials = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.groupMaterials.map(groupMaterial => {
      if (groupMaterial.template && groupMaterial.template.packagingType === PackagingType.Multi) {
        return (
          <MultiTemplateItem
            classNamePrefix="set-in-guide"
            groupMaterial={groupMaterial}
            setGroupId={setGroup.setGroupId}
            key={groupMaterial.groupMaterialId}
            level={2}
            prefix={
              GroupMaterialPrefixRenderer && (
                <GroupMaterialPrefixRenderer
                  guideMaterialId={guideMaterial.guideMaterialId}
                  groupMaterialId={groupMaterial.groupMaterialId}
                />
              )
            }
          />
        );
      }
      if (groupMaterial.template && groupMaterial.template.packagingType === PackagingType.Single) {
        return (
          <SingleTemplateItem
            classNamePrefix="set-in-guide"
            groupMaterial={groupMaterial}
            setGroupId={setGroup.setGroupId}
            key={groupMaterial.groupMaterialId}
            level={2}
            prefix={
              GroupMaterialPrefixRenderer && (
                <GroupMaterialPrefixRenderer
                  guideMaterialId={guideMaterial.guideMaterialId}
                  groupMaterialId={groupMaterial.groupMaterialId}
                />
              )
            }
          />
        );
      }
      if (groupMaterial.material) {
        return (
          <MaterialItem
            key={groupMaterial.groupMaterialId}
            level={2}
            noAmount
            onClick={handleGroupMaterialClick(groupMaterial)}
            material={groupMaterial.material}
            guideMaterial={guideMaterial}
            onAddLotNumber={onAddLotNumber}
            prefix={
              GroupMaterialPrefixRenderer && (
                <GroupMaterialPrefixRenderer
                  guideMaterialId={guideMaterial.guideMaterialId}
                  groupMaterialId={groupMaterial.groupMaterialId}
                />
              )
            }
            hideInstructions
          />
        );
      }

      return <>NOT IMPLEMENTED</>;
    });
  }, [GroupMaterialPrefixRenderer, data, guideMaterial, handleGroupMaterialClick, onAddLotNumber, setGroup.setGroupId]);

  const handleUpdateFile = async (fileId: string) => {
    await loadingStore.withLoadingBar(() => updateSetGroup({ setGroupId: setGroup.setGroupId, pictureFileId: fileId }));
    if (onReload) {
      await onReload();
    }
  };

  const materialGroupTags = useMemo(() => setGroup.tags.map(tag => <Note key={tag.tagId} title="" value={tag.name} />), [setGroup.tags]);

  return (
    <GuideMaterialListItem
      level={1}
      onDropdownExpand={handleExpand}
      dropdownItems={<MaterialSetListContext.Provider value={setListState}>{groupMaterials}</MaterialSetListContext.Provider>}
      isInitialLoading={isInitialLoading}
      name={setGroup.name}
      picture={<ItemPicture picture={setGroup} onUpdateFile={handleUpdateFile} />}
      instructions={<div className="note_wrapper">{materialGroupTags}</div>}
      end={<MaterialValueTags setGroupItemCount={setGroup.itemCount} fullInfo />}
      onAddLotNumber={onAddLotNumber}
    />
  );
};
