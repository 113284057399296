import React from 'react';
import Switch from 'react-switch';
import { FormikProps, FormikValues } from 'formik';

interface Props {
  meta: FormikProps<FormikValues>;
  name: string;
}

const CustomSwitch = ({ meta, name }: Props) => {
  const onChange = (value: boolean) => {
    meta.setFieldValue(name, value);
  };
  return (
    <Switch
      onColor="#3adf27"
      offColor="#4d7cfe"
      uncheckedIcon={false}
      checkedIcon={false}
      width={40}
      height={13}
      onChange={onChange}
      checked={meta.values[name]}
    />
  );
};

export default CustomSwitch;
