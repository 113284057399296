import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export function useInvalidateAnyGuide(): () => Promise<void> {
  const queryClient = useQueryClient();

  return useCallback(async () => queryClient.invalidateQueries(['any-guide']), [queryClient]);
}

export function useInvalidateMaterialLike(): () => Promise<void> {
  const queryClient = useQueryClient();

  return useCallback(async () => queryClient.invalidateQueries(['any-guide']), [queryClient]);
}

export function useInvalidateGuideMaterialCategories(): () => Promise<void> {
  const queryClient = useQueryClient();

  return useCallback(async () => queryClient.invalidateQueries(['guide-material-categories']), [queryClient]);
}

export function useInvalidateGroupMaterials(): () => Promise<void> {
  const queryClient = useQueryClient();

  return useCallback(async () => queryClient.invalidateQueries(['material-like', 'material-set']), [queryClient]);
}
