import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Button from 'components/Form/Button';
import { useTranslation } from 'react-i18next';
import Form, { InputField } from 'components/Form';
import { CheckTempPasswordDTO } from 'dto/user';
import { ErrorMessage } from 'formik';
import './LoginForgotPassword.css';
import { checkTempPassword } from 'api/auth';

interface Props {
  show: boolean;
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
  setShowForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setShowResetPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setTmpPasswordWithUsername: React.Dispatch<React.SetStateAction<CheckTempPasswordDTO | undefined>>;
}

const LoginForgotPassword = ({
  show = false,
  setShowLogin,
  setShowForgotPassword,
  setShowResetPassword,
  setTmpPasswordWithUsername
}: Props) => {
  const { t: translationLogin } = useTranslation('login');
  const { t: translationSignup } = useTranslation('signup');
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  useEffect(() => {
    setShowErrorMsg(false);
  }, [show]);

  const switchToResetPassword = () => {
    setShowForgotPassword(false);
    setShowResetPassword(true);
  };

  const switchToLogin = () => {
    setShowForgotPassword(false);
    setShowLogin(true);
  };

  const initialValues: CheckTempPasswordDTO = {
    username: '',
    tempPassword: ''
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .required(translationSignup('validation.fieldRequired'))
      .max(255, translationSignup('validation.textLength')),
    tempPassword: Yup.string()
      .required(translationSignup('validation.fieldRequired'))
      .max(255, translationSignup('validation.textLength'))
  });

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  const handleSubmitMyForm = () => {
    if (submitMyForm) submitMyForm();
  };

  const onSubmit = (data: CheckTempPasswordDTO) => {
    checkTempPassword(data).then(res => {
      if (!res) {
        setShowErrorMsg(true);
      } else {
        setTmpPasswordWithUsername(data);
        switchToResetPassword();
      }
    });
  };

  return (
    <>
      <div className={`div-block-170 ${!show ? 'hide' : ''}`}>
        <div className="div-block-178">
          <div className="div-block-171">
            <img src="images/logo_klinik-puls.svg" alt="" />
            <div className="txt_logo-copy">
              <strong>{translationLogin('logoTextFirstPart')}</strong>
              {translationLogin('logoTextSecondPart')}
            </div>
          </div>
          <div className="div-block-181">
            <div className="pw_form">
              <div className="headline-login">{translationLogin('forgotPassword.headline')}</div>
              <div className="h3">{translationLogin('forgotPassword.instructions')}</div>
              <ul className="list-2">
                <li className="list-item">{translationLogin('forgotPassword.instruction1')}</li>
                <li className="list-item">{translationLogin('forgotPassword.instruction2')}</li>
                <li className="list-item">{translationLogin('forgotPassword.instruction3')}</li>
                <li className="list-item">{translationLogin('forgotPassword.instruction4')}</li>
              </ul>
              <div className="line" />

              {show ? ( // force re-render of form when show changes to true
                <Form initialValues={initialValues} onSubmit={onSubmit} bindSubmitForm={bindSubmitForm} validationSchema={validationSchema}>
                  {props => {
                    return (
                      <>
                        <div className="w-form">
                          {showErrorMsg ? (
                            <div className="error-container">
                              <div />
                              <div className="failure repositioned">{translationLogin('errorMessage')}</div>{' '}
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="form-2">
                            <div className="login_input_box">
                              <label htmlFor="username" className="field-label-2">
                                {translationLogin('username')}
                              </label>
                              <InputField type="text" max-length="256" name="username" />
                              <ErrorMessage component={errProps => <div {...errProps} />} className="field_error" name="username" />
                            </div>
                            <div className="login_input_box">
                              <label htmlFor="tempPassword" className="field-label-2">
                                {translationLogin('forgotPassword.temporaryPassword')}
                              </label>
                              <InputField type="password" max-length="256" name="tempPassword" />
                              <ErrorMessage component={errProps => <div {...errProps} />} className="field_error" name="tempPassword" />
                            </div>
                          </div>
                        </div>
                        <div className="button_bar-copy">
                          <Button className="btn_big_cancel" type="button" onClick={switchToLogin}>
                            {translationLogin('cancel')}
                          </Button>
                          <Button
                            className="btn_big_done-copy"
                            disabled={!(props.isValid && props.dirty)}
                            type="button"
                            onClick={handleSubmitMyForm}
                          >
                            {translationLogin('submit')}
                          </Button>
                        </div>
                      </>
                    );
                  }}
                </Form>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForgotPassword;
