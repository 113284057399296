import { version } from 'constants/version';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './VersionNumber.css';

export default function VersionNumber({ type }: { type?: 'mobile' | 'desktop' }) {
  const { t } = useTranslation('version');

  return (
    <div className={`${type}-only`}>
      <br />
      <div className={`${type}-version-number`}>{`${t('versionNumber')}: ${version}`}</div>
    </div>
  );
}
