import React from 'react';
import ArrowIcon from 'components/ArrowIcon';
import ImageCircle from 'components/ImageCircle';
import { ExtendedUserDTO, UserStatus } from 'dto/user';
import UserRoleTag from 'components/UserRoleTag';

interface Props {
  user: ExtendedUserDTO;
  hover?: boolean;
  selected?: boolean | null;
  className?: string;
}

const UserListItemBody = (props: Props) => {
  return (
    <>
      <ImageCircle highlight={!!(props.hover || props.selected)} user={props.user} />
      <div className={`item_name with-button ${props.className}`}>
        <div className="div-block-137">
          <div className="div-block-138">
            <div className="t2 bottom-margin">{props.user.title}</div>
            <div>
              {props.user.lastName}, {props.user.firstName}
            </div>
          </div>
          {props.user.status === UserStatus.Active && <UserRoleTag user={props.user} />}
        </div>
        <ArrowIcon hover={props.hover} />
      </div>
    </>
  );
};

export default UserListItemBody;
