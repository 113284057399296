import React from 'react';
import { ProcessingStatus, ContentType } from 'dto/file';
import Lottie, { LottieProps } from 'react-lottie';
import { useTranslation } from 'react-i18next';
import Picture from 'components/Picture';
import animationData from '../../Video/videoLoading.json';
import { ReactComponent as IconLoading } from '../../Video/videoLoading_16.svg';
import './ThumbnailMediaProgressTag.css';

const iconWarning = 'images/icon_c-warning_16.svg';
const iconWait = 'images/icon_time-clock_16_white.svg';

interface Props {
  status: ProcessingStatus;
  uploadProgress?: number;
  contentType: ContentType;
  small?: boolean;
}

const defaultOptions: LottieProps['options'] = {
  loop: true,
  autoplay: true,
  animationData
};

const ThumbnailMediaProgressTag = ({ status, uploadProgress, contentType, small = false }: Props) => {
  const { t: videoT } = useTranslation('video');
  const { t: pictureT } = useTranslation('itemFormFlyOut');
  const t = contentType === ContentType.Video ? videoT : pictureT;

  const Tag = () => {
    switch (status) {
      case ProcessingStatus.Aborted:
        return (
          <>
            <Picture className="image_video_tag" src={iconWarning} />
            <div>{t('status.aborted')}</div>
          </>
        );
      case ProcessingStatus.Finished:
        return <></>;
      case ProcessingStatus.Pending:
        return (
          <>
            <Picture className="image_video_tag" src={iconWait} />
            <div>{t('status.pending')}</div>
          </>
        );
      case ProcessingStatus.Processing:
        return (
          <>
            <div className="lotti_video_opt">
              <Lottie options={defaultOptions} direction={1} speed={1.5}>
                <IconLoading className="image_video_tag" />
              </Lottie>
            </div>

            <div>{t('status.processing')}</div>
          </>
        );
      case ProcessingStatus.Uploading:
        return (
          <>
            <div className="lotti_video_opt">
              <Lottie options={defaultOptions} direction={1} speed={1.5}>
                <IconLoading className="image_video_tag" />
              </Lottie>
            </div>
            <div>{`${t('status.uploading')} ${uploadProgress !== undefined ? `${uploadProgress}%` : ''}`}</div>
          </>
        );
      case ProcessingStatus.Error:
      default:
        return (
          <>
            <Picture className="image_video_tag" src={iconWarning} />
            <div>{t('status.error')}</div>
          </>
        );
    }
  };

  return (
    <div className={`tag_video_convert tag-video-convert ${small ? 'small' : ''}`}>
      <Tag />
    </div>
  );
};

export default ThumbnailMediaProgressTag;
