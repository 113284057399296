// eslint-disable-next-line import/no-cycle
import {
  cloneGuide,
  cloneGuidesMaterial,
  createGuide,
  createGuideMaterial,
  deleteGuideMaterial,
  deleteGuideMaterials,
  deleteWholeGuides,
  exportAllGuides,
  exportGuide,
  getAdditionalGuidesByUser,
  getGuide,
  getGuideMaterials,
  getGuides,
  getGuidesByDepartment,
  relinkGuidesMaterial,
  reorderGuideMaterials,
  updateGuide
} from 'api/guide';
import {
  CloneGuideDTO,
  CloneGuideMaterialsDTO,
  CreateGuideDTO,
  CreateGuideMaterialDTO,
  DeleteGuideDTO,
  DeleteGuideMaterialDTO,
  DepartmentGuidesDTO,
  GroupedGuideMaterialsDTO,
  GuideDTO,
  UpdateGuideDTO,
  UpdateSortGuideMaterialsDTO,
  UserGuidesDTO
} from 'dto/guide';
import { GuideMaterialIdDTO, MaterialLikeIdDTO, MaterialType } from 'dto/material';
import { UserDTO } from 'dto/user';
import { action, computed, observable, runInAction } from 'mobx';

import { getGuideMaterialHistory } from 'api/history';
import { HistoryDTO } from 'dto/history';
import { BulkCopyRelinkProcedureDTO } from 'dto/procedure';
import { downloadFile, filterFileName } from 'util/download';

import { buildNewGuideMaterial } from 'modules/guideMaterial/utils';
import DomainStore from './domainStore';
import LoadingStore from './loadingStore';
import { AnyMaterial } from './searchStore';

export default class GuideStore {
  @observable
  private domainStore: DomainStore;

  @observable
  private loadingStore: LoadingStore;

  @observable // guides of current department
  guides: UserGuidesDTO[] = [];

  @observable
  selectedGuide?: GuideDTO;

  @observable // guides of selected user
  additionalGuides: DepartmentGuidesDTO[] = [];

  @observable
  selectedGuideOwner?: UserDTO;

  @observable
  showAdditionalGuides = false;

  @observable
  guideMaterials: GroupedGuideMaterialsDTO[] = [];

  @observable
  deleteGuideFlyout: {
    isOpen: boolean;
    userId?: string;
    departmentId?: string;
    guideId?: string;
  } = { isOpen: false };

  @observable
  isGuideFlyoutOpen = false;

  @observable
  isCloneGuideMaterialFlyoutOpen = false;

  @observable
  guideMaterialsToClone?: CloneGuideMaterialsDTO;

  @observable
  guideCategoryIdForAddMaterial?: string;

  @observable
  selectedGuideCloneFlyout?: GuideDTO;

  @observable
  selectedGuideOwnerCloneFlyout?: UserDTO;

  @observable
  additionalGuidesClone: DepartmentGuidesDTO[] = [];

  @observable
  isCloneGuideProcedureFlyoutOpen = false;

  @observable
  guidesClone: UserGuidesDTO[] = [];

  @observable
  history?: HistoryDTO[];

  @observable
  guideProcedureToClone?: BulkCopyRelinkProcedureDTO;

  @observable
  isCloneMaterialKnowledgeFlyout = false;

  @observable
  private lastLoadedGuidesDepartmentId?: string;

  @observable
  private lastLoadedAdditionalGuidesUserId?: string;

  @observable
  private onReload?: () => Promise<void>;

  @computed
  get defaultNextGuideOwner(): UserDTO {
    if (typeof this.selectedGuideOwner?.userId === 'string') {
      return this.guideOwnerList.find(user => user.userId === this.selectedGuideOwner?.userId) || new UserDTO();
    }
    return new UserDTO();
  }

  @computed
  get guideOwnerList() {
    const guideOwnerList: UserDTO[] = this.guides.map(userGuide => userGuide.user);
    return guideOwnerList;
  }

  @computed
  get guideOwnerListClone(): UserDTO[] {
    const guideOwnerListClone: UserDTO[] = Array.from(new Set(this.guidesClone.map(guide => guide.user.userId)))
      .map(userId => {
        const resultUser = this.guidesClone.filter(guide => guide.user.userId === userId);
        return resultUser[0].user;
      }) // Sort as it is otherwise very random in which order the users are.
      .sort((user1, user2) => `${user1.lastName} ${user1.firstName}`.localeCompare(`${user2.lastName} ${user2.firstName}`));

    return guideOwnerListClone;
  }

  @computed
  get selectedGuides(): GuideDTO[] {
    let selectedGuides: GuideDTO[] = [];
    const selectedUserId = this.selectedGuideOwner?.userId;
    if (selectedUserId) {
      const relevantGuides = this.guides.find(userGuide => userGuide.user.userId === selectedUserId);
      if (relevantGuides) {
        relevantGuides.departments.forEach(userGuide => {
          if (
            userGuide.department.functionalAreaShortName === this.domainStore.currentFunctionalArea.shortName &&
            userGuide.department.locationShortName === this.domainStore.currentLocation.shortName
          ) {
            selectedGuides = [...selectedGuides, ...userGuide.guides];
          }
        });
      }
    }

    return selectedGuides.sort((a, b) => a.name.localeCompare(b.name));
  }

  @computed
  get selectedGuidesClone(): DepartmentGuidesDTO[] {
    let selectedGuides: DepartmentGuidesDTO[] = [];
    const selectedUserId = this.selectedGuideOwnerCloneFlyout?.userId;
    if (selectedUserId) {
      const relevantGuides = this.additionalGuidesClone.filter(
        userGuide =>
          userGuide.department.functionalAreaShortName === this.domainStore.currentFunctionalArea.shortName &&
          userGuide.department.locationShortName === this.domainStore.currentLocation.shortName
      );

      if (relevantGuides) {
        selectedGuides = relevantGuides;
      }
    }
    return selectedGuides;
  }

  constructor(domainStore: DomainStore, loadingStore: LoadingStore) {
    this.domainStore = domainStore;
    this.loadingStore = loadingStore;
  }

  exportGuide = async (): Promise<void> => {
    if (!this.selectedGuide) {
      return;
    }

    const localUrl = await exportGuide(this.selectedGuide.guideId).then(pdf => {
      const blob = new Blob([pdf], { type: 'octet/stream' });
      return window.URL.createObjectURL(blob);
    });

    const fileName = filterFileName(`${this.selectedGuideOwner?.lastName} - ${this.selectedGuide.name}.pdf`);

    downloadFile({ url: localUrl, fileName });
  };

  exportAllGuides = async (): Promise<{ url: string; fileName: string } | undefined> => {
    const localUrl = await exportAllGuides().then(zip => {
      const blob = new Blob([zip], { type: 'octet/stream' });
      return window.URL.createObjectURL(blob);
    });

    const fileName = filterFileName(`backup.zip`);

    return { url: localUrl, fileName };
  };

  @action
  /**
   * setSelectedGuideOwner searches for the guide owner in all guides to get the UserDTO.
   * This DTO is set to the selectedGuideOwner.
   */
  setSelectedGuideOwnerFromGuides = (guideOwnerId: string) => {
    let user: UserDTO | undefined;

    this.guides.forEach(guides => {
      if (guides.user.userId === guideOwnerId) {
        user = guides.user;
      }
    });

    if (user === undefined) {
      throw new Error('the guide owner was not found inside of the guides');
    }

    this.resetGuidesList();
    this.selectedGuideOwner = user;
  };

  @action
  toggleShowAdditionalGuides() {
    this.showAdditionalGuides = !this.showAdditionalGuides;
  }

  @action
  hideDeleteGuideFlyout = () => {
    this.deleteGuideFlyout.guideId = undefined;
    this.deleteGuideFlyout.userId = undefined;
    this.deleteGuideFlyout.departmentId = undefined;

    this.deleteGuideFlyout.isOpen = false;
  };

  @action
  showDeleteGuideFlyout = (guideId?: string, userId?: string, departmentId?: string) => {
    this.deleteGuideFlyout.guideId = guideId;
    this.deleteGuideFlyout.userId = userId;
    this.deleteGuideFlyout.departmentId = departmentId;

    this.deleteGuideFlyout.isOpen = true;
  };

  @action
  setShowGuideFlyout = (isOpen: boolean) => {
    this.isGuideFlyoutOpen = isOpen;
  };

  @action
  async setSelectedGuide(guide?: GuideDTO) {
    this.selectedGuide = guide;
  }

  @action
  async setSelectedGuideOwner(owner?: UserDTO) {
    this.selectedGuideOwner = owner;
  }

  async loadGuidesByDepartment(departmentId: string) {
    const guides = await this.loadingStore.withLoadingBar(() => getGuidesByDepartment(departmentId));
    runInAction(() => {
      this.lastLoadedGuidesDepartmentId = departmentId;
      this.guides = guides;
    });
  }

  async loadGuides() {
    const guides = await this.loadingStore.withLoadingBar(() => getGuides());
    runInAction(() => {
      this.guides = guides;
    });
  }

  async loadAdditionalGuidesByUser(userId: string) {
    const userGuides: UserGuidesDTO[] = await this.loadingStore.withLoadingBar(() => getAdditionalGuidesByUser(userId));
    const relevantGuides = userGuides.find(userGuide => userGuide.user.userId === userId);
    let departmentGuides: DepartmentGuidesDTO[] = [];
    if (relevantGuides) {
      this.lastLoadedAdditionalGuidesUserId = userId;
      departmentGuides = relevantGuides.departments.filter(
        entry => entry.department.departmentId !== this.domainStore.currentDepartment.id
      );
    }

    runInAction(() => {
      this.additionalGuides = departmentGuides;
    });
  }

  async loadGuideMaterials(guideId: string) {
    const guideMaterials = await this.loadingStore.withLoadingBar(() => getGuideMaterials(guideId));
    runInAction(() => {
      this.guideMaterials = guideMaterials;
    });
  }

  @action
  async clearGuideMaterials() {
    this.guideMaterials = [];
  }

  async refreshGuides() {
    if (this.lastLoadedGuidesDepartmentId) {
      await this.loadGuidesByDepartment(this.lastLoadedGuidesDepartmentId);
    }

    if (this.lastLoadedAdditionalGuidesUserId) {
      await this.loadAdditionalGuidesByUser(this.lastLoadedAdditionalGuidesUserId);
    }

    const guideMaterials = await this.loadingStore.withLoadingBar(() => {
      if (this.selectedGuide?.guideId) {
        return getGuideMaterials(this.selectedGuide.guideId);
      }
      return Promise.resolve([]);
    });
    runInAction(() => {
      this.guideMaterials = guideMaterials;
    });
  }

  async refreshGuideMaterials() {
    const guideMaterials = await this.loadingStore.withLoadingBar(() => {
      if (this.selectedGuide?.guideId) {
        return getGuideMaterials(this.selectedGuide.guideId);
      }
      return Promise.resolve([]);
    });
    runInAction(() => {
      this.guideMaterials = guideMaterials;
    });
  }

  @action
  async reorderGuideMaterials(newGuideMaterial: GroupedGuideMaterialsDTO[]) {
    // preset the new order for a smooth ui
    this.guideMaterials = newGuideMaterial;

    const positions: UpdateSortGuideMaterialsDTO[] = newGuideMaterial.map(guideMaterialGroup => {
      const guideMaterialIds: string[] = [];
      guideMaterialGroup.materials.forEach(m => {
        guideMaterialIds.push(m.guideMaterialId);
      });
      return {
        categoryId: guideMaterialGroup.categoryId,
        guideMaterialIds
      };
    });

    // send new order to server
    await this.loadingStore.withLoadingBar(() => reorderGuideMaterials(positions));
  }

  /**
   * loadSelectedGuideById loads a specific guid using the api
   * @param guideId
   */
  async loadSelectedGuideById(guideId: string) {
    const guide = await this.loadingStore.withLoadingBar(() => getGuide(guideId));
    runInAction(() => {
      this.setSelectedGuide(guide);
      this.setSelectedGuideOwner(guide.user);
    });
  }

  createGuide(departmentId: string, name: string, ownerId: string) {
    const guide: CreateGuideDTO = {
      departmentId,
      name,
      ownerId
    };
    return this.loadingStore.withFlyoutLoadingBar(() => createGuide(guide));
  }

  async deleteGuide() {
    const toDelete: DeleteGuideDTO = {
      ownerId: this.deleteGuideFlyout.userId,
      departmentId: this.deleteGuideFlyout.departmentId,
      guideId: this.deleteGuideFlyout.guideId
    };
    await this.loadingStore.withFlyoutLoadingBar(() => deleteWholeGuides(toDelete));
    this.loadGuidesByDepartment(this.domainStore.currentDepartment.id);
  }

  async updateGuide(guideToUpdate: UpdateGuideDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => updateGuide(guideToUpdate));
    return this.loadGuidesByDepartment(this.domainStore.currentDepartment.id);
  }

  async createGuideMaterial(guideMaterial: CreateGuideMaterialDTO) {
    await this.loadingStore.withLoadingBar(() => createGuideMaterial(guideMaterial));
    runInAction(() => {
      this.guideCategoryIdForAddMaterial = undefined;
    });
    return this.loadGuideMaterials(guideMaterial.guideId);
  }

  /**
   * delete exactly one guide material
   * @param guideMaterialToDelete
   * @param guideId
   */
  async deleteGuideMaterial(guideMaterialToDelete: GuideMaterialIdDTO, guideId: string) {
    await this.loadingStore.withLoadingBar(() => deleteGuideMaterial(guideMaterialToDelete));
    return this.loadGuideMaterials(guideId);
  }

  /**
   * delete a material-like from one guide (which may occur several times there)
   * @param guideMaterialToDelete
   */
  async deleteGuideMaterials(guideMaterialToDelete: DeleteGuideMaterialDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => deleteGuideMaterials(guideMaterialToDelete));
  }

  @action
  setShowCloneGuideMaterialFlyout = (isOpen: boolean, onReload?: () => Promise<void>) => {
    if (isOpen) {
      this.onReload = onReload;
    } else if (onReload) {
      this.onReload = undefined;
    }
    this.isCloneGuideMaterialFlyoutOpen = isOpen;
  };

  @action
  setGuideMaterialsToClone = (targetGuideId: string) => {
    this.guideMaterialsToClone = { targetGuideId, sourceGuideId: '' };
  };

  async cloneGuidesMaterial(guideMaterialsToClone: CloneGuideMaterialsDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => cloneGuidesMaterial(guideMaterialsToClone));

    runInAction(() => {
      this.guideMaterialsToClone = undefined;
    });

    if (this.onReload) {
      return this.onReload();
    }
    return this.loadGuideMaterials(guideMaterialsToClone.targetGuideId);
  }

  async cloneGuide(guideClone: CloneGuideDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => cloneGuide(guideClone));
    this.refreshGuides();
  }

  @action
  setGuideCategoryIdForAddMaterial = (categoryId: string) => {
    this.guideCategoryIdForAddMaterial = categoryId;
  };

  @action
  resetGuidesList = () => {
    this.selectedGuideOwner = undefined;
    this.additionalGuides = [];
    this.showAdditionalGuides = false;
    this.lastLoadedAdditionalGuidesUserId = undefined;
    this.lastLoadedGuidesDepartmentId = undefined;
  };

  @action
  setSelectedGuideOwnerFromGuidesCloneFlyout = (guideOwnerId: string) => {
    let user: UserDTO | undefined;

    this.guidesClone.forEach(guides => {
      if (guides.user.userId === guideOwnerId) {
        user = guides.user;
      }
    });

    if (user === undefined) {
      throw new Error('the guide owner was not found inside of the guides');
    }

    this.selectedGuideOwnerCloneFlyout = user;
  };

  @action
  async setSelectedGuideCloneFlyout(guide?: GuideDTO) {
    this.selectedGuideCloneFlyout = guide;
  }

  @action
  resetSelectedGuideOwnerFromGuidesCloneFlyout = () => {
    this.selectedGuideCloneFlyout = undefined;
  };

  async getGuidesByDepartmentsClone(departmentIds: string[]) {
    departmentIds.forEach(async departmentId => {
      const guides = await this.loadingStore.withLoadingBar(() => getGuidesByDepartment(departmentId));

      runInAction(() => {
        this.guidesClone = [...this.guidesClone, ...guides];
      });
    });
  }

  @action
  resetAllSelectedGuideParamsFromCloneFlyout = () => {
    this.resetSelectedGuideOwnerFromGuidesCloneFlyout();
    this.selectedGuideOwnerCloneFlyout = undefined;
    this.additionalGuidesClone = [];
    this.guidesClone = [];
    this.guideMaterialsToClone = undefined;
    this.guideProcedureToClone = undefined;
    this.isCloneGuideProcedureFlyoutOpen = false;
    this.isCloneGuideMaterialFlyoutOpen = false;
  };

  @action
  setShowCloneGuideProcedureFlyout = (isOpen: boolean) => {
    this.isCloneGuideProcedureFlyoutOpen = isOpen;
  };

  async getHistory(guideId: string) {
    const history = await this.loadingStore.withFlyoutLoadingBar(() => getGuideMaterialHistory(guideId));
    runInAction(() => {
      this.history = history;
    });
  }

  @action
  setGuideProcedureToClone = (guideId: string) => {
    this.guideProcedureToClone = { guideId };
  };

  async getAdditionalGuidesByUserClone(userId: string) {
    const userGuides = await this.loadingStore.withLoadingBar(() => getAdditionalGuidesByUser(userId));

    runInAction(() => {
      this.additionalGuidesClone = userGuides[0].departments;
    });
  }

  @action
  setShowCloneMaterialKnowledgeFlyout = (isOpen: boolean) => {
    this.isCloneMaterialKnowledgeFlyout = isOpen;
  };

  addMaterialToGuide(materialLike: AnyMaterial) {
    if (!this.selectedGuide) {
      return Promise.resolve();
    }
    const categoryId = this.guideCategoryIdForAddMaterial ? this.guideCategoryIdForAddMaterial : this.guideMaterials[0].categoryId;
    const guideMaterial = buildNewGuideMaterial(this.selectedGuide.guideId, materialLike, categoryId);
    if (guideMaterial) {
      return this.createGuideMaterial(guideMaterial);
    }

    return Promise.resolve();
  }

  async replaceMaterialInGuide(materialLike: AnyMaterial, oldId: MaterialLikeIdDTO, guideId?: string): Promise<void> {
    if (!oldId.materialId && !oldId.materialSetId && !oldId.templateId) {
      return;
    }

    let newId: MaterialLikeIdDTO = {};

    if (materialLike.material) {
      // checks if searchresult is a package or only a single material
      if (materialLike.material.materialType === MaterialType.instrument) {
        throw new Error('instruments cannot be added as material');
      } else {
        newId = {
          materialId: materialLike.material.materialId
        };
      }
    }
    if (materialLike.pack) {
      newId = {
        templateId: materialLike.pack.templateId
      };
    }
    if (materialLike.materialSet) {
      newId = {
        materialSetId: materialLike.materialSet.materialSetId
      };
    }

    await this.loadingStore.withLoadingBar(() =>
      relinkGuidesMaterial({
        guideId,
        oldId,
        newId
      })
    );
  }
}
