import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import * as qs from 'query-string';

import { useStores } from 'util/mobx/stores';
import InstrumentOverview from 'pages/instrumentOverview/InstrumentOverview';
import InstrumentDetail from 'pages/instrumentDetail/InstrumentDetail';
import InstrumentContent from 'pages/instrumentContent/InstrumentContent';
import MaterialKnowledgeFormFlyout from 'components/MaterialKnowledge/MaterialKnowledgeFormFlyout';
import Safe from 'components/Safe';
import Page from 'components/Page';
import AppLayout from 'components/AppLayout';
import { ChatConfigurationTypes } from 'components/Chat/Chat';
import CreateMaterialSynonymFlyout from 'components/MaterialKnowledge/CreateMaterialSynonymFlyout';
import { AppBarHeaderText, AppBarPageIcon } from 'components/AppLayout/AppBar';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import MaterialValueTags from 'components/MaterialValueTags';
import InstrumentNavSection from './InstrumentNavSection';

const Instrument = observer(() => {
  const { instrumentStore } = useStores();
  const { t } = useTranslation('instruments');
  const location = useLocation();
  const instrumentId = qs.parse(location.search).instrumentId as string;
  useEffect(() => {
    instrumentStore.setInstrumentId(instrumentId);
    instrumentStore.loadInstrument(instrumentId);
  }, [instrumentId, instrumentStore]);
  const image = instrumentStore.instrument?.material.pictureThumbnail;

  const onUpdateFile = async (fileId: string) => {
    if (instrumentStore.instrument) {
      await instrumentStore.updateMaterial({ materialId: instrumentStore.instrument.material.materialId, pictureFileId: fileId });
    }
  };
  return (
    <>
      <AppLayout
        appBarIcon={<AppBarPageIcon key={image} src={image} onUpdateFile={onUpdateFile} />}
        appBarChildren={<AppBarHeaderText headline={instrumentStore.instrument?.material.name || ''} />}
        appBarRightChildren={<MaterialValueTags fullInfo classNameTitle="margin_left" instrument={instrumentStore.instrument} />}
        boardFlyout={<BoardFlyout />}
        flyout={
          <>
            <MaterialKnowledgeFormFlyout />
            <CreateMaterialSynonymFlyout />
          </>
        }
      >
        <Page
          headline={t('subMenu.headline')}
          idPrefix="instruments"
          navigation={<InstrumentNavSection instrumentId={instrumentId} />}
          chatConfigurationType={ChatConfigurationTypes.department}
        >
          <Switch>
            <Route path="/instrument/overview" component={() => <InstrumentOverview instrumentId={instrumentId} />} />
            <Route path="/instrument/detail" component={() => <InstrumentDetail />} />
            <Route path="/instrument/content" component={() => <InstrumentContent instrument={instrumentStore.instrument} />} />
          </Switch>
        </Page>
      </AppLayout>
    </>
  );
});

// moved Safe here to prevent running the useEffect if not logged in
export default () => (
  <Safe>
    <Instrument />
  </Safe>
);
