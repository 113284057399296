import moment, { Moment } from 'moment';
import { TransProps } from 'react-i18next';
import { DateDTO, UTCTimeDTO } from 'dto/surgery';
import 'moment/locale/de';
import { TFunction } from 'i18next';

export const dateFormat = 'DD.MM.YYYY';
export const timeFormat = 'HH:mm';

export const getDateWording = (d: Date | undefined, { t }: TransProps<any>, withArticle = false) => {
  if (!t || !d) return '';
  const today = moment();
  const date = moment(d);
  const yesterday = moment().subtract(1, 'days');
  if (date.isSame(today, 'day')) {
    return t('today');
  }
  if (date.isSame(yesterday, 'day')) {
    return t('yesterday');
  }
  return withArticle ? `${t('at')} ${date.format(dateFormat)}` : date.format(dateFormat);
};

export const getDayTimeWording = (d: Date, { t }: TransProps<any>, withArticle = false) => {
  if (!t) return '';
  const date = moment(d);
  const dateWording = getDateWording(d, { t }, withArticle);

  return `${dateWording} ${moment(date).format(timeFormat)} ${t('time')}`;
};

export const getDayWording = (d: Date, { t }: TransProps<any>, withArticle = false) => {
  if (!t) return '';
  const dateWording = getDateWording(d, { t }, withArticle);

  return `${dateWording}`;
};

export const isPostNew = (d: Date) => {
  const today = moment();
  const date = moment(d);
  return date.isSame(today, 'day');
};

export const getHoursTillNow = (d: Date) => {
  const time = moment.duration(
    moment(d)
      .locale('de')
      .diff(moment(new Date()))
  );

  return time;
};

export const getCurrentDate = (localtime?: boolean): DateDTO => {
  const now = moment();
  if (!localtime) {
    now.utc();
  }

  return {
    year: now.year(),
    month: now.month(),
    dayInMonth: now.date()
  };
};

export const getCurrentTime = (): UTCTimeDTO => {
  const now = moment().utc();
  return {
    hour: now.utc().hour(),
    minute: now.utc().minute(),
    second: now.utc().second()
  };
};

export const parseToDate = (date: Moment): DateDTO => ({
  year: date.year(),
  month: date.month(),
  dayInMonth: date.date()
});

export const parseToTime = (time: Moment): UTCTimeDTO => {
  time.utc();

  const result = {
    minute: time.minutes(),
    hour: time.hours(),
    second: time.seconds()
  };

  time.local();

  return result;
};

export const parseFromDateTime = (date: DateDTO, time?: UTCTimeDTO, doNotConvertTimezone?: boolean) => {
  const newTime = moment(`${date.dayInMonth}.${date.month + 1}.${date.year}`, 'DD.MM.YYYY');

  if (time) {
    if (!doNotConvertTimezone) {
      newTime.utc(true);
    }

    newTime.hours(time.hour);
    newTime.minutes(time.minute);
    newTime.seconds(time.second);
  }

  newTime.local();

  return newTime;
};

function isSameDay(day1: Date, day2: Date) {
  return day1.getFullYear() === day2.getFullYear() && day1.getDate() === day2.getDate() && day1.getMonth() === day2.getMonth();
}

export const getDayName = (t: TFunction, date: DateDTO, time?: UTCTimeDTO) => {
  const dateToCheck = parseFromDateTime(date, time).toDate();

  if (!dateToCheck) {
    return '';
  }

  const todaysDate = moment().toDate();
  const tomorrowsDate = moment()
    .add(24, 'hour')
    .toDate();
  const dayAfterTomorrowsDate = moment()
    .add(48, 'hour')
    .toDate();

  if (isSameDay(dateToCheck, todaysDate)) {
    return t('today');
  }
  if (isSameDay(dateToCheck, tomorrowsDate)) {
    return t('tomorrow');
  }
  if (isSameDay(dateToCheck, dayAfterTomorrowsDate)) {
    return t('dayAfterTomorrow');
  }
  return '';
};

export const getTimeRangeString = (date: DateDTO, t: TFunction, start: UTCTimeDTO, end?: UTCTimeDTO) => {
  const startString = parseFromDateTime(date, start)?.format(timeFormat);
  const endString = parseFromDateTime(date, end)?.format(timeFormat);
  let res = `${startString} ${t('time')}`;
  if (end) {
    res += ` ${t('till')} ${endString} ${t('time')}`;
  }
  return res;
};

export const getTimeDifference = (date: DateDTO, start: UTCTimeDTO, end: UTCTimeDTO) => {
  const startDate = parseFromDateTime(date, start);
  let endDate = parseFromDateTime(date, end);
  let diff = moment.duration(endDate?.diff(startDate));
  if (diff && diff.asMinutes() < 0) {
    endDate = endDate?.add(24, 'hours');
    diff = moment.duration(endDate?.diff(startDate));
  }

  return diff.asMinutes().toFixed(0);
};
