import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import MaterialDrawerDetailNav from 'components/MaterialDrawerDetailNav';
import { MaterialDTO } from 'dto/material';
import { GuideMaterialLikeDTO } from 'dto/guide';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import { CSSTransition } from 'react-transition-group';
import { Can, subjectArea } from 'casl/setupCaslAbility';
import Button from 'components/Form/Button';
import CreateMaterialSynonymFlyout from 'components/MaterialKnowledge/CreateMaterialSynonymFlyout';
import { subject } from '@casl/ability';
import GuideSettings from '../GuideSettings';
import FixedTopMaterialBox from './FixedTopMaterialBox';
import MaterialHead from './MaterialHead';
import DrawerMaterialContent from './DrawerMaterialContent';

interface Props {
  material: MaterialDTO;
  guideMaterial?: GuideMaterialLikeDTO;
  surgeryGuideMaterial?: SurgeryGuideMaterialLikeDTO;
  onUpdateGuideMaterial: (guideMaterial?: GuideMaterialLikeDTO, surgeryGuideMaterial?: SurgeryGuideMaterialLikeDTO) => void;
}

const MaterialDrawerContent = observer(({ material, guideMaterial, surgeryGuideMaterial, onUpdateGuideMaterial }: Props) => {
  const { guideStore, guideDetailDrawerStore, procedureStore, searchStore, domainStore, materialStore, materialSetsStore } = useStores();
  const [isShow, setIsShow] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(window.innerWidth >= 991);
  const { t } = useTranslation('guideSettings');

  const handleContentWrapperScroll = (event: React.UIEvent<HTMLElement>) => {
    if (!isShow && event.currentTarget.scrollTop >= 300) {
      setIsShow(true);
    } else if (isShow && event.currentTarget.scrollTop <= 299 && event.currentTarget.scrollTop !== 0) {
      setIsShow(false);
    }
  };

  const onUpdateFile = async (fileId: string) => {
    await materialStore.updateMaterial({ materialId: material.materialId, pictureFileId: fileId }, false);
    await guideDetailDrawerStore.reloadSelectedMaterial();
    if (guideStore.selectedGuide) {
      await procedureStore.loadProcedures(guideStore.selectedGuide.guideId);
    }
    if (searchStore.isSearchPanelOpen) {
      await searchStore.refreshSearch();
    }
    if (materialSetsStore.materialSetId) {
      await materialSetsStore.loadMaterialSet(materialSetsStore.materialSetId);
    }
  };

  return (
    <div className="div-block-14">
      <div className="div-block-12">
        <div className="ios-bar" />
        <CreateMaterialSynonymFlyout
          isOpen={guideDetailDrawerStore.isCreateMaterialSynonymOpen}
          onClose={() => guideDetailDrawerStore.closeCreateMaterialSynonymFlyout()}
        />
        <div className="mat_content_wrapper" onScroll={handleContentWrapperScroll}>
          <FixedTopMaterialBox material={material} isShow={isShow} onUpdateFile={onUpdateFile} />
          <div className="div-block-21" onScroll={handleContentWrapperScroll}>
            <MaterialHead material={material} onUpdateFile={onUpdateFile} />
            <DrawerMaterialContent key={material.materialId} material={material} />
          </div>
        </div>
        {guideDetailDrawerStore.settingsEnabled && guideMaterial && (
          <Can I="see" this={subject(subjectArea.materialSettings, { departmentId: domainStore.currentDepartment.id })}>
            <CSSTransition in={isSettingsOpen} timeout={500} classNames="settings-drawer" unmountOnExit>
              <GuideSettings
                guideMaterial={guideMaterial}
                surgeryGuideMaterial={surgeryGuideMaterial}
                onSettingsClose={() => setIsSettingsOpen(false)}
              />
            </CSSTransition>
          </Can>
        )}

        {guideDetailDrawerStore.settingsEnabled && surgeryGuideMaterial && (
          <CSSTransition in={isSettingsOpen} timeout={500} classNames="settings-drawer" unmountOnExit>
            <GuideSettings
              guideMaterial={guideMaterial}
              surgeryGuideMaterial={surgeryGuideMaterial}
              onSettingsClose={() => setIsSettingsOpen(false)}
            />
          </CSSTransition>
        )}
      </div>

      <div className="div-block-13">
        <MaterialDrawerDetailNav
          onUpdateFile={onUpdateFile}
          detailLink={`/material/overview?materialId=${material.materialId}`}
          knowledgeLink={`/material/content?materialId=${material.materialId}`}
          onShowSettings={() => setIsSettingsOpen(true)}
          isGuideMaterial={!!guideMaterial}
          isSurgeryGuideMaterial={!!surgeryGuideMaterial}
        />
        <Button onClick={() => onUpdateGuideMaterial(guideMaterial, surgeryGuideMaterial)} className="btn_big_cancel active">
          <div>{t('button.close')}</div>
        </Button>
      </div>
    </div>
  );
});

export default MaterialDrawerContent;
