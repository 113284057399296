import { observable, runInAction, action } from 'mobx';
import { MaterialDTO, MaterialLikeDTO, MaterialLikeIdOrPackageIdDTO } from 'dto/material';
import { InstrumentDTO } from 'dto/instrument';
import { GuideMaterialLikeDTO, NotesDTO, UpdateGuideMaterialDTO } from 'dto/guide';
import { getMaterial, getMaterialStorageLocations, getMaterialStorageLocationPaths } from 'api/material';
import { getInstrument } from 'api/instrument';
import { getPackage } from 'api/package';
import { getMaterialSet } from 'api/materialSet';
import { PackageDTO } from 'dto/package';
import { BasicMaterialSetDTO } from 'dto/materialSet';
import { getStorageLocationPath } from 'api/storageLocation';
import { getSurgeryGuideMaterial, updateSurgeryGuideMaterial } from 'api/surgeryGuide';
import { updateGuideMaterial, deleteGuideMaterial, getGuideMaterial } from 'api/guide';
import { StorageLocationPathDTO, MaterialStorageLocationsDTO } from 'dto/storageLocation';
import { SurgeryGuideMaterialLikeDTO, UpdateSurgeryGuideMaterialDTO } from 'dto/surgeryGuide';
import LoadingStore from './loadingStore';

export interface GuideSettingsValues {
  amount: number;
  notes: NotesDTO;
}

export interface MergedGuideMaterialDTO extends MaterialLikeDTO {
  guideMaterialId?: string;
  surgeryGuideMaterialId?: string;
  storageLocationId?: string;
  amount: number;
  notes: NotesDTO;
}

export default class GuideDetailDrawerStore {
  @observable
  private loadingStore: LoadingStore;

  @observable
  isOpen = false;

  /**
   * Use this instead of the knowledgeStore.isCreateMaterialSynonymOpen for the
   * Synonym flyout inside of the drawer.
   */
  @observable
  isCreateMaterialSynonymOpen = false;

  @observable
  selectedMaterial?: MaterialDTO;

  @observable
  selectedPackage?: PackageDTO;

  @observable
  selectedInstrument?: InstrumentDTO;

  @observable
  selectedMaterialSet?: BasicMaterialSetDTO;

  @observable
  selectedGuideItem?: GuideMaterialLikeDTO;

  @observable
  selectedSurgeryGuideItem?: SurgeryGuideMaterialLikeDTO;

  @observable
  storageLocationPath?: StorageLocationPathDTO;

  @observable
  materialStorageLocationPaths?: StorageLocationPathDTO[];

  @observable
  materialStorageLocations?: MaterialStorageLocationsDTO[];

  @observable
  guideSettingsValues?: GuideSettingsValues;

  @observable
  choosingLocationDisabled = false;

  @observable
  settingsEnabled = false;

  @observable
  reloadWithUseQuery = false;

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;
  }

  /**
   * opens the drawer including the guideMaterial information.
   * @param guideMaterialId the guideMaterialId to load
   * @param choosingLocationDisabled disable the choosing location functionality
   * @param onClose call this instead of reloading the guideMaterial list.
   */
  @action
  async openWithGuideMaterial(
    guideMaterialId: string,
    specific?: { packageId?: string; materialId?: string },
    choosingLocationDisabled = false,
    reloadWithUseQuery = false
  ) {
    this.clearAllSelectedItems(false);
    const guideMaterial = await this.loadingStore.withLoadingBar(() => getGuideMaterial(guideMaterialId));

    if (guideMaterial.material) {
      this.setSelectedMaterial(guideMaterial.material, guideMaterial, choosingLocationDisabled);
    } else if (guideMaterial.materialSet) {
      if (!specific?.packageId && !specific?.materialId) {
        this.setSelectedMaterialSet(guideMaterial.materialSet, guideMaterial);
      } else if (specific?.packageId) {
        const packageId = specific?.packageId;
        const pack = await this.loadingStore.withLoadingBar(() => getPackage(packageId));

        this.setSelectedPackage(pack.pack, guideMaterial);
      } else if (specific?.materialId) {
        const materialId = specific?.materialId;
        const material = await this.loadingStore.withLoadingBar(() => getMaterial(materialId));

        this.setSelectedMaterial(material, guideMaterial, true);
      }
    } else if (guideMaterial.template) {
      if (!specific?.packageId && !specific?.materialId) {
        throw new Error('opening templates without specific package or material is not supported');
      }

      if (specific?.packageId) {
        const packageId = specific?.packageId;
        const pack = await this.loadingStore.withLoadingBar(() => getPackage(packageId));

        this.setSelectedPackage(pack.pack, guideMaterial);
      }

      if (specific?.materialId) {
        const materialId = specific?.materialId;
        const material = await this.loadingStore.withLoadingBar(() => getMaterial(materialId));

        this.setSelectedMaterial(material, guideMaterial);
      }
    }

    console.log('enable', guideMaterialId);
    this.enableSettings();
    this.reloadWithUseQuery = reloadWithUseQuery;
    this.setIsOpen(true);
  }

  /**
   * opens the drawer including the surgeryGuideMaterial information.
   * @param surgeryGuideMaterialId the surgeryGuideMaterialId to load
   * @param choosingLocationDisabled disable the choosing location functionality
   * @param onClose call this instead of reloading the guideMaterial list.
   */
  @action
  async openWithSurgeryGuideMaterial(
    surgeryGuideMaterialId: string,
    specific?: { packageId?: string; materialId?: string },
    choosingLocationDisabled = false,
    reloadWithUseQuery = false
  ) {
    this.clearAllSelectedItems(false);
    const surgeryGuideMaterial = await this.loadingStore.withLoadingBar(() => getSurgeryGuideMaterial(surgeryGuideMaterialId));

    if (surgeryGuideMaterial.material) {
      this.setSelectedMaterial(surgeryGuideMaterial.material, undefined, choosingLocationDisabled);
    } else if (surgeryGuideMaterial.materialSet) {
      if (!specific?.packageId && !specific?.materialId) {
        this.setSelectedMaterialSet(surgeryGuideMaterial.materialSet);
      } else if (specific?.packageId) {
        const packageId = specific?.packageId;
        const pack = await this.loadingStore.withLoadingBar(() => getPackage(packageId));

        this.setSelectedPackage(pack.pack);
      } else if (specific?.materialId) {
        const materialId = specific?.materialId;
        const material = await this.loadingStore.withLoadingBar(() => getMaterial(materialId));

        this.setSelectedMaterial(material);
      }
    } else if (surgeryGuideMaterial.template) {
      if (!specific?.packageId && !specific?.materialId) {
        throw new Error('opening templates without specific package or material is not supported');
      }

      if (specific?.packageId) {
        const packageId = specific?.packageId;
        const pack = await this.loadingStore.withLoadingBar(() => getPackage(packageId));

        this.setSelectedPackage(pack.pack);
      }

      if (specific?.materialId) {
        const materialId = specific?.materialId;
        const material = await this.loadingStore.withLoadingBar(() => getMaterial(materialId));

        this.setSelectedMaterial(material);
      }
    }

    this.setSelectedSurgeryGuideItem(surgeryGuideMaterial);

    this.enableSettings();
    this.reloadWithUseQuery = reloadWithUseQuery;
    this.setIsOpen(true);
  }

  /**
   * opens the drawer including the guideMaterial information.
   * @param choosingLocationDisabled disable the choosing location functionality
   * @param onClose call this instead of reloading the guideMaterial list.
   * @param reloadWithUseQuery enables reloading using the use query invalidates
   */
  @action
  async open(specific?: { packageId?: string; materialId?: string; instrumentId?: string }, reloadWithUseQuery = false) {
    this.clearAllSelectedItems(false);

    if (specific?.instrumentId) {
      const instrumentId = specific?.instrumentId;
      const instrument = await this.loadingStore.withLoadingBar(() => getInstrument(instrumentId));

      this.setSelectedMaterial(instrument.material);
    }

    if (specific?.materialId) {
      const materialId = specific?.materialId;
      const material = await this.loadingStore.withLoadingBar(() => getMaterial(materialId));

      this.setSelectedMaterial(material);
    }

    if (specific?.packageId) {
      const packageId = specific?.packageId;
      const pack = await this.loadingStore.withLoadingBar(() => getPackage(packageId));

      this.setSelectedPackage(pack.pack);
    }

    this.reloadWithUseQuery = reloadWithUseQuery;
    this.setIsOpen(true);
  }

  @action
  setSelectedMaterial(selectedMaterial: MaterialDTO, selectedGuideMaterial?: GuideMaterialLikeDTO, choosingLocationDisabled = false) {
    this.clearAllSelectedItems(false);
    this.selectedMaterial = selectedMaterial;
    if (selectedGuideMaterial) {
      this.selectedGuideItem = selectedGuideMaterial;
    }
    this.choosingLocationDisabled = choosingLocationDisabled;
  }

  @action
  setSelectedPackage(selectedPackage: PackageDTO, selectedGuideMaterial?: GuideMaterialLikeDTO) {
    this.clearAllSelectedItems(false);
    this.selectedPackage = selectedPackage;
    if (selectedGuideMaterial) {
      this.selectedGuideItem = selectedGuideMaterial;
    }
  }

  @action
  setSelectedInstrument(selectedInstrument: InstrumentDTO, selectedGuideMaterial?: GuideMaterialLikeDTO) {
    this.clearAllSelectedItems(false);
    this.selectedInstrument = selectedInstrument;
    if (selectedGuideMaterial) {
      this.selectedGuideItem = selectedGuideMaterial;
    }
  }

  @action
  setSelectedMaterialSet(selectedMaterialSet: BasicMaterialSetDTO, selectedGuideMaterial?: GuideMaterialLikeDTO) {
    this.clearAllSelectedItems(false);
    this.selectedMaterialSet = selectedMaterialSet;
    if (selectedGuideMaterial) {
      this.selectedGuideItem = selectedGuideMaterial;
    }
  }

  @action
  setSelectedSurgeryGuideItem(selectedSurgeryGuideItem: SurgeryGuideMaterialLikeDTO) {
    this.selectedSurgeryGuideItem = selectedSurgeryGuideItem;
  }

  @action
  setIsOpen(isOpen: boolean) {
    this.isOpen = isOpen;
    if (!isOpen) {
      this.clearAllSelectedItems();
    }
  }

  @action
  clearAllSelectedItems(withTimeout = true) {
    const reset = () => {
      this.selectedMaterial = undefined;
      this.selectedPackage = undefined;
      this.selectedInstrument = undefined;
      this.selectedMaterialSet = undefined;
      this.selectedGuideItem = undefined;
      this.choosingLocationDisabled = false;
      this.selectedSurgeryGuideItem = undefined;
      this.guideSettingsValues = undefined;
      this.reloadWithUseQuery = false;
    };

    if (withTimeout) {
      // wait for animation complete
      setTimeout(() => {
        runInAction(reset);
      }, 500);
    } else {
      reset();
    }
  }

  async reloadSelectedMaterial() {
    const selectedMaterial = await this.loadingStore.withLoadingBar(() => {
      if (!this.selectedMaterial) {
        return Promise.resolve(undefined);
      }
      return getMaterial(this.selectedMaterial.materialId);
    });
    runInAction(() => {
      this.selectedMaterial = selectedMaterial;
    });
  }

  async reloadSelectedPackage() {
    const fullPackage = await this.loadingStore.withLoadingBar(() => {
      if (!this.selectedPackage) {
        return Promise.resolve(undefined);
      }
      return getPackage(this.selectedPackage.packageId);
    });
    runInAction(() => {
      if (fullPackage) {
        this.selectedPackage = fullPackage.pack;
      }
    });
  }

  async reloadSelectedInstrument() {
    const selectedInstrument = await this.loadingStore.withLoadingBar(() => {
      if (!this.selectedInstrument) {
        return Promise.resolve(undefined);
      }
      return getInstrument(this.selectedInstrument.instrumentId);
    });
    runInAction(() => {
      this.selectedInstrument = selectedInstrument;
    });
  }

  async reloadSelectedMaterialSet() {
    const selectedMaterialSet = await this.loadingStore.withLoadingBar(() => {
      if (!this.selectedMaterialSet) {
        return Promise.resolve(undefined);
      }
      return getMaterialSet(this.selectedMaterialSet.materialSetId);
    });
    runInAction(() => {
      if (selectedMaterialSet) {
        this.selectedMaterialSet = {
          ...selectedMaterialSet,
          materialSetId: selectedMaterialSet.materialSetId
        };
      }
    });
  }

  async loadStorageLocationPath(storageLocationId: string) {
    const storageLocationPath = await this.loadingStore.withLoadingBar(() => {
      return getStorageLocationPath(storageLocationId);
    });
    runInAction(() => {
      this.storageLocationPath = storageLocationPath;
    });
  }

  async updateGuideMaterial(guide: UpdateGuideMaterialDTO, callback: () => Promise<void>) {
    await this.loadingStore.withLoadingBar(() => {
      return updateGuideMaterial(guide);
    });
    await callback();
  }

  async updateSurgeryGuideMaterial(guide: UpdateSurgeryGuideMaterialDTO, callback: () => Promise<void>) {
    await this.loadingStore.withLoadingBar(() => {
      return updateSurgeryGuideMaterial(guide);
    });
    await callback();
  }

  async deleteGuideMaterial(guideMaterialId: string, callback: () => Promise<void>) {
    await this.loadingStore.withLoadingBar(() => {
      return deleteGuideMaterial({ guideMaterialId });
    });
    await callback();
  }

  async loadMaterialStorageLocationPaths(materialLikeId: MaterialLikeIdOrPackageIdDTO, functionalAreaId?: string) {
    const materialStorageLocationPaths = await this.loadingStore.withLoadingBar(() => {
      return getMaterialStorageLocationPaths({
        functionalAreaId,
        ...materialLikeId
      });
    });
    const materialStorageLocations = await this.loadingStore.withLoadingBar(() => {
      return getMaterialStorageLocations({
        functionalAreaId,
        ...materialLikeId
      });
    });
    runInAction(() => {
      this.materialStorageLocationPaths = materialStorageLocationPaths;
      this.materialStorageLocations = materialStorageLocations;
    });
  }

  @action
  resetStorageLocationData() {
    this.storageLocationPath = undefined;
    this.materialStorageLocationPaths = undefined;
    this.materialStorageLocations = undefined;
  }

  @action
  openCreateMaterialSynonymFlyout() {
    this.isCreateMaterialSynonymOpen = true;
  }

  @action
  closeCreateMaterialSynonymFlyout() {
    this.isCreateMaterialSynonymOpen = false;
  }

  @action
  resetGuideSettingsValues() {
    this.guideSettingsValues = undefined;
  }

  @action
  setGuideSettingsValues(guideSettingsValues: GuideSettingsValues) {
    this.guideSettingsValues = guideSettingsValues;
  }

  @action
  clearStorageLocationPath() {
    this.storageLocationPath = undefined;
  }

  @action
  enableSettings() {
    this.settingsEnabled = true;
  }

  @action
  disableSettings() {
    this.settingsEnabled = false;
  }
}
