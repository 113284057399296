import { GuideIdDTO } from 'dto/guide';
import {
  GroupedProcedureDTO,
  UpdateSortProceduresDTO,
  UpdateProcedureDTO,
  ProcedureDTO,
  CreateGuideProcedureDTO,
  GroupedGuideProcedureDTO,
  BulkCopyRelinkProcedureDTO,
  BulkLinkMaterialKnowledgeToProcedureDTO
} from 'dto/procedure';
import { get, put, patch, del, post } from './common';

import { validateArray, validateBool, validate } from './validation';

export const getProcedures = async (query: GuideIdDTO): Promise<GroupedProcedureDTO[]> => {
  const procedureGroups = await get('/guide/procedures', query).then(response => response.data);
  return validateArray(GroupedProcedureDTO, procedureGroups);
};

export const reorderGuideProcedures = async (reorderedGuideProcedures: UpdateSortProceduresDTO[]) => {
  return put('/procedures/positions', reorderedGuideProcedures).then(response => validateBool(response.data));
};

export const updateGuideProcedure = async (guideProcedureUpdateFields: UpdateProcedureDTO): Promise<ProcedureDTO> => {
  const updatedGuideProcedure = await patch('/guide/procedure', guideProcedureUpdateFields).then(response => response.data);
  return validate(ProcedureDTO, updatedGuideProcedure);
};

export const deleteGuideProcedure = async (guideProcedureId: string) => {
  return del('/guide/procedure', { guideProcedureId }).then(response => validateBool(response.data));
};

export const deleteGuidesProcedures = async (guideProcedureId: string) => {
  return del('/guides/procedures', { guideProcedureId }).then(response => validateBool(response.data));
};

export const createGuideProcedure = async (newGuideProcedure: CreateGuideProcedureDTO) => {
  const createdGuideProcedure = await post('/guide/procedure', newGuideProcedure).then(response => response.data);
  return validate(ProcedureDTO, createdGuideProcedure);
};

export const getProcedureGuides = async (guideProcedureId: string): Promise<GroupedGuideProcedureDTO[]> => {
  const guideProcedure = await get('/procedure/guides', { guideProcedureId }).then(response => response.data);
  return validateArray(GroupedGuideProcedureDTO, guideProcedure);
};

export const bulkUpdateCopyRelinkGuideProcedure = async (procedureToCopyRelink: BulkCopyRelinkProcedureDTO): Promise<ProcedureDTO[]> => {
  const guideProcedures = await put('/guide/procedure/bulk-copy-relink', procedureToCopyRelink).then(response => response.data);
  return validateArray(ProcedureDTO, guideProcedures);
};

export const bulkLinkMaterialKnowledgeToProcedure = async (
  materialKnowledgeToLink: BulkLinkMaterialKnowledgeToProcedureDTO
): Promise<ProcedureDTO[]> => {
  const guideProcedures = await post('/materialKnowledge/bulk-link-to-Procedure', materialKnowledgeToLink).then(response => response.data);
  return validateArray(ProcedureDTO, guideProcedures);
};
