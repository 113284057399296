import { CreateSurgeryGuideDTO, SurgeryGuideIdDTO } from 'dto/surgeryGuide';
import { GetBriefingHistoryDTO, BriefingHistoryDTO } from 'dto/history';
import { get, post, del } from './common';
import { validate } from './validation';

export const getBriefingHistory = async (params: GetBriefingHistoryDTO): Promise<BriefingHistoryDTO> => {
  const briefingHistory = await get('/surgery/briefing/history', params).then(response => response.data);
  return validate(BriefingHistoryDTO, briefingHistory);
};

export const addSurgeryGuide = async (data: CreateSurgeryGuideDTO) => {
  const department = await post('/surgery/briefing/surgery-guide', data).then(response => response.data);
  return validate(SurgeryGuideIdDTO, department);
};

export const deleteSurgeryGuide = async (params: SurgeryGuideIdDTO) => {
  return del('/surgery/briefing/surgery-guide', params).then(response => response.data);
};
