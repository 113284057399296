import { GroupMaterialDTO } from 'dto/groupMaterial';
import { MaterialListItem } from 'modules/material/ListItems';
import { ListItemRendererType } from 'modules/material/ListItems/MaterialListItem';
import React from 'react';

import './GroupMaterialsListItem.css';

/**
 * Implements the ListItemRendererType for the GuideMaterialLikeFlatDTO
 */
export const GroupMaterialsListItemRenderer: ListItemRendererType<GroupMaterialDTO> = ({
  level,
  name,
  picture,
  amount,
  dropdownItems,
  end,
  instructions,
  isInitialLoading,
  noExpandIconSpace,
  onClick,
  onDropdownExpand,
  prefix,
  menu,
  classNamePrefix
}) => {
  return (
    <MaterialListItem
      classNamePrefix={classNamePrefix || 'set'}
      menu={menu}
      level={level}
      name={name}
      picture={picture}
      amount={amount}
      dropdownItems={dropdownItems}
      noExpandIconSpace={noExpandIconSpace}
      end={end}
      instructions={instructions}
      isInitialLoading={isInitialLoading}
      onClick={onClick}
      onDropdownExpand={onDropdownExpand}
      prefix={prefix}
    />
  );
};
