import React from 'react';

import './ReportDepartmentItem.css';

interface Props {
  name: string;
  onClick: () => void;
  selected: boolean;
}

const ReportDepartmentItem = ({ name, onClick, selected }: Props) => {
  return (
    <div className={`list_item_report_text report-list-item ${selected ? 'selected' : ''}`} onClick={onClick}>
      <div className="item_name">{name}</div>
      <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-arrow-list" />
    </div>
  );
};

export default ReportDepartmentItem;
