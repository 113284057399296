import FlyoutContainer from 'components/FlyoutContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ResetPasswordFlyout = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('accountManagement');
  const { userStore } = useStores();

  return (
    <FlyoutContainer isOpen={isOpen} closePopUp={onClose} cancelLabel="Schliessen" hideFlyoutLine>
      <div className="flyout_box">
        <div className="h4">{t('userDetail.resetPasswordFlyout.headline')}</div>
        <div className="div-block-38">
          <div className="list_item_material_no_hover">
            <div className="book_info-copy in_prompt">
              <div className="t2 bottom-margin">{t('userDetail.resetPasswordFlyout.message')}</div>
              <div className="pw-block">{userStore.temporaryPassword}</div>
            </div>
          </div>
        </div>
      </div>
    </FlyoutContainer>
  );
};

export default ResetPasswordFlyout;
