import React, { useCallback } from 'react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import SideMenuIconButton from 'components/SideMenuIconButton';

const InstrumentContentRightMenu = observer(() => {
  const { t } = useTranslation('instruments');

  const { knowledgeStore } = useStores();

  const handleCreateFlyoutOpen = useCallback(() => {
    knowledgeStore.setDataForNewMaterialKnowledge(undefined, 0);
    knowledgeStore.setIsMaterialKnowledgeFormFlyoutOpen(true);
  }, [knowledgeStore]);

  return (
    <>
      <SideMenuIconButton
        handleClick={() => handleCreateFlyoutOpen()}
        label={t('content.rightMenu.addNewKnowledge')}
        iconPath="icon_f-add_16.svg"
      />
      <SideMenuIconButton
        handleClick={() => knowledgeStore.setIsCreateMaterialSynonymOpen(true)}
        label={t('content.rightMenu.newSynonym')}
        iconPath="icon_f-add_16.svg"
      />
    </>
  );
});

export default InstrumentContentRightMenu;
