import React, { useCallback, useEffect, useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { getAnimationState } from 'util/animation';
import { ListItemPlaceholder } from './ListItemPlaceholder';

interface ListItemContainerProps {
  containerId?: string;
  classNamePrefix: string;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  prefix?: React.ReactNode;

  level: number;
  onDropdownExpand?: () => void;
  dropdownItems?: React.ReactNode;

  /**
   * This can be set to remove the space reserved for the expand-icon
   */
  noExpandIconSpace?: boolean;

  isInitialLoading?: boolean;

  forceExpand?: boolean;

  enablePrefixExpand?: boolean;
}

export const ListItemContainer = ({
  containerId,
  classNamePrefix,
  children,
  prefix,
  level,
  onDropdownExpand,
  dropdownItems,
  noExpandIconSpace = false,
  isInitialLoading = false,
  forceExpand = false,
  enablePrefixExpand = false
}: ListItemContainerProps) => {
  const isLeaf = dropdownItems === undefined;

  const [isListExpanded, setIsListExpanded] = useState<boolean>(false);
  const [isListForced, setIsListForced] = useState<boolean>(forceExpand);

  useEffect(() => {
    setIsListForced(forceExpand);
  }, [forceExpand]);

  const isListExpandedOrForced = isListExpanded || isListForced;

  const loadItems = useCallback(() => {
    if (onDropdownExpand && !isListExpandedOrForced) {
      onDropdownExpand();
    }
    setIsListExpanded(!isListExpandedOrForced);
    setIsListForced(!isListExpandedOrForced);
  }, [isListExpandedOrForced, onDropdownExpand]);

  return (
    <div id={containerId}>
      <div className={`material-list-item ${classNamePrefix}-material-list-item`}>
        {enablePrefixExpand ? (
          <div onClick={loadItems} style={{ display: 'flex', cursor: 'pointer' }}>
            {prefix}
          </div>
        ) : (
          prefix
        )}
        {!noExpandIconSpace && (
          <div
            className={`${isLeaf ? 'visibility-hidden' : ''} material-list-arrow flex-vertical-center ${getAnimationState(
              !!(dropdownItems && isListExpandedOrForced),
              'expanded',
              'collapsed'
            )}`}
            onClick={loadItems}
          >
            <img alt="" src="images/icon_small-left_16.svg" />
          </div>
        )}
        {children}
      </div>
      <div
        className={`material-list-items ${classNamePrefix}-material-list-items ${!(dropdownItems && isListExpandedOrForced) && 'hidden'}`}
      >
        <ReactPlaceholder
          ready={!isInitialLoading}
          showLoadingAnimation
          customPlaceholder={<ListItemPlaceholder classNamePrefix={classNamePrefix} level={level + 1} />}
        >
          <div>{dropdownItems}</div>
        </ReactPlaceholder>
      </div>
    </div>
  );
};
