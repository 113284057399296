import { ExtendedUserDTO, UserDTO } from 'dto/user';
import { observer } from 'mobx-react';
import React from 'react';
import './UserInfoBox.css';

interface Props {
  user: UserDTO | ExtendedUserDTO;
  userInfoBoxClassName?: string;
  left?: boolean;
  infoBoxBoardPosition?: boolean;
}

const UserInfoBox = observer(({ user, userInfoBoxClassName, left, infoBoxBoardPosition = false }: Props) => {
  return (
    <>
      <div
        className={`${left ? 'user_info_box-left' : 'user_info_box'} ${userInfoBoxClassName || ''} ${
          infoBoxBoardPosition ? 'user_info_box_top' : ''
        }`}
      >
        <div className="usr_name_head">
          <div className={`${left ? 'div-block-186-left' : 'div-block-186'}`} />
          <div className="t2 bottom-margin">{user.title}</div>
          <div>
            <strong>
              {user.lastName} {', '} {user.firstName}
            </strong>
          </div>
        </div>
        <div className="usr_info_item">
          <img src="images/phone.svg" loading="lazy" alt="" />
          <div>{user.phoneNumber ? user.phoneNumber : ''}</div>
        </div>
        <div className="usr_info_item">
          <img src="images/email.svg" loading="lazy" alt="" />
          <div>{user.emailAddress ? user.emailAddress : ''}</div>
        </div>
      </div>
    </>
  );
});

export default UserInfoBox;
