import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import MaterialKnowledge from 'components/MaterialKnowledge';
import ContentRightMenu from './ContentRightMenu';

interface Props {
  materialId: string;
}

export default observer(({ materialId }: Props) => {
  const { appNavigationStore } = useStores();

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <ContentRightMenu />;
    });
  }, [appNavigationStore]);

  appNavigationStore.useSubPageIdSetter('knowledge');

  return (
    <div className="single_colum_content">
      <MaterialKnowledge materialLikeId={{ materialId }} />
    </div>
  );
});
