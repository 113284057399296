import React from 'react';
import SideMenuIconButton from 'components/SideMenuIconButton';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';

const SurgeryRightMenu = observer(() => {
  const { t } = useTranslation('surgery');
  const { surgeryStore, domainStore } = useStores();
  const { shortName: functionalAreaShortName } = domainStore.currentFunctionalArea;
  const handleAddGuide = () => {
    surgeryStore.setIsSelectGuideFlyoutOpen(true);
  };
  const handleOpenPlaner = () => surgeryStore.setIsPlannerDrawerOpen(true);

  return (
    <>
      {surgeryStore.selectedSurgeryBriefing && (
        <SideMenuIconButton handleClick={handleAddGuide} label={t('rightMenu.addGuide')} iconPath="icon_f-add_16.svg" />
      )}
      <SideMenuIconButton
        handleClick={handleOpenPlaner}
        label={t('rightMenu.openPlaner', { functionalAreaShortName })}
        iconPath="icon_calendar-date-2_16_blue.svg"
      />
    </>
  );
});

export default SurgeryRightMenu;
