import React from 'react';
import Button from 'components/Form/Button';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';

const PlannerNavigation = () => {
  const { surgeryStore, domainStore } = useStores();
  const { t } = useTranslation('surgery');
  const { shortName: functionalAreaShortName } = domainStore.currentFunctionalArea;

  return (
    <div className="navigation_op_planer planer-footer">
      <div className="div-block-23">
        <Button
          className="btn_material_detail btn-material-detail w-inline-block"
          onClick={() => surgeryStore.setIsSurgeryFormFlyoutOpen(true)}
        >
          <img src="images/icon_f-add_16_grey.svg" alt="" className="image_mat_detail image-mat-detail" />
          <div className="text-block-6">{t('planner.button.createSurgery', { functionalAreaShortName })}</div>
        </Button>
      </div>
      <Button onClick={() => surgeryStore.setIsPlannerDrawerOpen(false)} className="btn_close_op-plan">
        {t('planner.button.cancel')}
      </Button>
    </div>
  );
};

export default PlannerNavigation;
