import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { savePackageFile } from 'api/file';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Form, { InputField } from 'components/Form';
import PackageImagesThumbnails from 'pages/packageDetail/PackageDetailForm/PackageImagesThumbnails';
import { PackagingType, FullPackageDTO } from 'dto/package';
import Picture from 'components/Picture';

interface SingleInstrumentDetailFormValues {
  materialName: string;
  lotNumber?: string;
  manufacturerArticleNumber: string;
  lotNote: string;
  identificationNumber: string;
  lastSynchronisation: string;
  templateName: string;
  amount: number;
  storageLocation: string;
  expectedAmount?: number;
  type: PackagingType;
  manufacturer?: string;
  others: string;
}

interface Props {
  packageDetail: FullPackageDTO;
}

const PackageDetailForm = observer(({ packageDetail }: Props) => {
  const { instrumentStore } = useStores();
  const { t } = useTranslation('singleInstruments');
  const onSubmit = async (values: SingleInstrumentDetailFormValues) => {
    if (!values || Object.keys(values).length === 0) return;
    const packageId = packageDetail.pack.packageId;
    if (values.materialName) {
      const materialId = packageDetail.pack.instruments[0].material.materialId;
      await instrumentStore.updateMaterialInPackage({ name: values.materialName, materialId }, packageId);
    }
    if (values.templateName) {
      await instrumentStore.updateTemplate({ templateName: values.templateName, templateId: packageDetail.template.templateId }, packageId);
    }
  };

  const initialValues: SingleInstrumentDetailFormValues = {
    materialName: packageDetail.pack.instruments[0].material.name,
    lotNumber: packageDetail.pack.lotNumber?.lotNumber || '',
    manufacturerArticleNumber: packageDetail.pack.instruments[0].material.manufacturerArticleNumber || '',
    lotNote: packageDetail.pack.lotNumber?.note || '',
    identificationNumber: packageDetail.pack.serialNumber,
    lastSynchronisation: packageDetail.pack.lastSynchronisation || '',
    templateName: packageDetail.template.name,
    amount: packageDetail.pack.instruments[0].amount,
    storageLocation: '',
    expectedAmount: packageDetail.pack.instruments[0].expectedAmount,
    type: packageDetail.template.packagingType,
    manufacturer: packageDetail.pack.instruments[0].material.manufacturerName,
    others: ''
  };

  const onUpdateFile = async (fileId: string) => {
    if (instrumentStore.fullPackageItem) {
      await instrumentStore.updateMaterialInPackage(
        { pictureFileId: fileId, materialId: instrumentStore.fullPackageItem?.pack.instruments[0].material.materialId },
        packageDetail.pack.packageId
      );
    }
  };

  const onDeleteFile = async (fileId: string) => {
    const packageFileIds = packageDetail.pack.pictures.map(f => f.pictureFileId);
    await instrumentStore.updatePackage({
      pictureFileIds: packageFileIds.filter(id => id !== fileId),
      packageId: packageDetail.pack.packageId
    });
  };

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  useEffect(() => {
    const handleSubmitMyForm = () => {
      if (submitMyForm) submitMyForm();
    };
    return () => {
      handleSubmitMyForm();
    };
  });

  useEffect(() => {
    if (packageDetail.pack.instruments[0]) {
      instrumentStore.loadStorageLocations({ packageId: packageDetail.pack.packageId });
    }
  }, [instrumentStore, packageDetail.pack]);

  const erpStorageLocationsString = instrumentStore.erpStorageLocations.map(sl => sl.originStorageLocation.name).join(', ');

  return (
    <div className="single_colum_content">
      <div>
        <div className="div-block-45">
          <div className="stamm_big_image_wrapper">
            <div className="div-block-101">
              <div className="image_post_wide in_data">
                {packageDetail.pack.instruments[0].material.pictureSmall ? (
                  <Picture
                    alt=""
                    src={{
                      picture: packageDetail.pack.instruments[0].material.picture || '',
                      pictureFileId: packageDetail.pack.instruments[0].material.pictureFileId || '',
                      pictureSmall: packageDetail.pack.instruments[0].material.pictureSmall,
                      pictureThumbnail: packageDetail.pack.instruments[0].material.pictureThumbnail || ''
                    }}
                  />
                ) : (
                  <GetCameraImageButton buttonStyle="detailForm" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
                )}
              </div>
              <GetCameraImageButton
                isButtonVisible
                apiCall={savePackageFile}
                description={
                  packageDetail.pack.instruments[0].material.picture && packageDetail.pack.instruments[0].material.pictureFileId
                    ? t('detail.button.toChange')
                    : t('detail.button.toAdd')
                }
                onUpdateFile={onUpdateFile}
                buttonStyle="square"
                picturesOnly
                isAddable
              />
            </div>
          </div>
          <PackageImagesThumbnails images={packageDetail.pack.pictures} onImageDelete={onDeleteFile} />
        </div>
      </div>
      <div className="div-block-44">
        <Form initialValues={initialValues} bindSubmitForm={bindSubmitForm} onSubmit={onSubmit}>
          {() => (
            <>
              <div className="content_field">
                <div>{t('detail.label.materialName')}</div>
                <InputField name="materialName" placeholder={t('detail.label.materialName')} />
              </div>
              <div className="content_field">
                <div>{t('detail.label.lotNumber')}</div>
                <InputField name="lotNumber" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('detail.label.manufacturerArticleNumber')}</div>
                <InputField name="manufacturerArticleNumber" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('detail.label.diverseLotNumber')}</div>
                <InputField name="lotNote" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('detail.label.identificationNumber')}</div>
                <InputField name="identificationNumber" placeholder={t('detail.label.identificationNumber')} viewOnly />
              </div>
              <div className="content_field">
                <div>{t('detail.label.lastSynchronisation')}</div>
                <InputField name="lastSynchronisation" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('detail.label.templateName')}</div>
                <InputField name="templateName" />
              </div>
              <div className="content_field">
                <div>{t('detail.label.amount')}</div>
                <InputField name="amount" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('detail.label.storageLocation')}</div>
                <InputField name="storageLocation" value={erpStorageLocationsString} viewOnly />
              </div>
              <div className="content_field">
                <div>{t('detail.label.expectedAmount')}</div>
                <InputField name="expectedAmount" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('detail.label.type')}</div>
                <div className="input_field without_input top-margin">
                  <div className="material_note-copy">
                    {t(`detail.packagingTypes.${PackagingType[packageDetail.template.packagingType].toLocaleLowerCase()}`)}
                  </div>
                </div>
              </div>
              <div className="content_field">
                <div>{t('detail.label.manufacturer')}</div>
                <InputField name="manufacturer" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('detail.label.others')}</div>
                <InputField name="others" viewOnly />
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  );
});

export default PackageDetailForm;
