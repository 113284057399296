import React from 'react';
import { Field } from 'formik';
import './Input.css';

interface Props {
  placeholder?: string;
  name: string;
  type?: string;
}

const LoginInput = ({ name, placeholder, ...rest }: Props) => {
  return <Field className="text-field w-input" placeholder={placeholder} name={name} maxLength={255} {...rest} />;
};
export default LoginInput;
