import React, { useState, useEffect } from 'react';
import { isUUID } from 'class-validator';
import { useStores } from 'util/mobx/stores';
import GlobalFunctions from 'components/GlobalFunctions';
import { useTranslation } from 'react-i18next';
import MaterialGlobalFunctionsRightMenu from './MaterialGlobalFunctionsRightMenu';

interface Props {
  materialId: string;
}

const MaterialGlobalFunctionsGrid = ({ materialId }: Props) => {
  const [loading, setLoading] = useState(false);
  const { materialStore, appNavigationStore } = useStores();

  const { t } = useTranslation('material');

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <MaterialGlobalFunctionsRightMenu />;
    });
  }, [appNavigationStore]);

  appNavigationStore.useSubPageIdSetter('functions');

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      await materialStore.loadMaterialGuides(materialId);
      setLoading(false);
    }
    loadData();
  }, [materialStore, materialId]);
  if (isUUID(materialId) && !loading && materialStore.materialGuides) {
    return <GlobalFunctions filterButtonLabel={t('materialGlobalFunctions.button.containingGuidesFilter')} />;
  }
  return null;
};

export default MaterialGlobalFunctionsGrid;
