import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import SideMenuIconButton from 'components/SideMenuIconButton';

const PackageFunctionsRightMenu = observer(() => {
  const { t } = useTranslation('packages');
  const { t: searchTranslation } = useTranslation('materialSearch');
  const { instrumentStore, searchStore, materialStore, guideStore } = useStores();

  const handlerReplaceGuidesMaterial = async () => {
    searchStore.openSearchPanel(m => {
      if (instrumentStore.fullPackageItem?.template) {
        guideStore.replaceMaterialInGuide(m, { templateId: instrumentStore.fullPackageItem.template.templateId }).then(() => {
          if (instrumentStore.fullPackageItem) {
            instrumentStore.loadTemplateGuides(instrumentStore.fullPackageItem.template.templateId);
          }
        });
      }
    }, searchTranslation('searchItem.replace'));
  };

  const handlerDeleteGuidesMaterial = async () => {
    if (instrumentStore.fullPackageItem) {
      await materialStore.deleteGuidesMaterial({
        templateId: instrumentStore.fullPackageItem.template.templateId
      });
      instrumentStore.loadTemplateGuides(instrumentStore.fullPackageItem.template.templateId);
    }
  };

  return (
    <>
      <SideMenuIconButton
        handleClick={handlerReplaceGuidesMaterial}
        label={t('globalFunctions.rightMenu.replaceMaterial')}
        iconPath="icon_find_16.svg"
        disabled={instrumentStore.templateGuides.length === 0}
      />
      <SideMenuIconButton
        handleClick={handlerDeleteGuidesMaterial}
        disabled={instrumentStore.templateGuides.length === 0}
        red
        label={t('globalFunctions.rightMenu.deleteMaterial')}
        iconPath="icon_bin_16.svg"
      />
    </>
  );
});

export default PackageFunctionsRightMenu;
