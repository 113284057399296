import React from 'react';
import HoverWrapper from 'components/HoverWrapper';
import LinkBackWrapper from 'components/LinkBackWrapper';
import NavSectionBackButtonDesktopBody from './NavSectionBackButtonDesktopBody';

interface Props {
  goBack: () => void;
}

const NavSectionBackButtonDesktop = (props: Props) => {
  return (
    <LinkBackWrapper goBack={props.goBack}>
      <HoverWrapper>{({ hover }) => <NavSectionBackButtonDesktopBody hover={hover} />}</HoverWrapper>
    </LinkBackWrapper>
  );
};

export default NavSectionBackButtonDesktop;
