import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import * as qs from 'query-string';
import { ContentType, ProcessingStatus } from 'dto/file';
import { useStores } from 'util/mobx/stores';
import AppLayout from 'components/AppLayout';
import CreateMaterialSynonymFlyout from 'components/MaterialKnowledge/CreateMaterialSynonymFlyout';
import { AppBarHeaderText, AppBarPageIcon } from 'components/AppLayout/AppBar';
import MaterialDetail from 'pages/materialDetail/MaterialDetail';
import MaterialGlobalFunctionsGrid from 'pages/materialGlobalFunctions/MaterialGlobalFunctionsGrid';
import MaterialOverview from 'pages/materialOverviewPage/MaterialOverview';
import MaterialContent from 'pages/materialContent/MaterialContent';
import MaterialStorageLocations from 'pages/materialStorageLocations';
import StorageLocationDetailDrawer from 'pages/locationAdministration/StorageLocationDetailDrawer';
import SelectLocationFlyout from 'pages/flyouts/SelectLocationFlyout';
import Safe from 'components/Safe';
import Page from 'components/Page';
import MaterialKnowledgeFormFlyout from 'components/MaterialKnowledge/MaterialKnowledgeFormFlyout';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import { ChatConfigurationTypes } from 'components/Chat/Chat';
import MaterialValueTags from 'components/MaterialValueTags';
import MaterialNavSection from './MaterialNavSection';

const Material = observer(() => {
  const { materialStore, flyoutStore } = useStores();
  const { t } = useTranslation('material');
  const location = useLocation();
  const materialId = qs.parse(location.search).materialId as string;
  useEffect(() => {
    materialStore.setMaterialId(materialId);
    materialStore.loadMaterial(materialId);
  }, [materialId, materialStore]);

  const onShowMaterialPicture = () => {
    if (!materialStore.materialDetail || !materialStore.materialDetail.picture || !materialStore.materialDetail.pictureFileId) {
      return;
    }
    flyoutStore.setImagesToView({
      fullUrl: materialStore.materialDetail.picture,
      fileId: materialStore.materialDetail.pictureFileId,
      contentType: ContentType.Picture,
      status: ProcessingStatus.Finished,
      originalFileName: ''
    });
  };

  const onUpdateFile = async (fileId: string) => {
    await materialStore.updateMaterial({ materialId, pictureFileId: fileId });
  };

  return (
    <>
      <AppLayout
        appBarIcon={
          <AppBarPageIcon
            onClick={onShowMaterialPicture}
            key={materialStore.materialDetail?.pictureThumbnail}
            src={materialStore.materialDetail?.pictureThumbnail}
            onUpdateFile={onUpdateFile}
          />
        }
        appBarChildren={<AppBarHeaderText headline={materialStore.materialDetail?.name || ''} />}
        appBarRightChildren={<MaterialValueTags fullInfo classNameTitle="margin_left" material={materialStore.materialDetail} />}
        boardFlyout={<BoardFlyout />}
        drawer={<StorageLocationDetailDrawer />}
        flyout={
          <>
            <MaterialKnowledgeFormFlyout />
            <CreateMaterialSynonymFlyout />
            <SelectLocationFlyout />
          </>
        }
      >
        <Page
          headline={t('subMenu.headline')}
          idPrefix="single-material"
          navigation={<MaterialNavSection materialId={materialId} />}
          chatConfigurationType={ChatConfigurationTypes.department}
        >
          <Switch>
            <Route path="/material/overview" component={() => <MaterialOverview materialId={materialId} />} />
            <Route path="/material/functions" component={() => <MaterialGlobalFunctionsGrid materialId={materialId} />} />
            <Route path="/material/detail" component={() => <MaterialDetail materialId={materialId} />} />
            <Route path="/material/content" component={() => <MaterialContent materialId={materialId} />} />
            <Route path="/material/locations" component={() => <MaterialStorageLocations materialId={materialId} />} />
          </Switch>
        </Page>
      </AppLayout>
    </>
  );
});

// moved Safe here to prevent running the useEffect if not logged in
export default () => (
  <Safe>
    <Material />
  </Safe>
);
