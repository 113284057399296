import { useGetUsedSurgeryGuideMaterials, useSetUsedSurgeryGuideMaterials } from 'api/surgeryGuideHooks';
import { CreateUsedMaterialDTO, SetUsedMaterialDTO, UsedMaterialDTO, UsedMaterialStatus } from 'dto/usedMaterial';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { LoadingType } from 'stores/loadingStore';
import EditLotNumberFlyoutV2 from '../Flyouts/EditLotNumberFlyoutV2';

export interface UsedMaterialState {
  onReload: () => Promise<void>;
  getStatus: (surgeryGuideMaterialId: string, packageId?: string, groupMaterialId?: string) => UsedMaterialStatus | undefined;
  getLot: (surgeryGuideMaterialId: string, packageId?: string, groupMaterialId?: string) => string | undefined;
  setStatus: (status: UsedMaterialStatus, surgeryGuideMaterialId: string, groupMaterialId?: string, packageId?: string) => void;
  setLot: (lotNumber: string | undefined, surgeryGuideMaterialId: string, groupMaterialId?: string, packageId?: string) => void;
  isTemplateComplete: (surgeryGuideMaterialId: string, amount: number) => boolean;
  openLotDialog: (usedMaterial: CreateUsedMaterialDTO) => void;

  isInitialLoading: boolean;
  usedMaterials: UsedMaterialDTO[];
}

const UsedMaterialContext = React.createContext<UsedMaterialState | undefined>(undefined);

export function useUsedMaterialContext() {
  const state = useContext(UsedMaterialContext);
  if (state === undefined) {
    throw new Error('use of UsedMaterialContext without provider');
  }

  return state;
}

interface Props {
  children: React.ReactNode;
  surgeryGuideId: string;
}

function buildKey(surgeryGuideMaterialId: string, groupMaterialId?: string, packageId?: string) {
  return `${surgeryGuideMaterialId}-${groupMaterialId || ''}-${packageId || ''}`;
}

function buildKeyFrom(usedMaterial: UsedMaterialDTO) {
  return buildKey(usedMaterial.surgeryGuideMaterialId, usedMaterial.groupMaterialId, usedMaterial.packageId);
}

export const UsedMaterialProvider: React.FC<Props> = ({ surgeryGuideId, children }) => {
  const { data, refetch, isInitialLoading } = useGetUsedSurgeryGuideMaterials(surgeryGuideId, {});
  const { mutate } = useSetUsedSurgeryGuideMaterials(surgeryGuideId, {
    loadingType: LoadingType.ONLY_LOADING
  });
  const [isLotDialogOpen, setLotDialogOpen] = useState(false);
  const [lotDialogUsedMaterial, setLotDialogUsedMaterial] = useState<CreateUsedMaterialDTO>({ surgeryGuideMaterialId: '0' });

  const handleDialogOpen = (usedMaterial: CreateUsedMaterialDTO) => {
    setLotDialogUsedMaterial(usedMaterial);
    setLotDialogOpen(true);
  };

  const handleDialogClose = () => {
    setLotDialogOpen(false);
    setLotDialogUsedMaterial({ surgeryGuideMaterialId: '0' });
  };

  /**
   * The key is built using buildKey.
   */
  const [usedMaterials, setUsedMaterials] = useState(new Map<string, SetUsedMaterialDTO>());

  // Fill the usedMaterials initially
  useEffect(() => {
    if (!data?.usedMaterials) {
      return;
    }

    const newUsedMaterials = new Map<string, SetUsedMaterialDTO>();
    data.usedMaterials.forEach(usedMaterial => {
      newUsedMaterials.set(buildKeyFrom(usedMaterial), {
        surgeryGuideMaterialId: usedMaterial.surgeryGuideMaterialId,
        status: usedMaterial.status,
        lotNumber: usedMaterial.lotNumber,
        groupMaterialId: usedMaterial.groupMaterialId,
        packageId: usedMaterial.packageId
      });
    });

    setUsedMaterials(newUsedMaterials);
  }, [data]);

  const setUsedMaterial = useCallback(
    (
      status: UsedMaterialStatus | undefined,
      lotNumber: string | undefined,
      surgeryGuideMaterialId: string,
      groupMaterialId?: string,
      packageId?: string
    ) => {
      mutate(
        {
          usedMaterials: [{ surgeryGuideMaterialId, groupMaterialId, lotNumber, packageId, status }]
        },
        {
          onError: console.error,
          onSuccess: () => console.log('saved checks successfull')
        }
      );

      setUsedMaterials(current => {
        let changed = false;
        const key = buildKey(surgeryGuideMaterialId, groupMaterialId, packageId);
        let currentUsed = current.get(key);
        if (currentUsed === undefined) {
          currentUsed = {
            surgeryGuideMaterialId,
            groupMaterialId,
            packageId,
            status: status || UsedMaterialStatus.None,
            lotNumber: lotNumber || undefined
          };
          changed = true;
        } else {
          if (status !== undefined && currentUsed.status !== status) {
            currentUsed.status = status;
            changed = true;
          }
          if (lotNumber !== undefined && currentUsed.lotNumber !== lotNumber) {
            currentUsed.lotNumber = lotNumber;
            changed = true;
          }
        }

        if (changed) {
          current.set(key, currentUsed);
          return new Map(current.entries());
        }

        return current;
      });
    },
    [mutate]
  );

  const state: UsedMaterialState = useMemo(() => {
    return {
      onReload: async () => {
        await refetch();
      },
      getStatus: (surgeryGuideMaterialId: string, packageId?: string, groupMaterialId?: string): UsedMaterialStatus => {
        return usedMaterials.get(buildKey(surgeryGuideMaterialId, groupMaterialId, packageId))?.status || UsedMaterialStatus.None;
      },
      getLot: (surgeryGuideMaterialId: string, packageId?: string, groupMaterialId?: string): string | undefined => {
        return usedMaterials.get(buildKey(surgeryGuideMaterialId, groupMaterialId, packageId))?.lotNumber;
      },
      setStatus: (status: UsedMaterialStatus, surgeryGuideMaterialId: string, groupMaterialId?: string, packageId?: string) => {
        console.log('set status', status, surgeryGuideMaterialId, groupMaterialId, packageId);
        setUsedMaterial(status, undefined, surgeryGuideMaterialId, groupMaterialId, packageId);
      },
      setLot: (lotNumber: string | undefined, surgeryGuideMaterialId: string, groupMaterialId?: string, packageId?: string) => {
        setUsedMaterial(undefined, lotNumber, surgeryGuideMaterialId, groupMaterialId, packageId);
      },
      isTemplateComplete: (surgeryGuideMaterialId: string, amount: number) => {
        const usedMaterialValues = Array.from(usedMaterials.values());
        const checkedSurgeryItems = usedMaterialValues
          .filter(
            item =>
              item.status !== UsedMaterialStatus.None &&
              item.surgeryGuideMaterialId === surgeryGuideMaterialId &&
              (item.groupMaterialId !== undefined || item.packageId !== undefined)
          )
          .filter(item => item).length;
        return checkedSurgeryItems >= amount;
      },

      openLotDialog: handleDialogOpen,

      isInitialLoading,
      usedMaterials: data?.usedMaterials || []
    };
  }, [data, isInitialLoading, refetch, setUsedMaterial, usedMaterials]);

  return (
    <UsedMaterialContext.Provider value={state}>
      <>
        {children}
        <EditLotNumberFlyoutV2
          isOpen={isLotDialogOpen}
          handleClose={handleDialogClose}
          usedMaterial={lotDialogUsedMaterial}
          getLot={state.getLot}
          setLot={state.setLot}
        />
      </>
    </UsedMaterialContext.Provider>
  );
};
