import { CreateSetGroupDTO, UpdateSetGroupDTO, UpdateSortSetGroupsDTO, FullSetGroupDTO, SetGroupsWithTagsDTO } from 'dto/setGroup';
import { post, patch, del, put, get } from './common';
import { validate, validateBool } from './validation';

export const getSetGroups = async (materialSetId: string): Promise<SetGroupsWithTagsDTO> => {
  const groups = await get('/material-set/set-groups', { materialSetId }).then(response => response.data);
  return validate(SetGroupsWithTagsDTO, groups);
};

export const createSetGroup = async (setGroup: CreateSetGroupDTO): Promise<FullSetGroupDTO> => {
  const newSetGroup = await post('/material-set/set-group', setGroup).then(response => response.data);
  return validate(FullSetGroupDTO, newSetGroup);
};

export const updateSetGroup = async (materialSetUpdateFields: UpdateSetGroupDTO): Promise<FullSetGroupDTO> => {
  const updatedSetGroup = await patch(`/material-set/set-group`, materialSetUpdateFields).then(response => response.data);
  return validate(FullSetGroupDTO, updatedSetGroup);
};

export const deleteSetGroup = async (setGroupId: string) => {
  return del('/material-set/set-group', { setGroupId }).then(response => validateBool(response.data));
};

export const reorderSetGroups = async (reordered: UpdateSortSetGroupsDTO) => {
  return put('/material-set/set-groups/positions', reordered).then(response => validateBool(response.data));
};
