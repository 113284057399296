import { observable, action, runInAction } from 'mobx';
import { getTemplates, getTemplateGuides, updateTemplate } from 'api/template';
import { getPackage, updatePackage } from 'api/package';
import { getInstrument } from 'api/instrument';
import { TemplateDTO, UpdateTemplateDTO } from 'dto/template';
import { FullPackageDTO, UpdatePackageDTO, PackageDTO } from 'dto/package';
import { GroupedMaterialGuidesDTO, UpdateMaterialDTO, MaterialLikeIdOrPackageIdDTO } from 'dto/material';
import { updateMaterial, getMaterialStorageLocations } from 'api/material';
import { InstrumentDTO } from 'dto/instrument';
import { MaterialStorageLocationsDTO, LocationSource } from 'dto/storageLocation';
import LoadingStore from './loadingStore';

export default class InstrumentStore {
  @observable
  private loadingStore: LoadingStore;

  @observable
  packageId?: string;

  @observable
  fullPackageItem?: FullPackageDTO;

  @observable
  instrumentId?: string;

  @observable
  instrument?: InstrumentDTO;

  @observable
  templates?: TemplateDTO[];

  @observable
  packageItem?: PackageDTO;

  @observable
  templateGuides: GroupedMaterialGuidesDTO[] = [];

  @observable
  newKnowledgePosition?: number = undefined;

  @observable
  erpStorageLocations: MaterialStorageLocationsDTO[] = [];

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;
  }

  @action
  clear() {
    this.packageId = undefined;
    this.fullPackageItem = undefined;
    this.instrumentId = undefined;
    this.instrument = undefined;
    this.templates = undefined;
    this.packageItem = undefined;
    this.templateGuides = [];
    this.newKnowledgePosition = undefined;
  }

  @action
  setPackageId(packageId: string) {
    this.packageId = packageId;
  }

  @action
  setInstrumentId(instrumentId: string) {
    this.instrumentId = instrumentId;
  }

  @action
  async loadFullPackage(packageId: string) {
    this.fullPackageItem = undefined;
    const fullPackageItem = await this.loadingStore.withLoadingBar(() => getPackage(packageId));
    runInAction(() => {
      this.fullPackageItem = fullPackageItem;
    });
  }

  async loadStorageLocations(materialLikeOrPackageId: MaterialLikeIdOrPackageIdDTO) {
    const erpStorageLocations = await this.loadingStore.withLoadingBar(() =>
      getMaterialStorageLocations({ ...materialLikeOrPackageId, locationSource: LocationSource.ERP })
    );
    runInAction(() => {
      this.erpStorageLocations = erpStorageLocations;
    });
  }

  async loadInstrument(instrumentId: string) {
    const instrument = await this.loadingStore.withLoadingBar(() => getInstrument(instrumentId));
    runInAction(() => {
      this.instrument = instrument;
    });
  }

  async updatePackage(packageItem: UpdatePackageDTO, setFullPackageItem = true) {
    const updatedPackageItem = await this.loadingStore.withLoadingBar(() => updatePackage(packageItem));
    runInAction(() => {
      if (!setFullPackageItem) {
        return;
      }
      this.fullPackageItem = updatedPackageItem;
    });
  }

  async updateTemplate(template: UpdateTemplateDTO, packageId: string) {
    await this.loadingStore.withLoadingBar(() => updateTemplate(template));
    const packageItem = await this.loadingStore.withLoadingBar(() => getPackage(packageId));
    runInAction(() => {
      this.fullPackageItem = packageItem;
    });
  }

  async loadTemplates(departmentId?: string) {
    const templates = await this.loadingStore.withLoadingBar(() => getTemplates({ departmentId }));
    runInAction(() => {
      this.templates = templates;
    });
  }

  async updateMaterial(fields: UpdateMaterialDTO, setInstrumentDetail = true) {
    const material = await this.loadingStore.withLoadingBar(() => updateMaterial(fields));
    runInAction(() => {
      if (!setInstrumentDetail) {
        return;
      }
      if (this.instrument) {
        this.instrument = {
          ...this.instrument,
          material
        };
      }
    });
  }

  async updateMaterialInPackage(fields: UpdateMaterialDTO, packageId: string) {
    await this.loadingStore.withLoadingBar(() => updateMaterial(fields));
    const fullPackageItem = await this.loadingStore.withLoadingBar(() => getPackage(packageId));
    runInAction(() => {
      this.fullPackageItem = fullPackageItem;
    });
  }

  async loadTemplateGuides(templateId: string) {
    const templateGuides = await this.loadingStore.withLoadingBar(() => getTemplateGuides(templateId));
    runInAction(() => {
      this.templateGuides = templateGuides;
    });
  }

  @action
  setPackage(packageItem: PackageDTO) {
    this.packageItem = packageItem;
  }
}
