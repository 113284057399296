import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import SettingsItem from 'components/SettingsItem';
import { SettingsItemTypes } from 'stores/settingsStore';
import { LocationDTO } from 'dto/location';
import SettingsBlock from 'components/SettingsBlock';

const LocationsColumn = observer(() => {
  const { t } = useTranslation('settings');
  const { settingsStore } = useStores();
  useEffect(() => {
    settingsStore.loadLocations();
  }, [settingsStore]);

  const onActivate = (itemId: string) => {
    settingsStore.updateLocation({
      locationId: itemId,
      disabled: false
    });
  };

  const onDeactivate = (itemId: string) => {
    settingsStore.updateLocation({
      locationId: itemId,
      disabled: true
    });
  };

  const onCreateFlyoutOpen = () => {
    settingsStore.setItemFormFlyoutType(SettingsItemTypes.location);
    settingsStore.setItemFormFlyoutOpen(true);
  };

  const onSelect = (location: LocationDTO) => {
    if (location.locationId === settingsStore.selectedLocation?.locationId) {
      return;
    }
    settingsStore.setSelectedLocation(location);
    settingsStore.setSelectedFunctionalArea(null);
  };

  return (
    <SettingsBlock title={t('administration.locationsTitle')} onCreateFlyoutOpen={onCreateFlyoutOpen}>
      <div className="area_block">
        {settingsStore.locations.map(location => (
          <SettingsItem
            itemId={location.locationId}
            onActivate={onActivate}
            onDeActivate={onDeactivate}
            key={location.locationId}
            name={location.name}
            shortName={location.shortName}
            disabled={location.disabled}
            type={SettingsItemTypes.location}
            onClick={() => onSelect(location)}
            selected={location.locationId === settingsStore.selectedLocation?.locationId}
          />
        ))}
      </div>
    </SettingsBlock>
  );
});

export default LocationsColumn;
