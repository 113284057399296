import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { CSSTransition } from 'react-transition-group';
import { GroupedSurgeryGuideProcedureDTO } from 'dto/surgeryGuideProcedure';
import SurgeryProcedureDrawerMainContent from './SurgeryProcedureDrawerMainContent';
import SurgeryProcedureDrawerSidebar from './SurgeryProcedureDrawerSidebar';
import SurgeryProcedureDrawerFooter from './SurgeryProcedureDrawerFooter';
import './SurgeryProceduresDrawer.css';

const findFirstChapterWithChekableProcedures = (groupedGuideProcedures: GroupedSurgeryGuideProcedureDTO[]) => {
  let selectedChapter = '';
  groupedGuideProcedures.forEach(groupedGuideProcedure => {
    if (groupedGuideProcedure.surgeryGuideProcedures.filter(p => p.content.isCheckable).length > 0 && !selectedChapter) {
      selectedChapter = groupedGuideProcedure.chapter;
    }
  });
  return selectedChapter;
};

const SurgeryProceduresDrawer = observer(() => {
  const { surgeryGuideStore } = useStores();

  useEffect(() => {
    if (
      surgeryGuideStore.groupedGuideProcedures &&
      surgeryGuideStore.groupedGuideProcedures.length > 0 &&
      !surgeryGuideStore.selectedProcedureChapter
    ) {
      const selectedChapter = findFirstChapterWithChekableProcedures(surgeryGuideStore.groupedGuideProcedures);
      surgeryGuideStore.selectProcedureChapter(selectedChapter);
    }
  }, [surgeryGuideStore, surgeryGuideStore.groupedGuideProcedures]);

  return (
    <CSSTransition in={surgeryGuideStore.isProcedureDrawerOpen} timeout={500} classNames="pilot" unmountOnExit>
      <div className="op-pilot op-pilot-styled">
        <div className="w-layout-grid grid-6">
          <SurgeryProcedureDrawerMainContent />
          <SurgeryProcedureDrawerSidebar />
          <SurgeryProcedureDrawerFooter />
        </div>
      </div>
    </CSSTransition>
  );
});

export default SurgeryProceduresDrawer;
