/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MaterialDetailForm from './MaterialDetailForm';

interface Props {
  materialId: string;
}

const MaterialGlobalFunctionsGrid = observer(({ materialId }: Props) => {
  const { t } = useTranslation('material');
  const [loading, setLoading] = useState(false);
  const { domainStore, materialStore, appNavigationStore } = useStores();
  useEffect(() => {
    async function loadData() {
      setLoading(true);
      if (domainStore.departments.length === 0) {
        await domainStore.loadAllDepartments();
      }
      if (materialStore.materialDetail?.materialId !== materialId) await materialStore.loadMaterial(materialId);
      setLoading(false);
    }
    loadData();
  }, [domainStore, materialStore, materialId]);

  appNavigationStore.useSubPageIdSetter('detail');

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.withoutMenu();
  }, [appNavigationStore]);
  if (!loading && materialStore.materialDetail) {
    return (
      <MaterialDetailForm
        departments={domainStore.departments}
        materialDetail={materialStore.materialDetail}
        departmentsDropdownOptions={[
          {
            label: t('materialDetail.options.empty.label'),
            options: [
              {
                value: null,
                label: t('materialDetail.options.empty.label'),
                group: ''
              }
            ]
          },
          ...domainStore.departmentsDropdownOptions
        ]}
      />
    );
  }
  return null;
});

export default MaterialGlobalFunctionsGrid;
