import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import Safe from 'components/Safe';
import Page from 'components/Page';
import { AppBarHeaderText, AppBarPageIcon } from 'components/AppLayout/AppBar';
import AppLayout from 'components/AppLayout';
import SettingsAdministration from 'pages/settingsAdministration';
import SettingsBoard from 'pages/settingsBoard';
import SettingsKnowledgeTopics from 'pages/settingsKnowledgeTopics';
import SettingsBackup from 'pages/settingsBackup';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import SettingsSurgeryRooms from 'pages/settingsSurgeryRooms';
import SettingsNavigation from './SettingsNavigation';
import SettingsItemFlyoutForm from './SettingsItemFlyoutForm';

const Settings = observer(() => {
  const { t } = useTranslation('settings');
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;
  return (
    <Safe>
      <AppLayout
        appBarIcon={<AppBarPageIcon src="images/image_kp-settings.jpg" />}
        appBarChildren={<AppBarHeaderText headline={t('pageTitle')} />}
        flyout={<SettingsItemFlyoutForm />}
        boardFlyout={<BoardFlyout />}
      >
        <Page
          disableBackButton
          headline={t('subMenu.headline')}
          idPrefix="settings"
          withChatBar={false}
          withoutRightMenu
          navigation={<SettingsNavigation currentPath={currentPath} />}
        >
          <Switch>
            <Route path="/settings/administration" component={SettingsAdministration} />
            <Route path="/settings/surgery-rooms" component={SettingsSurgeryRooms} />
            <Route path="/settings/board" component={SettingsBoard} />
            <Route path="/settings/knowledge-topics" component={SettingsKnowledgeTopics} />
            <Route path="/settings/backup" component={SettingsBackup} />
          </Switch>
        </Page>
      </AppLayout>
    </Safe>
  );
});

export default Settings;
