import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import SideMenuIconButton from 'components/SideMenuIconButton';

const ContentRightMenu = observer(() => {
  const { t } = useTranslation('material');
  const { knowledgeStore } = useStores();

  const handleCreateFlyoutOpen = useCallback(() => {
    knowledgeStore.setDataForNewMaterialKnowledge(undefined, 0);
    knowledgeStore.setIsMaterialKnowledgeFormFlyoutOpen(true);
  }, [knowledgeStore]);

  return (
    <>
      <SideMenuIconButton
        handleClick={() => handleCreateFlyoutOpen()}
        label={t('content.button.addNewKnowledge')}
        iconPath="icon_f-add_16.svg"
      />

      <SideMenuIconButton
        handleClick={() => knowledgeStore.setIsCreateMaterialSynonymOpen(true)}
        label={t('content.button.newSynonym')}
        iconPath="icon_f-add_16.svg"
      />
    </>
  );
});

export default ContentRightMenu;
