import { runInAction, extendObservable } from 'mobx';

export interface FromPromiseState<T> {
  value: T;
  isLoading: boolean;
}

/**
 * fromPromiseWithState either returns the defaultValue or if the promise is resolved the result from it.
 * The returned callback returns a value which can be observed using mobX observe()
 *  -> it can trigger a refresh on promise resolve, just like any other mobx value.
 *
 * It is meant for simple usage of promises in computed mobx values without the need for await / then.
 *
 * In contrast to fromPromise, fromPromiseWithState returns a FromPromiseState which contains value and loading state.
 *
 * @param defaultValue Any value, used as default until the promise is resolved.
 * @param promise Any promise returning the same type as the defaultValue has.
 */
export function fromPromiseWithState<T>(defaultValue: T, origPromise: Promise<T>): () => FromPromiseState<T> {
  const promise = origPromise as Promise<T> & FromPromiseState<T>;

  extendObservable(promise, {
    value: defaultValue,
    isLoading: true
  });

  promise.then(value => {
    runInAction(() => {
      promise.value = value;
      promise.isLoading = false;
    });
  });

  return () => promise;
}

/**
 * fromPromise either returns the defaultValue or if the promise is resolved the result from it.
 * The returned callback returns a value which can be observed using mobX observe()
 *  -> it can trigger a refresh on promise resolve, just like any other mobx value.
 *
 * It is meant for simple usage of promises in computed mobx values without the need for await / then.
 *
 * @param defaultValue Any value, used as default until the promise is resolved.
 * @param promise Any promise returning the same type as the defaultValue has.
 */
export function fromPromise<T>(defaultValue: T, origPromise: Promise<T>): () => T {
  const promise = fromPromiseWithState(defaultValue, origPromise)();
  return () => promise.value;
}
