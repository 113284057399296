import GetCameraImageButton from 'components/GetCameraImageButton/GetCameraImageButton';
import Picture from 'components/Picture/Picture';
import { OptionalPictureDTO } from 'dto/file';
import React from 'react';

interface Props {
  onUpdateFile?: (fileId: string) => Promise<void>;
  picture?: OptionalPictureDTO;
  needsStorageLocation?: boolean;
  lazyLoadScrollContainer?: string;
}

const Flag: React.FC<{ show?: boolean }> = ({ show = false }) => {
  return show ? (
    <div className="icon_no_location">
      <img src="images/icon_orange_flag.jpg" alt="" />
    </div>
  ) : null;
};

export const ItemPicture = ({ onUpdateFile, picture, needsStorageLocation = false, lazyLoadScrollContainer }: Props) => {
  return (
    <>
      {picture?.picture ? (
        <Picture
          key={picture.pictureFileId}
          withBorder
          src={picture}
          size="pictureThumbnail"
          width={50}
          alt=""
          lazyLoadScrollContainer={lazyLoadScrollContainer}
          className="image_circle_50"
        >
          <Flag show={needsStorageLocation} />
        </Picture>
      ) : (
        <GetCameraImageButton buttonStyle="icon" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable withLoadingBar>
          <Flag show={needsStorageLocation} />
        </GetCameraImageButton>
      )}
    </>
  );
};
