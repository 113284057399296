import React, { useEffect, useMemo } from 'react';
import { observer, Provider, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import MaterialLikeStorageLocations from 'components/MaterialLikeStorageLocations';
import FlyoutContainer from 'components/FlyoutContainer';
import StorageLocationStore from 'stores/storageLocationStore';
import { MaterialLikeIdOrPackageIdDTO } from 'dto/material';
import { LoadingType } from 'stores/loadingStore';

/**
 * SelectMaterialLocationFlyout uses a local StorageLocationStore instead of the global one.
 * It gets injected here so that all components in this flyout automatically use the local one.
 *
 * -> This is done as workaround to avoid re-introducing a new logik for the flyout.
 * Alternative would be a heavy refactoring of the whole storageLocationStore.
 */
const SelectMaterialLocationFlyout = observer(() => {
  const { t } = useTranslation('storageLocations');
  const { storageLocationStore, guideDetailDrawerStore, domainStore, loadingStore, guideStore } = useStores();

  // creating a new local store by just creating a new instance of the StorageLocationStore
  const localStorageLocationStore = useLocalStore(
    () => new StorageLocationStore(domainStore, loadingStore, guideStore, LoadingType.FLYOUT)
  );

  // load the data for the localStorageLocationStore whenever the selectMaterialStorageLocationFlyoutMaterial of the global store changes -> e.g. the flyout opens.
  useEffect(() => {
    if (storageLocationStore.selectMaterialStorageLocationFlyoutMaterial) {
      localStorageLocationStore.setMaterialLikeId(storageLocationStore.selectMaterialStorageLocationFlyoutMaterial);
      localStorageLocationStore.loadMaterialLikeLocations(undefined);
    }
  }, [localStorageLocationStore, storageLocationStore.selectMaterialStorageLocationFlyoutMaterial]);

  // These on-callbacks partly use the localStorageLocationStore and partly the storageLocationStore.
  // They basically get the data from the local on and then set it on the global store.
  const onClose = () => {
    storageLocationStore.closeSelectMaterialLocationFlyout();
    // wait until flyout closed
    setTimeout(() => {
      localStorageLocationStore.clearStore();
    }, 1000);
  };

  const onSelect = async () => {
    if (localStorageLocationStore.selectedMaterialStorageLocation && storageLocationStore.selectedMaterialFlyoutCallback) {
      storageLocationStore.selectedMaterialFlyoutCallback(localStorageLocationStore.selectedMaterialStorageLocation.storageLocationId);
    }

    onClose();
  };

  const materialLikeOrPackageId: MaterialLikeIdOrPackageIdDTO | undefined = useMemo(() => {
    if (guideDetailDrawerStore.selectedMaterial) {
      return { materialId: guideDetailDrawerStore.selectedMaterial.materialId };
    }

    if (guideDetailDrawerStore.selectedMaterialSet) {
      return { materialSetId: guideDetailDrawerStore.selectedMaterialSet.materialSetId };
    }

    if (guideDetailDrawerStore.selectedPackage) {
      return { packageId: guideDetailDrawerStore.selectedPackage.packageId };
    }

    return undefined;
  }, [guideDetailDrawerStore.selectedMaterial, guideDetailDrawerStore.selectedMaterialSet, guideDetailDrawerStore.selectedPackage]);

  return (
    // Inject the localStorageLocationStore here as the storageLocationStore.
    // This basically "shadows" the global storageLocationStore by the local one for all child components.
    // -> Whenever they try to access the global store, the local store is used instead.
    <Provider storageLocationStore={localStorageLocationStore}>
      <FlyoutContainer
        icon={<img src="images/icon_location.jpg" width="50" alt="" className="image_circle_40" />}
        isOpen={!!storageLocationStore.selectMaterialStorageLocationFlyoutMaterial}
        closePopUp={onClose}
        cancelLabel={t('selectLocationFlyout.cancel')}
        onSubmit={storageLocationStore.selectedMaterialFlyoutCallback && onSelect}
        submitLabel={
          storageLocationStore.selectedMaterialFlyoutCallback &&
          t('selectLocationFlyout.submit', {
            locationName: localStorageLocationStore.selectedMaterialStorageLocation
              ? localStorageLocationStore.selectedMaterialStorageLocation.name
              : ''
          })
        }
        isAllowedToSubmit={!!localStorageLocationStore.selectedMaterialStorageLocation}
        title={t('selectLocationFlyout.headline')}
        iosMarginTop
      >
        <div className="div-block-149">
          <div className="div-block-131 padding padding_bottom location-list-container">
            {materialLikeOrPackageId ? <MaterialLikeStorageLocations materialLikeOrPackageId={materialLikeOrPackageId} inFlyout /> : null}
          </div>
        </div>
      </FlyoutContainer>
    </Provider>
  );
});

export default SelectMaterialLocationFlyout;
