import React from 'react';

interface Props {
  title: string;
  description: string;
}
function HelperImageDragAndDrop(props: Props) {
  return (
    <div className="picture_helper">
      <div className="div-block-39">
        <img src="images/info_16_grey.svg" alt="" className="image_help_icon" />
        <div className="div-block-40 block-wrap">
          <div className="text-block-11">{props.title}</div>
          <div>{props.description}</div>
        </div>
      </div>
      <img src="images/helper_dragdrop.jpg" width="115" alt="" className="image-5" />
    </div>
  );
}

export default HelperImageDragAndDrop;
