import { GroupedMaterialGuidesDTO } from 'dto/material';
import { GetTemplatesDTO, TemplateDTO, UpdateTemplateDTO, SimpleTemplateDTO, MultiTemplateDTO, SingleTemplateDTO } from 'dto/template';
import { get, patch } from './common';

import { validateArray, validate } from './validation';

export const getTemplates = async (query: GetTemplatesDTO): Promise<TemplateDTO[]> => {
  const templates = await get('/templates', query).then(response => response.data);
  return validateArray(TemplateDTO, templates);
};

export const getTemplate = async (templateId: string): Promise<TemplateDTO> => {
  const template = await get('/template', { templateId }).then(response => response.data);
  return validate(TemplateDTO, template);
};

export const getSingleTemplateV2 = async (templateId: string): Promise<SingleTemplateDTO> => {
  const template = await get('/v2/single-template', { templateId }).then(response => response.data);
  return validate(SingleTemplateDTO, template);
};

export const getMultiTemplateV2 = async (templateId: string): Promise<MultiTemplateDTO> => {
  const template = await get('/v2/multi-template', { templateId }).then(response => response.data);
  return validate(MultiTemplateDTO, template);
};

export const getTemplateGuides = async (templateId: string): Promise<GroupedMaterialGuidesDTO[]> => {
  const guideGroups = await get('/template/guides', { templateId }).then(response => response.data);
  return validateArray(GroupedMaterialGuidesDTO, guideGroups);
};

export const updateTemplate = async (templateToUpdate: UpdateTemplateDTO): Promise<SimpleTemplateDTO> => {
  const updatedTemplate = await patch('/template', templateToUpdate).then(response => response.data);
  return validate(SimpleTemplateDTO, updatedTemplate);
};
