import { AxiosError } from 'axios';
import {
  PostListDTO,
  GetPostDTO,
  PostDTO,
  CreateDepartmentPostDTO,
  CreateGuidePostDTO,
  GetFunctionalAreaPostDTO,
  GetDepartmentPostDTO,
  CreateFunctionalAreaPostDTO,
  DeletePostsDTO,
  GetBriefingPostDTO,
  CreateBriefingPostDTO,
  GetNewFunctionalAreaPostsDTO,
  UpdatePostDTO
} from 'dto/post';

import { get, post, del, patch } from './common';
import { validateArray, validate, validateBool } from './validation';

export const getGuidePosts = async (query: GetPostDTO): Promise<PostDTO[]> => {
  const posts = await get('/posts/guide', query).then(response => response.data);
  return validateArray(PostDTO, posts);
};

export const getDepartmentPosts = async (query: GetDepartmentPostDTO): Promise<PostDTO[]> => {
  const posts = await get('/posts/department', query).then(response => response.data);
  return validateArray(PostDTO, posts);
};

export const getFunctionalAreaPost = async (query: GetFunctionalAreaPostDTO): Promise<PostListDTO> => {
  const posts = await get('/posts/functionalArea', query).then(response => {
    // TODO: v1.3.7 - this check is only for backwards compatibility with server v1.3.7 e.g. no supported paging
    if (response.data.posts === undefined) {
      console.warn('detected old server - workaround missing api');
      return {
        posts: response.data.reverse(),
        count: response.data.length
      };
    }

    return response.data;
  });
  return posts;
};

export const getNewFunctionalAreaPosts = async (query: GetNewFunctionalAreaPostsDTO): Promise<PostDTO[]> => {
  const posts = await get('/posts/new/functionalArea', query)
    .then(response => response.data)
    .catch((err: AxiosError) => {
      // TODO: v1.3.7 - this check is only for backwards compatibility with server  e.g. the new-route is not supported -> mimic by calling getFunctionalAreaPost and filtering.
      if (err.response?.status === 404) {
        console.warn('detected old server - workaround missing api');

        return getFunctionalAreaPost(query).then(response => {
          const latestPostIndex = response.posts.findIndex(p => p.postId === query.latestFunctionalAreaPostId);
          return response.posts.slice(0, latestPostIndex);
        });
      }
      throw err;
    });
  return validateArray(PostDTO, posts);
};

export const getBriefingPost = async (query: GetBriefingPostDTO): Promise<PostDTO[]> => {
  const posts = await get('/posts/briefing', query).then(response => response.data);
  return validateArray(PostDTO, posts);
};

export const createDepartmentPosts = async (newPost: CreateDepartmentPostDTO): Promise<PostDTO> => {
  const createdPost = await post('/post/department', newPost).then(response => response.data);
  return validate(PostDTO, createdPost);
};

export const createGuidePosts = async (newPost: CreateGuidePostDTO): Promise<PostDTO> => {
  const createdPost = await post('/post/guide', newPost).then(response => response.data);
  return validate(PostDTO, createdPost);
};

export const createFunctionalAreaPosts = async (newPost: CreateFunctionalAreaPostDTO): Promise<PostDTO> => {
  const createdPost = await post('/post/functionalArea', newPost).then(response => response.data);
  return validate(PostDTO, createdPost);
};

export const createBriefingPost = async (newPost: CreateBriefingPostDTO): Promise<PostDTO> => {
  const createdPost = await post('/post/briefing', newPost).then(response => response.data);
  return validate(PostDTO, createdPost);
};

export const deletePosts = async (delPosts: DeletePostsDTO): Promise<boolean> => {
  return del('/posts', delPosts).then(response => validateBool(response.data));
};

export const editPost = async (updatePost: UpdatePostDTO): Promise<boolean> => {
  return patch('/post', updatePost).then(response => validateBool(response.data));
};
