import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import Button from 'components/Form/Button';
import BlackCheckBox from './BlackCheckBox';
import './SurgeryProcedureDrawerFooter.css';

const SurgeryProcedureDrawerFooter = observer(() => {
  const { t } = useTranslation('briefing');
  const { domainStore, surgeryGuideStore } = useStores();
  const { shortName: functionAreaShortName } = domainStore.currentFunctionalArea;

  const group = surgeryGuideStore.selectedGroup;

  const procedure = group?.surgeryGuideProcedures[surgeryGuideStore.procedureIndexInView];

  const goBack = () => surgeryGuideStore.setProcedureIndexInView(surgeryGuideStore.procedureIndexInView - 1);
  const goForward = () => surgeryGuideStore.setProcedureIndexInView(surgeryGuideStore.procedureIndexInView + 1);

  const onStatusChanged = (checked: boolean) => {
    if (!procedure) return;
    surgeryGuideStore.updateProcedure({
      surgeryGuideProcedureId: procedure.surgeryGuideProcedureId,
      checked
    });
    if (group && surgeryGuideStore.procedureIndexInView < group.surgeryGuideProcedures.length - 1) {
      goForward();
    }
  };

  return (
    <div className="div-block-164 pilot-footer">
      <div className="div-block-169">
        <img src="images/klinik_puls_logo.svg" alt="" className="logo_desktop" />
        <div className="txt_logo">
          <strong>{functionAreaShortName}</strong>
          {t('briefingProcedures.drawer.puls')}
        </div>
      </div>
      {group && procedure && (
        <div className="div-block-166">
          <div className="div-block-167">
            <Button className="btn_op-pilot" onClick={goBack} disabled={surgeryGuideStore.procedureIndexInView === 0}>
              <div>{t('briefingProcedures.drawer.buttons.stepBack')}</div>
            </Button>
            <div>
              <div className="lable_page">
                {surgeryGuideStore.procedureIndexInView + 1} {t('briefingProcedures.drawer.from')} {group.surgeryGuideProcedures.length}
              </div>
              {procedure && (
                <BlackCheckBox onStatusChanged={onStatusChanged} status={procedure.checked} key={procedure.surgeryGuideProcedureId} />
              )}
            </div>
            <Button
              className="btn_op-pilot"
              onClick={goForward}
              disabled={surgeryGuideStore.procedureIndexInView >= group.surgeryGuideProcedures.length - 1}
            >
              <div>{t('briefingProcedures.drawer.buttons.stepForward')}</div>
            </Button>
          </div>
          <Button className="btn_op-pilot_close btn-pilot-close" onClick={() => surgeryGuideStore.setIsProcedureDrawerOpen(false)}>
            <div>{t('briefingProcedures.drawer.buttons.closeAssistant')}</div>
          </Button>
        </div>
      )}
    </div>
  );
});

export default SurgeryProcedureDrawerFooter;
