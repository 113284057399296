import { getMaterialSetCheckItems, postMaterialSetCheckItems } from 'api/materialSet';
import {
  CheckStatus,
  CreateCheckedGroupMaterialDTO,
  CreateCheckedMaterialSetGroupsDTO,
  FullSetCheckDTO,
  SimpleMaterialSetCheckItemDTO
} from 'dto/materialSet';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useStores } from 'util/mobx/stores';

export type SetChecks = Omit<FullSetCheckDTO, 'setCheckId' | 'user' | 'createdAt' | 'groupMaterialChecks'> &
  Partial<Pick<FullSetCheckDTO, 'setCheckId' | 'user' | 'createdAt'>> & {
    groupMaterialChecks: Map<
      string,
      SimpleMaterialSetCheckItemDTO & {
        // setGroupId is only needed for new. When only reviewing old checks this is not relevant
        setGroupId?: string;
      }
    >;
  };

export interface SetCheckState {
  checks?: SetChecks;
  dirty: boolean;
  updateCheck: (id: { groupMaterialId?: string; setGroupId?: string }, status: CheckStatus) => void;
  submit: () => Promise<void>;
}

const SetCheckContext = React.createContext<SetCheckState>({
  checks: undefined,
  dirty: false,
  submit: () => Promise.resolve(),
  updateCheck: () => undefined
});

export function useSetCheckContext() {
  const state = useContext(SetCheckContext);
  if (state === undefined) {
    throw new Error('use of SetCheckContext without provider');
  }

  return state;
}

interface Props {
  children: React.ReactNode;
}

export const SetCheckProvider: React.FC<Props> = observer(({ children }) => {
  const { loadingStore, materialSetsStore } = useStores();
  const [checks, setChecks] = useState<SetChecks>();
  const [dirty, setDirty] = useState(false);

  const handleReset = useCallback(() => {
    setChecks(undefined);
    setDirty(false);
  }, []);
  const handleCreateNewSetChecks = useCallback((materialSetId: string) => {
    setChecks({
      groupMaterialChecks: new Map(),
      setGroupChecks: new Map(),
      materialSetId
    });
  }, []);

  const handleLoadSetChecks = useCallback(
    async (setCheckId: string) => {
      await loadingStore.withLoadingBar(() => getMaterialSetCheckItems(setCheckId)).then(setChecks);
    },
    [loadingStore]
  );

  const handleUpdateCheck: SetCheckState['updateCheck'] = useCallback((id, status) => {
    if (id.groupMaterialId && id.setGroupId) {
      const groupMaterialId = id.groupMaterialId;
      const setGroupId = id.setGroupId;
      setChecks(current => {
        if (!current) {
          return current;
        }

        current.groupMaterialChecks.set(groupMaterialId, {
          setCheckItemId: `${groupMaterialId}_temporary`,
          status,
          setGroupId
        });
        return { ...current };
      });
    } else if (id.setGroupId) {
      const setGroupId = id.setGroupId;
      setChecks(current => {
        if (!current) {
          return current;
        }

        current.setGroupChecks.set(setGroupId, { setCheckItemId: `${setGroupId}_temporary`, status });

        return { ...current };
      });
    }

    setDirty(true);
  }, []);

  const handleSubmit: SetCheckState['submit'] = useCallback(async () => {
    if (!checks || !dirty) {
      return;
    }

    const newChecks: CreateCheckedMaterialSetGroupsDTO[] = [];

    checks.setGroupChecks.forEach((value, key) => {
      const newGroupMaterialChecks: CreateCheckedGroupMaterialDTO[] = [];

      checks.groupMaterialChecks.forEach((groupMaterialValue, groupMaterialKey) => {
        newGroupMaterialChecks.push({
          groupMaterialId: groupMaterialKey,
          status: groupMaterialValue.status
        });
      });

      newChecks.push({
        setGroupId: key,
        status: value.status,
        groupMaterials: newGroupMaterialChecks
      });
    });
    await loadingStore.withLoadingBar(() =>
      postMaterialSetCheckItems({
        materialSetId: materialSetsStore.materialSetId,
        setGroups: newChecks
      })
    );

    handleReset();
  }, [checks, dirty, handleReset, loadingStore, materialSetsStore.materialSetId]);

  // Connect this context state to the global store as for now some things still use that store.
  useEffect(() => {
    if (materialSetsStore.isCheckListVisible && materialSetsStore.selectedSetCheck) {
      handleLoadSetChecks(materialSetsStore.selectedSetCheck.setCheckId);
    } else if (materialSetsStore.isCheckListVisible) {
      handleCreateNewSetChecks(materialSetsStore.materialSetId);
    } else {
      handleReset();
    }
  }, [
    handleCreateNewSetChecks,
    handleLoadSetChecks,
    handleReset,
    materialSetsStore.isCheckListVisible,
    materialSetsStore.materialSetId,
    materialSetsStore.selectedSetCheck
  ]);

  const state: SetCheckState = useMemo(() => {
    return {
      checks,
      dirty,
      updateCheck: handleUpdateCheck,
      submit: handleSubmit
    };
  }, [checks, dirty, handleSubmit, handleUpdateCheck]);

  return <SetCheckContext.Provider value={state}>{children}</SetCheckContext.Provider>;
});
