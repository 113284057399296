import { useGetGuideMaterialsV2 } from 'api/guideHooks';
import * as qs from 'query-string';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CloneMaterialKnowledgeItem from './CloneMaterialKnowledgeItem';

interface Props extends RouteComponentProps {
  handleAddLinkMaterialKnowledgeId: (knowledgeId?: string) => void;
  isKnowledgeSelected: (knowledgeId: string) => boolean;
}

export default withRouter((props: Props) => {
  const [openIndex, setOpenIndex] = useState(-1);
  const currentGuideId = qs.parse(props.location.search).guideId as string;

  const getOpenMaterialHandler = (index: number) => () => {
    setOpenIndex(index);
  };

  const { data } = useGetGuideMaterialsV2({
    guideId: currentGuideId
  });

  return (
    <>
      {data?.guideMaterials?.reduce((previous: JSX.Element[], guideMaterial, i) => {
        previous.push(
          <CloneMaterialKnowledgeItem
            key={guideMaterial.guideMaterialId}
            guideMaterial={guideMaterial}
            handleAddLinkMaterialKnowledgeId={props.handleAddLinkMaterialKnowledgeId}
            isOpen={openIndex === i}
            openHandler={getOpenMaterialHandler(i)}
            isKnowledgeSelected={props.isKnowledgeSelected}
          />
        );
        return previous;
      }, [])}
    </>
  );
});
