import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FunctionalAreaDepartmentReportDTO } from 'dto/functionalArea';
import ReportDepartmentItem from '../ReportDepartmentItem';
import { isRatioLow, isRatioMedium, isRatioHigh, getColorByRatio } from '../ReportGuides/reportGuidesUtils';
import DoughnutChart from '../DoughnutChart';
import HorizontalBarChart from '../HorizontalBarChart';
import { HorizontalBarChartTypes, DepartmentReportDTO } from '../types';

interface Props {
  reportData: FunctionalAreaDepartmentReportDTO[];
}

const ReportStandardsContent = ({ reportData }: Props) => {
  const departmentsReports: DepartmentReportDTO[] = reportData.map(report => ({
    name: report.departmentName,
    id: report.departmentId,
    value: report.ratioOfFilledGuideChapters
  }));
  const [selectedDepartment, setSelectedDepartment] = useState<DepartmentReportDTO>(departmentsReports[0]);
  const { t } = useTranslation('reports');
  const getDescriptionText = (translationString: string) => {
    return (
      <div className="div-block-93 text_center">
        <div className="text-block-18" style={{ backgroundColor: getColorByRatio(selectedDepartment.value) }}>
          <div>{t(`${translationString}.top`)}</div>
          <div>{t(`${translationString}.middle`)}</div>
          <div>{t(`${translationString}.bottom`)}</div>
        </div>
      </div>
    );
  };
  return (
    <div className="w-layout-grid report-container grid_reports_standards">
      <div className="content-container">
        <div className="h3">{t('departments')}</div>
        {departmentsReports.map(departmentsReport => (
          <ReportDepartmentItem
            onClick={() => setSelectedDepartment(departmentsReport)}
            key={departmentsReport.id}
            name={departmentsReport.name}
            selected={selectedDepartment.id === departmentsReport.id}
          />
        ))}
      </div>
      {selectedDepartment && (
        <>
          <div className="div-block-90">
            <DoughnutChart selectedDepartment={selectedDepartment} />
          </div>
          <div className="div-block-92 report-horizontal-chart-container">
            <div>{t('standards.descriptionTitle')}</div>
            {selectedDepartment.value === 0 && <div className="div-block-93 text_center" />}
            {isRatioLow(selectedDepartment.value) && getDescriptionText('guides.lowRatioDescription')}
            {isRatioMedium(selectedDepartment.value) && getDescriptionText('guides.mediumRatioDescription')}
            {isRatioHigh(selectedDepartment.value) && getDescriptionText('guides.highRatioDescription')}
            <div>{t('standards.chartTitle')}</div>
            <HorizontalBarChart
              type={HorizontalBarChartTypes.percentage}
              key={selectedDepartment.id}
              selectedDepartment={selectedDepartment}
              departments={departmentsReports}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ReportStandardsContent;
