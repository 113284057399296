/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { ExtendedUserDTO, UserDTO } from 'dto/user';
import HoverWrapper from 'components/HoverWrapper';
import GuideOwnerListItemBody from './GuideOwnerListItemBody';
import './GuideOwnerListItem.css';

interface Props {
  user: UserDTO | ExtendedUserDTO;
  picture?: string;
  disableMenu?: boolean;
  className?: string;
  isCloneGuideFlyout?: boolean;
}

const generateClassName = (className: string | undefined, selected: boolean) => {
  let generatedClassName = 'list_item_name';
  if (className) {
    generatedClassName = className;
  }
  if (selected) {
    generatedClassName = `${generatedClassName} selected`;
  }
  return generatedClassName;
};

export default observer((props: Props) => {
  const { guideStore } = useStores();

  const handleClick = (userId: string) => {
    if (guideStore.guideMaterialsToClone || guideStore.guideProcedureToClone) {
      guideStore.setSelectedGuideOwnerFromGuidesCloneFlyout(userId);
      guideStore.getAdditionalGuidesByUserClone(userId);
      return;
    }
    guideStore.setSelectedGuideOwnerFromGuides(userId);
    guideStore.loadAdditionalGuidesByUser(userId);
  };

  const selectedGuideOwnerId =
    guideStore.guideMaterialsToClone || guideStore.guideProcedureToClone
      ? guideStore.selectedGuideOwnerCloneFlyout?.userId
      : guideStore.selectedGuideOwner?.userId;

  const selected = selectedGuideOwnerId === props.user.userId;

  return (
    <div
      onClick={() => {
        handleClick(props.user.userId);
      }}
    >
      <HoverWrapper className={generateClassName(props.className, selected)}>
        {({ hover }) => <GuideOwnerListItemBody {...props} className="" selected={selected} hover={hover} withUserInfoBoxRight />}
      </HoverWrapper>
    </div>
  );
});
