/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { observer } from 'mobx-react';
import { SurgeryGuideDTO } from 'dto/surgeryGuide';
import { useTranslation } from 'react-i18next';

import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import Popover from 'components/Popover';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import { useStores } from 'util/mobx/stores';

interface Props {
  guide: SurgeryGuideDTO;
  hover?: boolean;
}

const SurgeryGuideActions = observer(({ guide, hover }: Props) => {
  const { surgeryStore } = useStores();
  const { t } = useTranslation('surgery');

  const onGoToMaterial = () => {
    // TODO
  };

  const onDeleteGuide = () => {
    surgeryStore.deleteSurgeryGuide(guide.surgeryGuideId);
  };

  return (
    <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
      {({ handleClose, isOpen }) => (
        <>
          <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
            <ItemMenuButton
              label={t('guideItem.button.goToMaterial')}
              icon="icon_prototype_16.svg"
              href={`/guide/materials?guideId=${guide.guide.guideId}`}
              isInPopover
              handleClick={onGoToMaterial}
            />
            <ItemMenuButton
              label={t('guideItem.button.deleteGuide')}
              icon="icon_bin_16.svg"
              warning
              isInPopover
              handleClick={onDeleteGuide}
            />
          </ItemMenu>
        </>
      )}
    </Popover>
  );
});

export default SurgeryGuideActions;
