import React from 'react';
import { useTranslation } from 'react-i18next';

import { DepartmentUsageReportDTO } from 'dto/department';
import HorizontalBarChart from '../HorizontalBarChart';
import { HorizontalBarChartTypes, DepartmentReportDTO } from '../types';

interface Props {
  reportData: DepartmentUsageReportDTO[];
}

const ReportGuidesContent = ({ reportData }: Props) => {
  const departmentsReports: DepartmentReportDTO[] = reportData.map(report => ({
    name: report.name,
    id: report.departmentId,
    value: report.handbooksCountInDepartment
  }));
  const { t } = useTranslation('reports');
  return (
    <div className="w-layout-grid report-container grid_reports_handbuecher grid-reports-handbuecher">
      <div className="div-block-92 report-horizontal-chart-container">
        <div>{t('guides.chartTitle')}</div>
        <HorizontalBarChart type={HorizontalBarChartTypes.amount} departments={departmentsReports} />
      </div>
    </div>
  );
};

export default ReportGuidesContent;
