import React from 'react';
import { TemplateDTO } from 'dto/template';
import { TemplateListItem } from 'components/ListItems';
import EmptyIndicator from 'components/EmptyIndicator';
import { useTranslation } from 'react-i18next';

interface Props {
  templates: TemplateDTO[];
  filterListBy?: string;
}

const TemplatesList = ({ templates, filterListBy }: Props) => {
  const { t } = useTranslation('instruments');
  return (
    <>
      <EmptyIndicator message={t('emptyInstrumentsInfo')} isEmpty={!templates.length}>
        {templates.map((template, i) => (
          // here is no other way to get a more unique key
          // eslint-disable-next-line react/no-array-index-key
          <TemplateListItem template={template} key={template.templateId + i} filterListBy={filterListBy} />
        ))}
      </EmptyIndicator>
    </>
  );
};

export default TemplatesList;
