import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import SettingsItem from 'components/SettingsItem';
import { SettingsItemTypes } from 'stores/settingsStore';
import { FunctionalAreaDTO } from 'dto/functionalArea';
import SettingsBlock from 'components/SettingsBlock';

interface Props {
  selectedFunctionalAreaId: string;
  functionalAreas: FunctionalAreaDTO[];
}

const DepartmentsColumn = observer(({ selectedFunctionalAreaId, functionalAreas }: Props) => {
  const { t } = useTranslation('settings');
  const { settingsStore } = useStores();
  const selectedFunctionalArea = functionalAreas.find(fa => fa.functionalAreaId === selectedFunctionalAreaId);

  const onActivate = (itemId: string) => {
    settingsStore.updateDepartment({
      departmentId: itemId,
      disabled: false
    });
  };

  const onDeactivate = (itemId: string) => {
    settingsStore.updateDepartment({
      departmentId: itemId,
      disabled: true
    });
  };

  const onCreateFlyoutOpen = () => {
    settingsStore.setItemFormFlyoutType(SettingsItemTypes.department);
    settingsStore.setItemFormFlyoutOpen(true);
  };

  return (
    <SettingsBlock title={t('administration.departmentsTitle')} onCreateFlyoutOpen={onCreateFlyoutOpen}>
      {selectedFunctionalArea && selectedFunctionalArea?.departments && selectedFunctionalArea?.departments?.length > 0 ? (
        <div className="area_block">
          {selectedFunctionalArea.departments.map(department => (
            <SettingsItem
              itemId={department.departmentId}
              onActivate={onActivate}
              onDeActivate={onDeactivate}
              key={department.departmentId}
              name={department.name}
              disabled={department.disabled}
              type={SettingsItemTypes.department}
            />
          ))}
        </div>
      ) : null}
    </SettingsBlock>
  );
});

export default DepartmentsColumn;
