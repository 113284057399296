import React, { useEffect } from 'react';
import { Route, Switch, RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import * as qs from 'query-string';
import { useStores } from 'util/mobx/stores';
import { UserDTO } from 'dto/user';
import Safe from 'components/Safe';
import Page from 'components/Page';
import { AppBarHeaderText } from 'components/AppLayout/AppBar';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import { ChatConfigurationTypes } from 'components/Chat/Chat';
import AppLayout from 'components/AppLayout';
import CreateMaterialSynonymFlyout from 'components/MaterialKnowledge/CreateMaterialSynonymFlyout';
import DeleteGuideFlyout from 'pages/flyouts/DeleteGuideFlyout';
import CloneGuideMaterialFlyout from 'pages/flyouts/Clone/CloneGuideMaterialFlyout/CloneGuideMaterialFlyout';
import CloneGuideProcedureFlyout from 'pages/flyouts/Clone/CloneGuideProcedureFlyout/CloneGuideProcedureFlyout';
import CloneMaterialKnowledgeFlyout from 'pages/flyouts/Clone/CloneMaterialKnowledgeFlyout';
import ProcedureFormFlyout from 'pages/guideProcedure/ProcedureFormFlyout';
import GuideHistoryFlyout from 'pages/guideMaterials/GuideHistoryFlyout';
import ValueTag from 'components/ValueTag';
import GuideFlyout from 'pages/guides/grid/content/createGuide/GuideFlyout';
import SelectLocationFlyout from 'pages/flyouts/SelectLocationFlyout';
import GuideNavSection from './GuideNavSection';
import GuideAppBarIcon from './GuideAppBarIcon';
import GuideProcedure from '../guideProcedure';
import { GuideMaterialsV2 } from './Material/GuideMaterialsV2';

const getHeadline = (selectedGuideOwner?: UserDTO) => {
  if (!selectedGuideOwner) {
    return '';
  }
  return `${selectedGuideOwner.title} ${selectedGuideOwner.lastName} ${selectedGuideOwner.firstName}`;
};

type Props = RouteComponentProps & {
  location: {
    pathname: string;
    search: string;
  };
};

const Guide = withRouter(
  observer((props: Props) => {
    const { guideStore } = useStores();
    const { t } = useTranslation('guideMaterials');
    const { t: guideTranslation } = useTranslation('guide');
    const currentGuideId = qs.parse(props.location.search).guideId as string;

    useEffect(() => {
      guideStore.loadSelectedGuideById(currentGuideId);
      return () => {
        guideStore.resetAllSelectedGuideParamsFromCloneFlyout();
      };
    }, [currentGuideId, guideStore]);

    return (
      <>
        <AppLayout
          appBarIcon={<GuideAppBarIcon user={guideStore.selectedGuideOwner} />}
          appBarChildren={
            <AppBarHeaderText headline={guideStore?.selectedGuide?.name || ''} subHeadline={getHeadline(guideStore.selectedGuideOwner)} />
          }
          flyoutInDrawer={
            <>
              <CreateMaterialSynonymFlyout />
              <SelectLocationFlyout />
            </>
          }
          boardFlyout={<BoardFlyout />}
          appBarRightChildren={
            <ValueTag
              classNameTitle="margin_left"
              title={guideTranslation('guideId')}
              tagValue={guideStore.selectedGuide?.guideNumber.toString()}
            />
          }
          flyout={
            <>
              <DeleteGuideFlyout />
              <GuideFlyout />
              <CloneGuideMaterialFlyout />
              <CloneGuideProcedureFlyout />
              <ProcedureFormFlyout />
              <CloneMaterialKnowledgeFlyout />
              <GuideHistoryFlyout />
            </>
          }
        >
          <Page
            headline={t('subMenu.headline')}
            idPrefix="guideslist"
            navigation={<GuideNavSection />}
            chatConfigurationType={ChatConfigurationTypes.guide}
            wrapperContentClass="flex_content guide-wrapper-content margin_right"
          >
            <Switch>
              <Route
                path="/guide/materials"
                component={() => <GuideMaterialsV2 guideId={currentGuideId} lazyLoadScrollContainer=".wrapper_content" />}
              />
              <Route
                path="/guide/procedure"
                component={() => <GuideProcedure lazyLoadScrollContainer=".wrapper_content" guideId={currentGuideId} />}
              />
            </Switch>
          </Page>
        </AppLayout>
      </>
    );
  })
);

// moved Safe here to prevent running the useEffect if not logged in
export default () => (
  <Safe>
    <Guide />
  </Safe>
);
