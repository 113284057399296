import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import SurgeryList from './SurgeryList';
import SurgeryDetails from './SurgeryDetails';
import SurgeryRightMenu from './SurgeryRightMenu';

interface Props {
  surgeryId: string;
}

const SurgeryBriefing = ({ surgeryId }: Props) => {
  const { appNavigationStore, surgeryStore, domainStore } = useStores();
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <SurgeryRightMenu />;
    });
  }, [appNavigationStore]);

  useEffect(() => {
    surgeryStore.clearSelectedSurgeryBriefing();
  }, [surgeryStore, domainStore.currentFunctionalArea.id]);

  useEffect(() => {
    if (surgeryId) {
      surgeryStore.loadDataWithPreselectedBriefing(surgeryId);
    } else {
      surgeryStore.setIsPlannerDrawerOpen(true);
    }
  }, [surgeryStore, surgeryId]);

  return (
    <div className="w-layout-grid grid_colum_content-op-briefing">
      <SurgeryList />
      <SurgeryDetails />
    </div>
  );
};

export default SurgeryBriefing;
