import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FlyoutContainer from 'components/FlyoutContainer';
import { GuideMaterialList } from 'modules/guideMaterial';
import { CategoryMaterialList } from 'modules/guideMaterial/CategoryMaterialList';
import { useStores } from 'util/mobx/stores';
import CloneGuideOverview from '../CloneGuideOverview';

import '../CloneFlyout.css';

export default observer(() => {
  const { t } = useTranslation('guide');
  const { guideStore } = useStores();
  const closePopUp = () => {
    guideStore.resetAllSelectedGuideParamsFromCloneFlyout();
  };
  const backPopUp = () => guideStore.resetSelectedGuideOwnerFromGuidesCloneFlyout();
  const onSubmit = async () => {
    if (guideStore.selectedGuideCloneFlyout && guideStore.guideMaterialsToClone) {
      await guideStore.cloneGuidesMaterial({
        ...guideStore.guideMaterialsToClone,
        sourceGuideId: guideStore.selectedGuideCloneFlyout.guideId
      });
    }
    closePopUp();
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_import.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={guideStore.isCloneGuideMaterialFlyoutOpen}
      closePopUp={guideStore.selectedGuideCloneFlyout ? backPopUp : closePopUp}
      onSubmit={onSubmit}
      submitLabel={guideStore.selectedGuideCloneFlyout && t('cloneGuideFlyout.button.submit')}
      isAllowedToSubmit
      title={t('cloneGuideFlyout.headline')}
      cancelLabel={guideStore.selectedGuideCloneFlyout ? t('cloneGuideFlyout.button.back') : t('cloneGuideFlyout.button.cancel')}
    >
      {guideStore.selectedGuideCloneFlyout ? (
        <div className="flyout_scroll_wrapper flyout-wrapper-fixed-height">
          <div className="flyout_box">
            <div>{t('cloneGuideFlyout.label.pasteThisMaterialListToGuide')}</div>
            <div id="clone-guide-material-flyout-lazy-scroll-container" className="flyout_scrollable in_flyout clone-list">
              <GuideMaterialList
                CategoryMaterialListElement={CategoryMaterialList}
                functionalAreaId={guideStore.selectedGuideCloneFlyout.functionalAreaId}
                guideLikeId={guideStore.selectedGuideCloneFlyout.guideId}
                readonly
                lazyLoadScrollContainer="#clone-guide-material-flyout-lazy-scroll-container"
              />
            </div>
          </div>
        </div>
      ) : (
        <CloneGuideOverview
          title={t('cloneGuideFlyout.label.chooseGuideForGuideMaterials')}
          guideOwnerList={guideStore.guideOwnerListClone}
          guidesClone={guideStore.selectedGuidesClone}
          className="flyout_scroll_wrapper flyout-wrapper-fixed-height"
        />
      )}
    </FlyoutContainer>
  );
});
