import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotesDTO } from 'dto/guide';

interface Props {
  notes: NotesDTO;
  translationPrefix?: string;
}

export const Note: React.FC<{
  title?: string;
  value?: string;
}> = ({ title, value }) => {
  return (
    <div className="material_note">
      <div>
        {title && (
          <>
            <strong>{title}</strong>
            {title && value ? ': ' : ''}
          </>
        )}
        {value}
      </div>
    </div>
  );
};

const Instructions = ({ notes, translationPrefix }: Props) => {
  const { t } = useTranslation('listItems');
  const prefix = translationPrefix ? `${translationPrefix}.` : '';
  return (
    <div className="note_wrapper">
      {notes.preparer && <Note title={t(`instructions.${prefix}notePreparer`)} value={notes.preparer} />}
      {notes.sterile && <Note title={t(`instructions.${prefix}noteSterile`)} value={notes.sterile} />}
      {notes.circular && <Note title={t(`instructions.${prefix}noteCircular`)} value={notes.circular} />}
      {notes.unpackOnInstruction && <Note title={t(`instructions.${prefix}noteUnpackOnInstruction`)} />}
    </div>
  );
};

export default Instructions;
