import React from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { FormikValues, FormikProps } from 'formik';
import './DatePicker.css';
import { DateDTO } from 'dto/surgery';
import { parseToDate, parseFromDateTime } from 'util/date';

const styles = () => ({
  input: {
    color: '#7a7a7a',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    paddingLeft: '10px',
    fontWeight: 400,
    marginTop: '20px',
    background: 'white',
    zIndex: 1
  }
});

interface Props extends WithStyles<typeof styles> {
  meta: FormikProps<FormikValues>;
  name: string;
}

const DatePickerComponent = ({ meta, name, classes, ...rest }: Props) => {
  const onSelectDate = (date: MaterialUiPickersDate) => {
    if (!date) return;
    const parsedDate: DateDTO = parseToDate(date);
    meta.setFieldValue(name, parsedDate);
  };
  return (
    <KeyboardDatePicker
      {...rest}
      autoOk
      value={meta.values[name] ? parseFromDateTime(meta.values[name], undefined, true) : null}
      onChange={onSelectDate}
      variant="inline"
      inputVariant="outlined"
      format="DD.MM.YYYY"
      PopoverProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'left' } }}
      InputProps={{ className: classes.input, notched: true }}
    />
  );
};

const DatePickerComponentWithStyles = withStyles(styles)(DatePickerComponent);

export default DatePickerComponentWithStyles;
