import React from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import { ProcedureDTO, ProcedureType, IconType } from 'dto/procedure';
import { getMaterial } from 'api/material';
import PostItem from 'components/PostItem';
import Shake from 'components/Shake';
import { getMaterialSet } from 'api/materialSet';
import { getTemplate } from 'api/template';
import { PictureDTO, OptionalPictureDTO } from 'dto/file';
import { useAbility } from '@casl/react';
import { AbilityContext, actions, subjectArea } from 'casl/setupCaslAbility';
import { subject } from '@casl/ability';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import ProcedureMenu from './ProcedureMenu';

interface Props extends OptionalLazyLoadProps {
  procedure: ProcedureDTO;
}

const ProcedureListItem = observer(({ procedure, lazyLoadScrollContainer }: Props) => {
  const { procedureStore, guideStore, animationStore, guideDetailDrawerStore } = useStores();
  const ability = useAbility(AbilityContext);

  const handleDeleteGuideProcedure = (event: React.MouseEvent) => {
    event.preventDefault();
    if (guideStore.selectedGuide) {
      procedureStore.deleteGuideProcedure(procedure.guideProcedureId, guideStore.selectedGuide.guideId);
    }
  };

  const handleDeleteAndUnlinkGuideProcedure = (event: React.MouseEvent) => {
    event.preventDefault();
    if (guideStore.selectedGuide) {
      procedureStore.unlinkSelectedProcedure(procedure.guideProcedureId, guideStore.selectedGuide.guideId, true).then(() => {
        if (guideStore.selectedGuide) {
          procedureStore.deleteGuideProcedure(procedure.guideProcedureId, guideStore.selectedGuide.guideId);
        }
      });
    }
  };

  const handleDeleteAllLinkedGuideProcedures = (event: React.MouseEvent) => {
    event.preventDefault();
    if (guideStore.selectedGuide) {
      procedureStore.deleteAllLinkedGuideProcedures(procedure.guideProcedureId, guideStore.selectedGuide.guideId);
    }
  };

  const handleSwitchChecklistGuideProcedure = (event: React.MouseEvent) => {
    event.preventDefault();
    if (guideStore.selectedGuide) {
      procedureStore.updateGuideProcedure(
        {
          guideProcedureId: procedure.guideProcedureId,
          content: {
            isCheckable: !procedure.content.isCheckable
          }
        },
        guideStore.selectedGuide.guideId,
        true
      );
    }
  };

  const handleOpenMaterialDetail = async () => {
    if (procedure.materialLikeId) {
      if (procedure.materialLikeId.materialId) {
        const material = await getMaterial(procedure.materialLikeId?.materialId);
        guideDetailDrawerStore.setSelectedMaterial(material);
        guideDetailDrawerStore.setIsOpen(true);
        return;
      }

      if (procedure.materialLikeId.materialSetId) {
        const set = await getMaterialSet(procedure.materialLikeId?.materialSetId);
        guideDetailDrawerStore.setSelectedMaterialSet(set);
        guideDetailDrawerStore.setIsOpen(true);
        return;
      }

      // templates are special: as there is no material drawer for a template,
      // we show the material drawer with the first package of the template
      if (procedure.materialLikeId.templateId) {
        const template = await getTemplate(procedure.materialLikeId?.templateId);
        if (template.packages.length < 1) {
          console.error('template does not contain any package');
        }
        if (template.packages[0].instruments.length < 0) {
          guideDetailDrawerStore.setSelectedPackage(template.packages[0]);
          guideDetailDrawerStore.setIsOpen(true);
        }
      }
    }
  };

  const handleUnlinkProcedure = () => {
    if (guideStore.selectedGuide) {
      procedureStore.unlinkSelectedProcedure(procedure.guideProcedureId, guideStore.selectedGuide.guideId, true);
    }
  };

  const handleOpenUpdateProcedureFlyout = (event: React.MouseEvent) => {
    event.preventDefault();
    procedureStore.setSelectedProcedure(procedure);
    procedureStore.setIsProcedureFormFlyoutOpen(true);
    animationStore.resetElementIdToShake();
  };

  const image: PictureDTO | OptionalPictureDTO | undefined = procedure.materialPicture || {};

  if (procedure.materialLikeId?.templateId) {
    image.picture = 'images/icon_layer.jpg';
    image.pictureThumbnail = 'images/icon_layer.jpg';
  }

  return (
    <Shake active={animationStore.elementIdsToShake?.indexOf(procedure.guideProcedureId) > -1}>
      {ability.can(actions.remove, subject(subjectArea.guideProcedure, { departmentId: guideStore.selectedGuide?.departmentId })) ||
      procedure.type === ProcedureType.Knowledge ? (
        <PostItem
          lazyLoadScrollContainer={lazyLoadScrollContainer}
          files={procedure.content.contentElementMedias}
          title={procedure.content.title}
          user={image.picture ? undefined : procedure.content.user}
          picture={image.picture ? image : undefined}
          isCheckable={procedure.content.isCheckable}
          iconType={procedure.type === ProcedureType.Knowledge ? IconType.MaterialPicture : IconType.UserPicture}
          onImageClick={handleOpenMaterialDetail}
          withUserInfoBoxLeft
          menu={
            <ProcedureMenu
              procedure={procedure}
              handleSwitchChecklistGuideProcedure={handleSwitchChecklistGuideProcedure}
              handleOpenMaterialDetail={handleOpenMaterialDetail}
              isMaterial={procedure.type === ProcedureType.Knowledge}
              isCheckable={procedure.content.isCheckable}
              editProcedure={handleOpenUpdateProcedureFlyout}
              unlinkProcedure={handleUnlinkProcedure}
              deleteProcedure={handleDeleteGuideProcedure}
              deleteAndUnlinkProcedure={handleDeleteAndUnlinkGuideProcedure}
              deleteAllLinkedProcedures={handleDeleteAllLinkedGuideProcedures}
            />
          }
        >
          <div>{procedure.content.description}</div>
        </PostItem>
      ) : (
        <PostItem
          lazyLoadScrollContainer={lazyLoadScrollContainer}
          files={procedure.content.contentElementMedias}
          title={procedure.content.title}
          user={image.picture ? undefined : procedure.content.user}
          picture={image.picture ? image : undefined}
          isCheckable={procedure.content.isCheckable}
          iconType={IconType.UserPicture}
          onImageClick={handleOpenMaterialDetail}
        >
          <div>{procedure.content.description}</div>
        </PostItem>
      )}
    </Shake>
  );
});

export default ProcedureListItem;
