import React from 'react';
import moment from 'moment';
import { parseToDate } from 'util/date';
import DateFilterButton from './DateFilterButton';
import CalendarButton from './CalendarButton';

const DateFilter = () => {
  return (
    <div className="filter">
      <DateFilterButton date={parseToDate(moment())} />
      <DateFilterButton date={parseToDate(moment().add('day', 1))} />
      <DateFilterButton date={parseToDate(moment().add('day', 2))} />
      <CalendarButton />
    </div>
  );
};

export default DateFilter;
