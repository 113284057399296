import React from 'react';
import { useTranslation } from 'react-i18next';
import Alert from 'components/Alert';

interface Props {
  isWifi: boolean | undefined;
}

export default (props: Props) => {
  const { t } = useTranslation('wifi');

  return (
    <Alert
      hide={props.isWifi}
      headline={t('headline')}
      message={t('message')}
      className="missing_connection"
      icon="images/icon_wifi-off_big.svg"
    />
  );
};
