import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { deleteMaterialSet } from 'api/materialSet';
import { useHistory } from 'react-router-dom';
import { useStores } from 'util/mobx/stores';

import FlyoutContainer from 'components/FlyoutContainer';
import Picture from 'components/Picture';
import { STARTPOINT } from 'constants/routes';

interface Props {
  groupType: string;
}

const DeleteMaterialSetFlyout = observer(({ groupType }: Props) => {
  const { t } = useTranslation(groupType);
  const history = useHistory();
  const { materialSetsStore, flyoutStore, appNavigationStore } = useStores();
  const closePopUp = () => flyoutStore.setIsDeleteMaterialSetFlyoutOpen(false);
  const onSubmit = async () => {
    await deleteMaterialSet(materialSetsStore.materialSetId);
    closePopUp();

    if (appNavigationStore.canGoBack(history)) {
      history.goBack();
      return;
    }
    history.push(STARTPOINT);
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_delete_prompt.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={flyoutStore.isDeleteMaterialSetFlyoutOpen}
      closePopUp={closePopUp}
      cancelLabel={t('deleteFlyout.button.cancel')}
      onSubmit={onSubmit}
      submitLabel={t(`deleteFlyout.button.submit`)}
      isAllowedToSubmit
      title={t(`deleteFlyout.headline`)}
      warning
    >
      <div className="flyout_box">
        <div className="h4">{t(`deleteFlyout.label.description`)}</div>
        {materialSetsStore.materialSetDetail ? (
          <div className="list_item_book in_promp">
            <div className="image_wrapper_50">
              <div className="image_border image-border" />
              <Picture src={materialSetsStore.materialSetDetail.pictureThumbnail} width={50} alt="" className="image_circle_50" />
            </div>
            <div className="book_info in_prompt">
              <div>{materialSetsStore.materialSetDetail.name}</div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </FlyoutContainer>
  );
});
export default DeleteMaterialSetFlyout;
