import React, { useCallback } from 'react';
import ItemMenuButton from 'components/ItemMenuButton';
import { useTranslation } from 'react-i18next';
import { PostDTO } from 'dto/post';
import { useStores } from 'util/mobx/stores';
import { deletePosts } from 'api/post';

interface Props {
  post: PostDTO;
}

const SurgeryPostItemMenu = ({ post }: Props) => {
  const { t } = useTranslation('comment');
  const { postStore, surgeryStore } = useStores();

  const reloadPosts = useCallback(() => {
    if (surgeryStore.selectedSurgeryBriefing) {
      postStore.loadBriefingPosts(surgeryStore.selectedSurgeryBriefing.briefingId);
    }
  }, [surgeryStore.selectedSurgeryBriefing, postStore]);

  const handleDeleteComment = useCallback(async () => {
    await deletePosts({ postId: post.postId });
    reloadPosts();
  }, [reloadPosts, post.postId]);

  const handleEditComment = useCallback(() => {
    postStore.setSelectedPost(post);
    postStore.setIsChatInEditMode(true);
    postStore.setIsChatBarExpanded(true);
  }, [post, postStore]);

  return (
    <>
      <ItemMenuButton label={t('edit')} icon="icon_i-edit_16.svg" isInPopover handleClick={handleEditComment} />
      <ItemMenuButton label={t('delete')} icon="icon_bin_16.svg" warning isInPopover handleClick={handleDeleteComment} />
    </>
  );
};

export default SurgeryPostItemMenu;
