import React from 'react';

interface Props {
  hover?: boolean;
}

const NavSectionBackButtonDesktopBody = (props: Props) => {
  const transform = props.hover
    ? 'translate3d(-10px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
    : 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)';
  const transitionDuration = props.hover ? '0.3s' : '0.7s';

  return (
    <div
      className="btn_back w-inline-block"
      style={{
        transform,
        transformStyle: 'preserve-3d',
        transitionDuration
      }}
    >
      <img src="images/icon_small-left_16.svg" width={16} alt="" className="image_arrow_back" style={{ transform: 'rotate(0deg)' }} />
    </div>
  );
};

export default NavSectionBackButtonDesktopBody;
