import React from 'react';
import { useTranslation } from 'react-i18next';
import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleReplace: () => void;
  handleDelete: () => void;
  groupType: string;
}

const SetGroupListItemMenu = ({ isOpen, handleClose, handleReplace, handleDelete, groupType }: Props) => {
  const { t } = useTranslation(groupType);
  return (
    <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
      <ItemMenuButton
        label={t('groupsList.setGroupMenu.openKnowledge')}
        icon="icon_search-content_16.svg"
        isInPopover
        handleClick={handleReplace}
      />
      <ItemMenuButton
        label={t('groupsList.setGroupMenu.deleteGroupMaterial')}
        icon="icon_bin_16.svg"
        warning
        isInPopover
        handleClick={handleDelete}
      />
    </ItemMenu>
  );
};

export default SetGroupListItemMenu;
