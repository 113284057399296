/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import 'reflect-metadata';
import { IsInt, IsUUID, ValidateNested, IsString } from 'class-validator';
import { Type } from 'class-transformer';

export class CheckedStatisticDTO {
  @IsInt()
  checked!: number;

  @IsInt()
  all!: number;
}

export class SurgeryStatisticDTO {
  @ValidateNested()
  @Type(() => CheckedStatisticDTO)
  material!: CheckedStatisticDTO;

  @ValidateNested()
  @Type(() => CheckedStatisticDTO)
  procedure!: CheckedStatisticDTO;
}

export class SurgeryProcedureStatisticDTO extends CheckedStatisticDTO {
  @IsUUID()
  chapterId!: string;

  @IsString()
  chapterName!: string;
}

export class SurgeryGuideStatisticDTO extends SurgeryStatisticDTO {
  @ValidateNested({ each: true })
  @Type(() => SurgeryProcedureStatisticDTO)
  procedures!: SurgeryProcedureStatisticDTO[];
}
