import React from 'react';
import PostItem from 'components/PostItem';
import { useTranslation } from 'react-i18next';
import MaterialKnowledge from 'components/MaterialKnowledge';
import { PackageDTO, PackagingType } from 'dto/package';
import CollapsibleBox from 'components/CollapsibleBox';
import { IconType } from 'dto/procedure';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import DrawerStorageLocationContent from '../../DrawerStorageLocationContent';

interface Props {
  packageItem?: PackageDTO;
}

const DrawerPackageContent = observer(({ packageItem }: Props) => {
  const { t } = useTranslation('instruments');
  const { t: packagesTranslate } = useTranslation('packages');
  const { t: translationStorageLocationItems } = useTranslation('storageLocationItems');

  const { guideDetailDrawerStore } = useStores();

  if (!packageItem) {
    return <></>;
  }

  const singleInstrument = packageItem.packagingType === PackagingType.Single ? packageItem.instruments[0] : undefined;

  return (
    <div className="material_content">
      <div className="material-name">{singleInstrument ? packageItem.instruments[0].material.name : packageItem.name}</div>
      <DrawerStorageLocationContent
        showFirstAsFake
        erpLocationPathHeadline={translationStorageLocationItems('locationPathTitleInstruments')}
      />
      <MaterialKnowledge
        isCollapsible
        materialLikeId={{ packageId: packageItem.packageId }}
        onCreateSynonym={() => guideDetailDrawerStore.openCreateMaterialSynonymFlyout()}
      />
      <div className="post_item_head_topic">
        <CollapsibleBox
          head={
            <>
              <div>{t('drawer.productInformation')}</div>
            </>
          }
        >
          <>
            <PostItem
              title={packagesTranslate('packageDetail.label.currentLotNumber')}
              menuClassName="material_detail"
              iconType={IconType.None}
            >
              <div>{packageItem.lotNumber?.lotNumber}</div>
            </PostItem>
            <PostItem
              title={packagesTranslate('packageDetail.label.preparationForLotNumber')}
              menuClassName="material_detail"
              iconType={IconType.None}
            >
              <div>{packageItem.lotNumber?.note}</div>
            </PostItem>
            <PostItem
              title={packagesTranslate('packageDetail.label.lastSynchronisation')}
              menuClassName="material_detail"
              iconType={IconType.None}
            >
              <div>{packageItem.lastSynchronisation}</div>
            </PostItem>
            {singleInstrument && (
              <>
                <PostItem title={t('instrumentDetail.label.manufacturerName')} menuClassName="material_detail" iconType={IconType.None}>
                  <div>{packageItem.instruments[0].material.manufacturerName}</div>
                </PostItem>
                <PostItem title={t('instrumentDetail.label.description')} menuClassName="material_detail" iconType={IconType.None}>
                  <div>{packageItem.instruments[0].description}</div>
                </PostItem>
                <PostItem title={t('instrumentDetail.label.amount')} menuClassName="material_detail" iconType={IconType.None}>
                  <div>{packageItem.instruments[0].amount}</div>
                </PostItem>
                <PostItem title={t('instrumentDetail.label.expectedAmount')} menuClassName="material_detail" iconType={IconType.None}>
                  <div>{packageItem.instruments[0].expectedAmount}</div>
                </PostItem>
              </>
            )}
          </>
        </CollapsibleBox>
      </div>
    </div>
  );
});

export default DrawerPackageContent;
