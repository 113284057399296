import React from 'react';

interface Props extends React.HTMLProps<HTMLDivElement> {
  title: string;
  tagValue?: React.ReactNode;
  classNameTitle?: string;
  classNameItem?: string;
  classNameValue?: string;
}

const ValueTag = ({ title, tagValue, classNameTitle, classNameItem, classNameValue, ...rest }: Props) => {
  if (!tagValue) {
    return null;
  }
  return (
    <div className={`item_number ${classNameItem || ''}`} {...rest}>
      <div className={`txt_number_type ${classNameTitle || ''}`}>{title}</div>
      <div className={`tag-value ${classNameValue || ''}`}>{tagValue}</div>
    </div>
  );
};

export default ValueTag;
