import React from 'react';
import { TextRow, RoundShape } from 'react-placeholder/lib/placeholders';

import 'react-placeholder/lib/reactPlaceholder.css';

interface Props {
  classNamePrefix: string;
  level: number;
}
export const ListItemPlaceholder = ({ classNamePrefix, level }: Props) => {
  return (
    <div
      style={{
        marginLeft: level > 0 ? level * 120 : 0
      }}
      className={`material-list-item ${classNamePrefix}-material-list-item`}
    >
      <div className="flex-vertical-center">
        <div className="image_wrapper_50 material-image">
          <RoundShape className="show-loading-animation" color="lightgray" />
        </div>
      </div>

      <div className="material_info">
        <TextRow className="show-loading-animation" color="lightgray" />
      </div>
    </div>
  );
};
