import HoverWrapper from 'components/HoverWrapper';
import { LocationDTO } from 'dto/location';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import LocationsListItem from './locationsListItem';

interface Props {
  setShowSurgeryRooms: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationsList = observer(({ setShowSurgeryRooms }: Props) => {
  const { settingsStore } = useStores();
  const { t } = useTranslation('settings');

  const checkIfSelected = useCallback(
    (location: LocationDTO) => {
      return location.locationId === settingsStore.selectedLocation?.locationId;
    },
    [settingsStore.selectedLocation]
  );

  return (
    <div className="div-block-141">
      <div className="h3">{t('surgeryRooms.locationsHeadline')}</div>
      {settingsStore.locations.map(location => {
        return (
          <HoverWrapper key={location.locationId}>
            {({ hover }) => (
              <LocationsListItem
                hover={hover}
                location={location}
                setShowSurgeryRooms={setShowSurgeryRooms}
                isSelected={checkIfSelected(location)}
              />
            )}
          </HoverWrapper>
        );
      })}
    </div>
  );
});

export default LocationsList;
