import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../util/mobx/stores';
import { StorageItemDTO } from '../../../../../dto/storageItem';
import Picture from '../../../../../components/Picture';
import { getPackage } from '../../../../../api/package';

import MaterialValueTags from '../../../../../components/MaterialValueTags';

interface Props {
  storageItem: StorageItemDTO;
}

const StorageLocationItem = observer(({ storageItem }: Props) => {
  const { guideDetailDrawerStore } = useStores();

  const onShowDetails = async () => {
    if (storageItem.material) {
      guideDetailDrawerStore.setSelectedMaterial(storageItem.material);
    }
    if (storageItem.materialSet) {
      guideDetailDrawerStore.setSelectedMaterialSet(storageItem.materialSet);
    }
    if (storageItem.package) {
      const fullPackage = await getPackage(storageItem.package.packageId);
      guideDetailDrawerStore.setSelectedPackage(fullPackage.pack);
    }
    guideDetailDrawerStore.setIsOpen(true);
  };

  const getPicture = (item: StorageItemDTO) => {
    if (item.material) {
      return item.material.pictureThumbnail;
    }
    if (item.materialSet) {
      return item.materialSet.pictureThumbnail;
    }
    if (item.package) {
      return item.package.picture?.pictureThumbnail;
    }
    return undefined;
  };
  const getName = (item: StorageItemDTO) => {
    if (item.material) {
      return item.material.name;
    }
    if (item.materialSet) {
      return item.materialSet.name;
    }
    if (item.package) {
      return item.package.name;
    }
    return null;
  };
  const picture = getPicture(storageItem);
  return (
    <div className="list-item list_item_material margin-right storage-item-container">
      <div style={{ marginRight: '10px' }}>{storageItem.amount.toString()}</div>
      <div className="image_wrapper_50" onClick={onShowDetails}>
        <div className="image_border image-border" />
        <Picture src={picture} width={50} alt="" className="image_circle_50" />
      </div>
      <div className="material_info">
        <div className="material_text" onClick={onShowDetails}>
          <div>{getName(storageItem)}</div>
        </div>
        <MaterialValueTags
          material={storageItem.material}
          materialSet={storageItem.materialSet}
          simplePack={storageItem.package}
          onClick={onShowDetails}
          fullInfo={!storageItem.material}
        />
      </div>
    </div>
  );
});

export default StorageLocationItem;
