import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import PopoverV2 from 'components/PopoverV2/PopoverV2';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import { GroupMaterialDTO } from 'dto/groupMaterial';
import { TemplateMenuRenderer } from 'modules/material/ListItems/Instrument/TemplateMenuRenderer';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaterialSetListContext } from '../materialSetListContext';

interface Props {
  setGroupId: string;
  groupMaterial: GroupMaterialDTO;
  specificId: { templateId?: string; packageId?: string; instrumentId?: string };
}

export const getGroupMaterialTemplateMenuRenderer = (setGroupId: string): TemplateMenuRenderer<GroupMaterialDTO> => {
  return ({ specificId, parent }) => <GroupMaterialListItemMenu specificId={specificId} groupMaterial={parent} setGroupId={setGroupId} />;
};

export const GroupMaterialListItemMenu = ({ setGroupId, groupMaterial, specificId }: Props) => {
  const { onClickItem, onDeleteGroupMaterial, groupType, checksEnabled, readonly } = useMaterialSetListContext();

  const { t } = useTranslation(groupType);

  const handleOpenKnowledge = useCallback(() => {
    if (!onClickItem) {
      return;
    }
    onClickItem(groupMaterial, { ...specificId, materialId: groupMaterial.material?.materialId });
  }, [groupMaterial, specificId, onClickItem]);

  const handleDelete = useCallback(() => {
    if (!onDeleteGroupMaterial) {
      return;
    }
    onDeleteGroupMaterial(setGroupId, groupMaterial.groupMaterialId);
  }, [groupMaterial.groupMaterialId, onDeleteGroupMaterial, setGroupId]);

  return !checksEnabled ? (
    <PopoverV2 customTrigger={<ArrowDotsVerticalIcon dotsOnly />}>
      {({ handleClose, isOpen }) => (
        <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
          {!(specificId.templateId && specificId.packageId === undefined) ? (
            <ItemMenuButton
              propagate
              label={t('groupsList.setGroupMenu.openKnowledge')}
              icon="icon_search-content_16.svg"
              isInPopover
              handleClick={handleOpenKnowledge}
            />
          ) : (
            undefined
          )}
          {!readonly ? (
            <ItemMenuButton
              propagate
              label={t('groupsList.setGroupMenu.deleteGroupMaterial')}
              icon="icon_bin_16.svg"
              warning
              isInPopover
              handleClick={handleDelete}
            />
          ) : (
            undefined
          )}
        </ItemMenu>
      )}
    </PopoverV2>
  ) : null;
};
