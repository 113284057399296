import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import Safe from 'components/Safe';
import Page from 'components/Page';
import AppLayout from 'components/AppLayout';
import { AppBarPageIcon, AppBarHeaderText } from 'components/AppLayout/AppBar';
import { useTranslation } from 'react-i18next';
import { ChatConfigurationTypes } from 'components/Chat/Chat';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import LocationAdministrationContent from './LocationAdministrationContent';
import LocationAdministrationRightMenu from './LocationAdministrationRightMenu';
import LocationAdministrationNavigation from './LocationAdministrationNavigation';
import CreateStorageLocationFlyout from './CreateStorageLocationFlyout';
import DeleteStorageLocationFlyout from './DeleteStorageLocationFlyout';
import StorageLocationDetailDrawer from './StorageLocationDetailDrawer';
import './LocationAdministration.css';
import MoveStorageLocationFlyout from './StorageLocationDetails/MoveStorageLocationFlyout';

const LocationAdministration = observer(() => {
  const { domainStore, appNavigationStore } = useStores();
  const { t } = useTranslation('storageLocations');

  const { shortName: locationShortName } = domainStore.currentLocation;
  const { shortName: functionAreaShortName } = domainStore.currentFunctionalArea;

  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <LocationAdministrationRightMenu />;
    });
  }, [appNavigationStore]);

  return (
    <AppLayout
      appBarIcon={<AppBarPageIcon src="images/image_map.jpg" />}
      appBarChildren={
        <AppBarHeaderText
          onClick={() => domainStore.setShowSwitchDomainFlyout(true)}
          headline={t('pageTitle')}
          subHeadline={`${locationShortName} | ${functionAreaShortName}`}
        />
      }
      boardFlyout={<BoardFlyout />}
      flyout={
        <>
          <CreateStorageLocationFlyout />
          <DeleteStorageLocationFlyout />
          <MoveStorageLocationFlyout />
        </>
      }
      drawer={<StorageLocationDetailDrawer />}
    >
      <Page
        disableBackButton
        idPrefix="storage-locations"
        withChatBar={false}
        gridClass="grid_main-copy storage-location-grid"
        wrapperContentClass="wrapper_content no_padding_left right left location-wrapper-content"
        functionsClass="location-right-menu"
        chatConfigurationType={ChatConfigurationTypes.department}
        navigation={<LocationAdministrationNavigation />}
      >
        <LocationAdministrationContent />
      </Page>
    </AppLayout>
  );
});

export default () => (
  <Safe>
    <LocationAdministration />
  </Safe>
);
