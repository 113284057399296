import React from 'react';
import { PackageDTO } from 'dto/package';
import Picture from 'components/Picture';
import MaterialValueTags from 'components/MaterialValueTags';
import GetCameraImageButton from 'components/GetCameraImageButton';
import { MaterialDTO } from 'dto/material';

interface Props {
  packageItem: PackageDTO;
  onUpdateFile: (fileId: string) => Promise<void>;
  singleInstrument?: MaterialDTO;
}

const defaultData = {
  pictureThumbnail: 'images/icon_book_makephoto.jpg',
  picture: 'images/image_no_photo.jpg'
};

const PackageHead = ({ packageItem, onUpdateFile, singleInstrument }: Props) => {
  if (singleInstrument) {
    return (
      <div className="material_head">
        <div className="small_product_image">
          <div className="image_border _3px" />
          {singleInstrument.picture ? (
            <Picture
              src={singleInstrument.picture ? singleInstrument.pictureThumbnail : undefined}
              width={100}
              alt=""
              className="image_circle_100"
            />
          ) : (
            <GetCameraImageButton buttonStyle="head" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
          )}
        </div>
        <div className="div-block-16">
          <MaterialValueTags fullInfo classNameTitle="mat_detail" classNameItem="mat_detail" pack={packageItem} />
        </div>
        <div className="div-block-18">
          <Picture
            src={singleInstrument.picture ? singleInstrument.picture : defaultData.picture}
            alt=""
            // altSrc="images/image_no_photo.jpg"
            className="image-2"
          />
        </div>
      </div>
    );
  }
  return (
    <div className="material_head">
      <div className="small_product_image">
        <div className="image_border _3px" />
        {packageItem.picture ? (
          <Picture
            src={packageItem.picture ? packageItem.picture.pictureThumbnail : undefined}
            width={100}
            alt=""
            className="image_circle_100"
          />
        ) : (
          <GetCameraImageButton buttonStyle="head" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
        )}
      </div>
      <div className="div-block-16">
        <MaterialValueTags fullInfo classNameTitle="mat_detail" classNameItem="mat_detail" pack={packageItem} />
      </div>
      <div className="div-block-18">
        <Picture
          src={packageItem.picture ? packageItem.picture.picture : defaultData.picture}
          alt=""
          // altSrc="images/image_no_photo.jpg"
          className="image-2"
        />
      </div>
    </div>
  );
};

export default PackageHead;
