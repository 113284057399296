import React from 'react';
import { BriefingDTO } from 'dto/briefing';
import SurgeryGuide from '../../SurgeryGuide';

interface Props {
  briefing: BriefingDTO;
}

const SurgeryBriefing = ({ briefing }: Props) => {
  return (
    <>
      {briefing.surgeryGuides.map(surgeryGuide => (
        <SurgeryGuide surgeryGuide={surgeryGuide} key={surgeryGuide.surgeryGuideId} stats={surgeryGuide.statistic} />
      ))}
    </>
  );
};

export default SurgeryBriefing;
