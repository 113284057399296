import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react';
import Button from 'components/Form/Button';
import { useStores } from 'util/mobx/stores';
import PlannerNavigation from './PlannerNavigation';
import SurgeryFormFlyout from './SurgeryFormFlyout';
import DeleteSurgeryFlyout from './DeleteSurgeryFlyout';
import DateFilter from './DateFilter';
import SurgeryPlannerContent from './SurgeryPlannerContent';
import SwitchDomainFlyout from '../SwitchDomainFlyout';
import './SurgeryPlannerDrawer.css';

const SurgeryPlannerDrawer = observer(() => {
  const { surgeryStore, domainStore } = useStores();
  const { t } = useTranslation('surgery');
  const { shortName: locationShortName } = domainStore.currentLocation;
  const { shortName: functionAreaShortName } = domainStore.currentFunctionalArea;

  useEffect(() => {
    surgeryStore.loadSurgeries();
    surgeryStore.loadRooms();
  }, [surgeryStore, domainStore.currentFunctionalArea.id, surgeryStore.selectedFilterDate]);

  return (
    <CSSTransition in={surgeryStore.isPlannerDrawerOpen} timeout={500} classNames="drawer" unmountOnExit>
      <div className="w-layout-grid op-planer op-planer-styled">
        <SurgeryFormFlyout />
        <DeleteSurgeryFlyout />
        <SwitchDomainFlyout isInDrawer />
        <div className="op-planer_head op-planer-head">
          <div className="op_planer_head">
            <div className="div-block-125">
              <div className="div-block-126">
                <img src="images/icon_op_planer.jpg" alt="" className="image_circle_40" />
                <div className="title_info no_hover">
                  <div>
                    {functionAreaShortName}-{t('planner.planner')}
                  </div>
                  <div className="t2 top-margin">
                    {locationShortName} | {functionAreaShortName}
                  </div>
                </div>
              </div>
              <Button className="btn_small_report" onClick={() => domainStore.setShowDrawerSwitchDomainFlyout(true)}>
                <div className="txt_dropout_1">{t('planner.button.switchDepartment')}</div>
              </Button>
            </div>
            <DateFilter />
          </div>
        </div>
        <div className="div-block-127 in_planer planer-content">
          <SurgeryPlannerContent />
        </div>
        <PlannerNavigation />
      </div>
    </CSSTransition>
  );
});

export default SurgeryPlannerDrawer;
