import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import StorageLocationsList from 'pages/locationAdministration/StorageLocationsList';
import FlyoutContainer from 'components/FlyoutContainer';
import './SelectLocationFlyout.css';

interface Props {
  flyoutHeadline?: string;
}

const SelectLocationFlyout = observer(({ flyoutHeadline }: Props) => {
  const { t } = useTranslation('storageLocations');
  const { storageLocationStore } = useStores();

  const onClose = () => {
    storageLocationStore.setIsSelectLocationFlyoutOpen(false);
    // wait until flyout closed
    setTimeout(() => {
      storageLocationStore.clearStore();
    }, 1000);
  };

  const onSelect = async () => {
    if (storageLocationStore.selectedStorageLocation) {
      await storageLocationStore.createStorageItem({
        storageLocationId: storageLocationStore.selectedStorageLocation.storageLocationId,
        amount: 1,
        ...storageLocationStore.materialLikeOrPackageId
      });
      storageLocationStore.setSelectedMaterialStorageLocation(storageLocationStore.selectedStorageLocation, false);
    }

    onClose();
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_location.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={storageLocationStore.isSelectLocationFlyoutOpen}
      closePopUp={onClose}
      cancelLabel={t('selectLocationFlyout.cancel')}
      onSubmit={onSelect}
      submitLabel={t('selectLocationFlyout.submit', {
        locationName: storageLocationStore.selectedStorageLocation ? storageLocationStore.selectedStorageLocation.name : ''
      })}
      isAllowedToSubmit={!!storageLocationStore.selectedStorageLocation}
      title={flyoutHeadline ? t(flyoutHeadline) : t('selectLocationFlyout.headline')}
    >
      <div className="div-block-149">
        <div className="div-block-131 padding padding_bottom location-list-container">
          <StorageLocationsList inFlyout />
        </div>
      </div>
    </FlyoutContainer>
  );
});

export default SelectLocationFlyout;
