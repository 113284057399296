/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import 'reflect-metadata';
import { IsUUID, IsOptional, IsString, IsEnum, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { MultiPackageDTO, PackageDTO, PackagingType } from './package';

export class GetTemplatesDTO {
  @IsUUID()
  @IsOptional()
  departmentId?: string;
}

export class TemplateDTO {
  @IsUUID()
  templateId!: string;

  @IsString()
  templateNumber!: string;

  @IsString()
  name!: string;

  @IsEnum(PackagingType)
  packagingType!: PackagingType;

  /**
   * packages is in some cases not used. Then it is just an empty array.
   */
  @ValidateNested({ each: true })
  @Type(() => PackageDTO)
  packages!: PackageDTO[];
}

export class MultiTemplateDTO {
  @IsUUID()
  templateId!: string;

  @IsString()
  templateNumber!: string;

  @IsString()
  name!: string;

  @IsEnum(PackagingType)
  packagingType!: PackagingType;

  @ValidateNested({ each: true })
  @Type(() => MultiPackageDTO)
  packages!: MultiPackageDTO[];
}

export class SingleTemplateDTO {
  @IsUUID()
  templateId!: string;

  @IsString()
  templateNumber!: string;

  @IsString()
  name!: string;

  @IsEnum(PackagingType)
  packagingType!: PackagingType;

  // Also includes the instruments, as they are directly needed in single packaged.
  @ValidateNested({ each: true })
  @Type(() => PackageDTO)
  packages!: PackageDTO[];
}

export class SimpleTemplateDTO {
  @IsUUID()
  templateId!: string;

  @IsString()
  templateNumber!: string;

  @IsString()
  name!: string;

  @IsEnum(PackagingType)
  packagingType!: PackagingType;
}

export class UpdateTemplateDTO {
  @IsUUID()
  templateId!: string;

  @IsString()
  @IsOptional()
  templateName?: string;
}
