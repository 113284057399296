import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import SideMenuIconButton from 'components/SideMenuIconButton';
import MoreMenuMaterialSearchButton from 'components/MoreMenuMaterialSearchButton';

const InstrumentsRightMenu = observer(() => {
  const { t } = useTranslation('packages');
  const { domainStore, postStore } = useStores();

  const handleSwitchDomainClick = () => {
    domainStore.setShowSwitchDomainFlyout(true);
  };

  const onCommentsFlyoutOpen = async () => {
    await postStore.loadDepartmentPosts(domainStore.currentDepartment.id);
    postStore.setIsChatFlyoutOpen(true);
  };

  return (
    <>
      <SideMenuIconButton handleClick={onCommentsFlyoutOpen} label={t('rightMenu.comments')} iconPath="icon_icon_f-comment_16_blue.svg" />
      <SideMenuIconButton handleClick={handleSwitchDomainClick} label={t('rightMenu.changeDepartment')} iconPath="icon_pin-edit_16.svg" />
      <MoreMenuMaterialSearchButton />
    </>
  );
});

export default InstrumentsRightMenu;
