import { observable, runInAction, action } from 'mobx';
import { FunctionalAreaDepartmentReportDTO } from 'dto/functionalArea';
import { getFunctionalAreaReport } from 'api/functionalArea';
import { getLocationUsageReport } from 'api/location';
import { LocationUsageReportDTO } from 'dto/location';
import LoadingStore from './loadingStore';

export default class ReportStore {
  @observable
  private loadingStore: LoadingStore;

  @observable
  filledGuideRations: FunctionalAreaDepartmentReportDTO[] = [];

  @observable
  locationReport: LocationUsageReportDTO | null = null;

  @observable
  isReportDrawerOpen = false;

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;
  }

  async loadFilledGuideRationsByAreaId(functionalAreaId: string) {
    const filledGuideRations = await this.loadingStore.withLoadingBar(() => getFunctionalAreaReport(functionalAreaId));
    runInAction(() => {
      this.filledGuideRations = filledGuideRations;
    });
  }

  async loadLocationReport(locationId: string) {
    const locationReport = await this.loadingStore.withLoadingBar(() => getLocationUsageReport(locationId));
    runInAction(() => {
      this.locationReport = locationReport;
    });
  }

  @action
  setIsReportDrawerOpen(status: boolean) {
    this.isReportDrawerOpen = status;
  }
}
