import { ListItemRendererType } from 'modules/material/ListItems/MaterialListItem';
import React, { useCallback } from 'react';

import { GuideMaterialLikeFlatDTO } from 'dto/guide';
import { GuideMaterialListItem } from '../ListItems/GuideMaterialListItem';
import { useUsedMaterialContext } from './UsedMaterial/UsedMaterialContext';
import { InstrumentRendererParentType } from '../../material/ListItems/Instrument/InstrumentRendererParentType';

/**
 * Implements the ListItemRendererType for the GuideMaterialLikeFlatDTO for surgeries
 */
export const SurgeryGuideMaterialListItemRenderer: ListItemRendererType<InstrumentRendererParentType<GuideMaterialLikeFlatDTO>> = ({
  parentEntity,
  level,
  name,
  picture,
  amount,
  dropdownItems,
  end,
  instructions,
  isInitialLoading,
  noExpandIconSpace,
  onClick,
  onDropdownExpand,
  prefix,
  classNamePrefix,
  enablePrefixExpand
}) => {
  const { openLotDialog } = useUsedMaterialContext();

  const handleAddLotNumber = useCallback(() => {
    openLotDialog({ surgeryGuideMaterialId: parentEntity.guideMaterialId, packageId: parentEntity.currentPackageId });
  }, [openLotDialog, parentEntity.currentPackageId, parentEntity.guideMaterialId]);

  return (
    <GuideMaterialListItem
      classNamePrefix={classNamePrefix}
      level={level}
      name={name}
      picture={picture}
      amount={amount}
      dropdownItems={dropdownItems}
      end={end}
      instructions={instructions}
      isInitialLoading={isInitialLoading}
      noExpandIconSpace={noExpandIconSpace}
      onClick={onClick}
      onDropdownExpand={onDropdownExpand}
      onOpenKnowledgeAndAttitudes={onClick}
      onAddLotNumber={handleAddLotNumber}
      enableLotNumberEdit={!!parentEntity.currentPackageId && !parentEntity.currentInstrumentId}
      prefix={prefix}
      enablePrefixExpand={enablePrefixExpand}
    />
  );
};
