import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import * as qs from 'query-string';

import { useStores } from 'util/mobx/stores';
import SingleInstrumentOverview from 'pages/singleInstrumentOverview/SingleInstrumentOverview';
import SingleInstrumentDetail from 'pages/singleInstrumentDetail/SingleInstrumentDetail';
import SingleInstrumentGlobalFunctions from 'pages/singleInstrumentGlobalFunctions/SingleInstrumentGlobalFunctions';
import SingleInstrumentStorageLocations from 'pages/SingleInstrumentStorageLocations';
import StorageLocationDetailDrawer from 'pages/locationAdministration/StorageLocationDetailDrawer';
import SelectLocationFlyout from 'pages/flyouts/SelectLocationFlyout';
import MaterialKnowledgeFormFlyout from 'components/MaterialKnowledge/MaterialKnowledgeFormFlyout';
import Safe from 'components/Safe';
import Page from 'components/Page';
import AppLayout from 'components/AppLayout';
import { ChatConfigurationTypes } from 'components/Chat/Chat';
import CreateMaterialSynonymFlyout from 'components/MaterialKnowledge/CreateMaterialSynonymFlyout';
import { AppBarHeaderText, AppBarPageIcon } from 'components/AppLayout/AppBar';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import MaterialValueTags from 'components/MaterialValueTags';
import PackageContent from 'pages/packageContentPage/PackageContentPage';
import SingleInstrumentNavSection from './SingleInstrumentNavSection';

const SingleInstrument = observer(() => {
  const { instrumentStore } = useStores();
  const { t } = useTranslation('singleInstruments');
  const location = useLocation();
  const packageId = qs.parse(location.search).packageId as string;
  useEffect(() => {
    instrumentStore.setPackageId(packageId);
    instrumentStore.loadFullPackage(packageId);
  }, [packageId, instrumentStore]);
  const image = instrumentStore.fullPackageItem?.pack.packagePicture?.pictureThumbnail;

  const onUpdateFile = async (fileId: string) => {
    if (instrumentStore.fullPackageItem) {
      await instrumentStore.updateMaterialInPackage(
        { pictureFileId: fileId, materialId: instrumentStore.fullPackageItem?.pack.instruments[0].material.materialId },
        packageId
      );
    }
  };
  return (
    <>
      <AppLayout
        appBarIcon={<AppBarPageIcon key={image} src={image} onUpdateFile={onUpdateFile} />}
        appBarChildren={<AppBarHeaderText headline={instrumentStore.fullPackageItem?.pack.instruments[0].material.name || ''} />}
        appBarRightChildren={<MaterialValueTags fullInfo classNameTitle="margin_left" pack={instrumentStore.fullPackageItem?.pack} />}
        boardFlyout={<BoardFlyout />}
        drawer={<StorageLocationDetailDrawer />}
        flyout={
          <>
            <MaterialKnowledgeFormFlyout />
            <CreateMaterialSynonymFlyout />
            <SelectLocationFlyout />
          </>
        }
      >
        <Page
          headline={t('subMenu.headline')}
          idPrefix="instruments"
          navigation={<SingleInstrumentNavSection packageId={packageId} />}
          chatConfigurationType={ChatConfigurationTypes.department}
        >
          <Switch>
            <Route path="/single-instrument/overview" component={() => <SingleInstrumentOverview packageId={packageId} />} />
            <Route path="/single-instrument/detail" component={() => <SingleInstrumentDetail />} />
            {/* The content / knowledge is exactly the same as for the normal package */}
            <Route
              path="/single-instrument/content"
              component={() => <PackageContent packageId={packageId} translationKey="singleInstruments" />}
            />
            <Route path="/single-instrument/functions" component={() => <SingleInstrumentGlobalFunctions />} />
            <Route path="/single-instrument/locations" component={() => <SingleInstrumentStorageLocations packageId={packageId} />} />
          </Switch>
        </Page>
      </AppLayout>
    </>
  );
});

// moved Safe here to prevent running the useEffect if not logged in
export default () => (
  <Safe>
    <SingleInstrument />
  </Safe>
);
