import { GuideMaterialsDTO } from 'dto/guide';
import {
  CreateSurgeryGuideMaterialDTO,
  FullSurgeryGuideDTO,
  GetSurgeryGuideMaterialsDTO,
  GroupedSurgeryGuideMaterialsDTO,
  SurgeryGuideIdDTO,
  SurgeryGuideMaterialLikeDTO,
  UpdateSortSurgeryGuideMaterialsDTO,
  UpdateSortSurgeryGuideMaterialsV2DTO,
  UpdateSurgeryGuideMaterialDTO
} from 'dto/surgeryGuide';
import {
  CreateSurgeryGuideProcedureDTO,
  GroupedSurgeryGuideProcedureDTO,
  SurgeryProcedureDTO,
  UpdateSortSurgeryGuideProceduresDTO,
  UpdateSurgeryGuideProcedureDTO
} from 'dto/surgeryGuideProcedure';
import { SetUsedMaterialDTO, UsedMaterialListDTO } from 'dto/usedMaterial';
import { del, get, patch, post, put } from './common';
import { validate, validateArray, validateBool } from './validation';

export const getSurgeryGuide = async (surgeryGuideId: string): Promise<FullSurgeryGuideDTO> => {
  const surgeryGuide = await get('/surgery/briefing/surgery-guide', { surgeryGuideId }).then(response => response.data);
  return validate(FullSurgeryGuideDTO, surgeryGuide);
};

export const deleteSurgeryGuide = async (surgeryGuideId: string): Promise<boolean> => {
  return del('/surgery/briefing/surgery-guide', { surgeryGuideId }).then(response => validateBool(response.data));
};

export const getSurgeryGuideMaterial = async (surgeryGuideMaterialId: string): Promise<SurgeryGuideMaterialLikeDTO> => {
  const surgeryGuideMaterial = await get('/surgery/briefing/surgery-guide/material', { surgeryGuideMaterialId }).then(
    response => response.data
  );
  return validate(SurgeryGuideMaterialLikeDTO, surgeryGuideMaterial);
};

/**
 * @deprecated use getSurgeryGuideMaterialsV2 instead
 * @param surgeryGuideId
 * @returns
 */
export const getSurgeryGuideMaterials = async (surgeryGuideId: string): Promise<GroupedSurgeryGuideMaterialsDTO[]> => {
  const surgeryGuideMaterials = await get('/surgery/briefing/surgery-guide/materials', { surgeryGuideId }).then(response => response.data);
  return validateArray(GroupedSurgeryGuideMaterialsDTO, surgeryGuideMaterials);
};

export const getSurgeryGuideMaterialsV2 = async (options: GetSurgeryGuideMaterialsDTO): Promise<GuideMaterialsDTO> => {
  const guideMaterials = await get('/v2/surgery/briefing/surgery-guide/materials', options).then(response => response.data);
  return validate(GuideMaterialsDTO, guideMaterials);
};

/**
 * @deprecated use v2 api
 * @param reorderedGuideMaterials
 * @returns
 */
export const reorderSurgeryGuideMaterials = async (reorderedGuideMaterials: UpdateSortSurgeryGuideMaterialsDTO[]) => {
  return put('/surgery/briefing/surgery-guide/materials/positions', reorderedGuideMaterials).then(response => validateBool(response.data));
};

export const reorderSurgeryGuideMaterialsV2 = async (reorderedSurgeryGuideMaterials: UpdateSortSurgeryGuideMaterialsV2DTO) => {
  return put('/v2/surgery/briefing/surgery-guide/materials/positions', reorderedSurgeryGuideMaterials).then(response =>
    validateBool(response.data)
  );
};

export const updateSurgeryGuideMaterial = async (surgeryGuideMaterialUpdateFields: UpdateSurgeryGuideMaterialDTO): Promise<void> => {
  await patch('/surgery/briefing/surgery-guide/material', surgeryGuideMaterialUpdateFields).then(response => response.data);
};

export const setUsedSurgeryGuideMaterials = async (fields: SetUsedMaterialDTO[]): Promise<void> => {
  await put('/surgery/briefing/surgery-guide/materials/used', fields);
};

export const getUsedSurgeryGuideMaterials = async (surgeryGuideId: string): Promise<UsedMaterialListDTO> => {
  const usedMaterials = await get('/surgery/briefing/surgery-guide/materials/used', { surgeryGuideId }).then(response => response.data);
  return validate(UsedMaterialListDTO, usedMaterials);
};

export const getSurgeryGuideProcedures = async (query: SurgeryGuideIdDTO): Promise<GroupedSurgeryGuideProcedureDTO[]> => {
  const procedureGroups = await get('/surgery/briefing/surgery-guide/procedures', query).then(response => response.data);
  return validateArray(GroupedSurgeryGuideProcedureDTO, procedureGroups);
};

export const reorderSurgeryGuideProcedures = async (reorderedGuideProcedures: UpdateSortSurgeryGuideProceduresDTO[]) => {
  return put('/surgery/briefing/surgery-guide/procedures/positions', reorderedGuideProcedures).then(response =>
    validateBool(response.data)
  );
};

export const updateSurgeryGuideProcedure = async (
  guideProcedureUpdateFields: UpdateSurgeryGuideProcedureDTO
): Promise<SurgeryProcedureDTO> => {
  const updatedGuideProcedure = await patch('/surgery/briefing/surgery-guide/procedure', guideProcedureUpdateFields).then(
    response => response.data
  );
  return validate(SurgeryProcedureDTO, updatedGuideProcedure);
};

export const createSurgeryGuideProcedure = async (newGuideProcedure: CreateSurgeryGuideProcedureDTO) => {
  const createdGuideProcedure = await post('/surgery/briefing/surgery-guide/procedure', newGuideProcedure).then(response => response.data);
  return validate(SurgeryProcedureDTO, createdGuideProcedure);
};

export const createSurgeryGuideMaterial = async (newGuideMaterial: CreateSurgeryGuideMaterialDTO) => {
  const createdGuideMaterial = await post('/surgery/briefing/surgery-guide/material', newGuideMaterial).then(response => response.data);
  return validate(SurgeryGuideMaterialLikeDTO, createdGuideMaterial);
};
