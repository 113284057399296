/* eslint-disable @typescript-eslint/no-explicit-any */

export const getKeyByValue = (enumType: { [key: number]: string }, value: string) => {
  let key: any = '';
  Object.keys(enumType).forEach((objectKey: any) => {
    if (typeof enumType[objectKey] === 'string' && enumType[objectKey].toLowerCase() === value.toLowerCase()) {
      key = objectKey;
    }
  });
  return Number.parseInt(key, 10);
};

export function getValueByKey(enumType: Record<number, string>, key: number) {
  return enumType[key].toString().toLowerCase();
}
