import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';

import { UserDTO } from 'dto/user';
import ImageCircle from 'components/ImageCircle';
import { dateFormat } from 'util/date';

interface Props {
  link: string;
  image?: string;
  name: string;
  text: string;
  additionText?: string;
  btnText?: string;
  user?: UserDTO;
  createdDate?: Date;
}

const OverviewBox = ({ link, image, name, text, additionText, btnText, user, createdDate }: Props) => (
  <Link to={link} replace className="div-block-47 w-inline-block">
    <div className="div-block-49">{image && <img src={`images/${image}.svg`} className="image-6" alt="" />}</div>
    <div className="div-block-50">
      <div className="material-name">{name}</div>
      {!createdDate && <div>{parse(text)}</div>}
      {user && createdDate && (
        <>
          <div>
            {text} {moment(createdDate).format(dateFormat)} {additionText}
          </div>
          <div className="list_item_name in_overview">
            <ImageCircle size="50" user={user} highlight={false} />
            <div className="item_name">
              <div className="t2 bottom-margin">{user.title}</div>
              <div>
                {user.lastName}, {user.firstName}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
    {btnText && (
      <div className="btn_small_overview">
        <div className="txt_dropout_1">{btnText}</div>
      </div>
    )}
  </Link>
);

export default OverviewBox;
