import React from 'react';
import LinkWrapper from 'components/LinkWrapper';
import { useStores } from 'util/mobx/stores';
import './AppMenuSettingsButton.css';
import Button from 'components/Form/Button';

interface Props {
  icon: string;
  label: string;
  routePath: string;
  currentPath: string;
  onClick?: () => void;
}

const iconUrl = (iconName: string) => `images/${iconName}.svg`;

const AppMenuSettingsButton = ({ icon, label, routePath, currentPath, onClick = () => null }: Props) => {
  const { appNavigationStore } = useStores();
  const onClickHandler = () => {
    appNavigationStore.closeMenu();
    onClick();
  };
  const isActive = () => {
    if (routePath === currentPath || routePath.split('/')[1] === currentPath.split('/')[1]) {
      return true;
    }
    return false;
  };

  const className = isActive() ? 'btn_settings btn-settings active' : 'btn_settings btn-settings';

  return (
    <LinkWrapper onClick={onClickHandler} routePath={routePath} fullWidth>
      <Button className={className}>
        <img src={iconUrl(icon)} alt="" className="image_setting" />
        <div className="text-block-5">{label}</div>
      </Button>
    </LinkWrapper>
  );
};

export default AppMenuSettingsButton;
