import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import SideMenuIconButton from 'components/SideMenuIconButton';

interface Props {
  groupType: string;
}

const WrapperFunctions = observer(({ groupType }: Props) => {
  const { t } = useTranslation(groupType);
  const { t: searchTranslation } = useTranslation('materialSearch');
  const { materialStore, searchStore, materialSetsStore, flyoutStore, guideStore } = useStores();

  const handlerReplaceGuidesMaterial = async () => {
    searchStore.openSearchPanel(m => {
      if (materialSetsStore.materialSetId) {
        guideStore.replaceMaterialInGuide(m, { materialSetId: materialSetsStore.materialSetId }).then(() => {
          if (!materialSetsStore.materialSetId) {
            materialSetsStore.loadGuides(materialSetsStore.materialSetId);
          }
        });
      }
    }, searchTranslation('searchItem.replace'));
  };

  const handlerDeleteGuidesMaterial = async () => {
    if (materialSetsStore.materialSetId) {
      await materialStore.deleteGuidesMaterial({
        materialSetId: materialSetsStore.materialSetId
      });
      materialSetsStore.loadGuides(materialSetsStore.materialSetId);
      materialSetsStore.refreshMaterialSet();
    }
  };

  return (
    <>
      <SideMenuIconButton
        handleClick={handlerReplaceGuidesMaterial}
        label={t('globalFunctions.rightMenu.replaceMaterial')}
        iconPath="icon_find_16.svg"
        disabled={materialSetsStore.guides.length === 0}
      />
      <SideMenuIconButton
        handleClick={handlerDeleteGuidesMaterial}
        red
        label={t('globalFunctions.rightMenu.deleteMaterial')}
        disabled={materialSetsStore.guides.length === 0}
        iconPath="icon_bin_16.svg"
      />
      <SideMenuIconButton
        handleClick={() => flyoutStore.setIsDeleteMaterialSetFlyoutOpen(true)}
        label={t('globalFunctions.rightMenu.deleteCompleteMaterial')}
        iconPath="icon_bin_16.svg"
        red
        disabled={materialSetsStore.deleteDisabled()}
      />
    </>
  );
});

export default WrapperFunctions;
