import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import Safe from 'components/Safe';
import Page from 'components/Page';
import { AppBarHeaderText, AppBarPageIcon } from 'components/AppLayout/AppBar';
import AppLayout from 'components/AppLayout';
import AccountManagementUsers from 'pages/accountManagementUsers';
import AccountManagementTitles from 'pages/accountManagementTitles';
import AccountManagementUserDetailsFlyout from 'pages/accountManagementUserDetailsFlyout';
import SettingsItemFlyoutForm from 'pages/settings/SettingsItemFlyoutForm';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import AccountManagementNavigation from './accountManagementNavigation';

const AccountManagement = observer(() => {
  const { t } = useTranslation('accountManagement');
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;
  return (
    <Safe>
      <AppLayout
        appBarIcon={<AppBarPageIcon src="images/image_kp-settings.jpg" />}
        appBarChildren={<AppBarHeaderText headline={t('pageTitle')} />}
        boardFlyout={<BoardFlyout />}
        flyout={
          <>
            <SettingsItemFlyoutForm />
            <AccountManagementUserDetailsFlyout />
          </>
        }
      >
        <Page
          disableBackButton
          headline={t('subMenu.headline')}
          idPrefix="guides"
          withChatBar
          navigation={<AccountManagementNavigation currentPath={currentPath} />}
          withoutRightMenu
          withoutRightMenuClass={false}
          withoutMoreBtn
        >
          <Switch>
            <Route path="/accountManagement/users" component={AccountManagementUsers} />
            <Route path="/accountManagement/titles" component={AccountManagementTitles} />
          </Switch>
        </Page>
      </AppLayout>
    </Safe>
  );
});

export default AccountManagement;
