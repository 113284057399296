import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import DeleteGuideFlyout from 'pages/flyouts/DeleteGuideFlyout';
import { Can, subjectArea, actions } from 'casl/setupCaslAbility';
import { subject } from '@casl/ability';
import DepartmentLead from '../../../components/DepartmentLead';
import GuideOwnerList from '../grid/content/ownerList/GuideOwnerList';
import BookList from '../grid/content/guideList/BookList';
import AdditionalBookList from '../grid/content/guideList/AdditionalBookList';
import ScrollPositionManager from '../../../components/ScrollPositionManager';
import GuidesRightNavigation from '../GuidesRightNavigation';
import GuideFlyout from '../grid/content/createGuide/GuideFlyout';

const GuidesContent = observer(() => {
  const { guideStore, domainStore, appNavigationStore } = useStores();
  const { t } = useTranslation('guide');
  useEffect(() => {
    const currentDepartmentId = domainStore.currentDepartment.id;
    if (currentDepartmentId) {
      guideStore.loadGuidesByDepartment(currentDepartmentId);
    }
  }, [guideStore, domainStore.currentDepartment.id]);

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <GuidesRightNavigation />;
    });
  }, [appNavigationStore]);

  const handleShowCreateGuideClick = () => {
    guideStore.setSelectedGuide(undefined);
    guideStore.setShowGuideFlyout(true);
  };

  return (
    <>
      <GuideFlyout />
      <DeleteGuideFlyout />
      <div className="w-layout-grid grid_colum_content">
        <div id="grid-guides-colum_left" className="colum_left">
          <DepartmentLead />
          <div className="line" />
          <GuideOwnerList guideOwnerList={guideStore.guideOwnerList} />
        </div>
        <ScrollPositionManager
          scrollKey="grid-guides-colum-right"
          id="grid-guides-colum_right"
          className="colum_right books bottom-padding-popover colum-scroll"
          style={{ gridColumnStart: '2' }}
        >
          <Can I={actions.add} this={subject(subjectArea.guide, { departmentId: domainStore.currentDepartment.id })}>
            <>
              <div className="list_item_book_add mobile_only" onClick={handleShowCreateGuideClick}>
                <div className="image_wrapper_50">
                  <img src="images/icon_add.jpg" width="50" alt="" className="image_circle_50" />
                </div>
                <div className="info_box">
                  <div>{t('create')}</div>
                </div>
              </div>
            </>
          </Can>
          <BookList guideList={guideStore.selectedGuides} />
          <>{guideStore.selectedGuideOwner?.userId && <AdditionalBookList />}</>
        </ScrollPositionManager>
      </div>
    </>
  );
});

export default GuidesContent;
