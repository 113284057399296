import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import FlyoutContainer from 'components/FlyoutContainer';
import { getDateWording } from 'util/date';
import { getSurgeryGuideProcedures } from 'api/surgeryGuide';
import { GroupedSurgeryGuideProcedureDTO, SurgeryProcedureDTO } from 'dto/surgeryGuideProcedure';
import ImageCircle from 'components/ImageCircle';
import PostMediaList from 'components/PostItem/PostMediaList';

const findProcedureById = (procedureId: string, procedures: GroupedSurgeryGuideProcedureDTO[]) => {
  let procedure: SurgeryProcedureDTO | undefined;
  procedures.forEach(groupedProcedure => {
    if (procedure) {
      return;
    }
    procedure = groupedProcedure.surgeryGuideProcedures.find(p => p.surgeryGuideProcedureId === procedureId);
  });
  return procedure;
};

const SettingsItemFlyoutForm = observer(() => {
  const [procedure, setProcedure] = useState<SurgeryProcedureDTO>();
  const { surgeryStore } = useStores();
  const { t } = useTranslation('surgery');
  const onClose = () => surgeryStore.setIsHistoryProcedureFlyoutOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      if (surgeryStore.isHistoryProcedureFlyoutOpen && surgeryStore.selectedHistoryGuideItem && surgeryStore.selectedHistoryItem) {
        const procedures = await getSurgeryGuideProcedures({ surgeryGuideId: surgeryStore.selectedHistoryGuideItem.surgeryGuideId });
        const selectedProcedure = findProcedureById(surgeryStore.selectedHistoryItem.objectId, procedures);
        if (selectedProcedure) {
          setProcedure(selectedProcedure);
        }
      }
    };
    fetchData();
  }, [surgeryStore, surgeryStore.isHistoryProcedureFlyoutOpen]);

  const date = getDateWording(surgeryStore.selectedHistoryItem?.createdAt, useTranslation('date'));

  return (
    <FlyoutContainer
      icon={<img src="images/icon_edit.jpg" alt="" className="image_circle_40" />}
      isOpen={surgeryStore.isHistoryProcedureFlyoutOpen}
      closePopUp={onClose}
      cancelLabel={t('historyProcedureItem.cancel')}
      title={t('historyProcedureItem.title')}
    >
      <>
        <div className="flyout_box">
          <div className="txt_chapter_flyout">{date}</div>
          {procedure && surgeryStore.selectedHistoryItem && (
            <div className="post_item in_flyout">
              <div className="post_box in_flyout_board">
                <ImageCircle
                  className="image_circle_40_post"
                  size="40"
                  user={surgeryStore.selectedHistoryItem.user}
                  highlight={false}
                  noWrapper
                />
                <div className="post_box in_post">
                  {procedure.content.isCheckable && <img src="images/icon_checkable_22.svg" alt="" className="icon_checkable" />}
                  <div className="h2">{procedure.content.title}</div>
                  <div className="show-newlines">{procedure.content.description}</div>
                  <PostMediaList files={procedure.content.contentElementMedias} />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    </FlyoutContainer>
  );
});

export default SettingsItemFlyoutForm;
