import { observable, action, runInAction, computed } from 'mobx';
import { getLocations, updateLocations, createLocations } from 'api/location';
import { updateFunctionalArea, createFunctionalArea } from 'api/functionalArea';
import { updateDepartment, createDepartment } from 'api/departments';
import { getGuideChapters, updateGuideChapter, createGuideChapter } from 'api/guideChapter';
import { getGuideMaterialCategories, updateGuideMaterialCategory, createGuideMaterialCategory } from 'api/guideMaterialCategory';
import { getMaterialChapters, createMaterialChapter, updateMaterialChapter } from 'api/materialChapter';
import { LocationDTO, UpdateLocationDTO, CreateLocationDTO } from 'dto/location';
import { CreateFunctionalAreaDTO, UpdateFunctionalAreaDTO, SimpleFunctionalAreaDTO } from 'dto/functionalArea';
import { DepartmentDTO, CreateDepartmentDTO, UpdateDepartmentDTO } from 'dto/department';
import { UpdateGuideChapterDTO, CreateGuideChapterDTO, GroupedGuideChapterDTO } from 'dto/guideChapter';
import { UpdateGuideMaterialCategoryDTO, CreateGuideMaterialCategoryDTO, GroupedGuideMaterialCategoryDTO } from 'dto/guideMaterialCategory';
import { MaterialChapterDTO, UpdateMaterialChapterDTO, CreateMaterialChapterDTO } from 'dto/materialChapter';
import { UpdateTitleDTO, TitleDTO, CreateTitleDTO } from 'dto/title';
import { updateTitle, getTitles, createTitle } from 'api/user';
import {
  CreateSurgeryRoomDTO,
  SetSurgeryRoomFunctionalAreaDTO,
  SurgeryRoomDTO,
  SurgeryRoomFunctionalAreaDTO,
  UpdateSurgeryRoomDTO,
  UpdateSortSurgeryRoomDTO
} from 'dto/surgeryRoom';
import {
  getSurgeryRooms,
  createSurgeryRoom,
  updateSurgeryRoom,
  getSurgeryRoomFunctionalAreas,
  setSurgeryRoomFunctionalArea,
  reorderSurgeryRooms
} from 'api/surgeryRoom';
import LoadingStore from './loadingStore';
import DomainStore from './domainStore';

export enum SettingsItemTypes {
  location = 'location',
  functionalArea = 'functionalArea',
  department = 'department',
  guideChapter = 'guideChapter',
  materialCategory = 'materialCategory',
  materialChapter = 'materialChapter',
  accountManagementTitle = 'accountManagementTitle',
  surgeryRoom = 'surgeryRoom'
}

export interface SettingsItemDTO {
  name: string;
  shortName?: string;
  itemId?: string;
  disabled?: boolean;
  type?: SettingsItemTypes;
}

export default class SettingsStore {
  @observable
  private loadingStore: LoadingStore;

  @observable
  private domainStore: DomainStore;

  @observable
  locations: LocationDTO[] = [];

  @observable
  surgeryRooms: SurgeryRoomDTO[] = [];

  @observable
  surgeryRoomFunctionalAreas: SurgeryRoomFunctionalAreaDTO[] = [];

  @observable
  guideChapters: GroupedGuideChapterDTO[] = [];

  @observable
  materialCategories: GroupedGuideMaterialCategoryDTO[] = [];

  @observable
  materialChapters: MaterialChapterDTO[] = [];

  @observable
  accountManagementTitles: TitleDTO[] = [];

  @observable
  itemFormFlyoutOpen = false;

  @observable
  itemFormFlyoutType?: SettingsItemTypes;

  @observable
  itemFormFlyoutUpdateTitle?: string;

  @observable
  itemFormFlyoutCreateTitle?: string;

  @observable
  selectedItem: SettingsItemDTO | null = null;

  @observable
  selectedLocation: LocationDTO | null = null;

  @observable
  selectedSurgeryRoom: SurgeryRoomDTO | null = null;

  @observable
  selectedFunctionalArea: SimpleFunctionalAreaDTO | null = null;

  @observable
  selectedDepartment: DepartmentDTO | null = null;

  @computed
  get titlesDropdownOptions() {
    return this.accountManagementTitles.map(title => ({
      label: title.name,
      value: title.titleId
    }));
  }

  constructor(domainStore: DomainStore, loadingStore: LoadingStore) {
    this.domainStore = domainStore;
    this.loadingStore = loadingStore;
  }

  @action
  async loadLocations() {
    const locations = await this.loadingStore.withLoadingBar(() => getLocations({ includeChildren: true, includeDisabled: true }));
    await this.domainStore.loadAllDepartments();
    runInAction(() => {
      this.locations = locations;
    });
  }

  @action
  async loadSurgeryRooms() {
    if (this.selectedLocation) {
      const locationId = this.selectedLocation.locationId;
      const surgeryRooms = await this.loadingStore.withLoadingBar(() => getSurgeryRooms({ locationId }));
      runInAction(() => {
        this.surgeryRooms = surgeryRooms;
      });
    }
  }

  async reorderSurgeryRooms(surgeryRooms: UpdateSortSurgeryRoomDTO) {
    await this.loadingStore.withLoadingBar(() => reorderSurgeryRooms(surgeryRooms));
    await this.loadSurgeryRooms();
  }

  @action
  async loadSurgeryRoomFunctionalAreas() {
    if (this.selectedSurgeryRoom) {
      const surgeryRoomId = this.selectedSurgeryRoom.surgeryRoomId;
      const surgeryRoomFunctionalAreas = await this.loadingStore.withLoadingBar(() => getSurgeryRoomFunctionalAreas(surgeryRoomId));
      runInAction(() => {
        this.surgeryRoomFunctionalAreas = surgeryRoomFunctionalAreas;
      });
    }
  }

  @action
  async loadGuideChapters() {
    const guideChapters = await this.loadingStore.withLoadingBar(() =>
      getGuideChapters({ includeDisabledChapters: true, includeDisabledFunctionalAreas: true })
    );
    runInAction(() => {
      this.guideChapters = guideChapters;
    });
  }

  @action
  async loadMaterialCategories() {
    const materialCategories = await this.loadingStore.withLoadingBar(() =>
      getGuideMaterialCategories({ includeDisabledCategories: true, includeDisabledFunctionalAreas: true })
    );
    runInAction(() => {
      this.materialCategories = materialCategories;
    });
  }

  @action
  async loadMaterialChapters() {
    const materialChapters = await this.loadingStore.withLoadingBar(() => getMaterialChapters({ includeDisabled: true }));
    runInAction(() => {
      this.materialChapters = materialChapters;
    });
  }

  @action
  async loadAccountManagementTitles() {
    const accountManagementTitles = await this.loadingStore.withLoadingBar(() => getTitles());
    runInAction(() => {
      this.accountManagementTitles = accountManagementTitles;
    });
  }

  @action
  async updateLocation(location: UpdateLocationDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => updateLocations(location));
    this.loadLocations();
  }

  @action
  async createLocation(location: CreateLocationDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => createLocations(location));
    this.loadLocations();
  }

  @action
  async updateFunctionalArea(functionalArea: UpdateFunctionalAreaDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => updateFunctionalArea(functionalArea));
    this.loadLocations();
  }

  @action
  async createFunctionalArea(functionalArea: CreateFunctionalAreaDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => createFunctionalArea(functionalArea));
    this.loadLocations();
  }

  @action
  async createSurgeryRoom(surgeryRoom: CreateSurgeryRoomDTO) {
    const newSurgeryRoom = await this.loadingStore.withFlyoutLoadingBar(() => createSurgeryRoom(surgeryRoom));
    this.loadSurgeryRooms();
    this.setSelectedSurgeryRoom(newSurgeryRoom);
  }

  @action
  async updateSurgeryRoom(surgeryRoom: UpdateSurgeryRoomDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => updateSurgeryRoom(surgeryRoom));
    this.loadSurgeryRooms();
  }

  @action
  async setSurgeryRoomFunctionalArea(surgeryRoomFunctionalArea: SetSurgeryRoomFunctionalAreaDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => setSurgeryRoomFunctionalArea(surgeryRoomFunctionalArea));
    this.loadSurgeryRoomFunctionalAreas();
  }

  @action
  async updateDepartment(department: UpdateDepartmentDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => updateDepartment(department));
    this.loadLocations();
  }

  @action
  async createDepartment(department: CreateDepartmentDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => createDepartment(department));
    this.loadLocations();
  }

  @action
  async updateGuideChapter(guideChapter: UpdateGuideChapterDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => updateGuideChapter(guideChapter));
    this.loadGuideChapters();
  }

  @action
  async createGuideChapter(guideChapter: CreateGuideChapterDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => createGuideChapter(guideChapter));
    this.loadGuideChapters();
  }

  @action
  async updateMaterialCategory(materialCategory: UpdateGuideMaterialCategoryDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => updateGuideMaterialCategory(materialCategory));
    this.loadMaterialCategories();
  }

  @action
  async createMaterialCategory(materialCategory: CreateGuideMaterialCategoryDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => createGuideMaterialCategory(materialCategory));
    this.loadMaterialCategories();
  }

  @action
  async updateMaterialChapter(materialChapter: UpdateMaterialChapterDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => updateMaterialChapter(materialChapter));
    this.loadMaterialChapters();
  }

  @action
  async createMaterialChapter(materialChapter: CreateMaterialChapterDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => createMaterialChapter(materialChapter));
    this.loadMaterialChapters();
  }

  @action
  async updateAccountManagementTitle(accountManagementTitle: UpdateTitleDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => updateTitle(accountManagementTitle));
    this.loadAccountManagementTitles();
  }

  @action
  async createAccountManagementTitle(accountManagementTitle: CreateTitleDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => createTitle(accountManagementTitle));
    this.loadAccountManagementTitles();
  }

  @action
  setItemFormFlyoutOpen(status: boolean) {
    this.itemFormFlyoutOpen = status;
  }

  @action
  setItemFormFlyoutType(type: SettingsItemTypes) {
    this.itemFormFlyoutType = type;
  }

  @action
  setItemFormFlyoutTitles(updateItemTitle: string, createItemTitle: string) {
    this.itemFormFlyoutUpdateTitle = updateItemTitle;
    this.itemFormFlyoutCreateTitle = createItemTitle;
  }

  @action
  async setSelectedItem(item: SettingsItemDTO | null) {
    this.selectedItem = item;
  }

  @action
  async setSelectedLocation(item: LocationDTO | null) {
    this.selectedLocation = item;
    this.selectedSurgeryRoom = null;
  }

  @action
  async setSelectedSurgeryRoom(item: SurgeryRoomDTO | null) {
    this.selectedSurgeryRoom = item;
  }

  @action
  async setSelectedFunctionalArea(item: SimpleFunctionalAreaDTO | null) {
    this.selectedFunctionalArea = item;
  }

  @action
  async setSelectedDepartment(item: DepartmentDTO | null) {
    this.selectedDepartment = item;
  }
}
