import {
  CreateStorageLocationDTO,
  SimpleStorageLocationDTO,
  StorageLocationDTO,
  UpdateStorageLocationDTO,
  UpdateSortStorageLocationDTO,
  GetStorageLocationDTO,
  DeleteWholeStorageLocationResultDTO,
  StorageLocationPathDTO
} from 'dto/storageLocation';
import { post, get, del, patch, put } from './common';
import { validateBool, validate, validateArray } from './validation';

export const createStorageLocation = async (storageLocation: CreateStorageLocationDTO): Promise<SimpleStorageLocationDTO> => {
  const newValue = await post('/storage-location', storageLocation).then(response => response.data);
  return validate(SimpleStorageLocationDTO, newValue);
};

export const getStorageLocationChildren = async (storageLocation: GetStorageLocationDTO): Promise<SimpleStorageLocationDTO[]> => {
  const values = await get('/storage-location/children', storageLocation).then(response => response.data);
  return validateArray(SimpleStorageLocationDTO, values);
};

export const getStorageLocation = async (storageLocationId: string): Promise<StorageLocationDTO> => {
  const values = await get('/storage-location', { storageLocationId }).then(response => response.data);
  return validate(StorageLocationDTO, values);
};

export const deleteStorageLocation = async (storageLocationId: string): Promise<DeleteWholeStorageLocationResultDTO> => {
  return del('/storage-location', { storageLocationId }).then(response => validate(DeleteWholeStorageLocationResultDTO, response.data));
};

export const updateStorageLocation = async (updateFields: UpdateStorageLocationDTO): Promise<SimpleStorageLocationDTO> => {
  const updatedValue = await patch(`/storage-location`, updateFields).then(response => response.data);
  return validate(SimpleStorageLocationDTO, updatedValue);
};

export const reorderStorageLocations = async (reorderedStorageLocations: UpdateSortStorageLocationDTO) => {
  return put('/storage-location/positions', reorderedStorageLocations).then(response => validateBool(response.data));
};

export const getStorageLocationPath = async (storageLocationId: string): Promise<StorageLocationPathDTO> => {
  const values = await get('/storage-location/path', { storageLocationId }).then(response => response.data);
  return validate(StorageLocationPathDTO, values);
};
