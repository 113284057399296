import React from 'react';
import LazyLoadExternal, { LazyLoadProps } from 'react-lazyload';

export interface OptionalLazyLoadProps {
  lazyLoadScrollContainer?: string;
}

type Props = OptionalLazyLoadProps & Omit<LazyLoadProps, 'scrollContainer'>;

const OptionalLazyLoad = ({ lazyLoadScrollContainer, children, ...rest }: Props) => {
  if (lazyLoadScrollContainer) {
    return (
      <LazyLoadExternal scrollContainer={lazyLoadScrollContainer} {...rest}>
        {children}
      </LazyLoadExternal>
    );
  }

  return <>{children}</>;
};

export default OptionalLazyLoad;
