import React from 'react';
import { PostDTO } from 'dto/post';
import ImageCircle from 'components/ImageCircle';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { isPostNew } from 'util/date';
import BoardPostFooter from './BoardPostFooter';
import './DepartmentPost.css';

interface Props {
  post: PostDTO;
}

const DepartmentPost = ({ post }: Props) => {
  const isNew = true;
  const { postStore } = useStores();
  const { t } = useTranslation('board');
  const onShowDetails = () => {
    postStore.setIsFlyoutInBoardOpen(true);
    postStore.setSelectedPost(post);
  };
  if (!post.department) {
    return null;
  }
  return (
    <div className="board-post-wrapper">
      <div className="board_box flex_vertical board-box" onClick={onShowDetails}>
        <div className="b_box_border" />
        {isNew && isPostNew(post.createdAt) && (
          <div className="b_tag_new">
            <div>{t('label.new')}</div>
          </div>
        )}
        <div className="b_box_content">
          <ImageCircle size="40" user={post.contentElement.user} highlight={false} withUserInfoBoxRight infoBoxBoardPosition />

          <div className="c_entry_name">
            <div>
              {post.contentElement?.user.firstName}, {post.contentElement?.user.lastName}
            </div>
          </div>
        </div>
        <BoardPostFooter departmentName={post.department?.name} createAt={post.createdAt} />
      </div>
    </div>
  );
};

export default DepartmentPost;
