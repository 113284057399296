import React from 'react';
import './ListItemText.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
  isSelected?: boolean;
}

const ListItemText = ({ text, isSelected = false, ...rest }: Props) => (
  <div className={`list_item_text list-item-text ${isSelected ? 'list-item-text--selected' : ''}`} {...rest}>
    <div className="item_name">
      <div>{text}</div>
    </div>
    <img src="images/icon_arrow_normal.svg" alt="" className="image-arrow-list image_arrow_list" />
  </div>
);

export default ListItemText;
