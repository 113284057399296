import React from 'react';
import Popover from 'components/Popover';
import ItemMenu from 'components/ItemMenu';
import { SurgeryProcedureDTO } from 'dto/surgeryGuideProcedure';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Button from 'components/Form/Button';
import './SurgeryProcedureStatusBox.css';
import { useStores } from 'util/mobx/stores';

interface Props {
  procedure: SurgeryProcedureDTO;
}

const getLabelClass = (procedure: SurgeryProcedureDTO) => {
  if (procedure.disabled) {
    return 'lable_red';
  }
  return '';
};

const getLabelText = (procedure: SurgeryProcedureDTO, t: TFunction) => {
  if (procedure.disabled) {
    return t('briefingProcedures.procedureItem.disabled');
  }
  if (!procedure.originalGuideProcedureId) {
    return t('briefingProcedures.procedureItem.withoutOrigin');
  }
  return t('briefingProcedures.procedureItem.planned');
};

const SurgeryProcedureStatusBox = ({ procedure }: Props) => {
  const { surgeryGuideStore } = useStores();
  const { t } = useTranslation('briefing');

  const onChangeProcedureStatus = (disabled: boolean) => {
    surgeryGuideStore.updateProcedure({ surgeryGuideProcedureId: procedure.surgeryGuideProcedureId, disabled });
  };

  return (
    <div className={`div-block-160 surgery-procedure-container ${getLabelClass(procedure)}`}>
      <Popover
        trigger={
          <div className="label-inner">
            <div>{getLabelText(procedure, t)}</div>
            <img src="images/icon_small-triangle-down_white.svg" alt="" className="image_arrow_lable" />
          </div>
        }
      >
        {({ handleClose, isOpen }) => (
          <ItemMenu isOpen={!!isOpen} handleClose={handleClose} className="padding_top status-menu">
            <div className="txt_info_text">{t('briefingProcedures.procedureItem.status')}</div>
            <div className="line_menu-edit" />
            {procedure.disabled ? (
              <Button className="btn_list_edit status-button" onClick={() => onChangeProcedureStatus(false)}>
                <div>{t('briefingProcedures.procedureItem.planned')}</div>
              </Button>
            ) : (
              <Button className="btn_list_edit status-button" onClick={() => onChangeProcedureStatus(true)}>
                <div>{t('briefingProcedures.procedureItem.disabled')}</div>
              </Button>
            )}
          </ItemMenu>
        )}
      </Popover>
    </div>
  );
};

export default SurgeryProcedureStatusBox;
