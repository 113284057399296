/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsBoolean, IsUUID, IsString, ValidateNested, IsNotEmptyObject, IsOptional } from 'class-validator';
import { Type } from 'class-transformer';
import { UserDTO } from './user';
import { SimpleDepartmentDTO } from './department';

export class SetDepartmentMaintainerDTO {
  @IsBoolean()
  isLead!: boolean;

  @IsUUID()
  userId!: string;

  @IsUUID()
  departmentId!: string;
}

/**
 * GetDepartmentMaintainerDTO is used to GET the Maintainer Rights of a specific User.
 * @param departmentId if passed it only checks if a User has Permissions inside the given Department.
 */
export class GetDepartmentMaintainerDTO {
  @IsUUID()
  userId!: string;

  @IsUUID()
  @IsOptional()
  departmentId?: string;
}

/**
 * DepartmentMaintainerDTO is used to Validate the Mapping if you need the FUll a DepartmentMaintainer Entry.
 */
export class DepartmentMaintainerDTO {
  @IsUUID()
  departmentMaintainerId!: string;

  @IsBoolean()
  isLead!: boolean;

  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => UserDTO)
  user!: UserDTO;

  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => SimpleDepartmentDTO)
  department!: SimpleDepartmentDTO;
}

/**
 * DepartmentMaintainerDTO is used to Validate the Mapping if you only need the Ids of department and user.
 */
export class SimpleDepartmentMaintainerDTO {
  @IsUUID()
  departmentMaintainerId!: string;

  @IsBoolean()
  isLead!: boolean;

  @IsUUID()
  @IsString()
  userId!: string;

  @IsUUID()
  departmentId!: string;
}

export class DeleteDepartmentMaintainerDTO {
  @IsUUID()
  @IsString()
  userId!: string;

  @IsUUID()
  departmentId!: string;
}
