import React from 'react';
import { ListItemContainer } from './ListItemContainer';
import './MaterialListItem.css';

export interface InstructionRendererProps<T> {
  parentEntity: T;
}

export type InstructionRendererType<T> = React.FC<InstructionRendererProps<T>>;

export interface ListItemRendererProps<T> {
  parentEntity: T;
  level: number;
  classNamePrefix?: string;
  onDropdownExpand?: () => void;
  onClick?: () => void;
  dropdownItems?: JSX.Element[];
  isInitialLoading?: boolean;
  amount?: number;
  name: string;
  picture: React.ReactNode;
  instructions?: React.ReactNode;
  end: React.ReactNode;
  prefix?: React.ReactNode;
  menu?: React.ReactNode;

  /**
   * This can be set to remove the space reserved for the expand-icon
   */
  noExpandIconSpace?: boolean;

  enablePrefixExpand?: boolean;
}

export type ListItemRendererType<T> = React.FC<ListItemRendererProps<T>>;

interface Props {
  containerId?: string;
  classNamePrefix: string;

  name: string;
  picture: React.ReactNode;
  onClick?: () => void;

  amount?: number;
  instructions?: React.ReactNode;
  end?: React.ReactNode;

  menu?: React.ReactNode;

  prefix?: React.ReactNode;

  level: number;
  onDropdownExpand?: () => void;
  dropdownItems?: React.ReactNode;

  /**
   * This can be set to remove the space reserved for the expand-icon
   */
  noExpandIconSpace?: boolean;

  isInitialLoading?: boolean;
  forceExpand?: boolean;

  enablePrefixExpand?: boolean;
}
export const MaterialListItem = ({
  containerId,
  classNamePrefix,
  name,
  picture,
  onClick,
  onDropdownExpand,
  amount,
  instructions,
  end,
  menu,
  prefix,
  level,
  dropdownItems,
  noExpandIconSpace,
  isInitialLoading = false,
  forceExpand = false,
  enablePrefixExpand = false
}: Props) => {
  return (
    <ListItemContainer
      containerId={containerId}
      forceExpand={forceExpand}
      classNamePrefix={classNamePrefix}
      prefix={
        <>
          <div className="prefix-space">{prefix}</div>
          <div
            style={{
              marginLeft: level > 0 ? level * 60 : 0
            }}
          />
          {amount !== undefined && (
            <div className="material-amount flex-vertical-center">
              <div>{amount}</div>
            </div>
          )}
        </>
      }
      enablePrefixExpand={enablePrefixExpand}
      level={level}
      onDropdownExpand={onDropdownExpand}
      isInitialLoading={isInitialLoading}
      dropdownItems={dropdownItems}
      noExpandIconSpace={noExpandIconSpace}
    >
      <div className="flex-vertical-center">
        <div className="image_wrapper_50 guide-material-image">{picture}</div>
      </div>

      <div className="material_info">
        <div className="material_text" onClick={onClick}>
          <div>{name}</div>
          {instructions}
        </div>
        {end}
        <div style={{ width: 40 }}>{menu}</div>
      </div>
    </ListItemContainer>
  );
};
