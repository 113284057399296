import React from 'react';
import Picture from 'components/Picture';
import './AppBarPageIcon.css';
import GetCameraImageButton from 'components/GetCameraImageButton';
import HoverWrapper from 'components/HoverWrapper';

interface Props extends React.HTMLProps<HTMLDivElement> {
  src?: string;
  altSrc?: string;
  className?: string;
  onUpdateFile?: (file: string) => Promise<void>;
}

const AppBarPageIcon = ({ src, altSrc, className = '', onUpdateFile, ...additionalProps }: Props) => (
  <div className={`title_icon ${className}`} {...additionalProps}>
    {onUpdateFile && !src ? (
      <HoverWrapper>
        {({ hover }) => (
          <GetCameraImageButton buttonStyle="appBar" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable hover={hover} />
        )}
      </HoverWrapper>
    ) : (
      <Picture src={src} altSrc={altSrc} width="50" alt="" className="image_circle_40" />
    )}
  </div>
);

export default AppBarPageIcon;
