import { CreateSynonymDTO, SynonymDTO, UpdateMaterialSynonymDTO, DeleteMaterialSynonymDTO } from 'dto/synonym';
import { get, post, patch, del } from './common';

import { validate, validateBool } from './validation';

export const createMaterialSynonym = async (materialSynonym: CreateSynonymDTO): Promise<SynonymDTO> => {
  const newMaterialSynonym = await post('/synonym', materialSynonym).then(response => response.data);
  return validate(SynonymDTO, newMaterialSynonym);
};

export const getMaterialSynonym = async (materialId: string): Promise<SynonymDTO> => {
  const materialSynonym = await get('/synonym', { materialId }).then(response => response.data);
  return validate(SynonymDTO, materialSynonym);
};

export const updateMaterialSynonym = async (synonymToChange: UpdateMaterialSynonymDTO): Promise<SynonymDTO> => {
  const updatedMaterialSynonym = await patch('/synonym', synonymToChange).then(response => response.data);
  return validate(SynonymDTO, updatedMaterialSynonym);
};

export const deleteMaterialSynonym = async (materialSynonymId: DeleteMaterialSynonymDTO) => {
  return del('/synonym', materialSynonymId).then(response => validateBool(response.data));
};
