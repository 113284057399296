import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { saveFile } from 'api/file';
import { CreateMaterialSetDTO } from 'dto/materialSet';
import FlyoutContainer from 'components/FlyoutContainer';
import UploadFile from 'components/UploadFiles';
import { useStores } from 'util/mobx/stores';
import { ContentType } from 'dto/file';
import Form, { InputField } from 'components/Form';

interface CreateGroupValues {
  name: string;
}

const SetGroupFormFlyout = observer(() => {
  const { t: createSetGroupFlyoutTranslation } = useTranslation('createSetGroupFlyout');
  const { t: updateSetGroupFlyoutTranslation } = useTranslation('updateSetGroupFlyout');
  const { materialSetsStore } = useStores();
  const [isAllowedToSubmit, setIsAllowedToSubmit] = useState(false);
  const [imageId, setImageId] = useState<string | undefined>(materialSetsStore.selectedSetGroup?.pictureFileId);

  const initialValues: CreateGroupValues = {
    name: materialSetsStore.selectedSetGroup ? materialSetsStore.selectedSetGroup.name : ''
  };

  useEffect(() => {
    setIsAllowedToSubmit(false);
    setImageId(undefined);
  }, [materialSetsStore.isSetGroupFormFlyoutOpen]);

  const closePopUp = () => materialSetsStore.setIsSetGroupFormFlyoutOpen(false);

  const onGroupCreate = (values: CreateGroupValues) => {
    materialSetsStore.createSetGroup({
      name: values.name,
      pictureFileId: imageId,
      materialSetId: materialSetsStore.materialSetId,
      position: 0
    });
    materialSetsStore.setIsSetGroupFormFlyoutOpen(false);
  };

  const onGroupUpdate = (values: CreateGroupValues) => {
    if (!materialSetsStore.selectedSetGroup) return;
    materialSetsStore.updateSetGroup({
      name: values.name,
      pictureFileId: imageId,
      setGroupId: materialSetsStore.selectedSetGroup.setGroupId
    });
    materialSetsStore.setIsSetGroupFormFlyoutOpen(false);
  };

  const onSubmit = (values: CreateGroupValues) => {
    if (materialSetsStore.selectedSetGroup) {
      onGroupUpdate(values);
    } else {
      onGroupCreate(values);
    }
  };

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  const bindAllowedToSubmit = (isDirty: boolean, values: CreateMaterialSetDTO) => {
    if (!materialSetsStore.selectedSetGroup && isDirty && values.name) {
      setIsAllowedToSubmit(true);
      return;
    }
    if (materialSetsStore.selectedSetGroup && values.name) {
      setIsAllowedToSubmit(true);
      return;
    }

    setIsAllowedToSubmit(false);
  };

  const handleSubmitMyForm = () => {
    if (submitMyForm) submitMyForm();
  };

  const t = materialSetsStore.selectedSetGroup ? updateSetGroupFlyoutTranslation : createSetGroupFlyoutTranslation;

  return (
    <FlyoutContainer
      icon={<img src="images/icon_new_single_material.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={materialSetsStore.isSetGroupFormFlyoutOpen}
      closePopUp={closePopUp}
      cancelLabel={t('button.cancel')}
      onSubmit={handleSubmitMyForm}
      submitLabel={t('button.submit')}
      isAllowedToSubmit={isAllowedToSubmit}
      title={t('headline')}
    >
      <Form initialValues={initialValues} bindSubmitForm={bindSubmitForm} bindAllowedToSubmit={bindAllowedToSubmit} onSubmit={onSubmit}>
        {() => {
          return (
            <div className="flyout_scroll_wrapper">
              <div className="flyout_box">
                <div>{t('label.name')}</div>
                <InputField name="name" placeholder={t('label.namePlaceholder')} />
              </div>
              <div className="flyout_box">
                <div>{t('label.media')}</div>
                <UploadFile
                  setImageId={setImageId}
                  cameraImageButtonDescription={t('imageButton')}
                  cameraImageButtonDescriptionAlready={t('imageButtonImageAlreadyThere')}
                  saveFile={saveFile}
                  picturesOnly
                  accept={[ContentType.Picture]}
                  imageSrc={materialSetsStore.selectedSetGroup?.pictureThumbnail}
                />
              </div>
            </div>
          );
        }}
      </Form>
    </FlyoutContainer>
  );
});
export default SetGroupFormFlyout;
