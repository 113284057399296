import React from 'react';
import Lottie from 'react-lottie';

import animationData from './menu.json';

interface Props {
  direction: boolean;
}

export default (props: Props) => {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData
  };

  const size = 28;

  return <Lottie options={defaultOptions} direction={props.direction ? -1 : 1} height={size} width={size} speed={1.5} />;
};
