import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import MaterialKnowledge from 'components/MaterialKnowledge';
import MaterialSetContentRightMenu from './MaterialSetContentRightMenu';

interface Props {
  materialSetId: string;
  groupType: string;
}

const MaterialSetContent = observer(({ materialSetId, groupType }: Props) => {
  const { appNavigationStore, domainStore } = useStores();

  // set the component for the right menu
  useEffect(() => {
    if (!domainStore.isMobile) {
      appNavigationStore.setRightMenuBuilder(() => {
        return <MaterialSetContentRightMenu groupType={groupType} />;
      });
      return;
    }
    appNavigationStore.withoutMenu();
  }, [appNavigationStore, groupType, domainStore.isMobile]);

  appNavigationStore.useSubPageIdSetter('knowledge');

  return (
    <div className="single_colum_content">
      <MaterialKnowledge materialLikeId={{ materialSetId }} />
    </div>
  );
});

export default MaterialSetContent;
