import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import FlyoutContainer from 'components/FlyoutContainer';
import { moveStorageItems } from 'api/storageItem';
import { StorageLocationDTO } from 'dto/storageLocation';
import { UpdateStorageItemDTO } from 'dto/storageItem';
import SelectMaterialsContent from './SelectMaterialsContent';
import SelectMaterialLocationContent from './SelectMaterialLocationContent';

enum Step {
  SELECT_MATERIALS,
  SELECT_LOCATION
}

const MoveStorageLocationFlyout = observer(() => {
  const { flyoutStore, storageLocationStore } = useStores();
  const { t } = useTranslation('storageLocations');

  const [step, setStep] = useState(Step.SELECT_MATERIALS);
  const [selectedStorageLocation, setSelectedStorageLocation] = useState<StorageLocationDTO>();
  const [currentIndex, setCurrentIndex] = useState<number>();
  const [selectedMaterialItems, setSelectedMaterialItems] = useState(new Set<string>());

  const handleClose = useCallback(async () => {
    setStep(Step.SELECT_MATERIALS);
    const payload: UpdateStorageItemDTO[] = [];
    selectedMaterialItems.forEach(item => {
      payload.push({ storageItemId: item, storageLocationId: selectedStorageLocation?.storageLocationId });
    });
    if (selectedStorageLocation?.storageLocationId) {
      await moveStorageItems(payload);

      if (selectedStorageLocation && currentIndex !== undefined) {
        storageLocationStore.setSelectedStorageLocation(selectedStorageLocation, currentIndex);
      }
    }

    setSelectedStorageLocation(undefined);
    flyoutStore.setIsMoveStorageLocationFlyoutOpen(false);
  }, [flyoutStore, selectedMaterialItems, selectedStorageLocation, currentIndex, storageLocationStore]);

  const isAllowedToSubmit = useMemo(() => {
    switch (step) {
      case Step.SELECT_MATERIALS:
        return selectedMaterialItems.size >= 1;
      case Step.SELECT_LOCATION:
        return !!selectedStorageLocation;
      default:
        return false;
    }
  }, [selectedMaterialItems, selectedStorageLocation, step]);

  const submitLabel = useMemo(() => {
    switch (step) {
      case Step.SELECT_MATERIALS:
        return t('moveStorageLocationFlyout.selectMaterials.submit');
      case Step.SELECT_LOCATION:
        return selectedStorageLocation?.name
          ? t('moveStorageLocationFlyout.selectMaterialLocation.submit', { locationName: selectedStorageLocation.name })
          : t('moveStorageLocationFlyout.selectMaterialLocation.submitDisabled');
      default:
        return '';
    }
  }, [step, selectedStorageLocation, t]);

  const headline = useMemo(() => {
    switch (step) {
      case Step.SELECT_MATERIALS:
        return t('moveStorageLocationFlyout.selectMaterials.headline');
      case Step.SELECT_LOCATION:
        return t('moveStorageLocationFlyout.selectMaterialLocation.headline');
      default:
        return '';
    }
  }, [step, t]);

  const handleSubmit = useCallback(() => {
    switch (step) {
      case Step.SELECT_MATERIALS:
        setStep(Step.SELECT_LOCATION);
        break;
      case Step.SELECT_LOCATION:
        handleClose();
        break;
      default:
    }
  }, [step, handleClose]);

  const handleSelectLocation = (location: StorageLocationDTO, index: number) => {
    setSelectedStorageLocation(location);
    setCurrentIndex(index);
  };

  return (
    <FlyoutContainer
      closePopUp={handleClose}
      isOpen={flyoutStore.isMoveStorageLocationFlyoutOpen}
      icon={<img src="images/move.svg" width="50" alt="" />}
      cancelLabel={t('moveStorageLocationFlyout.cancel')}
      onSubmit={handleSubmit}
      submitLabel={submitLabel}
      isAllowedToSubmit={isAllowedToSubmit}
      title={headline}
    >
      <div className="div-block-149">
        <div className="div-block-131 padding padding_bottom location-list-container">
          {step === Step.SELECT_MATERIALS ? <SelectMaterialsContent setCheckedItems={setSelectedMaterialItems} /> : null}
          {step === Step.SELECT_LOCATION ? <SelectMaterialLocationContent onSelect={handleSelectLocation} /> : null}
        </div>
      </div>
    </FlyoutContainer>
  );
});

export default MoveStorageLocationFlyout;
