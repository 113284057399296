import React from 'react';
import { observer } from 'mobx-react';

import FlyoutContainer from 'components/FlyoutContainer';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import PersonalizedHistoryItem from 'components/History/PersonalizedHistoryItem';
import { HistoryActionType, HistoryDTO } from 'dto/history';
import { TFunction } from 'i18next';
import ProcedureContentElementHistoryIcons from './ProcedureContentElementHistoryIcons';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
}

const getHistoryDescription = (translate: TFunction, item: HistoryDTO) => {
  if (item.action === HistoryActionType.create) {
    return translate('textCreate');
  }
  if (item.action === HistoryActionType.notify) {
    return translate('textNotify');
  }
  if (item.action === HistoryActionType.update) {
    return translate('textUpdate');
  }

  return '';
};

const ProcedureContentElementHistory = observer(({ isOpen = false, onClose }: Props) => {
  const { t } = useTranslation('procedureContentElementHistoryFlyout');
  const { procedureStore } = useStores();
  return (
    <FlyoutContainer
      icon={<img src="images/icon_history.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={isOpen}
      closePopUp={onClose}
      cancelLabel={t('button.conclude')}
    >
      <div className="flyout_scroll_wrapper">
        <div className="flyout_box">
          <div>{t('label.processingHistory')}</div>
          <div className="menu_list">
            {procedureStore.procedureContentElementHistory?.map(historyItem => (
              <PersonalizedHistoryItem
                icon={<ProcedureContentElementHistoryIcons action={historyItem.action} />}
                item={historyItem}
                description={getHistoryDescription(t, historyItem)}
                key={historyItem.historyId}
                descriptionAtBottom
              />
            ))}
          </div>
        </div>
      </div>
    </FlyoutContainer>
  );
});

export default ProcedureContentElementHistory;
