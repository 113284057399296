import React from 'react';
import { observer } from 'mobx-react';
import SurgeryListItem from 'pages/surgeryBriefing/SurgeryListItem';
import { Draggable } from 'react-beautiful-dnd';
import { getItemStyle } from 'util/dragAndDrop';
import { RoomSurgeriesDTO } from 'dto/surgery';

interface Props {
  roomSurgeries: RoomSurgeriesDTO;
}

const SurgeryListItems = observer(({ roomSurgeries }: Props) => {
  return (
    <div className="div-block-113">
      {roomSurgeries.surgeries.map((surgery, index) => (
        <Draggable index={index} draggableId={surgery.surgeryId} key={surgery.surgeryId}>
          {(draggableProvided, surgerySnapshot) => (
            <div
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
              style={getItemStyle(surgerySnapshot.isDragging, draggableProvided.draggableProps.style)}
            >
              <SurgeryListItem surgery={surgery} index={index} key={surgery.surgeryId} room={roomSurgeries.room} withStats />
            </div>
          )}
        </Draggable>
      ))}
    </div>
  );
});

export default SurgeryListItems;
