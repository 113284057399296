import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetGuideMaterialCategories } from 'api/guideMaterialCategoryHooks';
import { useInvalidateAnyGuide } from 'api/invalidate';
import { createSurgeryGuideMaterial } from 'api/surgeryGuide';
import SideMenuIconButton from 'components/SideMenuIconButton/SideMenuIconButton';
import { observer } from 'mobx-react';
import { filterCategory } from 'modules/guideMaterial';
import { buildNewSurgeryGuideMaterial } from 'modules/guideMaterial/utils';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyMaterial } from 'stores/searchStore';
import { useStores } from 'util/mobx/stores';

export const SurgeryGuideMaterialRightMenu = withRouter(
  observer(({ history }: RouteComponentProps) => {
    const { t } = useTranslation('briefing');
    const { t: searchTranslation } = useTranslation('materialSearch');
    const { surgeryStore, searchStore, surgeryGuideStore, domainStore } = useStores();

    const { data: groupedGuideMaterialCategories } = useGetGuideMaterialCategories({});
    const invalidateGuide = useInvalidateAnyGuide();

    // Map the correct functional area out of the result.
    const guideMaterialCategories = useMemo(() => {
      return filterCategory(domainStore.currentFunctionalArea.id, groupedGuideMaterialCategories);
    }, [domainStore.currentFunctionalArea.id, groupedGuideMaterialCategories]);

    const handleCreateMaterial = useCallback(
      async (material: AnyMaterial) => {
        if (!surgeryGuideStore.surgeryGuide?.surgeryGuideId || !guideMaterialCategories || !guideMaterialCategories[0]) {
          return;
        }
        const newGuideMaterial = buildNewSurgeryGuideMaterial(
          surgeryGuideStore.surgeryGuide.surgeryGuideId,
          material,
          guideMaterialCategories[0].guideMaterialCategoryId
        );
        if (!newGuideMaterial) {
          return;
        }

        await createSurgeryGuideMaterial(newGuideMaterial);
        await invalidateGuide();
      },
      [guideMaterialCategories, invalidateGuide, surgeryGuideStore.surgeryGuide]
    );

    const handleAddMaterial = () => {
      searchStore.openSearchPanel(handleCreateMaterial, searchTranslation('searchItem.addItem'));
    };

    const handleOpenPlaner = () => surgeryStore.setIsPlannerDrawerOpen(true);
    const handleGoToBriefing = () => {
      if (surgeryGuideStore.briefing) {
        history.push(`/surgery/briefing?surgeryId=${surgeryGuideStore.briefing.surgery.surgeryId}`);
      }
    };

    const goToOriginalGuide = () => {
      history.push(`/guide/materials?guideId=${surgeryGuideStore.surgeryGuide?.guide.guideId}`);
    };

    return (
      <>
        <SideMenuIconButton
          handleClick={handleAddMaterial}
          label={t('briefingMaterials.rightMenu.createMaterial')}
          iconPath="icon_f-add_16.svg"
        />
        <SideMenuIconButton
          handleClick={handleGoToBriefing}
          label={t('briefingMaterials.rightMenu.showBriefing')}
          iconPath="icon_person_16_blue.svg"
        />
        <SideMenuIconButton
          handleClick={goToOriginalGuide}
          label={t('briefingMaterials.rightMenu.goToGuide')}
          iconPath="icon_prototype_16.svg"
        />
        <SideMenuIconButton
          handleClick={handleOpenPlaner}
          label={t('briefingMaterials.rightMenu.openPlaner', { functionalAreaShortName: domainStore.currentFunctionalArea.shortName })}
          iconPath="icon_calendar-date-2_16_blue.svg"
        />
      </>
    );
  })
);
