import { DepartmentReportDTO } from '../types';

export const getMaxValue = (departments: DepartmentReportDTO[]) => {
  let max = 0;
  departments.forEach(department => {
    if (department.value > max) {
      max = department.value;
    }
  });
  return max;
};

export const colors = ['#800000', '#005e7e', '#00806e', '#537d00', '#a1d523', '#1dd57e'];
