import React, { useState, useEffect } from 'react';
import { FullSetGroupDTO } from 'dto/setGroup';

import { GuideMaterialLikeDTO } from 'dto/guide';
import Picture from 'components/Picture';
import HoverWrapper from 'components/HoverWrapper';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import MaterialValueTags from 'components/MaterialValueTags';
import GetCameraImageButton from 'components/GetCameraImageButton';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import Tags from 'components/Tags/Tags';
import ListItemDropdownContainer from '../ListItemDropdownContainer';
import GroupMaterialListItem from '../GroupMaterialListItem';

import './SetGroupListItem.css';

interface Props extends OptionalLazyLoadProps {
  setGroup: FullSetGroupDTO;
  guideData?: GuideMaterialLikeDTO;
  hideTags?: boolean;
  isClone?: boolean;
  hideAmountTag?: boolean;
}

const SetGroupListItem = observer(
  ({ setGroup, guideData, hideTags = false, isClone = false, hideAmountTag = false, lazyLoadScrollContainer }: Props) => {
    const [groupMaterials, setGroupMaterials] = useState(setGroup.groupMaterials);
    const { materialSetsStore, guideStore } = useStores();

    useEffect(() => {
      setGroupMaterials(setGroup.groupMaterials);
    }, [setGroup.groupMaterials]);

    const renderList = () => (
      <div className="content_holder no_padding padding_left instruments-list">
        {groupMaterials.map(groupMaterial => {
          return (
            <GroupMaterialListItem
              lazyLoadScrollContainer={lazyLoadScrollContainer}
              hideTags={hideTags}
              groupMaterial={groupMaterial}
              key={`${groupMaterial.groupMaterialId}${groupMaterial.material?.pictureThumbnail}`}
              guideData={guideData}
              isClone={isClone}
            />
          );
        })}
      </div>
    );

    const onUpdateFile = async (file: string) => {
      await materialSetsStore.updateSetGroup({ setGroupId: setGroup.setGroupId, pictureFileId: file });
      if (guideStore.selectedGuide) {
        guideStore.loadGuideMaterials(guideStore.selectedGuide.guideId);
      }
    };

    return (
      <ListItemDropdownContainer classPrefix="set-group" ListElement={renderList} key={setGroup.setGroupId} triggerExpandOnClick={isClone}>
        <HoverWrapper className="list_item_material">
          {() => (
            <>
              <div className="image_wrapper_50">
                {setGroup.picture ? (
                  <Picture
                    withBorder
                    src={setGroup}
                    size="pictureThumbnail"
                    width={50}
                    alt=""
                    lazyLoadScrollContainer={lazyLoadScrollContainer}
                    className="image_circle_50"
                  />
                ) : (
                  <>
                    <div className="image_border image-border" />
                    <GetCameraImageButton
                      buttonStyle="icon"
                      onUpdateFile={onUpdateFile}
                      description=""
                      picturesOnly
                      isAddable
                      withLoadingBar
                    />
                  </>
                )}
              </div>
              <div className="material_info w-inline-block package-info">
                <div className="material_text material-text">
                  <div className="link-block">{setGroup.name}</div>
                  {!hideTags ? <Tags tags={setGroup.tags} /> : <></>}
                </div>
                <MaterialValueTags setGroupItemCount={setGroup.groupMaterials.length} fullInfo={!hideAmountTag} />
              </div>
            </>
          )}
        </HoverWrapper>
      </ListItemDropdownContainer>
    );
  }
);

export default SetGroupListItem;
