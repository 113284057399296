import { AxiosError } from 'axios';
import { ClassTransformer } from 'class-transformer';
import { GroupedMaterialGuidesDTO } from 'dto/material';
import {
  CreateCheckMaterialSetDTO,
  CreateMaterialSetDTO,
  DepartmentMaterialSetsDTO,
  DepartmentMaterialSetsV2DTO,
  FullSetCheckDTO,
  GetMaterialSetsDTO,
  MaterialSetDTO,
  SetCheckDTO,
  SimpleMaterialSetCheckItemDTO,
  UpdateMaterialSetDTO
} from 'dto/materialSet';
import { del, get, onError, patch, post } from './common';
import { validate, validateArray, validateBool } from './validation';

export const getMaterialSets = async (params: GetMaterialSetsDTO): Promise<DepartmentMaterialSetsDTO[]> => {
  const sets = await get('/material-sets', params).then(response => response.data);
  return validateArray(DepartmentMaterialSetsDTO, sets);
};

export const getMaterialSetsV2 = async (params: GetMaterialSetsDTO): Promise<DepartmentMaterialSetsV2DTO[]> => {
  const sets = await get('/v2/material-sets', params).then(response => response.data);
  return validateArray(DepartmentMaterialSetsV2DTO, sets);
};

export const getMaterialSet = async (materialSetId: string): Promise<MaterialSetDTO> => {
  const set = await get('/material-set', { materialSetId }).then(response => response.data);
  return validate(MaterialSetDTO, set);
};

/**
 * @param materialSetId
 * @returns the setCheck data or null, if the set was never checked
 */
export const getMaterialSetLastCheck = async (materialSetId: string): Promise<SetCheckDTO | null> => {
  const check: unknown | null = await get('/material-set/last-check', { materialSetId }, undefined, true)
    .then(response => response.data)
    .catch((err: AxiosError) => {
      if (err.response?.status === 404) {
        return null;
      }
      // Throw the error as it would have been thrown without the 'hideError' boolean.
      throw onError(false)(err);
    });
  return check !== null ? validate(SetCheckDTO, check) : null;
};

export const getMaterialSetGuides = async (materialSetId: string): Promise<GroupedMaterialGuidesDTO[]> => {
  const guides = await get('/material-set/guides', { materialSetId }).then(response => response.data);
  return validateArray(GroupedMaterialGuidesDTO, guides);
};

export const createMaterialSet = async (materialSetToCreate: CreateMaterialSetDTO) => {
  const materialSet = await post('/material-set', materialSetToCreate).then(response => response.data);
  return validate(MaterialSetDTO, materialSet);
};

export const updateMaterialSet = async (materialSetUpdateFields: UpdateMaterialSetDTO): Promise<MaterialSetDTO> => {
  const updatedMaterialSet = await patch(`/material-set`, materialSetUpdateFields).then(response => response.data);
  return validate(MaterialSetDTO, updatedMaterialSet);
};

export const deleteMaterialSet = async (materialSetId: string) => {
  return del('/material-set', { materialSetId }).then(response => validateBool(response.data));
};

export const getMaterialSetChecks = async (materialSetId: string): Promise<SetCheckDTO[]> => {
  const materialSetChecks = await get('/material-set/checks', { materialSetId }).then(response => response.data);
  return validateArray(SetCheckDTO, materialSetChecks);
};

/**
 * custom transformer which handles the Map
 * @param res
 */
const setCheckTransformer = (res: string) => {
  const transformer = new ClassTransformer();
  // hard to type correctly...
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transformed = transformer.deserialize(FullSetCheckDTO, res) as any;

  transformed.setGroupChecks = Object.keys(transformed.setGroupChecks).reduce((prev, current) => {
    if (transformed.setGroupChecks[current]) {
      prev.set(current, transformed.setGroupChecks[current]);
    }
    return prev;
  }, new Map<string, SimpleMaterialSetCheckItemDTO>());

  transformed.groupMaterialChecks = Object.keys(transformed.groupMaterialChecks).reduce((prev, current) => {
    if (transformed.groupMaterialChecks[current]) {
      prev.set(current, transformed.groupMaterialChecks[current]);
    }
    return prev;
  }, new Map<string, SimpleMaterialSetCheckItemDTO>());

  return transformed;
};

export const getMaterialSetCheckItems = async (setCheckId: string): Promise<FullSetCheckDTO> => {
  const fullMaterialSetCheck = await get(
    '/material-set/check-items',
    { setCheckId },
    {
      transformResponse: setCheckTransformer
    }
  ).then(response => response.data);

  // Validating the transformed response with the maps doesn't work anymore...
  return fullMaterialSetCheck as FullSetCheckDTO;
  // return validate(FullSetCheckDTO, fullMaterialSetCheck);
};

export const postMaterialSetCheckItems = async (materialsToCheck: CreateCheckMaterialSetDTO): Promise<FullSetCheckDTO> => {
  const checkedMaterials = await post('/material-set/checks', materialsToCheck, {
    transformResponse: setCheckTransformer
  }).then((response: { data: FullSetCheckDTO }) => response.data);

  // Validating the transformed response with the maps doesn't work anymore...
  return checkedMaterials as FullSetCheckDTO;
  // return validate(FullSetCheckDTO, checkedMaterials);
};
