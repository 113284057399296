import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import NavSectionButton from 'components/NavSection/NavSectionButton';
import { useStores } from 'util/mobx/stores';

const LocationAdministrationNavigation = observer(() => {
  const { t } = useTranslation('storageLocations');
  const { storageLocationStore } = useStores();
  if (!storageLocationStore.selectedStorageLocation) {
    return null;
  }
  return (
    <>
      <NavSectionButton
        onClick={() => storageLocationStore.setIsDetailDrawerOpen(true)}
        className="btn_section auto_width _100 w-inline-block"
        icon="icon_search-content_16_grey"
        label={t('button.showDetailsMobile', { locationName: storageLocationStore.selectedStorageLocation.name })}
      />
    </>
  );
});

export default LocationAdministrationNavigation;
