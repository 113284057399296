import React, { useState } from 'react';
import Lottie, { LottieProps } from 'react-lottie';
import animationData from 'components/ListItems/EditableGroupMaterialListItem/CheckListItemLottie/animationData.json';
import animationReverseData from 'pages/surgeryMaterials/SurgeryMaterialLikeListItem/SurgeryMaterialCheckItem/checkbox_reverse.json';

interface Props extends React.HTMLProps<HTMLDivElement> {
  status: boolean;
  onStatusChanged: (status: boolean) => void;
  notEditable?: boolean;
  procedureId: string;
}

const SurgeryProcedureCheckItem = ({ status, onStatusChanged, notEditable = false, ...rest }: Props) => {
  const [isAnimationPaused, setIsAnimationPaused] = useState(true);
  const defaultOptions: LottieProps['options'] = {
    autoplay: false,
    loop: false,
    animationData: status ? animationReverseData : animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    if (notEditable) {
      return;
    }
    setIsAnimationPaused(false);
    setTimeout(() => {
      onStatusChanged(!status);
    }, 1000);
  };

  return (
    <div onClick={onClick} className={`btn_checkbox in_prozplan ${notEditable ? 'not-editable' : ''}`} {...rest}>
      <Lottie
        segments={[50, 50]}
        ariaRole="button"
        options={defaultOptions}
        direction={1}
        speed={1.5}
        isPaused={isAnimationPaused}
        eventListeners={[
          {
            eventName: 'complete',
            callback: () => setIsAnimationPaused(true)
          }
        ]}
        isClickToPauseDisabled={notEditable}
      >
        <img src="images/check-white.svg" alt="" />
      </Lottie>
    </div>
  );
};

export default SurgeryProcedureCheckItem;
