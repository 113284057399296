import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import SettingsItem from 'components/SettingsItem';
import { reorderGuideMaterialCategories } from 'api/guideMaterialCategory';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { SettingsItemTypes } from 'stores/settingsStore';
import { GroupedGuideMaterialCategoryDTO, GuideMaterialCategoryDTO } from 'dto/guideMaterialCategory';
import SettingsBlock from 'components/SettingsBlock';
import { getItemStyle } from 'util/dragAndDrop';

interface Props {
  selectedFunctionalAreaId: string;
  materialCategories: GroupedGuideMaterialCategoryDTO[];
}

const MaterialCategoriesColumn = observer(({ selectedFunctionalAreaId, materialCategories }: Props) => {
  const [selectedMaterialCategories, setSelectedMaterialCategories] = useState<GuideMaterialCategoryDTO[]>([]);
  const { t } = useTranslation('settings');
  const { settingsStore } = useStores();

  useEffect(() => {
    const selectedMaterialCategoriesGroup = materialCategories.find(mc => mc.functionalArea.functionalAreaId === selectedFunctionalAreaId);
    if (selectedMaterialCategoriesGroup?.guideMaterialCategories) {
      setSelectedMaterialCategories(selectedMaterialCategoriesGroup?.guideMaterialCategories);
    }
  }, [materialCategories, selectedFunctionalAreaId]);

  const onActivate = (itemId: string) => {
    settingsStore.updateMaterialCategory({
      guideMaterialCategoryId: itemId,
      disabled: false
    });
  };

  const onDeactivate = (itemId: string) => {
    settingsStore.updateMaterialCategory({
      guideMaterialCategoryId: itemId,
      disabled: true
    });
  };

  const onCreateFlyoutOpen = () => {
    settingsStore.setItemFormFlyoutType(SettingsItemTypes.materialCategory);
    settingsStore.setItemFormFlyoutOpen(true);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !selectedMaterialCategories) return;

    const fileToBeMoved = selectedMaterialCategories.find(mc => mc.guideMaterialCategoryId === result.draggableId);
    const oldMaterialCategories = selectedMaterialCategories.filter(mc => mc.guideMaterialCategoryId !== result.draggableId);

    if (fileToBeMoved && oldMaterialCategories) {
      oldMaterialCategories.splice(result.destination.index, 0, fileToBeMoved);
      setSelectedMaterialCategories([...oldMaterialCategories]);
      reorderGuideMaterialCategories({ guideMaterialCategoryIds: oldMaterialCategories.map(mc => mc.guideMaterialCategoryId) });
    }
  };

  return (
    <SettingsBlock title={t('administration.materialCategoryTitle')} onCreateFlyoutOpen={onCreateFlyoutOpen}>
      {selectedMaterialCategories && selectedMaterialCategories.length > 0 ? (
        <div className="area_block">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="materialCategoriesColumn">
              {droppableProvided => (
                <div ref={droppableProvided.innerRef}>
                  {selectedMaterialCategories.map((materialCategory, index) => (
                    <Draggable
                      draggableId={materialCategory.guideMaterialCategoryId}
                      index={index}
                      key={materialCategory.guideMaterialCategoryId}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <SettingsItem
                            itemId={materialCategory.guideMaterialCategoryId}
                            onActivate={onActivate}
                            onDeActivate={onDeactivate}
                            name={materialCategory.name}
                            disabled={materialCategory.disabled}
                            type={SettingsItemTypes.materialCategory}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : null}
    </SettingsBlock>
  );
});

export default MaterialCategoriesColumn;
