import React from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import SurgeryRoomDateInfo from '../SurgeryRoomDateInfo';
import SurgeryListItem from '../SurgeryListItem';

const SurgeryList = observer(() => {
  const { surgeryStore } = useStores();

  return (
    <div id="grid-guides-colum_left" className="colum_left in_operation">
      {surgeryStore.selectedSurgeryBriefing && (
        <>
          <SurgeryRoomDateInfo />
          <div className="op_holder">
            <div className="div-block-114">
              <div className="line_operation" />
              <div className="div-block-113">
                {Array.isArray(surgeryStore.surgeriesInSelectedRoom) &&
                  surgeryStore.surgeriesInSelectedRoom.map((surgery, index) => {
                    if (surgeryStore.selectedRoom) {
                      return (
                        <SurgeryListItem
                          noLabels
                          noMenu
                          surgery={surgery}
                          key={surgery.surgeryId}
                          index={index}
                          room={surgeryStore.selectedRoom}
                          selected={surgeryStore.selectedSurgeryBriefing?.surgery.surgeryId === surgery.surgeryId}
                        />
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export default SurgeryList;
