/* eslint-disable no-await-in-loop */
/* eslint-disable no-underscore-dangle */

import React, { createRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import FlyoutContainer from 'components/FlyoutContainer';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import Editor from '@toast-ui/react-image-editor';
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import './ImageEditor.css';
import { useSecureURL } from 'util/hooks';

const waitUntilStateIsUnlocked = (editorInstance: any) =>
  new Promise(resolve => {
    const interval = setInterval(() => {
      if (!editorInstance._invoker._isLocked) {
        clearInterval(interval);
        resolve();
      }
    }, 100);
  });

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  image: ContentElementMediaDTO;
  updateImageLabeling: (fileId: string, pictureLabelling: string, newFile: Blob) => void;
}

interface ObjectProperties {
  id: number;
  left: number;
  top: number;
  fill: number;
  angle: number;
  fontSize: number;
  text: string;
}

const editorObjectProps = ['left', 'top', 'fill', 'angle', 'fontSize', 'text'];

const ImageEditor = observer(({ isOpen = false, onClose, image, updateImageLabeling }: Props) => {
  const { t } = useTranslation('imageFlyout');
  const locale = {
    Delete: t('delete'),
    DeleteAll: t('deleteAll'),
    Color: t('color'),
    Undo: t('undo'),
    Redo: t('redo'),
    'Text size': t('textSize'),
    Text: t('text')
  };
  const [itemsIds, setItemsIds] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const editorRef = createRef<any>();
  const onObjectActivated = (item: ObjectProperties) => {
    if (!itemsIds.includes(item.id)) {
      setItemsIds(ids => [...ids, item.id]);
    }
  };

  const imageURL = useSecureURL(image.fullUrl);

  useEffect(() => {
    async function addLabels(labels: ObjectProperties[], editorInstance: any) {
      await waitUntilStateIsUnlocked(editorInstance);
      const ids = [];
      for (let i = 0; i < labels.length; i++) {
        const element: ObjectProperties = labels[i];
        const labelProps = {
          styles: {
            fill: element.fill,
            fontSize: element.fontSize,
            angle: element.angle
          },
          position: {
            x: element.left,
            y: element.top
          }
        };
        const item = await editorInstance.addText(element.text, labelProps);
        ids.push(item.id);
      }
      setItemsIds([...ids]);
    }
    if (image.pictureLabelling && isOpen && editorRef.current) {
      const labels: ObjectProperties[] = JSON.parse(image.pictureLabelling);
      const editorInstance = editorRef.current.getInstance();
      addLabels(labels, editorInstance);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const onSubmit = async () => {
    setLoading(true);
    const editorInstance = editorRef.current.getInstance();
    const fileUri = editorInstance.toDataURL();
    const blob = await fetch(fileUri).then(r => r.blob());
    const labelingData: any[] = [];
    for (let i = 0; i < itemsIds.length; i++) {
      const id = itemsIds[i];
      const props = editorInstance.getObjectProperties(id, editorObjectProps);
      if (props) {
        labelingData.push(props);
      }
    }
    if (labelingData.length > 0) {
      await updateImageLabeling(image.fileId, JSON.stringify(labelingData), blob);
    } else {
      await updateImageLabeling(image.fileId, '', blob);
    }
    setLoading(false);
    onClose();
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_edit.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={isOpen}
      className="fullscreen-flyout"
      closePopUp={onSubmit}
      cancelLabel={t('cancel')}
      title={t('imageEditorTitle')}
      closeAsSubmit
      isAllowedToSubmit
      isLoading={loading}
    >
      <div className="image-editor-container">
        <Editor
          ref={editorRef}
          onObjectActivated={onObjectActivated}
          includeUI={{
            locale,
            loadImage: {
              path: imageURL,
              name: 'SampleImage'
            },
            menu: ['text'],
            initMenu: 'text',
            menuBarPosition: 'bottom',
            uiSize: {
              height: '100%'
            }
          }}
          selectionStyle={{
            cornerSize: 10,
            rotatingPointOffset: 70
          }}
        />
      </div>
    </FlyoutContainer>
  );
});

export default ImageEditor;
