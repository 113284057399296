import React from 'react';
import SideMenuIconButton from 'components/SideMenuIconButton';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';

const SurgeryProceduresRightMenu = withRouter(
  observer(({ history }: RouteComponentProps) => {
    const { t } = useTranslation('briefing');
    const { surgeryStore, procedureStore, surgeryGuideStore, domainStore } = useStores();
    const handleAddProcedure = () => {
      procedureStore.setIsProcedureFormFlyoutOpen(true);
    };
    const handleOpenDrawer = () => surgeryGuideStore.setIsProcedureDrawerOpen(true);
    const handleOpenPlaner = () => surgeryStore.setIsPlannerDrawerOpen(true);
    const handleGoToBriefing = () => {
      if (surgeryGuideStore.briefing) {
        history.push(`/surgery/briefing?surgeryId=${surgeryGuideStore.briefing.surgery.surgeryId}`);
      }
    };

    const goToOriginalGuide = () => {
      history.push(`/guide/procedure?guideId=${surgeryGuideStore.surgeryGuide?.guide.guideId}`);
    };
    const { shortName: functionalAreaShortName } = domainStore.currentFunctionalArea;
    return (
      <>
        <SideMenuIconButton
          disabled={surgeryGuideStore.checkableGuideProcedures.length === 0}
          handleClick={handleOpenDrawer}
          label={t('briefingProcedures.rightMenu.openCheckList')}
          iconPath="icon_checkbox_check_16.svg"
        />
        <SideMenuIconButton
          handleClick={handleAddProcedure}
          label={t('briefingProcedures.rightMenu.createProcedure')}
          iconPath="icon_f-add_16.svg"
        />
        <SideMenuIconButton
          handleClick={handleGoToBriefing}
          label={t('briefingProcedures.rightMenu.showBriefing')}
          iconPath="icon_person_16_blue.svg"
        />
        <SideMenuIconButton
          handleClick={goToOriginalGuide}
          label={t('briefingProcedures.rightMenu.goToGuide')}
          iconPath="icon_prototype_16.svg"
        />
        <SideMenuIconButton
          handleClick={handleOpenPlaner}
          label={t('briefingProcedures.rightMenu.openPlaner', { functionalAreaShortName })}
          iconPath="icon_calendar-date-2_16_blue.svg"
        />
      </>
    );
  })
);

export default SurgeryProceduresRightMenu;
