/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { ValidateNested, IsString, IsNotEmptyObject, IsOptional, IsUUID, IsBoolean, IsNumber } from 'class-validator';
import { Type } from 'class-transformer';
import { FunctionalAreaDTO } from './functionalArea';
import 'reflect-metadata';
import { SimpleDepartmentMaintainerDTO } from './departmentMaintainer';

export class SimpleDepartmentDTO {
  @IsUUID()
  departmentId!: string;

  @IsString()
  name!: string;

  @IsString()
  functionalAreaShortName!: string;

  @IsString()
  locationShortName!: string;
}

export class DepartmentDTO {
  @IsUUID()
  departmentId!: string;

  @IsString()
  name!: string;

  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => FunctionalAreaDTO)
  functionalArea!: FunctionalAreaDTO;

  @ValidateNested()
  @Type(() => SimpleDepartmentMaintainerDTO)
  departmentMaintainers!: SimpleDepartmentMaintainerDTO[];

  @IsBoolean()
  disabled!: boolean;
}

export class CreateDepartmentDTO {
  @IsString()
  name!: string;

  @IsUUID()
  functionalAreaId!: string;
}

export class UpdateDepartmentDTO {
  @IsUUID()
  departmentId!: string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsBoolean()
  @IsOptional()
  disabled?: boolean;
}

export class GroupedDepartmentsDTO {
  @IsString()
  locationShortName!: string;

  @IsString()
  locationId!: string;

  @IsString()
  functionalAreaShortName!: string;

  @IsString()
  functionalAreaId!: string;

  @ValidateNested({ each: true })
  @Type(() => DepartmentDTO)
  departments!: DepartmentDTO[];
}

export class DepartmentUsageReportDTO {
  @IsUUID()
  departmentId!: string;

  @IsString()
  name!: string;

  @IsNumber()
  handbooksCountInDepartment!: number;
}
