import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import SettingsItem from 'components/SettingsItem';
import { SettingsItemTypes } from 'stores/settingsStore';
import { LocationDTO } from 'dto/location';
import { FunctionalAreaDTO } from 'dto/functionalArea';
import SettingsBlock from 'components/SettingsBlock';

interface Props {
  selectedLocationId: string;
  locations: LocationDTO[];
}

const FunctionalAreaColumn = observer(({ selectedLocationId, locations }: Props) => {
  const { t } = useTranslation('settings');
  const { settingsStore } = useStores();
  const selectedLocation = locations.find(l => l.locationId === selectedLocationId);

  const onActivate = (itemId: string) => {
    settingsStore.updateFunctionalArea({
      functionalAreaId: itemId,
      disabled: false
    });
  };

  const onDeactivate = (itemId: string) => {
    settingsStore.updateFunctionalArea({
      functionalAreaId: itemId,
      disabled: true
    });
  };

  const onCreateFlyoutOpen = () => {
    settingsStore.setItemFormFlyoutType(SettingsItemTypes.functionalArea);
    settingsStore.setItemFormFlyoutOpen(true);
  };

  const onSelect = (functionalArea: FunctionalAreaDTO) => {
    settingsStore.setSelectedFunctionalArea(functionalArea);
  };

  return (
    <SettingsBlock title={t('administration.functionalAreasTitle')} onCreateFlyoutOpen={onCreateFlyoutOpen}>
      {selectedLocation && selectedLocation?.functionalAreas && selectedLocation?.functionalAreas?.length > 0 ? (
        <div className="area_block">
          {selectedLocation.functionalAreas.map(functionalArea => (
            <SettingsItem
              itemId={functionalArea.functionalAreaId}
              onActivate={onActivate}
              onDeActivate={onDeactivate}
              key={functionalArea.functionalAreaId}
              name={functionalArea.name}
              shortName={functionalArea.shortName}
              disabled={functionalArea.disabled}
              type={SettingsItemTypes.functionalArea}
              onClick={() => onSelect(functionalArea)}
              selected={functionalArea.functionalAreaId === settingsStore.selectedFunctionalArea?.functionalAreaId}
            />
          ))}
        </div>
      ) : null}
    </SettingsBlock>
  );
});

export default FunctionalAreaColumn;
