/* eslint-disable max-classes-per-file */
/* eslint-disable import/no-cycle */
import { IsInt, Min, IsOptional, IsNumber } from 'class-validator';

export class PagingDTO {
  @IsInt()
  @Min(0)
  @IsOptional()
  page?: number;

  @IsInt()
  @Min(0)
  @IsOptional()
  pageSize?: number;
}

export class PagingCountDTO {
  @IsNumber()
  total!: number;
}
