import React from 'react';

import { DepartmentGuidesDTO } from 'dto/guide';
import GuideOwnerList from 'pages/guides/grid/content/ownerList/GuideOwnerList';
import GuideListGroup from 'pages/guides/grid/content/guideList/GuideListGroup';
import { UserDTO } from 'dto/user';

interface Props {
  title: string;
  guideOwnerList: UserDTO[];
  guidesClone: DepartmentGuidesDTO[];
  className: string;
}

export default (props: Props) => (
  <div className={props.className}>
    <div className="flyout_box">
      <div>{props.title}</div>
      <div className="menu_list">
        <div className="w-layout-grid grid_colum_content">
          <div className="colum_left in_flyout">
            <GuideOwnerList guideOwnerList={props.guideOwnerList} isCloneGuideFlyout />
          </div>
          <div className="colum_right in_flyout">
            {props.guidesClone.map(guideGroup => {
              const locationName = guideGroup.department.locationShortName;
              const departmentName = guideGroup.department.name;
              const functionalAreaName = guideGroup.department.functionalAreaShortName;
              return (
                <GuideListGroup
                  guideList={guideGroup.guides}
                  groupName={`${locationName} | ${functionalAreaName} | ${departmentName}`}
                  key={guideGroup.department.departmentId}
                  className="txt_section_flyout"
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
);
