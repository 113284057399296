import React from 'react';
import Picture from 'components/Picture';
import { BasicMaterialSetDTO } from 'dto/materialSet';
import GetCameraImageButton from 'components/GetCameraImageButton';

interface Props {
  materialSet: BasicMaterialSetDTO;
  isShow: boolean;
  onUpdateFile: (fileId: string) => Promise<void>;
}

const FixedTopMaterialBox = ({ materialSet, isShow, onUpdateFile }: Props) => {
  const fixedTopBoxStyle = isShow ? 'show_on_scroll fixed_top_box active' : 'show_on_scroll fixed_top_box';
  return (
    <div className={fixedTopBoxStyle}>
      <div className="div-block-22">
        <div className="image_wrapper_50">
          <div className="image_border" />
          {materialSet.picture ? (
            <Picture src={materialSet.pictureThumbnail} width={50} alt="" className="image_circle_50" />
          ) : (
            <GetCameraImageButton buttonStyle="icon" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
          )}
        </div>
        <div>{materialSet.name}</div>
      </div>
    </div>
  );
};

export default FixedTopMaterialBox;
