import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import MaterialKnowledge from 'components/MaterialKnowledge';
import { SimplePackageWithPicturesDTO } from 'dto/package';
import ImageCircle from 'components/ImageCircle';
import { getPackageSiblings } from 'api/package';
import PackageContentRightMenu from './PackageContentRightMenu';

interface Props {
  packageId: string;
  translationKey: string;
}

const PackageContent = observer(({ packageId, translationKey }: Props) => {
  const { appNavigationStore, domainStore } = useStores();
  const { t } = useTranslation('packages');
  const [siblingPackages, setSiblingPackages] = useState<SimplePackageWithPicturesDTO[]>([]);

  // set the component for the right menu
  useEffect(() => {
    if (!domainStore.isMobile) {
      appNavigationStore.setRightMenuBuilder(() => <PackageContentRightMenu translationKey={translationKey} />);
      return;
    }
    appNavigationStore.withoutMenu();
  }, [appNavigationStore, translationKey, domainStore.isMobile]);

  useEffect(() => {
    getPackageSiblings(packageId).then(setSiblingPackages);
  }, [packageId]);

  appNavigationStore.useSubPageIdSetter('knowledge');

  return (
    <div className="single_colum_content">
      <div className="start_content_box">
        <h1>{t('content.alsoApplies')}</h1>
        <div className="start_box_wrapper">
          {siblingPackages.map(packageItem => (
            <div key={packageItem.packageId} className="list_item_link">
              <ImageCircle noHover pictures={packageItem.packagePicture ? [packageItem.packagePicture] : []} />
              <div className="link_item_info">
                <div className="link-block">
                  <div>{packageItem.name}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <MaterialKnowledge materialLikeId={{ packageId }} />
    </div>
  );
});

export default PackageContent;
