import React from 'react';
import ItemMenuButton from 'components/ItemMenuButton';
import { useTranslation } from 'react-i18next';
import { ProcedureDTO } from 'dto/procedure';
import { useAbility } from '@casl/react';
import { AbilityContext, actions, subjectArea } from 'casl/setupCaslAbility';
import { subject } from '@casl/ability';
import { useStores } from 'util/mobx/stores';

interface Props {
  isCheckable: boolean;
  procedure: ProcedureDTO;
  isMaterial: boolean;
  handleSwitchChecklistGuideProcedure: (event: React.MouseEvent) => void;
  editProcedure: (event: React.MouseEvent) => void;
  unlinkProcedure: (event: React.MouseEvent) => void;
  deleteProcedure: (event: React.MouseEvent) => void;
  deleteAllLinkedProcedures: (event: React.MouseEvent) => void;
  deleteAndUnlinkProcedure: (event: React.MouseEvent) => void;
  handleOpenMaterialDetail: () => void;
}

const ProcedureMenu = ({
  isCheckable,
  handleSwitchChecklistGuideProcedure,
  editProcedure,
  unlinkProcedure,
  deleteProcedure,
  deleteAllLinkedProcedures,
  deleteAndUnlinkProcedure,
  procedure,
  isMaterial,
  handleOpenMaterialDetail
}: Props) => {
  const { t } = useTranslation('guideProcedure');
  const ability = useAbility(AbilityContext);
  const { guideStore } = useStores();
  const handleDelete = (event: React.MouseEvent) => {
    if (procedure.linkCount > 0) {
      deleteAllLinkedProcedures(event);
    } else {
      deleteProcedure(event);
    }
  };

  if (ability.can(actions.remove, subject(subjectArea.guideProcedure, { departmentId: guideStore.selectedGuide?.departmentId })))
    return (
      <>
        {isMaterial && (
          <>
            <div className="menu_info_box bottom_margin">
              <div>{t('detailMenu.materialKnowledge')}</div>
            </div>
            <div className="line_menu-edit" />
          </>
        )}
        {!isMaterial && procedure.linkCount !== 0 && (
          <>
            <div className="menu_info_box">
              <div>{t('detailMenu.compoundProcedure')}</div>
            </div>
            <div className="txt_info_text">{t('detailMenu.compoundProcedureDesc')}</div>
            <div className="line_menu-edit" />
          </>
        )}
        {isCheckable ? (
          <ItemMenuButton
            label={t('detailMenu.checkListFunc')}
            strongLabel={t('detailMenu.checked')}
            icon="icon_c-check_16.svg"
            isInPopover
            handleClick={handleSwitchChecklistGuideProcedure}
          />
        ) : (
          <ItemMenuButton
            label={t('detailMenu.checkListFunc')}
            strongLabel={t('detailMenu.unchecked')}
            icon="icon_c-check_16_blue.svg"
            isInPopover
            handleClick={handleSwitchChecklistGuideProcedure}
          />
        )}
        {!isMaterial ? (
          <ItemMenuButton
            label={t('detailMenu.editProcedure')}
            icon="icon_i-edit_16.svg"
            isInPopover
            handleClick={event => editProcedure(event)}
          />
        ) : (
          <ItemMenuButton
            label={t('detailMenu.materialDetails')}
            icon="icon_search-content_16.svg"
            isInPopover
            handleClick={handleOpenMaterialDetail}
          />
        )}

        {procedure.linkCount > 0 && !isMaterial && (
          <>
            <ItemMenuButton
              label={t('detailMenu.disconnect')}
              icon="icon_unlink_16.svg"
              isInPopover
              handleClick={event => unlinkProcedure(event)}
            />
            <ItemMenuButton
              label={t('detailMenu.disconnectAndDelete')}
              warning
              icon="icon_a-tag-remove_16.svg"
              isInPopover
              handleClick={deleteAndUnlinkProcedure}
            />
          </>
        )}
        {!isMaterial ? (
          <ItemMenuButton
            label={procedure.linkCount === 0 ? t('detailMenu.deleteProcedure') : t('detailMenu.deleteProcedureWithConnections')}
            icon="icon_bin_16.svg"
            warning
            isInPopover
            handleClick={handleDelete}
          />
        ) : (
          ability.can(actions.removeMany, subjectArea.guideProcedure) && (
            <ItemMenuButton
              label={t('detailMenu.deleteAllKnowledgeFromGuides')}
              icon="icon_bin_16.svg"
              warning
              isInPopover
              handleClick={handleDelete}
            />
          )
        )}
        {isMaterial && (
          <ItemMenuButton
            label={t('detailMenu.deleteKnowledgeFromGuide')}
            icon="icon_card-remove_16.svg"
            warning
            isInPopover
            handleClick={deleteProcedure}
          />
        )}
      </>
    );

  return (
    <ItemMenuButton
      label={t('detailMenu.materialDetails')}
      icon="icon_search-content_16.svg"
      isInPopover
      handleClick={handleOpenMaterialDetail}
    />
  );
};

export default ProcedureMenu;
