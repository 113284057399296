import React from 'react';
import * as Yup from 'yup';
import Button from 'components/Form/Button';
import { useTranslation } from 'react-i18next';
import Form, { InputField } from 'components/Form';
import { CheckTempPasswordDTO } from 'dto/user';
import { ErrorMessage } from 'formik';
import './LoginResetPassword.css';
import { updateAndLoginWithTmpPassword } from 'api/auth';

interface Props {
  show: boolean;
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
  setShowResetPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAuthMessage: React.Dispatch<React.SetStateAction<boolean>>;
  setWithPasswordResetMessage: React.Dispatch<React.SetStateAction<boolean>>;
  tmpPasswordWithUsername: CheckTempPasswordDTO | undefined;
}

interface Passwords {
  newPassword: string;
  passwordConfirm: string;
}

const LoginResetPassword = ({
  show = false,
  setShowLogin,
  setShowResetPassword,
  setShowAuthMessage,
  setWithPasswordResetMessage,
  tmpPasswordWithUsername
}: Props) => {
  const { t: translationLogin } = useTranslation('login');
  const { t: translationSignup } = useTranslation('signup');

  const switchToLogin = () => {
    setShowResetPassword(false);
    setShowLogin(true);
  };

  const initialValues = {
    newPassword: '',
    passwordConfirm: ''
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .required(translationSignup('validation.fieldRequired'))
      .min(8, translationSignup('validation.passwordLength'))
      .max(255, translationSignup('validation.textLength')),
    newPasswordConfirm: Yup.string()
      .oneOf([Yup.ref('newPassword')], translationSignup('validation.passwordsMatch'))
      .required(translationSignup('validation.fieldRequired'))
  });

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  const handleSubmitMyForm = () => {
    if (submitMyForm) submitMyForm();
  };

  const onSubmit = (data: Passwords) => {
    const { newPassword } = data;
    if (tmpPasswordWithUsername) {
      updateAndLoginWithTmpPassword({ ...tmpPasswordWithUsername, password: newPassword }).then(() => {
        setShowResetPassword(false);
        setWithPasswordResetMessage(true);
        setShowAuthMessage(true);
      });
    }
  };

  return (
    <>
      <div className={`div-block-170 ${!show ? 'hide' : ''}`}>
        <div className="div-block-178">
          <div className="div-block-171">
            <img src="images/logo_klinik-puls.svg" alt="" />
            <div className="txt_logo-copy">
              <strong>{translationLogin('logoTextFirstPart')}</strong>
              {translationLogin('logoTextSecondPart')}
            </div>
          </div>
          <div className="div-block-181">
            <div className="pw_new_pw displayed-by-default">
              <div className="headline-login">{translationLogin('resetPassword.headline')}</div>

              {show ? ( // force re-render of form when show changes to true
                <Form initialValues={initialValues} onSubmit={onSubmit} bindSubmitForm={bindSubmitForm} validationSchema={validationSchema}>
                  {props => {
                    return (
                      <>
                        <div className="div-block-179">
                          <div className="login_input_box">
                            <label htmlFor="newPassword" className="field-label-2">
                              {translationSignup('password')}
                            </label>
                            <InputField type="password" max-length="256" name="newPassword" />
                            <ErrorMessage component={errProps => <div {...errProps} />} className="field_error" name="newPassword" />
                          </div>
                          <div className="login_input_box">
                            <label htmlFor="newPasswordConfirm" className="field-label-2">
                              {translationSignup('passwordConfirm')}
                            </label>
                            <InputField type="password" max-length="256" name="newPasswordConfirm" />
                            <ErrorMessage component={errProps => <div {...errProps} />} className="field_error" name="newPasswordConfirm" />
                          </div>
                        </div>

                        <div className="button_bar-copy">
                          <Button className="btn_big_cancel" type="button" onClick={switchToLogin}>
                            {translationLogin('cancel')}
                          </Button>
                          <Button
                            className="btn_big_done-copy"
                            disabled={!(props.isValid && props.dirty)}
                            type="button"
                            onClick={handleSubmitMyForm}
                          >
                            {translationLogin('submit')}
                          </Button>
                        </div>
                      </>
                    );
                  }}
                </Form>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginResetPassword;
