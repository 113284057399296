import React from 'react';
import Button from 'components/Form/Button';
import './SideMenuIconButton.css';

interface Props extends React.HTMLProps<HTMLButtonElement> {
  handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: string;
  iconPath: string;
  red?: boolean;
}

export default ({ handleClick, label, iconPath, red, ...rest }: Props) => (
  <Button
    className={`btn_function btn-function button_function ${red ? 'red-color btn-red-color' : ''}`}
    {...rest}
    type="button"
    onClick={handleClick}
  >
    <img src={`images/${iconPath}`} alt="" className="img_function image_function img-function" />
    <div>{label}</div>
  </Button>
);
