import React from 'react';
import { useTranslation } from 'react-i18next';
import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  handleOpenDetails: () => void;
}

const SurgeryListItemMenu = observer(({ isOpen, handleClose, handleDelete, handleOpenDetails }: Props) => {
  const { domainStore } = useStores();
  const { t } = useTranslation('surgery');

  const { shortName: functionalAreaShortName } = domainStore.currentFunctionalArea;

  return (
    <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
      <ItemMenuButton
        label={t('surgeryListItem.change', { functionalAreaShortName })}
        icon="icon_data-table_16_blue.svg"
        isInPopover
        handleClick={handleOpenDetails}
      />
      <ItemMenuButton
        warning
        label={t('surgeryListItem.delete', { functionalAreaShortName })}
        icon="icon_bin_16.svg"
        isInPopover
        handleClick={handleDelete}
      />
    </ItemMenu>
  );
});

export default SurgeryListItemMenu;
