import { observable, runInAction, action } from 'mobx';
import {
  getProcedures,
  deleteGuideProcedure,
  updateGuideProcedure,
  createGuideProcedure,
  getProcedureGuides,
  bulkUpdateCopyRelinkGuideProcedure,
  bulkLinkMaterialKnowledgeToProcedure,
  deleteGuidesProcedures
} from 'api/procedure';
import {
  GroupedProcedureDTO,
  UpdateProcedureDTO,
  CreateGuideProcedureDTO,
  ProcedureDTO,
  GroupedGuideProcedureDTO,
  BulkCopyRelinkProcedureDTO,
  BulkLinkMaterialKnowledgeToProcedureDTO
} from 'dto/procedure';
import { getContentElementHistory } from 'api/history';
import { HistoryDTO } from 'dto/history';
import { OptionalProcedureContentElementDTO } from 'dto/contentElement';
import LoadingStore from './loadingStore';

export default class ProcedureStore {
  @observable
  private loadingStore: LoadingStore;

  @observable
  procedures?: GroupedProcedureDTO[];

  @observable
  isCreateProcedureFlyoutOpen = false;

  @observable
  chapterIdForNewProcedures?: string;

  @observable
  positionForNewProcedures?: number;

  @observable
  isUpdateProcedureFlyoutOpen = false;

  @observable
  procedureGuides?: GroupedGuideProcedureDTO[];

  @observable
  proceduresClone: GroupedProcedureDTO[] = [];

  @observable
  procedureContentElementHistory?: HistoryDTO[];

  @observable
  isProcedureFormFlyoutOpen = false;

  @observable
  selectedProcedure: ProcedureDTO | null = null;

  @observable
  selectedGuideChapterId = '';

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;
  }

  async loadProcedures(guideId: string) {
    const procedures = await this.loadingStore.withLoadingBar(() => getProcedures({ guideId }));
    runInAction(() => {
      this.setProcedures(procedures);
    });
  }

  @action
  setProcedures(procedures: GroupedProcedureDTO[]) {
    this.procedures = procedures;
  }

  @action
  resetProcedures() {
    this.procedures = undefined;
  }

  async deleteGuideProcedure(guideProcedureId: string, guideId: string) {
    await this.loadingStore.withLoadingBar(() => deleteGuideProcedure(guideProcedureId));
    return this.loadProcedures(guideId);
  }

  async deleteAllLinkedGuideProcedures(guideProcedureId: string, guideId: string) {
    await this.loadingStore.withLoadingBar(() => deleteGuidesProcedures(guideProcedureId));
    return this.loadProcedures(guideId);
  }

  async updateGuideProcedure(guideProcedureUpdateFields: UpdateProcedureDTO, guideId: string, fetchProcedures: boolean) {
    await this.loadingStore.withFlyoutLoadingBar(() => updateGuideProcedure(guideProcedureUpdateFields));
    if (fetchProcedures) {
      return this.loadProcedures(guideId);
    }
    return null;
  }

  async unlinkSelectedProcedure(guideProcedureId: string, guideId: string, fetchProcedures: boolean) {
    await this.loadingStore.withFlyoutLoadingBar(() =>
      updateGuideProcedure({
        guideProcedureId,
        unlink: true
      })
    );
    if (fetchProcedures) {
      return this.loadProcedures(guideId);
    }
    return null;
  }

  async updateSelectedProcedure(updateFields: OptionalProcedureContentElementDTO, guideId: string, createPost: boolean) {
    if (this.selectedProcedure === null) {
      return;
    }

    await this.updateGuideProcedure(
      {
        guideProcedureId: this.selectedProcedure.guideProcedureId,
        content: updateFields,
        createPost
      },
      guideId,
      true
    );
  }

  async getProcedureGuides(guideProcedureId: string) {
    const procedureGuides = await this.loadingStore.withLoadingBar(() => getProcedureGuides(guideProcedureId));
    runInAction(() => {
      this.procedureGuides = procedureGuides;
    });
  }

  async loadContentElementHistory(contentElementId: string) {
    const procedureContentElementHistory = await this.loadingStore.withLoadingBar(() => getContentElementHistory(contentElementId));
    runInAction(() => {
      this.procedureContentElementHistory = procedureContentElementHistory;
    });
  }

  @action
  setIsCreateProcedureFlyoutOpen = (isCreateProcedureFlyoutOpen: boolean) => {
    this.isCreateProcedureFlyoutOpen = isCreateProcedureFlyoutOpen;
  };

  async createGuideProcedure(newGuideProcedure: CreateGuideProcedureDTO) {
    const procedure = await this.loadingStore.withFlyoutLoadingBar(() => createGuideProcedure(newGuideProcedure));
    runInAction(() => {
      this.chapterIdForNewProcedures = undefined;
      this.positionForNewProcedures = undefined;
    });
    this.loadProcedures(newGuideProcedure.guideId);
    return procedure;
  }

  @action
  setNewProcedureData = (chapterId: string, position?: number) => {
    this.chapterIdForNewProcedures = chapterId;
    this.positionForNewProcedures = position;
  };

  @action
  setIsUpdateProcedureFlyoutOpen = (isUpdateProcedureFlyoutOpen: boolean) => {
    this.isUpdateProcedureFlyoutOpen = isUpdateProcedureFlyoutOpen;
  };

  async bulkUpdateCopyRelinkGuideProcedure(procedureToCopyRelink: BulkCopyRelinkProcedureDTO) {
    const procedures = await this.loadingStore.withFlyoutLoadingBar(() => bulkUpdateCopyRelinkGuideProcedure(procedureToCopyRelink));
    this.loadProcedures(procedureToCopyRelink.guideId);
    return procedures;
  }

  async loadProceduresClone(guideId: string) {
    const procedures = await this.loadingStore.withLoadingBar(() => getProcedures({ guideId }));
    runInAction(() => {
      this.proceduresClone = procedures;
    });
  }

  @action
  resetProceduresClone = () => {
    this.proceduresClone = [];
  };

  async bulkLinkMaterialKnowledgeToProcedure(materialKnowledgeToLink: BulkLinkMaterialKnowledgeToProcedureDTO) {
    const insertedProcedures = await this.loadingStore.withLoadingBar(() => bulkLinkMaterialKnowledgeToProcedure(materialKnowledgeToLink));
    runInAction(() => {
      this.chapterIdForNewProcedures = undefined;
      this.positionForNewProcedures = undefined;
    });
    await this.loadProcedures(materialKnowledgeToLink.guideId);

    return insertedProcedures;
  }

  @action
  setIsProcedureFormFlyoutOpen = (isProcedureFormFlyoutOpen: boolean) => {
    this.isProcedureFormFlyoutOpen = isProcedureFormFlyoutOpen;
  };

  @action
  setSelectedProcedure(selectedProcedure: ProcedureDTO | null) {
    this.selectedProcedure = selectedProcedure;
  }

  @action
  setSelectedGuideChapterId(selectedGuideChapterId: string) {
    this.selectedGuideChapterId = selectedGuideChapterId;
  }
}
