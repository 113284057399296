import { MaterialStorageLocationsDTO, StorageLocationDTO } from 'dto/storageLocation';

export const getOriginLocations = (materialStorageLocations: MaterialStorageLocationsDTO[]) =>
  materialStorageLocations.map(sl => sl.originStorageLocation);

export const getEndStorageLocations = (
  materialStorageLocations: MaterialStorageLocationsDTO[],
  selectedMaterialStorageLocation?: StorageLocationDTO
) => {
  const findByOrigin = materialStorageLocations.find(
    sl => sl.originStorageLocation.storageLocationId === selectedMaterialStorageLocation?.storageLocationId
  )?.endStorageLocations;
  if (findByOrigin) {
    return findByOrigin;
  }
  const findByEnd = materialStorageLocations.find(sl =>
    sl.endStorageLocations.find(es => es.storageLocationId === selectedMaterialStorageLocation?.storageLocationId)
  )?.endStorageLocations;
  return findByEnd;
};
