import { getFile } from 'api/common';

export function downloadFromUrl(filename: string, url?: string) {
  if (!url) {
    return;
  }

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

export function downloadFile(data?: { url: string; fileName: string }) {
  if (data) {
    downloadFromUrl(data.fileName, data.url);
  }
}

export function filterFileName(fileName: string): string {
  return fileName.replace(/[\\/:"*?<>|@#,;\n\0]/g, '_').replace(/^\./, '_');
}

/**
 * fetchSecure downloads the given url using the authentication
 * and then creates a object url which can be used for e.g. img tags as src.
 */
export function fetchSecure(url: string, hideError = false): Promise<string> {
  return getFile(url, {}, {}, hideError)
    .then(file => {
      return window.URL.createObjectURL(file);
    })
    .catch(err => {
      console.error(err);
      return err;
    });
}
