import React, { useEffect } from 'react';
import { observer, Provider, useLocalStore } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import MaterialLikeStorageLocations from 'components/MaterialLikeStorageLocations';
import StorageLocationStore from 'stores/storageLocationStore';
import { StorageLocationDTO } from 'dto/storageLocation';
import { LoadingType } from 'stores/loadingStore';

interface Props {
  onSelect: (newLocation: StorageLocationDTO, index: number) => void;
}

/**
 * SelectMaterialLocationContent uses a local StorageLocationStore instead of the global one.
 * It gets injected here so that all components in this flyout automatically use the local one.
 *
 * -> This is done as workaround to avoid re-introducing a new logik for the flyout.
 * Alternative would be a heavy refactoring of the whole storageLocationStore.
 */
const SelectMaterialLocationContent = observer(({ onSelect }: Props) => {
  const { storageLocationStore, domainStore, loadingStore, guideStore } = useStores();

  // creating a new local store by just creating a new instance of the StorageLocationStore
  const localStorageLocationStore = useLocalStore(
    () => new StorageLocationStore(domainStore, loadingStore, guideStore, LoadingType.FLYOUT)
  );

  // load the data for the localStorageLocationStore whenever the selectMaterialStorageLocationFlyoutMaterial of the global store changes -> e.g. the flyout opens.
  useEffect(() => {
    if (storageLocationStore.selectMaterialStorageLocationFlyoutMaterial) {
      localStorageLocationStore.setMaterialLikeId(storageLocationStore.selectMaterialStorageLocationFlyoutMaterial);
      localStorageLocationStore.loadMaterialLikeLocations(undefined);
    }
  }, [localStorageLocationStore, storageLocationStore.selectMaterialStorageLocationFlyoutMaterial]);

  useEffect(() => {
    if (localStorageLocationStore.selectedStorageLocation) {
      onSelect(localStorageLocationStore.selectedStorageLocation, localStorageLocationStore.currentIndex);
    }
  }, [localStorageLocationStore.selectedStorageLocation, localStorageLocationStore.currentIndex, onSelect]);

  return (
    // Inject the localStorageLocationStore here as the storageLocationStore.
    // This basically "shadows" the global storageLocationStore by the local one for all child components.
    // -> Whenever they try to access the global store, the local store is used instead.
    <Provider storageLocationStore={localStorageLocationStore}>
      <MaterialLikeStorageLocations inFlyout />
    </Provider>
  );
});

export default SelectMaterialLocationContent;
