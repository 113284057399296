/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import 'reflect-metadata';
import { IsUUID, IsInt, ValidateNested, IsOptional } from 'class-validator';
import { Type } from 'class-transformer';
import { MaterialLikeIdOrPackageIdDTO, MaterialDTO } from './material';
import { SimpleMaterialSetDTO } from './materialSet';
import { SimplePackageDTO } from './package';

export class CreateStorageItemDTO extends MaterialLikeIdOrPackageIdDTO {
  @IsUUID()
  storageLocationId!: string;

  @IsInt()
  amount!: number;
}

export class UpdateStorageItemDTO {
  @IsUUID()
  storageItemId!: string;

  @IsOptional()
  @IsInt()
  amount?: number;

  @IsUUID()
  @IsOptional()
  storageLocationId?: string;
}

export class SimpleStorageItemDTO {
  @IsUUID()
  storageItemId!: string;

  @IsUUID()
  storageLocationId!: string;

  @IsInt()
  amount!: number;
}

export class StorageItemDTO extends SimpleStorageItemDTO {
  @ValidateNested()
  @IsOptional()
  @Type(() => MaterialDTO)
  material?: MaterialDTO;

  @ValidateNested()
  @IsOptional()
  @Type(() => SimplePackageDTO)
  package?: SimplePackageDTO;

  @ValidateNested()
  @IsOptional()
  @Type(() => SimpleMaterialSetDTO)
  materialSet?: SimpleMaterialSetDTO;
}

export class UpdateSortStorageItemDTO {
  @IsUUID(undefined, { each: true })
  storageItemIds!: string[];
}
