import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { saveFile } from 'api/file';
import { CreateMaterialSetDTO, SetType } from 'dto/materialSet';
import FlyoutContainer from 'components/FlyoutContainer';
import UploadFile from 'components/UploadFiles';
import { useStores } from 'util/mobx/stores';
import { ContentType } from 'dto/file';
import Form, { InputField } from 'components/Form';

interface Props {
  groupType: SetType;
}

const CreateMaterialSetFlyout = observer(({ groupType }: Props) => {
  const { t } = useTranslation('createGroupFlyout');
  const { domainStore, flyoutStore, materialSetsStore } = useStores();
  const [isAllowedToSubmit, setIsAllowedToSubmit] = useState(false);
  const [imageId, setImageId] = useState<string | undefined>();

  useEffect(() => {
    setIsAllowedToSubmit(false);
    setImageId(undefined);
  }, [flyoutStore.isCreateMaterialSetFlyoutOpen]);

  const initialValues: CreateMaterialSetDTO = {
    name: '',
    type: groupType
  };

  const typeValue = SetType[groupType].toLowerCase();

  const closePopUp = () => flyoutStore.setIsCreateMaterialSetFlyoutOpen(false);

  const onImplantCreate = (values: CreateMaterialSetDTO) => {
    materialSetsStore.createMaterialSet(
      {
        ...values,
        type: +groupType,
        pictureFileId: imageId
      },
      domainStore.currentFunctionalArea.id
    );
    flyoutStore.setIsCreateMaterialSetFlyoutOpen(false);
  };

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  const bindAllowedToSubmit = (isDirty: boolean, values: CreateMaterialSetDTO) => {
    if (isDirty && values.name) {
      setIsAllowedToSubmit(true);
    } else {
      setIsAllowedToSubmit(false);
    }
  };

  const handleSubmitMyForm = () => {
    if (submitMyForm) submitMyForm();
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_new_single_material.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={flyoutStore.isCreateMaterialSetFlyoutOpen}
      closePopUp={closePopUp}
      cancelLabel={t(`${typeValue}.button.cancel`)}
      onSubmit={handleSubmitMyForm}
      submitLabel={t(`${typeValue}.button.submit`)}
      isAllowedToSubmit={isAllowedToSubmit}
      title={t(`${typeValue}.headline`)}
    >
      <Form
        initialValues={initialValues}
        bindSubmitForm={bindSubmitForm}
        bindAllowedToSubmit={bindAllowedToSubmit}
        onSubmit={onImplantCreate}
      >
        {() => {
          return (
            <div className="flyout_scroll_wrapper">
              <div className="flyout_box">
                <div>{t(`${typeValue}.label.name`)}</div>
                <InputField name="name" placeholder={t(`${typeValue}.label.namePlaceholder`)} />
              </div>
              <div className="flyout_box">
                <div>{t(`${typeValue}.label.media`)}</div>
                <UploadFile
                  setImageId={setImageId}
                  cameraImageButtonDescription={t(`${typeValue}.imageButton`)}
                  cameraImageButtonDescriptionAlready={t(`${typeValue}.imageButtonImageAlreadyThere`)}
                  saveFile={saveFile}
                  picturesOnly
                  accept={[ContentType.Picture]}
                />
              </div>
            </div>
          );
        }}
      </Form>
    </FlyoutContainer>
  );
});
export default CreateMaterialSetFlyout;
