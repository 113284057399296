import React, { useState } from 'react';
import Login from 'pages/login/Login';
import LoginRightImage from 'pages/login/loginRightImage';
import SignupForm from 'pages/SignupForm';
import LoginForgotPassword from 'pages/loginForgotPassword';
import LoginForgotUsername from 'pages/loginForgotUsername';
import AuthMessage from 'pages/AuthMessage';
import LoginResetPassword from 'pages/loginResetPassword';
import { CheckTempPasswordDTO } from 'dto/user';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';

const Authentication = observer(() => {
  const { appNavigationStore, authStore } = useStores();
  const [showLogin, setShowLogin] = useState(true);
  const [showSignup, setShowSignup] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showForgotUsername, setShowForgotUsername] = useState(false);
  const [showAuthMessage, setShowAuthMessage] = useState(false);
  const [withPasswordResetMessage, setWithPasswordResetMessage] = useState(false);
  const [withSignupMessage, setWithSignupMessage] = useState(false);

  const [tmpPasswordWithUsername, setTmpPasswordWithUsername] = useState<CheckTempPasswordDTO>();

  if (!appNavigationStore.apiConfigAvailable || !authStore.serverConfig) {
    return <></>;
  }

  return (
    <>
      <div className="ios-bar" />
      <div className="w-layout-grid grid-7">
        <Login
          setShowLogin={setShowLogin}
          setShowSignup={setShowSignup}
          setShowForgotPassword={setShowForgotPassword}
          setShowForgotUsername={setShowForgotUsername}
          show={showLogin}
        />
        {!authStore.serverConfig?.ldapEnabled && (
          <SignupForm
            setShowLogin={setShowLogin}
            setShowSignup={setShowSignup}
            setShowAuthMessage={setShowAuthMessage}
            setWithSignupMessage={setWithSignupMessage}
            show={showSignup}
          />
        )}
        {showAuthMessage && (
          <AuthMessage
            setShowAuthMessage={setShowAuthMessage}
            setShowLogin={setShowLogin}
            withSignupMessage={withSignupMessage}
            withPasswordResetMessage={withPasswordResetMessage}
          />
        )}
        {!authStore.serverConfig?.ldapEnabled && (
          <>
            <LoginForgotPassword
              setShowLogin={setShowLogin}
              setShowForgotPassword={setShowForgotPassword}
              setShowResetPassword={setShowResetPassword}
              show={showForgotPassword}
              setTmpPasswordWithUsername={setTmpPasswordWithUsername}
            />
            <LoginResetPassword
              setShowLogin={setShowLogin}
              setShowResetPassword={setShowResetPassword}
              setShowAuthMessage={setShowAuthMessage}
              setWithPasswordResetMessage={setWithPasswordResetMessage}
              show={showResetPassword}
              tmpPasswordWithUsername={tmpPasswordWithUsername}
            />
            <LoginForgotUsername setShowLogin={setShowLogin} setShowForgotUsername={setShowForgotUsername} show={showForgotUsername} />
          </>
        )}
        <LoginRightImage />
      </div>
    </>
  );
});

export default Authentication;
