import { CreateSynonymDTO } from 'dto/synonym';
import { createMaterialSynonym, deleteMaterialSynonym } from 'api/synonym';

import { observable, action, runInAction } from 'mobx';
import { MaterialKnowledgeDTO, UpdateSortMaterialKnowledgeElementsDTO, GroupedMaterialKnowledgeDTO } from 'dto/materialKnowledge';
import {
  getKnowledge,
  createKnowledgeElement,
  updateKnowledgeElement,
  deleteKnowledgeElement,
  reorderKnowledgeElements
} from 'api/knowledge';
import { MaterialLikeIdOrPackageIdDTO } from 'dto/material';
import { OptionalContentElementDTO, CreateContentElementDTO } from 'dto/contentElement';
import { DropResult } from 'react-beautiful-dnd';
import LoadingStore from './loadingStore';

export default class MaterialStore {
  @observable
  private loadingStore: LoadingStore;

  /**
   * selectedMaterialLikeId saves the last used id to refresh easily.
   */
  @observable
  selectedMaterialLikeId?: MaterialLikeIdOrPackageIdDTO;

  @observable
  materialKnowledge: GroupedMaterialKnowledgeDTO[] = [];

  @observable
  selectedKnowledge?: MaterialKnowledgeDTO;

  @observable
  chapterIdForNewMaterialKnowledge?: string;

  @observable
  positionForNewMaterialKnowledge?: number;

  @observable
  isCreateMaterialSynonymOpen = false;

  @observable
  isMaterialKnowledgeFormFlyoutOpen = false;

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;
  }

  /**
   * loadMaterialKnowledge loads the new data by the given ID. If the id is omitted it refreshes by using the last known one.
   */
  @action
  async loadMaterialKnowledge(materialLikeId?: MaterialLikeIdOrPackageIdDTO) {
    const idToLoad = materialLikeId || this.selectedMaterialLikeId;

    if (idToLoad === undefined) {
      console.error('either a new id has to be provided or a selected one has to exist');
      return;
    }

    // reset values temporarily if it is a complete new loading by a new id, to prevent showing old data
    if (materialLikeId !== undefined && materialLikeId !== this.selectedMaterialLikeId) {
      this.materialKnowledge = [];
      this.selectedMaterialLikeId = undefined;
    }

    const knowledge = await this.loadingStore.withLoadingBar(() => getKnowledge(idToLoad));

    // set the new data
    runInAction(() => {
      this.materialKnowledge = knowledge;
      this.selectedMaterialLikeId = idToLoad;
    });
  }

  @action
  async reorder(result: DropResult) {
    if (!result.destination) return;
    const destination = this.materialKnowledge.find(group => group.materialChapterId === result.destination?.droppableId);
    if (destination?.isGenerated) {
      return;
    }
    let knowledgeToBeMoved: MaterialKnowledgeDTO;
    let newKnowledgeElements = this.materialKnowledge.map(knowledgeElementGroup => {
      if (result.source?.droppableId === knowledgeElementGroup.materialChapterId) {
        const draggableItem = knowledgeElementGroup.knowledgeElements.find(
          ke => ke.materialKnowledgeElementId === result.draggableId || ke.materialSynonymId === result.draggableId
        );
        knowledgeToBeMoved = draggableItem || knowledgeToBeMoved;
        return {
          ...knowledgeElementGroup,
          knowledgeElements: knowledgeElementGroup.knowledgeElements.filter(
            ke => ke.materialKnowledgeElementId !== result.draggableId && ke.materialSynonymId !== result.draggableId
          )
        };
      }
      return knowledgeElementGroup;
    });
    newKnowledgeElements = newKnowledgeElements.map(knowledgeElementGroup => {
      if (result.destination?.droppableId === knowledgeElementGroup.materialChapterId) {
        knowledgeElementGroup.knowledgeElements.splice(result.destination.index, 0, knowledgeToBeMoved);
        return {
          ...knowledgeElementGroup,
          knowledgeElements: knowledgeElementGroup.knowledgeElements
        };
      }
      return knowledgeElementGroup;
    });
    const positions: UpdateSortMaterialKnowledgeElementsDTO[] = [];

    newKnowledgeElements.forEach(knowledgeElementGroup => {
      const materialKnowledgeElementIdsAll = knowledgeElementGroup.knowledgeElements.map(
        ke => ke.materialKnowledgeElementId || ke.materialSynonymId
      );
      const materialKnowledgeElementIds: string[] = [];
      materialKnowledgeElementIdsAll.forEach(id => {
        if (id) {
          materialKnowledgeElementIds.push(id);
        }
      });
      if (!knowledgeElementGroup.isGenerated) {
        positions.push({
          chapterId: knowledgeElementGroup.materialChapterId,
          materialKnowledgeElementIds
        });
      }
    });

    this.materialKnowledge = newKnowledgeElements;
    await this.loadingStore.withLoadingBar(() => reorderKnowledgeElements(positions));
    // refresh to be sure
    this.loadMaterialKnowledge();
  }

  @action
  async setSelectedKnowledge(knowledge: MaterialKnowledgeDTO) {
    this.selectedKnowledge = knowledge;
  }

  @action
  async deselectKnowledge() {
    this.selectedKnowledge = undefined;
  }

  async deleteMaterialSynonym(materialSynonymId: string) {
    await this.loadingStore.withLoadingBar(() => deleteMaterialSynonym({ synonymId: materialSynonymId }));
    this.loadMaterialKnowledge();
  }

  async createMaterialKnowledge(materialKnowledge: CreateContentElementDTO) {
    const knowledgeElement = await this.loadingStore.withFlyoutLoadingBar(async () => {
      if (this.chapterIdForNewMaterialKnowledge === undefined) {
        return undefined;
      }
      return createKnowledgeElement({
        materialChapterId: this.chapterIdForNewMaterialKnowledge,
        position: this.positionForNewMaterialKnowledge,
        content: materialKnowledge,
        ...this.selectedMaterialLikeId
      });
    });

    this.loadMaterialKnowledge();
    return knowledgeElement;
  }

  async updateMaterialKnowledge(materialKnowledge: OptionalContentElementDTO) {
    const knowledgeElement = await this.loadingStore.withFlyoutLoadingBar(async () => {
      if (this.selectedKnowledge?.materialKnowledgeElementId === undefined) {
        return undefined;
      }
      return updateKnowledgeElement({
        materialKnowledgeElementId: this.selectedKnowledge?.materialKnowledgeElementId,
        content: materialKnowledge
      });
    });
    this.loadMaterialKnowledge();
    return knowledgeElement;
  }

  async createMaterialSynonym(materialSynonym: CreateSynonymDTO) {
    const synonym = await this.loadingStore.withFlyoutLoadingBar(() => createMaterialSynonym(materialSynonym));
    this.loadMaterialKnowledge();
    return synonym;
  }

  @action
  setIsCreateMaterialSynonymOpen(isCreateMaterialSynonymOpen: boolean) {
    this.isCreateMaterialSynonymOpen = isCreateMaterialSynonymOpen;
  }

  @action
  setIsMaterialKnowledgeFormFlyoutOpen(isMaterialKnowledgeFormFlyoutOpen: boolean) {
    this.isMaterialKnowledgeFormFlyoutOpen = isMaterialKnowledgeFormFlyoutOpen;
  }

  @action
  setDataForNewMaterialKnowledge(selectedMaterialChapterId?: string, position?: number) {
    if (!this.selectedMaterialLikeId) {
      return;
    }

    let filteredSelectedMaterialChapterId = selectedMaterialChapterId;
    if (!selectedMaterialChapterId) {
      const filteredMaterialKnowledge = this.materialKnowledge.filter(knowledgeElement => knowledgeElement.isGenerated === false);
      filteredSelectedMaterialChapterId = filteredMaterialKnowledge[0].materialChapterId;
    }

    if (!filteredSelectedMaterialChapterId) {
      throw new Error('no materialChapterId selected');
    }

    this.chapterIdForNewMaterialKnowledge = filteredSelectedMaterialChapterId;
    this.positionForNewMaterialKnowledge = position || 0;
  }

  async deleteKnowledgeElement(knowledgeId: string) {
    await deleteKnowledgeElement(knowledgeId);
    this.loadMaterialKnowledge();
  }
}
