import { GuideMaterialLikeFlatDTO } from 'dto/guide';
import React, { useContext } from 'react';

export type OnGuideMaterialClick = (
  guideMaterial: GuideMaterialLikeFlatDTO,
  specific?: { packageId?: string; materialId?: string }
) => void;

export interface GuideMaterialListState {
  onReload?: () => Promise<void>;
  guideLikeId: string;
  onGuideMaterialClick?: OnGuideMaterialClick;
  readonly: boolean;
  lazyLoadScrollContainer?: string;

  onDelete?: (guideMaterialId: string) => Promise<void>;
}

export const GuideMaterialListContext = React.createContext<GuideMaterialListState | undefined>(undefined);

export function useGuideMaterialListContext() {
  const state = useContext(GuideMaterialListContext);
  if (state === undefined) {
    throw new Error('use of GuideMaterialListContext without provider');
  }

  return state;
}
