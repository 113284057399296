import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import SettingsItem from 'components/SettingsItem';
import { SettingsItemTypes } from 'stores/settingsStore';
import { LocationDTO } from 'dto/location';
import { deletePosts } from 'api/post';
import VersionNumber from 'components/VersionNumber/VersionNumber';

const SettingsBoard = observer(() => {
  const { settingsStore, appNavigationStore } = useStores();
  const { t } = useTranslation('settings');
  useEffect(() => {
    settingsStore.loadLocations();
    return () => {
      settingsStore.setSelectedLocation(null);
      settingsStore.setSelectedFunctionalArea(null);
    };
  }, [settingsStore]);

  appNavigationStore.useSubPageIdSetter('board');

  const onSelectLocation = (location: LocationDTO) => {
    if (location.locationId === settingsStore.selectedLocation?.locationId) {
      return;
    }
    settingsStore.setSelectedLocation(location);
    settingsStore.setSelectedFunctionalArea(null);
  };
  const onDeletePosts = (functionalAreaId: string) => {
    return deletePosts({ functionalAreaId });
  };
  return (
    <>
      <div className="single_colum_content-copy">
        <div className="div-block-79-copy">
          <h1>{t('board.title')}</h1>
          <div className="div-block-80-copy">
            <div className="div-block-82">
              {settingsStore.locations.map(location => (
                <SettingsItem
                  itemId={location.locationId}
                  key={location.locationId}
                  name={location.name}
                  shortName={location.shortName}
                  disabled={location.disabled}
                  type={SettingsItemTypes.location}
                  onClick={() => onSelectLocation(location)}
                  selected={location.locationId === settingsStore.selectedLocation?.locationId}
                  notEditable
                />
              ))}
            </div>
            <div className="div-block-82">
              {settingsStore.selectedLocation &&
                settingsStore.selectedLocation.functionalAreas &&
                settingsStore.selectedLocation.functionalAreas.map(functionalArea => (
                  <SettingsItem
                    itemId={functionalArea.functionalAreaId}
                    key={functionalArea.functionalAreaId}
                    name={functionalArea.name}
                    shortName={functionalArea.shortName}
                    disabled={functionalArea.disabled}
                    type={SettingsItemTypes.functionalArea}
                    onDeletePosts={() => onDeletePosts(functionalArea.functionalAreaId)}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <VersionNumber type="mobile" />
    </>
  );
});

export default SettingsBoard;
