import React, { useState } from 'react';
import Lottie, { LottieProps } from 'react-lottie';
import animationData from './animationData.json';
import animationDataReverse from './checkbox_reverse.json';

interface Props extends React.HTMLProps<HTMLDivElement> {
  small?: boolean;
  onStatusChanged: (status: boolean) => void;
  status: boolean;
}

const BlackCheckBox = ({ small = false, onStatusChanged, status }: Props) => {
  const [isAnimationPaused, setIsAnimationPaused] = useState(true);
  const defaultOptions: LottieProps['options'] = {
    autoplay: false,
    loop: false,
    animationData: status ? animationDataReverse : animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const onButtonClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setIsAnimationPaused(false);
    setTimeout(() => {
      onStatusChanged(!status);
    }, 1000);
  };

  return (
    <div onClick={onButtonClick} className={`${small ? 'btn_checkbox in_prozplan' : 'btn_checkbox_op-pilot'}`}>
      <Lottie
        segments={[50, 50]}
        ariaRole="button"
        options={defaultOptions}
        direction={1}
        speed={1.5}
        isPaused={isAnimationPaused}
        eventListeners={[
          {
            eventName: 'complete',
            callback: () => setIsAnimationPaused(true)
          }
        ]}
      >
        <img src="images/check-white.svg" alt="" />
      </Lottie>
    </div>
  );
};

export default BlackCheckBox;
