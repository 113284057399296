import React from 'react';
import { GroupMaterialDTO } from 'dto/groupMaterial';
import { InstructionRendererType } from 'modules/material/ListItems/MaterialListItem';
import Tags from 'components/Tags';
import { useMaterialSetListContext } from '../materialSetListContext';

export const InstructionRender: InstructionRendererType<GroupMaterialDTO> = ({ parentEntity }) => {
  const { onTagCreate, onTagDelete, onTagUpdate, readonly } = useMaterialSetListContext();

  return (
    <Tags
      editable={!readonly}
      tags={parentEntity.tags}
      onTagCreate={onTagCreate && onTagCreate({ groupMaterialId: parentEntity.groupMaterialId })}
      onTagUpdate={onTagUpdate}
      onTagDelete={onTagDelete}
    />
  );
};
