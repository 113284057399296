import { FileMetadataDTO } from 'dto/file';
import { post, get, UploadProgressCallback } from './common';
import { validate, validateArray } from './validation';

export const getFile = async (fileId: string) => {
  const fileMetadata = await get('/file-metadata', { fileId }).then(response => response.data);
  return validate(FileMetadataDTO, fileMetadata);
};

export const getFiles = async (fileIds: string[]) => {
  const fileMetadata = await get('/file-metadatas', { fileIds: fileIds.join(',') }).then(response => response.data);
  return validateArray(FileMetadataDTO, fileMetadata);
};

export const saveFile = async (formData: FormData, onUploadProgress?: UploadProgressCallback) => {
  const fileMetadata = await post('/file/material', formData, { onUploadProgress }).then(response => response.data);
  return validate(FileMetadataDTO, fileMetadata);
};

export const savePackageFile = async (formData: FormData, onUploadProgress?: UploadProgressCallback) => {
  const fileMetadata = await post('/file/package', formData, { onUploadProgress }).then(response => response.data);
  return validate(FileMetadataDTO, fileMetadata);
};

export const saveFileContentElement = async (formData: FormData, onUploadProgress?: UploadProgressCallback) => {
  const fileMetadata = await post('/file/contentelement', formData, { onUploadProgress }).then(response => response.data);
  return validate(FileMetadataDTO, fileMetadata);
};

export const saveFileKnowledgeElement = async (formData: FormData, onUploadProgress?: UploadProgressCallback) => {
  const fileMetadata = await post('/file/knowledgeelement', formData, { onUploadProgress }).then(response => response.data);
  return validate(FileMetadataDTO, fileMetadata);
};

export const saveFileUser = async (formData: FormData, onUploadProgress?: UploadProgressCallback) => {
  const fileMetadata = await post('/file/user', formData, { onUploadProgress }).then(response => response.data);
  return validate(FileMetadataDTO, fileMetadata);
};

export const unsafeSaveFileUser = async (formData: FormData, onUploadProgress?: UploadProgressCallback) => {
  const fileMetadata = await post('/unsafe/file/user', formData, { onUploadProgress }).then(response => response.data);
  return validate(FileMetadataDTO, fileMetadata);
};
