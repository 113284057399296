import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import FlyoutContainer from 'components/FlyoutContainer';
import UploadMultipleFiles from 'components/UploadMultipleFiles';
import { saveFileKnowledgeElement } from 'api/file';
import Form, { InputField, TextareaField } from 'components/Form';
import { OptionalContentElementDTO, CreateContentElementDTO } from 'dto/contentElement';
import { useStores } from 'util/mobx/stores';
import { isUUID } from 'class-validator';
import { MixedContentElementMediaDTO } from 'components/UploadMultipleFiles/MixedContentElementMediaDTO';

interface Props {
  className?: string;
}

const MaterialKnowledgeFormFlyout = observer(({ className = '' }: Props) => {
  const { knowledgeStore, animationStore } = useStores();
  const { t: itemFormFlyOutTranslate } = useTranslation('itemFormFlyOut');
  const { t } = useTranslation('material');
  const [isAllowedToSubmit, setIsAllowedToSubmit] = useState(false);
  const [isUploadingInProgress, setIsUploadingInProgress] = useState(false);

  const initialValues: OptionalContentElementDTO = {
    title: knowledgeStore.selectedKnowledge?.content.title || '',
    description: knowledgeStore.selectedKnowledge?.content.description || '',
    contentElementMedias: knowledgeStore.selectedKnowledge
      ? knowledgeStore.selectedKnowledge.content.contentElementMedias.map(c => {
          return {
            fileId: c.fileId,
            title: c.title
          };
        })
      : []
  };

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  const bindAllowedToSubmit = (isDirty: boolean, values: any) => {
    let isUploading = false;
    if (values.contentElementMedias) {
      values.contentElementMedias.forEach((media: MixedContentElementMediaDTO) => {
        if (!isUUID(media.fileId)) {
          isUploading = true;
        }
      });
    }
    setIsUploadingInProgress(isUploading);
    setIsAllowedToSubmit(isDirty && !isUploading);
  };

  const handleSubmitMyForm = () => {
    if (submitMyForm) submitMyForm();
  };

  const onFlyoutClose = () => {
    knowledgeStore.setIsMaterialKnowledgeFormFlyoutOpen(false);
    knowledgeStore.deselectKnowledge();
  };

  const onMaterialContentCreate = async (values: CreateContentElementDTO) => {
    const knowledgeElement = await knowledgeStore.createMaterialKnowledge(values);
    if (knowledgeElement && knowledgeElement.materialKnowledgeElementId) {
      animationStore.setElementIdToShake([knowledgeElement.materialKnowledgeElementId]);
    }
    onFlyoutClose();
  };

  const onMaterialContentUpdate = async (values: OptionalContentElementDTO) => {
    if (knowledgeStore.selectedKnowledge?.materialKnowledgeElementId) {
      animationStore.setElementIdToShake([knowledgeStore.selectedKnowledge.materialKnowledgeElementId]);
      await knowledgeStore.updateMaterialKnowledge(values);
    }
    onFlyoutClose();
  };

  const onSubmit = (data: CreateContentElementDTO) => {
    const newData = data;
    if (data.contentElementMedias) {
      newData.contentElementMedias = data.contentElementMedias?.map(m => {
        // need to manually set the fields as the data contains some fields (in js, not ts) which are not in CreateContentElementMediaDTO.
        // they should not be sendet through the api
        return {
          fileId: m.fileId,
          pictureLabelling: m.pictureLabelling,
          pictureLabellingFileId: m.pictureLabellingFileId,
          title: m.title
        };
      });
    }

    if (knowledgeStore.selectedKnowledge) {
      onMaterialContentUpdate(newData);
    } else {
      onMaterialContentCreate(newData);
    }
  };

  return (
    <FlyoutContainer
      icon={
        knowledgeStore.selectedKnowledge ? (
          <img src="images/icon_edit.jpg" width="50" alt="" className="image_circle_40" />
        ) : (
          <img src="images/icon_new_1.jpg" width="50" alt="" className="image_circle_40" />
        )
      }
      isOpen={knowledgeStore.isMaterialKnowledgeFormFlyoutOpen}
      closePopUp={onFlyoutClose}
      cancelLabel={itemFormFlyOutTranslate('button.cancel')}
      onSubmit={handleSubmitMyForm}
      submitLabel={itemFormFlyOutTranslate('button.submit')}
      isAllowedToSubmit={isAllowedToSubmit}
      isLoading={isUploadingInProgress}
      className={className}
      title={
        knowledgeStore.selectedKnowledge ? t('content.updateMaterialKnowledge.headline') : t('content.createMaterialKnowledge.headline')
      }
    >
      <Form
        initialValues={initialValues}
        bindSubmitForm={bindSubmitForm}
        bindAllowedToSubmit={bindAllowedToSubmit}
        onSubmit={onSubmit}
        className="flyout-container-full_height"
      >
        {form => {
          return (
            <div className="flyout_scroll_wrapper">
              <div className="flyout_box">
                <div>{itemFormFlyOutTranslate('label.title')}</div>
                <InputField name="title" placeholder={itemFormFlyOutTranslate('label.titlePlaceholder')} />
              </div>
              <div className="flyout_box">
                <div>{itemFormFlyOutTranslate('label.description')}</div>
                <TextareaField name="description" placeholder={itemFormFlyOutTranslate('label.descriptionPlaceholder')} />
              </div>
              <div className="flyout_box flyout-box-height-only-form">
                <UploadMultipleFiles
                  saveFile={saveFileKnowledgeElement}
                  meta={form}
                  name="contentElementMedias"
                  files={knowledgeStore.selectedKnowledge?.content.contentElementMedias || []}
                  reorderContentElementMedia={reorderedMedias => {
                    form.setFieldValue('contentElementMedias', reorderedMedias);
                  }}
                />
              </div>
            </div>
          );
        }}
      </Form>
    </FlyoutContainer>
  );
});

export default MaterialKnowledgeFormFlyout;
