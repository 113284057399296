import React, { useCallback, useEffect, useState } from 'react';

interface ChildrenProps {
  hover: boolean;
}

interface Props extends React.HTMLProps<HTMLDivElement> {
  children: (props: ChildrenProps) => JSX.Element | null;
  hoverContainerId?: string;
}

const HoverWrapper = ({ children, hoverContainerId, ...rest }: Props) => {
  const [hover, setHover] = useState(false);
  const onMouseEnter = useCallback(() => setHover(true), []);
  const onMouseLeave = useCallback(() => setHover(false), []);

  useEffect(() => {
    // Preserve the last used instances to be able to cleanup properly
    // in the returned function of the useEffect.
    const lastOnMouseEnter = onMouseEnter;
    const lastOnMouseLeave = onMouseLeave;
    let lastContainer: HTMLElement | null = null;

    if (hoverContainerId) {
      lastContainer = document.getElementById(hoverContainerId);
      if (lastContainer) {
        lastContainer.addEventListener('mouseenter', lastOnMouseEnter);
        lastContainer.addEventListener('mouseleave', lastOnMouseLeave);
      }
    }

    return () => {
      if (lastContainer) {
        lastContainer.removeEventListener('mouseenter', lastOnMouseEnter);
        lastContainer.removeEventListener('mouseleave', lastOnMouseLeave);
      }
    };
  }, [hoverContainerId, onMouseEnter, onMouseLeave]);

  return (
    <div {...rest} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children({ hover })}
    </div>
  );
};

export default HoverWrapper;
