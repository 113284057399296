import React from 'react';
import { SurgeryProcedureDTO } from 'dto/surgeryGuideProcedure';
import { useTranslation } from 'react-i18next';
import PostMediaList from 'components/PostItem/PostMediaList';
import './SurgeryProcedurePreview.css';

interface Props {
  surgeryProcedure: SurgeryProcedureDTO;
  index: number;
  length: number;
  selected: boolean;
  onClick: () => void;
}

const SurgeryProcedurePreview = ({ surgeryProcedure, index, length, selected, onClick }: Props) => {
  const { t } = useTranslation('briefing');
  return (
    <div
      className={`o-pilot_preview surgery-procedure-preview ${selected ? 'selected' : ''} ${surgeryProcedure.checked ? 'active' : ''}`}
      onClick={onClick}
    >
      {surgeryProcedure.checked && (
        <>
          <img src="images/icon_check-e_16_green.svg" alt="" className="image_checked active" />
        </>
      )}
      <div className={`lable_page label-page in_preview ${surgeryProcedure.checked ? 'active' : ''}`}>
        {index} {t('briefingProcedures.drawer.from')} {length}
      </div>
      <div className="headline_pilot_preview">
        <strong>{surgeryProcedure.content.title}</strong>
      </div>
      <div className="txt_content-op_pilot-preview">{surgeryProcedure.content.description}</div>
      <PostMediaList files={surgeryProcedure.content.contentElementMedias} inDrawer viewOnly noPreview />
    </div>
  );
};

export default SurgeryProcedurePreview;
