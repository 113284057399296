import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';

import AdditionalBookListShowButton from './AdditionalBookListShowButton';
import AdditionalGuideListContent from './AdditionalGuideListContent';

const AdditionalBookList = observer(() => {
  const { guideStore } = useStores();
  const { additionalGuides, showAdditionalGuides } = guideStore;

  return (
    <>
      {!!additionalGuides.length && (
        <>
          <AdditionalBookListShowButton />
          {showAdditionalGuides && <AdditionalGuideListContent additionalGuides={additionalGuides} />}
        </>
      )}
    </>
  );
});

export default AdditionalBookList;
