/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsString, IsUUID, ValidateNested, IsOptional, IsInt, IsBoolean, IsEnum } from 'class-validator';
import { Type } from 'class-transformer';
import 'reflect-metadata';
import { ProcedureContentElementDTO, OptionalProcedureContentElementDTO, CreateProcedureContentElementDTO } from './contentElement';
import { FullGuideDTO } from './guide';
import { SimpleDepartmentDTO } from './department';
import { MaterialLikeIdDTO } from './material';
import { PictureDTO } from './file';

export enum ProcedureType {
  Procedure,
  Knowledge
}

export enum IconType {
  MaterialPicture,
  UserPicture,
  None
}

export class ProcedureDTO {
  @IsUUID()
  guideProcedureId!: string;

  @IsUUID()
  guideChapterId!: string;

  @ValidateNested()
  @IsOptional()
  @Type(() => MaterialLikeIdDTO)
  materialLikeId?: MaterialLikeIdDTO;

  @ValidateNested()
  @IsOptional()
  @Type(() => PictureDTO)
  materialPicture?: PictureDTO;

  @ValidateNested()
  @Type(() => ProcedureContentElementDTO)
  content!: ProcedureContentElementDTO;

  @IsInt()
  linkCount!: number;

  @IsEnum(ProcedureType)
  type!: ProcedureType;
}

export class CreateGuideProcedureDTO {
  @IsUUID()
  guideChapterId!: string;

  @IsUUID()
  guideId!: string;

  @IsInt()
  @IsOptional()
  position?: number;

  @IsUUID()
  @IsOptional()
  contentElementId?: string;

  @ValidateNested()
  @Type(() => CreateProcedureContentElementDTO)
  content?: CreateProcedureContentElementDTO;

  @IsBoolean()
  @IsOptional()
  createPost?: boolean;
}

export class UpdateSortProceduresDTO {
  @IsUUID()
  chapterId!: string;

  @IsUUID(undefined, { each: true })
  guideProceduresIds!: string[];
}

export class GroupedProcedureDTO {
  @IsString()
  chapter!: string;

  @IsUUID()
  chapterId!: string;

  @ValidateNested({ each: true })
  @Type(() => ProcedureDTO)
  procedures!: ProcedureDTO[];
}

export class GroupedGuideProcedureDTO {
  @ValidateNested()
  @Type(() => SimpleDepartmentDTO)
  department!: SimpleDepartmentDTO;

  @ValidateNested()
  @Type(() => FullGuideDTO)
  guides!: FullGuideDTO[];
}

export class GuideProcedureIdDTO {
  @IsUUID()
  guideProcedureId!: string;
}

export class UpdateProcedureDTO {
  @IsUUID()
  guideProcedureId!: string;

  @ValidateNested()
  @Type(() => OptionalProcedureContentElementDTO)
  content?: OptionalProcedureContentElementDTO;

  @IsBoolean()
  @IsOptional()
  createPost?: boolean;

  @IsBoolean()
  @IsOptional()
  unlink?: boolean;
}

export class BulkCopyRelinkProcedureDTO {
  @IsUUID()
  guideId!: string;

  @IsInt()
  @IsOptional()
  position?: number;

  @IsOptional()
  @IsUUID()
  guideChapterId?: string;

  @IsUUID(undefined, { each: true })
  @IsOptional()
  copyGuideProcedureIds?: string[];

  @IsUUID(undefined, { each: true })
  @IsOptional()
  linkGuideProcedureIds?: string[];

  @IsBoolean()
  @IsOptional()
  createPost?: boolean;
}

export class BulkLinkMaterialKnowledgeToProcedureDTO {
  @IsUUID()
  guideId!: string;

  @IsInt()
  @IsOptional()
  position?: number;

  @IsUUID()
  guideChapterId!: string;

  @IsUUID(undefined, { each: true })
  linkMaterialKnowledgeIds!: string[];

  @IsBoolean()
  @IsOptional()
  createPost?: boolean;
}
