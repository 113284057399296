import { useStores } from 'util/mobx/stores';
import { useQuery } from '@tanstack/react-query';
import { LoadingType } from 'stores/loadingStore';
import { GetGuideMaterialCategoryDTO } from 'dto/guideMaterialCategory';
import { ApiQueryOptions } from './query';
import { getGuideMaterialCategories } from './guideMaterialCategory';

export const useGetGuideMaterialCategories = (
  getGuideMaterialCategoriesOptions: GetGuideMaterialCategoryDTO,
  options: ApiQueryOptions = {}
) => {
  const { loadingStore } = useStores();
  const { loadingType, ...queryOptions } = options;
  return useQuery({
    queryKey: ['guide-material-categories', getGuideMaterialCategoriesOptions],
    queryFn: async () => {
      return loadingStore.withSpecificLoadingBar(loadingType || LoadingType.DEFAULT, () =>
        getGuideMaterialCategories(getGuideMaterialCategoriesOptions)
      );
    },
    ...queryOptions
  });
};
