import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { ExtendedUserDTO, UserStatus } from 'dto/user';
import CustomInput from 'components/CustomInput';
import UserListItemBody from './userListItemBody';

interface UserGroup {
  groupChar: string;
  users: ExtendedUserDTO[];
}

const AccountManagementUsers = observer(() => {
  const { t } = useTranslation('accountManagement');
  const { userStore } = useStores();

  const [filterTerm, setFilterTerm] = useState('');
  const [filteredGroupedUsers, setFilteredGroupedUsers] = useState<UserGroup[]>([]);
  const [status, setStatus] = useState<UserStatus>(UserStatus.Pending);

  const groupUsers = (users: ExtendedUserDTO[]) => {
    const groupedUsers: UserGroup[] = [];
    users.forEach(user => {
      if (groupedUsers.some(userGroup => userGroup.groupChar === user.lastName[0])) {
        const relevantGroup = groupedUsers.find(userGroup => userGroup.groupChar === user.lastName[0]);
        if (relevantGroup) relevantGroup.users.push(user);
      } else {
        const groupedUser: UserGroup = { groupChar: user.lastName[0], users: [user] };
        groupedUsers.push(groupedUser);
      }
    });
    setFilteredGroupedUsers(groupedUsers);
  };

  useEffect(() => {
    userStore.loadAllUsers();
  }, [userStore]);

  useEffect(() => {
    const filterUsers = (allUsers: ExtendedUserDTO[]) => {
      const filteredUsers = allUsers.filter(user => {
        const bothNames = user.firstName.toLowerCase() + user.lastName.toLowerCase();
        const reversedBothNames = user.lastName.toLowerCase() + user.firstName.toLowerCase();

        return user.status === status && (bothNames.includes(filterTerm) || reversedBothNames.includes(filterTerm));
      });
      groupUsers(filteredUsers);
    };

    const allUsers = userStore.allUsers || [];
    filterUsers(allUsers);
  }, [filterTerm, userStore.allUsers, status]);

  useEffect(() => {
    // reset selected user to avoid a pre selected user
    userStore.setSelectedUser(undefined);
  }, [userStore]);

  return (
    <div className="div-block-127">
      <div className="filter no-padding">
        <div className={`btn_filter in_op-plan ${status === UserStatus.Pending && 'active'}`} onClick={() => setStatus(UserStatus.Pending)}>
          <div>
            <strong>{t('users.status.pending')}</strong>
          </div>
        </div>
        <div className={`btn_filter in_op-plan ${status === UserStatus.Active && 'active'}`} onClick={() => setStatus(UserStatus.Active)}>
          <div>
            <strong>{t('users.status.active')}</strong>
          </div>
        </div>
        <div
          className={`btn_filter in_op-plan ${status === UserStatus.Inactive && 'active'}`}
          onClick={() => setStatus(UserStatus.Inactive)}
        >
          <div>
            <strong>{t('users.status.inactive')}</strong>
          </div>
        </div>
      </div>
      <>
        <CustomInput
          isSearchInput
          containerClassName="input_field_settings non-bold with_search"
          onChange={event => setFilterTerm(event.target.value ? event.target.value.toLowerCase().replace(/ /g, '') : '')}
          placeholder={t('users.searchFieldPlaceholder')}
        />
        {filteredGroupedUsers.map(userGroup => (
          <React.Fragment key={userGroup.groupChar}>
            <div className="sticky_head">
              <div className="div-block-139">
                <div>{userGroup.groupChar}</div>
                <div className="info_box counter">
                  <div>{userGroup.users.length}</div>
                </div>
              </div>
            </div>

            <div className="content_holder_list">
              {userGroup.users.map(user => (
                <div className="list_item_name in_user_mgmt" key={user.userId} onClick={() => userStore.openUserDetailsFlyout(user)}>
                  <UserListItemBody user={user} className="flex" />
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
      </>
    </div>
  );
});

export default AccountManagementUsers;
