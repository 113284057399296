import InfoBox from 'components/InfoBox';
import ListItemAdd from 'components/ListItemAdd';
import { SurgeryRoomDTO } from 'dto/surgeryRoom';
import { observer } from 'mobx-react';
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsItemTypes } from 'stores/settingsStore';
import { useStores } from 'util/mobx/stores';
import HoverWrapper from 'components/HoverWrapper';
import { Draggable, Droppable, DragDropContext, DropResult } from 'react-beautiful-dnd';
import { getItemStyle } from 'util/dragAndDrop';
import SurgeryRoomsListItem from './surgeryRoomsListItem';

interface Props {
  setShowSurgeryRoomFunctionalAreas: React.Dispatch<React.SetStateAction<boolean>>;
}

const SurgeryRoomsList = observer(({ setShowSurgeryRoomFunctionalAreas }: Props) => {
  const { t } = useTranslation('settings');
  const { settingsStore } = useStores();
  const [surgeryRooms, setSurgeryRooms] = useState(settingsStore.surgeryRooms || []);

  useEffect(() => {
    setSurgeryRooms(settingsStore.surgeryRooms);
  }, [settingsStore, settingsStore.selectedSurgeryRoom, settingsStore.surgeryRooms]);

  const onCreateFlyoutOpen = () => {
    settingsStore.setItemFormFlyoutTitles(t('formFlyout.surgeryRoomUpdateTitle'), t('formFlyout.surgeryRoomCreateTitle'));
    settingsStore.setItemFormFlyoutType(SettingsItemTypes.surgeryRoom);
    settingsStore.setItemFormFlyoutOpen(true);
  };

  const checkIfSelected = useCallback(
    (surgeryRoom: SurgeryRoomDTO) => {
      return surgeryRoom.surgeryRoomId === settingsStore.selectedSurgeryRoom?.surgeryRoomId;
    },
    [settingsStore.selectedSurgeryRoom]
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const surgeryRoomToBeMoved = surgeryRooms.find(s => s.surgeryRoomId === result.draggableId);
    const oldSurgeryRooms = surgeryRooms.filter(s => s.surgeryRoomId !== result.draggableId);

    if (surgeryRoomToBeMoved) {
      oldSurgeryRooms.splice(result.destination.index, 0, surgeryRoomToBeMoved);
      settingsStore.reorderSurgeryRooms({ surgeryRoomIds: oldSurgeryRooms.map(s => s.surgeryRoomId) });
      setSurgeryRooms(oldSurgeryRooms);
    }
  };

  return (
    <div className="div-block-140">
      <div className="h3">{t('surgeryRooms.surgeryRoomsHeadline')}</div>

      <ListItemAdd className="post_item_add post-item-add" onClick={onCreateFlyoutOpen}>
        <InfoBox label={t('surgeryRooms.createNew')} />
      </ListItemAdd>

      {surgeryRooms ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="surgeryRoomList">
            {droppableProvider => (
              <div ref={droppableProvider.innerRef}>
                {surgeryRooms.map((room, index) => {
                  return (
                    <Draggable key={room.surgeryRoomId} draggableId={room.surgeryRoomId} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <>
                            <HoverWrapper key={room.surgeryRoomId}>
                              {({ hover }) => (
                                <>
                                  <SurgeryRoomsListItem
                                    surgeryRoom={room}
                                    setShowSurgeryRoomFunctionalAreas={setShowSurgeryRoomFunctionalAreas}
                                    hover={hover}
                                    isSelected={checkIfSelected(room)}
                                  />
                                </>
                              )}
                            </HoverWrapper>
                          </>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {droppableProvider.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <></>
      )}
    </div>
  );
});

export default SurgeryRoomsList;
