/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsString, IsUUID, IsBoolean, IsOptional, ValidateNested } from 'class-validator';
import 'reflect-metadata';
import { Type } from 'class-transformer';
import { FunctionalAreaDTO, FunctionalAreaUsageReportDTO } from './functionalArea';

export class LocationDTO {
  @IsUUID()
  locationId!: string;

  @IsString()
  shortName!: string;

  @IsString()
  name!: string;

  @IsBoolean()
  disabled!: boolean;

  @ValidateNested()
  @IsOptional()
  @Type(() => FunctionalAreaDTO)
  functionalAreas?: FunctionalAreaDTO[];
}

export class CreateLocationDTO {
  @IsString()
  shortName!: string;

  @IsString()
  name!: string;
}

export class UpdateLocationDTO {
  @IsUUID()
  locationId!: string;

  @IsString()
  @IsOptional()
  shortName?: string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsBoolean()
  @IsOptional()
  disabled?: boolean;
}

export class GetLocationDTO {
  /**
   * includeChildren indicates if you also want all functional areas and all departments connected to the locations.
   */
  @IsBoolean()
  @IsOptional()
  includeChildren?: boolean;

  @IsBoolean()
  @IsOptional()
  includeDisabled?: boolean;
}

export class LocationUsageReportDTO {
  @IsUUID()
  locationId!: string;

  @IsString()
  name!: string;

  @IsString()
  shortName!: string;

  @ValidateNested()
  @Type(() => FunctionalAreaUsageReportDTO)
  functionalAreas!: FunctionalAreaUsageReportDTO[];
}
