import { updateMaterial } from 'api/material';
import MaterialValueTags from 'components/MaterialValueTags/MaterialValueTags';
import Tags from 'components/Tags';
import { GroupMaterialDTO } from 'dto/groupMaterial';
import { MaterialListItem } from 'modules/material/ListItems';
import { SetCheck } from 'modules/materialSet/SetCheck/SetCheck';
import { useMaterialSetListContext } from 'modules/materialSet/materialSetListContext';
import React, { useCallback } from 'react';
import { useStores } from 'util/mobx/stores';
import { ItemPicture } from '../../../material/ListItems';

interface Props {
  setGroupId: string;
  groupMaterial: GroupMaterialDTO;
  lazyLoadScrollContainer?: string;
  level?: number;
  amount?: number;
  onReload?: () => void;
  menu?: React.ReactElement;
}

export const MaterialItem = ({ setGroupId, groupMaterial, lazyLoadScrollContainer, level = 1, amount, onReload, menu }: Props) => {
  if (!groupMaterial.material) {
    throw new Error('groupMaterial is not an material');
  }

  const material = groupMaterial.material;

  const { loadingStore } = useStores();
  const { onTagCreate, onTagDelete, onTagUpdate, onClickItem, readonly } = useMaterialSetListContext();

  const handleOnClick = useCallback(() => {
    if (!onClickItem) {
      return;
    }
    onClickItem(groupMaterial, { materialId: material.materialId });
  }, [groupMaterial, material.materialId, onClickItem]);

  const handleUpdateFile = useCallback(
    async (fileId: string) => {
      if (!onReload) {
        return;
      }
      await loadingStore.withLoadingBar(() => updateMaterial({ materialId: material.materialId, pictureFileId: fileId }));
      onReload();
    },
    [loadingStore, material.materialId, onReload]
  );

  return (
    <MaterialListItem
      classNamePrefix="set"
      level={level}
      name={material.name}
      amount={amount}
      picture={<ItemPicture lazyLoadScrollContainer={lazyLoadScrollContainer} picture={material} onUpdateFile={handleUpdateFile} />}
      onClick={onClickItem && handleOnClick}
      end={<MaterialValueTags material={material} />}
      prefix={<SetCheck groupMaterialId={groupMaterial.groupMaterialId} setGroupId={setGroupId} />}
      instructions={
        <Tags
          editable={!readonly}
          tags={groupMaterial.tags}
          onTagCreate={onTagCreate && onTagCreate({ groupMaterialId: groupMaterial.groupMaterialId })}
          onTagUpdate={onTagUpdate}
          onTagDelete={onTagDelete}
        />
      }
      menu={menu}
    />
  );
};
