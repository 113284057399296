import { TemplatePicture } from 'components/Picture';
import { PackagingType } from 'dto/package';
import { TemplateDTO } from 'dto/template';
import React from 'react';
import ListItemDropdownContainer from '../ListItemDropdownContainer';
import PackageListItem from '../PackageListItem';
import SingleInstrumentListItem from '../SingleInstrumentListItem';
import './TemplateListItem.css';

interface Props {
  template: TemplateDTO;
  filterListBy?: string;
}

const TemplateListItem = ({ template, filterListBy }: Props) => {
  const renderList = () => (
    <div style={{ display: 'block' }} className="content_holder no_padding padding_left in_matlist lvl_1 packages-list">
      {template.packages.map(packageItem => {
        if (packageItem.packagingType === PackagingType.Single) {
          return <SingleInstrumentListItem singleInstrument={packageItem} key={packageItem.packageId} filterListBy={filterListBy} />;
        }
        return <PackageListItem packageItem={packageItem} key={packageItem.packageId} filterListBy={filterListBy} />;
      })}
    </div>
  );
  return (
    <ListItemDropdownContainer ListElement={renderList} classPrefix="template">
      <div className="image_wrapper_50">
        <TemplatePicture />
      </div>
      <div className="material_info">
        <div className="material_text">
          <div>{template.name}</div>
        </div>
      </div>
    </ListItemDropdownContainer>
  );
};

export default TemplateListItem;
