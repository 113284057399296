import { InstrumentsDTO, InstrumentDTO } from 'dto/instrument';
import { get } from './common';

import { validate } from './validation';

export const getInstrument = async (instrumentId: string): Promise<InstrumentDTO> => {
  const templates = await get('/instrument', { instrumentId }).then(response => response.data);
  return validate(InstrumentDTO, templates);
};

export const getInstruments = async (packageId: string): Promise<InstrumentsDTO> => {
  const template = await get('/package/instruments', { packageId }).then(response => response.data);
  return validate(InstrumentsDTO, template);
};
