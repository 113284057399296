import React from 'react';
import LinkBackWrapper from 'components/LinkBackWrapper';
import './NavSectionButton.css';
import Picture from 'components/Picture';

interface Props {
  icon: string;
  label: string;
  goBack: () => void;
}

const iconUrl = (iconName: string) => `images/${iconName}.svg`;

const NavSectionBackButton = (props: Props) => {
  return (
    <LinkBackWrapper className="btn_back_mobile" goBack={props.goBack} autoWidth>
      <div className="btn_section btn_back_mobile">
        <Picture src={iconUrl(props.icon)} alt="" className="image_section" />
        <div>{props.label}</div>
      </div>
    </LinkBackWrapper>
  );
};

export default NavSectionBackButton;
