import React from 'react';
import { HistoryActionType, HistoryDTO, SurgeryGuideProcedureHistoryDTO } from 'dto/history';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import ListItemDropdownContainer from 'components/ListItems/ListItemDropdownContainer';
import SurgeryProceduresHistoryIcons from './SurgeryProceduresHistoryIcons';
import SurgeryHistoryItem from '../SurgeryHistoryItem';

interface Props {
  histories: SurgeryGuideProcedureHistoryDTO[];
  onProcedureClick: (historyItem: HistoryDTO) => void;
}

const getHistoryDescription = (translate: TFunction, item: HistoryDTO) => {
  if (item.action === HistoryActionType.create) {
    return translate('chatAndHistory.historyProcedures.created');
  }
  if (item.action === HistoryActionType.update && item.field === 'checked' && item.dataValue === '1') {
    return translate('chatAndHistory.historyProcedures.checked');
  }
  if (item.action === HistoryActionType.update && item.field === 'checked' && item.dataValue === '0') {
    return translate('chatAndHistory.historyProcedures.unchecked');
  }
  if (item.action === HistoryActionType.update && item.field === 'disabled' && item.dataValue === '1') {
    return translate('chatAndHistory.historyProcedures.disabled');
  }
  if (item.action === HistoryActionType.update && item.field === 'disabled' && item.dataValue === '0') {
    return translate('chatAndHistory.historyProcedures.enabled');
  }
  return translate('chatAndHistory.historyProcedures.made');
};

const SurgeryHistoryProcedures = ({ histories, onProcedureClick }: Props) => {
  const { t } = useTranslation('surgery');
  const renderList = (history: SurgeryGuideProcedureHistoryDTO) => (
    <div className="guide-items-list">
      {history.procedureHistories.map(historyItem => {
        return (
          <SurgeryHistoryItem
            onClick={() => onProcedureClick(historyItem)}
            icon={<SurgeryProceduresHistoryIcons historyItem={historyItem} />}
            item={historyItem}
            description={getHistoryDescription(t, historyItem)}
            key={historyItem.historyId}
            showLink
          />
        );
      })}
    </div>
  );

  return (
    <div className="history-guide-items-list">
      {histories.map(history => (
        <ListItemDropdownContainer
          ListElement={() => renderList(history)}
          classPrefix="guide-item"
          key={history.guideChapter.guideChapterId}
        >
          <div className="list_item_material with_dropdown list-item">
            <div className="image_wrapper_50">
              <img src="images/picture-prozeduren.jpg" alt="" className="image_circle_50" />
              <div className="image_border image-border" />
            </div>
            <div className="material_info">
              <div className="material_text">
                <div className="material_text">
                  <div>{history.guideChapter.name}</div>
                </div>
              </div>
            </div>
          </div>
        </ListItemDropdownContainer>
      ))}
    </div>
  );
};

export default SurgeryHistoryProcedures;
