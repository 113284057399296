import React from 'react';
import './MoreMenuMaterialSearchButton.css';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';

export default () => {
  const { searchStore } = useStores();
  const { t } = useTranslation('guide');

  const handlerOpenSearchPanel = () => {
    searchStore.openSearchPanel();
  };

  return (
    <>
      <div className="wrapper_section_buttons wrapper-section-buttons" onClick={handlerOpenSearchPanel}>
        <div className="btn_more_section btn-more-section">
          <img src="images/icon_magnifier_16.svg" alt="" className="image_section" />
          <div>{t('subMenu.materialSearch')}</div>
        </div>
      </div>
    </>
  );
};
