import { CSSProperties } from 'react';
import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

const grid = 4;

export const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined): CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: isDragging ? grid * 2 : '',
  margin: isDragging ? `0 0 ${grid}px 0` : ``,

  // change background colour if dragging
  background: isDragging ? '#f7f9fa' : '',

  // remove blue outline on the IPad
  outline: 'none',

  // styles we need to apply on draggables
  ...draggableStyle
});
