import React from 'react';
import { PostDTO } from 'dto/post';
import ImageCircle from 'components/ImageCircle';
import PostMediaList from 'components/PostItem/PostMediaList';
import { getDayTimeWording } from 'util/date';
import { useTranslation } from 'react-i18next';
import Popover from 'components/Popover';
import ItemMenu from '../../../../components/ItemMenu';

interface Props {
  post: PostDTO;
  menu?: JSX.Element | JSX.Element[] | false;
}

const SurgeryPostItem = ({ post, menu }: Props) => {
  return (
    <div className="post_item in_operationen">
      <div className="b_chat_content in_operation">
        <ImageCircle
          className="image_circle_40_post"
          size="40"
          user={post?.contentElement.user}
          highlight={false}
          noWrapper
          withUserInfoBoxLeft
        />

        <div className="txt_content">{post.contentElement.description}</div>
        <PostMediaList files={post.contentElement.contentElementMedias} />
        <div className="t2 bottom-margin">{getDayTimeWording(post.createdAt, useTranslation('date'), true)}</div>
        {menu && (
          <div className="btn_show_detail_post" style={{ top: '15px', right: '20px' }}>
            <Popover trigger={<img src="images/icon_menu_dots.svg" alt="" className="image_dots_post image-dots-post" />}>
              {({ handleClose, isOpen }) => (
                <ItemMenu handleClose={handleClose} isOpen={!!isOpen}>
                  {menu}
                </ItemMenu>
              )}
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
};

export default SurgeryPostItem;
