import React from 'react';
import { MaterialDTO } from 'dto/material';
import Picture from 'components/Picture';
import MaterialValueTags from 'components/MaterialValueTags';
import GetCameraImageButton from 'components/GetCameraImageButton';

interface Props {
  material: MaterialDTO;
  onUpdateFile: (fileId: string) => Promise<void>;
}

const defaultData = {
  pictureThumbnail: 'images/icon_book_makephoto.jpg',
  picture: 'images/image_no_photo.jpg'
};

const MaterialHead = ({ material, onUpdateFile }: Props) => {
  return (
    <div className="material_head">
      <div className="small_product_image">
        <div className="image_border _3px" />
        {material.pictureThumbnail ? (
          <Picture src={material.pictureThumbnail} width={100} alt="" className="image_circle_100" />
        ) : (
          <GetCameraImageButton buttonStyle="head" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
        )}
      </div>
      <div className="div-block-16">
        <MaterialValueTags fullInfo classNameTitle="mat_detail " classNameItem="mat_detail" material={material} />
      </div>
      <div className="div-block-18">
        <Picture
          src={material.picture || defaultData.picture}
          alt=""
          // altSrc="images/image_no_photo.jpg"
          className="image-2"
        />
      </div>
    </div>
  );
};

export default MaterialHead;
