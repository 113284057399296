import React, { useState } from 'react';
import Picture from 'components/Picture';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import { saveAs } from 'file-saver';
import './DownloadButton.css';
import { fetchSecure } from 'util/download';
import { useStores } from 'util/mobx/stores';

interface Props {
  file: ContentElementMediaDTO;
  children?: JSX.Element[] | JSX.Element;
  wrapChildrenWithDownload?: boolean;
}

export default ({ file, children, wrapChildrenWithDownload = true }: Props) => {
  const { domainStore } = useStores();
  const [isActive, setIsActive] = useState(false);

  const exportFile = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event) {
      event.stopPropagation();
    }
    if (file) {
      let title: string | undefined = `${file.title}.${file.fileExtension}`;
      if (!file.title) {
        title = file.originalFileName;
      }

      if (domainStore.isMobile) {
        return;
      }

      fetchSecure(file.fullUrl).then(url => saveAs(url, title));
    }
  };

  return (
    <>
      <div className="download_file mobile_hidden">
        <div className="hitbox_helper">
          <div
            className="div-block-75"
            onClick={e => exportFile(e)}
            onMouseEnter={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
          >
            <Picture
              src="images/data-download.svg"
              alt=""
              className={isActive ? 'icon_question icon_question_active' : 'icon_question icon_question_nonactive'}
            />
          </div>
        </div>
      </div>
      {children && <div onClick={wrapChildrenWithDownload ? exportFile : undefined}>{children}</div>}
    </>
  );
};
