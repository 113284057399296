import React from 'react';
import { GuideDTO } from 'dto/guide';
import BookList from './BookList';

interface Props {
  groupName: string;
  guideList: GuideDTO[];
  className: string;
}

const GuideListGroup = (props: Props) => (
  <>
    <h1 className={props.className}>{props.groupName}</h1>
    <BookList guideList={props.guideList} />
  </>
);

export default GuideListGroup;
