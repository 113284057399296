import { MaterialListItem } from 'modules/material/ListItems';
import { ListItemRendererType } from 'modules/material/ListItems/MaterialListItem';
import React, { useCallback, useMemo } from 'react';
import { GuideMaterialLikeFlatDTO } from 'dto/guide';
import { v4 as uuidv4 } from 'uuid';
import { GuideMaterialMenu } from '../GuideMaterialMenu';
import { useGuideMaterialListContext } from '../guideMaterialListContext';
import './GuideMaterialListItem.css';

interface Props {
  name: string;
  picture: React.ReactNode;
  onClick?: () => void;

  amount?: number;
  instructions?: React.ReactNode;
  end?: React.ReactNode;

  onDelete?: () => void;
  onAddLotNumber?: () => void;
  onOpenKnowledgeAndAttitudes?: () => void;

  prefix?: React.ReactNode;
  enablePrefixExpand?: boolean;

  level: number;
  onDropdownExpand?: () => void;
  dropdownItems?: JSX.Element[] | JSX.Element;

  /**
   * This can be set to remove the space reserved for the expand-icon
   */
  noExpandIconSpace?: boolean;

  isInitialLoading?: boolean;
  classNamePrefix?: string;

  /**
   * Enable the lot number menu entry (e.g. for templates)
   */
  enableLotNumberEdit?: boolean;
}
export const GuideMaterialListItem = ({
  name,
  picture,
  onClick,
  onDropdownExpand,
  amount,
  instructions,
  end,
  onDelete,
  onAddLotNumber,
  onOpenKnowledgeAndAttitudes,
  prefix,
  enablePrefixExpand = false,
  level,
  dropdownItems,
  noExpandIconSpace,
  isInitialLoading = false,
  classNamePrefix,
  enableLotNumberEdit = false
}: Props) => {
  const hoverContainerId = useMemo(() => {
    return uuidv4().toString();
  }, []);
  return (
    <MaterialListItem
      containerId={hoverContainerId}
      classNamePrefix={classNamePrefix || 'guide'}
      menu={
        <GuideMaterialMenu
          hoverContainerId={hoverContainerId}
          dotsOnly={!onClick}
          onDelete={onDelete}
          onAddLotNumber={enableLotNumberEdit ? onAddLotNumber : undefined}
          onOpenKnowledgeAndAttitudes={onOpenKnowledgeAndAttitudes}
        />
      }
      level={level}
      name={name}
      picture={picture}
      amount={amount}
      dropdownItems={dropdownItems}
      noExpandIconSpace={noExpandIconSpace}
      end={end}
      instructions={instructions}
      isInitialLoading={isInitialLoading}
      onClick={onClick}
      onDropdownExpand={onDropdownExpand}
      prefix={prefix}
      enablePrefixExpand={enablePrefixExpand}
    />
  );
};

/**
 * Implements the ListItemRendererType for the GuideMaterialLikeFlatDTO
 */
export const GuideMaterialListItemRenderer: ListItemRendererType<GuideMaterialLikeFlatDTO> = ({
  parentEntity,
  level,
  name,
  picture,
  amount,
  dropdownItems,
  end,
  instructions,
  isInitialLoading,
  noExpandIconSpace,
  onClick,
  onDropdownExpand,
  prefix,
  enablePrefixExpand
}) => {
  const { onDelete } = useGuideMaterialListContext();

  const handleDelete = useCallback(() => {
    if (!onDelete) {
      return;
    }
    onDelete(parentEntity.guideMaterialId);
  }, [onDelete, parentEntity.guideMaterialId]);

  return (
    <GuideMaterialListItem
      level={level}
      name={name}
      picture={picture}
      amount={amount}
      dropdownItems={dropdownItems}
      end={end}
      instructions={instructions}
      isInitialLoading={isInitialLoading}
      noExpandIconSpace={noExpandIconSpace}
      onClick={onClick}
      onDelete={handleDelete}
      onDropdownExpand={onDropdownExpand}
      onOpenKnowledgeAndAttitudes={onClick}
      prefix={prefix}
      enablePrefixExpand={enablePrefixExpand}
    />
  );
};
