import React from 'react';
import { observer } from 'mobx-react';

import FlyoutContainer from 'components/FlyoutContainer';
import { GroupedGuideProcedureDTO } from 'dto/procedure';
import { useTranslation } from 'react-i18next';
import { GuideListItem } from 'components/ListItems';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  relatedGuides?: GroupedGuideProcedureDTO[];
}

const ProcedureGuidesFlyout = observer(({ isOpen = false, onClose, relatedGuides = [] }: Props) => {
  const { t } = useTranslation('procedureGuidesFlyout');

  const renderTip = () => (
    <div className="text-block-13">
      {t('tip')}
      <br />
      <strong>{t('tipBold')}</strong>
    </div>
  );

  return (
    <FlyoutContainer tip={renderTip()} isOpen={isOpen} closePopUp={onClose} cancelLabel={t('button.close')}>
      <div className="flyout_box">
        <div>{t('label.relatedManuals')}</div>
        <div className="menu_list">
          {relatedGuides.map(({ department, guides }) => (
            <>
              <div className="txt_section_nav">
                {department.locationShortName} | {department.functionalAreaShortName} | {department.name}
              </div>
              {guides.map(guide => (
                <div className="btn_in_list_no_hover">
                  <GuideListItem guide={guide} inFlyout />
                </div>
              ))}
            </>
          ))}
        </div>
      </div>
    </FlyoutContainer>
  );
});

export default ProcedureGuidesFlyout;
