import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { StorageLocationListItem } from 'components/ListItems';
import { SimpleStorageLocationDTO } from 'dto/storageLocation';

interface Props {
  items?: SimpleStorageLocationDTO[];
  inFlyout?: boolean;
}

const OriginStorageLocationsList = observer(({ items = [], inFlyout }: Props) => {
  const { storageLocationStore, domainStore } = useStores();
  const { t } = useTranslation('storageLocations');
  const checkActive = (location: SimpleStorageLocationDTO) => {
    let status = false;
    storageLocationStore.materialStorageLocations.forEach(st => {
      if (
        location.storageLocationId === st.originStorageLocation.storageLocationId &&
        st.endStorageLocations.find(es => es.storageLocationId === storageLocationStore.selectedMaterialStorageLocation?.storageLocationId)
      ) {
        status = true;
      }
    });
    return status;
  };

  const selectStorageLocation = (storageLocation: SimpleStorageLocationDTO) => {
    storageLocationStore.setSelectedMaterialStorageLocation(storageLocation, true);
  };

  return (
    <div className="div-block-140">
      {inFlyout ? (
        <div className="h3">{t('startingPoint')}</div>
      ) : (
        <div className="h3">
          {domainStore.currentLocation.shortName} | {domainStore.currentFunctionalArea.shortName}
        </div>
      )}
      <div
        className={`storage-location-items-list ${storageLocationStore.selectedMaterialStorageLocation ? 'with-back-button' : ''} ${
          inFlyout ? 'no-height' : ''
        }`}
      >
        {items.map(storageLocation => (
          <StorageLocationListItem
            storageLocation={storageLocation}
            key={storageLocation.storageLocationId}
            isSelected={checkActive(storageLocation)}
            onClick={() => selectStorageLocation(storageLocation)}
          />
        ))}
      </div>
    </div>
  );
});

export default OriginStorageLocationsList;
