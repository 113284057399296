import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import FlyoutButtonBar from './FlyoutButtonBar';
import './FlyoutContainer.css';

interface Props {
  title?: string;
  isOpen: boolean;
  children: JSX.Element[] | JSX.Element;
  tip?: JSX.Element;
  // only when these are filled there is a button bar
  onSubmit?: () => void;
  closePopUp: () => void;
  isAllowedToSubmit?: boolean;
  icon?: JSX.Element;
  submitLabel?: string;
  cancelLabel?: string;
  warning?: boolean;
  closeAsSubmit?: boolean;
  headerButton?: JSX.Element | null | false;
  className?: string;
  isLoading?: boolean;
  itemRef?: React.RefObject<HTMLDivElement>;
  hideFlyoutLine?: boolean;
  iosMarginTop?: boolean;
}

export default observer((props: Props) => {
  const { loadingStore } = useStores();
  return (
    <CSSTransition in={props.isOpen} timeout={500} classNames="flyout" unmountOnExit>
      <div className={`tool_flyout_wrapper no_margin ${props.className || ''} `} style={{ display: 'flex' }}>
        <div className={`flyout-container-base flyout-container ${props.iosMarginTop ? 'iosMarginTop' : ''}`} ref={props.itemRef}>
          {props.icon && props.title && (
            <div className="flyout_header flylout-header">
              <div className="div-block-53 flyout-image-container">
                <div className="image_wrapper_40">{props.icon}</div>
                <div>{props.title}</div>
              </div>
              {props.headerButton ? props.headerButton : null}
            </div>
          )}
          {!props.icon && props.title && <div className="t2-bold">{props.title}</div>}
          {!props.hideFlyoutLine && (
            <div className={`flyout_line flyout-line ${loadingStore.isFlyoutLoading || props.isLoading ? 'line-loading' : ''} `} />
          )}
          {(loadingStore.isFlyoutLoading || props.isLoading) && <div className="div-block-154 flyout-loader-background" />}
          {props.children}
          {props.cancelLabel && (
            <FlyoutButtonBar
              closePopUp={props.closePopUp}
              onSubmit={props.onSubmit}
              cancelLabel={props.cancelLabel}
              submitLabel={props.submitLabel}
              isAllowedToSubmit={props.isAllowedToSubmit}
              warning={props.warning}
              tip={props.tip}
              closeAsSubmit={props.closeAsSubmit}
              isLoading={props.isLoading}
            />
          )}
        </div>
      </div>
    </CSSTransition>
  );
});
