import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import Button from 'components/Form/Button';
import { ReportsContentType } from '../types';
import ReportNavigationButton from './ReportNavigationButton';
import './ReportNavigation.css';

interface Props {
  setContentType: (type: ReportsContentType) => void;
  selectedContentType: ReportsContentType;
}

const ReportNavigation = ({ setContentType, selectedContentType }: Props) => {
  const { t } = useTranslation('reports');
  const { reportStore } = useStores();
  return (
    <div className="navigation_reports report-navigation">
      <div className="div-block-23">
        <ReportNavigationButton
          text={t('menu.standardization')}
          icon="images/icon_selection_16.svg"
          onClick={() => setContentType(ReportsContentType.standardization)}
          selected={selectedContentType === ReportsContentType.standardization}
        />
        <ReportNavigationButton
          text={t('menu.guides')}
          icon="images/icon_saved-items_16.svg"
          onClick={() => setContentType(ReportsContentType.guides)}
          selected={selectedContentType === ReportsContentType.guides}
        />
      </div>
      <Button onClick={() => reportStore.setIsReportDrawerOpen(false)} className="btn_close_reports">
        {t('menu.close')}
      </Button>
    </div>
  );
};

export default ReportNavigation;
