import { observable, action, runInAction } from 'mobx';

import { PostDTO, PostListDTO } from 'dto/post';
import {
  getGuidePosts,
  createGuidePosts,
  getDepartmentPosts,
  createDepartmentPosts,
  getFunctionalAreaPost,
  createFunctionalAreaPosts,
  createBriefingPost,
  getBriefingPost,
  getNewFunctionalAreaPosts,
  editPost
} from 'api/post';
import { CreatePostContentElementDTO, OptionalPostContentElementDTO } from 'dto/contentElement';
import LoadingStore from './loadingStore';

export default class PostStore {
  @observable
  private loadingStore: LoadingStore;

  @observable
  guidePosts?: PostDTO[];

  @observable
  guidesPostsInFunctionalArea?: PostDTO[];

  @observable
  functionalAreaPosts: PostListDTO = {
    count: 0,
    posts: []
  };

  @observable
  newFunctionalAreaPosts: PostDTO[] = [];

  @observable
  nextFunctionalAreaPostPage = 0;

  @observable
  departmentPosts?: PostDTO[];

  @observable
  departmentPostsInFunctionalArea?: PostDTO[];

  @observable
  dialogPosts?: PostDTO[];

  @observable
  isDialogInEditMode = false;

  @observable
  selectedDialogPost?: PostDTO;

  @observable
  briefingPosts?: PostDTO[];

  @observable
  isChatBoardFlyoutShown: boolean | null = null;

  @observable
  isFlyoutInBoardOpen = false;

  @observable
  isChatBarExpanded: boolean | null = null;

  @observable
  isChatInEditMode = false;

  @observable
  isChatFlyoutOpen = false;

  @observable
  isChatFlyoutCollapsible = false;

  @observable
  selectedPost?: PostDTO;

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;
  }

  @action
  setIsChatBoardFlyoutShown = (status: boolean) => {
    this.isChatBoardFlyoutShown = status;
  };

  @action
  async loadGuidePosts(guideId: string) {
    const guidePosts = await this.loadingStore.withLoadingBar(() => getGuidePosts({ guideId, days: 21 }));
    runInAction(() => {
      this.guidePosts = guidePosts;
    });
  }

  @action
  async loadGuidesPostsInFunctionalArea(functionalAreaId: string) {
    const guidePosts = await this.loadingStore.withLoadingBar(() => getGuidePosts({ functionalAreaId, days: 21 }));
    runInAction(() => {
      this.guidesPostsInFunctionalArea = guidePosts;
    });
  }

  @action
  async loadDepartmentPosts(departmentId: string) {
    const departmentPosts = await this.loadingStore.withLoadingBar(() => getDepartmentPosts({ departmentId, days: 21 }));
    runInAction(() => {
      this.departmentPosts = departmentPosts;
    });
  }

  @action
  async loadDepartmentPostsInFunctionalArea(functionalAreaId: string) {
    const departmentPostsInFunctionalArea = await this.loadingStore.withLoadingBar(() =>
      getDepartmentPosts({ functionalAreaId, days: 21 })
    );
    runInAction(() => {
      this.departmentPostsInFunctionalArea = departmentPostsInFunctionalArea;
    });
  }

  @action
  async loadNewFunctionalAreaPosts(functionalAreaId: string) {
    const functionalAreaPosts = await this.loadingStore.withLoadingBar(() => {
      const latestFunctionalAreaPostId = this.functionalAreaPosts.posts.length === 0 ? undefined : this.functionalAreaPosts.posts[0].postId;

      return getNewFunctionalAreaPosts({ functionalAreaId, latestFunctionalAreaPostId });
    });

    runInAction(() => {
      this.newFunctionalAreaPosts = functionalAreaPosts;
    });
  }

  @action
  async loadNextFunctionalAreaPosts(functionalAreaId: string) {
    const functionalAreaPosts = await this.loadingStore.withLoadingBar(() =>
      getFunctionalAreaPost({
        functionalAreaId,
        page: this.nextFunctionalAreaPostPage,
        pageSize: 30,
        latestFunctionalAreaPostId: this.functionalAreaPosts.posts?.[0]?.postId || undefined
      })
    );

    runInAction(() => {
      this.functionalAreaPosts.posts.push(...functionalAreaPosts.posts);
      this.functionalAreaPosts.count = functionalAreaPosts.count;

      this.nextFunctionalAreaPostPage++;
    });
  }

  @action
  async loadBriefingPosts(briefingId: string) {
    const briefingPosts = await this.loadingStore.withLoadingBar(() => getBriefingPost({ briefingId }));
    runInAction(() => {
      this.briefingPosts = briefingPosts;
    });
  }

  @action
  resetPosts() {
    this.guidesPostsInFunctionalArea = undefined;
    this.guidePosts = undefined;
    this.departmentPosts = undefined;
    this.departmentPostsInFunctionalArea = undefined;
    this.functionalAreaPosts = {
      count: 0,
      posts: []
    };
    this.nextFunctionalAreaPostPage = 0;
    this.newFunctionalAreaPosts = [];
  }

  @action
  async createDepartmentPost(departmentId: string, post: CreatePostContentElementDTO) {
    await this.loadingStore.withLoadingBar(() => createDepartmentPosts({ departmentId, content: post }));
    this.loadDepartmentPosts(departmentId);
  }

  @action
  async createGuidePost(guideId: string, post: CreatePostContentElementDTO) {
    await this.loadingStore.withLoadingBar(() => createGuidePosts({ guideId, content: post }));
    this.loadGuidePosts(guideId);
  }

  @action
  async createFunctionalAreaPost(functionalAreaId: string, post: CreatePostContentElementDTO) {
    await this.loadingStore.withLoadingBar(() => createFunctionalAreaPosts({ functionalAreaId, content: post }));
    this.loadNewFunctionalAreaPosts(functionalAreaId);
  }

  @action
  async createBriefingPost(briefingId: string, post: CreatePostContentElementDTO) {
    await this.loadingStore.withLoadingBar(() => createBriefingPost({ briefingId, content: post }));
    this.loadBriefingPosts(briefingId);
  }

  @action
  async editPost(postId: string, post: OptionalPostContentElementDTO) {
    await this.loadingStore.withLoadingBar(() => editPost({ postId, content: post }));
  }

  @action
  setIsFlyoutInBoardOpen = (status: boolean) => {
    this.isFlyoutInBoardOpen = status;
    if (!status) {
      setTimeout(() => this.resetSelectedPost(), 500);
    }
  };

  @action
  setIsChatBarExpanded = (status: boolean | null) => {
    this.isChatBarExpanded = status;
  };

  @action
  setIsChatInEditMode = (status: boolean) => {
    this.isChatInEditMode = status;
  };

  @action
  setIsChatFlyoutOpen = (status: boolean) => {
    this.isChatFlyoutOpen = status;
  };

  @action
  setIsChatFlyoutCollapsible = (status: boolean) => {
    this.isChatFlyoutCollapsible = status;
  };

  @action
  setSelectedPost = (post: PostDTO) => {
    this.selectedPost = post;
  };

  @action
  resetSelectedPost = () => {
    this.selectedPost = undefined;
  };

  @action
  activateDialogEdit = (post: PostDTO) => {
    this.selectedDialogPost = post;
    this.isDialogInEditMode = true;
  };

  @action
  resetDialogForm = () => {
    this.selectedDialogPost = undefined;
    this.isDialogInEditMode = false;
  };

  @action
  reloadBoard = async (currentFunctionalAreaId: string) => {
    await this.resetPosts();
    this.loadNewFunctionalAreaPosts(currentFunctionalAreaId);
    this.loadDepartmentPostsInFunctionalArea(currentFunctionalAreaId);
    this.loadGuidesPostsInFunctionalArea(currentFunctionalAreaId);
  };
}
