import { subject } from '@casl/ability';
import { reorderGroupMaterials } from 'api/groupMaterial';
import { useInvalidateGroupMaterials } from 'api/invalidate';
import { reorderSetGroups } from 'api/setGroup';
import { Can, actions, subjectArea } from 'casl/setupCaslAbility';
import Button from 'components/Form/Button';
import InfoBox from 'components/InfoBox';
import ListItemAdd from 'components/ListItemAdd';
import { SetGroupWithTagsDTO } from 'dto/setGroup';
import { TagDTO } from 'dto/tag';
import { observer } from 'mobx-react';
import { MaterialSetList } from 'modules/materialSet/MaterialSetList';
import { OnGroupMaterialClick, TagParent } from 'modules/materialSet/materialSetListContext';
import React, { useCallback, useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { useSetCheckContext } from 'modules/materialSet/SetCheck/SetCheckContext';
import { LoadingType } from 'stores/loadingStore';
import CheckListAuthorBox from './CheckListAuthorBox';
import MaterialSetGroupsRightMenu from './MaterialSetGroupsRightMenu';
import MaterialSetGroupsRightMenuMobile from './MaterialSetGroupsRightMenuMobile';

import './MaterialSetGroups2.css';

interface Props {
  groupType: string;
  checkListDisabled: boolean;
}

const MaterialSetGroups2 = observer(({ groupType, checkListDisabled }: Props) => {
  const { materialSetsStore, appNavigationStore, domainStore, guideDetailDrawerStore, loadingStore } = useStores();
  const { submit } = useSetCheckContext();
  const [setGroups, setSetGroups] = useState(materialSetsStore.materialSetDetail?.setGroups);
  // Hide until the checks are reset to prevent showing them for a short time.
  const [show, setShow] = useState(false);
  const { t } = useTranslation(groupType);

  useEffect(() => {
    setSetGroups(materialSetsStore.materialSetDetail?.setGroups);
  }, [materialSetsStore.materialSetDetail]);

  // Refresh the store, so that it gets all changes done in this page.
  useEffect(() => {
    materialSetsStore.refreshMaterialSet();
  }, [materialSetsStore]);

  // set the component for the right menu
  useEffect(() => {
    if (!materialSetsStore.isCheckListVisible && !domainStore.isMobile) {
      appNavigationStore.setRightMenuBuilder(() => {
        return <MaterialSetGroupsRightMenu groupType={groupType} checkListDisabled={checkListDisabled} />;
      });
      return;
    }
    if (!materialSetsStore.isCheckListVisible && !checkListDisabled && domainStore.isMobile) {
      appNavigationStore.setRightMenuBuilder(() => {
        return <MaterialSetGroupsRightMenuMobile groupType={groupType} checkListDisabled={checkListDisabled} />;
      });
      return;
    }
    appNavigationStore.withoutMenu();
  }, [appNavigationStore, groupType, checkListDisabled, domainStore.isMobile, materialSetsStore.isCheckListVisible]);

  const invalidateGroupMaterials = useInvalidateGroupMaterials();

  useEffect(() => {
    materialSetsStore.registerReload(async () => {
      await invalidateGroupMaterials();
    });

    return () => {
      materialSetsStore.registerReload(undefined);
    };
  }, [invalidateGroupMaterials, materialSetsStore]);

  useEffect(() => {
    if (materialSetsStore.isCheckListVisible) {
      materialSetsStore.loadMaterialSetChecks(materialSetsStore.materialSetId);
      if (!materialSetsStore.selectedSetCheck) {
        materialSetsStore.generateCheckItems();
      }
    }
    if (materialSetsStore.materialSetDetail) {
      setSetGroups(materialSetsStore.materialSetDetail.setGroups);
    }
  }, [materialSetsStore, materialSetsStore.isCheckListVisible, materialSetsStore.materialSetDetail]);

  useEffect(() => {
    materialSetsStore.setIsCheckListVisible(false);
    setShow(true);
  }, [materialSetsStore]);

  const handleCreateGroup = useCallback(() => {
    materialSetsStore.setIsSetGroupFormFlyoutOpen(true);
  }, [materialSetsStore]);

  const handleGroupDragEnd = (result: DropResult) => {
    if (!result.destination || !setGroups) return;
    const setGroupToBeRemoved = setGroups.find(group => group.setGroupId === result.draggableId);
    const oldSetGroups = setGroups.filter(group => group.setGroupId !== result.draggableId);
    if (setGroupToBeRemoved) {
      oldSetGroups.splice(result.destination.index, 0, setGroupToBeRemoved);
      setSetGroups([...oldSetGroups]);
      reorderSetGroups({
        setGroupIds: oldSetGroups.map(group => group.setGroupId)
      });
    }
  };

  const handleReload = useCallback(async () => {
    await loadingStore.withSpecificLoadingBar(LoadingType.DEFAULT, async () => {
      await materialSetsStore.refreshMaterialSet();
    });
  }, [loadingStore, materialSetsStore]);

  const handleGroupItemDragEnd = async (result: DropResult) => {
    await loadingStore.withSpecificLoadingBar(LoadingType.DEFAULT, async () => {
      if (result.destination?.index === undefined) {
        return;
      }

      await reorderGroupMaterials({
        sourceGroupMaterialId: result.draggableId,
        targetIndex: result.destination.index
      });
    });

    await handleReload();
  };

  const handleTagCreate = useCallback(
    (parentId: TagParent) => (tagName: string): Promise<TagDTO> => {
      if (parentId.groupMaterialId) {
        return materialSetsStore.createGroupMaterialTag(parentId.groupMaterialId, tagName);
      }
      if (parentId.setGroupId) {
        return materialSetsStore.createSetGroupTag(parentId.setGroupId, tagName);
      }
      throw new Error('no parentId is set');
    },
    [materialSetsStore]
  );

  const handleTagUpdate = useCallback(
    async (tag: TagDTO): Promise<TagDTO> => {
      return materialSetsStore.updateTag(tag);
    },
    [materialSetsStore]
  );

  const handleTagDelete = useCallback(
    async (tagId: string): Promise<boolean> => {
      return materialSetsStore.deleteTag(tagId);
    },
    [materialSetsStore]
  );

  const handleSetGroupDelete = useCallback(
    async (setGroupId: string) => {
      return materialSetsStore.deleteSetGroup(setGroupId);
    },
    [materialSetsStore]
  );

  const handleSetGroupUpdate = useCallback(
    async (setGroup: SetGroupWithTagsDTO) => {
      await materialSetsStore.setIsSetGroupFormFlyoutOpen(true, setGroup);
    },
    [materialSetsStore]
  );

  const handleOnClickItem: OnGroupMaterialClick = useCallback(
    (groupMaterial, specific) => {
      guideDetailDrawerStore.open(specific, true);
    },
    [guideDetailDrawerStore]
  );

  const handleDeleteGroupMaterial = useCallback(
    (setGroupId: string, groupMaterialId: string) => {
      materialSetsStore.deleteGroupMaterial(groupMaterialId).then(() => handleReload());
    },
    [handleReload, materialSetsStore]
  );

  appNavigationStore.useSubPageIdSetter('list');

  if (setGroups && show) {
    return (
      <>
        {!materialSetsStore.isCheckListVisible && !checkListDisabled && (
          <div className="mobile_only_container">
            <Button className="btn_start_control" onClick={() => materialSetsStore.setIsCheckListVisible(true)}>
              <img src="images/icon_checkbox_check_16.svg" alt="" className="image_pack_done" />
              {t('groupsList.button.startImplantCheck')}
            </Button>
          </div>
        )}
        <div className={`div-block-102_white set-group-left-white-area ${materialSetsStore.isCheckListVisible ? 'visible' : ''}`} />
        <div className={`div-block-102_fixed set-group-left-line ${materialSetsStore.isCheckListVisible ? 'visible' : ''}`} />
        <div className="single_colum_content">
          {materialSetsStore.isCheckListVisible && <CheckListAuthorBox groupType={groupType} onSubmit={submit} />}
          {!materialSetsStore.isCheckListVisible && (
            <Can I={actions.add} this={subject(subjectArea.materialSetGroup, { departmentId: domainStore.currentDepartment.id })}>
              <div>
                <ListItemAdd marginLeft={13} className="list_item_material_add in_packlist" onClick={handleCreateGroup}>
                  <InfoBox label={t('groupsList.button.createGroup')} />
                </ListItemAdd>
              </div>
            </Can>
          )}
          <MaterialSetList
            groupType={groupType}
            materialSetId={materialSetsStore.materialSetId}
            setGroups={setGroups}
            onReload={handleReload}
            onReorderGroup={handleGroupDragEnd}
            onReorderGroupItem={handleGroupItemDragEnd}
            onTagCreate={handleTagCreate}
            onTagUpdate={handleTagUpdate}
            onTagDelete={handleTagDelete}
            onSetGroupUpdate={handleSetGroupUpdate}
            onSetGroupDelete={handleSetGroupDelete}
            onClickItem={handleOnClickItem}
            onDeleteGroupMaterial={handleDeleteGroupMaterial}
          />
        </div>
      </>
    );
  }
  return null;
});

export default MaterialSetGroups2;
