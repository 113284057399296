import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { PostDTO, PostType } from 'dto/post';
import { useStores } from 'util/mobx/stores';
import ChatFlyoutContainer from './ChatFlyoutContainer';
import './ChatFlyout.css';
import CommentItem from '../CommentItem';
import CommentProcedureItem from '../CommentProcedureItem';

interface Props {
  posts?: PostDTO[];
  flyoutTitle: string;
}

const icon = (
  <div className="icon_board">
    <img src="images/icon_f-comment_16.svg" alt="" />
  </div>
);

const ChatFlyout = observer(({ posts = [], flyoutTitle }: Props) => {
  const { t } = useTranslation('chat');
  const { postStore } = useStores();
  const onChatBarExpand = () => {
    postStore.setIsChatBarExpanded(true);
    postStore.setIsChatFlyoutCollapsible(true);
  };
  const onFlyoutClose = () => {
    postStore.setIsChatFlyoutOpen(false);
    setTimeout(() => {
      postStore.setIsChatFlyoutCollapsible(false);
    }, 500);
  };

  const chatFlyoutConfiguration = {
    collapsible: {
      onSubmit: undefined,
      buttonSubmitLabel: undefined,
      onClose: undefined,
      buttonCloseLabel: undefined,
      withMinimizeButton: true,
      isCollapsible: true
    },
    notCollapsible: {
      onSubmit: onChatBarExpand,
      buttonSubmitLabel: t('flyout.writeComment'),
      onClose: onFlyoutClose,
      buttonCloseLabel: t('flyout.cancel'),
      withMinimizeButton: false,
      isCollapsible: false
    }
  };

  const selectedChatFlyoutConfiguration = chatFlyoutConfiguration[postStore.isChatFlyoutCollapsible ? 'collapsible' : 'notCollapsible'];

  return (
    <ChatFlyoutContainer
      onSubmit={selectedChatFlyoutConfiguration.onSubmit}
      buttonSubmitLabel={selectedChatFlyoutConfiguration.buttonSubmitLabel}
      onClose={selectedChatFlyoutConfiguration.onClose}
      buttonCloseLabel={selectedChatFlyoutConfiguration.buttonCloseLabel}
      isOpen={postStore.isChatFlyoutOpen}
      title={flyoutTitle}
      icon={icon}
      withMinimizeButton={selectedChatFlyoutConfiguration.withMinimizeButton}
      isCollapsible={selectedChatFlyoutConfiguration.isCollapsible}
    >
      <div>
        {posts.length} {t('flyout.labels.comment')}
        {posts.length !== 1 ? 'e' : ''}
      </div>
      <div className="menu_list_messages">
        {posts.map(post =>
          post.type === PostType.Chat ? (
            <CommentItem key={post.contentElement.contentElementId} comment={post} withDate />
          ) : (
            <CommentProcedureItem key={post.contentElement.contentElementId} post={post} withDate />
          )
        )}
      </div>
    </ChatFlyoutContainer>
  );
});

export default ChatFlyout;
