import React from 'react';
import { HistoryActionType, HistoryDTO } from 'dto/history';

interface Props {
  historyItem: HistoryDTO;
}

// const IconNoMage = () => <img src="images/icon_history_proz_no_made" alt="" />;
const IconCreated = () => <img src="images/icon_history_proz_add.jpg" alt="" />;
const IconUnChecked = () => <img src="images/icon_history_check_remove.jpg" alt="" />;
const IconChecked = () => <img src="images/icon_history_check.jpg" alt="" />;

const ProcedureContentElementHistoryIconsFactory = ({ historyItem }: Props) => {
  const getIcon = (h: HistoryDTO) => {
    if (h.action === HistoryActionType.update && h.field === 'checked' && h.dataValue === '0') {
      return <IconUnChecked />;
    }
    if (h.action === HistoryActionType.update && h.field === 'checked' && h.dataValue === '1') {
      return <IconChecked />;
    }
    if (h.action === HistoryActionType.create) {
      return <IconCreated />;
    }
    return null;
  };

  if (!getIcon(historyItem)) {
    return null;
  }

  return <div className="icon_no_location">{getIcon(historyItem)}</div>;
};

export default ProcedureContentElementHistoryIconsFactory;
