import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import Viewer, { Worker, defaultLayout, ToolbarSlot, Slot, RenderToolbar, LocalizationMap } from '@phuocng/react-pdf-viewer';
import FlyoutContainer from 'components/FlyoutContainer';
import { useSecureURL } from 'util/hooks';
import de from './localization.json';

const renderToolbar = (toolbarSlot: ToolbarSlot): React.ReactElement => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        width: '100%'
      }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          flexShrink: 1,
          justifyContent: 'center'
        }}
      >
        <div style={{ padding: '0 2px' }}>{toolbarSlot.previousPageButton}</div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
        </div>
        <div style={{ padding: '0 2px' }}>{toolbarSlot.nextPageButton}</div>
        <div style={{ padding: '0 2px' }}>{toolbarSlot.zoomOutButton}</div>
        <div style={{ padding: '0 2px' }}>{toolbarSlot.zoomInButton}</div>
      </div>
    </div>
  );
};

const layout = (isSidebarOpened: boolean, container: Slot, main: Slot, toolbar: RenderToolbar, sidebar: Slot): React.ReactElement => {
  return defaultLayout(isSidebarOpened, container, main, toolbar(renderToolbar), sidebar);
};

const PDFViewerFlyout = observer(() => {
  const { flyoutStore } = useStores();
  const { t } = useTranslation('pdfFlyout');

  const closePopUp = () => flyoutStore.setPdfToView(null);

  const secureUrl = useSecureURL(flyoutStore.pdfToView?.fullUrl);

  return (
    <FlyoutContainer
      icon={<img src="images/icon_edit.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={!!flyoutStore.pdfToView}
      className="fullscreen-flyout"
      closePopUp={closePopUp}
      cancelLabel={t('cancel')}
      closeAsSubmit
      title={t('title')}
      isAllowedToSubmit
    >
      <div className="fullscreen-content">
        <div className="image-flyout-content">
          <Worker workerUrl="js/pdf.worker.js">
            {flyoutStore.pdfToView && secureUrl && (
              <Viewer fileUrl={secureUrl} layout={layout} localization={(de as unknown) as LocalizationMap} />
            )}
          </Worker>
        </div>
      </div>
    </FlyoutContainer>
  );
});

export default PDFViewerFlyout;
