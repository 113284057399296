import React from 'react';
import './ReportNavigationButton.css';

interface Props {
  onClick: () => void;
  text: string;
  icon: string;
  selected: boolean;
}

const ReportNavigationButton = ({ onClick, text, icon, selected }: Props) => {
  return (
    <div className={`btn_reports btn-report ${selected ? 'selected' : ''}`} onClick={onClick}>
      <img src={icon} alt="" className="image_mat_detail image-mat-detail" />
      <div className="text-block-6">{text}</div>
    </div>
  );
};

export default ReportNavigationButton;
