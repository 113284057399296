/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import 'reflect-metadata';
import { IsUUID, IsOptional, IsBoolean, ValidateNested, IsString } from 'class-validator';
import { Type } from 'class-transformer';
import { SimpleFunctionalAreaDTO } from './functionalArea';

export class GetSurgeryRoomsDTO {
  @IsUUID()
  @IsOptional()
  functionalAreaId?: string;

  @IsUUID()
  @IsOptional()
  locationId?: string;
}

export class SurgeryRoomIdDTO {
  @IsUUID()
  surgeryRoomId!: string;
}

export class SurgeryRoomFunctionalAreaDTO {
  @ValidateNested()
  @Type(() => SimpleFunctionalAreaDTO)
  functionalArea!: SimpleFunctionalAreaDTO;

  @IsBoolean()
  active!: boolean;
}

export class CreateSurgeryRoomDTO {
  @IsString()
  name!: string;

  @IsUUID()
  locationId!: string;
}

export class UpdateSurgeryRoomDTO {
  @IsUUID()
  surgeryRoomId!: string;

  @IsString()
  @IsOptional()
  name?: string;
}

export class SetSurgeryRoomFunctionalAreaDTO {
  @IsUUID()
  surgeryRoomId!: string;

  @IsUUID()
  functionalAreaId!: string;

  @IsBoolean()
  active!: boolean;
}

export class SurgeryRoomDTO {
  @IsUUID()
  surgeryRoomId!: string;

  @IsString()
  name!: string;
}

export class UpdateSortSurgeryRoomDTO {
  @IsUUID(undefined, { each: true })
  surgeryRoomIds!: string[];
}
