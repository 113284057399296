import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { getItemStyle } from 'util/dragAndDrop';
import { GroupedMaterialKnowledgeDTO, MaterialKnowledgeDTO } from 'dto/materialKnowledge';
import { ChapterType } from 'dto/materialChapter';
import { Draggable } from 'react-beautiful-dnd';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import Shake from 'components/Shake';
import { useTranslation } from 'react-i18next';
import { IconType } from 'dto/procedure';
import KnowledgeElementItem from '../KnowledgeElementItem';

interface Props {
  onCreate: (position?: number) => void;
  materialKnowledge: GroupedMaterialKnowledgeDTO;
  listItemAddButtons?: boolean;
  isCollapsible?: boolean;
  iconType?: IconType;
}

const KnowledgeElements = observer(({ materialKnowledge, listItemAddButtons, onCreate, isCollapsible = false, iconType }: Props) => {
  const { t } = useTranslation('material');
  const { animationStore } = useStores();
  // in Drawer
  if (isCollapsible) {
    return (
      <>
        {materialKnowledge.knowledgeElements.map((knowledgeElement: MaterialKnowledgeDTO, index) => {
          const key = knowledgeElement.materialKnowledgeElementId || knowledgeElement.materialSynonymId || index.toString();
          return (
            <div key={key}>
              {materialKnowledge.chapterType === ChapterType.Synonym
                ? listItemAddButtons &&
                  index === 0 && (
                    <ListItemAdd className="post_item_add post-item-add" onClick={() => onCreate(index)}>
                      <InfoBox label={t('content.infoBoxSynonym')} />
                    </ListItemAdd>
                  )
                : listItemAddButtons && (
                    <ListItemAdd className="post_item_add post-item-add" onClick={() => onCreate(index)}>
                      <InfoBox label={t('content.infoBox')} />
                    </ListItemAdd>
                  )}
              <Shake active={animationStore.elementIdsToShake?.indexOf(key) > -1}>
                <KnowledgeElementItem
                  materialKnowledge={materialKnowledge}
                  knowledgeElement={knowledgeElement}
                  isCollapsible={isCollapsible}
                  iconType={iconType}
                />
              </Shake>
            </div>
          );
        })}
      </>
    );
  }
  return (
    <>
      {materialKnowledge.knowledgeElements.map((knowledgeElement: MaterialKnowledgeDTO, index) => {
        const key = knowledgeElement.materialKnowledgeElementId || knowledgeElement.materialSynonymId || index.toString();
        return (
          <Draggable draggableId={key} index={index} key={key} isDragDisabled={!!knowledgeElement.materialSynonymId}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              >
                {materialKnowledge.chapterType === ChapterType.Synonym
                  ? listItemAddButtons &&
                    index === 0 && (
                      <ListItemAdd className="post_item_add post-item-add" onClick={() => onCreate(index)}>
                        <InfoBox label={t('content.infoBoxSynonym')} />
                      </ListItemAdd>
                    )
                  : listItemAddButtons && (
                      <ListItemAdd className="post_item_add post-item-add" onClick={() => onCreate(index)}>
                        <InfoBox label={t('content.infoBox')} />
                      </ListItemAdd>
                    )}
                <Shake active={animationStore.elementIdsToShake?.indexOf(key) > -1}>
                  <KnowledgeElementItem
                    materialKnowledge={materialKnowledge}
                    knowledgeElement={knowledgeElement}
                    isCollapsible={isCollapsible}
                    iconType={iconType}
                  />
                </Shake>
              </div>
            )}
          </Draggable>
        );
      })}
    </>
  );
});

export default KnowledgeElements;
