import { UserDTO, ExtendedUserDTO, AccountDTO, SecureOptionalAccountDTO, AdminOptionalAccountDTO } from 'dto/user';
import { get, post, patch } from './common';
import { validateArray, validate } from './validation';
import { TitleDTO, UpdateTitleDTO, CreateTitleDTO } from '../dto/title';

export const getAllUsers = async (): Promise<UserDTO[]> => {
  const users = await get('/users').then(response => response.data);
  return validateArray(UserDTO, users);
};

export const getAllExtendedUsers = async (): Promise<ExtendedUserDTO[]> => {
  const extendedUsers = await get('/extended-users').then(response => response.data);
  return validateArray(ExtendedUserDTO, extendedUsers);
};

/**
 * gets the User that is currently Logged in (with jwt)
 */
export const getUser = async (): Promise<UserDTO> => {
  const user = await get('/user').then(response => response.data);
  return validate(UserDTO, user);
};
/**
 * gets the ExtendedUser that is currently Logged in (with jwt)
 */
export const getExtendedUser = async (): Promise<ExtendedUserDTO> => {
  const user = await get('/extended-user').then(response => response.data);
  return validate(ExtendedUserDTO, user);
};

/**
 * only the User that is logged In is able to use this API
 */
export const updateUser = async (fieldsToUpdate: SecureOptionalAccountDTO): Promise<UserDTO> => {
  const user = await patch('/account/user', fieldsToUpdate).then(response => response.data);
  return validate(UserDTO, user);
};

/**
 * Used for User Managment Page only usable if you have Admin rights
 */
export const updateUserWithAdmin = async (fieldsToUpdate: AdminOptionalAccountDTO): Promise<UserDTO> => {
  const user = await patch('/admin/account/user', fieldsToUpdate).then(response => response.data);
  return validate(UserDTO, user);
};

/**
 * Used for User Managment Page only usable if you have Admin rights`
 * user then has to log in with /account/user/login/tempPassword instead of /account/user/login
 *
 * call loginWithTempPassword function for login and setting of new Password(removing TempPassword)
 * so a user can login normal after this has been done
 */
export const resetPasswordForUser = async (userId: string): Promise<string> => {
  const tempPassword = await patch('/admin/account/user/reset/password', { userId }).then(response => response.data);
  return tempPassword;
};

export const getAccount = async (): Promise<AccountDTO> => {
  const account = await get('/account/user').then(response => response.data);
  return validate(AccountDTO, account);
};

export const getTitles = async () => {
  const titles = await get('/titles').then(response => response.data);
  return validateArray(TitleDTO, titles);
};

export const updateTitle = async (titleToUpdate: UpdateTitleDTO) => {
  const title = await patch('/title', titleToUpdate).then(response => response.data);
  return validate(TitleDTO, title);
};

export const createTitle = async (titleToCreate: CreateTitleDTO) => {
  const title = await post('/title', titleToCreate).then(response => response.data);
  return validate(TitleDTO, title);
};

/**
 * Returns a new jwt which is generated by authenticating with the current jwt.
 */
export const refreshJwt = async () => {
  const jwt = await get('/refresh/jwt').then(response => response.data);
  return jwt;
};
