import React from 'react';
import { HistoryActionType } from 'dto/history';

interface Props {
  action: HistoryActionType;
}

const IconCreate = () => <img src="images/icon_history_add.jpg" alt="" />;
const IconDelete = () => <img src="images/icon_history_delete.jpg" alt="" />;
const IconNotify = () => <img src="images/icon_history_notify.jpg" alt="" />;
const IconEdit = () => <img src="images/icon_history_edit.jpg" alt="" />;

const ProcedureContentElementHistoryIconsFactory = ({ action }: Props) => {
  const getIcon = (actionType: HistoryActionType) => {
    if (actionType === HistoryActionType.create) {
      return <IconCreate />;
    }
    if (actionType === HistoryActionType.delete) {
      return <IconDelete />;
    }
    if (actionType === HistoryActionType.notify) {
      return <IconNotify />;
    }
    if (actionType === HistoryActionType.update) {
      return <IconEdit />;
    }
    return null;
  };

  return <div className="icon_no_location">{getIcon(action)}</div>;
};

export default ProcedureContentElementHistoryIconsFactory;
