import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import ReportGuides from './ReportGuides';
import ReportStandards from './ReportStandards';
import ReportHead from './ReportHead';
import ReportNavigation from './ReportNavigation';
import { ReportsContentType } from './types';
import './ReportsDrawer.css';

const ReportsDrawer = observer(() => {
  const { reportStore, domainStore } = useStores();
  const itemRef: React.RefObject<HTMLDivElement> = useRef(null);
  const [contentType, setContentType] = useState<ReportsContentType>(ReportsContentType.standardization);
  useEffect(() => {
    const onClose = (event: MouseEvent) => {
      if (
        reportStore.isReportDrawerOpen &&
        event.target instanceof Node &&
        !itemRef.current?.contains(event.target) &&
        !domainStore.isSwitchDomainFlyoutOpen
      ) {
        setTimeout(() => reportStore.setIsReportDrawerOpen(false));
      }
    };
    if (itemRef.current) {
      if (reportStore.isReportDrawerOpen) {
        document.body.addEventListener('click', onClose);
      } else {
        document.body.removeEventListener('click', onClose);
      }
    }

    return () => {
      document.body.removeEventListener('click', onClose);
    };
  }, [itemRef, reportStore.isReportDrawerOpen, domainStore.isSwitchDomainFlyoutOpen, reportStore]);
  return (
    <>
      <CSSTransition in={reportStore.isReportDrawerOpen} timeout={500} classNames="drawer" unmountOnExit>
        <div className="w-layout-grid reports grid-3 reports-styled" ref={itemRef}>
          <ReportHead />
          {contentType === ReportsContentType.guides && <ReportGuides />}
          {contentType === ReportsContentType.standardization && <ReportStandards />}
          <ReportNavigation setContentType={setContentType} selectedContentType={contentType} />
        </div>
      </CSSTransition>
    </>
  );
});

export default ReportsDrawer;
