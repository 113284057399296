import { useEffect } from 'react';
import { observable, action, computed, runInAction } from 'mobx';

interface Path {
  prev: string | null;
  current: string;
}

export default class AppNavigationStore {
  // rightMenuBuilder handles can be set to a component which should be used as right menu
  // it should be set using setRightMenuBuilder inside of a useEffect in the sub-pages
  // rightMenuComponent can then be used to get a current instance of the menu
  @observable
  private rightMenuBuilder: () => JSX.Element | undefined = () => undefined;

  @observable
  subPageId = '';

  @observable
  isMenuOpen = false;

  @observable
  isKeyboardOpen = false;

  @observable
  path?: Path;

  @observable
  apiConfigAvailable = false;

  @computed
  get rightMenuComponent(): JSX.Element | undefined {
    return this.rightMenuBuilder();
  }

  /**
   * useSubPageIdSetter sets the name of the subpage currently shown to set the
   * grid-id in the Page component correctly
   * Just call in the subpage component. It will set / unset the id automatically.
   * @param subPageId
   */
  @action
  useSubPageIdSetter(subPageId: string) {
    return useEffect(() => {
      runInAction(() => {
        this.subPageId = subPageId;
      });

      return () => {
        runInAction(() => {
          this.subPageId = '';
        });
      };
    });
  }

  @action
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  @action
  closeMenu() {
    this.isMenuOpen = false;
  }

  @action
  withoutMenu() {
    this.rightMenuBuilder = () => undefined;
  }

  @action
  setIsKeyboardOpen(status: boolean) {
    this.isKeyboardOpen = status;
  }

  @action
  setRightMenuBuilder(rightMenuBuilder: () => JSX.Element) {
    this.rightMenuBuilder = rightMenuBuilder;
  }

  @action
  setPath(path: Path) {
    this.path = path;
  }

  @action
  apiReady() {
    this.apiConfigAvailable = true;
  }

  // usage of type any due to history type not available
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  canGoBack(history: any): boolean {
    return !!(this.path?.prev && !this.path?.prev.includes('next') && this.path?.prev !== '/' && history.location.pathname !== '/');
  }
}
