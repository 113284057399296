import { useGetGuideMaterialsV2 } from 'api/guideHooks';
import { useGetSurgeryGuideMaterialsV2 } from 'api/surgeryGuideHooks';
import { GuideMaterialLikeFlatDTO, GuideMaterialsDTO } from 'dto/guide';
import { GuideMaterialCategoryDTO } from 'dto/guideMaterialCategory';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { GuideMaterialLikeItem } from './Guide/GuideMaterialLikeItem';
import { SurgeryGuideMaterialLikeItem } from './SurgeryGuide/SurgeryGuideMaterialLikeItem';
import { useGuideMaterialListContext } from './guideMaterialListContext';
import { CategoryPrefixRenderer } from './GuideMaterialList';

export type MaterialLikeRendererType = React.FC<{
  guideMaterial: GuideMaterialLikeFlatDTO;
  onClick?: (guideMaterial: GuideMaterialLikeFlatDTO) => void;
}>;

interface CommonProps {
  MaterialLikeRenderer: MaterialLikeRendererType;
  CategoryPrefix?: CategoryPrefixRenderer;
  guideMaterials: GuideMaterialsDTO;
  guideMaterialCategory: GuideMaterialCategoryDTO;
}

const CommonCategoryMaterialList: React.FC<CommonProps> = ({
  MaterialLikeRenderer,
  CategoryPrefix,
  guideMaterials,
  guideMaterialCategory
}) => {
  const { onGuideMaterialClick, readonly, guideLikeId } = useGuideMaterialListContext();

  return (
    <>
      <h1>{guideMaterialCategory.name}</h1>
      {CategoryPrefix && <CategoryPrefix guideId={guideLikeId} guideMaterialCategoryId={guideMaterialCategory.guideMaterialCategoryId} />}
      {guideMaterials.guideMaterials.map((gm, i) => (
        <Draggable isDragDisabled={readonly} index={i} draggableId={gm.guideMaterialId} key={gm.guideMaterialId}>
          {draggableProvided => (
            <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
              <MaterialLikeRenderer guideMaterial={gm} onClick={onGuideMaterialClick} />
            </div>
          )}
        </Draggable>
      ))}
    </>
  );
};

export const CategoryMaterialList: React.FC<Omit<CommonProps, 'guideMaterials' | 'MaterialLikeRenderer'>> = ({
  guideMaterialCategory,
  ...commonProps
}) => {
  const { guideLikeId } = useGuideMaterialListContext();

  const { data } = useGetGuideMaterialsV2({
    guideId: guideLikeId,
    guideMaterialCategoryId: guideMaterialCategory.guideMaterialCategoryId
  });

  if (!data) {
    return <></>;
  }

  return (
    <CommonCategoryMaterialList
      MaterialLikeRenderer={GuideMaterialLikeItem}
      guideMaterialCategory={guideMaterialCategory}
      guideMaterials={data}
      {...commonProps}
    />
  );
};

export const CategorySurgeryMaterialList: React.FC<Omit<CommonProps, 'guideMaterials' | 'MaterialLikeRenderer'>> = ({
  guideMaterialCategory,
  ...commonProps
}) => {
  const { guideLikeId } = useGuideMaterialListContext();

  const { data } = useGetSurgeryGuideMaterialsV2({
    surgeryGuideId: guideLikeId,
    guideMaterialCategoryId: guideMaterialCategory.guideMaterialCategoryId
  });

  if (!data) {
    return <></>;
  }

  return (
    <CommonCategoryMaterialList
      MaterialLikeRenderer={SurgeryGuideMaterialLikeItem}
      guideMaterialCategory={guideMaterialCategory}
      guideMaterials={data}
      {...commonProps}
    />
  );
};
