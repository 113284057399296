import React, { useState, useEffect } from 'react';
import './CollapsibleBox.css';

interface Props {
  isExpandedByDefault?: boolean;
  head: JSX.Element | JSX.Element[];
  valueTag?: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
  isClone?: boolean;
  onClick?: () => void;
  close?: boolean;
  headClassName?: string;
  contentClass?: string;
  noArrow?: boolean;
  noHead?: boolean;
  onChangeVisibility?: (isVisible: boolean) => void;
}

const getContentClass = (isContentShown: boolean | null): '' | 'show' | 'hide' => {
  if (isContentShown === null) return '';
  return isContentShown ? 'show' : 'hide';
};

const getHeadClass = (isContentShown: boolean | null): '' | 'content-shown' | 'content-hidden' => {
  if (isContentShown === null) return '';
  return isContentShown ? 'content-shown' : 'content-hidden';
};

const CollapsibleBox = ({
  head,
  valueTag,
  children,
  isExpandedByDefault = false,
  isClone,
  onClick,
  close,
  headClassName,
  noHead,
  noArrow = false,
  onChangeVisibility,
  contentClass = ''
}: Props) => {
  const [isContentShown, setIsContentShown] = useState<boolean | null>(isExpandedByDefault ? null : false);
  const changeContentVisibility = () => {
    if (isContentShown === null) {
      setIsContentShown(false);
      if (onChangeVisibility) onChangeVisibility(false);
    } else {
      setIsContentShown(!isContentShown);
      if (onChangeVisibility) onChangeVisibility(!isContentShown);
    }

    if (isClone && onClick) onClick();
  };
  useEffect(() => {
    setIsContentShown(isExpandedByDefault ? null : false);
  }, [isExpandedByDefault]);
  const collapsibleBoxClassName = !isClone ? 'div-block-19' : 'div-block-19 in_flyout';
  useEffect(() => {
    if (close) {
      setIsContentShown(false);
    }
  }, [close]);

  return (
    <>
      {!noHead ? (
        <div
          className={`${collapsibleBoxClassName} ${headClassName || 'dropdown_content_head'} ${getHeadClass(isContentShown)}`}
          onClick={changeContentVisibility}
        >
          {!isClone ? (
            <>
              {!noArrow && <img src="images/icon_ctrl-down_14.svg" alt="" className="icon_arrow_chapter icon-arrow" />}

              {head}
            </>
          ) : (
            <>
              <div className="div-block-55">
                {!noArrow && <img src="images/icon_ctrl-down_14.svg" alt="" className="icon_arrow_chapter icon-arrow" />}
                {head}
              </div>
              {valueTag && <>{valueTag} </>}
            </>
          )}
        </div>
      ) : (
        <></>
      )}

      <div className={`content_holder collapsible-box-content ${contentClass} ${getContentClass(isContentShown)}`}>{children}</div>
    </>
  );
};

export default CollapsibleBox;
