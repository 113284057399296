import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import NavSectionButton from 'components/NavSection/NavSectionButton';
import { useLocation } from 'react-router-dom';

interface Props {
  instrumentId: string;
}

const InstrumentNavSection = observer(({ instrumentId }: Props) => {
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;
  const { t } = useTranslation('instruments');

  return (
    <>
      <NavSectionButton
        icon="icon_single-content-02_16"
        label={t('subMenu.overview')}
        routePath={`/instrument/overview?instrumentId=${instrumentId}`}
        currentPath={currentPath}
      />
      <NavSectionButton
        icon="icon_data-table_16"
        label={t('subMenu.baseData')}
        routePath={`/instrument/detail?instrumentId=${instrumentId}`}
        currentPath={currentPath}
      />
      <NavSectionButton
        icon="icon_bulb-62_16"
        label={t('subMenu.knowledge')}
        routePath={`/instrument/content?instrumentId=${instrumentId}`}
        currentPath={currentPath}
      />
    </>
  );
});

export default InstrumentNavSection;
