import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapsibleBox from 'components/CollapsibleBox';
import { BriefingDTO } from 'dto/briefing';
import { getTimeRangeString, getTimeDifference } from 'util/date';
import './SurgeryPatient.css';
import { observer } from 'mobx-react';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Picture from 'components/Picture';
import { useStores } from 'util/mobx/stores';
import HoverWrapper from 'components/HoverWrapper';
import PatientHead from './PatientHead';

interface Props {
  briefing: BriefingDTO;
}

const SurgeryPatient = observer(({ briefing }: Props) => {
  const { t } = useTranslation('surgery');
  const { t: tDate } = useTranslation('date');
  const { surgeryStore, flyoutStore } = useStores();

  const onUpdateFile = (patientPictureFileId: string) => {
    surgeryStore.updateSurgery({ surgeryId: briefing.surgery.surgeryId, patientPictureFileId });
    return surgeryStore.refreshBriefing();
  };

  const handleAddImagesToView = () => {
    if (briefing.surgery.patientPicture) {
      flyoutStore.setImagesToView(briefing.surgery.patientPicture.picture);
    }
  };
  return (
    <CollapsibleBox
      headClassName="dropdown_content_head dropdown_content_head_open in_operation briefing-dropdown"
      contentClass="in_operation"
      isExpandedByDefault
      head={<PatientHead patient={briefing.surgery} />}
    >
      <div className="briefing-notes">
        {briefing.surgery.surgeryTimeStart && (
          <>
            <strong>{t('briefing.labels.time')}:</strong>{' '}
            {getTimeRangeString(briefing.surgery.surgeryDate, tDate, briefing.surgery.surgeryTimeStart, briefing.surgery.surgeryTimeEnd)}
            <br />
          </>
        )}
        {briefing.surgery.surgeryTimeStart && briefing.surgery.surgeryTimeEnd && (
          <>
            <strong>{t('briefing.labels.duration')}:</strong>{' '}
            {getTimeDifference(briefing.surgery.surgeryDate, briefing.surgery.surgeryTimeStart, briefing.surgery.surgeryTimeEnd)}{' '}
            {tDate('minutes')}
          </>
        )}
        <br />
        <strong>{t('briefing.labels.id')}:</strong> {briefing.surgery.patientNumber}
        <br />
        <strong>{t('briefing.labels.caseNumber')}:</strong> {briefing.surgery.caseNumber}
        <br />
        <strong>{t('briefing.labels.comment')}:</strong> {briefing.surgery.comment}
      </div>
      <HoverWrapper className="image_wrapper_50 in_patien_detail" onClick={handleAddImagesToView}>
        {({ hover }) =>
          briefing.surgery.patientPicture ? (
            <Picture src={briefing.surgery.patientPicture.pictureThumbnail} width={50} alt="" className="image_circle_50 icon-pointer" />
          ) : (
            <GetCameraImageButton
              buttonStyle="userIcon"
              onUpdateFile={onUpdateFile}
              description=""
              hover={hover}
              picturesOnly
              isAddable
              withLoadingBar
            />
          )
        }
      </HoverWrapper>
    </CollapsibleBox>
  );
});

export default SurgeryPatient;
