import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useStores } from 'util/mobx/stores';
import VersionNumber from 'components/VersionNumber/VersionNumber';
import SurgeryRoomsList from './surgeryRoomsList';
import LocationsList from './locationsList';
import SurgeryRoomFunctionalAreasList from './SurgeryRoomFunctionalAreasList';
import './SettingsSurgeryRooms.css';

const SettingsSurgeryRooms = observer(() => {
  const { settingsStore, appNavigationStore } = useStores();
  const [showSurgeryRooms, setShowSurgeryRooms] = useState(false);
  const [showSurgeryRoomFunctionalAreas, setShowSurgeryRoomFunctionalAreas] = useState(false);

  useEffect(() => {
    settingsStore.loadLocations();
  }, [settingsStore]);

  appNavigationStore.useSubPageIdSetter('rooms');

  return (
    <>
      <div className="w-layout-grid grid_colum_content_lagerorte">
        <div className="colum_left border left">
          <div className="div-block-143 no-scroll">
            <LocationsList setShowSurgeryRooms={setShowSurgeryRooms} />
            {showSurgeryRooms ? <SurgeryRoomsList setShowSurgeryRoomFunctionalAreas={setShowSurgeryRoomFunctionalAreas} /> : <></>}
          </div>
        </div>
        <div className="colum_right">
          {showSurgeryRoomFunctionalAreas && settingsStore.selectedSurgeryRoom ? <SurgeryRoomFunctionalAreasList /> : <></>}
        </div>
      </div>
      <VersionNumber type="mobile" />
    </>
  );
});

export default SettingsSurgeryRooms;
