import {
  GetDepartmentMaintainerDTO,
  SetDepartmentMaintainerDTO,
  DepartmentMaintainerDTO,
  DeleteDepartmentMaintainerDTO
} from 'dto/departmentMaintainer';
import { get, post, del } from './common';
import { validateArray, validate, validateBool } from './validation';

export const getDepartmentMaintainers = async (departmentId: string) => {
  const departments = await get('/department/maintainers', { departmentId }).then(response => response.data);
  return validateArray(DepartmentMaintainerDTO, departments);
};

export const getDepartmentMaintainer = async (departmentMaintainer: GetDepartmentMaintainerDTO) => {
  const departments = await get('/department/maintainer', departmentMaintainer).then(response => response.data);
  return validateArray(DepartmentMaintainerDTO, departments);
};

export const getDepartmentMaintainerLead = async (departmentId: string) => {
  const departments = await get('/department/maintainer/lead', { departmentId }).then(response => response.data);
  return validate(DepartmentMaintainerDTO, departments);
};

/* updates maintainer isLead if maintainer already exists, otherwise new maintainer is created */
export const setDepartmentMaintainer = async (departmentMaintainerToSet: SetDepartmentMaintainerDTO) => {
  const department = await post('/department/maintainer', departmentMaintainerToSet).then(response => response.data);
  return validate(DepartmentMaintainerDTO, department);
};

export const deleteDepartmentMaintainer = async (departmentMaintainerToDelete: DeleteDepartmentMaintainerDTO) => {
  return del('/department/maintainer', departmentMaintainerToDelete).then(response => validateBool(response.data));
};
