import React from 'react';
import './AppBarHeaderText.css';

interface Props {
  headline: string;
  subHeadline?: string;
  onClick?: () => void;
}

const AppBarHeaderText = ({ headline, subHeadline, onClick }: Props) => {
  return (
    <div className={`title_info app-bar-header-text ${onClick ? '' : 'no-click'}`} onClick={onClick}>
      <div>{headline}</div>
      {subHeadline && <div className="t2 top-margin">{subHeadline}</div>}
    </div>
  );
};
export default AppBarHeaderText;
