import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import { SettingsItemTypes } from 'stores/settingsStore';
import SettingsItem from 'components/SettingsItem';
import { useStores } from 'util/mobx/stores';
import { TitleDTO } from '../../dto/title';

const AccountManagementTitles = observer(() => {
  const [titles, setTitles] = useState<TitleDTO[]>();
  const { t: translationSettings } = useTranslation('settings');
  const { t: translationAccountManagement } = useTranslation('accountManagement');
  const { settingsStore } = useStores();

  useEffect(() => {
    settingsStore.setItemFormFlyoutTitles(
      translationSettings('formFlyout.accountManagementUpdateTitle'),
      translationSettings('formFlyout.accountManagementCreateTitle')
    );
    const loadData = async () => {
      await settingsStore.loadAccountManagementTitles();
    };
    loadData();
  }, [settingsStore, translationSettings]);

  useEffect(() => {
    setTitles(settingsStore.accountManagementTitles);
  }, [settingsStore.accountManagementTitles]);

  const onCreateFlyoutOpen = () => {
    settingsStore.setItemFormFlyoutType(SettingsItemTypes.accountManagementTitle);
    settingsStore.setItemFormFlyoutOpen(true);
  };

  return (
    <div className="div-block-127">
      <div className="div-block-79-copy">
        <h1>{translationAccountManagement('titles.headline')}</h1>
        <ListItemAdd className="post_item_add post-item-add" onClick={onCreateFlyoutOpen}>
          <InfoBox label={translationAccountManagement('titles.createTitle')} />
        </ListItemAdd>
        <div className="div-block-80-copy">
          <div className="div-block-82">
            {titles &&
              titles.map(title => (
                <SettingsItem
                  itemId={title.titleId}
                  key={title.titleId}
                  name={title.name}
                  type={SettingsItemTypes.accountManagementTitle}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default AccountManagementTitles;
