/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { Type } from 'class-transformer';
import { IsBoolean, IsEnum, IsInt, IsNumber, IsOptional, IsString, IsUUID, Max, Min, ValidateNested } from 'class-validator';
import 'reflect-metadata';
import { PictureDTO } from './file';
import { SurgeryStatisticDTO } from './statistic';
import { SurgeryRoomDTO } from './surgeryRoom';
import { Gender } from './user';

export class DateDTO {
  @IsInt()
  year!: number;

  /**
   * month starts at 0 to be compatible with the js Date
   */
  @IsInt()
  @Min(0)
  @Max(11)
  month!: number;

  @IsInt()
  @Min(1)
  @Max(31)
  dayInMonth!: number;
}

export class UTCTimeDTO {
  @IsInt()
  @Min(0)
  @Max(23)
  hour!: number;

  @IsInt()
  @Min(0)
  @Max(59)
  minute!: number;

  @IsInt()
  @Min(0)
  @Max(59)
  second!: number;
}

export class GetSurgeriesDTO extends DateDTO {
  /**
   * If provided, the surgeries are selected for this timezone instead of UTC.
   */
  @IsString()
  @IsOptional()
  timezone?: string;

  @IsUUID()
  functionalAreaId!: string;

  @IsBoolean()
  includeStatistic!: boolean;
}

export class SurgeryPatientDTO {
  @IsString()
  @IsOptional()
  patientNumber?: string;

  @IsString()
  @IsOptional()
  firstName?: string;

  @IsString()
  @IsOptional()
  lastName?: string;

  @IsEnum(Gender)
  @IsOptional()
  gender?: Gender;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDTO)
  birthday?: DateDTO;

  @ValidateNested()
  @IsOptional()
  @Type(() => PictureDTO)
  patientPicture?: PictureDTO;
}

export class SurgeryDTO extends SurgeryPatientDTO {
  @IsUUID()
  surgeryId!: string;

  @IsString()
  name!: string;

  @ValidateNested()
  @Type(() => DateDTO)
  surgeryDate!: DateDTO;

  @IsString()
  @IsOptional()
  caseNumber?: string;

  @IsUUID()
  surgeryRoomId!: string;

  @ValidateNested()
  @Type(() => UTCTimeDTO)
  @IsOptional()
  surgeryTimeStart?: UTCTimeDTO;

  @ValidateNested()
  @Type(() => UTCTimeDTO)
  @IsOptional()
  surgeryTimeEnd?: UTCTimeDTO;

  @IsString()
  @IsOptional()
  doctorFullName?: string;

  @IsString()
  @IsOptional()
  comment?: string;

  /**
   * postCount is the number of posts related to the surgery and current functional area (-> to the briefing).
   * It may not be always used. If it is undefined, the postCount is not loaded.
   * If it is 0 the count is loaded but there are no posts.
   */
  @IsNumber()
  @IsOptional()
  postCount?: number;

  /**
   * statistic may be undefined if it is not loaded
   */
  @ValidateNested()
  @Type(() => SurgeryStatisticDTO)
  statistic?: SurgeryStatisticDTO;

  /**
   * "Fallwagennummern" of the guides in the surgery briefing (empty array if the briefing is not included)
   */
  @IsInt({ each: true })
  surgeryGuideNumbers!: number[];
}

export class RoomSurgeriesDTO {
  @ValidateNested()
  @Type(() => SurgeryRoomDTO)
  room!: SurgeryRoomDTO;

  @ValidateNested({ each: true })
  @Type(() => SurgeryDTO)
  surgeries!: SurgeryDTO[];
}

export class UpdateSurgeryPatientDTO {
  @IsString()
  @IsOptional()
  patientNumber?: string;

  @IsString()
  @IsOptional()
  firstName?: string;

  @IsString()
  @IsOptional()
  lastName?: string;

  @IsEnum(Gender)
  @IsOptional()
  gender?: Gender;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDTO)
  birthday?: DateDTO;

  @IsUUID()
  @IsOptional()
  patientPictureFileId?: string;
}

export class CreateSurgeryDTO extends UpdateSurgeryPatientDTO {
  @IsString()
  name!: string;

  @IsUUID()
  surgeryRoomId!: string;

  @ValidateNested()
  @Type(() => DateDTO)
  surgeryDate!: DateDTO;

  @ValidateNested()
  @Type(() => UTCTimeDTO)
  @IsOptional()
  surgeryTimeStart?: UTCTimeDTO;

  @ValidateNested()
  @Type(() => UTCTimeDTO)
  @IsOptional()
  surgeryTimeEnd?: UTCTimeDTO;

  @IsString()
  @IsOptional()
  caseNumber?: string;

  @IsString()
  @IsOptional()
  doctorFullName?: string;

  @IsString()
  @IsOptional()
  comment?: string;
}

export class UpdateSurgeryDTO extends UpdateSurgeryPatientDTO {
  @IsUUID()
  surgeryId!: string;

  @IsString()
  @IsOptional()
  name?: string;

  @ValidateNested()
  @Type(() => DateDTO)
  @IsOptional()
  surgeryDate?: DateDTO;

  @ValidateNested()
  @Type(() => UTCTimeDTO)
  @IsOptional()
  surgeryTimeStart?: UTCTimeDTO;

  @ValidateNested()
  @Type(() => UTCTimeDTO)
  @IsOptional()
  surgeryTimeEnd?: UTCTimeDTO;

  @IsString()
  @IsOptional()
  caseNumber?: string;

  @IsString()
  @IsOptional()
  doctorFullName?: string;

  @IsString()
  @IsOptional()
  comment?: string;

  @IsUUID()
  @IsOptional()
  surgeryRoomId?: string;
}

export class UpdateSortSurgeryDTO {
  @IsUUID()
  surgeryRoomId!: string;

  @IsUUID(undefined, { each: true })
  surgeryIds!: string[];
}
