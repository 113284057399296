import React from 'react';

interface Props {
  label: string;
  onClick?: (event: React.MouseEvent) => void;
}

export default (props: Props) => (
  <div className="info_box" onClick={props.onClick}>
    <div>{props.label}</div>
  </div>
);
