import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Lottie, { LottieProps } from 'react-lottie';
import { CheckStatus, CreateCheckedMaterialSetGroupsDTO, CreateCheckedGroupMaterialDTO } from 'dto/materialSet';
import { useStores } from 'util/mobx/stores';
import animationData from './animationData.json';
import './CheckListItemLottie.css';

interface Props extends React.HTMLProps<HTMLDivElement> {
  setGroupId?: string;
  groupMaterialId?: string;
  onStatusChanged: (status: CheckStatus) => void;
  notEditable?: boolean;
  inSublist?: boolean;
}

const getStatus = (list: CreateCheckedMaterialSetGroupsDTO[], setGroupId?: string, groupMaterialId?: string) => {
  if (setGroupId) {
    const setGroup = list.find(group => group.setGroupId === setGroupId);
    return setGroup?.status === CheckStatus.Ok;
  }
  if (groupMaterialId) {
    let groupMaterialToFind: CreateCheckedGroupMaterialDTO = {
      groupMaterialId: '',
      status: CheckStatus.Missing
    };
    list.forEach(group => {
      const groupMaterial = group.groupMaterials.find(gm => gm.groupMaterialId === groupMaterialId);
      if (groupMaterial) {
        groupMaterialToFind = groupMaterial;
      }
    });
    return groupMaterialToFind.status === CheckStatus.Ok;
  }
  return false;
};

const CheckListItemLottie = observer(
  ({ setGroupId, groupMaterialId, onStatusChanged, notEditable = false, inSublist = false, ...rest }: Props) => {
    const { materialSetsStore } = useStores();
    const [isAnimationPaused, setIsAnimationPaused] = useState(true);
    const defaultOptions: LottieProps['options'] = {
      autoplay: false,
      loop: false,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const status = getStatus(materialSetsStore.checkedSetGroupsToCreate, setGroupId, groupMaterialId);

    const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();

      if (notEditable) {
        return;
      }
      setIsAnimationPaused(false);
      onStatusChanged(status ? CheckStatus.Missing : CheckStatus.Ok);
    };

    if (notEditable && status) {
      return (
        <div
          className={`lottie-check-container controll_check ${inSublist ? 'in_sublist' : ''} ${
            materialSetsStore.isCheckListVisible ? 'visible' : 'hidden'
          }`}
        >
          <img src="images/checked-icon.svg" alt="" />
        </div>
      );
    }

    return (
      <div
        onClick={onClick}
        className={`lottie-check-container btn_checkbox ${inSublist ? 'in_sublist' : ''} ${notEditable ? 'not-editable' : ''} ${
          materialSetsStore.isCheckListVisible ? 'visible' : 'hidden'
        }`}
        {...rest}
      >
        <Lottie
          key={setGroupId || groupMaterialId}
          options={defaultOptions}
          direction={status ? 1 : -1}
          speed={1.5}
          isPaused={isAnimationPaused}
          eventListeners={[
            {
              eventName: 'complete',
              callback: () => setIsAnimationPaused(true)
            }
          ]}
          isClickToPauseDisabled={notEditable}
        >
          <img src="images/check-white.svg" alt="" />
        </Lottie>
      </div>
    );
  }
);

export default CheckListItemLottie;
