import React from 'react';
import { PostType } from 'dto/post';
import { useTranslation } from 'react-i18next';

interface Props {
  postType: PostType;
}

const BoardPostHeader = ({ postType }: Props) => {
  const { t } = useTranslation('board');
  return (
    <div className="b_box_head">
      <div className="b_update_status">
        <img src="images/icon_edit.jpg" width="50" alt="" className="image_circle_20" />
        <div className="txt_dropout_1">{postType === PostType.ProcedureUpdate ? t('post.procedureAdjustment') : t('post.comment')}</div>
      </div>
    </div>
  );
};

export default BoardPostHeader;
