import React, { useState, useEffect, useRef } from 'react';
import './Popover.css';

interface ChildrenProps {
  isOpen: boolean | undefined;
  handleClose: () => void;
}

interface Props {
  isOpen?: boolean;
  className?: string;
  children: (data: ChildrenProps) => React.ReactNode;
  trigger: JSX.Element;
}

const Popover = (props: Props) => {
  const itemRef: React.RefObject<HTMLDivElement> = useRef(null);
  const [isOpen, setIsOpen] = useState(props.isOpen);

  const onOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    const onClose = (event: MouseEvent) => {
      if (isOpen && event.target instanceof Node && !itemRef.current?.contains(event.target)) {
        setTimeout(() => setIsOpen(false));
      }
    };

    if (itemRef.current) {
      if (isOpen) {
        document.body.addEventListener('click', onClose);
      } else {
        document.body.removeEventListener('click', onClose);
      }
    }

    return () => {
      document.body.removeEventListener('click', onClose);
    };
  }, [itemRef, isOpen]);

  return (
    <>
      {props.trigger && React.cloneElement(props.trigger, { onClick: onOpen, 'data-type': 'trigger' })}
      <div ref={itemRef} className={`custom-popover ${props.className || ''} ${isOpen ? 'show' : 'hide'}`}>
        {props.children({ handleClose, isOpen })}
      </div>
    </>
  );
};

export default Popover;
