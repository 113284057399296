import { useInvalidateAnyGuide, useInvalidateMaterialLike } from 'api/invalidate';
import Button from 'components/Form/Button';
import { GuideMaterialLikeDTO } from 'dto/guide';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MergedGuideMaterialDTO } from 'stores/guideDetailDrawerStore';
import { useStores } from 'util/mobx/stores';
import '../Settings.css';
import GuideSettingsForm from './GuideSettingsForm';

interface Props {
  onSettingsClose: () => void;
  guideMaterial?: GuideMaterialLikeDTO;
  surgeryGuideMaterial?: SurgeryGuideMaterialLikeDTO;
  hideStorageLocation?: boolean;
}

const GuideSettings = observer(({ guideMaterial, surgeryGuideMaterial, hideStorageLocation = false, onSettingsClose }: Props) => {
  const { t } = useTranslation('guideSettings');
  const { guideDetailDrawerStore, guideStore } = useStores();
  const invalidateGuide = useInvalidateAnyGuide();
  const invalidateMaterials = useInvalidateMaterialLike();

  const guideItem: MergedGuideMaterialDTO | undefined = guideMaterial || surgeryGuideMaterial;

  if (!guideItem) {
    return null;
  }

  const handleRefreshGuideMaterials = async () => {
    if (guideDetailDrawerStore.reloadWithUseQuery) {
      await Promise.all([invalidateGuide(), invalidateMaterials()]);
    } else {
      await guideStore.refreshGuideMaterials();
    }
  };

  const handleDeleteGuideMaterial = () => {
    if (guideMaterial) {
      guideDetailDrawerStore.deleteGuideMaterial(guideMaterial.guideMaterialId, handleRefreshGuideMaterials);
    }
    guideDetailDrawerStore.setIsOpen(false);
  };

  return (
    <div className="mat_settings_wrapper guide-settings">
      <div className="mat_settings_header white-backgroud">
        <div className="div-block-25 mat_head">
          <img src="images/icon_settings-gear_16.svg" alt="" className="image_function" />
          <div>{guideMaterial ? t('headline') : t('headlineForSurgeryMaterial')}</div>
        </div>
        <div className="close_icon_mobile" onClick={onSettingsClose}>
          <img src="images/icon_e-remove_16.svg" alt="" />
        </div>
      </div>
      <GuideSettingsForm guideItem={guideItem} hideStorageLocation={hideStorageLocation} />
      <div className="mat_settings_header">
        <img src="images/icon_settings-gear_16.svg" alt="" className="image_function" />
        <div>{t('functions')}</div>
      </div>
      {guideMaterial ? (
        <div className="setting_wrapper function">
          <Button className="btn_materialset_detail delete-button w-inline-block" onClick={handleDeleteGuideMaterial}>
            <img src="images/icon_bin_16.svg" alt="" className="image_mat_detail" />
            <div className="text-block-6-copy">{t('button.delete')}</div>
          </Button>
        </div>
      ) : (
        <div className="delete-text">{t('surgeryDeleteDescription')}</div>
      )}
    </div>
  );
});

export default GuideSettings;
