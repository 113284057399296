import React from 'react';
import AppNavigationStore from 'stores/appNavigationStore';
import GuideStore from 'stores/guideStore';
import DomainStore from 'stores/domainStore';
import MaterialStore from 'stores/materialStore';
import ProcedureStore from 'stores/procedureStore';
import ReportStore from 'stores/reportStore';
import FlyoutStore from 'stores/flyoutStore';
import PostStore from 'stores/postStore';
import SettingsStore from 'stores/settingsStore';
import InstrumentStore from 'stores/instrumentStore';
import AnimationStore from 'stores/animationStore';
import SearchStore from 'stores/searchStore';
import GuideDetailDrawerStore from 'stores/guideDetailDrawerStore';
import MaterialSetsStore from 'stores/materialSetsStore';
import KnowledgeStore from 'stores/knowledgeStore';
import StorageLocationStore from 'stores/storageLocationStore';
import LoadingStore from 'stores/loadingStore';
import UserStore from 'stores/userStore';
import SurgeryStore from 'stores/surgeryStore';
import SurgeryGuideStore from 'stores/surgeryGuideStore';
import ErrorStore from 'stores/errorStore';
import { MobXProviderContext } from 'mobx-react';
import AuthStore from 'stores/authStore';

export default interface Stores {
  appNavigationStore: AppNavigationStore;
  guideStore: GuideStore;
  domainStore: DomainStore;
  materialStore: MaterialStore;
  procedureStore: ProcedureStore;
  reportStore: ReportStore;
  flyoutStore: FlyoutStore;
  postStore: PostStore;
  settingsStore: SettingsStore;
  instrumentStore: InstrumentStore;
  animationStore: AnimationStore;
  searchStore: SearchStore;
  guideDetailDrawerStore: GuideDetailDrawerStore;
  materialSetsStore: MaterialSetsStore;
  knowledgeStore: KnowledgeStore;
  storageLocationStore: StorageLocationStore;
  loadingStore: LoadingStore;
  userStore: UserStore;
  surgeryStore: SurgeryStore;
  surgeryGuideStore: SurgeryGuideStore;
  authStore: AuthStore;
  errorStore: ErrorStore;
}

export function useStores(): Stores {
  return React.useContext(MobXProviderContext) as Stores;
}
