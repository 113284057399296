/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { ValidateNested, IsString, IsNotEmptyObject, IsUUID, IsNumber, IsOptional, IsBoolean } from 'class-validator';
import { Type } from 'class-transformer';
import 'reflect-metadata';
import { LocationDTO } from './location';
import { DepartmentDTO, DepartmentUsageReportDTO } from './department';

export class SimpleFunctionalAreaDTO {
  @IsUUID()
  functionalAreaId!: string;

  @IsString()
  name!: string;
}

export class FunctionalAreaDTO extends SimpleFunctionalAreaDTO {
  @IsUUID()
  functionalAreaId!: string;

  @IsString()
  shortName!: string;

  @IsString()
  name!: string;

  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => LocationDTO)
  location!: LocationDTO;

  @IsBoolean()
  disabled!: boolean;

  @ValidateNested()
  @IsOptional()
  @Type(() => DepartmentDTO)
  departments?: DepartmentDTO[];
}

export class CreateFunctionalAreaDTO {
  @IsString()
  shortName!: string;

  @IsString()
  name!: string;

  @IsUUID()
  locationId!: string;
}

export class UpdateFunctionalAreaDTO {
  @IsUUID()
  functionalAreaId!: string;

  @IsString()
  @IsOptional()
  shortName?: string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsBoolean()
  @IsOptional()
  disabled?: boolean;
}

export class FunctionalAreaUsageReportDTO {
  @IsUUID()
  functionalAreaId!: string;

  @IsString()
  name!: string;

  @IsString()
  shortName!: string;

  @IsNumber()
  handbooksCountInFunctionalArea!: number;

  @ValidateNested()
  @Type(() => DepartmentUsageReportDTO)
  departments!: DepartmentUsageReportDTO[];
}

export class FunctionalAreaDepartmentReportDTO {
  @IsUUID()
  departmentId!: string;

  @IsString()
  departmentName!: string;

  @IsNumber()
  ratioOfFilledGuideChapters!: number;
}
