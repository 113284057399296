import React from 'react';
import ImageCircle from 'components/ImageCircle';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import moment from 'moment';
import { dateFormat, timeFormat } from 'util/date';
import { useTranslation } from 'react-i18next';
import LoadingIcon from 'components/LoadingIcon';
import Button from 'components/Form/Button';
import './CheckListAuthorBox.css';

interface Props {
  groupType: string;
  onSubmit: () => Promise<void>;
}

const CheckListAuthorBox = observer(({ groupType, onSubmit }: Props) => {
  const { domainStore, materialSetsStore } = useStores();
  const { t } = useTranslation(groupType);
  const { t: dateTranslate } = useTranslation('date');

  const onCheckListSubmit = () => {
    onSubmit();
    materialSetsStore.setIsCheckListVisible(false);
  };
  return (
    <div className="time_stamp_checked check-list-author-box">
      <div className="div-block-107">
        <div className="dot" />
      </div>
      <div className="div-block-106">
        {materialSetsStore.selectedSetCheck ? (
          <div className="saved check-list-buttons">
            <Button className="btn_pack_done" onClick={() => materialSetsStore.setIsCheckListVisible(false)}>
              <img src="images/icon_f-remove_16_white.svg" alt="" className="image_pack_done" />
              {t('groupsList.button.cancelViewMode')}
            </Button>
          </div>
        ) : (
          <div className="saved check-list-buttons">
            {!domainStore.isMobile && (
              <Button className="tag_pack_saving desktop-only">
                <LoadingIcon />
                {t('groupsList.button.controlInProgress')}
              </Button>
            )}
            <Button className="tag_pack_saving cancel-button" onClick={() => materialSetsStore.setIsCheckListVisible(false)}>
              <img src="images/icon_f-remove_16_white.svg" alt="" className="image_pack_done" />
              {t('groupsList.button.cancelControl')}
            </Button>
            <Button className="btn_pack_done" onClick={onCheckListSubmit}>
              <img src="images/icon_checkbox_check_16_white.svg" alt="" className="image_pack_done" />
              {t('groupsList.button.endControl')}
            </Button>
          </div>
        )}

        {materialSetsStore.selectedSetCheck && (
          <>
            <ImageCircle user={materialSetsStore.selectedSetCheck.user} size="50" highlight={false} />
            <div className="item_time_stamp">
              <div className="t2 bottom-margin">
                {t('groupsList.controlOf')} {moment(materialSetsStore.selectedSetCheck.createdAt).format(`${dateFormat} ${timeFormat}`)}{' '}
                {dateTranslate('time')}
              </div>
              <div>
                {materialSetsStore.selectedSetCheck.user.lastName}, {materialSetsStore.selectedSetCheck.user.firstName}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default CheckListAuthorBox;
