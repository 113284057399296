import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';

interface Props {
  inMaterials?: boolean;
}

const UsedGuide = observer(({ inMaterials = false }: Props) => {
  const { surgeryGuideStore } = useStores();
  const { t } = useTranslation('surgery');
  return (
    <div>
      <div className={`used_book ${inMaterials ? 'book-in-materials' : 'in_prozplan'} `}>
        <div className="div-block-107-copy">
          <div className="dot" />
        </div>
        <div className="div-block-106 grey">
          <div className="h3">{t('guideItem.sourceGuide')}</div>
          <div className="div-block-159">
            <div className="image_wrapper_50">
              <img src="images/icon_book.jpg" alt="" className="image_circle_50" />
            </div>
            <div>{surgeryGuideStore.surgeryGuide?.guide.name}</div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default UsedGuide;
