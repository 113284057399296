import React, { useState, useEffect } from 'react';
import Form, { InputField, Select } from 'components/Form';
import { ErrorMessage } from 'formik';
import * as Yup from 'yup';
import UploadFiles from 'components/UploadFiles';
import { ContentType } from 'dto/file';
import { unsafeSaveFileUser } from 'api/file';
import { useTranslation } from 'react-i18next';
import { signupUser } from 'api/auth';
import { getTitles } from 'api/user';
import Button from 'components/Form/Button';
import { GenderDropdownOptionsSelection } from 'util/userUtils';
import { TitleDTO } from '../../dto/title';
import { RegisterDTO, Gender } from '../../dto/user';
import './SignupForm.css';

interface Props {
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSignup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAuthMessage: React.Dispatch<React.SetStateAction<boolean>>;
  setWithSignupMessage: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}

const SignupForm = ({ setShowLogin, setShowSignup, setShowAuthMessage, setWithSignupMessage, show = false }: Props) => {
  const { t: translationSignup } = useTranslation('signup');
  const { t: translationLogin } = useTranslation('login');

  const [imageId, setImageId] = useState<string | undefined>(undefined);
  const [titles, setTitles] = useState<TitleDTO[]>();

  useEffect(() => {
    getTitles().then(setTitles);
  }, []);

  useEffect(() => {
    setImageId(undefined);
  }, [show]);

  const switchToLogin = () => {
    setShowSignup(false);
    setShowLogin(true);
  };

  const initialValues: RegisterDTO = {
    firstName: '',
    lastName: '',
    titleId: '',
    username: '',
    password: '',
    emailAddress: '',
    phoneNumber: '',
    salutationKey: Gender.Female,
    pictureFileId: ''
  };

  const validationSchema = Yup.object({
    salutationKey: Yup.number()
      .required(translationSignup('validation.fieldRequired'))
      .max(255, translationSignup('validation.textLength')),
    firstName: Yup.string()
      .required(translationSignup('validation.fieldRequired'))
      .max(255, translationSignup('validation.textLength')),
    lastName: Yup.string()
      .required(translationSignup('validation.fieldRequired'))
      .max(255, translationSignup('validation.textLength')),
    titleId: Yup.string()
      .required(translationSignup('validation.fieldRequired'))
      .max(255, translationSignup('validation.textLength')),
    username: Yup.string()
      .required(translationSignup('validation.fieldRequired'))
      .min(4, translationSignup('validation.usernameMinLength'))
      .max(20, translationSignup('validation.usernameMaxLength')),
    password: Yup.string()
      .required(translationSignup('validation.fieldRequired'))
      .min(8, translationSignup('validation.passwordLength'))
      .max(255, translationSignup('validation.textLength')),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password')], translationSignup('validation.passwordsMatch'))
      .required(translationSignup('validation.fieldRequired')),
    emailAddress: Yup.string()
      .email(translationSignup('validation.invalidEmailAddress'))
      .max(255, translationSignup('validation.textLength'))
  });

  const titleOptions = titles && titles.map(title => ({ label: title.name, value: title.titleId }));

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  const handleSubmitMyForm = () => {
    if (submitMyForm) submitMyForm();
  };

  const onSubmit = (values: RegisterDTO) => {
    const newValues = {
      ...initialValues,
      ...values,
      passwordConfirm: undefined,
      pictureFileId: imageId,
      emailAddress: values.emailAddress ? values.emailAddress : undefined,
      phoneNumber: values.phoneNumber ? values.phoneNumber : undefined
    };

    signupUser({ ...newValues }).then(() => {
      setShowSignup(false);
      setWithSignupMessage(true);
      setShowAuthMessage(true);
    });
  };

  return (
    <>
      {titles ? (
        <div id="w-node-813f77523eee-1858301a" className={`div-block-170 ${!show ? 'hide' : ''}`}>
          <div className="registrieren shown-by-default">
            <div className="div-block-171">
              <img src="images/logo_klinik-puls.svg" alt="" className="logo_desktop-copy" />
              <div className="txt_logo-copy">
                <strong>{translationLogin('logoTextFirstPart')}</strong>
                {translationLogin('logoTextSecondPart')}
              </div>
            </div>
            <div className="div-block-181">
              {show ? ( // force re-render of form when show changes to true
                <Form
                  className="reg_form"
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  bindSubmitForm={bindSubmitForm}
                  validationSchema={validationSchema}
                >
                  {props => {
                    return (
                      <>
                        <div className="headline-login">{translationSignup('headline')}</div>
                        <UploadFiles
                          setImageId={setImageId}
                          cameraImageButtonDescription={translationSignup('imageButton')}
                          cameraImageButtonDescriptionAlready={translationSignup('imageButtonImageAlreadyThere')}
                          saveFile={unsafeSaveFileUser}
                          picturesOnly
                          accept={[ContentType.Picture]}
                          withoutHelper
                          withoutGet
                          userButtonStyle
                        />

                        <div className="div-block-179">
                          <div className="login_input_box">
                            <label className="field-label-2" htmlFor="salutationKey">
                              {translationSignup('salutationLabel')}
                            </label>
                            <InputField
                              className="input_dropdown top-margin"
                              as="select"
                              name="salutationKey"
                              component={Select}
                              mappedOptions={GenderDropdownOptionsSelection}
                            />
                          </div>
                          <div className="login_input_box">
                            <label className="field-label-2" htmlFor="titleId">
                              {translationSignup('title')}
                            </label>
                            <InputField
                              className="input_dropdown top-margin"
                              as="select"
                              name="titleId"
                              component={Select}
                              mappedOptions={titleOptions}
                              placeholder={translationSignup('titlePlaceholder')}
                            />
                            <ErrorMessage component={errProps => <div {...errProps} />} className="field_error" name="titleId" />
                          </div>

                          <div className="login_input_box">
                            <label className="field-label-2" htmlFor="firstName">
                              {translationSignup('firstName')}
                            </label>
                            <InputField name="firstName" viewOnly={false} />
                            <ErrorMessage component={errProps => <div {...errProps} />} className="field_error" name="firstName" />
                          </div>
                          <div className="login_input_box">
                            <label className="field-label-2" htmlFor="lastName">
                              {translationSignup('lastName')}
                            </label>
                            <InputField name="lastName" viewOnly={false} />
                            <ErrorMessage component={errProps => <div {...errProps} />} className="field_error" name="lastName" />
                          </div>
                          <div className="login_input_box">
                            <label className="field-label-2" htmlFor="emailAddress">
                              {translationSignup('email')}
                            </label>
                            <InputField name="emailAddress" type="email" viewOnly={false} />
                            <ErrorMessage component={errProps => <div {...errProps} />} className="field_error" name="emailAddress" />
                          </div>
                          <div className="login_input_box">
                            <label className="field-label-2" htmlFor="phoneNumber">
                              {translationSignup('phoneNumber')}
                            </label>
                            <InputField name="phoneNumber" viewOnly={false} />
                          </div>
                          <div className="login_input_box">
                            <label className="field-label-2" htmlFor="username">
                              {translationSignup('username')}
                            </label>
                            <InputField name="username" viewOnly={false} />
                            <ErrorMessage component={errProps => <div {...errProps} />} className="field_error" name="username" />
                          </div>
                          <div className="login_input_box">
                            <label className="field-label-2" htmlFor="password">
                              {translationSignup('password')}
                            </label>
                            <InputField name="password" viewOnly={false} type="password" />
                            <ErrorMessage component={errProps => <div {...errProps} />} className="field_error" name="password" />
                          </div>
                          <div className="login_input_box">
                            <label className="field-label-2" htmlFor="passwordConfirm">
                              {translationSignup('passwordConfirm')}
                            </label>
                            <InputField name="passwordConfirm" viewOnly={false} type="password" />
                            <ErrorMessage component={errProps => <div {...errProps} />} className="field_error" name="passwordConfirm" />
                          </div>
                        </div>
                        <div className="button_bar-copy">
                          <Button className="btn_big_cancel" onClick={switchToLogin}>
                            {translationSignup('cancel')}
                          </Button>
                          <Button
                            className="btn_big_done-copy"
                            disabled={!(props.isValid && props.dirty)}
                            type="button"
                            onClick={() => handleSubmitMyForm()}
                          >
                            {translationSignup('submit')}
                          </Button>
                        </div>
                      </>
                    );
                  }}
                </Form>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SignupForm;
