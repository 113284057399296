import {
  GuideDTO,
  GuideIdDTO,
  GroupedGuideMaterialsDTO,
  UserGuidesDTO,
  CreateGuideMaterialDTO,
  UpdateGuideMaterialDTO,
  CreateGuideDTO,
  UpdateSortGuideMaterialsDTO,
  UpdateGuideDTO,
  FullGuideDTO,
  DeleteGuideMaterialDTO,
  RelinkGuideMaterialDTO,
  CloneGuideMaterialsDTO,
  CloneGuideDTO,
  DeleteGuideDTO,
  GuideMaterialLikeDTO,
  GuideMaterialsDTO,
  GetGuideMaterialsDTO,
  UpdateSortGuideMaterialsV2DTO
} from 'dto/guide';
import { GuideMaterialIdDTO, MaterialLikeIdDTO } from 'dto/material';
import { get, post, patch, put, del } from './common';
import { validateArray, validate, validateBool } from './validation';

export const getGuides = async (): Promise<UserGuidesDTO[]> => {
  const guides = await get('/guides').then(response => response.data);
  return validateArray(UserGuidesDTO, guides);
};

export const getGuidesByDepartment = async (departmentId: string): Promise<UserGuidesDTO[]> => {
  const guides = await get('/guides', { departmentId }).then(response => response.data);
  return validateArray(UserGuidesDTO, guides);
};

export const getGuidesByFunctionalArea = async (functionalAreaId: string): Promise<UserGuidesDTO[]> => {
  const guides = await get('/guides', { functionalAreaId }).then(response => response.data);
  return validateArray(UserGuidesDTO, guides);
};

export const getAdditionalGuidesByUser = async (ownerId: string): Promise<UserGuidesDTO[]> => {
  const userGuides = await get('/guides', { ownerId }).then(response => response.data);
  return validateArray(UserGuidesDTO, userGuides);
};

export const getGuide = async (guideId: string): Promise<FullGuideDTO> => {
  const guide = await get('/guide', { guideId }).then(response => response.data);
  return validate(FullGuideDTO, guide);
};

export const createGuide = async (newGuide: CreateGuideDTO) => {
  const guideId = await post('/guide', newGuide)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
  return validate(GuideIdDTO, guideId);
};

export const deleteWholeGuides = async (guides: DeleteGuideDTO) => {
  return del('/guides', guides).then(response => validateBool(response.data));
};

/**
 * @deprecated use getGuideMaterialsV2 instead
 * @param guideId
 * @returns
 */
export const getGuideMaterials = async (guideId: string): Promise<GroupedGuideMaterialsDTO[]> => {
  const guideMaterials = await get('/guide/materials', { guideId }).then(response => response.data);
  return validateArray(GroupedGuideMaterialsDTO, guideMaterials);
};

export const getGuideMaterialsV2 = async (options: GetGuideMaterialsDTO): Promise<GuideMaterialsDTO> => {
  const guideMaterials = await get('/v2/guide/materials', options).then(response => response.data);
  return validate(GuideMaterialsDTO, guideMaterials);
};

export const getGuideMaterial = async (guideMaterialId: string): Promise<GuideMaterialLikeDTO> => {
  const guideMaterial = await get('/guide/material', { guideMaterialId }).then(response => response.data);
  return validate(GuideMaterialLikeDTO, guideMaterial);
};

export const createGuideMaterial = async (guideMaterial: CreateGuideMaterialDTO): Promise<GuideMaterialLikeDTO> => {
  const newMaterial = await post('/guide/material', guideMaterial).then(response => response.data);
  return validate(GuideMaterialLikeDTO, newMaterial);
};

export const updateGuideMaterial = async (guideMaterialUpdateFields: UpdateGuideMaterialDTO): Promise<void> => {
  await patch('/guide/material', guideMaterialUpdateFields).then(response => response.data);
};

/**
 * @deprecated use v2 api
 * @param reorderedGuideMaterials
 * @returns
 */
export const reorderGuideMaterials = async (reorderedGuideMaterials: UpdateSortGuideMaterialsDTO[]) => {
  return put('/guide/materials/positions', reorderedGuideMaterials).then(response => validateBool(response.data));
};

export const reorderGuideMaterialsV2 = async (reorderedGuideMaterials: UpdateSortGuideMaterialsV2DTO) => {
  return put('/v2/guide/materials/positions', reorderedGuideMaterials).then(response => validateBool(response.data));
};

export const updateGuide = async (guideUpdateFields: UpdateGuideDTO): Promise<GuideDTO> => {
  const updatedGuide = await patch('/guide', guideUpdateFields).then(response => response.data);
  return validate(GuideDTO, updatedGuide);
};

export const deleteGuideMaterials = async (guideMaterialToDelete: DeleteGuideMaterialDTO) => {
  return del('/guide/materials', guideMaterialToDelete).then(response => validateBool(response.data));
};

export const deleteGuidesMaterial = async (materialId: MaterialLikeIdDTO) => {
  return del('/guides/material', materialId).then(response => validateBool(response.data));
};

export const deleteGuideMaterial = async (guideMaterialId: GuideMaterialIdDTO) => {
  return del('/guide/material', guideMaterialId).then(response => validateBool(response.data));
};

export const relinkGuidesMaterial = async (guideMaterialToUpdate: RelinkGuideMaterialDTO) => {
  return put('/guides/material/relink', guideMaterialToUpdate).then(response => validateBool(response.data));
};

export const cloneGuidesMaterial = async (guideMaterialsToClone: CloneGuideMaterialsDTO) => {
  return put('/guide/materials/clone', guideMaterialsToClone).then(response => validateBool(response.data));
};

export const cloneGuide = async (guideClone: CloneGuideDTO) => {
  return put('/guide/clone', guideClone).then(response => validate(GuideIdDTO, response.data));
};

export const exportGuide = async (guideId: string): Promise<BlobPart> => {
  return get('/guide/export', { guideId }, { responseType: 'blob' }).then(response => response.data);
};

export const exportAllGuides = async (): Promise<BlobPart> => {
  return get('/guides/export', {}, { responseType: 'blob' }).then(response => response.data);
};
