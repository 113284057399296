import React from 'react';
import Select, { OptionTypeBase, OptionsType } from 'react-select';
import { FormikValues, FormikProps } from 'formik';
import { customStyles, getSelectItemById } from './selectUtils';
import InputField from '../InputField';

interface Props {
  defaultValue?: OptionTypeBase;
  mappedOptions: OptionsType<OptionTypeBase>;
  meta: FormikProps<FormikValues>;
  name: string;
  placeholder?: string;
  isGrouped?: boolean;
  viewOnly: boolean;
  maxMenuHeight?: number;
}

const SelectComponent = ({ viewOnly, defaultValue, mappedOptions, meta, name, isGrouped = false, placeholder, maxMenuHeight }: Props) => {
  const selectedOption = getSelectItemById(mappedOptions, meta.values[name], isGrouped);

  const onChange = (data: OptionTypeBase) => {
    meta.setFieldValue(name, data.value);
  };

  if (viewOnly) {
    return <InputField name={name} viewOnly={viewOnly} value={selectedOption?.label} isGrouped={isGrouped} />;
  }

  return (
    <Select
      name={name}
      value={selectedOption}
      options={mappedOptions}
      defaultValue={defaultValue}
      styles={customStyles}
      maxMenuHeight={maxMenuHeight}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default SelectComponent;
