import React from 'react';
import { Field } from 'formik';

interface Props {
  placeholder: string;
  name: string;
  viewOnly: boolean;
}

const Textarea = ({ name, placeholder, viewOnly }: Props) => {
  return (
    <div className={`input_field-text top-margin ${viewOnly ? 'without-input' : 'with-input'}`}>
      <Field
        placeholder={placeholder}
        style={{ width: '100%', outline: 'none', border: '0', color: 'black', resize: 'none' }}
        name={name}
        disabled={viewOnly}
        maxLength={500}
        as="textarea"
      />
      {!viewOnly && (
        <img src="images/icon_s-edit_16.svg" style={{ filter: 'saturate(0%) brightness(167%)' }} alt="" className="image_write" />
      )}{' '}
    </div>
  );
};
export default Textarea;
