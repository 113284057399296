import React, { useCallback } from 'react';
import ItemMenuButton from 'components/ItemMenuButton';
import { useTranslation } from 'react-i18next';
import { PostDTO } from 'dto/post';
import { useStores } from 'util/mobx/stores';
import { deletePosts } from 'api/post';

interface Props {
  post: PostDTO;
}

const DialogMenu = ({ post }: Props) => {
  const { t } = useTranslation('comment');
  const { postStore, domainStore } = useStores();

  const reloadPosts = useCallback(async () => {
    await postStore.reloadBoard(domainStore.currentFunctionalArea.id);
  }, [domainStore.currentFunctionalArea.id, postStore]);

  const handleDeleteComment = useCallback(async () => {
    await deletePosts({ postId: post.postId });
    reloadPosts();
  }, [reloadPosts, post.postId]);

  const handleEditComment = useCallback(() => {
    postStore.activateDialogEdit(post);
  }, [postStore, post]);

  return (
    <>
      <ItemMenuButton label={t('edit')} icon="icon_i-edit_16.svg" isInPopover handleClick={handleEditComment} />
      <ItemMenuButton label={t('delete')} icon="icon_bin_16.svg" warning isInPopover handleClick={handleDeleteComment} />
    </>
  );
};

export default DialogMenu;
