import { GetLocationDTO, LocationDTO, CreateLocationDTO, UpdateLocationDTO, LocationUsageReportDTO } from 'dto/location';
import { get, post, patch, del } from './common';
import { validate, validateBool } from './validation';

export const getLocations = async (getLocationsOptions: GetLocationDTO) => {
  const locations = await get('/locations', getLocationsOptions).then(response => response.data);
  return locations;
  // return validateArray(LocationDTO, locations);
};

export const getLocationUsageReport = async (locationId: string) => {
  const locationReport = await get('/location/report', { locationId }).then(response => response.data);
  return validate(LocationUsageReportDTO, locationReport);
};

export const createLocations = async (getLocationsOptions: CreateLocationDTO) => {
  const location = await post('/location', getLocationsOptions).then(response => response.data);
  return validate(LocationDTO, location);
};

export const updateLocations = async (getLocationsOptions: UpdateLocationDTO) => {
  const location = await patch('/location', getLocationsOptions).then(response => response.data);
  return validate(LocationDTO, location);
};

export const deleteLocation = async (locationId: string) => {
  return del('/guides/material', { locationId }).then(response => validateBool(response.data));
};
