import React from 'react';
import './StatusBar.css';

interface Props {
  all: number;
  checked: number;
  title?: string;
  isDetailed?: boolean;
  isSmall?: boolean;
  hideIfAllIs0?: boolean;
}

const getPercent = (all: number, checked: number) => {
  if (all === 0) {
    return 100;
  }
  return (checked / all) * 100;
};

const getIndicatorClass = (percent: number) => {
  if (percent === 0) {
    return 'value_0 in_overview';
  }
  if (percent === 100) {
    return 'value_100 in_overview';
  }
  return '';
};

const StatusBar = ({ all, checked, title, isDetailed = true, isSmall = false, hideIfAllIs0 = false }: Props) => {
  const percent = getPercent(all, checked);
  return !(hideIfAllIs0 && all === 0) ? (
    <>
      {isDetailed && <div>{title}</div>}
      <div className={`status_bar ${!isDetailed ? 'in_operation_info' : ''} ${isSmall ? 'small' : ''} status-bar`}>
        <div className={`chart_indicator ${getIndicatorClass(percent)}`} style={{ width: `${percent}%` }}>
          {!isSmall && (
            <div className={percent < 10 ? 'status_bar_text_reserved' : ''}>
              {checked}/{all}
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default StatusBar;
