import React, { useState } from 'react';
import moment from 'moment';
import DepartmentList from 'components/DepartmentList';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import Popover from 'components/Popover';
import { AdminOptionalAccountDTO, UserRole, UserStatus } from 'dto/user';
import FlyoutContainer from 'components/FlyoutContainer';
import ItemMenu from 'components/ItemMenu';
import Button from 'components/Form/Button';
import ImageCircle from 'components/ImageCircle';
import UserDetailsForm from 'components/UserDetailsForm';
import ResetPasswordFlyout from './resetPasswordFlyout';
import { SetDepartmentLeaderFlyout, SetDepartmentMaintainerFlyout } from './setDepartmentFlyouts';
import './AccountManagementUserDetailsFlyout.css';

const AccountManagementUserDetailsFlyout = observer(() => {
  const { t } = useTranslation('accountManagement');
  const { userStore } = useStores();
  const [isSetDepartmentLeaderFlyoutFlyoutOpen, setIsSetDepartmentLeaderFlyoutOpen] = useState(false);
  const [isSetDepartmentMaintainerFlyoutOpen, setIsSetDepartmentMaintainerFlyoutOpen] = useState(false);
  const [isResetPasswordFlyoutOpen, setIsResetPasswordFlyoutOpen] = useState(false);

  const onCloseUserDetailsFlyout = () => {
    userStore.closeUserDetailsFlyout();
  };

  const onChangeDepartmentLeader = () => {
    setIsSetDepartmentLeaderFlyoutOpen(true);
    setIsSetDepartmentMaintainerFlyoutOpen(false);
  };

  const onChangeDepartmentMaintainer = () => {
    setIsSetDepartmentLeaderFlyoutOpen(false);
    setIsSetDepartmentMaintainerFlyoutOpen(true);
  };

  const closeSetDepartmentFlyouts = () => {
    setIsSetDepartmentLeaderFlyoutOpen(false);
    setIsSetDepartmentMaintainerFlyoutOpen(false);
  };
  const closeResetPasswordFlyout = () => {
    userStore.temporaryPassword = '';
    setIsResetPasswordFlyoutOpen(false);
  };

  const initialValues: AdminOptionalAccountDTO = {
    titleId: userStore.selectedUser ? userStore.selectedUser.titleId : '',
    firstName: userStore.selectedUser ? userStore.selectedUser.firstName : '',
    lastName: userStore.selectedUser ? userStore.selectedUser.lastName : '',
    emailAddress: userStore.selectedUser ? userStore.selectedUser.emailAddress : '',
    phoneNumber: userStore.selectedUser ? userStore.selectedUser.phoneNumber : '',
    username: userStore.selectedUser ? userStore.selectedUser.username : '',
    userId: userStore.selectedUser ? userStore.selectedUser.userId : ''
  };

  const onChangeUserStatus = (status: UserStatus) => {
    if (userStore.selectedUser) {
      userStore.updateUserWithAdmin({ userId: userStore.selectedUser.userId, status });
    }
  };
  const onChangeUserRole = (userRole: UserRole) => {
    if (userStore.selectedUser) {
      userStore.updateUserWithAdmin({ userId: userStore.selectedUser.userId, userRole });
    }
  };

  const onResetPassword = async () => {
    if (userStore.selectedUser) {
      await userStore.resetPassword();
      setIsResetPasswordFlyoutOpen(true);
    }
  };

  return (
    <FlyoutContainer
      isOpen={userStore.isUserDetailsFlyoutOpen}
      closePopUp={onCloseUserDetailsFlyout}
      title={t('userDetail.title')}
      icon={<ImageCircle user={userStore.selectedUser} className="image_circle_40" size="40" />}
      cancelLabel={t('userDetail.button.cancel')}
      isAllowedToSubmit
      closeAsSubmit
    >
      <div className="flyout_scroll_wrapper">
        <div className="div-block-131 fix-dropdown-overflow">
          <div className="flyout_box_head">
            <div className="item_name-copy">
              {userStore.selectedUser ? (
                <div>
                  <div className="t2 bottom-margin">{userStore.selectedUser.title}</div>
                  <div>{`${userStore.selectedUser.firstName}, ${userStore.selectedUser.lastName}`}</div>
                </div>
              ) : (
                <></>
              )}

              <div className="div-block-184">
                <div className="div-block-160-copy dropdown-container">
                  <Popover
                    trigger={
                      <div className="label-inner">
                        <div>
                          {userStore.selectedUser?.status === UserStatus.Inactive && t('users.status.inactive')}
                          {userStore.selectedUser?.status === UserStatus.Active && t('users.status.active')}
                          {userStore.selectedUser?.status === UserStatus.Pending && t('users.status.pending')}
                          <img src="images/icon_small-triangle-down_white.svg" alt="" className="image_arrow_lable " />
                        </div>
                      </div>
                    }
                  >
                    {({ handleClose, isOpen }) => (
                      <ItemMenu isOpen={!!isOpen} handleClose={handleClose} className="padding_top-copy status-menu">
                        <div className="txt_info_text">{t('userDetail.userStatusDropdown.dropdownHeader')}</div>
                        <div className="line_menu-edit" />

                        <Button className="btn_list_edit status-button" onClick={() => onChangeUserStatus(UserStatus.Inactive)}>
                          <div>{t('users.status.inactive')}</div>
                        </Button>
                        <Button className="btn_list_edit status-button " onClick={() => onChangeUserStatus(UserStatus.Active)}>
                          <div>{t('users.status.active')}</div>
                        </Button>
                        <Button className="btn_list_edit status-button " onClick={() => onChangeUserStatus(UserStatus.Pending)}>
                          <div>{t('users.status.pending')}</div>
                        </Button>
                      </ItemMenu>
                    )}
                  </Popover>
                </div>
                <div className="div-block-160-copy dropdown-container rights">
                  <Popover
                    trigger={
                      <div className="label-inner">
                        <div>
                          {userStore.selectedUser?.userRole === UserRole.Admin ? t('users.userRoleTag.admin') : t('users.userRoleTag.user')}
                          <img src="images/icon_small-triangle-down_blue.svg" alt="" className="image_arrow_lable" />
                        </div>
                      </div>
                    }
                  >
                    {({ handleClose, isOpen }) => (
                      <ItemMenu isOpen={!!isOpen} handleClose={handleClose} className="padding_top-copy status-menu">
                        <div className="txt_info_text">{t('userDetail.userRoleDropdown.dropdownHeader')}</div>
                        <div className="line_menu-edit" />

                        <Button className="btn_list_edit status-button" onClick={() => onChangeUserRole(UserRole.User)}>
                          <div>{t('users.userRoleTag.user')}</div>
                        </Button>
                        <Button className="btn_list_edit status-button " onClick={() => onChangeUserRole(UserRole.Admin)}>
                          <div>{t('users.userRoleTag.admin')}</div>
                        </Button>
                      </ItemMenu>
                    )}
                  </Popover>
                </div>
                {!userStore.selectedUser?.usesLdapLogin && (
                  <div className="div-block-160-copy dropdown-container rights">
                    <Button onClick={onResetPassword}>{t('userDetail.resetPassword')}</Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="line-copy" />

        <UserDetailsForm
          usesLdap={userStore.selectedUser?.usesLdapLogin || false}
          initialValues={initialValues}
          viewOnly
          className="in_user_manger"
        />

        <div className="flyout_box">
          <div>{t('userDetail.latestLogin')}</div>
          <div className="text-block-29">
            {userStore.selectedUser?.lastOnline && moment(userStore.selectedUser.lastOnline).format(t('userDetail.latestLoginFormat'))}
          </div>
        </div>

        <div className="flyout_box">
          <DepartmentList
            departmentMaintainerOf={userStore.selectedUserLeaderOf}
            headline={t('userDetail.departmentOrganisation.maintainerIsLead')}
            onChange={onChangeDepartmentLeader}
            onChangeButtonText={t('userDetail.departmentOrganisation.onChangeButton')}
          />
        </div>
        <div className="flyout_box">
          <DepartmentList
            departmentMaintainerOf={userStore.selectedUserMaintainerOf}
            headline={t('userDetail.departmentOrganisation.maintainerIsNotLead')}
            onChange={onChangeDepartmentMaintainer}
            onChangeButtonText={t('userDetail.departmentOrganisation.onChangeButton')}
          />
        </div>
      </div>

      {/* flyout to select department leadership for the current user */}
      <SetDepartmentLeaderFlyout isOpen={isSetDepartmentLeaderFlyoutFlyoutOpen} onClose={closeSetDepartmentFlyouts} />
      {/* flyout to select additional department rights for the current user */}
      <SetDepartmentMaintainerFlyout isOpen={isSetDepartmentMaintainerFlyoutOpen} onClose={closeSetDepartmentFlyouts} />

      <ResetPasswordFlyout isOpen={isResetPasswordFlyoutOpen} onClose={closeResetPasswordFlyout} />
    </FlyoutContainer>
  );
});

export default AccountManagementUserDetailsFlyout;
