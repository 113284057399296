import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { PostType, PostDTO } from 'dto/post';
import { useStores } from 'util/mobx/stores';
import CommentItem from 'components/Chat/CommentItem';
import CommentProcedureItem from 'components/Chat/CommentProcedureItem';
import { getDayTimeWording } from 'util/date';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ChatFlyoutContainer from '../../ChatFlyout/ChatFlyoutContainer';

const commentIcon = (
  <div className="icon_board">
    <img src="images/icon_f-comment_16.svg" alt="" />
  </div>
);

const procedureIcon = (
  <div className="image_wrapper_40">
    <img src="images/icon_edit.jpg" alt="" className="image_circle_40" />
  </div>
);

const getSubmitLink = (post: PostDTO) => {
  if (!post) return '';
  if (post.department) {
    return '/guides';
  }
  if (post.type === PostType.Chat) {
    return `/guide/materials?guideId=${post.guide?.guideId}`;
  }
  return `/guide/procedure?guideId=${post.guide?.guideId}`;
};

const getFlyoutTitle = (post?: PostDTO) => {
  if (!post) return '';
  if (post.department) {
    return 'post.postDetailsFlyout.departmentCommentLabel';
  }
  if (post.type === PostType.ProcedureUpdate) {
    return 'post.postDetailsFlyout.procedureLabel';
  }
  return 'post.postDetailsFlyout.commentLabel';
};

const getFlyoutGoToButton = (post?: PostDTO) => {
  if (!post) return '';
  if (post.department) {
    return 'post.postDetailsFlyout.buttons.gotoDepartment';
  }
  return 'post.postDetailsFlyout.buttons.gotoGuide';
};

const PostDetailsFlyout = withRouter(
  observer(({ history }: RouteComponentProps) => {
    const { t } = useTranslation('board');
    const { postStore, domainStore } = useStores();
    const translate = useTranslation('date');

    const setSelectedDepartment = (departmentId: string) => {
      let department;
      domainStore.departments.forEach(groupedDepartment => {
        groupedDepartment.departments.forEach(d => {
          if (d.departmentId === departmentId) {
            department = d;
          }
        });
      });
      if (department) {
        domainStore.setCurrentDepartment(department);
      }
    };

    const onGoTo = () => {
      if (!postStore.selectedPost) return;
      const link = getSubmitLink(postStore.selectedPost);
      postStore.setIsFlyoutInBoardOpen(false);
      if (postStore.selectedPost.department) {
        setSelectedDepartment(postStore.selectedPost.department.departmentId);
      }
      setTimeout(() => {
        postStore.setIsChatBoardFlyoutShown(false);
        history.push(link);
      }, 500);
    };
    return (
      <ChatFlyoutContainer
        isOpen={postStore.isFlyoutInBoardOpen}
        isSingleContent
        onClose={() => postStore.setIsFlyoutInBoardOpen(false)}
        buttonSubmitLabel={t(getFlyoutGoToButton(postStore.selectedPost))}
        buttonCloseLabel={t('post.postDetailsFlyout.buttons.cancel')}
        onSubmit={onGoTo}
        title={t(getFlyoutTitle(postStore.selectedPost))}
        icon={postStore.selectedPost?.type === PostType.ProcedureUpdate ? procedureIcon : commentIcon}
      >
        {postStore.selectedPost ? (
          <>
            <div>{getDayTimeWording(postStore.selectedPost.createdAt, translate)}</div>
            <div className="menu_list_messages">
              {postStore.selectedPost?.type === PostType.Chat ? (
                <CommentItem comment={postStore.selectedPost} />
              ) : (
                <CommentProcedureItem post={postStore.selectedPost} />
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </ChatFlyoutContainer>
    );
  })
);

export default PostDetailsFlyout;
