import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from 'util/mobx/stores';
import FlyoutContainer from 'components/FlyoutContainer';
import CustomRadioButton from 'components/CustomRadioButton';
import { ProcedureType, IconType, ProcedureDTO } from 'dto/procedure';
import PostItem from 'components/PostItem';
import CloneGuideOverview from '../CloneGuideOverview';
import '../CloneFlyout.css';

export default observer(() => {
  const { t } = useTranslation('guide');
  const { guideStore, procedureStore, animationStore } = useStores();
  const [linkGuideProcedureIds, setLinkGuideProcedureIds] = useState<string[]>([]);
  const [copyGuideProcedureIds, setCopyGuideProcedureIds] = useState<string[]>([]);
  const closePopUp = () => {
    guideStore.resetAllSelectedGuideParamsFromCloneFlyout();
    setLinkGuideProcedureIds([]);
    setCopyGuideProcedureIds([]);
  };
  const backPopUp = () => {
    procedureStore.resetProceduresClone();
    guideStore.resetSelectedGuideOwnerFromGuidesCloneFlyout();
    setLinkGuideProcedureIds([]);
    setCopyGuideProcedureIds([]);
  };
  const onSubmit = async () => {
    if (
      guideStore.guideProcedureToClone &&
      (linkGuideProcedureIds.length > 0 || copyGuideProcedureIds.length > 0) &&
      procedureStore.chapterIdForNewProcedures
    ) {
      const procedures = await procedureStore.bulkUpdateCopyRelinkGuideProcedure({
        guideId: guideStore.guideProcedureToClone.guideId,
        guideChapterId: procedureStore.chapterIdForNewProcedures,
        position: procedureStore.positionForNewProcedures,
        copyGuideProcedureIds,
        linkGuideProcedureIds
      });
      closePopUp();
      animationStore.setElementIdToShake(procedures.map((p: ProcedureDTO) => p.guideProcedureId));
    }
  };

  const handleAddLinkGuideProcedureIds = (guideProcedureId: string) => {
    if (copyGuideProcedureIds.includes(guideProcedureId)) {
      const copyGuideProcedureIdsWithoutCurrentId = copyGuideProcedureIds.filter(id => id !== guideProcedureId);
      setCopyGuideProcedureIds(copyGuideProcedureIdsWithoutCurrentId);
    }

    if (linkGuideProcedureIds.includes(guideProcedureId)) {
      const linkGuideProcedureIdsWithoutCurrentId = linkGuideProcedureIds.filter(id => id !== guideProcedureId);
      setLinkGuideProcedureIds(linkGuideProcedureIdsWithoutCurrentId);
      return;
    }

    setLinkGuideProcedureIds([...linkGuideProcedureIds, guideProcedureId]);
  };

  const handleAddCopyGuideProcedureIds = (guideProcedureId: string) => {
    if (linkGuideProcedureIds.includes(guideProcedureId)) {
      const linkGuideProcedureIdsWithoutCurrentId = linkGuideProcedureIds.filter(id => id !== guideProcedureId);
      setLinkGuideProcedureIds(linkGuideProcedureIdsWithoutCurrentId);
    }

    if (copyGuideProcedureIds.includes(guideProcedureId)) {
      const copyGuideProcedureIdsWithoutCurrentId = copyGuideProcedureIds.filter(id => id !== guideProcedureId);
      setCopyGuideProcedureIds(copyGuideProcedureIdsWithoutCurrentId);
      return;
    }

    setCopyGuideProcedureIds([...copyGuideProcedureIds, guideProcedureId]);
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_import.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={guideStore.isCloneGuideProcedureFlyoutOpen}
      closePopUp={guideStore.selectedGuideCloneFlyout ? backPopUp : closePopUp}
      onSubmit={onSubmit}
      submitLabel={guideStore.selectedGuideCloneFlyout && t('cloneGuideProcedure.button.submit')}
      isAllowedToSubmit={linkGuideProcedureIds.length > 0 || copyGuideProcedureIds.length > 0}
      title={t('cloneGuideProcedure.headline')}
      cancelLabel={guideStore.selectedGuideCloneFlyout ? t('cloneGuideProcedure.button.back') : t('cloneGuideProcedure.button.cancel')}
    >
      {guideStore.selectedGuideCloneFlyout ? (
        <div className="flyout_scroll_wrapper flyout-container-full_height">
          <div className="flyout_box">
            <div>{t('cloneGuideProcedure.label.chooseProcedureForPaste')}</div>
            <div className="flyout_scrollable in_flyout clone-list">
              {procedureStore.proceduresClone.map(procedureItem => (
                <React.Fragment key={procedureItem.chapterId}>
                  <div className="txt_section_flyout padding_left">{procedureItem.chapter}</div>
                  {procedureItem.procedures.map(procedure => (
                    <PostItem
                      files={procedure.content.contentElementMedias}
                      title={procedure.content.title}
                      user={procedure.content.user}
                      picture={procedure.materialPicture}
                      isCheckable={procedure.content.isCheckable}
                      iconType={procedure.type === ProcedureType.Knowledge ? IconType.MaterialPicture : IconType.UserPicture}
                      radioButton={
                        <>
                          <CustomRadioButton
                            title={t('cloneGuideProcedure.button.linkProcedure')}
                            checked={linkGuideProcedureIds.includes(procedure.guideProcedureId)}
                            onClick={() => handleAddLinkGuideProcedureIds(procedure.guideProcedureId)}
                          />
                          {procedure.type !== ProcedureType.Knowledge ? (
                            <CustomRadioButton
                              title={t('cloneGuideProcedure.button.copyProcedure')}
                              checked={copyGuideProcedureIds.includes(procedure.guideProcedureId)}
                              onClick={() => handleAddCopyGuideProcedureIds(procedure.guideProcedureId)}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      }
                    >
                      <div>{procedure.content.description}</div>
                    </PostItem>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <CloneGuideOverview
          title={t('cloneGuideProcedure.label.chooseGuideForGuideProcedure')}
          guideOwnerList={guideStore.guideOwnerListClone}
          guidesClone={guideStore.selectedGuidesClone}
          className="flyout_scroll_wrapper flyout-container-full_height"
        />
      )}
    </FlyoutContainer>
  );
});
