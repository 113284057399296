import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import OverviewBox from 'components/OverviewBox';

interface Props {
  packageId: string;
}

const PackageOverview = observer(({ packageId }: Props) => {
  const { t } = useTranslation('packages');
  const { appNavigationStore } = useStores();
  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.withoutMenu();
  }, [appNavigationStore]);

  appNavigationStore.useSubPageIdSetter('overview');

  return (
    <div className="single_colum_content no_padding_right">
      <div>
        <div className="div-block-48">
          <OverviewBox
            link={`/package/detail?packageId=${packageId}`}
            name={t('overview.baseData.name')}
            text={t('overview.baseData.textHtml')}
            btnText={t('overview.baseData.btnText')}
            image="data-table_16"
          />
          <OverviewBox
            link={`/package/instruments?packageId=${packageId}`}
            name={t('overview.packingList.name')}
            text={t('overview.packingList.textHtml')}
            btnText={t('overview.packingList.btnText')}
            image="icon_document_16"
          />
          <OverviewBox
            link={`/package/content?packageId=${packageId}`}
            name={t('overview.knowledge.name')}
            text={t('overview.knowledge.textHtml')}
            btnText={t('overview.knowledge.btnText')}
            image="bulb-62_16"
          />
          <OverviewBox
            link={`/package/functions?packageId=${packageId}`}
            name={t('overview.globalFunctions.name')}
            text={t('overview.globalFunctions.textHtml')}
            btnText={t('overview.globalFunctions.btnText')}
            image="globe_16"
          />

          <OverviewBox
            link={`/package/locations?packageId=${packageId}`}
            name={t('overview.storageLocation.name')}
            text={t('overview.storageLocation.textHtml')}
            btnText={t('overview.storageLocation.btnText')}
            image="pin-3_16"
          />
        </div>
      </div>
    </div>
  );
});

export default PackageOverview;
