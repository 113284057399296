import React, { useCallback } from 'react';
import { UsedMaterialStatus } from 'dto/usedMaterial';
import { GuideCheck } from './GuideCheck';
import { useUsedMaterialContext } from './UsedMaterialContext';

interface Props {
  surgeryGuideMaterialId: string;
  groupMaterialId?: string;
  packageId?: string;
  onAddLotNumber: () => void;
}

export const UsedMaterialCheck: React.FC<Props> = ({ surgeryGuideMaterialId, groupMaterialId, packageId, onAddLotNumber }) => {
  const { getStatus, setStatus } = useUsedMaterialContext();
  const checkState = getStatus(surgeryGuideMaterialId, packageId, groupMaterialId);
  const isChecked = checkState !== UsedMaterialStatus.None;

  const handleStatusChange = useCallback(() => {
    // Toggle checked
    setStatus(isChecked ? UsedMaterialStatus.None : UsedMaterialStatus.Prepared, surgeryGuideMaterialId, groupMaterialId, packageId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupMaterialId, packageId, setStatus, surgeryGuideMaterialId]);

  const handleSetStatus = useCallback(
    (status: UsedMaterialStatus) => {
      setStatus(status, surgeryGuideMaterialId, groupMaterialId, packageId);
    },
    [groupMaterialId, packageId, setStatus, surgeryGuideMaterialId]
  );

  return (
    <GuideCheck
      checked={isChecked}
      onClick={handleStatusChange}
      setStatus={handleSetStatus}
      status={checkState}
      groupMaterialId={groupMaterialId}
      onAddLotNumber={onAddLotNumber}
    />
  );
};
