import React from 'react';
import Button from 'components/Form/Button';
import { scanQR } from 'util/mobile/mobileUtils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStores } from 'util/mobx/stores';

interface Props {
  transformToURL: (data: phonegapBarcode.BarcodeScanResult) => Promise<string | undefined>;
  className?: string;
  label?: string;
  onScan?: () => void;
}

const QRScanButton = ({ className = '', label, onScan, transformToURL: transform }: Props) => {
  const { t } = useTranslation('qr');
  const history = useHistory();

  const { domainStore } = useStores();

  const handleScan = () => {
    if (onScan) {
      onScan();
    }

    scanQR().then(data => {
      transform(data).then(url => {
        if (url) {
          history.push(url);
        }
      });
    });
  };

  return domainStore.isMobile ? (
    <div className="div-block-37">
      <div className="search_location">
        <div>{t('qrScanBoxLabel')}</div>
        <div className="btn_dropout in_search">
          <img src="images/camera-2.svg" alt="" className="image_search_location" />
          <Button className={className} onClick={handleScan}>
            {label || t('qrScanButtonLabel')}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
export default QRScanButton;
