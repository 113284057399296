import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import {
  SETTINGS,
  GUIDE,
  GUIDES,
  MATERIAL,
  INSTRUMENTS,
  PACKAGE,
  SINGLE_INSTRUMENT,
  INSTRUMENT,
  GROUP,
  ADMINISTRATION_LOCATION,
  ACCOUNTMANAGEMENT,
  USERSETTINGS,
  SURGERY_BRIEFING,
  SURGERY_GUIDE
} from 'constants/routes';
import { Can, subjectArea } from 'casl/setupCaslAbility';
import { determineMainPath } from 'util/navigation';
import AppMenuPrimaryButton from './AppMenuPrimaryButton';
import AppMenuSettingsButton from './AppMenuSettingsButton';
import LoggedInUser from './LoggedInUser';

import './AppMenu.css';

interface Props {
  location: {
    pathname: string;
    search: string;
  };
}

const AppMenu = withRouter(
  observer((props: Props) => {
    const { t } = useTranslation('navigation');
    const { postStore, reportStore, domainStore, searchStore, authStore } = useStores();

    const currentMainPath = determineMainPath(props.location.pathname);

    const handlerOpenSearchPanel = () => {
      searchStore.openSearchPanel();
    };

    const handleOpenChatBoard = () => {
      postStore.setIsChatBoardFlyoutShown(true);
    };

    const handleReportDrawerOpen = () => {
      reportStore.setIsReportDrawerOpen(true);
    };
    return (
      <div style={{ display: 'flex' }} className="menu div-block-144 div-block-156">
        <LoggedInUser />

        <AppMenuPrimaryButton
          routePath={SURGERY_BRIEFING}
          icon="icon_accessibility"
          label={t('surgery', { functionalAreaShortName: domainStore.currentFunctionalArea.shortName })}
          currentPath={currentMainPath}
          additionalPathsOfGroup={[SURGERY_GUIDE]}
        />
        <AppMenuPrimaryButton
          routePath={GUIDES}
          icon="icon_saved-items"
          label={t('guide')}
          currentPath={currentMainPath}
          additionalPathsOfGroup={[GUIDE, MATERIAL, INSTRUMENTS, PACKAGE, SINGLE_INSTRUMENT, INSTRUMENT, GROUP]}
        />
        <AppMenuPrimaryButton
          routePath={ADMINISTRATION_LOCATION}
          icon="icon_pin-3"
          label={t('storageLocation')}
          active={props.location.pathname === ADMINISTRATION_LOCATION}
          currentPath={currentMainPath}
        />
        <AppMenuPrimaryButton
          icon="icon_magnifier"
          label={t('materialSearch')}
          onClick={handlerOpenSearchPanel}
          active={searchStore.isSearchPanelOpen}
        />
        <AppMenuPrimaryButton
          onClick={handleReportDrawerOpen}
          icon="icon_ranking"
          label={t('reports')}
          active={reportStore.isReportDrawerOpen}
        />
        <AppMenuPrimaryButton
          onClick={handleOpenChatBoard}
          icon="icon_feedback_24"
          label={t('board')}
          active={!!postStore.isChatBoardFlyoutShown}
        />
        <div className="line_menu" />

        <Can I="see" a={subjectArea.appMenuSettingsButton}>
          <AppMenuSettingsButton
            routePath={ACCOUNTMANAGEMENT}
            icon="icon_apps_16"
            label={t('accountManagement')}
            currentPath={currentMainPath}
          />
          {!domainStore.isMobile && (
            <AppMenuSettingsButton routePath={SETTINGS} icon="icon_settings-gear_16" label={t('settings')} currentPath={currentMainPath} />
          )}
        </Can>

        <AppMenuSettingsButton routePath={USERSETTINGS} icon="icon_a-edit_16" label={t('userSettings')} currentPath={currentMainPath} />
        <AppMenuSettingsButton
          onClick={() => authStore.logout()}
          routePath="/"
          icon="icon_button-power_16"
          label={t('logout')}
          currentPath={currentMainPath}
        />
      </div>
    );
  })
);

export default AppMenu;
