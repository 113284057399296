import React from 'react';
import { getAnimationState } from 'util/animation';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';

const PictureHelper = () => {
  const { t } = useTranslation('chat');
  const { postStore, domainStore } = useStores();

  return !domainStore.isMobile ? (
    <div
      className={`picture_helper in_chatbar picture-helper desktop-only ${getAnimationState(
        postStore.isChatBarExpanded,
        'expanded',
        'collapsed'
      )}`}
    >
      <div className="div-block-39 in_chatbar">
        <img src="images/icon_c-info_16_grey.svg" alt="" className="image_help_icon" />
        <div className="div-block-40 block-wrap">
          <div className="text-block-11">{t('inputForm.tip.title')}</div>
          <div>{t('inputForm.tip.description')}</div>
        </div>
      </div>
    </div>
  ) : (
    <> </>
  );
};

export default PictureHelper;
