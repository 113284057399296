import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import * as qs from 'query-string';

import { useStores } from 'util/mobx/stores';
import PackageOverview from 'pages/packageOverview/PackageOverview';
import PackageDetail from 'pages/packageDetail/PackageDetail';
import PackageInstrumentsList from 'pages/packageInstrumentsList/PackageInstrumentsList';
import PackageContent from 'pages/packageContentPage/PackageContentPage';
import PackageGlobalFunctions from 'pages/packageGlobalFunctions/PackageGlobalFunctions';
import PackageStorageLocations from 'pages/packageStorageLocations';
import SelectLocationFlyout from 'pages/flyouts/SelectLocationFlyout';
import StorageLocationDetailDrawer from 'pages/locationAdministration/StorageLocationDetailDrawer';
import MaterialKnowledgeFormFlyout from 'components/MaterialKnowledge/MaterialKnowledgeFormFlyout';
import Safe from 'components/Safe';
import Page from 'components/Page';
import AppLayout from 'components/AppLayout';
import { ChatConfigurationTypes } from 'components/Chat/Chat';
import CreateMaterialSynonymFlyout from 'components/MaterialKnowledge/CreateMaterialSynonymFlyout';
import { AppBarHeaderText, AppBarPageIcon } from 'components/AppLayout/AppBar';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import MaterialValueTags from 'components/MaterialValueTags';
import PackageNavSection from './PackageNavSection';

const Package = observer(() => {
  const { instrumentStore } = useStores();
  const { t } = useTranslation('packages');
  const location = useLocation();
  const packageId = qs.parse(location.search).packageId as string;
  useEffect(() => {
    instrumentStore.setPackageId(packageId);
    instrumentStore.loadFullPackage(packageId);
  }, [packageId, instrumentStore]);
  const image = instrumentStore.fullPackageItem?.pack.picture ? instrumentStore.fullPackageItem?.pack.picture.pictureThumbnail : undefined;

  const onUpdateFile = async (fileId: string) => {
    if (instrumentStore.fullPackageItem) {
      const pictureFileIds = instrumentStore.fullPackageItem.pack.pictures.map((file, index) => {
        if (index === 0) {
          return fileId;
        }
        return file.pictureFileId;
      });
      if (pictureFileIds.length === 0) {
        pictureFileIds.push(fileId);
      }
      await instrumentStore.updatePackage({ pictureFileIds, packageId: instrumentStore.fullPackageItem.pack.packageId });
    }
  };
  return (
    <>
      <AppLayout
        appBarIcon={<AppBarPageIcon key={image} src={image} onUpdateFile={onUpdateFile} />}
        appBarChildren={<AppBarHeaderText headline={instrumentStore.fullPackageItem?.pack.name || ''} />}
        appBarRightChildren={<MaterialValueTags fullInfo classNameTitle="margin_left" pack={instrumentStore.fullPackageItem?.pack} />}
        boardFlyout={<BoardFlyout />}
        drawer={<StorageLocationDetailDrawer />}
        flyout={
          <>
            <MaterialKnowledgeFormFlyout />
            <CreateMaterialSynonymFlyout />
            <SelectLocationFlyout />
          </>
        }
      >
        <Page
          headline={t('subMenu.headline')}
          idPrefix="instruments"
          navigation={<PackageNavSection packageId={packageId} />}
          chatConfigurationType={ChatConfigurationTypes.department}
        >
          <Switch>
            <Route path="/package/overview" component={() => <PackageOverview packageId={packageId} />} />
            <Route path="/package/detail" component={() => <PackageDetail />} />
            <Route path="/package/instruments" component={() => <PackageInstrumentsList />} />
            <Route path="/package/content" component={() => <PackageContent packageId={packageId} translationKey="packages" />} />
            <Route path="/package/functions" component={() => <PackageGlobalFunctions packageItem={instrumentStore.fullPackageItem} />} />
            <Route path="/package/locations" component={() => <PackageStorageLocations packageId={packageId} />} />
          </Switch>
        </Page>
      </AppLayout>
    </>
  );
});

// moved Safe here to prevent running the useEffect if not logged in
export default () => (
  <Safe>
    <Package />
  </Safe>
);
