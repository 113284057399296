import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import { SetGroupWithTagsDTO } from 'dto/setGroup';
import { TagDTO } from 'dto/tag';
import React, { useCallback, useMemo } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from 'react-beautiful-dnd';
import { getItemStyle } from 'util/dragAndDrop';
import { SetGroupItem } from './ListItems/MaterialSet/SetGroupItem';
import { useSetCheckContext } from './SetCheck/SetCheckContext';
import { MaterialSetListContext, MaterialSetListState, OnGroupMaterialClick, TagParent } from './materialSetListContext';

export interface Props extends OptionalLazyLoadProps {
  groupType: string;
  materialSetId: string;

  // TODO: maybe load the groups here and do not pass?
  setGroups: SetGroupWithTagsDTO[];

  onReorderGroup?: (result: DropResult, provided: ResponderProvided) => void;
  onReorderGroupItem?: (result: DropResult, provided: ResponderProvided) => void;
  onReload?: () => Promise<void>;

  onTagCreate?: (parent: TagParent) => (tagName: string) => Promise<TagDTO>;
  onTagUpdate?: (tag: TagDTO) => Promise<TagDTO>;
  onTagDelete?: (tagId: string) => Promise<boolean>;

  onSetGroupUpdate?: (setGroup: SetGroupWithTagsDTO) => void;
  onSetGroupDelete?: (setGroupId: string) => void;

  onClickItem?: OnGroupMaterialClick;
  onDeleteGroupMaterial?: (setGroupId: string, groupMaterialId: string) => void;
  readonly?: boolean;
  level?: number;
}

export const MaterialSetList: React.FC<Props> = ({
  groupType,
  materialSetId,
  setGroups,
  onReload,
  onTagCreate,
  onTagUpdate,
  onTagDelete,

  onReorderGroup,
  onReorderGroupItem,

  onSetGroupUpdate,
  onSetGroupDelete,

  onDeleteGroupMaterial,
  onClickItem,

  lazyLoadScrollContainer,
  readonly = false,
  level = 0
}) => {
  const { checks } = useSetCheckContext();

  const state = useMemo<MaterialSetListState>(
    () => ({
      groupType,
      checksEnabled: !!checks,
      onReload,
      lazyLoadScrollContainer,
      onTagCreate,
      onTagUpdate,
      onTagDelete,

      onSetGroupUpdate,
      onSetGroupDelete,

      onDeleteGroupMaterial,
      onClickItem,
      readonly
    }),
    [
      groupType,
      checks,
      onReload,
      lazyLoadScrollContainer,
      onTagCreate,
      onTagUpdate,
      onTagDelete,
      onSetGroupUpdate,
      onSetGroupDelete,
      onDeleteGroupMaterial,
      onClickItem,
      readonly
    ]
  );

  const handleDragEnd = useCallback(
    (result: DropResult, provided: ResponderProvided) => {
      switch (result.type) {
        case 'groupItem':
          if (onReorderGroupItem) {
            onReorderGroupItem(result, provided);
          }
          break;
        case 'setGroup':
          if (onReorderGroup) {
            onReorderGroup(result, provided);
          }
          break;
        default:
          throw new Error('NOT IMPLEMENTED');
      }
    },
    [onReorderGroup, onReorderGroupItem]
  );

  return (
    <MaterialSetListContext.Provider value={state}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={materialSetId} type="setGroup">
          {(provided, dropableSnapshot) => (
            <div ref={provided.innerRef} className={`single-colum-content-item ${dropableSnapshot.isDraggingOver ? 'no-dropdown' : ''}`}>
              {setGroups &&
                setGroups.map((group, index) => {
                  return (
                    <Draggable index={index} draggableId={group.setGroupId} key={group.setGroupId} isDragDisabled={!!checks || readonly}>
                      {(draggableProvided, snapshot) => {
                        const style = getItemStyle(snapshot.isDragging, draggableProvided.draggableProps.style) as { top: number };
                        if (snapshot.isDragging) {
                          // Hacky Hack https://github.com/atlassian/react-beautiful-dnd/issues/1881#issuecomment-695772970
                          style.top -= (draggableProvided.draggableProps.style as { height: number })?.height || 0;
                        }

                        return (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            style={style}
                          >
                            <SetGroupItem level={level} key={group.setGroupId} setGroup={group} />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </MaterialSetListContext.Provider>
  );
};
