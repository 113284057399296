/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsString, IsEnum } from 'class-validator';
import 'reflect-metadata';

export enum QRSize {
  Small,
  Big
}

export class GetQRCodePDFDTO {
  @IsString()
  payload!: string;

  @IsString()
  headingTop!: string;

  @IsString()
  heading!: string;

  @IsString()
  heading2!: string;

  @IsString()
  description!: string;

  @IsEnum(QRSize)
  size!: QRSize;
}
