import React from 'react';
import Form from 'components/Form';
import { CreatePostContentElementDTO } from 'dto/contentElement';
import { useStores } from 'util/mobx/stores';
import { getAnimationState } from 'util/animation';
import DialogFormContent from './DialogFormContent/DialogFormContent';
import './DialogForm.css';

interface Props {
  afterSubmit: () => void;
  setIsExpanded: (isExpanded: boolean | null) => void;
  isExpanded: boolean | null;
}

const DialogForm = ({ afterSubmit, isExpanded, setIsExpanded }: Props) => {
  const { postStore, domainStore } = useStores();

  const onSubmit = async ({ description, contentElementMedias }: CreatePostContentElementDTO) => {
    const post: CreatePostContentElementDTO = {
      description,
      contentElementMedias: contentElementMedias ? contentElementMedias.map(e => ({ fileId: e.fileId, title: e.title })) : []
    };

    if (postStore.isDialogInEditMode && postStore.selectedDialogPost) {
      await postStore.editPost(postStore.selectedDialogPost.postId, post);
      await postStore.reloadBoard(domainStore.currentFunctionalArea.id);
    } else {
      await postStore.createFunctionalAreaPost(domainStore.currentFunctionalArea.id, post);
    }

    postStore.resetDialogForm();
    setIsExpanded(false);
    afterSubmit();
  };

  return (
    <div className={`div-block-88 dialog-form-container ${getAnimationState(isExpanded, 'expanded', 'collapsed')}`}>
      <div className="b_chat_line" />
      <Form
        initialValues={{
          description: '',
          contentElementMedias: []
        }}
        onSubmit={onSubmit}
      >
        {form => <DialogFormContent form={form} setIsExpanded={setIsExpanded} isExpanded={isExpanded} />}
      </Form>
    </div>
  );
};

export default DialogForm;
