import {
  CreateGuideChapterDTO,
  GetGuideChapterDTO,
  UpdateGuideChapterDTO,
  UpdateSortGuideChaptersDTO,
  GroupedGuideChapterDTO,
  GuideChapterDTO
} from 'dto/guideChapter';
import { get, post, patch, put, del } from './common';
import { validateArray, validate, validateBool } from './validation';

export const getGuideChapters = async (getGuideChaptersOptions: GetGuideChapterDTO) => {
  const guideChapters = await get('/guide-chapters', getGuideChaptersOptions).then(response => response.data);
  return validateArray(GroupedGuideChapterDTO, guideChapters);
};

export const createGuideChapter = async (getGuideChaptersOptions: CreateGuideChapterDTO) => {
  const guideChapter = await post('/guide-chapter', getGuideChaptersOptions).then(response => response.data);
  return validate(GuideChapterDTO, guideChapter);
};

export const updateGuideChapter = async (getGuideChaptersOptions: UpdateGuideChapterDTO) => {
  const guideChapter = await patch('/guide-chapter', getGuideChaptersOptions).then(response => response.data);
  return validate(GuideChapterDTO, guideChapter);
};

export const reorderGuideChapters = async (reorderedGuideChapters: UpdateSortGuideChaptersDTO) => {
  return put('/guide-chapter/positions', reorderedGuideChapters).then(response => validateBool(response.data));
};

export const deleteGuideChapter = async (guideChapterId: string) => {
  return del('/guides/material', { guideChapterId }).then(response => validateBool(response.data));
};
