/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsString, IsUUID, IsBoolean, IsOptional, ValidateNested, IsNotEmptyObject } from 'class-validator';
import 'reflect-metadata';
import { Type } from 'class-transformer';
import { FunctionalAreaDTO } from './functionalArea';

export class GuideMaterialCategoryDTO {
  @IsUUID()
  guideMaterialCategoryId!: string;

  @IsString()
  name!: string;

  @IsUUID()
  functionalAreaId!: string;

  @IsBoolean()
  disabled!: boolean;
}

export class GroupedGuideMaterialCategoryDTO {
  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => FunctionalAreaDTO)
  functionalArea!: FunctionalAreaDTO;

  @ValidateNested({ each: true })
  @Type(() => GuideMaterialCategoryDTO)
  guideMaterialCategories!: GuideMaterialCategoryDTO[];
}

export class GetGuideMaterialCategoryDTO {
  @IsBoolean()
  @IsOptional()
  includeDisabledCategories?: boolean;

  @IsBoolean()
  @IsOptional()
  includeDisabledFunctionalAreas?: boolean;
}

export class UpdateGuideMaterialCategoryDTO {
  @IsUUID()
  guideMaterialCategoryId!: string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsBoolean()
  @IsOptional()
  disabled?: boolean;
}

export class CreateGuideMaterialCategoryDTO {
  @IsUUID()
  functionalAreaId!: string;

  @IsString()
  name!: string;
}

export class UpdateSortGuideMaterialCategoriesDTO {
  /**
   * Important: the guideMaterialCategoryIds have all to be from the same functional area.
   * Else there is no guarantee what happens.
   */
  @IsUUID(undefined, { each: true })
  guideMaterialCategoryIds!: string[];
}
