import { FunctionalAreaDepartmentReportDTO, FunctionalAreaDTO, CreateFunctionalAreaDTO, UpdateFunctionalAreaDTO } from 'dto/functionalArea';
import { get, post, patch, del } from './common';
import { validateArray, validate, validateBool } from './validation';

export const getFunctionalAreaReport = async (functionalAreaId: string) => {
  const departments = await get('/functional-area/report', { functionalAreaId }).then(response => response.data);
  return validateArray(FunctionalAreaDepartmentReportDTO, departments);
};

export const getFunctionalAreas = async () => {
  const functionalAreas = await get('/functional-areas').then(response => response.data);
  return validateArray(FunctionalAreaDTO, functionalAreas);
};

export const createFunctionalArea = async (getFunctionalAreasOptions: CreateFunctionalAreaDTO) => {
  const functionalArea = await post('/functional-area', getFunctionalAreasOptions).then(response => response.data);
  return validate(FunctionalAreaDTO, functionalArea);
};

export const updateFunctionalArea = async (getFunctionalAreasOptions: UpdateFunctionalAreaDTO) => {
  const functionalArea = await patch('/functional-area', getFunctionalAreasOptions).then(response => response.data);
  return validate(FunctionalAreaDTO, functionalArea);
};

export const deleteFunctionalArea = async (functionalAreaId: string) => {
  return del('/guides/material', { functionalAreaId }).then(response => validateBool(response.data));
};
