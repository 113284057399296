import React from 'react';
import { CSSTransition } from 'react-transition-group';

import FlyoutButtonBar from '../FlyoutButtonBar';
import './SmallFlyoutContainer.css';

interface Props {
  title?: string;
  isOpen: boolean;
  children: JSX.Element[] | JSX.Element;
  tip?: JSX.Element;
  // only when these are filled there is a button bar
  onSubmit?: () => void;
  closePopUp: () => void;
  isAllowedToSubmit?: boolean;
  icon?: JSX.Element;
  submitLabel?: string;
  cancelLabel?: string;
  warning?: boolean;
  closeAsSubmit?: boolean;
  headerButton?: JSX.Element | null;
  className?: string;
  isLoading?: boolean;
  itemRef?: React.RefObject<HTMLDivElement>;
}

export default (props: Props) => (
  <CSSTransition in={props.isOpen} timeout={500} classNames="flyout" unmountOnExit>
    <div className={` ${props.className || ''} `} style={{ display: 'flex' }}>
      <div className="flyout-container-base flyout-container flyout_histroy-copy" style={{ display: 'block' }} ref={props.itemRef}>
        {props.icon && props.title && (
          <div className="flyout_header flylout-header">
            <div className="div-block-53 flyout-image-container">
              <div className="image_wrapper_40">{props.icon}</div>
              <div>{props.title}</div>
            </div>
            {props.headerButton && props.headerButton}
          </div>
        )}
        {!props.icon && props.title && <div className="t2-bold">{props.title}</div>}
        <div className="div-block-149">
          <div className="div-block-131 in_personensuche">
            <div className="div-block-127 white ">{props.children}</div>
          </div>
        </div>

        {props.cancelLabel && (
          <FlyoutButtonBar
            closePopUp={props.closePopUp}
            onSubmit={props.onSubmit}
            cancelLabel={props.cancelLabel}
            submitLabel={props.submitLabel}
            isAllowedToSubmit={props.isAllowedToSubmit}
            warning={props.warning}
            tip={props.tip}
            closeAsSubmit={props.closeAsSubmit}
            isLoading={props.isLoading}
          />
        )}
      </div>
    </div>
  </CSSTransition>
);
