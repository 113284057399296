import React from 'react';
import GetCameraImageButton from 'components/GetCameraImageButton';
import { saveFileContentElement } from 'api/file';
import { getFileName, getMediaType, getFileExtension } from 'util/fileUtils';
import { FormikValues, FormikProps } from 'formik';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import { MixedContentElementMediaDTO } from 'components/UploadMultipleFiles/MixedContentElementMediaDTO';

import { ProcessingStatus } from 'dto/file';

interface Props extends React.HTMLProps<HTMLDivElement> {
  meta: FormikProps<FormikValues>;
  isExpanded: boolean | null;
  isAddable?: boolean;
}

const GetCameraImageButtonWrapper = ({ meta, isExpanded, isAddable = false }: Props) => {
  const onSaveFile = async (file: File) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const progressFileId = (file.lastModified + Math.random() * 100).toString();

    const response = await saveFileContentElement(bodyFormData, e => {
      const extension = getFileExtension(file.name);
      const newFile: MixedContentElementMediaDTO = {
        ...file,
        contentType: getMediaType(extension),
        progress: Math.round((e.loaded / e.total) * 100),
        fileId: progressFileId,
        status: ProcessingStatus.Uploading,
        fullUrl: '',
        originalFileName: ''
      };
      meta.setFieldValue('contentElementMedias', [...meta.values.contentElementMedias, newFile]);
    });
    if (response) {
      const title = getFileName(file.name, file.type);
      const uploadedFile: ContentElementMediaDTO = {
        ...response,
        title
      };
      meta.setFieldValue('contentElementMedias', [...meta.values.contentElementMedias, uploadedFile]);
    }
  };

  return (
    <GetCameraImageButton
      className={isExpanded ? 'expanded' : 'collapsed'}
      buttonStyle="comment"
      onSaveFile={onSaveFile}
      isAddable={isAddable}
    />
  );
};

export default GetCameraImageButtonWrapper;
