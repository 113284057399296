import React from 'react';
import Loader from 'react-loader-spinner';
import './Button.css';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: any;
  type?: 'button' | 'submit' | 'reset';
  icon?: string;
  isLoading?: boolean;
}

const Button = ({ children, type = 'submit', className = '', icon, isLoading = false, ...rest }: Props) => (
  // eslint-disable-next-line react/button-has-type
  <button className={`button ${className} ${icon ? 'with-icon' : ''}`} type={type} {...rest}>
    {children} {icon && <img src={icon} alt="" />}
    {isLoading && (
      <div className="button-loader-wrapper">
        <Loader type="Puff" color="#FFF" height={20} width={20} />
      </div>
    )}
  </button>
);
export default Button;
