import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import NavSectionButton from 'components/NavSection/NavSectionButton';

interface Props {
  currentPath: string;
}

const GuidesNavigation = observer(({ currentPath }: Props) => {
  const { searchStore } = useStores();
  const { t } = useTranslation('guide');

  const handlerOpenSearchPanel = () => {
    searchStore.openSearchPanel();
  };

  return (
    <>
      <NavSectionButton icon="icon_saved-items_16" label={t('subMenu.guide')} routePath="/guides" currentPath={currentPath} />
      <NavSectionButton icon="icon_slice_16" label={t('subMenu.singleMaterial')} routePath="/guides/materials" currentPath={currentPath} />
      <NavSectionButton icon="icon_archive_16" label={t('subMenu.instruments')} routePath="/guides/instruments" currentPath={currentPath} />
      <NavSectionButton
        icon="icon_sticker_16"
        label={t('subMenu.implants')}
        routePath="/guides/groups/implants"
        currentPath={currentPath}
      />
      <NavSectionButton
        icon="icon_storage-unit_16"
        label={t('subMenu.materialSets')}
        routePath="/guides/groups/sets"
        currentPath={currentPath}
      />
      <NavSectionButton icon="icon_oil-2_16" label={t('subMenu.solutions')} routePath="/guides/groups/medicals" currentPath={currentPath} />
      <NavSectionButton
        icon="icon_app_16"
        label={t('subMenu.rentalEquipment')}
        routePath="/guides/groups/rentalmaterials"
        currentPath={currentPath}
      />
      <div className="nav_more">
        <div className="txt_section_nav for_mobile">{t('more')}</div>
        <div className="desktop-only">
          <NavSectionButton
            icon="icon_magnifier_16"
            label={t('subMenu.materialSearch')}
            onClick={handlerOpenSearchPanel}
            className="btn_section desktop"
          />
        </div>
      </div>
    </>
  );
});

export default GuidesNavigation;
