import { StylesConfig, OptionsType, OptionTypeBase } from 'react-select';

export const customStyles: StylesConfig<any, false> = {
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    background: state.isFocused ? '#4d7cfe' : 'white',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 'lighter',
    color: state.isFocused ? 'white' : '#1b2733',

    padding: '12px 20px '
  }),
  control: provided => ({
    ...provided,
    cursor: 'pointer',
    height: '51px',
    marginTop: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 1',
    border: '1px solid #e6e8eb',
    borderRadius: '5px',
    backgroundColor: '#fff',
    color: '#c1c7cd',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 'lighter'
  }),
  singleValue: provided => ({
    ...provided
  }),
  container: provided => ({
    ...provided
  }),
  menu: provided => ({
    ...provided,
    zIndex: 999
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: provided => ({
    ...provided,
    paddingRight: '12px' // 8px are given
  })
};

export const getSelectItemById = (options: OptionsType<OptionTypeBase>, selectedId: string, isGrouped: boolean) => {
  if (isGrouped) {
    let selectedValue: OptionTypeBase = {};
    options.map((option: OptionTypeBase) => {
      const data = option.options.find((o: OptionTypeBase) => o.value === selectedId);
      if (data) {
        selectedValue = data;
      }
      return null;
    });
    if (selectedValue.group && selectedValue.label) {
      return { ...selectedValue, label: `${selectedValue.group} |  ${selectedValue.label}` };
    }
  }
  const selectedValue = options.find((option: OptionTypeBase) => option.value === selectedId);
  if (selectedValue) {
    return selectedValue;
  }
  return undefined;
};
