import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomInput from 'components/CustomInput';
import FlyoutContainer from 'components/FlyoutContainer';
import { CreateUsedMaterialDTO } from 'dto/usedMaterial';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  usedMaterial: CreateUsedMaterialDTO;

  // Don't use the material context for them to prevent a cyclic dependency.
  getLot: (surgeryGuideMaterialId: string, packageId?: string, groupMaterialId?: string) => string | undefined;
  setLot: (lotNumber: string | undefined, surgeryGuideMaterialId: string, groupMaterialId?: string, packageId?: string) => void;
}

const EditLotNumberFlyoutV2 = ({ isOpen, handleClose, usedMaterial, getLot, setLot }: Props) => {
  const { t } = useTranslation('briefing');
  const [lotValue, setLotValue] = useState<string>('');

  useEffect(() => {
    const lot = getLot(usedMaterial.surgeryGuideMaterialId, usedMaterial.packageId, usedMaterial.groupMaterialId);
    setLotValue(lot || '');
  }, [usedMaterial.surgeryGuideMaterialId, usedMaterial.packageId, usedMaterial.groupMaterialId, usedMaterial, getLot]);

  const handleSubmit = useCallback(() => {
    setLot(lotValue, usedMaterial.surgeryGuideMaterialId, usedMaterial.groupMaterialId, usedMaterial.packageId);
    handleClose();
  }, [handleClose, lotValue, setLot, usedMaterial.groupMaterialId, usedMaterial.packageId, usedMaterial.surgeryGuideMaterialId]);

  return (
    <FlyoutContainer
      icon={<img src="images/icon_tag.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={isOpen}
      closePopUp={handleClose}
      cancelLabel={t('briefingMaterials.editLotNumberFlyout.button.cancel')}
      onSubmit={handleSubmit}
      submitLabel={t('briefingMaterials.editLotNumberFlyout.button.submit')}
      isAllowedToSubmit={lotValue !== ''}
      title={`${t('briefingMaterials.editLotNumberFlyout.headline')}V2`}
    >
      <div className="flyout_box">
        <div>{t('briefingMaterials.editLotNumberFlyout.label.lotNumberName')}</div>
        <CustomInput
          value={lotValue}
          onChange={event => setLotValue(event.target.value)}
          placeholder={t('briefingMaterials.editLotNumberFlyout.label.lotNumberPlaceholder')}
        />
      </div>
    </FlyoutContainer>
  );
};

export default EditLotNumberFlyoutV2;
