import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';

import FlyoutContainer from 'components/FlyoutContainer';
import Picture from 'components/Picture';

const DeleteStorageLocationFlyout = observer(() => {
  const { t } = useTranslation('storageLocations');
  const { storageLocationStore } = useStores();
  const closePopUp = () => storageLocationStore.setIsDeleteFlyoutOpen(false);
  const onSubmit = async () => {
    if (storageLocationStore.selectedStorageLocation) {
      await storageLocationStore.deleteStorageLocation(
        storageLocationStore.selectedStorageLocation.storageLocationId,
        storageLocationStore.currentIndex,
        storageLocationStore.selectedStorageLocation.parentStorageLocationId
      );
    }
    closePopUp();
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_delete_prompt.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={storageLocationStore.isDeleteFlyoutOpen}
      closePopUp={closePopUp}
      cancelLabel={t('deleteFlyout.button.cancel')}
      onSubmit={onSubmit}
      submitLabel={t(`deleteFlyout.button.submit`)}
      isAllowedToSubmit
      title={t(`deleteFlyout.headline`)}
      warning
    >
      <div className="flyout_box">
        <div className="h4">{t(`deleteFlyout.label.description`)}</div>
        {storageLocationStore.selectedStorageLocation ? (
          <div className="list_item_book in_promp">
            <div className="image_wrapper_50">
              <div className="image_border image-border" />
              <Picture src={storageLocationStore.selectedStorageLocation.pictureThumbnail} width={50} alt="" className="image_circle_50" />
            </div>
            <div className="book_info in_prompt">
              <div>{storageLocationStore.selectedStorageLocation.name}</div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </FlyoutContainer>
  );
});
export default DeleteStorageLocationFlyout;
