import React from 'react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import Button from 'components/Form/Button';

const ReportHead = () => {
  const { domainStore } = useStores();
  const { t } = useTranslation('reports');
  return (
    <div className="report_head report-head">
      <div className="board_head">
        <img src="images/icon_reports.jpg" alt="" className="image_circle_40" />
        <div className="title_info in_reports">
          <div>{t('headline')}</div>
          <div className="t2 top-margin">{domainStore.currentFunctionalArea.shortName}</div>
        </div>
      </div>
      <Button className="btn_small_report" onClick={() => domainStore.setShowSwitchDomainFlyout(true)}>
        <div className="txt_dropout_1">{t('button.switchDepartment')}</div>
      </Button>
    </div>
  );
};

export default ReportHead;
