import React from 'react';

import { DepartmentGuidesDTO } from 'dto/guide';
import GuideListGroup from './GuideListGroup';

interface Props {
  additionalGuides: DepartmentGuidesDTO[];
}

const AdditionalGuideListContent = (props: Props) => (
  <div className="dropout_content content-stretc">
    {props.additionalGuides.map(guideGroup => {
      const locationName = guideGroup.department.locationShortName;
      const departmentName = guideGroup.department.name;
      const functionalAreaName = guideGroup.department.functionalAreaShortName;

      return (
        <GuideListGroup
          key={guideGroup.department.departmentId}
          guideList={guideGroup.guides}
          groupName={`${locationName} | ${functionalAreaName} | ${departmentName}`}
          className="h1 top-margin"
        />
      );
    })}
  </div>
);

export default AdditionalGuideListContent;
