import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Form/Button';

interface Props {
  closePopUp: () => void;
}

const SearchNav = (props: Props) => {
  const { t } = useTranslation('materialSearch');

  return (
    <div className="search_navigation search-navigation">
      <Button id="55bc5353-e2dd-a73f-26e7-e7a523144e46" className="btn_big_add active" onClick={props.closePopUp}>
        <div>{t('searchNav.btnCancel')}</div>
      </Button>
    </div>
  );
};

export default SearchNav;
