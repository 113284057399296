import React from 'react';
import { observer } from 'mobx-react';
import Picture from 'components/Picture';
import { useStores } from 'util/mobx/stores';
import { GroupMaterialDTO } from 'dto/groupMaterial';
import Popover from 'components/Popover';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import { TagDTO } from 'dto/tag';
import { CheckStatus } from 'dto/materialSet';
import { Can, subjectArea, actions } from 'casl/setupCaslAbility';
import MaterialValueTags from 'components/MaterialValueTags';
import GetCameraImageButton from 'components/GetCameraImageButton';
import { subject } from '@casl/ability';
import Tags from 'components/Tags/Tags';
import GroupMaterialListItemMenu from './GroupMaterialListItemMenu';
import CheckListItemLottie from './CheckListItemLottie';
import './EditableGroupMaterialListItem.css';

interface Props {
  groupMaterial: GroupMaterialDTO;
  onGroupMaterialDelete: (groupMaterialId: string) => void;
  groupType: string;
}

const GroupMaterialListItem = observer(({ groupMaterial, onGroupMaterialDelete, groupType }: Props) => {
  const { guideDetailDrawerStore, materialSetsStore, materialStore, domainStore } = useStores();

  const onTagCreate = (tagName: string) => {
    return materialSetsStore.createGroupMaterialTag(groupMaterial.groupMaterialId, tagName);
  };

  const onTagDelete = (tagId: string) => {
    return materialSetsStore.deleteTag(tagId);
  };

  const onTagUpdate = (tag: TagDTO) => {
    return materialSetsStore.updateTag(tag);
  };

  const onDeleteFromSetGroup = async (handleClose: () => void) => {
    await materialSetsStore.deleteGroupMaterial(groupMaterial.groupMaterialId);
    onGroupMaterialDelete(groupMaterial.groupMaterialId);
    handleClose();
  };

  const onReplace = () => {
    if (groupMaterial.material) {
      guideDetailDrawerStore.setSelectedMaterial(groupMaterial.material);
      guideDetailDrawerStore.setIsOpen(true);
    } else {
      throw new Error('NOT IMPLEMENTED');
    }
  };

  const onUpdateFile = async (fileId: string) => {
    if (groupMaterial.material) {
      await materialStore.updateMaterial({ materialId: groupMaterial.material.materialId, pictureFileId: fileId });
    } else {
      throw new Error('NOT IMPLEMENTED');
    }
    await materialSetsStore.refreshMaterialSets();
    await materialSetsStore.refreshMaterialSet();
  };
  const onAddToCheckList = (status: CheckStatus) => {
    materialSetsStore.addGroupMaterialCheckItem(status, groupMaterial.groupMaterialId);
  };

  return (
    <div className="list_item_material margin-right">
      <div className="item_count" />
      <div className="image_wrapper_50">
        <div className="image_border image-border" />
        {groupMaterial.material?.picture ? (
          <>
            <Picture src={groupMaterial.material.pictureThumbnail} width={50} alt="" className="image_circle_50" />
          </>
        ) : (
          <GetCameraImageButton buttonStyle="icon" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable withLoadingBar />
        )}
        <CheckListItemLottie
          groupMaterialId={groupMaterial.groupMaterialId}
          onStatusChanged={onAddToCheckList}
          notEditable={!!materialSetsStore.selectedSetCheck}
          inSublist
        />
      </div>
      <div className="material_info group-material-info">
        <div className="material_text">
          <div>{groupMaterial.material?.name || groupMaterial.template?.name || 'NOT IMPLEMENTED'}</div>
          <Tags
            tags={groupMaterial.tags}
            onTagCreate={onTagCreate}
            onTagUpdate={onTagUpdate}
            onTagDelete={onTagDelete}
            editable={!materialSetsStore.isCheckListVisible}
          />
        </div>
        <MaterialValueTags material={groupMaterial.material} />
        {!materialSetsStore.isCheckListVisible && (
          <Can I={actions.see} this={subject(subjectArea.groupMaterialMenu, { departmentId: domainStore.currentDepartment.id })}>
            <Popover trigger={<ArrowDotsVerticalIcon dotsOnly />}>
              {({ handleClose, isOpen }) => (
                <GroupMaterialListItemMenu
                  handleClose={handleClose}
                  isOpen={!!isOpen}
                  handleDelete={() => onDeleteFromSetGroup(handleClose)}
                  handleReplace={onReplace}
                  groupType={groupType}
                />
              )}
            </Popover>
          </Can>
        )}
      </div>
    </div>
  );
});

export default GroupMaterialListItem;
