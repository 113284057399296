/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { observer } from 'mobx-react';
import { FullGuideDTO } from 'dto/guide';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';

import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import './ItemActions.css';
import PopoverV2 from 'components/PopoverV2/PopoverV2';

interface Props {
  guide: FullGuideDTO;
  actions?: JSX.Element;
}

const ItemActions = observer(({ guide }: Props) => {
  const { materialStore, searchStore, guideStore } = useStores();
  const { t } = useTranslation('material');
  const { t: searchTranslation } = useTranslation('materialSearch');

  const handleSwitchSearchClick = () => {
    if (materialStore.materialDetail) {
      searchStore.openSearchPanel(m => {
        guideStore.replaceMaterialInGuide(m, { materialId: materialStore.materialDetail?.materialId }, guide.guideId).then(() => {
          if (materialStore.materialId) {
            materialStore.loadMaterialGuides(materialStore.materialId);
          }
        });
      }, searchTranslation('searchItem.replace'));
    }
  };

  const handleDeleteGuideMaterialClick = async () => {
    if (materialStore.materialDetail) {
      await guideStore.deleteGuideMaterials({
        guideId: guide.guideId,
        materialId: materialStore.materialDetail.materialId
      });
      materialStore.loadMaterialGuides(materialStore.materialDetail.materialId);
    }
  };

  return (
    <PopoverV2 customTrigger={<img src="images/icon_menu_dots.svg" alt="" className="image_dots_post image-dots-post" />}>
      {({ handleClose, isOpen }) => (
        <>
          <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
            <ItemMenuButton
              label={t('materialGlobalFunctions.dropdownMenu.replaceMaterial')}
              icon="icon_find_16.svg"
              isInPopover
              handleClick={handleSwitchSearchClick}
            />
            <ItemMenuButton
              label={t('materialGlobalFunctions.dropdownMenu.deleteMaterial')}
              icon="icon_bin_16.svg"
              warning
              isInPopover
              handleClick={handleDeleteGuideMaterialClick}
            />
          </ItemMenu>
        </>
      )}
    </PopoverV2>
  );
});

export default ItemActions;
