import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import PopoverV2 from 'components/PopoverV2/PopoverV2';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import { SetGroupWithTagsDTO } from 'dto/setGroup';
import { useMaterialSetListContext } from '../materialSetListContext';

interface Props {
  setGroup: SetGroupWithTagsDTO;
}

export const SetGroupListItemMenu = ({ setGroup }: Props) => {
  const { onSetGroupUpdate, onSetGroupDelete, groupType, checksEnabled, readonly } = useMaterialSetListContext();

  const { t } = useTranslation(groupType);

  const handleEditSetGroup = useCallback(() => {
    if (!onSetGroupUpdate) {
      return;
    }
    onSetGroupUpdate(setGroup);
  }, [onSetGroupUpdate, setGroup]);

  const handleDeleteSetGroup = useCallback(() => {
    if (!onSetGroupDelete) {
      return;
    }
    onSetGroupDelete(setGroup.setGroupId);
  }, [onSetGroupDelete, setGroup.setGroupId]);

  return !checksEnabled && !readonly ? (
    <PopoverV2 customTrigger={<ArrowDotsVerticalIcon dotsOnly />}>
      {({ handleClose, isOpen }) => (
        <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
          <ItemMenuButton
            propagate
            label={t('groupsList.setGroupMenu.rename')}
            icon="icon_background_16.svg"
            isInPopover
            handleClick={handleEditSetGroup}
          />
          <ItemMenuButton
            propagate
            label={t('groupsList.setGroupMenu.changePicture')}
            icon="icon_camera-2_16.svg"
            isInPopover
            handleClick={handleEditSetGroup}
          />
          <ItemMenuButton
            propagate
            label={t('groupsList.setGroupMenu.delete')}
            icon="icon_bin_16.svg"
            warning
            isInPopover
            handleClick={handleDeleteSetGroup}
          />
        </ItemMenu>
      )}
    </PopoverV2>
  ) : null;
};
