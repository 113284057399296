import React from 'react';
import Button from 'components/Form/Button';
import { useTranslation } from 'react-i18next';

export enum SurgeryHistoryFilterTypes {
  guides = 'guides',
  materials = 'materials',
  procedures = 'procedures'
}

interface Props {
  onFilterSelect: (filter: SurgeryHistoryFilterTypes) => void;
  selectedFilter?: SurgeryHistoryFilterTypes;
}

const SurgeryHistoryFilters = ({ onFilterSelect, selectedFilter }: Props) => {
  const { t } = useTranslation('surgery');
  return (
    <div className="tab_filter tab-filter">
      <Button
        className={`btn_filter btn-filter ${selectedFilter === SurgeryHistoryFilterTypes.guides ? 'active' : ''}`}
        onClick={() => onFilterSelect(SurgeryHistoryFilterTypes.guides)}
      >
        <div>{t('chatAndHistory.historyFilters.guides')}</div>
      </Button>
      <Button
        className={`btn_filter btn-filter ${selectedFilter === SurgeryHistoryFilterTypes.materials ? 'active' : ''}`}
        onClick={() => onFilterSelect(SurgeryHistoryFilterTypes.materials)}
      >
        <div>{t('chatAndHistory.historyFilters.materials')}</div>
      </Button>
      <Button
        className={`btn_filter btn-filter ${selectedFilter === SurgeryHistoryFilterTypes.procedures ? 'active' : ''}`}
        onClick={() => onFilterSelect(SurgeryHistoryFilterTypes.procedures)}
      >
        <div>{t('chatAndHistory.historyFilters.procedures')}</div>
      </Button>
    </div>
  );
};

export default SurgeryHistoryFilters;
