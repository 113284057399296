import { CreateGuideMaterialDTO } from 'dto/guide';
import { GroupedGuideMaterialCategoryDTO } from 'dto/guideMaterialCategory';
import { MaterialType } from 'dto/material';
import { CreateSurgeryGuideMaterialDTO } from 'dto/surgeryGuide';
import { AnyMaterial } from 'stores/searchStore';

export function buildNewGuideMaterial(guideId: string, materialLike: AnyMaterial, categoryId: string) {
  let guideMaterial: CreateGuideMaterialDTO | undefined;

  // check which materialLike --> if materialSet different way of adding (see SearchMaterialSetItem)
  if (materialLike.material) {
    // checks if search result is a package or only a single material
    if (materialLike.material.materialType === MaterialType.instrument) {
      throw new Error('instruments cannot be added as material');
    } else {
      guideMaterial = {
        guideId,
        materialId: materialLike.material.materialId,
        guideMaterialCategoryId: categoryId,
        amount: 1,
        notes: {
          unpackOnInstruction: false
        }
      };
    }
  }
  if (materialLike.pack) {
    guideMaterial = {
      guideId,
      templateId: materialLike.pack.templateId,
      guideMaterialCategoryId: categoryId,
      amount: 1,
      notes: {
        unpackOnInstruction: false
      }
    };
  }
  if (materialLike.materialSet)
    guideMaterial = {
      guideId,
      materialSetId: materialLike.materialSet.materialSetId,
      guideMaterialCategoryId: categoryId,
      amount: 1,
      notes: {
        unpackOnInstruction: false
      }
    };

  return guideMaterial;
}

export function buildNewSurgeryGuideMaterial(
  surgeryGuideId: string,
  materialLike: AnyMaterial,
  categoryId: string
): CreateSurgeryGuideMaterialDTO | undefined {
  const newMaterial = buildNewGuideMaterial(surgeryGuideId, materialLike, categoryId);
  if (!newMaterial) {
    return undefined;
  }

  return {
    amount: newMaterial.amount,
    guideMaterialCategoryId: newMaterial.guideMaterialCategoryId,
    notes: newMaterial.notes,
    materialId: newMaterial.materialId,
    materialSetId: newMaterial.materialSetId,
    templateId: newMaterial.templateId,
    surgeryGuideId
  };
}

/**
 * TODO: provide api to get the categories only for one functionalArea.
 * @param functionalAreaId
 */
export function filterCategory(functionalAreaId: string, groupedGuideMaterialCategories?: GroupedGuideMaterialCategoryDTO[]) {
  // Map the correct functional area out of the result.
  if (!groupedGuideMaterialCategories) {
    return undefined;
  }
  const category = groupedGuideMaterialCategories.find(c => c.functionalArea.functionalAreaId === functionalAreaId);
  return category?.guideMaterialCategories;
}
