import { GuideMaterialLikeFlatDTO } from 'dto/guide';
import { PackagingType } from 'dto/package';
import React, { useCallback } from 'react';
import { SingleTemplateItem } from '../ListItems/Instrument/SingleTemplate';
import { TemplateItem } from '../ListItems/Instrument/TemplateItem';
import { MaterialItem } from '../ListItems/Material/MaterialItem';
import { MaterialSetItem } from '../ListItems/MaterialSet/MaterialSetItem';
import { UsedMaterialCheck } from './UsedMaterial/UsedMaterialCheck';
import { useGuideMaterialListContext } from '../guideMaterialListContext';
import { useUsedMaterialContext } from './UsedMaterial/UsedMaterialContext';
import { GuideMaterialCheckPrefixRenderer } from '../ListItems/checkRenderer';
import { GuideCountCheck } from './UsedMaterial/GuideCountCheck';
import { SurgeryGuideMaterialListItemRenderer } from './SurgeryGuideMaterialListItemRenderer';

const CheckPrefix: GuideMaterialCheckPrefixRenderer = ({ guideMaterialId, packageId, groupMaterialId }) => {
  const { openLotDialog } = useUsedMaterialContext();
  const handleAddLotNumber = useCallback(() => {
    openLotDialog({ surgeryGuideMaterialId: guideMaterialId, packageId, groupMaterialId });
  }, [groupMaterialId, guideMaterialId, openLotDialog, packageId]);

  return (
    <UsedMaterialCheck
      surgeryGuideMaterialId={guideMaterialId}
      packageId={packageId}
      onAddLotNumber={handleAddLotNumber}
      groupMaterialId={groupMaterialId}
    />
  );
};

interface GuideMaterialLikeItemProps {
  guideMaterial: GuideMaterialLikeFlatDTO;
}

export const SurgeryGuideMaterialLikeItem: React.FC<GuideMaterialLikeItemProps> = ({ guideMaterial }) => {
  const { lazyLoadScrollContainer, onGuideMaterialClick } = useGuideMaterialListContext();
  const { openLotDialog } = useUsedMaterialContext();

  const handleClickMaterial = useCallback(() => {
    if (!onGuideMaterialClick) {
      return;
    }
    onGuideMaterialClick(guideMaterial);
  }, [guideMaterial, onGuideMaterialClick]);

  const handleClickTemplate = useCallback(
    (
      parent: GuideMaterialLikeFlatDTO,
      id: {
        packageId?: string | undefined;
        materialId?: string | undefined;
      }
    ) => {
      if (!onGuideMaterialClick) {
        return;
      }
      onGuideMaterialClick(parent, { packageId: id.packageId, materialId: id.materialId });
    },
    [onGuideMaterialClick]
  );

  const handleAddLotNumber = useCallback(() => {
    openLotDialog({ surgeryGuideMaterialId: guideMaterial.guideMaterialId });
  }, [guideMaterial.guideMaterialId, openLotDialog]);

  let item = <>UNKNOWN</>;

  if (guideMaterial.material) {
    item = (
      <MaterialItem
        material={guideMaterial.material}
        guideMaterial={guideMaterial}
        onClick={handleClickMaterial}
        lazyLoadScrollContainer={lazyLoadScrollContainer}
        onAddLotNumber={handleAddLotNumber}
        prefix={<UsedMaterialCheck surgeryGuideMaterialId={guideMaterial.guideMaterialId} onAddLotNumber={handleAddLotNumber} />}
      />
    );
  }

  if (guideMaterial.template && guideMaterial.template.packagingType === PackagingType.Multi) {
    item = (
      <TemplateItem
        ListItemRenderer={SurgeryGuideMaterialListItemRenderer}
        guideMaterial={guideMaterial}
        onClick={handleClickTemplate}
        PackagePrefix={CheckPrefix}
        prefix={<GuideCountCheck guideMaterial={guideMaterial} />}
      />
    );
  }

  if (guideMaterial.template && guideMaterial.template.packagingType === PackagingType.Single) {
    item = (
      <SingleTemplateItem
        ListItemRenderer={SurgeryGuideMaterialListItemRenderer}
        guideMaterial={guideMaterial}
        PackagePrefix={CheckPrefix}
        prefix={<GuideCountCheck guideMaterial={guideMaterial} />}
        onClick={handleClickTemplate}
      />
    );
  }

  if (guideMaterial.materialSet) {
    item = (
      <MaterialSetItem
        guideMaterial={guideMaterial}
        onClick={handleClickMaterial}
        onAddLotNumber={handleAddLotNumber}
        CheckPrefix={CheckPrefix}
      />
    );
  }

  return <div className="surgery-guide-material-category">{item}</div>;
};
