import React from 'react';
import './Check.css';

interface Props {
  onClick?: () => void;
  checked?: boolean;
  /**
   * Set to true, for an non-clickable empty placeholder
   */
  empty?: boolean;
}

export const Check: React.FC<Props> = ({ onClick, checked = false, empty = false }) => {
  return (
    <>
      {empty ? (
        <button className="check" type="button" style={{ zIndex: 10, cursor: 'default' }}>
          <img src="images/check-white-empty.svg" alt="" />
        </button>
      ) : (
        <button className="check" type="button" onClick={onClick} style={{ zIndex: 10 }}>
          <img src={checked ? 'images/check-green.svg' : 'images/check-white.svg'} alt="" />
        </button>
      )}
    </>
  );
};
