/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { Type } from 'class-transformer';
import { IsInt, IsOptional, IsString, IsUUID, ValidateNested } from 'class-validator';
import 'reflect-metadata';
import { PagingCountDTO } from './generic';
import { MaterialDTO } from './material';

export class InstrumentDTO {
  @IsUUID()
  instrumentId!: string;

  @ValidateNested()
  @Type(() => MaterialDTO)
  material!: MaterialDTO;

  @IsOptional()
  @IsString()
  description?: string;

  @IsInt()
  amount!: number;

  @IsInt()
  @IsOptional()
  expectedAmount?: number;

  @IsString()
  @IsOptional()
  lastSynchronisation?: string;
}

export class InstrumentsDTO extends PagingCountDTO {
  @ValidateNested({ each: true })
  @Type(() => InstrumentDTO)
  instruments!: InstrumentDTO[];
}
