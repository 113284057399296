import React from 'react';
import Document from 'components/Document';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';

interface Props {
  file: ContentElementMediaDTO;
  inDrawer?: boolean;
  noPreview?: boolean;
}

export default ({ file, inDrawer, noPreview }: Props) => {
  return (
    <>
      <div className="data_file">
        <Document
          file={file}
          type={file.contentType}
          noPreview={noPreview}
          title={inDrawer ? undefined : file.title}
          showDownloadButton={!noPreview}
        />
      </div>
    </>
  );
};
