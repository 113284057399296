import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import SurgeryProcedurePreview from './SurgeryProcedurePreview';

const SurgeryProcedureDrawerSidebar = observer(() => {
  const { surgeryGuideStore } = useStores();
  const onSelectProcedureIndex = (procedureIndex: number) => surgeryGuideStore.setProcedureIndexInView(procedureIndex);
  const group = surgeryGuideStore.selectedGroup;
  return (
    <div className="div-block-161 pilot-sidebar">
      {group &&
        group.surgeryGuideProcedures.map((surgeryProcedure, index) => (
          <SurgeryProcedurePreview
            selected={index === surgeryGuideStore.procedureIndexInView}
            onClick={() => onSelectProcedureIndex(index)}
            surgeryProcedure={surgeryProcedure}
            index={index + 1}
            length={group.surgeryGuideProcedures.length}
          />
        ))}
    </div>
  );
});

export default SurgeryProcedureDrawerSidebar;
