/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsUUID, IsString, ValidateIf } from 'class-validator';
import 'reflect-metadata';

export class TagAssociationDTO {
  @ValidateIf(dto => dto.groupMaterialId === undefined)
  @IsUUID()
  setGroupId?: string;

  @ValidateIf(dto => dto.setGroupId === undefined)
  @IsUUID()
  groupMaterialId?: string;
}

export class TagDTO extends TagAssociationDTO {
  @IsUUID()
  tagId!: string;

  @IsString()
  name!: string;
}

export class CreateTagDTO {
  @IsString()
  name!: string;

  @ValidateIf(dto => dto.groupMaterialId === undefined)
  @IsUUID()
  setGroupId?: string;

  @ValidateIf(dto => dto.setGroupId === undefined)
  @IsUUID()
  groupMaterialId?: string;
}
export class UpdateTagDTO {
  @IsUUID()
  tagId!: string;

  @IsString()
  name!: string;
}
