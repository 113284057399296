import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { HistoryActionType, HistoryDTO } from 'dto/history';
import { TFunction } from 'i18next';
import ProcedureContentElementHistoryIconsFactory from 'pages/guideProcedure/ProcedureContentElementHistory/ProcedureContentElementHistoryIcons';
import SurgeryHistoryItem from './SurgeryHistoryItem';
import GuideHistoryItem from './GuideHistoryItem';
import { SurgeryHistoryFilterTypes } from '../SurgeryHistoryFilters';
import './SurgeryHistory.css';

interface Props {
  selectedFilter?: SurgeryHistoryFilterTypes;
}

const getHistoryDescription = (translate: TFunction, item: HistoryDTO) => {
  switch (item.action) {
    case HistoryActionType.create:
      return translate('chatAndHistory.historyGuides.created', { guideName: item.targetName });
    case HistoryActionType.delete:
      return translate('chatAndHistory.historyGuides.deleted', { guideName: item.targetName });
    default:
      return ``;
  }
};

const SurgeryHistory = observer(({ selectedFilter }: Props) => {
  const { t } = useTranslation('surgery');
  const { surgeryStore } = useStores();

  return (
    <div className="list_item_flex">
      {(selectedFilter === SurgeryHistoryFilterTypes.materials || selectedFilter === SurgeryHistoryFilterTypes.procedures) &&
        surgeryStore.briefingHistory?.surgeryGuideHistories.map(surgeryGuideHistory => (
          <GuideHistoryItem surgeryGuideHistory={surgeryGuideHistory} selectedFilter={selectedFilter} />
        ))}
      {surgeryStore.briefingHistory?.briefingHistories.map(briefingHistory => {
        if (selectedFilter === SurgeryHistoryFilterTypes.guides) {
          return (
            <SurgeryHistoryItem
              icon={<ProcedureContentElementHistoryIconsFactory action={briefingHistory.action} />}
              className="pl-20"
              item={briefingHistory}
              description={getHistoryDescription(t, briefingHistory)}
              key={briefingHistory.historyId}
            />
          );
        }
        return null;
      })}
    </div>
  );
});

export default SurgeryHistory;
