import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { getColorByRatio } from '../ReportGuides/reportGuidesUtils';
import { DepartmentReportDTO } from '../types';
import './DoughnutChart.css';

interface Props {
  selectedDepartment: DepartmentReportDTO;
}

const DoughnutChart = observer(({ selectedDepartment }: Props) => {
  const { t } = useTranslation('reports');
  return (
    <div className="doughnut-chart-container div-block-copy">
      {selectedDepartment.value !== 0 && (
        <div className="label">
          <span className="number" style={{ color: getColorByRatio(selectedDepartment.value) }}>
            {Math.round(selectedDepartment.value * 100)}
          </span>
          <span className="text" style={{ color: getColorByRatio(selectedDepartment.value) }}>
            {t('guides.percent')}
          </span>
        </div>
      )}
      <div>
        <Doughnut
          width={350}
          height={350}
          data={{
            datasets: [
              {
                data: [selectedDepartment.value, 1 - selectedDepartment.value],
                label: selectedDepartment.name,
                backgroundColor: [getColorByRatio(selectedDepartment.value), '#e3e4e8']
              }
            ]
          }}
          options={{
            tooltips: {
              enabled: false
            },
            responsive: true,
            maintainAspectRatio: false,
            cutoutPercentage: 80
          }}
        />
      </div>
      {selectedDepartment.value === 0 ? (
        <div className="info no-procedure">{t('guides.zeroText')}</div>
      ) : (
        <div className="info">{t('guides.percentageText', { department: selectedDepartment.name })}</div>
      )}
    </div>
  );
});

export default DoughnutChart;
