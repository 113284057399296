import {
  CreateMaterialDTO,
  MaterialDTO,
  MaterialSearchDTO,
  UpdateMaterialDTO,
  GroupedMaterialGuidesDTO,
  MaterialSearchResultDTO,
  MaterialContainersDTO
} from 'dto/material';
import { UpdateSortMaterialKnowledgeElementsDTO } from 'dto/materialKnowledge';
import { MaterialStorageLocationsDTO, GetMaterialStorageLocationsDTO, StorageLocationPathDTO } from 'dto/storageLocation';
import { get, post, patch, put } from './common';

import { validateArray, validate, validateBool } from './validation';

export const getMaterials = async (query?: MaterialSearchDTO): Promise<MaterialSearchResultDTO[]> => {
  const materials = await get('/materials', query).then(response => response.data);
  return validateArray(MaterialSearchResultDTO, materials);
};

export const createMaterial = async (material: CreateMaterialDTO): Promise<MaterialDTO> => {
  const newMaterial = await post('/material', material).then(response => response.data);
  return validate(MaterialDTO, newMaterial);
};

export const getMaterial = async (materialId: string): Promise<MaterialDTO> => {
  const material = await get('/material', { materialId }).then(response => response.data);
  return validate(MaterialDTO, material);
};

export const getMaterialStorageLocations = async (query: GetMaterialStorageLocationsDTO): Promise<MaterialStorageLocationsDTO[]> => {
  const storageLocations = await get('/material/storage-locations', query).then(response => response.data);
  return validateArray(MaterialStorageLocationsDTO, storageLocations);
};

export const getMaterialStorageLocationPaths = async (query: GetMaterialStorageLocationsDTO): Promise<StorageLocationPathDTO[]> => {
  const storageLocations = await get('/material/storage-location/paths', query).then(response => response.data);
  return validateArray(StorageLocationPathDTO, storageLocations);
};

export const updateMaterial = async (materialUpdateFields: UpdateMaterialDTO): Promise<MaterialDTO> => {
  const updatedMaterial = await patch(`/material`, materialUpdateFields).then(response => response.data);
  return validate(MaterialDTO, updatedMaterial);
};

export const getMaterialGuides = async (materialId: string): Promise<GroupedMaterialGuidesDTO[]> => {
  const guideGroups = await get('/material/guides', { materialId }).then(response => response.data);
  return validateArray(GroupedMaterialGuidesDTO, guideGroups);
};

export const getLatestMaterials = async (latestMaterials: MaterialSearchDTO): Promise<MaterialSearchResultDTO[]> => {
  const materials = await get('/materials', latestMaterials).then(response => response.data);
  return validateArray(MaterialSearchResultDTO, materials);
};

export const reorderMaterialKnowledgeElements = async (reorderedMaterialKnowledgeElements: UpdateSortMaterialKnowledgeElementsDTO[]) => {
  return put('/material/knowledge-elements/positions', reorderedMaterialKnowledgeElements).then(response => validateBool(response.data));
};

export const getMaterialContainers = async (materialId: string): Promise<MaterialContainersDTO> => {
  const materialContainers = await get('/material/containers', { materialId }).then(response => response.data);
  return validate(MaterialContainersDTO, materialContainers);
};
