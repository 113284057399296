import React, { useState } from 'react';
import moment from 'moment';
import Button from 'components/Form/Button';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import { parseFromDateTime, parseToDate } from 'util/date';
import { useStores } from 'util/mobx/stores';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import './CalendarButton.css';
import { observer } from 'mobx-react';
import { DateDTO } from 'dto/surgery';

const isDateInButtonsRange = (date: DateDTO) => {
  const today = parseToDate(moment());
  const tomorrow = parseToDate(moment().add('day', 1));
  const dayAfterTomorrow = parseToDate(moment().add('day', 2));
  if (date.year === today.year && date.month === today.month && date.dayInMonth === today.dayInMonth) {
    return true;
  }
  if (date.year === tomorrow.year && date.month === tomorrow.month && date.dayInMonth === tomorrow.dayInMonth) {
    return true;
  }
  if (date.year === dayAfterTomorrow.year && date.month === dayAfterTomorrow.month && date.dayInMonth === dayAfterTomorrow.dayInMonth) {
    return true;
  }
  return false;
};

const CalendarButton = observer(() => {
  const { t } = useTranslation('date');
  const { surgeryStore } = useStores();
  const [isOpen, setIsOpen] = useState(false);

  const onSelectDate = (date: MaterialUiPickersDate) => {
    if (!date) return;
    surgeryStore.selectFilterDate(parseToDate(date));
  };

  return (
    <>
      <Button
        className={`btn_material_detail as_filter calendar-button ${isDateInButtonsRange(surgeryStore.selectedFilterDate) ? '' : 'active'}`}
        onClick={() => setIsOpen(true)}
      >
        <img src="images/icon_calendar-2_16.svg" alt="" className="image_mat_detail as_filter" />
        <div>
          {isDateInButtonsRange(surgeryStore.selectedFilterDate) ? (
            <strong>{t('selectDate')}</strong>
          ) : (
            <strong>
              {parseFromDateTime(surgeryStore.selectedFilterDate, {
                hour: 0,
                minute: 0,
                second: 0
              })?.format('DD/MM/YYYY')}
            </strong>
          )}
        </div>
      </Button>
      <DatePicker
        onClose={() => setIsOpen(false)}
        autoOk
        variant="inline"
        open={isOpen}
        className="hidden-input"
        value={parseFromDateTime(surgeryStore.selectedFilterDate, {
          hour: 0,
          minute: 0,
          second: 0
        })}
        onChange={onSelectDate}
      />
    </>
  );
});

export default CalendarButton;
