export enum ReportsContentType {
  standardization = 'standardization',
  guides = 'guides'
}

export enum HorizontalBarChartTypes {
  percentage = 'percentage',
  amount = 'amount'
}

export interface DepartmentReportDTO {
  name: string;
  value: number;
  id: string;
}
