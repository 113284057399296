import React, { useEffect } from 'react';

import { useStores } from 'util/mobx/stores';
import { MaterialLikeIdOrPackageIdDTO } from 'dto/material';
import StorageLocationsList from 'pages/locationAdministration/StorageLocationsList';
import StorageLocationDetails from 'pages/locationAdministration/StorageLocationDetails';
import { observer } from 'mobx-react';
import { LocationSource } from 'dto/storageLocation';

interface Props {
  materialLikeOrPackageId?: MaterialLikeIdOrPackageIdDTO;
  inFlyout?: boolean;
}

const MaterialLikeStorageLocations = observer(({ materialLikeOrPackageId, inFlyout = false }: Props) => {
  const { storageLocationStore, domainStore } = useStores();

  useEffect(() => {
    if (inFlyout && materialLikeOrPackageId !== undefined) {
      return undefined;
    }
    if (materialLikeOrPackageId) {
      storageLocationStore.setMaterialLikeId(materialLikeOrPackageId);
      storageLocationStore.loadMaterialLikeLocations(LocationSource.KPS);
    } else {
      storageLocationStore.loadLocations();
    }
    return () => {
      if (!inFlyout) {
        storageLocationStore.clearMaterialLikeData();
      }
    };
  }, [storageLocationStore, materialLikeOrPackageId, domainStore.currentFunctionalArea.id, inFlyout]);

  if (inFlyout) {
    return <StorageLocationsList assignedToMaterial={materialLikeOrPackageId !== undefined} inFlyout />;
  }
  return (
    <div className="w-layout-grid grid_colum_content_lagerorte">
      <StorageLocationsList assignedToMaterial />
      {storageLocationStore.selectedMaterialStorageLocation && (
        <StorageLocationDetails selectedStorageLocation={storageLocationStore.selectedMaterialStorageLocation} />
      )}
    </div>
  );
});

export default MaterialLikeStorageLocations;
