import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { saveFile } from 'api/file';
import { CreateStorageLocationDTO } from 'dto/storageLocation';
import FlyoutContainer from 'components/FlyoutContainer';
import UploadFile from 'components/UploadFiles';
import { useStores } from 'util/mobx/stores';
import { ContentType } from 'dto/file';
import Form, { InputField } from 'components/Form';
import './CreateStorageLocationFlyout.css';

const CreateStorageLocationFlyout = observer(() => {
  const { t } = useTranslation('storageLocations');
  const { domainStore, storageLocationStore } = useStores();
  const [isAllowedToSubmit, setIsAllowedToSubmit] = useState(false);
  const [imageId, setImageId] = useState<string | undefined>();

  useEffect(() => {
    setImageId(undefined);
    setIsAllowedToSubmit(false);
  }, [storageLocationStore.isCreateFlyoutOpen]);

  const initialValues: CreateStorageLocationDTO = {
    name: ''
  };

  const closePopUp = () => storageLocationStore.setIsCreateFlyoutOpen(false);

  const onStorageLocationCreate = async (values: CreateStorageLocationDTO) => {
    const storageLocation = {
      ...values,
      pictureFileId: imageId,
      parentStorageLocationId: storageLocationStore.selectedParentId,
      functionalAreaId: domainStore.currentFunctionalArea.id
    };
    await storageLocationStore.createStorageLocation(storageLocation, storageLocationStore.selectedIndexToCreate);
    storageLocationStore.setIsCreateFlyoutOpen(false);
  };

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  const bindAllowedToSubmit = (isDirty: boolean, values: CreateStorageLocationDTO) => {
    if (isDirty && values.name) {
      setIsAllowedToSubmit(true);
    } else {
      setIsAllowedToSubmit(false);
    }
  };

  const handleSubmitMyForm = () => {
    if (submitMyForm) submitMyForm();
  };

  const translationKey = storageLocationStore.selectedParentId ? 'createFlyoutLocation' : 'createFlyout';

  return (
    <FlyoutContainer
      icon={<img src="images/icon_pin-search_blue-48.svg" width="50" alt="" className="image_circle_40" />}
      className="create-storage-container"
      isOpen={storageLocationStore.isCreateFlyoutOpen}
      closePopUp={closePopUp}
      cancelLabel={t(`${translationKey}.button.cancel`)}
      onSubmit={handleSubmitMyForm}
      submitLabel={t(`${translationKey}.button.submit`)}
      isAllowedToSubmit={isAllowedToSubmit}
      title={t(`${translationKey}.headline`)}
    >
      <Form
        initialValues={initialValues}
        bindSubmitForm={bindSubmitForm}
        bindAllowedToSubmit={bindAllowedToSubmit}
        onSubmit={onStorageLocationCreate}
      >
        {() => {
          return (
            <div className="flyout_scroll_wrapper">
              <div className="flyout_box">
                <div>{t(`${translationKey}.label.name`)}</div>
                <InputField name="name" placeholder={t(`${translationKey}.label.namePlaceholder`)} />
              </div>
              <div className="flyout_box">
                <div>{t('createFlyout.label.media')}</div>
                <UploadFile
                  setImageId={setImageId}
                  cameraImageButtonDescription={t(`${translationKey}.imageButton`)}
                  cameraImageButtonDescriptionAlready={t(`${translationKey}.imageButtonImageAlreadyThere`)}
                  saveFile={saveFile}
                  picturesOnly
                  accept={[ContentType.Picture]}
                />
              </div>
            </div>
          );
        }}
      </Form>
    </FlyoutContainer>
  );
});
export default CreateStorageLocationFlyout;
