import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { getDayName, parseFromDateTime, dateFormat } from 'util/date';
import { BriefingDTO } from 'dto/briefing';

interface Props {
  mobileOnly?: boolean;
}

const SurgeryRoomDateInfo = observer(({ mobileOnly = false }: Props) => {
  const { t } = useTranslation('date');
  const { surgeryStore } = useStores();

  const getSurgeryDayName = (briefing: BriefingDTO) => {
    const dayName = getDayName(t, briefing.surgery.surgeryDate, briefing.surgery.surgeryTimeStart);
    return dayName ? `${dayName}, ` : '';
  };

  return (
    <h1 className={`${mobileOnly ? 'mobile_only' : ''}`}>
      {surgeryStore.selectedRoom?.name}
      <br />
      {surgeryStore.selectedSurgeryBriefing && (
        <>
          {getSurgeryDayName(surgeryStore.selectedSurgeryBriefing)}
          {parseFromDateTime(
            surgeryStore.selectedSurgeryBriefing.surgery.surgeryDate,
            surgeryStore.selectedSurgeryBriefing.surgery.surgeryTimeStart
          )?.format(dateFormat)}
        </>
      )}
    </h1>
  );
});

export default SurgeryRoomDateInfo;
