import React from 'react';
import { observer } from 'mobx-react';
import NavSectionButton from 'components/NavSection/NavSectionButton';
import { useTranslation } from 'react-i18next';

interface Props {
  currentPath: string;
}

const AccountManagementNavigation = observer(({ currentPath }: Props) => {
  const { t } = useTranslation('accountManagement');
  return (
    <>
      <NavSectionButton icon="icon_globe_16" label={t('subMenu.users')} routePath="/accountManagement/users" currentPath={currentPath} />
      <NavSectionButton
        icon="icon_bulb-62_16"
        label={t('subMenu.titles')}
        routePath="/accountManagement/titles"
        currentPath={currentPath}
      />
    </>
  );
});

export default AccountManagementNavigation;
