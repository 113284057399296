import OptionalLazyLoad from 'components/OptionalLazyLoad';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import { OptionalPictureDTO } from 'dto/file';
import React, { useState, useEffect, useMemo } from 'react';
import { useSecureURL } from 'util/hooks';
import { useStores } from 'util/mobx/stores';

const defaultImage = 'images/icon_book_makephoto.jpg';
const errorImage = 'images/icon_book_missing_photo.jpg';
interface Props
  extends Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src'>,
    OptionalLazyLoadProps {
  altSrc?: string;
  /**
   * either pass src as string to just simply show an image or pass it as PictureDTO
   * to be able to click on picture to show it on the bigPictureFlyout
   */
  src?: string | OptionalPictureDTO;
  /**
   * only needed when the whole PictureDTO as src
   */
  size?: 'picture' | 'pictureThumbnail' | 'pictureSmall';
  withBorder?: boolean;
}
const InternalPicture = ({ children, src = defaultImage, size = 'pictureSmall', withBorder = false, ...props }: Props) => {
  const { flyoutStore } = useStores();

  const handleAddImagesToView = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (typeof src === 'string') {
      return;
    }

    if (!src.picture) {
      return;
    }

    e.stopPropagation();
    flyoutStore.setImagesToView(src.picture);
  };

  const finalSrc = useMemo((): string => {
    if (typeof src === 'string') {
      return src;
    }

    const srcToUse = src[size];
    if (!srcToUse) {
      return props.altSrc || '';
    }
    return srcToUse;
  }, [size, src, props.altSrc]);

  const [currentSrc, setCurrentSrc] = useState(finalSrc);
  useEffect(() => {
    setCurrentSrc(finalSrc);
  }, [src, finalSrc]);

  const onError = () => {
    if (props.altSrc) {
      setCurrentSrc(props.altSrc);
    } else {
      setCurrentSrc(errorImage);
    }
  };

  // remove altSrc prop from the dom-img tag
  const imgProps = props;
  delete imgProps.altSrc;

  const url = useSecureURL(currentSrc);

  return url ? (
    <>
      {/* eslint-disable-next-line jsx-a11y/alt-text, jsx-a11y/no-noninteractive-element-interactions */}
      <img {...imgProps} src={url} onError={onError} onClick={handleAddImagesToView} />
      {withBorder ? <div className="image_border image-border" onClick={handleAddImagesToView} /> : null}
      {children}
    </>
  ) : (
    <></>
  );
};

const Picture = ({ lazyLoadScrollContainer, ...props }: Props) => {
  return (
    <>
      <OptionalLazyLoad offset={100} overflow lazyLoadScrollContainer={lazyLoadScrollContainer}>
        <InternalPicture {...props} />
      </OptionalLazyLoad>
    </>
  );
};

export default Picture;
