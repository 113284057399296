import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  scrollKey: string;
  children?: JSX.Element[] | JSX.Element | null;
}

const memoryStore = {
  thisData: new Map(),
  get(key: string) {
    return this.thisData.get(key) || null;
  },
  set(key: string, data: number) {
    return this.thisData.set(key, data);
  }
};

export default observer(({ scrollKey, children, ...rest }: Props) => {
  const scrollContainer: React.RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const onScroll = (event: Event) => {
      const target = event.target as HTMLDivElement;
      if (target && target.scrollTop) {
        memoryStore.set(scrollKey, target.scrollTop);
      }
    };
    const element = scrollContainer.current;
    if (element) {
      const scrollPosition = memoryStore.get(scrollKey);
      element.scrollTo(0, scrollPosition);
      element.addEventListener('scroll', onScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', onScroll);
      }
    };
  }, [scrollKey]);

  return (
    <div {...rest} ref={scrollContainer}>
      {children}
    </div>
  );
});
