import React from 'react';
import { useTranslation } from 'react-i18next';
import Popover from 'components/Popover';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import ItemMenuButton from 'components/ItemMenuButton';
import ItemMenu from 'components/ItemMenu';
import './SettingsItem.css';
import { SettingsItemDTO } from 'stores/settingsStore';
import { useStores } from 'util/mobx/stores';

interface Props extends SettingsItemDTO {
  onActivate?: (itemId: string) => void;
  onDeActivate?: (itemId: string) => void;
  onDeletePosts?: () => Promise<unknown>;
  onClick?: () => void;
  selected?: boolean;
  notEditable?: boolean;
  isFixed?: boolean;
}

const SettingsItem = ({
  name,
  shortName,
  disabled,
  itemId,
  onActivate,
  onDeActivate,
  type,
  onClick = () => null,
  selected = false,
  notEditable = false,
  isFixed = false,
  onDeletePosts
}: Props) => {
  const { t } = useTranslation('settings');
  const { settingsStore, loadingStore } = useStores();
  const onRename = () => {
    settingsStore.setSelectedItem({
      name,
      shortName,
      type,
      itemId,
      disabled
    });
    if (type) {
      settingsStore.setItemFormFlyoutType(type);
    }
    settingsStore.setItemFormFlyoutOpen(true);
  };

  const activate = () => {
    if (itemId && onActivate) {
      onActivate(itemId);
    }
  };

  const deactivate = () => {
    if (itemId && onDeActivate) {
      onDeActivate(itemId);
    }
  };

  const renderActiveDeactivateButtons = (isDisabled: boolean | undefined) => {
    if (!onActivate || !onDeActivate) return <></>;
    if (isDisabled) {
      return <ItemMenuButton warning label={t('item.menu.active')} icon="eye.svg" isInPopover handleClick={() => activate()} />;
    }
    return <ItemMenuButton warning label={t('item.menu.deactive')} icon="b-eye.svg" isInPopover handleClick={() => deactivate()} />;
  };

  return (
    <div
      className={`${isFixed ? 'list_item_name_fixed' : 'list_item_setting'} list-item-settings ${disabled ? 'inactive' : ''} ${
        selected ? 'selected' : ''
      }`}
      onClick={onClick}
    >
      {disabled && (
        <div className="setting_tag">
          <div className="text-block-15">{t('item.inactive')}</div>
        </div>
      )}
      <div className="item_name">
        {shortName && <div className="t2 bottom-margin">{name}</div>}
        <div>{shortName || name}</div>
      </div>
      {notEditable ? (
        <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-dots-post" />
      ) : (
        <div className={`btn_show_detail btn-detail-position ${isFixed ? 'hidden' : ''}`}>
          <Popover trigger={<ArrowDotsVerticalIcon dotsOnly />}>
            {({ handleClose, isOpen }) => (
              <>
                <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
                  {onDeletePosts && (
                    <ItemMenuButton
                      label={t('item.menu.deletePosts')}
                      icon="icon_bin_16.svg"
                      warning
                      isInPopover
                      handleClick={() => {
                        loadingStore.withOnlyLoadingBar(onDeletePosts);
                        handleClose();
                      }}
                    />
                  )}
                  {!onDeletePosts ? (
                    <ItemMenuButton label={t('item.menu.rename')} icon="icon_background_16.svg" isInPopover handleClick={onRename} />
                  ) : (
                    undefined
                  )}
                  {renderActiveDeactivateButtons(disabled)}
                </ItemMenu>
              </>
            )}
          </Popover>
        </div>
      )}
    </div>
  );
};

export default SettingsItem;
