/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { observer } from 'mobx-react';
import { FullGuideDTO } from 'dto/guide';

import './GuideListItem.css';

interface Props {
  guide: FullGuideDTO;
  hover?: boolean;
  inFlyout?: boolean;
  actions?: JSX.Element;
}

const GuideListItem = observer(({ guide, actions, inFlyout = false, hover }: Props) => {
  const actionsWithHoverProps = actions && React.cloneElement(actions, { hover });

  if (inFlyout) {
    return (
      <div className="list_item_book list-item-book in_prompt in-prompt list-item">
        <div className="image_wrapper_50">
          <img src="images/icon_book.jpg" alt="" className="image_circle_50" />
          <div className="image_border image-border" />
        </div>
        <div className="book_info book-info in_prompt">
          <div className="t2 bottom-margin">
            {guide.user.firstName} {guide.user.lastName}
          </div>
          <div>{guide.name}</div>
        </div>
        {actions && <div className="btn_show_detail">{actionsWithHoverProps}</div>}
      </div>
    );
  }

  return (
    <div className="list_item_book list-item-book list-item">
      <div className="image_wrapper_50">
        <img src="images/icon_book.jpg" alt="" className="image_circle_50" />
        <div className="image_border image-border" />
      </div>
      <div className="book_info book-info">
        <div className="link-block w-inline-block">
          <div className="t2 bottom-margin">
            {guide.user.firstName} {guide.user.lastName}
          </div>
          <div>{guide.name}</div>
        </div>
      </div>
      {actions && <div className="btn_show_detail">{actionsWithHoverProps}</div>}
    </div>
  );
});

export default GuideListItem;
