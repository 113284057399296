import React from 'react';
import { useStores } from 'util/mobx/stores';
import { InstrumentDTO } from 'dto/instrument';
import { GuideMaterialLikeDTO } from 'dto/guide';
import ImageCircle from 'components/ImageCircle';
import HoverWrapper from 'components/HoverWrapper';
import Popover from 'components/Popover';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import GetCameraImageButton from 'components/GetCameraImageButton';
import MaterialValueTags from 'components/MaterialValueTags';
import LinkWrapper from 'components/LinkWrapper';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import GuideInstrumentListMenu from './GuideInstrumentListMenu';

import './InstrumentListItem.css';

interface Props extends OptionalLazyLoadProps {
  instrument: InstrumentDTO;
  noHover?: boolean;
  guideData?: GuideMaterialLikeDTO;
  isClone?: boolean;
  filterListBy?: string;
}

const InstrumentListItem = ({ instrument, noHover = false, guideData, isClone = false, filterListBy, lazyLoadScrollContainer }: Props) => {
  const { guideDetailDrawerStore, instrumentStore, guideStore, domainStore } = useStores();
  const onOpenDetails = async () => {
    if (isClone) {
      return;
    }
    guideDetailDrawerStore.setSelectedInstrument(instrument, guideData);
    guideDetailDrawerStore.setIsOpen(true);
  };
  const onUpdateFile = async (fileId: string) => {
    await instrumentStore.updateMaterial({ materialId: instrument.material.materialId, pictureFileId: fileId });
    if (filterListBy === 'all') {
      await instrumentStore.loadTemplates();
    }
    if (filterListBy === domainStore.currentDepartment.id) {
      await instrumentStore.loadTemplates(domainStore.currentDepartment.id);
    }
    if (guideStore.selectedGuide) {
      guideStore.loadGuideMaterials(guideStore.selectedGuide.guideId);
    }
  };

  const routePath = `/instrument/overview?instrumentId=${instrument.instrumentId}`;
  return (
    <HoverWrapper>
      {({ hover }) => {
        if (guideData) {
          return (
            <div className="list_item_material margin-right w-inline-block package-info image-border-container">
              <div className={`list_item_material margin-right image-border-container ${noHover ? 'no_hover' : ''}`}>
                <div className="item_count" onClick={onOpenDetails}>
                  <div>{instrument.amount}</div>
                </div>
                {instrument.material.picture ? (
                  <>
                    <ImageCircle
                      lazyLoadScrollContainer={lazyLoadScrollContainer}
                      pictures={[instrument.material]}
                      className="image_circle_50"
                      instrumentLvl={guideData && 'lvl2'}
                    />
                  </>
                ) : (
                  <>
                    <div className="image_wrapper_50">
                      <div className="image_border image-border" />
                      <GetCameraImageButton
                        buttonStyle="icon"
                        onUpdateFile={onUpdateFile}
                        description=""
                        picturesOnly
                        isAddable
                        withLoadingBar
                      />
                      <div className="material_lvl2_line">
                        <div className="dot in_mat_list" />
                      </div>
                    </div>
                  </>
                )}
                <div className={`material_info instrument-info ${noHover ? 'no_hover' : ''}`}>
                  <div className="material_text" onClick={onOpenDetails}>
                    <div>{instrument.material.name}</div>
                  </div>
                  <MaterialValueTags instrument={instrument} onClick={onOpenDetails} />
                  {!isClone ? (
                    <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
                      {({ handleClose, isOpen }) => (
                        <GuideInstrumentListMenu handleClose={handleClose} isOpen={!!isOpen} handleOpenDetails={onOpenDetails} />
                      )}
                    </Popover>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          );
        }
        return (
          <div className={`list_item_material margin-right image-border-container ${noHover ? 'no_hover' : ''}`}>
            <div className="item_count">
              <div>{instrument.amount}</div>
            </div>
            {instrument.material.picture ? (
              <>
                <ImageCircle
                  lazyLoadScrollContainer={lazyLoadScrollContainer}
                  pictures={[instrument.material]}
                  className="image_circle_50"
                />
              </>
            ) : (
              <>
                <div className="image_wrapper_50">
                  <div className="image_border image-border" />
                  <GetCameraImageButton
                    buttonStyle="icon"
                    onUpdateFile={onUpdateFile}
                    description=""
                    picturesOnly
                    isAddable
                    withLoadingBar
                  />
                </div>
              </>
            )}
            <LinkWrapper routePath={routePath} className="list_item_material w-inline-block package-info">
              <div className={`material_info instrument-info ${noHover ? 'no_hover' : ''}`}>
                <div className="material_text">
                  <div>{instrument.material.name}</div>
                </div>
                <MaterialValueTags instrument={instrument} />
                {!noHover && <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-arrow-list" />}
              </div>
            </LinkWrapper>
          </div>
        );
      }}
    </HoverWrapper>
  );
};

export default InstrumentListItem;
