import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import SettingsItem from 'components/SettingsItem';
import { reorderGuideChapters } from 'api/guideChapter';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { SettingsItemTypes } from 'stores/settingsStore';
import { GroupedGuideChapterDTO, GuideChapterDTO } from 'dto/guideChapter';
import SettingsBlock from 'components/SettingsBlock';
import { getItemStyle } from 'util/dragAndDrop';

interface Props {
  selectedFunctionalAreaId: string;
  guideChapters: GroupedGuideChapterDTO[];
}

const GuidesChaptersColumn = observer(({ selectedFunctionalAreaId, guideChapters }: Props) => {
  const [selectedGuideChapters, setSelectedGuideChapters] = useState<GuideChapterDTO[]>([]);
  const { t } = useTranslation('settings');
  const { settingsStore } = useStores();

  useEffect(() => {
    const selectedGuideChaptersGroup = guideChapters.find(g => g.functionalArea.functionalAreaId === selectedFunctionalAreaId);
    if (selectedGuideChaptersGroup?.guideChapters) {
      setSelectedGuideChapters(selectedGuideChaptersGroup.guideChapters);
    }
  }, [guideChapters, selectedFunctionalAreaId]);

  const onActivate = (itemId: string) => {
    settingsStore.updateGuideChapter({
      guideChapterId: itemId,
      disabled: false
    });
  };

  const onDeactivate = (itemId: string) => {
    settingsStore.updateGuideChapter({
      guideChapterId: itemId,
      disabled: true
    });
  };

  const onCreateFlyoutOpen = () => {
    settingsStore.setItemFormFlyoutType(SettingsItemTypes.guideChapter);
    settingsStore.setItemFormFlyoutOpen(true);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !selectedGuideChapters) return;

    const fileToBeMoved = selectedGuideChapters.find(gc => gc.guideChapterId === result.draggableId);
    const oldGuideChapters = selectedGuideChapters.filter(gc => gc.guideChapterId !== result.draggableId);

    if (fileToBeMoved && oldGuideChapters) {
      oldGuideChapters.splice(result.destination.index, 0, fileToBeMoved);
      reorderGuideChapters({ guideChapterIds: oldGuideChapters.map(gc => gc.guideChapterId) });
      setSelectedGuideChapters([...oldGuideChapters]);
    }
  };

  return (
    <SettingsBlock title={t('administration.guideChaptersTitle')} onCreateFlyoutOpen={onCreateFlyoutOpen}>
      {selectedGuideChapters && selectedGuideChapters.length > 0 ? (
        <div className="area_block">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="guideChaptersColumn">
              {droppableProvided => (
                <div ref={droppableProvided.innerRef}>
                  {selectedGuideChapters.map((guideChapter, index) => (
                    <>
                      <Draggable draggableId={guideChapter.guideChapterId} index={index} key={guideChapter.guideChapterId}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <SettingsItem
                              itemId={guideChapter.guideChapterId}
                              onActivate={onActivate}
                              onDeActivate={onDeactivate}
                              name={guideChapter.name}
                              disabled={guideChapter.disabled}
                              type={SettingsItemTypes.guideChapter}
                            />
                          </div>
                        )}
                      </Draggable>
                    </>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : null}
    </SettingsBlock>
  );
});

export default GuidesChaptersColumn;
