import { useStores } from 'util/mobx/stores';
import { useQuery } from '@tanstack/react-query';
import { LoadingType } from 'stores/loadingStore';
import { ApiQueryOptions } from './query';
import { getMultiTemplateV2, getSingleTemplateV2 } from './template';

export const useGetSingleTemplateV2 = (templateId: string, options: ApiQueryOptions = {}) => {
  const { loadingStore } = useStores();
  const { loadingType, ...queryOptions } = options;
  return useQuery({
    queryKey: ['material-like', 'template', { templateId }],
    queryFn: async () => {
      return loadingStore.withSpecificLoadingBar(loadingType || LoadingType.DEFAULT, () => getSingleTemplateV2(templateId));
    },
    ...queryOptions
  });
};

export const useGetMultiTemplateV2 = (templateId: string, options: ApiQueryOptions = {}) => {
  const { loadingStore } = useStores();
  return useQuery({
    queryKey: ['material-like', 'template', { templateId }],
    queryFn: async () => {
      return loadingStore.withSpecificLoadingBar(options.loadingType || LoadingType.DEFAULT, () => getMultiTemplateV2(templateId));
    },
    enabled: options.enabled,
    cacheTime: options.cacheTime
  });
};
