import { observer } from 'mobx-react';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStores } from 'util/mobx/stores';

import CustomDropdown from 'components/CustomDropdown';
import CustomInput from 'components/CustomInput';
import FlyoutContainer from 'components/FlyoutContainer';
import Button from 'components/Form/Button';
import { UserDTO } from 'dto/user';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import { subjectArea, actions, AbilityContext } from 'casl/setupCaslAbility';
import { useAbility } from '@casl/react';
import GuideOwnerListItemBody from '../ownerList/GuideOwnerListItemBody';
import SelectUserFlyout from './SelectUserFlyout';
import './GuideFlyout.css';

const GuideFlyout = observer(() => {
  const { t } = useTranslation('guide');
  const ability = useAbility(AbilityContext);
  const { domainStore, guideStore, userStore } = useStores();
  const [departmentId, setDepartmentId] = useState<string>(guideStore.selectedGuide?.departmentId || domainStore.currentDepartment.id);
  const [isSelectUserFlyoutOpen, setIsSelectUserFlyoutOpen] = useState(false);
  const [name, setName] = useState('');

  const [guideOwner, setGuideOwner] = useState<UserDTO | null>(null);

  useEffect(() => {
    setDepartmentId(guideStore.selectedGuide?.departmentId || domainStore.currentDepartment.id);
  }, [guideStore.selectedGuide, domainStore.currentDepartment.id]);

  useEffect(() => {
    if (guideStore.selectedGuide) {
      if (guideStore.selectedGuide.name) {
        setName(guideStore.selectedGuide.name);
      }
      if (guideStore.selectedGuideOwner) {
        setGuideOwner(guideStore.selectedGuideOwner);
      }
    } else {
      setName('');
      if (guideStore.selectedGuideOwner) {
        setGuideOwner(guideStore.selectedGuideOwner);
      }
    }
  }, [guideStore.selectedGuide, guideStore.selectedGuideOwner]);

  useEffect(() => {
    if (guideStore.isGuideFlyoutOpen) (async () => userStore.loadAllUsers(true))();
  }, [guideStore.isGuideFlyoutOpen, userStore]);

  const resetState = () => {
    setName('');
    setIsSelectUserFlyoutOpen(false);
    setGuideOwner(null);
  };

  const history = useHistory();

  const onCancel = () => {
    guideStore.setShowGuideFlyout(false);

    if (!guideStore.selectedGuide) {
      resetState();
    }
  };

  const onUpdateGuide = async () => {
    if (guideStore.selectedGuide && guideStore.selectedGuide.name && guideOwner) {
      await guideStore.updateGuide({
        guideId: guideStore.selectedGuide.guideId,
        name,
        departmentId,
        ownerId: guideOwner.userId
      });
      guideStore.loadAdditionalGuidesByUser(guideOwner.userId);
      guideStore.loadSelectedGuideById(guideStore.selectedGuide.guideId);
      guideStore.setShowGuideFlyout(false);
    }
  };

  const onCreateGuide = async () => {
    if (departmentId && name && guideOwner && guideOwner.userId) {
      const { guideId } = await guideStore.createGuide(departmentId, name, guideOwner.userId);
      guideStore.setShowGuideFlyout(false);
      history.push(`/guide/materials?guideId=${guideId}`);
    }
    resetState();
  };

  const onSubmit = async () => {
    if (guideStore.selectedGuide) {
      onUpdateGuide();
    } else {
      onCreateGuide();
    }
  };

  const handleUserSelection = (selectedUser: UserDTO) => {
    setGuideOwner(selectedUser);
    setIsSelectUserFlyoutOpen(false);
  };

  const closeSelectUserFlyout = () => {
    setIsSelectUserFlyoutOpen(false);
  };

  const isValidFormInput = (): boolean => {
    return name !== '' && !!guideOwner;
  };

  const getSelectedDepartmentName = useCallback(() => {
    const selected = domainStore.getDepartmentDropdownOptionById(departmentId);
    return `${selected.group} | ${selected.label}`;
  }, [domainStore, departmentId]);

  return (
    <>
      <FlyoutContainer
        icon={<img src="images/icon_book.jpg" width="50" alt="" className="image_circle_40" />}
        isOpen={guideStore.isGuideFlyoutOpen}
        closePopUp={onCancel}
        cancelLabel={guideStore.selectedGuide ? t('updateGuide.button.cancel') : t('createGuide.button.cancel')}
        onSubmit={onSubmit}
        submitLabel={guideStore.selectedGuide ? t('updateGuide.button.submit') : t('createGuide.button.submit')}
        isAllowedToSubmit={isValidFormInput()}
        title={guideStore.selectedGuide ? t('updateGuide.headline') : t('createGuide.headline')}
      >
        <SelectUserFlyout
          handleUserSelection={handleUserSelection}
          isSelectUserFlyoutOpen={isSelectUserFlyoutOpen}
          closeSelectUserFlyout={closeSelectUserFlyout}
          preselectedUser={guideOwner}
        />
        {ability.can(actions.see, subjectArea.createGuideFlyoutDepartmentSelector) ? (
          <div className="flyout_box">
            <div>{t('createGuide.label.department')}</div>
            <CustomDropdown
              defaultValue={domainStore.getDepartmentDropdownOptionById(departmentId)}
              mappedOptions={domainStore.departmentsDropdownOptions}
              onChange={setDepartmentId}
            />
          </div>
        ) : (
          <div className="flyout_box">
            <div>{t('createGuide.label.department')}</div>
            <div className="input_field without_input" style={{ marginTop: '20px' }}>
              {getSelectedDepartmentName()}
            </div>
          </div>
        )}
        <div className="flyout_box">
          <div>{guideStore.selectedGuide ? t('updateGuide.label.guideName') : t('createGuide.label.guideName')}</div>
          <CustomInput
            value={guideStore.selectedGuide ? name : ''}
            onChange={event => setName(event.target.value)}
            placeholder={
              guideStore.selectedGuide ? t('updateGuide.label.guideNamePlaceholder') : t('createGuide.label.guideNamePlaceholder')
            }
          />
        </div>
        <div className="flyout_box">
          <div>{t('createGuide.label.guideOwner')}</div>
          <div className="div-block-41 with_content flex_update">
            {guideOwner && guideOwner.userId ? (
              <>
                <GuideOwnerListItemBody user={guideOwner} disableMenu />
                <Button className="btn_small_flyout" onClick={() => setIsSelectUserFlyoutOpen(true)}>
                  <div className="txt_dropout_1">{t('createGuide.button.changeGuideOwner')}</div>
                </Button>
              </>
            ) : (
              <>
                <ListItemAdd className="post_item_add post-item-add" onClick={() => setIsSelectUserFlyoutOpen(true)}>
                  <InfoBox label={t('createGuide.button.addGuideOwner')} />
                </ListItemAdd>
              </>
            )}
          </div>
        </div>
      </FlyoutContainer>
    </>
  );
});
export default GuideFlyout;
