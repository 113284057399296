import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  groupType: string;
  onClick: () => void;
  className?: string;
}

const OverviewBoxPhotoLink = ({ groupType, className = '', onClick }: Props) => {
  const { t } = useTranslation(groupType);
  return (
    <div className={`div-block-47 bg_color ${className}`} onClick={onClick}>
      <div className="div-block-49 absolut">
        <img src="images/logo_klinik-puls_white.svg" alt="" className="puls_track" width={29} />
      </div>
      <div className="div-block-50 flex">
        <div className="div-block-50-copy">
          <div className="material-name">{t('overview.photoLink.name')}</div>
          <div>{t('overview.photoLink.textHtml')}</div>
        </div>
        <img src="images/qr_code_image.png" alt="" className="image-7" width={153} />
      </div>
      <div className="btn_small_overview white">
        <div className="txt_dropout_1">{t('overview.photoLink.btnText')}</div>
      </div>
    </div>
  );
};

export default OverviewBoxPhotoLink;
