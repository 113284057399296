import React, { useEffect, useState } from 'react';
import { getAnimationState } from 'util/animation';
import './ListItemDropdownContainer.css';

interface Props extends React.HTMLProps<HTMLDivElement> {
  children: JSX.Element | JSX.Element[];
  ListElement?: () => JSX.Element;
  classPrefix: string;
  inGuide?: boolean;
  className?: string;
  isExpandedByDefault?: boolean;
  triggerExpandOnClick?: boolean;
}

const ListItemDropdownContainer = ({
  children,
  ListElement,
  classPrefix,
  inGuide = false,
  className = '',
  isExpandedByDefault = false,
  triggerExpandOnClick = false,
  style
}: Props) => {
  const [isListExpanded, setIsListExpanded] = useState<boolean>(isExpandedByDefault);

  // Render the ListElement only one time, so that already rendered items preserve their state even on collapsing
  // e.g. preserve the checks in Surgery
  const [wasOneTimeExpanded, setWasOneTimeExpanded] = useState<boolean>(isExpandedByDefault);

  useEffect(() => {
    if (isListExpanded && !wasOneTimeExpanded) {
      setWasOneTimeExpanded(true);
    }
  }, [isListExpanded, wasOneTimeExpanded]);

  let prefixSpecificDropDown: JSX.Element | JSX.Element[];
  if (classPrefix === 'template' || classPrefix === 'set-group') {
    prefixSpecificDropDown = (
      <div
        className={`list_item_material with_dropdown list-item-material ${
          inGuide ? 'in_materiallist' : ''
        } image-border-container ${classPrefix}-list-item-info`}
        onClick={() => setIsListExpanded(!isListExpanded)}
      >
        <div
          className={`item_dropdown item_open ${inGuide ? '' : 'left'} item-dropdown`}
          onClick={() => setIsListExpanded(!isListExpanded)}
        >
          <img src="images/icon_small-left_16.svg" alt="" className={`image_arrow_dropdown_2 image-arrow ${classPrefix}-image-arrow`} />
        </div>
        {children}
      </div>
    );
  } else if (classPrefix === 'package') {
    prefixSpecificDropDown = (
      <div
        className={`list_item_material list-item-material with_dropdown ${
          inGuide ? 'in_materiallist lvl_1' : ''
        } image-border-container ${classPrefix}-list-item-info`}
        onClick={() => triggerExpandOnClick && setIsListExpanded(!isListExpanded)}
      >
        <div
          className={`item_dropdown item_open ${inGuide ? '' : 'left'} item-dropdown`}
          onClick={() => setIsListExpanded(!isListExpanded)}
        >
          <img src="images/icon_small-left_16.svg" alt="" className={`image_arrow_dropdown_2 image-arrow ${classPrefix}-image-arrow`} />
        </div>
        {children}
      </div>
    );
  } else {
    prefixSpecificDropDown = (
      <div
        className={`list_item_material list-item-material with_dropdown ${
          inGuide ? 'in_materiallist' : ''
        } image-border-container ${classPrefix}-list-item-info`}
        onClick={() => triggerExpandOnClick && setIsListExpanded(!isListExpanded)}
      >
        <div
          className={`item_dropdown item_open ${inGuide ? '' : 'left'} item-dropdown`}
          onClick={() => setIsListExpanded(!isListExpanded)}
        >
          <img src="images/icon_small-left_16.svg" alt="" className={`image_arrow_dropdown_2 image-arrow ${classPrefix}-image-arrow`} />
        </div>
        {children}
      </div>
    );
  }
  return (
    <div
      className={`list_item_dropdown list-item-material list-item-dropdown ${classPrefix}-list-item-dropdown ${getAnimationState(
        isListExpanded,
        'expanded',
        'collapsed'
      )} ${className}`}
      style={style}
    >
      <div className="list_item_flex">
        {prefixSpecificDropDown}
        {/* It is intentional to call ListElement() instead of <ListElement /> to avoid rerenderings and therefore collapsing the list
         if anything in the list changes. (such as checking the surgery guidematerial checks) */}
        <div className={isListExpanded ? '' : 'hidden'}>{ListElement && wasOneTimeExpanded && ListElement()}</div>
      </div>
    </div>
  );
};

export default ListItemDropdownContainer;
