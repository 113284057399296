import React from 'react';
import { HistoryDTO } from 'dto/history';
import ImageCircle from 'components/ImageCircle';
import moment from 'moment';
import { dateFormat } from 'util/date';

interface Props {
  item: HistoryDTO;
  description: string;
  icon: JSX.Element;
  descriptionAtBottom?: boolean;
  className?: string;
}

const PersonalizedHistoryItem = ({ item, description, icon, descriptionAtBottom = false, className = '' }: Props) => {
  return (
    <div className={`btn_in_list_no_hover ${className}`}>
      <div className="list_item_book in_promp">
        <div className="image_wrapper_50">
          <ImageCircle user={item.user} size="50" highlight={false} />
          {icon}
        </div>
        <div className="book_info in_prompt">
          <div className="t2 bottom-margin">
            {moment(item.createdAt).format(dateFormat)} <strong>{`${item.user.lastName}, ${item.user.firstName}`}</strong>{' '}
            {descriptionAtBottom ? '' : description}
          </div>
          <div>{descriptionAtBottom ? description : item.targetName}</div>
        </div>
      </div>
    </div>
  );
};

export default PersonalizedHistoryItem;
