import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import NavSectionButton from 'components/NavSection/NavSectionButton';
import { useLocation } from 'react-router-dom';

interface Props {
  materialSetId: string;
  groupType: string;
  hideStorageLocation: boolean;
}

const MaterialSetNavSection = observer(({ materialSetId, groupType, hideStorageLocation }: Props) => {
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;
  const { t } = useTranslation(groupType);

  return (
    <>
      <NavSectionButton
        icon="icon_single-content-02_16"
        label={t('subMenu.overview')}
        routePath={`/group/${groupType}/overview?materialSetId=${materialSetId}`}
        currentPath={currentPath}
      />
      <NavSectionButton
        icon="icon_data-table_16"
        label={t('subMenu.baseData')}
        routePath={`/group/${groupType}/detail?materialSetId=${materialSetId}`}
        currentPath={currentPath}
      />
      <NavSectionButton
        icon="icon_document_16"
        label={t('subMenu.implantsControl')}
        labelMobile={t('subMenu.implantsControlMobile', {
          defaultValue: null
        })}
        routePath={`/group/${groupType}/list?materialSetId=${materialSetId}`}
        currentPath={currentPath}
      />
      <NavSectionButton
        icon="icon_bulb-62_16"
        label={t('subMenu.knowledge')}
        routePath={`/group/${groupType}/content?materialSetId=${materialSetId}`}
        currentPath={currentPath}
      />
      <NavSectionButton
        icon="icon_globe_16"
        label={t('subMenu.globalFunctions')}
        routePath={`/group/${groupType}/functions?materialSetId=${materialSetId}`}
        currentPath={currentPath}
      />
      {/* TODO */}
      {!hideStorageLocation && (
        <NavSectionButton
          icon="icon_pin-3_16"
          label={t('subMenu.locations')}
          routePath={`/group/${groupType}/locations?materialSetId=${materialSetId}`}
          currentPath={currentPath}
        />
      )}
    </>
  );
});

export default MaterialSetNavSection;
