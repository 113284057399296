import { RoomSurgeriesDTO, GetSurgeriesDTO, CreateSurgeryDTO, SurgeryDTO, UpdateSurgeryDTO, UpdateSortSurgeryDTO } from 'dto/surgery';
import { GetBriefingDTO, BriefingDTO } from 'dto/briefing';
import { GetSurgeryRoomsDTO, SurgeryRoomDTO } from 'dto/surgeryRoom';
import config from 'config/config';
import { get, post, patch, del, put } from './common';
import { validateArray, validate, validateBool } from './validation';

export const getSurgeries = async (params: GetSurgeriesDTO): Promise<RoomSurgeriesDTO[]> => {
  const options = { ...params };
  if (!params.timezone) {
    options.timezone = config.timezone || 'Europe/Berlin';
  }

  const surgeryRooms = await get('/surgeries', options).then(response => response.data);
  return validateArray(RoomSurgeriesDTO, surgeryRooms);
};

export const getSurgeryRooms = async (params: GetSurgeryRoomsDTO): Promise<SurgeryRoomDTO[]> => {
  const surgeryRooms = await get('/surgery/rooms', params).then(response => response.data);
  return validateArray(SurgeryRoomDTO, surgeryRooms);
};

export const createSurgery = async (surgeryToCreate: CreateSurgeryDTO) => {
  const surgery = await post('/surgery', surgeryToCreate).then(response => response.data);
  return validate(SurgeryDTO, surgery);
};

export const updateSurgery = async (surgeryUpdateFields: UpdateSurgeryDTO): Promise<SurgeryDTO> => {
  const updatedSurgery = await patch(`/surgery`, surgeryUpdateFields).then(response => response.data);
  return validate(SurgeryDTO, updatedSurgery);
};

export const deleteSurgery = async (surgeryId: string) => {
  return del('/surgery', { surgeryId }).then(response => validateBool(response.data));
};

export const reorderSurgeries = async (reorderedSurgeries: UpdateSortSurgeryDTO[]) => {
  return put('/surgeries/positions', reorderedSurgeries).then(response => validateBool(response.data));
};

export const getSurgeryBriefing = async (params: GetBriefingDTO) => {
  const briefing = await get('/surgery/briefing', params).then(response => response.data);
  return validate(BriefingDTO, briefing);
};
