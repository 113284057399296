/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsString, IsUUID, ValidateNested, IsBoolean, IsOptional, IsEnum, IsInt } from 'class-validator';
import 'reflect-metadata';
import { Type } from 'class-transformer';
import { ContentElementDTO, OptionalContentElementDTO, CreateContentElementDTO } from './contentElement';
import { ChapterType } from './materialChapter';
import { MaterialLikeIdOrPackageIdDTO } from './material';

export class MaterialKnowledgeDTO {
  @IsUUID()
  @IsOptional()
  materialKnowledgeElementId?: string;

  @IsUUID()
  @IsOptional()
  materialSynonymId?: string;

  @IsUUID()
  materialChapterId!: string;

  @ValidateNested()
  @Type(() => ContentElementDTO)
  content!: ContentElementDTO;
}

export class GroupedMaterialKnowledgeDTO {
  @IsEnum(ChapterType)
  chapterType!: ChapterType;

  @IsString()
  materialChapter!: string;

  @IsUUID()
  materialChapterId!: string;

  @IsBoolean()
  isGenerated!: boolean;

  @IsBoolean()
  isAlwaysVisible!: boolean;

  @ValidateNested({ each: true })
  @Type(() => MaterialKnowledgeDTO)
  knowledgeElements!: MaterialKnowledgeDTO[];
}

export class CreateMaterialKnowledgeDTO extends MaterialLikeIdOrPackageIdDTO {
  @IsInt()
  @IsOptional()
  position?: number;

  @IsUUID()
  materialChapterId!: string;

  @ValidateNested()
  @Type(() => CreateContentElementDTO)
  content?: CreateContentElementDTO;

  @IsUUID()
  @IsOptional()
  contentElementId?: string;
}

export class UpdateMaterialKnowledgeDTO {
  @IsUUID()
  materialKnowledgeElementId!: string;

  @ValidateNested()
  @Type(() => OptionalContentElementDTO)
  content?: OptionalContentElementDTO;
}

export class MaterialKnowledgeIdDTO {
  @IsUUID()
  materialKnowledgeElementId!: string;
}

export class UpdateSortMaterialKnowledgeElementsDTO {
  @IsUUID()
  chapterId!: string;

  @IsUUID(undefined, { each: true })
  materialKnowledgeElementIds!: string[];
}
