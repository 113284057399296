import React, { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import CustomRadioButton from 'components/CustomRadioButton';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import StorageLocationItem from './StorageLocationItem';
import { StorageItemDTO } from '../../../../../dto/storageItem';

interface Props {
  setCheckedItems: (newItems: Set<string>) => void;
}

const SelectMaterialsContent = observer(({ setCheckedItems }: Props) => {
  const { storageLocationStore } = useStores();
  const { t } = useTranslation('storageLocations');
  const [checkedState, setCheckedState] = useState(new Set<string>());

  const handleOnChange = (storageItem: StorageItemDTO) => {
    setCheckedState(current => {
      if (current.has(storageItem.storageItemId)) {
        current.delete(storageItem.storageItemId);
      } else {
        current.add(storageItem.storageItemId);
      }
      return new Set(current);
    });
  };

  const isAllSelected = useMemo(() => {
    let all = true;

    if (storageLocationStore.selectedStorageLocation) {
      storageLocationStore.selectedStorageLocation.storageItems.forEach(item => {
        all = !checkedState.has(item.storageItemId) ? false : all;
      });
    }

    return all;
  }, [checkedState, storageLocationStore.selectedStorageLocation]);

  const handleSelectAll = () => {
    if (storageLocationStore.selectedStorageLocation) {
      const currentCheckedState = checkedState;
      if (isAllSelected) {
        storageLocationStore.selectedStorageLocation.storageItems.forEach(item => {
          currentCheckedState.delete(item.storageItemId);
        });
      } else {
        storageLocationStore.selectedStorageLocation.storageItems.forEach(item => {
          currentCheckedState.add(item.storageItemId);
        });
      }
      setCheckedState(new Set(currentCheckedState));
    }
  };

  useEffect(() => {
    setCheckedItems(checkedState);
  }, [checkedState, setCheckedItems]);

  if (storageLocationStore.selectedStorageLocation?.storageItems && storageLocationStore.selectedStorageLocation.storageItems.length >= 1) {
    return (
      <div>
        <CustomRadioButton
          title={t('moveStorageLocationFlyout.selectMaterials.selectAll')}
          checked={isAllSelected}
          onClick={handleSelectAll}
        />
        {storageLocationStore.selectedStorageLocation?.storageItems.map(storageItem => (
          <div key={storageItem.storageItemId}>
            <CustomRadioButton
              title={t('moveStorageLocationFlyout.selectMaterials.selectSingle')}
              checked={checkedState.has(storageItem.storageItemId)}
              onClick={() => handleOnChange(storageItem)}
            />
            <StorageLocationItem storageItem={storageItem} />
          </div>
        ))}
      </div>
    );
  }

  return <div>{t('moveStorageLocationFlyout.selectMaterials.noItems')}</div>;
});

export default SelectMaterialsContent;
