import { CreateContentElementMediaDTO } from 'dto/contentElementMedia';
import ProcedureStore from 'stores/procedureStore';
import SurgeryGuideStore from 'stores/surgeryGuideStore';
import { CreateProcedureContentElementDTO } from 'dto/contentElement';
import { CreateGuideProcedureDTO } from 'dto/procedure';
import { CreateSurgeryGuideProcedureDTO } from 'dto/surgeryGuideProcedure';

export interface ProcedureFormValues extends CreateProcedureContentElementDTO {
  createPost: boolean;
}

export const prepareMediasToSave = (contentElementMedias?: CreateContentElementMediaDTO[]) => {
  let newContentElementMedias: CreateContentElementMediaDTO[] | undefined;
  if (contentElementMedias) {
    newContentElementMedias = contentElementMedias.map(element => {
      return {
        fileId: element.fileId,
        title: element.title,
        pictureLabellingFileId: element.pictureLabellingFileId,
        pictureLabelling: element.pictureLabelling
      };
    });
  }

  return newContentElementMedias || [];
};

export const getInitialValues = (procedureStore: ProcedureStore, surgeryGuideStore: SurgeryGuideStore, isSurgery?: boolean) => {
  if (isSurgery) {
    return {
      title: surgeryGuideStore.selectedSurgeryProcedure ? surgeryGuideStore.selectedSurgeryProcedure.content.title : '',
      description: surgeryGuideStore.selectedSurgeryProcedure ? surgeryGuideStore.selectedSurgeryProcedure.content.description : '',
      isCheckable: surgeryGuideStore.selectedSurgeryProcedure ? !!surgeryGuideStore.selectedSurgeryProcedure.content.isCheckable : false,
      createPost: false,
      contentElementMedias: surgeryGuideStore.selectedSurgeryProcedure
        ? surgeryGuideStore.selectedSurgeryProcedure.content.contentElementMedias.map(c => {
            return { fileId: c.fileId, title: c.title };
          })
        : []
    };
  }
  return {
    title: procedureStore.selectedProcedure ? procedureStore.selectedProcedure?.content.title : '',
    description: procedureStore.selectedProcedure ? procedureStore.selectedProcedure?.content.description : '',
    isCheckable: procedureStore.selectedProcedure ? !!procedureStore.selectedProcedure?.content.isCheckable : false,
    createPost: false,
    contentElementMedias: procedureStore.selectedProcedure
      ? procedureStore.selectedProcedure.content.contentElementMedias.map(c => {
          return { fileId: c.fileId, title: c.title };
        })
      : []
  };
};

export const generateCreateProcedureData = (
  guideId: string,
  guideChapterId: string,
  position: number | undefined,
  { title, description, isCheckable = false, contentElementMedias, createPost }: ProcedureFormValues
): CreateGuideProcedureDTO => {
  return {
    guideChapterId,
    position,
    guideId,
    createPost,
    content: {
      title,
      description,
      isCheckable,
      contentElementMedias: prepareMediasToSave(contentElementMedias)
    }
  };
};

export const generateCreateSurgeryProcedureData = (
  surgeryGuideId: string,
  guideChapterId: string,
  { title, description, contentElementMedias }: ProcedureFormValues
): CreateSurgeryGuideProcedureDTO => {
  return {
    guideChapterId,
    position: 0,
    surgeryGuideId,
    content: {
      checked: false,
      title,
      description,
      contentElementMedias: prepareMediasToSave(contentElementMedias)
    }
  };
};
