import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'util/mobx/stores';
import { AbilityContext } from 'casl/setupCaslAbility';

interface Props {
  children: JSX.Element;
}

export default observer(({ children }: Props) => {
  const history = useHistory();
  const { domainStore, authStore } = useStores();

  if (!authStore.isLoggedIn) {
    history.push(`/?next=${history.location.pathname}${history.location.search}`);
    return null;
  }
  if (domainStore.ability === undefined) {
    throw new Error('ability not set');
  }

  // show dialog to choose a new domain if the current one is disabled
  if (!domainStore.currentDomainActive) {
    domainStore.setShowSwitchDomainFlyout(true);
  }

  return <AbilityContext.Provider value={domainStore.ability}> {children}</AbilityContext.Provider>;
});
