import React, { useRef, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { OptionTypeBase } from 'react-select';
import { useStores } from 'util/mobx/stores';

import FlyoutContainer from 'components/FlyoutContainer';
import { DepartmentDTO } from 'dto/department';

interface Props {
  isInDrawer?: boolean;
}

const SwitchDomainFlyout = observer(({ isInDrawer = false }: Props) => {
  const { t } = useTranslation('switchDomain');
  const itemRef: React.RefObject<HTMLDivElement> = useRef(null);
  const { domainStore, guideStore, postStore } = useStores();
  const isOpen = isInDrawer ? domainStore.isDrawerSwitchDomainFlyoutOpen : domainStore.isSwitchDomainFlyoutOpen;

  const closePopUp = useCallback(() => {
    domainStore.setShowSwitchDomainFlyout(false);
    domainStore.setShowDrawerSwitchDomainFlyout(false);
  }, [domainStore]);

  useEffect(() => {
    const onClose = (event: MouseEvent) => {
      if (isOpen && event.target instanceof Node && !itemRef.current?.contains(event.target)) {
        closePopUp();
      }
    };
    if (itemRef.current) {
      if (isOpen) {
        document.body.addEventListener('click', onClose);
      } else {
        document.body.removeEventListener('click', onClose);
      }
    }

    return () => {
      document.body.removeEventListener('click', onClose);
    };
  }, [isOpen, closePopUp]);

  const onSelect = async (selectedDepartmentId: string) => {
    if (selectedDepartmentId) {
      domainStore.handleSwitchDepartment(selectedDepartmentId);
      postStore.loadDepartmentPosts(selectedDepartmentId);
      guideStore.resetGuidesList();
    }
    closePopUp();
  };

  return (
    <FlyoutContainer
      className="switch-domain-flyout"
      isOpen={isOpen}
      closePopUp={closePopUp}
      itemRef={itemRef}
      title={t('headline')}
      icon={<img src="images/icon_edit_fachbereich.jpg" width="50" alt="" className="image_circle_40" />}
      cancelLabel={t('button.cancel')}
      iosMarginTop
    >
      <div className="flyout_box">
        <div>{t('label.department')}</div>
        <div className="menu_list" style={{ overflow: 'auto', maxHeight: 320 }}>
          {domainStore.departments.map((departmentsByLocation: OptionTypeBase) => (
            <React.Fragment key={`${departmentsByLocation.locationShortName}${departmentsByLocation.functionalAreaShortName}`}>
              <div className="txt_section_nav">
                {departmentsByLocation.locationShortName} | {departmentsByLocation.functionalAreaShortName}
              </div>
              {departmentsByLocation.departments.map((department: DepartmentDTO) => (
                <div
                  key={department.departmentId}
                  className={`btn_in_list ${domainStore.currentDepartment.id === department.departmentId && 'active'}`}
                  onClick={() => onSelect(department.departmentId)}
                >
                  {department.name}
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </FlyoutContainer>
  );
});
export default SwitchDomainFlyout;
