import React from 'react';
import { MaterialDTO } from 'dto/material';

import './FixedTopMaterialBox.css';
import Picture from 'components/Picture';
import MaterialValueTags from 'components/MaterialValueTags';
import GetCameraImageButton from 'components/GetCameraImageButton';

interface Props {
  material: MaterialDTO;
  isShow: boolean;
  onUpdateFile: (fileId: string) => Promise<void>;
}

const FixedTopMaterialBox = (props: Props) => {
  const { material, isShow } = props;
  const fixedTopBoxStyle = isShow ? 'show_on_scroll fixed_top_box active' : 'show_on_scroll fixed_top_box';

  return (
    <>
      <div className={fixedTopBoxStyle}>
        <div className="div-block-22">
          <div className="image_wrapper_50">
            <div className="image_border" />
            {material.pictureThumbnail ? (
              <Picture src={material.pictureThumbnail} width={50} alt="" className="image_circle_50" />
            ) : (
              <GetCameraImageButton buttonStyle="icon" onUpdateFile={props.onUpdateFile} description="" picturesOnly isAddable />
            )}
          </div>
          <div>{material.name}</div>
        </div>
        <MaterialValueTags classNameTitle="margin_left" material={material} />
      </div>
    </>
  );
};

export default FixedTopMaterialBox;
