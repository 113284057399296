import React from 'react';
import { useTranslation } from 'react-i18next';
import { PostDTO } from 'dto/post';
import { getDateWording } from 'util/date';
import ImageCircle from 'components/ImageCircle';
import PostMediaList from 'components/PostItem/PostMediaList';

interface Props {
  post: PostDTO;
  withDate?: boolean;
}

const CommentProcedureItem = ({ post, withDate = false }: Props) => {
  const date = getDateWording(post.createdAt, useTranslation('date'));
  return (
    <>
      {withDate && <div className="txt_chapter_flyout">{date}</div>}
      <div className="post_item in_flyout">
        <div className="post_box in_flyout_board">
          <ImageCircle className="image_circle_40_post" size="40" user={post?.contentElement.user} highlight={false} noWrapper />
          <div className="post_box in_post">
            {post?.guideProcedure?.content.isCheckable && <img src="images/icon_checkable_22.svg" alt="" className="icon_checkable" />}
            <div className="h2">{post?.guideProcedure?.content.title}</div>
            <div className="show-newlines">{post?.guideProcedure?.content.description}</div>
            <PostMediaList files={post.contentElement.contentElementMedias} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentProcedureItem;
