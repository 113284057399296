/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  hover?: boolean;
  showAdditionalBooks: boolean;
}

export default (props: Props) => {
  const { t } = useTranslation('guide');

  // TODO fix image
  const srcString = props.hover ? 'images/icon_ctrl-down_14__white.svg' : 'images/icon_ctrl-down_14.svg';

  return (
    <>
      <div className="txt_dropout_1">{props.showAdditionalBooks ? t('button.fadeOutAdditional') : t('button.fadeInAdditional')}</div>

      <img
        src={srcString}
        style={
          props.showAdditionalBooks
            ? {
                WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0DEG) skew(0, 0)',
                transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0DEG) skew(0, 0)',
                transitionDuration: '0.3s'
              }
            : {
                WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(-90DEG) skew(0, 0)',
                transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(-90DEG) skew(0, 0)',
                transitionDuration: '0.3s'
              }
        }
        alt=""
        className="icon_arrow_12"
      />
    </>
  );
};
