import React from 'react';
import ListItemDropdownContainer from 'components/ListItems/ListItemDropdownContainer';
import { HistoryDTO, SurgeryGuideHistoryDTO } from 'dto/history';
import { useStores } from 'util/mobx/stores';
import SurgeryHistoryProcedures from '../SurgeryHistoryProcedures';
import SurgeryHistoryMaterials from '../SurgeryHistoryMaterials';
import './GuideHistoryItem.css';
import { SurgeryHistoryFilterTypes } from '../../SurgeryHistoryFilters';

interface Props {
  surgeryGuideHistory: SurgeryGuideHistoryDTO;
  selectedFilter: SurgeryHistoryFilterTypes;
}

const GuideHistoryItem = ({ surgeryGuideHistory, selectedFilter }: Props) => {
  const { surgeryStore } = useStores();
  const surgeryGuide = surgeryStore.selectedSurgeryBriefing?.surgeryGuides.find(
    s => s.surgeryGuideId === surgeryGuideHistory.surgeryGuideId
  );

  if (!surgeryGuide) {
    return null;
  }

  const onProcedureClick = (historyItem: HistoryDTO) => {
    surgeryStore.selectHistoryItemAndGuide(historyItem, surgeryGuideHistory);
    surgeryStore.setIsHistoryProcedureFlyoutOpen(true);
  };

  const renderList = () => {
    return (
      <>
        {selectedFilter === SurgeryHistoryFilterTypes.materials && (
          <SurgeryHistoryMaterials histories={surgeryGuideHistory.materialHistories} />
        )}
        {selectedFilter === SurgeryHistoryFilterTypes.procedures && (
          <SurgeryHistoryProcedures onProcedureClick={onProcedureClick} histories={surgeryGuideHistory.procedureHistories} />
        )}
      </>
    );
  };

  return (
    <ListItemDropdownContainer ListElement={renderList} classPrefix="guide">
      <>
        <div className="image_wrapper_50">
          <img src="images/icon_book.jpg" alt="" className="image_circle_50" />
          <div className="image_border image-border" />
        </div>
        <div className="material_info">
          <div className="material_text">
            <div className="material_text bold">
              <div className="t2 bottom-margin">
                {surgeryGuide.guide.user.title} {surgeryGuide.guide.user.firstName} {surgeryGuide.guide.user.lastName}
              </div>
              <div>{surgeryGuide.guide.name}</div>
            </div>
          </div>
          <div className="item_number">
            <div className="txt_number_type">Handbuch ID</div>
            <div>{surgeryGuide.guide.guideNumber}</div>
          </div>
        </div>
      </>
    </ListItemDropdownContainer>
  );
};

export default GuideHistoryItem;
