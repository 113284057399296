import { useStores } from 'util/mobx/stores';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { LoadingType } from 'stores/loadingStore';
import { GetSurgeryGuideMaterialsDTO } from 'dto/surgeryGuide';
import { SetUsedMaterialDTO } from 'dto/usedMaterial';
import { useCallback } from 'react';
import { ApiMutationOptions, ApiQueryOptions } from './query';
import { getSurgeryGuideMaterialsV2, getUsedSurgeryGuideMaterials, setUsedSurgeryGuideMaterials } from './surgeryGuide';

export const useGetSurgeryGuideMaterialsV2 = (params: GetSurgeryGuideMaterialsDTO, options: ApiQueryOptions = {}) => {
  const { loadingStore } = useStores();
  const { loadingType, ...queryOptions } = options;
  return useQuery({
    queryKey: ['any-guide', 'surgery', 'briefing', 'surgery-guide', 'materials', params],
    queryFn: async () => {
      return loadingStore.withSpecificLoadingBar(loadingType || LoadingType.DEFAULT, () => getSurgeryGuideMaterialsV2(params));
    },
    ...queryOptions
  });
};

export const useGetUsedSurgeryGuideMaterials = (surgeryGuideId: string, options: ApiQueryOptions = {}) => {
  const { loadingStore } = useStores();
  const { loadingType, ...queryOptions } = options;
  return useQuery({
    queryKey: ['any-guide', 'surgery', 'briefing', 'surgery-guide', 'materials', 'used', { surgeryGuideId }],
    queryFn: async () => {
      return loadingStore.withSpecificLoadingBar(loadingType || LoadingType.DEFAULT, () => getUsedSurgeryGuideMaterials(surgeryGuideId));
    },
    ...queryOptions
  });
};

export const useSetUsedSurgeryGuideMaterials = (surgeryGuideId: string, options: ApiMutationOptions = {}) => {
  const { loadingStore } = useStores();
  const { loadingType, ...mutationOptions } = options;
  return useMutation<void, unknown, { usedMaterials: SetUsedMaterialDTO[] }>({
    mutationFn: async ({ usedMaterials }) => {
      return loadingStore.withSpecificLoadingBar(loadingType || LoadingType.DEFAULT, () => setUsedSurgeryGuideMaterials(usedMaterials));
    },
    mutationKey: ['any-guide', 'surgery', 'briefing', 'surgery-guide', 'materials', 'used', { surgeryGuideId }],
    ...mutationOptions
  });
};

export const useInvalidateUsedSurgeryGuideMaterials = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(['any-guide', 'surgery', 'briefing', 'surgery-guide', 'materials', 'used']);
  }, [queryClient]);
};
