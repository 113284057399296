import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { STARTPOINT } from 'constants/routes';
import NavSectionHeadline from './NavSectionHeadline';
import NavSectionBackButtonDesktop from './NavSectionBackButtonDesktop';
import NavSectionBackButton from './NavSectionBackButton';

import './NavSection.css';

interface Props {
  headline?: string;
  children: JSX.Element[] | JSX.Element;
  disableBackButton?: boolean;
  className: string;
}

const NavSection = observer((props: Props) => {
  const { appNavigationStore } = useStores();
  const history = useHistory();
  if (appNavigationStore.isKeyboardOpen) {
    return null;
  }

  const goBack = () => {
    if (appNavigationStore.canGoBack(history)) {
      history.goBack();

      return;
    }
    history.push(STARTPOINT);
  };

  return (
    <div className={`wrapper_section_nav wrapper-section-nav ${props.className}`}>
      {!props.disableBackButton && <NavSectionBackButtonDesktop goBack={goBack} />}

      <div className="nav_overview">
        {props.headline && <NavSectionHeadline headline={props.headline} />}
        {!props.disableBackButton && <NavSectionBackButton icon="icon_small-left_16" label="Zurück" goBack={goBack} />}
        {props.children}
      </div>
    </div>
  );
});

export default NavSection;
