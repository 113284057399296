/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { getColorByRatio } from '../ReportGuides/reportGuidesUtils';
import { getMaxValue, colors } from './horizontalBarChartUtils';
import { HorizontalBarChartTypes, DepartmentReportDTO } from '../types';
import './HorizontalBarChart.css';

interface Props {
  selectedDepartment?: DepartmentReportDTO;
  departments: DepartmentReportDTO[];
  type: HorizontalBarChartTypes;
}

const HorizontalBarChart = observer(({ selectedDepartment, departments, type }: Props) => {
  const { t } = useTranslation('reports');
  const [isAnimationInProgress, setIsAnimationInProgress] = useState(true);
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setIsAnimationInProgress(false);
    }, 1000);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  /**
   * @param value the amount of guides in a department
   * @returns the size of the bar, depending on the amount of guides in a department
   * if there are no guides in a departments it uses its minSize
   */
  const calculateBarSize = (value: number): number => {
    const maxValue = getMaxValue(departments);
    const minSize = 0.5;

    if (value < minSize) {
      return minSize / maxValue;
    }

    return value / maxValue;
  };

  if (type === HorizontalBarChartTypes.percentage) {
    const otherDepartments: DepartmentReportDTO[] = departments.filter(d => d.id !== (selectedDepartment && selectedDepartment.id));
    return (
      <div className="div-block-93 with_chart">
        {otherDepartments.map(department => {
          const percent = Math.round(department.value * 100);
          return (
            <div className="div-block-94" key={department.id}>
              <div>{department.name}</div>
              {percent > 0 && (
                <div
                  style={{
                    backgroundColor: getColorByRatio(department.value),
                    width: isAnimationInProgress ? 0 : `${percent}%`
                  }}
                  className="bar_chart bar-chart"
                >
                  {!isAnimationInProgress && <span style={{ marginLeft: percent < 5 ? percent * 0.1 + 20 : 0 }}>{percent}%</span>}
                </div>
              )}
              {department.value === 0 && <div className="bar_chart_empty">{t('guides.zeroText')}</div>}
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div className="div-block-93 with_chart">
      {departments.map((department, index) => {
        const amount = department.value;
        const barSize = calculateBarSize(amount);

        let width = isAnimationInProgress ? 0 : `${barSize * 100}%`;

        let textMarginLeft = barSize < 5 ? barSize * 0.1 + 20 : 0;
        if (amount === 0) {
          width = '26px';
          textMarginLeft = 0;
        }

        return (
          <div className="div-block-94" key={department.id}>
            <div>{department.name}</div>
            <div
              style={{
                backgroundColor: colors[index],
                width
              }}
              className="bar_chart bar-chart"
            >
              {!isAnimationInProgress && <span style={{ marginLeft: textMarginLeft }}>{amount}</span>}
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default HorizontalBarChart;
