import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { GroupedSurgeryGuideProcedureDTO } from 'dto/surgeryGuideProcedure';
import PostMediaList from 'components/PostItem/PostMediaList';
import SurgeryMaterialStatusBox from 'pages/surgeryMaterials/SurgeryMaterialLikeListItem/SurgeryMaterialStatusBox';
import BlackCheckBox from '../SurgeryProcedureDrawerFooter/BlackCheckBox';
import './ProcedureContentDrawerBox.css';

interface Props {
  group: GroupedSurgeryGuideProcedureDTO;
}

const getAnimationClass = (isSlideForward: boolean | undefined, previousIndex: number, currentIndex: number) => {
  if (previousIndex > currentIndex) {
    return 'reverse';
  }
  if (previousIndex > currentIndex) {
    return 'forward';
  }
  if (isSlideForward === undefined) {
    return '';
  }
  if (isSlideForward) {
    return 'reverse';
  }
  return 'forward';
};

const ProcedureContentDrawerBox = observer(({ group }: Props) => {
  const { surgeryGuideStore } = useStores();
  const [procedure, setProcedure] = useState(group.surgeryGuideProcedures[surgeryGuideStore.procedureIndexInView]);
  const [previousIndex, setPreviousIndex] = useState(surgeryGuideStore.procedureIndexInView);
  const [isSlideForward, setIsSlideForward] = useState<boolean | undefined>();

  useEffect(() => {
    if (surgeryGuideStore.procedureIndexInView > previousIndex) {
      setIsSlideForward(true);
      setPreviousIndex(surgeryGuideStore.procedureIndexInView);
    }
    if (surgeryGuideStore.procedureIndexInView < previousIndex) {
      setIsSlideForward(false);
      setPreviousIndex(surgeryGuideStore.procedureIndexInView);
    }
    setProcedure(group.surgeryGuideProcedures[surgeryGuideStore.procedureIndexInView]);
  }, [surgeryGuideStore.procedureIndexInView, group.surgeryGuideProcedures, previousIndex]);

  const onStatusChanged = (checked: boolean) => {
    surgeryGuideStore.updateProcedure({
      surgeryGuideProcedureId: procedure.surgeryGuideProcedureId,
      checked
    });
  };

  return (
    <div
      className={`op-pilot_content_box op-pilot-content-box ${getAnimationClass(
        isSlideForward,
        previousIndex,
        surgeryGuideStore.procedureIndexInView
      )}`}
    >
      <div className="post_item in_op-pilot">
        <BlackCheckBox small status={procedure.checked} onStatusChanged={onStatusChanged} key={+procedure.checked} />
        <div className="post_box in_op-pilot">
          <div className="headline_op-pilot">
            <strong>{procedure.content.title}</strong>
          </div>
          <div className="txt_content">{procedure.content.description}</div>
          <PostMediaList files={procedure.content.contentElementMedias} />
          <SurgeryMaterialStatusBox procedure={procedure} />
        </div>
      </div>
    </div>
  );
});

export default ProcedureContentDrawerBox;
