import React from 'react';
import './Alert.css';

interface Props {
  hide?: boolean;
  icon?: string;
  className?: string;
  onClick?: () => void;
  headline: string;
  message: string;
}

export default ({
  hide = false,
  icon = 'images/icon_wifi-off_big.svg',
  className = 'warning_message',
  headline,
  message,
  onClick
}: Props) => {
  return (
    <div className={`infobox-wrapper ${className} ${hide ? 'alert-animation-out' : 'alert-animation-in'}`} onClick={onClick}>
      {onClick ? <img className="close" src="images/icon_e-remove_12.svg" alt="" loading="lazy" /> : <></>}
      <img src={icon} width="46" alt="" className="image-9" />
      <div>
        <div>{headline}</div>
        <div className="t2 dark">{message}</div>
      </div>
    </div>
  );
};
