import { useGetGuideMaterialCategories } from 'api/guideMaterialCategoryHooks';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import { UpdateSortGuideMaterialsV2DTO } from 'dto/guide';
import { GuideMaterialCategoryDTO } from 'dto/guideMaterialCategory';
import React, { useCallback, useMemo } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { GuideMaterialListContext, GuideMaterialListState, OnGuideMaterialClick } from './guideMaterialListContext';
import { filterCategory } from './utils';

import './GuideMaterialList.css';

export type CategoryMaterialListRenderer = React.FC<{
  CategoryPrefix?: CategoryPrefixRenderer;
  guideMaterialCategory: GuideMaterialCategoryDTO;
}>;

export type CategoryPrefixRenderer = React.FC<{
  guideId: string;
  guideMaterialCategoryId: string;
}>;

export interface Props extends OptionalLazyLoadProps {
  /**
   * This is a renderer to abstract away the specific material loading.
   * You can either pass the CategorySurgeryMaterialList or the CategoryMaterialList.
   */
  CategoryMaterialListElement: CategoryMaterialListRenderer;
  functionalAreaId: string;
  CategoryPrefix?: CategoryPrefixRenderer;
  guideLikeId: string;
  onGuideMaterialClick?: OnGuideMaterialClick;
  onReorder?: (reorder: UpdateSortGuideMaterialsV2DTO) => void;
  onReload?: () => Promise<void>;
  onDelete?: (guideMaterialId: string) => Promise<void>;
  readonly?: boolean;
}

export const GuideMaterialList: React.FC<Props> = ({
  CategoryMaterialListElement,
  functionalAreaId,
  CategoryPrefix,
  guideLikeId,
  onGuideMaterialClick,
  onReorder,
  onReload,
  onDelete,
  readonly = false,
  lazyLoadScrollContainer
}) => {
  const { data: groupedGuideMaterialCategories } = useGetGuideMaterialCategories({});

  // Map the correct functional area out of the result.
  const guideMaterialCategories = useMemo(() => {
    return filterCategory(functionalAreaId, groupedGuideMaterialCategories);
  }, [functionalAreaId, groupedGuideMaterialCategories]);

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination || !onReorder || !onReload) {
        return;
      }

      await onReorder({
        guideId: guideLikeId,
        guideMaterialCategoryId: result.destination.droppableId,
        index: result.destination.index,
        guideMaterialId: result.draggableId
      });
      await onReload();
    },
    [guideLikeId, onReload, onReorder]
  );

  const state = useMemo<GuideMaterialListState>(
    () => ({
      onReload,
      readonly,
      guideLikeId,
      onGuideMaterialClick,
      lazyLoadScrollContainer,
      onDelete
    }),
    [onReload, readonly, guideLikeId, onGuideMaterialClick, lazyLoadScrollContainer, onDelete]
  );

  if (!guideMaterialCategories) {
    return <></>;
  }

  return (
    <GuideMaterialListContext.Provider value={state}>
      <DragDropContext onDragEnd={handleDragEnd}>
        {guideMaterialCategories.map(category => (
          <Droppable droppableId={category.guideMaterialCategoryId} key={category.guideMaterialCategoryId}>
            {provided => (
              <div ref={provided.innerRef} className="single-colum-content-item">
                <CategoryMaterialListElement CategoryPrefix={CategoryPrefix} guideMaterialCategory={category} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </GuideMaterialListContext.Provider>
  );
};
