import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { MaterialDTO, MaterialType } from 'dto/material';
import Picture from 'components/Picture';

import './SearchMaterialItem.css';
import Button from 'components/Form/Button';

interface Props {
  material: MaterialDTO;
  isLast?: boolean;
}

const SearchMaterialItem = observer(({ material, isLast }: Props) => {
  const { t } = useTranslation('materialSearch');
  const { searchStore } = useStores();

  const handleShowMaterialContainerFlyout = () => {
    searchStore.selectSearchItem(material);
  };

  return (
    <div className={`div-block-27 search-material-item ${isLast ? 'last' : ''}`}>
      <div className="list_item_material_in_search search-material-list-item" onClick={handleShowMaterialContainerFlyout}>
        <div className="image_wrapper_50">
          <div style={{ opacity: 0 }} className="image_border" />
          <Picture src={material.pictureThumbnail} width="50" alt="" className="image_circle_50" />
        </div>
        <div className="material_info in_search material-info">
          <div className="div-block-34">
            <div className="material_text search-panel">
              <div>{material.name}</div>
            </div>
            {material.manufacturerArticleNumber ? (
              <div className="item_number search-panel">
                <div className="txt_number_type">{t('searchItem.numberType')}</div>
                <div>{material.manufacturerArticleNumber}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="div-block-30 search-panel">
            {material.materialType === MaterialType.instrument ? (
              <div className="info_box material_search no_hover">
                <div>{t('searchItem.materialTypeInstrument')}</div>
              </div>
            ) : (
              <div />
            )}

            {searchStore.actionButtonLabel && (
              <div onClick={handleShowMaterialContainerFlyout}>
                <Button className="btn_small_flyout">
                  <div className="txt_dropout_1">{searchStore.actionButtonLabel}</div>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default SearchMaterialItem;
