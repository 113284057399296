import React from 'react';
import Picture from 'components/Picture';
import './PreviewImage.css';

interface Props {
  src: string;
}
export default function PreviewImage({ src }: Props) {
  return (
    <div className="preview_image">
      <div className="image_wrapper_50">
        <Picture alt="taken" src={src} className="image_circle_50" />
      </div>
    </div>
  );
}
