import React from 'react';
import { SimpleStorageLocationDTO } from 'dto/storageLocation';
import Picture from 'components/Picture';

interface Props {
  storageLocation: SimpleStorageLocationDTO;
  last: boolean;
}

const LocationBox = ({ storageLocation, last }: Props) => {
  return (
    <div className={`location_box location-box ${last ? 'last-box' : ''}`}>
      <div className={`info_box location ${last ? 'last' : ''}`}>
        {last ? (
          <Picture src={storageLocation.pictureThumbnail} width={50} alt="" className="image_circle_30 static" />
        ) : (
          <img src="images/icon_pennant_12.svg" alt={storageLocation.name} />
        )}

        <div className="div-block-7">{storageLocation.name}</div>
      </div>
    </div>
  );
};

export default LocationBox;
