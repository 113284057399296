import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Safe from 'components/Safe';
import Page from 'components/Page';
import { AppBarHeaderText, AppBarPageIcon } from 'components/AppLayout/AppBar';
import AppLayout from 'components/AppLayout';

import UserSettingsData from 'pages/userSettingsData';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import UserSettingsNavigation from './userSettingsNavigation';
import './UserSettings.css';

const UserSettings = observer(() => {
  const { t: translationUserSettings } = useTranslation('userSettings');
  const { t: translationAccountManagement } = useTranslation('accountManagement');
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;

  return (
    <Safe>
      <AppLayout
        appBarIcon={<AppBarPageIcon src="images/image_kp-settings.jpg" />}
        appBarChildren={<AppBarHeaderText headline={translationUserSettings('pageTitle')} />}
        boardFlyout={<BoardFlyout />}
      >
        <Page
          disableBackButton
          idPrefix="guides"
          headline={translationAccountManagement('subMenu.headline')}
          withChatBar={false}
          withoutRightMenu
          withoutRightMenuClass={false}
          navigation={<UserSettingsNavigation currentPath={currentPath} />}
        >
          <Switch>
            <Route path="/user-settings/data" component={UserSettingsData} />
          </Switch>
        </Page>
      </AppLayout>
    </Safe>
  );
});

export default UserSettings;
