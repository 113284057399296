import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDayTimeWording } from 'util/date';

interface Props {
  departmentName?: string;
  createAt: Date;
}

const BoardPostFooter = ({ departmentName = '', createAt }: Props) => {
  return (
    <div className="b_box_info">
      <div className="b_label">
        <img src="images/icon_saved-items_12.svg" alt="" className="icon_board_fachbereich" />
        <div>{departmentName}</div>
      </div>
      <div className="txt_dropout_1">{getDayTimeWording(createAt, useTranslation('date'))}</div>
    </div>
  );
};

export default BoardPostFooter;
