import HoverWrapper from 'components/HoverWrapper/HoverWrapper';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import React, { useCallback, useState } from 'react';
import { Popover, PopoverPosition } from 'react-tiny-popover';

interface ChildrenProps {
  isOpen: boolean | undefined;
  handleClose: () => void;
}

interface Props {
  isOpen?: boolean;
  children: (data: ChildrenProps) => React.ReactNode;
  popoverPosition?: PopoverPosition[];
  customTrigger?: React.ReactElement;
  customTriggerClasses?: string;
  boundaryInset?: number;
  hoverContainerId?: string;
}

const PopoverV2 = (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen ?? false);

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => setIsOpen(false), []);

  return (
    <Popover
      isOpen={isOpen}
      reposition
      boundaryInset={props.boundaryInset ?? 150}
      positions={props.popoverPosition || ['bottom', 'right', 'top', 'left']}
      align="start"
      onClickOutside={() => setIsOpen(false)}
      content={<>{props.children({ handleClose, isOpen })}</>}
      containerStyle={{ zIndex: '100', left: '30px' }}
    >
      {props.customTrigger ? (
        <div className={props.customTriggerClasses} onClick={handleClick}>
          {props.customTrigger}
        </div>
      ) : (
        <div>
          <HoverWrapper hoverContainerId={props.hoverContainerId}>
            {({ hover }) => <ArrowDotsVerticalIcon direction={!hover} onClick={handleClick} />}
          </HoverWrapper>
        </div>
      )}
    </Popover>
  );
};

export default PopoverV2;
