import React from 'react';
import { SurgeryGuideDTO } from 'dto/surgeryGuide';
import { UserDTO } from 'dto/user';
import HoverWrapper from 'components/HoverWrapper';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SurgeryGuideActions from './SurgeryGuideActions';

interface Props {
  surgeryGuide: SurgeryGuideDTO;
  user: UserDTO;
}

const SurgeryGuideListItem = ({ surgeryGuide, user }: Props) => {
  const { t } = useTranslation('surgery');
  return (
    <>
      <HoverWrapper className="list_item_book in_status list-item-book list-item">
        {({ hover }) => (
          <>
            <div className="image_wrapper_50">
              <img src="images/icon_book.jpg" alt="" className="image_circle_50" />
              <div className="image_border image-border" />
            </div>
            <div className="book_info with_hover in_operationen book-info">
              <Link
                to={`/surgery-guide/materials?surgeryGuideId=${surgeryGuide.surgeryGuideId}`}
                className="material_text bold w-inline-block"
              >
                <div className="t2 bottom-margin">
                  {user.firstName} {user.lastName}
                </div>
                <div>{surgeryGuide.guide.name}</div>
              </Link>
            </div>
            <div className="btn_show_detail">
              <SurgeryGuideActions guide={surgeryGuide} hover={hover} />
            </div>
          </>
        )}
      </HoverWrapper>
      <div className="note_wrapper in_briefing">
        <div className="material_note">
          <div>
            {t('guideItem.guideId')}: {surgeryGuide.guide.guideNumber}
          </div>
        </div>
        <div className="material_note">
          <div>
            {t('guideItem.caseNumber')}: {surgeryGuide.surgeryGuideNumber}
          </div>
        </div>
      </div>
    </>
  );
};

export default SurgeryGuideListItem;
