import React, { CSSProperties } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import { useTranslation } from 'react-i18next';
import { ContentType } from 'dto/file';
import { FileUploadFunc } from 'api/common';
import { MixedContentElementMediaDTO } from 'components/UploadMultipleFiles/MixedContentElementMediaDTO';
import PreviewMediaBox from './PreviewMediaBox';
import PreviewDocumentBox from './PreviewDocumentBox';
import CommentFileBox from './CommentFileBox';

interface Props {
  onReorder?: (files: ContentElementMediaDTO[]) => void;
  onChange?: (files: MixedContentElementMediaDTO[]) => void;
  files: MixedContentElementMediaDTO[];
  saveFile: FileUploadFunc;
  inComment?: boolean;
}

const ThumbnailList = ({ onReorder, files, onChange, saveFile, inComment = false }: Props) => {
  const { t } = useTranslation('itemFormFlyOut');
  const onMediaLabelChange = (mediaId: string, title: string) => {
    if (!onChange) return;
    const updatedFiles = files.map(file => {
      if (file?.fileId === mediaId) {
        return {
          ...file,
          title
        };
      }
      return file;
    });
    onChange(updatedFiles);
  };

  const onDeleteImage = async (fileId: string) => {
    if (!onChange) return;
    const updatedFiles = files.filter(f => f.fileId !== fileId);
    onChange(updatedFiles);
  };

  const mediaFiles = files.filter(f => f.contentType && (f.contentType === ContentType.Picture || f.contentType === ContentType.Video));
  const documents = files.filter(f => f.contentType !== ContentType.Picture && f.contentType !== ContentType.Video);
  const grid = 4;

  const getListStyle = () =>
    ({
      display: 'flex',
      flexWrap: 'wrap',
      padding: grid,
      width: '100%'
    } as CSSProperties);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const mediaFileToBeMoved = mediaFiles.find(mediaFile => mediaFile.fileId === result.draggableId);
    const oldMediaFile = mediaFiles.filter(mediaFile => mediaFile.fileId !== result.draggableId);

    if (mediaFileToBeMoved && onReorder) {
      oldMediaFile.splice(result.destination.index, 0, mediaFileToBeMoved);
      onReorder([...oldMediaFile, ...documents]);
    }
  };

  const onUpdateImageLabeling = async (fileId: string, pictureLabelling: string, labeledBlob: Blob) => {
    if (!onChange) return;
    const bodyFormData = new FormData();
    bodyFormData.append('file', labeledBlob);
    const metadata = await saveFile(bodyFormData, () => {
      console.log('TODO: progress display (if needed?)');
    });

    const file = files.find(f => f.fileId === fileId);
    const updatedFiles = files.map(f => {
      if (file && f.fileId === fileId) {
        return {
          ...file,
          pictureLabellingUrl: metadata.fullUrl,
          pictureLabelling,
          pictureLabellingFileId: metadata.fileId
        };
      }
      return f;
    });
    onChange(updatedFiles);
  };

  if (inComment) {
    return (
      <div className="chat_media_wrapper chat-media-wrapper">
        {files.map(file => (
          <CommentFileBox key={file.fileId} file={file} onDeleteImage={onDeleteImage} />
        ))}
      </div>
    );
  }
  return (
    <>
      {mediaFiles.length > 0 && (
        <div className="div-block-41 with_content">
          <div className="media_images_wrapper" style={{ marginTop: 20 }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="procedureFlyout" direction="horizontal">
                {provided => (
                  <div ref={provided.innerRef} style={getListStyle()}>
                    {mediaFiles.map((file, index) => {
                      return (
                        <PreviewMediaBox
                          updateImageLabeling={onUpdateImageLabeling}
                          onMediaLabelChange={onMediaLabelChange}
                          onDeleteImage={onDeleteImage}
                          key={file.fileId}
                          file={file}
                          index={index}
                          progress={file.progress}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      )}
      {documents.length > 0 && (
        <>
          <div>{t('label.files')}</div>
          <div className="media_files_wrapper_in_flyout">
            {documents.map(document => (
              <>
                {document.contentType === ContentType.Video || document.contentType === ContentType.Picture ? (
                  <>
                    {document.progress}
                    <PreviewDocumentBox file={document} onDeleteFile={onDeleteImage} key={document.smallUrl} />
                  </>
                ) : (
                  <PreviewDocumentBox file={document} onDeleteFile={onDeleteImage} key={document.smallUrl} />
                )}
              </>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default ThumbnailList;
