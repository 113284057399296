import React from 'react';

interface Props {
  isOpen?: boolean;
  handleClose: () => void;
  children: JSX.Element | (JSX.Element | undefined)[] | undefined;
  className?: string;
}

const ItemMenu = ({ isOpen, handleClose, children, className = '' }: Props) => {
  return (
    <>
      {isOpen ? (
        <div
          onClick={handleClose}
          style={{
            WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
            transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
            display: 'block'
          }}
          className={`menu_edit ${className}`}
        >
          {children}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ItemMenu;
