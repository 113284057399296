import React from 'react';
import ClearedButton from 'components/ClearedButton';

interface Props {
  goBack: () => void;
  children: JSX.Element[] | JSX.Element;
  fullWidth?: boolean;
  autoWidth?: boolean;
  className?: string;
}

type Style = { textDecoration: string; width?: string };

const LinkWrapper = (props: Props) => {
  const customStyle: Style = { textDecoration: 'none' };
  if (props.fullWidth) {
    customStyle.width = '100%';
  }
  if (props.autoWidth) {
    customStyle.width = 'auto';
  }

  return (
    <ClearedButton className={props.className} onClick={props.goBack}>
      {props.children}
    </ClearedButton>
  );
};

export default LinkWrapper;
