import { SessionDTO, RegisterDTO, UpdatePasswordWithTempPasswordDTO, CheckTempPasswordDTO, AccountDTO } from 'dto/user';
import { unsafePost, unsafePatch } from './common';
import { validate, validateArray, validateBool } from './validation';

export const checkAuth = async (username: string, password: string): Promise<SessionDTO> => {
  const account = await unsafePost('/account/login', { username, password }, true).then(response => response.data);
  return validate(SessionDTO, account);
};

/**
 * call loginWithTempPassword function for login and setting of new Password(removing TempPassword)
 * so a user can login normal after this has been done
 */
export const updateAndLoginWithTmpPassword = async (authWithTempPassword: UpdatePasswordWithTempPasswordDTO): Promise<SessionDTO> => {
  const session = await unsafePatch('/account/user/login/tempPassword', authWithTempPassword).then(response => response.data);
  return validate(SessionDTO, session);
};

export const checkTempPassword = async (tempPasswordToCheck: CheckTempPasswordDTO) => {
  const isValid = await unsafePost('/account/user/login/checkTempPassword', tempPasswordToCheck, true).then(response => response.data);
  return validateBool(isValid);
};

export const signupUser = async (newUser: RegisterDTO) => {
  const user = await unsafePost('/account/register', newUser).then(response => response.data);
  return validateArray(AccountDTO, user);
};
