import { get } from './common';
import { validate } from './validation';
import { ConfigDTO } from '../dto/config';

export const getServerConfig = async (): Promise<ConfigDTO> => {
  const config = await get('/config', undefined, undefined, true, true)
    .then(response => response.data)
    .catch(err => {
      // TODO: v1.3.7 - this check is only for backwards compatibility with server v1.3.7
      if (err.response?.status === 401) {
        console.warn('detected old server - workaround missing api');
        return { ldapEnabled: false, ldapAutomaticallyUpdateUserData: false } as ConfigDTO;
      }
      throw err;
    });
  return validate(ConfigDTO, config);
};
