import { Plugins, NetworkStatus, KeyboardInfo, AppState } from '@capacitor/core';
import { ScreenOrientation } from '@ionic-native/screen-orientation';

const { Device, Storage } = Plugins;

const API_URL_STORAGE_KEY = 'apiUrl';

export async function isMobile(): Promise<boolean> {
  const info = await Device.getInfo();
  switch (info.platform) {
    case 'ios':
      return true;

    case 'android':
      return true;

    case 'web':
      return false;
    case 'electron':
      return false;

    default:
      break;
  }
  return false;
}

export async function lockPortrait() {
  if (await isMobile()) {
    ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
  }
}

export async function unlockPortrait() {
  if (await isMobile()) {
    ScreenOrientation.unlock();
  }
}

export async function scanQR(options?: phonegapBarcode.BarcodeScanOptions): Promise<phonegapBarcode.BarcodeScanResult> {
  return new Promise((resolve, reject) => {
    ((window as any).cordova.plugins as CordovaPlugins).barcodeScanner.scan(
      result => {
        resolve(result);
      },
      err => {
        reject(err);
      },
      options
    );
  });
}

export async function addNetworkListeners(networkStatusChange: (status: NetworkStatus) => void) {
  if (await isMobile()) {
    Plugins.Network.addListener('networkStatusChange', networkStatusChange);
  }
}

/**
 * added this function because when starting the app initially
 * without wifi connection the wifiIndicator wouldnt recognize it
 * because it only triggers when changing the connection state...
 * so you run this function first to avoid this problem
 */
export async function getNetworkStatus() {
  if (await isMobile()) {
    return Plugins.Network.getStatus();
  }
  return undefined;
}

export async function addKeyboardListeners(onKeyboardDidShow: (info: KeyboardInfo) => void, onKeyboardDidHide: () => void) {
  if (await isMobile()) {
    Plugins.Keyboard.addListener('keyboardDidShow', onKeyboardDidShow);
    Plugins.Keyboard.addListener('keyboardDidHide', onKeyboardDidHide);
  }
}

export async function setApiUrl(value: string) {
  await Storage.set({
    key: API_URL_STORAGE_KEY,
    value
  });
}

export async function getApiUrl() {
  return Storage.get({ key: API_URL_STORAGE_KEY });
}

let timeWentInactive = 0;
let timeout: NodeJS.Timeout;
export async function addAppListenerToCheckIfActive(logout: () => void) {
  if (await isMobile()) {
    Plugins.App.addListener('appStateChange', (state: AppState) => {
      if (!state.isActive) {
        timeWentInactive = new Date().getTime();

        timeout = setTimeout(() => {
          Plugins.App.getState().then(currentState => {
            if (!currentState.isActive) {
              logout();
            }
          });
        }, 15000);
      } else if (new Date().getTime() - timeWentInactive > 10000) {
        logout();
      } else if (timeout) {
        clearTimeout(timeout);
      }
    });
  }
}
