import React from 'react';
import { useTranslation } from 'react-i18next';
import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleOpenDetails: () => void;
  handleDelete: () => void;
}

const GuidePackageListItemMenu = ({ isOpen, handleClose, handleOpenDetails, handleDelete }: Props) => {
  const { t } = useTranslation('guideMaterials');
  return (
    <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
      <ItemMenuButton
        label={t('detailMenu.packageKnowledgeAndAttitudes')}
        icon="icon_search-content_16.svg"
        isInPopover
        handleClick={handleOpenDetails}
      />

      <ItemMenuButton
        label={t('detailMenu.packageDeleteFromGuide')}
        icon="icon_bin_16.svg"
        warning
        isInPopover
        handleClick={handleDelete}
      />
    </ItemMenu>
  );
};

export default GuidePackageListItemMenu;
