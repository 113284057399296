import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import MaterialDrawerDetailNav from 'components/MaterialDrawerDetailNav';
import { GuideMaterialLikeDTO } from 'dto/guide';
import { SetType, BasicMaterialSetDTO } from 'dto/materialSet';
import { CSSTransition } from 'react-transition-group';
import { Can, subjectArea } from 'casl/setupCaslAbility';
import Button from 'components/Form/Button';
import CreateMaterialSynonymFlyout from 'components/MaterialKnowledge/CreateMaterialSynonymFlyout';
import { subject } from '@casl/ability';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import { getKeyByValue } from 'util/enum';
import GuideSettings from '../GuideSettings';
import FixedTopMaterialSetBox from './FixedTopMaterialSetBox';
import MaterialSetHead from './MaterialSetHead';
import DrawerMaterialSetContent from './DrawerMaterialSetContent';

interface Props {
  materialSet: BasicMaterialSetDTO;
  guideMaterial?: GuideMaterialLikeDTO;
  surgeryGuideMaterial?: SurgeryGuideMaterialLikeDTO;
  onUpdateGuideMaterial: (guideMaterial?: GuideMaterialLikeDTO, surgeryGuideMaterial?: SurgeryGuideMaterialLikeDTO) => void;
}

const MaterialSetDrawerContent = observer(({ materialSet, guideMaterial, surgeryGuideMaterial, onUpdateGuideMaterial }: Props) => {
  const { guideStore, materialSetsStore, guideDetailDrawerStore, procedureStore, searchStore, domainStore } = useStores();
  const [isShow, setIsShow] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(window.innerWidth >= 991);
  const { t } = useTranslation('guideSettings');

  const handleContentWrapperScroll = (event: React.UIEvent<HTMLElement>) => {
    if (!isShow && event.currentTarget.scrollTop >= 300) {
      setIsShow(true);
    } else if (isShow && event.currentTarget.scrollTop <= 299 && event.currentTarget.scrollTop !== 0) {
      setIsShow(false);
    }
  };

  const onUpdateFile = async (fileId: string) => {
    await materialSetsStore.updateMaterialSet({ materialSetId: materialSet.materialSetId, pictureFileId: fileId }, false);
    guideDetailDrawerStore.reloadSelectedMaterialSet();

    if (guideStore.selectedGuide) {
      procedureStore.loadProcedures(guideStore.selectedGuide.guideId);
    }

    if (searchStore.isSearchPanelOpen) {
      searchStore.refreshSearch();
    }
  };

  const materialSetType = SetType[materialSet.type].toLowerCase();

  return (
    <div className="div-block-14">
      <div className="div-block-12">
        <div className="ios-bar" />
        <CreateMaterialSynonymFlyout
          isOpen={guideDetailDrawerStore.isCreateMaterialSynonymOpen}
          onClose={() => guideDetailDrawerStore.closeCreateMaterialSynonymFlyout()}
        />
        <div className="mat_content_wrapper" onScroll={handleContentWrapperScroll}>
          <FixedTopMaterialSetBox materialSet={materialSet} isShow={isShow} onUpdateFile={onUpdateFile} />
          <div className="div-block-21" onScroll={handleContentWrapperScroll}>
            <MaterialSetHead materialSet={materialSet} onUpdateFile={onUpdateFile} />
            <DrawerMaterialSetContent key={materialSet.materialSetId} materialSet={materialSet} />
          </div>
        </div>
        {guideDetailDrawerStore.settingsEnabled && guideMaterial && (
          <Can I="see" this={subject(subjectArea.materialSettings, { departmentId: domainStore.currentDepartment.id })}>
            <CSSTransition in={isSettingsOpen} timeout={500} classNames="settings-drawer" unmountOnExit>
              <GuideSettings
                hideStorageLocation={getKeyByValue(SetType, materialSetType) === SetType.Medicals}
                surgeryGuideMaterial={surgeryGuideMaterial}
                guideMaterial={guideMaterial}
                onSettingsClose={() => setIsSettingsOpen(false)}
              />
            </CSSTransition>
          </Can>
        )}
        {guideDetailDrawerStore.settingsEnabled && surgeryGuideMaterial && (
          <CSSTransition in={isSettingsOpen} timeout={500} classNames="settings-drawer" unmountOnExit>
            <GuideSettings
              hideStorageLocation={getKeyByValue(SetType, materialSetType) === SetType.Medicals}
              surgeryGuideMaterial={surgeryGuideMaterial}
              guideMaterial={guideMaterial}
              onSettingsClose={() => setIsSettingsOpen(false)}
            />
          </CSSTransition>
        )}
      </div>
      <div className="div-block-13">
        <MaterialDrawerDetailNav
          detailLink={`/group/${materialSetType}/overview?materialSetId=${materialSet.materialSetId}`}
          knowledgeLink={`/group/${materialSetType}/content?materialSetId=${materialSet.materialSetId}`}
          onShowSettings={() => setIsSettingsOpen(true)}
          onUpdateFile={onUpdateFile}
          isGuideMaterial={!!guideMaterial}
          isSurgeryGuideMaterial={!!surgeryGuideMaterial}
        />
        <Button onClick={() => onUpdateGuideMaterial(guideMaterial, surgeryGuideMaterial)} className="btn_big_cancel active">
          <div>{t('button.close')}</div>
        </Button>
      </div>
    </div>
  );
});

export default MaterialSetDrawerContent;
