import { Gender } from 'dto/user';
import i18n from './i18n';

export const GenderDropdownOptions = [
  {
    label: i18n.t('gender:female'),
    value: Gender.Female
  },
  {
    label: i18n.t('gender:male'),
    value: Gender.Male
  },
  {
    label: i18n.t('gender:diverse'),
    value: Gender.Diverse
  }
];

export const GenderDropdownOptionsSelection = [
  {
    label: i18n.t('salutationSelection:female'),
    value: Gender.Female
  },
  {
    label: i18n.t('salutationSelection:male'),
    value: Gender.Male
  },
  {
    label: i18n.t('salutationSelection:diverse'),
    value: Gender.Diverse
  }
];
