import React from 'react';
import { SimpleStorageLocationDTO } from 'dto/storageLocation';
import Picture from 'components/Picture';
import './StorageLocationListItem.css';

interface Props extends React.HTMLProps<HTMLDivElement> {
  storageLocation: SimpleStorageLocationDTO;
  isSelected?: boolean;
  whiteBorder?: boolean;
}

const StorageLocationListItem = ({ storageLocation, isSelected = false, whiteBorder = false, ...rest }: Props) => {
  return (
    <div
      {...rest}
      className={`list_item_name storage-location-list-item lagerort image-border-container list-item ${isSelected ? 'selected' : ''} ${
        whiteBorder && !isSelected ? 'white' : ''
      }`}
    >
      <div className="image_wrapper_50">
        <div className="image_border image-border" />
        <Picture src={storageLocation.pictureThumbnail} width={50} alt="" className="image_circle_50" />
      </div>
      <div className="item_name">{storageLocation.name}</div>
      <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-arrow-list" />
    </div>
  );
};

export default StorageLocationListItem;
