import { useGetSingleTemplateV2 } from 'api/templateHooks';
import MaterialValueTags from 'components/MaterialValueTags/MaterialValueTags';
import { TemplatePicture } from 'components/Picture';
import { SimpleTemplateDTO } from 'dto/template';
import React, { useCallback, useMemo, useState } from 'react';
import { LoadingType } from 'stores/loadingStore';
import { PackageDTO } from 'dto/package';
import { InstructionRendererType, ListItemRendererProps } from '../MaterialListItem';
import { TemplateMenuRenderer } from './TemplateMenuRenderer';
import { GenericSinglePackageItem } from './GenericSinglePackageItem';

interface Props<T> {
  level: number;

  parentEntity: T;
  ListItemRenderer: React.FC<ListItemRendererProps<T>>;

  template: SimpleTemplateDTO;
  prefix?: React.ReactElement;
  enablePrefixExpand?: boolean;
  amount?: number;
  onClick?: (parent: T, id: { packageId?: string; materialId?: string }) => void;

  InstructionRenderer?: InstructionRendererType<T>;
  PackagePrefixRenderer?: React.FC<{ parent: T; pack: PackageDTO }>;
  MenuRenderer?: TemplateMenuRenderer<T>;
  classNamePrefix?: string;
}

export function GenericSingleTemplateItem<T extends { currentPackageId?: string }>({
  level = 0,

  parentEntity,
  ListItemRenderer,

  template,
  amount,
  prefix,
  enablePrefixExpand = false,
  onClick,

  PackagePrefixRenderer,
  InstructionRenderer,
  MenuRenderer,
  classNamePrefix
}: Props<T>) {
  const [enabled, setEnabled] = useState(false);

  const handleExpand = useCallback(() => {
    setEnabled(true);
  }, []);

  const { data, isInitialLoading } = useGetSingleTemplateV2(template.templateId, {
    enabled,
    loadingType: LoadingType.ONLY_LOADING
  });

  const templatePackages = useMemo(() => {
    if (!data || !enabled) {
      return [];
    }
    return data.packages.map(pack => {
      return (
        <GenericSinglePackageItem
          key={pack.packageId}
          level={2}
          ListItemRenderer={ListItemRenderer}
          MenuRenderer={MenuRenderer}
          pack={pack}
          parentEntity={{ ...parentEntity, currentPackageId: pack.packageId }}
          prefix={PackagePrefixRenderer && <PackagePrefixRenderer parent={parentEntity} pack={pack} />}
          onClick={onClick}
        />
      );
    });
  }, [ListItemRenderer, MenuRenderer, PackagePrefixRenderer, data, enabled, onClick, parentEntity]);

  return (
    <ListItemRenderer
      classNamePrefix={classNamePrefix}
      parentEntity={parentEntity}
      level={level}
      onDropdownExpand={handleExpand}
      dropdownItems={templatePackages}
      isInitialLoading={isInitialLoading}
      amount={amount}
      name={template.name}
      picture={<TemplatePicture />}
      instructions={InstructionRenderer && <InstructionRenderer parentEntity={parentEntity} />}
      end={<MaterialValueTags template={template} />}
      menu={MenuRenderer && <MenuRenderer parent={parentEntity} specificId={{ templateId: template.templateId }} />}
      prefix={prefix}
      enablePrefixExpand={enablePrefixExpand}
    />
  );
}
