import React from 'react';
import { observer } from 'mobx-react';
import './EmptyIndicator.css';

interface Props {
  children: React.ReactNode;
  isEmpty: boolean;
  message: React.ReactNode;
}

export default observer(({ children, isEmpty, message }: Props) => {
  return isEmpty ? (
    <div className="div-block-152 bottom-margin empty-indicator">
      <div className="div-block-153 empty-indicator-content">
        <img src="images/icon_c-info_16_grey.svg" alt="" className="icon_empty_helper" />
        {message}
      </div>
    </div>
  ) : (
    <>{children}</>
  );
});
