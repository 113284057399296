import { observable, action, runInAction, computed } from 'mobx';
import { deleteDepartmentMaintainer, setDepartmentMaintainer } from 'api/departmentMaintainer';
import { DeleteDepartmentMaintainerDTO, SetDepartmentMaintainerDTO } from 'dto/departmentMaintainer';
import { getAllExtendedUsers, getAllUsers, getExtendedUser, resetPasswordForUser, updateUser, updateUserWithAdmin } from '../api/user';
import { AdminOptionalAccountDTO, ExtendedUserDTO, SecureOptionalAccountDTO } from '../dto/user';
import LoadingStore from './loadingStore';

export default class UserStore {
  @observable
  private loadingStore: LoadingStore;

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;
  }

  @observable
  allUsers?: ExtendedUserDTO[];

  @observable
  selectedUser?: ExtendedUserDTO;

  @observable
  isUserDetailsFlyoutOpen = false;

  @observable
  temporaryPassword = '';

  @computed
  get selectedUserLeaderOf() {
    if (!this.selectedUser) {
      return [];
    }
    return this.selectedUser.departmentMaintainerOf.filter(maintainer => maintainer.isLead === true);
  }

  @computed
  get selectedUserMaintainerOf() {
    if (!this.selectedUser) {
      return [];
    }
    return this.selectedUser.departmentMaintainerOf.filter(maintainer => maintainer.isLead === false);
  }

  @action
  reset() {
    this.allUsers = undefined;
    this.selectedUser = undefined;
    this.isUserDetailsFlyoutOpen = false;
    this.temporaryPassword = '';
  }

  async loadAllUsers(useFakeExtended = false) {
    let users: ExtendedUserDTO[];

    if (useFakeExtended) {
      users = (await this.loadingStore.withLoadingBar(() => getAllUsers())).map(user => {
        return {
          ...user,
          departmentMaintainerOf: [],
          username: ''
        };
      });
    } else {
      users = await this.loadingStore.withLoadingBar(() => getAllExtendedUsers());
    }

    if (users && Array.isArray(users))
      users.sort((a, b) => {
        if (a.lastName < b.lastName) return -1;
        if (a.lastName > b.lastName) return 1;
        return 0;
      });

    if (this.selectedUser) {
      users = users.map(user => {
        if (user.userId === this.selectedUser?.userId) {
          this.setSelectedUser(user);
        }
        return user;
      });
    }

    runInAction(() => {
      this.allUsers = users;
    });
  }

  @action
  setSelectedUser(selectedUser?: ExtendedUserDTO) {
    this.selectedUser = selectedUser;
  }

  async setUserAsDepartmentMaintainer(departmentMaintainerToSet: SetDepartmentMaintainerDTO) {
    await this.loadingStore.withLoadingBar(() => setDepartmentMaintainer(departmentMaintainerToSet));
    await this.loadAllUsers();
  }

  async removeUserAsDepartmentMaintainer(departmentMaintainerToDelete: DeleteDepartmentMaintainerDTO) {
    await this.loadingStore.withLoadingBar(() => deleteDepartmentMaintainer(departmentMaintainerToDelete));
    await this.loadAllUsers();
  }

  @action
  async updateUserWithAdmin(fieldsToUpdate: AdminOptionalAccountDTO) {
    await this.loadingStore.withLoadingBar(() => updateUserWithAdmin(fieldsToUpdate));
    await this.loadAllUsers();
  }

  @action
  async updateUserSecure(fieldsToUpdate: SecureOptionalAccountDTO) {
    await this.loadingStore.withLoadingBar(() => updateUser(fieldsToUpdate));
    await this.setLoggedInUserAsSelected();
  }

  @action
  async resetPassword() {
    const temporaryPassword = await this.loadingStore.withLoadingBar(() => {
      if (!this.selectedUser) {
        return Promise.resolve(undefined);
      }
      return resetPasswordForUser(this.selectedUser.userId);
    });
    if (temporaryPassword) {
      this.temporaryPassword = temporaryPassword;
    }
  }

  @action
  openUserDetailsFlyout(user: ExtendedUserDTO) {
    this.selectedUser = user;
    this.isUserDetailsFlyoutOpen = true;
  }

  @action
  async closeUserDetailsFlyout() {
    this.isUserDetailsFlyoutOpen = false;
    this.selectedUser = undefined;
    await this.loadAllUsers;
  }

  async setLoggedInUserAsSelected() {
    const loggedInUser = await this.loadingStore.withLoadingBar(() => getExtendedUser());
    runInAction(() => {
      this.selectedUser = loggedInUser;
    });
  }
}
