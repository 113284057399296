/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/Form/Button';

interface Props {
  routePath?: string;
  children: JSX.Element[] | JSX.Element;
  fullWidth?: boolean;
  autoWidth?: boolean;
  className?: string;
  replace?: boolean;
  onClick?: MouseEventHandler;
}

type Style = { textDecoration: string; width?: string };

const LinkWrapper = (props: Props) => {
  const customStyle: Style = { textDecoration: 'none' };
  if (props.fullWidth) {
    customStyle.width = '100%';
  }
  if (props.autoWidth) {
    customStyle.width = 'auto';
  }

  return props.routePath === undefined ? (
    <Button onClick={props.onClick} style={customStyle} className={props.className}>
      {props.children}
    </Button>
  ) : (
    <Link to={props.routePath} replace={props.replace} onClick={props.onClick} style={customStyle} className={props.className}>
      {props.children}
    </Link>
  );
};

export default LinkWrapper;
