import React from 'react';
import Picture from 'components/Picture';
import { PackageDTO, PackagingType } from 'dto/package';
import MaterialValueTags from 'components/MaterialValueTags';
import GetCameraImageButton from 'components/GetCameraImageButton';

interface Props {
  packageItem: PackageDTO;
  isShow: boolean;
  onUpdateFile: (fileId: string) => Promise<void>;
}

const FixedTopMaterialBox = ({ packageItem, isShow, onUpdateFile }: Props) => {
  const fixedTopBoxStyle = isShow ? 'show_on_scroll fixed_top_box active' : 'show_on_scroll fixed_top_box';
  return (
    <div className={fixedTopBoxStyle}>
      <div className="div-block-22">
        <div className="image_wrapper_50">
          <div className="image_border" />
          {packageItem.picture ? (
            <Picture src={packageItem.picture.pictureThumbnail} width={50} alt="" className="image_circle_50" />
          ) : (
            <GetCameraImageButton buttonStyle="icon" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
          )}
        </div>
        <div>{packageItem.packagingType === PackagingType.Multi ? packageItem.name : packageItem.name}</div>
      </div>
      <div className="div-block-42">
        <MaterialValueTags pack={packageItem} />
      </div>
    </div>
  );
};

export default FixedTopMaterialBox;
