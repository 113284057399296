import AppLayout from 'components/AppLayout';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import { ChatConfigurationTypes } from 'components/Chat/Chat';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Page from 'components/Page';
import Picture from 'components/Picture';
import Safe from 'components/Safe';
import ValueTag from 'components/ValueTag';
import { observer } from 'mobx-react';
import SelectLocationFlyout from 'pages/flyouts/SelectLocationFlyout';
import SurgeryPlannerDrawer from 'pages/flyouts/SurgeryPlannerDrawer';
import ProcedureFormFlyout from 'pages/guideProcedure/ProcedureFormFlyout';
import SurgeryProcedures from 'pages/surgeryProcedures';
import SurgeryProceduresDrawer from 'pages/surgeryProcedures/SurgeryProceduresDrawer';
import * as qs from 'query-string';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { useStores } from 'util/mobx/stores';
import './SurgeryGuide.css';
import { SurgeryGuideMaterialsV2 } from 'pages/surgery/Material/SurgeryGuideMaterialsV2';
import { UsedMaterialProvider } from 'modules/guideMaterial/SurgeryGuide/UsedMaterial/UsedMaterialContext';
import EditLotNumberFlyout from 'pages/surgeryMaterials/EditLotNumberFlyout';
import SurgeryGuideBarHeader from './SurgeryGuideBarHeader';
import BriefingNavigation from './SurgeryGuideNavigation';

type Props = RouteComponentProps & {
  location: {
    pathname: string;
    search: string;
  };
};

const SurgeryGuide = withRouter(
  observer(({ location }: Props) => {
    const { surgeryGuideStore, flyoutStore, surgeryStore } = useStores();
    const { t } = useTranslation('surgery');
    const surgeryGuideId = qs.parse(location.search).surgeryGuideId as string;

    useEffect(() => {
      surgeryGuideStore.loadGuide(surgeryGuideId);
    }, [surgeryGuideStore, surgeryGuideId]);

    const handleAddImagesToView = () => {
      if (surgeryStore?.selectedSurgeryBriefing?.surgery.patientPicture) {
        flyoutStore.setImagesToView(surgeryStore.selectedSurgeryBriefing.surgery.patientPicture?.picture);
      }
    };
    return (
      <AppLayout
        appBarIcon={
          <div className="title_icon title-icon" onClick={handleAddImagesToView}>
            {surgeryStore.selectedSurgeryBriefing ? (
              <Picture
                src={surgeryStore.selectedSurgeryBriefing.surgery.patientPicture?.pictureThumbnail}
                width={40}
                sizes="40px"
                alt=""
                className="image_circle_40"
              />
            ) : (
              <GetCameraImageButton buttonStyle="userIcon" description="" picturesOnly />
            )}
          </div>
        }
        flyoutInDrawer={
          <>
            <SelectLocationFlyout />
          </>
        }
        appBarRightChildren={
          surgeryGuideStore.surgeryGuide?.surgeryGuideNumber ? (
            <ValueTag
              classNameTitle="margin_left"
              title={`${t('guideItem.caseNumber')}:`}
              tagValue={`${surgeryGuideStore.surgeryGuide.surgeryGuideNumber}`}
            />
          ) : (
            undefined
          )
        }
        fullPageDrawer={<SurgeryProceduresDrawer />}
        drawers={<SurgeryPlannerDrawer />}
        boardFlyout={<BoardFlyout />}
        appBarChildren={<SurgeryGuideBarHeader />}
        flyout={
          <>
            <ProcedureFormFlyout isSurgery />
            <EditLotNumberFlyout />
          </>
        }
      >
        <UsedMaterialProvider surgeryGuideId={surgeryGuideId}>
          <Page
            navigation={<BriefingNavigation />}
            gridClass="grid_main_checkable"
            idPrefix="surgery-guides"
            wrapperContentClass="flex_content surgery-guide-content margin_right"
            chatConfigurationType={ChatConfigurationTypes.briefing}
          >
            <Route
              path="/surgery-guide/materials"
              component={() => <SurgeryGuideMaterialsV2 surgeryGuideId={surgeryGuideId} lazyLoadScrollContainer=".surgery-guide-content" />}
            />
            <Route
              path="/surgery-guide/procedures"
              component={() => <SurgeryProcedures surgeryGuideId={surgeryGuideId} lazyLoadScrollContainer=".surgery-guide-content" />}
            />
          </Page>
        </UsedMaterialProvider>
      </AppLayout>
    );
  })
);

export default () => (
  <Safe>
    <SurgeryGuide />
  </Safe>
);
