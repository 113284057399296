import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import SideMenuIconButton from 'components/SideMenuIconButton';
import { useStores } from 'util/mobx/stores';
import { Can, subjectArea, actions } from 'casl/setupCaslAbility';
import MoreMenuMaterialSearchButton from 'components/MoreMenuMaterialSearchButton';
import { subject } from '@casl/ability';

const GuidesRightNavigation = observer(() => {
  const { t } = useTranslation('guide');

  const { guideStore, domainStore, postStore } = useStores();

  const handleShowCreateGuideClick = () => {
    guideStore.setSelectedGuide(undefined);
    guideStore.setShowGuideFlyout(true);
  };
  const handleSwitchDomainClick = () => {
    domainStore.setShowSwitchDomainFlyout(true);
  };

  const onCommentsFlyoutOpen = async () => {
    await postStore.loadDepartmentPosts(domainStore.currentDepartment.id);
    postStore.setIsChatFlyoutOpen(true);
  };

  return (
    <>
      <Can I={actions.add} this={subject(subjectArea.guide, { departmentId: domainStore.currentDepartment.id })}>
        {!domainStore.isMobile && (
          <SideMenuIconButton handleClick={handleShowCreateGuideClick} label={t('button.createGuide')} iconPath="icon_f-add_16.svg" />
        )}
      </Can>
      <SideMenuIconButton handleClick={onCommentsFlyoutOpen} label={t('button.showComments')} iconPath="icon_icon_f-comment_16_blue.svg" />
      <SideMenuIconButton handleClick={handleSwitchDomainClick} label={t('button.switchDepartment')} iconPath="icon_pin-edit_16.svg" />
      <div className="line_more_menu" />
      <MoreMenuMaterialSearchButton />
    </>
  );
});

export default GuidesRightNavigation;
