import React from 'react';
import { FormikProps, FormikValues } from 'formik';

interface FormikFieldProps {
  name: string;
}

interface Props {
  innerComponent: React.ComponentType<any>;
  form: FormikProps<FormikValues>;
  field: FormikFieldProps;
}

const GroupTextarea = ({ innerComponent: Component, form, field, ...rest }: Props) => <Component meta={form} {...field} {...rest} />;
export default GroupTextarea;
