import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';

import ReportStandardsContent from './ReportStandardsContent';

const ReportStandards = observer(() => {
  const { reportStore, domainStore } = useStores();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function loadData() {
      setLoading(true);
      await reportStore.loadFilledGuideRationsByAreaId(domainStore.currentFunctionalArea.id);
      setLoading(false);
    }
    loadData();
  }, [domainStore.currentFunctionalArea.id, reportStore]);
  if (!reportStore.filledGuideRations || reportStore.filledGuideRations.length === 0 || loading) {
    return null;
  }
  return <ReportStandardsContent key={domainStore.currentFunctionalArea.id} reportData={reportStore.filledGuideRations} />;
});

export default ReportStandards;
