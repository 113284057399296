import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';

import Form, { InputField } from 'components/Form';
import FlyoutContainer from 'components/FlyoutContainer';
import { SettingsItemDTO, SettingsItemTypes } from 'stores/settingsStore';

const SettingsItemFlyoutForm = observer(() => {
  const [isAllowedToSubmit, setIsAllowedToSubmit] = useState(false);
  const { t } = useTranslation('settings');
  const { settingsStore } = useStores();

  const onClose = () => {
    settingsStore.setItemFormFlyoutOpen(false);
    settingsStore.setSelectedItem(null);
  };

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  const bindAllowedToSubmit = (isDirty: boolean, values: SettingsItemDTO) => {
    if (isDirty && values.name && values.shortName && values.shortName.length <= 4) {
      return setIsAllowedToSubmit(true);
    }
    if (
      settingsStore.itemFormFlyoutType !== SettingsItemTypes.location &&
      settingsStore.itemFormFlyoutType !== SettingsItemTypes.functionalArea &&
      isDirty &&
      values.name
    ) {
      return setIsAllowedToSubmit(true);
    }
    return setIsAllowedToSubmit(false);
  };

  const handleSubmitMyForm = () => {
    if (submitMyForm) submitMyForm();
  };

  const initialValues: SettingsItemDTO = {
    name: settingsStore.selectedItem ? settingsStore.selectedItem.name : '',
    shortName: settingsStore.selectedItem ? settingsStore.selectedItem.shortName : ''
  };

  const onCreate = async (data: SettingsItemDTO) => {
    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.location && data.shortName) {
      return settingsStore.createLocation({
        ...data,
        shortName: data.shortName
      });
    }
    if (
      settingsStore.itemFormFlyoutType === SettingsItemTypes.functionalArea &&
      settingsStore.selectedLocation?.locationId &&
      data.shortName
    ) {
      return settingsStore.createFunctionalArea({
        locationId: settingsStore.selectedLocation?.locationId,
        ...data,
        shortName: data.shortName
      });
    }
    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.surgeryRoom && settingsStore.selectedLocation?.locationId) {
      return settingsStore.createSurgeryRoom({
        locationId: settingsStore.selectedLocation?.locationId,
        ...data
      });
    }

    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.materialChapter) {
      return settingsStore.createMaterialChapter(data);
    }
    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.accountManagementTitle) {
      return settingsStore.createAccountManagementTitle(data);
    }
    if (!settingsStore.selectedFunctionalArea?.functionalAreaId) return undefined;
    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.department) {
      return settingsStore.createDepartment({
        ...data,
        functionalAreaId: settingsStore.selectedFunctionalArea?.functionalAreaId
      });
    }
    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.guideChapter) {
      return settingsStore.createGuideChapter({
        ...data,
        functionalAreaId: settingsStore.selectedFunctionalArea?.functionalAreaId
      });
    }
    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.materialCategory) {
      return settingsStore.createMaterialCategory({
        ...data,
        functionalAreaId: settingsStore.selectedFunctionalArea?.functionalAreaId
      });
    }
    return undefined;
  };

  const onUpdate = async (data: SettingsItemDTO) => {
    if (!settingsStore.selectedItem?.itemId) {
      return undefined;
    }
    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.location) {
      return settingsStore.updateLocation({
        locationId: settingsStore.selectedItem?.itemId,
        ...data
      });
    }
    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.surgeryRoom) {
      return settingsStore.updateSurgeryRoom({
        surgeryRoomId: settingsStore.selectedItem?.itemId,
        ...data
      });
    }
    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.functionalArea) {
      return settingsStore.updateFunctionalArea({
        functionalAreaId: settingsStore.selectedItem?.itemId,
        ...data
      });
    }
    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.department) {
      return settingsStore.updateDepartment({
        departmentId: settingsStore.selectedItem?.itemId,
        ...data
      });
    }
    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.guideChapter) {
      return settingsStore.updateGuideChapter({
        guideChapterId: settingsStore.selectedItem?.itemId,
        ...data
      });
    }
    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.materialCategory) {
      return settingsStore.updateMaterialCategory({
        guideMaterialCategoryId: settingsStore.selectedItem?.itemId,
        ...data
      });
    }
    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.materialChapter) {
      return settingsStore.updateMaterialChapter({
        materialChapterId: settingsStore.selectedItem?.itemId,
        ...data
      });
    }

    if (settingsStore.itemFormFlyoutType === SettingsItemTypes.accountManagementTitle) {
      return settingsStore.updateAccountManagementTitle({
        titleId: settingsStore.selectedItem?.itemId,
        ...data
      });
    }
    return undefined;
  };

  const onSubmit = async (data: SettingsItemDTO) => {
    if (settingsStore.selectedItem) {
      await onUpdate(data);
    } else {
      await onCreate(data);
    }
    onClose();
  };

  const isShortNameVisible = () => {
    return (
      settingsStore.itemFormFlyoutType === SettingsItemTypes.location ||
      settingsStore.itemFormFlyoutType === SettingsItemTypes.functionalArea
    );
  };
  return (
    <FlyoutContainer
      icon={<img src={`images/${settingsStore.selectedItem ? 'icon_edit' : 'icon_new_1'}.jpg`} alt="" className="image_circle_40" />}
      isOpen={settingsStore.itemFormFlyoutOpen}
      closePopUp={onClose}
      cancelLabel={t('formFlyout.cancel')}
      onSubmit={handleSubmitMyForm}
      submitLabel={settingsStore.selectedItem ? t('formFlyout.submitUpdate') : t('formFlyout.submitCreate')}
      isAllowedToSubmit={isAllowedToSubmit}
      title={settingsStore.selectedItem ? settingsStore.itemFormFlyoutUpdateTitle : settingsStore.itemFormFlyoutCreateTitle}
    >
      <Form initialValues={initialValues} bindSubmitForm={bindSubmitForm} bindAllowedToSubmit={bindAllowedToSubmit} onSubmit={onSubmit}>
        {() => (
          <>
            <div className="flyout_box">
              <div>{t('formFlyout.name')}</div>
              <InputField name="name" placeholder={t('formFlyout.namePlaceholder')} />
            </div>
            {isShortNameVisible() && (
              <div className="flyout_box">
                <div>{t('formFlyout.shortName')}</div>
                <InputField name="shortName" placeholder={t('formFlyout.shortNamePlaceholder')} />
              </div>
            )}
          </>
        )}
      </Form>
    </FlyoutContainer>
  );
});

export default SettingsItemFlyoutForm;
