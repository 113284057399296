import React from 'react';
import Picture from 'components/Picture';
import './ItemMenuButton.css';
import { Link } from 'react-router-dom';

interface Props {
  label: string;
  secondaryLabel?: string;
  strongLabel?: string;
  icon?: string;
  handleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  warning?: boolean;
  isInPopover?: boolean;
  href?: string;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  propagate?: boolean;
}

const ItemMenuButton = ({
  children,
  label,
  secondaryLabel,
  strongLabel,
  icon,
  handleClick,
  warning,
  isInPopover,
  href,
  className = '',
  disabled = false,
  propagate = false
}: Props) => {
  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!propagate) {
      event.stopPropagation();
    }
    if (handleClick) handleClick(event);
  };
  if (href) {
    return (
      <Link to={href} className={`btn_list_edit ${isInPopover ? 'button_function' : ''}`}>
        <Picture src={`images/${icon}`} alt="" className="image_function img_function">
          <div>
            {label}
            {children}
          </div>
        </Picture>
      </Link>
    );
  }
  return (
    <div
      onClick={onClick}
      className={`btn_list_edit btn-list-edit ${isInPopover && !warning ? 'button_function' : ''} ${warning && 'red-color'} ${className} ${
        disabled ? 'disabled' : ''
      }`}
    >
      {icon && <Picture src={`images/${icon}`} alt="" className="image_function img_function" />}
      <div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {label}
          <div className="text-span-5">{secondaryLabel}</div>
        </div>
        {strongLabel && (
          <>
            <br />
            <strong>{strongLabel}</strong>
          </>
        )}
      </div>
    </div>
  );
};

export default ItemMenuButton;
