/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import 'reflect-metadata';
import { IsUUID, IsOptional, IsString, ValidateNested, IsEnum, IsNumber, IsBoolean } from 'class-validator';
import { Type } from 'class-transformer';
import { StorageItemDTO } from './storageItem';
import { MaterialLikeIdOrPackageIdDTO } from './material';
import { OptionalPictureDTO } from './file';

export enum LocationSource {
  KPS,
  ERP
}

/**
 * SimpleStorageLocationDTO is the basis for all StorageLocationDTO`s
 */
export class SimpleStorageLocationDTO extends OptionalPictureDTO {
  @IsUUID()
  storageLocationId!: string;

  @IsUUID()
  @IsOptional()
  parentStorageLocationId?: string;

  @IsString()
  name!: string;

  @IsUUID()
  @IsOptional()
  functionalAreaId?: string;

  @IsEnum(LocationSource)
  locationSource!: LocationSource;
}

/**
 * MaterialStorageLocationDTO is a storage location of a specific material -> it contains the amount
 */
export class MaterialStorageLocationDTO extends SimpleStorageLocationDTO {
  @IsNumber()
  amount!: number;
}

/**
 * StorageLocationDTO includes also the storage items
 */
export class StorageLocationDTO extends SimpleStorageLocationDTO {
  @ValidateNested({ each: true })
  @Type(() => StorageItemDTO)
  storageItems!: StorageItemDTO[];
}

/**
 * ParentStorageLocationDTO provides child storageLocation of a parent
 */
export class ParentStorageLocationDTO {
  @IsOptional()
  @ValidateNested()
  @Type(() => StorageLocationDTO)
  storageLocation?: StorageLocationDTO;

  @IsString({ each: true })
  childStorageLocationIds!: string[];
}

export class CreateStorageLocationDTO {
  @IsUUID()
  @IsOptional()
  parentStorageLocationId?: string;

  @IsUUID()
  @IsOptional()
  functionalAreaId?: string;

  @IsString()
  name!: string;

  @IsUUID()
  @IsOptional()
  pictureFileId?: string;
}

export class UpdateStorageLocationDTO {
  @IsUUID()
  storageLocationId!: string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsUUID()
  @IsOptional()
  pictureFileId?: string;
}

export class GetStorageLocationDTO {
  @IsUUID()
  @IsOptional()
  storageLocationId?: string;

  @IsUUID()
  @IsOptional()
  functionalAreaId?: string;

  @IsEnum(LocationSource)
  locationSource!: LocationSource;
}

/**
 * StorageLocationPathDTO contains a list of several storage locations which together define a path to the last one.
 */
export class StorageLocationPathDTO {
  @ValidateNested({ each: true })
  @Type(() => SimpleStorageLocationDTO)
  storageLocations!: SimpleStorageLocationDTO[];
}

export class GetMaterialStorageLocationsDTO extends MaterialLikeIdOrPackageIdDTO {
  @IsEnum(LocationSource)
  @IsOptional()
  locationSource?: LocationSource;

  @IsUUID()
  @IsOptional()
  functionalAreaId?: string;
}

export class GetMaterialStorageLocationUsageDTO extends GetMaterialStorageLocationsDTO {
  @IsUUID()
  storageLocationId!: string;
}

export class UpdateSortStorageLocationDTO {
  @IsUUID(undefined, { each: true })
  storageLocationIds!: string[];
}

/**
 * MaterialStorageLocationsDTO contains only the start and all end points where a material exists
 */
export class MaterialStorageLocationsDTO {
  @ValidateNested({ each: true })
  @Type(() => SimpleStorageLocationDTO)
  originStorageLocation!: SimpleStorageLocationDTO;

  @ValidateNested({ each: true })
  @Type(() => MaterialStorageLocationDTO)
  endStorageLocations!: MaterialStorageLocationDTO[];
}

export class DeleteWholeStorageLocationResultDTO {
  @IsBoolean()
  success!: boolean;

  @IsUUID(undefined, { each: true })
  deletedIds!: string[];
}
