import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';

import './Shake.css';

interface Props {
  children: JSX.Element | JSX.Element[];
  active: boolean;
  className?: string;
}

const Shake = ({ children, active, className = '' }: Props) => {
  const { animationStore } = useStores();
  useEffect(() => {
    if (active) {
      setTimeout(() => {
        animationStore.resetElementIdToShake();
      }, 2000);
    }
  }, [active, animationStore]);
  return <div className={`${className} ${active ? 'shake' : ''}`}>{children}</div>;
};

export default Shake;
