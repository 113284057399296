import React from 'react';
import Button from 'components/Form/Button';

interface Props {
  title: string;
  checked: boolean;
  onClick: () => void;
  color?: 'blue' | 'white';
}

const themes = {
  blue: {
    checked: 'images/icon_checkbox_check_16.svg',
    unChecked: 'images/icon_checkbox_empty_16.svg'
  },
  white: {
    checked: 'images/icon_checkbox_check_16_white.svg',
    unChecked: 'images/icon_checkbox_empty_16_white.svg'
  }
};

export default ({ title, checked, onClick, color = 'blue' }: Props) => {
  const theme = themes[color];
  return (
    <Button className="button_selector" onClick={onClick}>
      <div className="btn_checkbox_header">
        {checked ? (
          <img src={theme.checked} alt="" className="image_checkbox_check" />
        ) : (
          <img src={theme.unChecked} alt="" className="image_checkbox_empty" />
        )}
      </div>
      <div>{title}</div>
    </Button>
  );
};
