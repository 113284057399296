import { Check } from 'components/Check/Check';
import React, { useCallback } from 'react';
import { CheckStatus } from 'dto/materialSet';
import { useSetCheckContext } from './SetCheckContext';

interface Props {
  groupMaterialId?: string;
  setGroupId?: string;
}

export const SetCheck: React.FC<Props> = ({ groupMaterialId, setGroupId }) => {
  const { checks, updateCheck } = useSetCheckContext();

  let status = CheckStatus.Missing;

  if (groupMaterialId) {
    status = checks?.groupMaterialChecks.get(groupMaterialId)?.status || CheckStatus.Missing;
  } else if (setGroupId) {
    status = checks?.setGroupChecks.get(setGroupId)?.status || CheckStatus.Missing;
  }

  const handleUpdateCheck = useCallback(() => {
    updateCheck({ groupMaterialId, setGroupId }, status === CheckStatus.Ok ? CheckStatus.Missing : CheckStatus.Ok);
  }, [groupMaterialId, setGroupId, status, updateCheck]);

  return checks ? <Check checked={status === CheckStatus.Ok} onClick={handleUpdateCheck} /> : null;
};
