import { AnyMaterial } from 'stores/searchStore';
import { CreateStorageItemDTO } from 'dto/storageItem';
import StorageLocationStore from 'stores/storageLocationStore';
import { StorageLocationDTO } from 'dto/storageLocation';

export const createStorageItem = (
  storageLocationStore: StorageLocationStore,
  selectedStorageLocation: StorageLocationDTO,
  materialLikeIdOrPackageId?: AnyMaterial
) => {
  if (!materialLikeIdOrPackageId) {
    return;
  }
  const storageItem: CreateStorageItemDTO = {
    storageLocationId: selectedStorageLocation.storageLocationId,
    amount: 1,
    packageId: materialLikeIdOrPackageId.pack?.packageId,
    materialId: materialLikeIdOrPackageId.material?.materialId,
    materialSetId: materialLikeIdOrPackageId.materialSet?.materialSetId
  };
  storageLocationStore.createStorageItem(storageItem);
};
