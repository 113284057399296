import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import PopoverV2 from 'components/PopoverV2/PopoverV2';
import { UsedMaterialStatus } from 'dto/usedMaterial';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './GuideCheckMenu.css';
import SurgeryMaterialCheckStatus from 'pages/surgeryMaterials/SurgeryMaterialLikeListItem/SurgeryMaterialCheckDropdown/SurgeryMaterialCheckStatus/SurgeryMaterialCheckStatus';

interface Props {
  status?: UsedMaterialStatus;
  onSetStatus: (status: UsedMaterialStatus) => void;
  groupMaterialId?: string;
  onAddLotNumber: () => void;
}

export const GuideCheckMenu = ({ status, onSetStatus, groupMaterialId, onAddLotNumber }: Props) => {
  const { t } = useTranslation('surgery');

  const onStatusSelect = (handleClose: () => void, selectedStatus: UsedMaterialStatus) => {
    onSetStatus(selectedStatus);
    handleClose();
  };

  return (
    <PopoverV2
      customTriggerClasses={!status || status === UsedMaterialStatus.Prepared ? 'guide-check-menu' : ''}
      customTrigger={
        status && status !== UsedMaterialStatus.Prepared ? (
          <SurgeryMaterialCheckStatus status={status} customClass="guide-check-status-trigger" />
        ) : (
          <img src="images/icon_right-arrow_12.svg" alt="" className="image_arrow_checkbox" />
        )
      }
    >
      {({ handleClose, isOpen }) => (
        <ItemMenu className="menu-popover" isOpen={!!isOpen} handleClose={handleClose}>
          <>
            {groupMaterialId && status !== UsedMaterialStatus.None && (
              <ItemMenuButton
                isInPopover
                handleClick={() => onStatusSelect(handleClose, UsedMaterialStatus.None)}
                label={t('guideCheck.menu.reset')}
                icon="icon_back-arrow_16_blue.svg"
              />
            )}

            {!groupMaterialId && (
              <>
                {status !== UsedMaterialStatus.None && (
                  <ItemMenuButton
                    isInPopover
                    handleClick={() => onStatusSelect(handleClose, UsedMaterialStatus.None)}
                    label={t('guideCheck.menu.notPrepared')}
                    icon="icon_check-e_16_grey.svg"
                  />
                )}

                {status !== UsedMaterialStatus.Prepared && (
                  <ItemMenuButton
                    isInPopover
                    handleClick={() => onStatusSelect(handleClose, UsedMaterialStatus.Prepared)}
                    label={t('guideCheck.menu.prepared.main')}
                    secondaryLabel={t('guideCheck.menu.prepared.secondary')}
                    icon="icon_check-e_16_green.svg"
                    className="green"
                  />
                )}

                {status !== UsedMaterialStatus.NotNeeded && (
                  <ItemMenuButton
                    isInPopover
                    handleClick={() => onStatusSelect(handleClose, UsedMaterialStatus.NotNeeded)}
                    label={t('guideCheck.menu.notRequired')}
                    icon="icon_e-remove_16.svg"
                  />
                )}

                {status !== UsedMaterialStatus.onInterventionDay && (
                  <ItemMenuButton
                    isInPopover
                    handleClick={() => onStatusSelect(handleClose, UsedMaterialStatus.onInterventionDay)}
                    label={t('guideCheck.menu.onInterventionDay')}
                    icon="icon_calendar-date-2_16-yellow.svg"
                    className="yellow"
                  />
                )}
              </>
            )}

            <div className="txt_info_text top_margin border-top">{t('guideCheck.menu.secondaryTitle')}</div>

            {status !== UsedMaterialStatus.Used && (
              <ItemMenuButton
                isInPopover
                handleClick={() => onStatusSelect(handleClose, UsedMaterialStatus.Used)}
                label={t('guideCheck.menu.used')}
                icon="icon_single-01_16_green.svg"
                className="green"
              />
            )}

            {status !== UsedMaterialStatus.Implanted && (
              <ItemMenuButton
                isInPopover
                handleClick={() => onStatusSelect(handleClose, UsedMaterialStatus.Implanted)}
                label={t('guideCheck.menu.implanted')}
                icon="icon_sticker_16_green.svg"
                className="green"
              />
            )}

            {/* <ItemMenuButton
            isInPopover
            handleClick={() => onStatusSelect(handleClose, UsedMaterialStatus.Planned)}
            label={t('guideCheck.menu.planned')}
            icon="icon_calendar-event_16_yellow.svg"
          /> */}

            {status !== UsedMaterialStatus.UsedNotImplanted && (
              <ItemMenuButton
                isInPopover
                handleClick={() => onStatusSelect(handleClose, UsedMaterialStatus.UsedNotImplanted)}
                label={t('guideCheck.menu.usedNotImplanted')}
                icon="icon_sticker_16_yellow.svg"
                className="yellow"
              />
            )}

            <ItemMenuButton
              isInPopover
              handleClick={() => {
                onAddLotNumber();
                handleClose();
              }}
              label={t('guideCheck.menu.lotNumber')}
              icon="icon_i-edit_16.svg"
            />
          </>
        </ItemMenu>
      )}
    </PopoverV2>
  );
};
