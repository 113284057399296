import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import Button from 'components/Form/Button';
import { SimplePackageDTO } from 'dto/package';
import { BasicMaterialSetDTO } from 'dto/materialSet';
import { MaterialDTO } from 'dto/material';
import { getPackage } from 'api/package';
import Picture from 'components/Picture';
import { SelectableType } from 'stores/searchStore';

interface Props {
  package?: SimplePackageDTO;
  materialSet?: BasicMaterialSetDTO;
  materialErp?: MaterialDTO;
  materialManual?: MaterialDTO;
}

const MaterialContainerListItem = observer(({ package: pack, materialSet, materialErp, materialManual }: Props) => {
  const { searchStore, guideDetailDrawerStore } = useStores();

  const handleSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (searchStore.onSelect) {
      if (pack) {
        searchStore.onSelect({ pack });
      }
      if (materialSet) {
        searchStore.onSelect({ materialSet });
      }
      if (materialErp) {
        searchStore.onSelect({ material: materialErp });
      }
      if (materialManual) {
        searchStore.onSelect({ material: materialManual });
      }
    }
  };

  const handleShowDetailDrawerClick = async () => {
    if (pack) {
      const fullPackage = await getPackage(pack.packageId);
      guideDetailDrawerStore.setSelectedPackage(fullPackage.pack);
    }
    if (materialSet) {
      guideDetailDrawerStore.setSelectedMaterialSet(materialSet);
    }
    if (materialErp) {
      guideDetailDrawerStore.setSelectedMaterial(materialErp);
    }
    if (materialManual) {
      guideDetailDrawerStore.setSelectedMaterial(materialManual);
    }
    guideDetailDrawerStore.setIsOpen(true);
  };

  const getName = () => {
    if (pack) {
      return pack.name;
    }
    if (materialSet) {
      return materialSet?.name;
    }
    if (materialErp) {
      return materialErp.name;
    }
    if (materialManual) {
      return materialManual.name;
    }
    return '';
  };

  let showButton = false;

  if (searchStore.onSelect) {
    if (materialManual || materialErp) {
      showButton = searchStore.selectableTypes.indexOf(SelectableType.Material) >= 0;
    } else if (pack) {
      showButton = searchStore.selectableTypes.indexOf(SelectableType.Package) >= 0;
    } else if (materialSet) {
      showButton = searchStore.selectableTypes.indexOf(SelectableType.Set) >= 0;
    }
  }

  return (
    <>
      <div className="list_item_material" onClick={handleShowDetailDrawerClick}>
        <div className="image_wrapper_50">
          <div className="image_border image-border" />
          {materialErp && <Picture src={materialErp.pictureThumbnail} width="50" alt="" className="image_circle_50" />}
          {materialManual && <Picture src={materialManual.pictureThumbnail} width="50" alt="" className="image_circle_50" />}
          {pack && <Picture src={pack.picture?.pictureThumbnail} width="50" alt="" className="image_circle_50" />}
          {materialSet && <Picture src={materialSet.pictureThumbnail} width="50" alt="" className="image_circle_50" />}
        </div>
        <div className="material_info">
          <div className="material_text">
            <div>{getName()}</div>
          </div>
          {showButton && (
            <div onClick={handleSelect}>
              <Button className="btn_small_flyout">
                <div className="txt_dropout_1">{searchStore.actionButtonLabel}</div>
              </Button>
            </div>
          )}
          <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-arrow-list" />
        </div>
      </div>
    </>
  );
});

export default MaterialContainerListItem;
