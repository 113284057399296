import React from 'react';
import { useTranslation } from 'react-i18next';
import { SurgeryTabs } from 'stores/surgeryStore';
import SurgeryHistoryFilters, { SurgeryHistoryFilterTypes } from '../SurgeryHistoryFilters';

interface Props {
  selectedTab: SurgeryTabs;
  onTabSelect: (selectedTab: SurgeryTabs) => void;
  selectedFilter?: SurgeryHistoryFilterTypes;
  onFilterSelect: (selectedFilter: SurgeryHistoryFilterTypes) => void;
}

const Tabs = ({ selectedTab, onTabSelect, selectedFilter, onFilterSelect }: Props) => {
  const { t } = useTranslation('surgery');
  const onSelect = (tab: SurgeryTabs) => onTabSelect(tab);
  return (
    <div className="chat_op_header">
      <div className="tab_bar">
        <div className={`${selectedTab === SurgeryTabs.chat ? 'btn_tab_active' : 'btn_tab'}`} onClick={() => onSelect(SurgeryTabs.chat)}>
          {t('chatAndHistory.tabs.chat')}
        </div>
        <div
          className={`${selectedTab === SurgeryTabs.history ? 'btn_tab_active' : 'btn_tab'}`}
          onClick={() => onSelect(SurgeryTabs.history)}
        >
          {t('chatAndHistory.tabs.history')}
        </div>
      </div>
      {selectedTab === SurgeryTabs.history && <SurgeryHistoryFilters selectedFilter={selectedFilter} onFilterSelect={onFilterSelect} />}
    </div>
  );
};

export default Tabs;
