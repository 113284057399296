import React from 'react';
import Document from 'components/Document';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import { useTranslation } from 'react-i18next';
import Button from 'components/Form/Button';

interface Props {
  file: ContentElementMediaDTO;
  onDeleteFile: (fileId: string) => void;
  showDownloadButton?: boolean;
}

export default ({ file, onDeleteFile, showDownloadButton = true }: Props) => {
  const { t } = useTranslation('itemFormFlyOut');
  return (
    <div className="data_file in_flyout">
      <Document file={file} type={file.contentType} title={file.title} showDownloadButton={showDownloadButton} />
      <Button className="btn_small_flyout_delete" onClick={() => onDeleteFile(file.fileId)}>
        <div className="txt_dropout_1">{t('uploadFiles.deleteDocument')}</div>
      </Button>
    </div>
  );
};
