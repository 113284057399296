import { CreateGroupMaterialDTO, GroupMaterialDTO, GroupMaterialsDTO, UpdateSortGroupMaterialsV2DTO } from 'dto/groupMaterial';
import { del, get, post, put } from './common';
import { validate, validateBool } from './validation';

export const getGroupMaterials = async (setGroupId: string): Promise<GroupMaterialsDTO> => {
  const groups = await get('/material-set/set-group/group-materials', { setGroupId }).then(response => response.data);
  return validate(GroupMaterialsDTO, groups);
};

export const createGroupMaterial = async (groupMaterial: CreateGroupMaterialDTO): Promise<GroupMaterialDTO> => {
  const newSetGroup = await post('/material-set/set-group/group-material', groupMaterial).then(response => response.data);
  return newSetGroup;
};

export const deleteGroupMaterial = async (groupMaterialId: string) => {
  return del('/material-set/set-group/group-material', { groupMaterialId }).then(response => validateBool(response.data));
};

export const reorderGroupMaterials = async (reordered: UpdateSortGroupMaterialsV2DTO) => {
  return put('/v2/material-set/set-group/group-materials/positions', reordered).then(response => validateBool(response.data));
};
