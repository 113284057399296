import {
  CreateGuideMaterialCategoryDTO,
  UpdateGuideMaterialCategoryDTO,
  UpdateSortGuideMaterialCategoriesDTO,
  GetGuideMaterialCategoryDTO,
  GroupedGuideMaterialCategoryDTO,
  GuideMaterialCategoryDTO
} from 'dto/guideMaterialCategory';
import { get, post, patch, put, del } from './common';
import { validateArray, validate, validateBool } from './validation';

export const getGuideMaterialCategories = async (getGuideMaterialCategoriesOptions: GetGuideMaterialCategoryDTO) => {
  const guideMaterialCategories = await get('/guide-material-categories', getGuideMaterialCategoriesOptions).then(
    response => response.data
  );
  return validateArray(GroupedGuideMaterialCategoryDTO, guideMaterialCategories);
};

export const createGuideMaterialCategory = async (getGuideMaterialCategoriesOptions: CreateGuideMaterialCategoryDTO) => {
  const guideMaterialCategory = await post('/guide-material-category', getGuideMaterialCategoriesOptions).then(response => response.data);
  return validate(GuideMaterialCategoryDTO, guideMaterialCategory);
};

export const updateGuideMaterialCategory = async (getGuideMaterialCategoriesOptions: UpdateGuideMaterialCategoryDTO) => {
  const guideMaterialCategory = await patch('/guide-material-category', getGuideMaterialCategoriesOptions).then(response => response.data);
  return validate(GuideMaterialCategoryDTO, guideMaterialCategory);
};

export const reorderGuideMaterialCategories = async (reorderedGuideMaterialCategories: UpdateSortGuideMaterialCategoriesDTO) => {
  return put('/guide-material-category/positions', reorderedGuideMaterialCategories).then(response => validateBool(response.data));
};

export const deleteGuideMaterialCategory = async (guideMaterialCategoryId: string) => {
  return del('/guides/material', { guideMaterialCategoryId }).then(response => validateBool(response.data));
};
