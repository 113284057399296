import React from 'react';

export const TemplatePicture: React.FC = () => {
  return (
    <>
      <div className="image_border white image-border" />
      <img src="images/icon_layer.jpg" alt="" className="image_circle_50" />
    </>
  );
};
