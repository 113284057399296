import React from 'react';
import ImageCircle from 'components/ImageCircle';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';

const LoggedInUser = observer(() => {
  const { t } = useTranslation('navigation');
  const { authStore } = useStores();

  if (!authStore.user) {
    return <> </>;
  }

  return (
    <div className="div-block-155">
      <div className="t2-copy-copy">{t('user.headline')}</div>
      <div className="user-info">
        <ImageCircle user={authStore.user} highlight={false} size="40" />
        <div>
          <div>
            {authStore.user.lastName}, {authStore.user.firstName}
          </div>
        </div>
      </div>
    </div>
  );
});

export default LoggedInUser;
