import {
  SurgeryRoomFunctionalAreaDTO,
  CreateSurgeryRoomDTO,
  UpdateSurgeryRoomDTO,
  SetSurgeryRoomFunctionalAreaDTO,
  GetSurgeryRoomsDTO,
  SurgeryRoomDTO,
  UpdateSortSurgeryRoomDTO
} from 'dto/surgeryRoom';
import { get, post, patch, put } from './common';
import { validateArray, validate, validateBool } from './validation';

export const getSurgeryRooms = async (params: GetSurgeryRoomsDTO): Promise<SurgeryRoomDTO[]> => {
  const surgeryRooms = await get('/surgery/rooms', params).then(response => response.data);
  return validateArray(SurgeryRoomDTO, surgeryRooms);
};

export const getSurgeryRoomFunctionalAreas = async (surgeryRoomId: string): Promise<SurgeryRoomFunctionalAreaDTO[]> => {
  const surgeryRoomFunctionalAreas = await get('/surgery/room/functional-areas', { surgeryRoomId }).then(response => response.data);
  return validateArray(SurgeryRoomFunctionalAreaDTO, surgeryRoomFunctionalAreas);
};

export const createSurgeryRoom = async (surgeryRoomToCreate: CreateSurgeryRoomDTO) => {
  const createdSurgeryRoom = await post('/surgery/room', surgeryRoomToCreate).then(response => response.data);
  return validate(SurgeryRoomDTO, createdSurgeryRoom);
};

export const updateSurgeryRoom = async (surgeryUpdateFields: UpdateSurgeryRoomDTO): Promise<SurgeryRoomDTO> => {
  const updatedSurgeryRoom = await patch(`/surgery/room`, surgeryUpdateFields).then(response => response.data);
  return validate(SurgeryRoomDTO, updatedSurgeryRoom);
};

export const setSurgeryRoomFunctionalArea = async (params: SetSurgeryRoomFunctionalAreaDTO) => {
  return put('/surgery/room/functional-area', params).then(response => validateBool(response.data));
};

export const reorderSurgeryRooms = async (reorderedSurgeryRooms: UpdateSortSurgeryRoomDTO) => {
  return put('/surgery/rooms/positions', reorderedSurgeryRooms).then(response => validateBool(response.data));
};
