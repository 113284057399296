import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import CollapsibleBox from 'components/CollapsibleBox';
import { GuideListItem } from 'components/ListItems';
import { FullPackageDTO } from 'dto/package';
import { FullGuideDTO } from 'dto/guide';
import { useTranslation } from 'react-i18next';
import PackageGlobalFunctionsItemActions from './PackageGlobalFunctionsItemActions';
import PackageFunctionsRightMenu from './PackageFunctionsRightMenu';

interface Props {
  packageItem?: FullPackageDTO;
}

const PackageGlobalFunctions = observer(({ packageItem }: Props) => {
  const { instrumentStore, appNavigationStore } = useStores();

  const { t } = useTranslation('packages');

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <PackageFunctionsRightMenu />;
    });
  }, [appNavigationStore]);

  useEffect(() => {
    if (packageItem?.template) {
      instrumentStore.loadTemplateGuides(packageItem.template.templateId);
    }
  }, [instrumentStore, packageItem]);

  appNavigationStore.useSubPageIdSetter('functions');

  return (
    <div className="single_colum_content with_list _5px-padding">
      <div className="content_filter">
        <div className="btn_filter_content active">
          <div className="txt_dropout_1">{t('globalFunctions.button.containingGuidesFilter')}</div>
        </div>
      </div>
      {instrumentStore.templateGuides.map(groupedGuide => (
        <div className="post_item_head_topic post-item-head" key={groupedGuide.department.departmentId}>
          <CollapsibleBox
            head={
              <div>
                {groupedGuide.department.locationShortName} | {groupedGuide.department.functionalAreaShortName} |{' '}
                {groupedGuide.department.name}
              </div>
            }
          >
            {groupedGuide.guides.map((guide: FullGuideDTO) => (
              <GuideListItem key={guide.guideId} guide={guide} actions={<PackageGlobalFunctionsItemActions guide={guide} />} />
            ))}
          </CollapsibleBox>
        </div>
      ))}
    </div>
  );
});

export default PackageGlobalFunctions;
