import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { SurgeryDTO } from 'dto/surgery';
import CollapsibleBox from 'components/CollapsibleBox';
import { useStores } from 'util/mobx/stores';
import SurgeryListItems from '../SurgeryListItems';

const EXPECTED_ROOM_KEY = 'expanded-room';

const SurgeryPlannerContent = observer(() => {
  const { surgeryStore } = useStores();

  const [expandedRoom, setExpandedRoom] = useState<string>(localStorage.getItem(EXPECTED_ROOM_KEY) || '');

  useEffect(() => {
    localStorage.setItem(EXPECTED_ROOM_KEY, expandedRoom);
  }, [expandedRoom]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    let surgeryToBeMoved: SurgeryDTO;
    let newSurgeries = surgeryStore.surgeries.map(roomSurgery => {
      if (result.source?.droppableId === roomSurgery.room.surgeryRoomId) {
        const draggableItem = roomSurgery.surgeries.find(rs => rs.surgeryId === result.draggableId);
        surgeryToBeMoved = draggableItem || surgeryToBeMoved;
        return {
          ...roomSurgery,
          surgeries: roomSurgery.surgeries.filter(rs => rs.surgeryId !== result.draggableId)
        };
      }
      return roomSurgery;
    });
    newSurgeries = newSurgeries.map(roomSurgery => {
      if (result.destination?.droppableId === roomSurgery.room.surgeryRoomId) {
        roomSurgery.surgeries.splice(result.destination.index, 0, surgeryToBeMoved);
        return {
          ...roomSurgery,
          surgeries: roomSurgery.surgeries
        };
      }
      return roomSurgery;
    });
    surgeryStore.reorderSurgeries(newSurgeries);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {surgeryStore.surgeries.map(surgeryRoom => (
        <Droppable droppableId={surgeryRoom.room.surgeryRoomId} key={surgeryRoom.room.surgeryRoomId}>
          {provided => (
            <div ref={provided.innerRef} className="single-colum-content-item post-item-head">
              <CollapsibleBox
                isExpandedByDefault={surgeryRoom.room.surgeryRoomId === expandedRoom}
                onChangeVisibility={isVisible => {
                  if (isVisible) {
                    setExpandedRoom(surgeryRoom.room.surgeryRoomId);
                  } else {
                    setExpandedRoom('');
                  }
                }}
                headClassName="dropdown_content_head_open test "
                head={
                  <>
                    <div>{surgeryRoom.room.name}</div>
                    <div className="info_box counter">{surgeryRoom.surgeries.length}</div>
                  </>
                }
              >
                <div className="div-block-114">
                  <div className="line_operation op_planer" />
                  <SurgeryListItems roomSurgeries={surgeryRoom} />
                </div>
              </CollapsibleBox>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </DragDropContext>
  );
});

export default SurgeryPlannerContent;
