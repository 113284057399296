/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import HoverWrapper from 'components/HoverWrapper';
import AdditionalBookListShowButtonBody from './AdditionalBookListShowButtonBody';

export default observer(() => {
  const { guideStore } = useStores();
  const handleClick = () => {
    guideStore.toggleShowAdditionalGuides();
  };

  return (
    <div onClick={handleClick}>
      <HoverWrapper className="btn_dropout">
        {({ hover }) => <AdditionalBookListShowButtonBody showAdditionalBooks={guideStore.showAdditionalGuides} hover={hover} />}
      </HoverWrapper>
    </div>
  );
});
