import React from 'react';
import { OptionTypeBase, OptionsType } from 'react-select';
import { Field } from 'formik';
import Textarea from '../Textarea';
import GroupTextarea from './GroupTextarea';

interface Props {
  component?: React.ComponentType<any>;
  name: string;
  placeholder?: string;
  className?: string;
  viewOnly?: boolean;
  mappedOptions?: OptionsType<OptionTypeBase>;
}

const TextareaField = ({ component = Textarea, ...rest }: Props) => (
  <Field {...rest} component={GroupTextarea} innerComponent={component} maxLength={500} />
);
export default TextareaField;
