import { HistoryDTO, BriefingHistoryDTO } from 'dto/history';
import { get } from './common';
import { validateArray, validate } from './validation';

export const getEntireHistory = async (): Promise<HistoryDTO[]> => {
  const history = await get('/history').then(response => response.data);
  return validateArray(HistoryDTO, history);
};

export const getGuideMaterialHistory = async (guideId: string): Promise<HistoryDTO[]> => {
  const history = await get('/guide/history', { guideId }).then(response => response.data);
  return validateArray(HistoryDTO, history);
};

export const getContentElementHistory = async (contentElementId: string): Promise<HistoryDTO[]> => {
  const history = await get('/content-element/history', { contentElementId }).then(response => response.data);
  return validateArray(HistoryDTO, history);
};

export const getBriefingHistory = async (briefingId: string): Promise<BriefingHistoryDTO> => {
  const history = await get('/surgery/briefing/history', { briefingId }).then(response => response.data);
  return validate(BriefingHistoryDTO, history);
};
