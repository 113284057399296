import React from 'react';
import { BasicMaterialSetDTO } from 'dto/materialSet';
import Picture from 'components/Picture';
import MaterialValueTags from 'components/MaterialValueTags';
import GetCameraImageButton from 'components/GetCameraImageButton';

interface Props {
  materialSet: BasicMaterialSetDTO;
  onUpdateFile: (fileId: string) => Promise<void>;
}

const defaultData = {
  pictureThumbnail: 'images/icon_book_makephoto.jpg',
  picture: 'images/image_no_photo.jpg'
};

const MaterialSetHead = ({ materialSet, onUpdateFile }: Props) => {
  return (
    <div className="material_head">
      <div className="small_product_image">
        <div className="image_border _3px" />
        {materialSet.pictureThumbnail ? (
          <Picture src={materialSet.pictureThumbnail} width={100} alt="" className="image_circle_100" />
        ) : (
          <GetCameraImageButton buttonStyle="head" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
        )}
      </div>
      <div className="div-block-16">
        <MaterialValueTags fullInfo classNameTitle="mat_detail " classNameItem="mat_detail" materialSet={materialSet} />
      </div>
      <div className="div-block-18">
        <Picture
          src={materialSet.picture ? materialSet.picture : defaultData.picture}
          alt=""
          // altSrc="images/image_no_photo.jpg"
          className="image-2"
        />
      </div>
    </div>
  );
};

export default MaterialSetHead;
