import { observable, action, computed, runInAction } from 'mobx';

export enum LoadingType {
  DEFAULT,
  FLYOUT,
  ONLY_LOADING,
  NONE
}

export default class LoadingStore {
  @observable
  private loadingCounter = 0;

  @computed
  get isLoading() {
    return this.loadingCounter > 0;
  }

  set isLoading(state: boolean) {
    let counter = this.loadingCounter;
    if (state) {
      counter += 1;
    } else {
      counter -= 1;
    }

    if (counter <= 0) {
      counter = 0;
    }

    runInAction(() => {
      this.loadingCounter = counter;
    });
  }

  @observable
  isFlyoutLoading = false;

  @observable
  withOverlay = true;

  @action
  async withSpecificLoadingBar<T>(loadingType: LoadingType, apiCall: () => Promise<T>): Promise<T> {
    switch (loadingType) {
      case LoadingType.DEFAULT:
        return this.withLoadingBar(apiCall);
      case LoadingType.FLYOUT:
        return this.withFlyoutLoadingBar(apiCall);
      case LoadingType.ONLY_LOADING:
        return this.withOnlyLoadingBar(apiCall);
      default:
        return apiCall();
    }
  }

  @action
  async withLoadingBar<T>(apiCall: () => Promise<T>): Promise<T> {
    this.setIsLoading(true);
    this.setWithOverlay(true);
    const response = apiCall();

    return response
      .then(res => {
        this.setIsLoading(false);
        this.setWithOverlay(false);
        return res;
      })
      .catch(err => {
        this.setIsLoading(false);
        this.setWithOverlay(false);
        throw err;
      });
  }

  /**
   * withOnlyLoadingBar enables only the loading bar but not the overlay.
   * @param apiCall
   */
  @action
  async withOnlyLoadingBar<T>(apiCall: () => Promise<T>): Promise<T> {
    this.setIsLoading(true);
    this.setWithOverlay(false);
    const response = apiCall();

    return response
      .then(res => {
        this.setIsLoading(false);
        return res;
      })
      .catch(err => {
        this.setIsLoading(false);
        throw err;
      });
  }

  @action
  async withFlyoutLoadingBar<T>(apiCall: () => Promise<T>): Promise<T> {
    this.setIsFlyoutLoading(true);
    this.setWithOverlay(true);

    const response = apiCall();

    return response
      .then(res => {
        this.setIsFlyoutLoading(false);
        this.setWithOverlay(false);
        return res;
      })
      .catch(err => {
        this.setIsFlyoutLoading(false);
        this.setWithOverlay(false);
        throw err;
      });
  }

  @action
  private setIsLoading = (status: boolean) => {
    this.isLoading = status;
  };

  @action
  private setIsFlyoutLoading = (status: boolean) => {
    this.isFlyoutLoading = status;
  };

  @action
  private setWithOverlay = (status: boolean) => {
    this.withOverlay = status;
  };
}
