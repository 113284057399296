import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  label: string;
  className: string;
}

const AppBarBranding = (props: Props) => {
  const { t } = useTranslation('app');
  return (
    <>
      <img src="images/logo_klinik-puls.svg" className={props.className} alt="" />
      <div className="txt_logo">
        <strong>{props.label}</strong>
        {t('puls')}
      </div>
    </>
  );
};

export default AppBarBranding;
