import React, { useEffect } from 'react';
import { OptionTypeBase, OptionsType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import GetCameraImageButton from 'components/GetCameraImageButton';
import { GroupedDepartmentsDTO } from 'dto/department';
import { MaterialDTO, UpdateMaterialDTO } from 'dto/material';
import Form, { InputField, Select } from 'components/Form';
import Picture from 'components/Picture';

interface Props {
  departments: GroupedDepartmentsDTO[];
  materialDetail: MaterialDTO;
  departmentsDropdownOptions: OptionsType<OptionTypeBase>;
}

const MaterialDetailForm = observer(({ materialDetail, departmentsDropdownOptions }: Props) => {
  const { materialStore, flyoutStore } = useStores();
  const { t } = useTranslation('material');
  const onSubmit = (values: UpdateMaterialDTO) => {
    if (!values || Object.keys(values).length === 0) return;
    materialStore.updateMaterial({
      ...values,
      materialId: materialDetail.materialId
    });
  };

  const onUpdateFile = async (fileId: string) => {
    await materialStore.updateMaterial({ materialId: materialDetail.materialId, pictureFileId: fileId });
  };

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  useEffect(() => {
    const handleSubmitMyForm = () => {
      if (submitMyForm) submitMyForm();
    };
    return () => {
      handleSubmitMyForm();
    };
  });

  const handleAddImagesToView = () => {
    if (materialDetail.picture) {
      flyoutStore.setImagesToView(materialDetail.picture);
    }
  };

  useEffect(() => {
    materialStore.loadStorageLocations();
  }, [materialStore]);

  const erpStorageLocationsString = materialStore.erpStorageLocations.map(sl => sl.originStorageLocation.name).join(', ');
  return (
    <div className="single_colum_content material-content">
      <div>
        <div className="div-block-45">
          <div className="image_post_wide in_data post-image-container" onClick={handleAddImagesToView}>
            {materialDetail.pictureSmall ? (
              <Picture src={materialDetail.pictureSmall} alt="Material Detail" />
            ) : (
              <GetCameraImageButton buttonStyle="detailForm" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
            )}
          </div>
          <GetCameraImageButton
            isButtonVisible
            description={materialDetail.pictureFileId ? t('materialDetail.button.toChange') : t('materialDetail.button.toAdd')}
            onUpdateFile={onUpdateFile}
            buttonStyle="square"
            picturesOnly
            isAddable
          />
        </div>
      </div>
      <div className="div-block-44">
        <Form initialValues={{ ...materialDetail }} bindSubmitForm={bindSubmitForm} onSubmit={onSubmit}>
          {() => (
            <>
              <div className="content_field">
                <div>{t('materialDetail.label.description')}</div>
                <InputField name="name" placeholder={t('materialDetail.label.description')} />
              </div>
              <div className="content_field">
                <div>{t('materialDetail.label.department')}</div>
                <InputField
                  className="input_field top-margin"
                  mappedOptions={departmentsDropdownOptions}
                  name="departmentId"
                  component={Select}
                  placeholder={t('materialDetail.label.department')}
                  isGrouped
                />
              </div>
              <div className="content_field">
                <div>{t('materialDetail.label.articleNumber')}</div>
                <InputField name="manufacturerArticleNumber" placeholder={t('materialDetail.label.articleNumber')} />
              </div>
              <div className="content_field">
                <div>{t('materialDetail.label.erpId')}</div>
                <InputField name="erpId" placeholder={t('materialDetail.label.erpId')} viewOnly />
              </div>
              <div className="content_field">
                <div>{t('materialDetail.label.storageLocation')}</div>
                <InputField
                  value={erpStorageLocationsString}
                  name="erpStorageLocation"
                  placeholder={t('materialDetail.label.storageLocation')}
                  viewOnly
                />
              </div>
              <div className="content_field">
                <div>{t('materialDetail.label.productDescription')}</div>
                <InputField name="description" placeholder={t('materialDetail.label.productDescription')} />
              </div>
              <div className="content_field">
                <div>{t('materialDetail.label.price')}</div>
                <InputField name="price" placeholder={t('materialDetail.label.price')} />
              </div>
              <div className="content_field">
                <div>{t('materialDetail.label.packingUnit')}</div>
                <InputField name="packageSize" placeholder={t('materialDetail.label.packingUnit')} />
              </div>
              <div className="content_field">
                <div>{t('materialDetail.label.manufacturer')}</div>
                <InputField name="manufacturerName" placeholder={t('materialDetail.label.manufacturer')} />
              </div>
              <div className="content_field">
                <div>{t('materialDetail.label.supplier')}</div>
                <InputField name="supplier" placeholder={t('materialDetail.label.supplier')} />
              </div>
              <div className="content_field">
                <div>{t('materialDetail.label.lastSynchronisation')}</div>
                <InputField
                  value={materialDetail.lastSynchronisation}
                  name="lastSynchronisation"
                  placeholder={t('materialDetail.label.lastSynchronisation')}
                  viewOnly
                />
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  );
});

export default MaterialDetailForm;
