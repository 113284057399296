import React from 'react';
import { useTranslation } from 'react-i18next';
import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleOpenDetails: () => void;
}

const GuideGroupMaterialListItemMenu = ({ isOpen, handleClose, handleOpenDetails }: Props) => {
  const { t } = useTranslation('guideMaterials');
  return (
    <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
      <ItemMenuButton
        label={t('detailMenu.singleInstrumentKnowledgeAndAttitudes')}
        icon="icon_search-content_16.svg"
        isInPopover
        handleClick={handleOpenDetails}
      />
    </ItemMenu>
  );
};

export default GuideGroupMaterialListItemMenu;
