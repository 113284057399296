import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Form/Button';
import { FilterBy } from 'dto/material';

interface Props {
  onFilterSelect: (filterType: FilterBy) => void;
}

const getActiveClass = (selectedFilter: FilterBy, filterType: FilterBy) => (selectedFilter === filterType ? 'active' : '');

const SearchFilters = ({ onFilterSelect }: Props) => {
  const [selectedFilter, setSelectedFilter] = useState<FilterBy>('all');
  const { t } = useTranslation('guideMaterialSearchDetail');
  const onSelect = (filterType: FilterBy) => {
    if (filterType === 'all') {
      setSelectedFilter(filterType);
      onFilterSelect('all');
    } else {
      setSelectedFilter(filterType);
      onFilterSelect(filterType);
    }
  };
  return (
    <div className="div-block-35 search-filters">
      <h1 className="white in_search">{t('filters.title')}</h1>
      <div className="div-block-36">
        <Button className={`btn_filter ${getActiveClass(selectedFilter, 'all')}`} onClick={() => onSelect('all')}>
          {t('filters.all')}
        </Button>
        <Button className={`btn_filter ${getActiveClass(selectedFilter, 'erp')}`} onClick={() => onSelect('erp')}>
          {t('filters.erp')}
        </Button>
        <Button className={`btn_filter ${getActiveClass(selectedFilter, 'manual')}`} onClick={() => onSelect('manual')}>
          {t('filters.manuallyCreated')}
        </Button>
        <Button className={`btn_filter ${getActiveClass(selectedFilter, 'instruments')}`} onClick={() => onSelect('instruments')}>
          {t('filters.instrument')}
        </Button>
        <Button className={`btn_filter ${getActiveClass(selectedFilter, 'implants')}`} onClick={() => onSelect('implants')}>
          {t('filters.implant')}
        </Button>
        <Button className={`btn_filter ${getActiveClass(selectedFilter, 'sets')}`} onClick={() => onSelect('sets')}>
          {t('filters.set')}
        </Button>
        <Button className={`btn_filter ${getActiveClass(selectedFilter, 'medicals')}`} onClick={() => onSelect('medicals')}>
          {t('filters.medical')}
        </Button>
        <Button className={`btn_filter ${getActiveClass(selectedFilter, 'rentalMaterials')}`} onClick={() => onSelect('rentalMaterials')}>
          {t('filters.rentalMaterial')}
        </Button>
      </div>
    </div>
  );
};

export default SearchFilters;
