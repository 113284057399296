import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import Picture from 'components/Picture';
import { SimpleMaterialSetDTO, SetType } from 'dto/materialSet';
import Button from 'components/Form/Button';
import { useTranslation } from 'react-i18next';
import { SelectableType } from 'stores/searchStore';

interface Props {
  materialSet: SimpleMaterialSetDTO;
  isLast?: boolean;
}

const SearchMaterialSetItem = observer(({ materialSet, isLast }: Props) => {
  const { guideDetailDrawerStore, searchStore } = useStores();
  const { t } = useTranslation('materialSearch');

  const handleSelect = () => {
    if (searchStore.onSelect) {
      searchStore.onSelect({ materialSet });
    }
  };

  const handleShowMaterialDrawerClick = () => {
    guideDetailDrawerStore.setSelectedMaterialSet(materialSet);
    guideDetailDrawerStore.setIsOpen(true);
  };

  const showButton = searchStore.onSelect && searchStore.selectableTypes.indexOf(SelectableType.Set) >= 0;

  return (
    <div className={`div-block-27 search-material-item ${isLast ? 'last' : ''}`}>
      <div className="list_item_material_in_search search-material-list-item">
        <div className="image_wrapper_50" onClick={handleShowMaterialDrawerClick}>
          <div style={{ opacity: 0 }} className="image_border" />
          <Picture src={materialSet.pictureThumbnail} width="50" alt="" className="image_circle_50" />
        </div>
        <div className="material_info in_search material-info">
          <div className="div-block-34" onClick={handleShowMaterialDrawerClick}>
            <div className="material_text search-panel">
              <div>{materialSet.name}</div>
            </div>
          </div>
          <div className="div-block-30 search-panel">
            {materialSet.type === SetType.Implants ? (
              <div className="info_box material_search no_hover">
                <div>{t('searchItem.setTypeImplants')}</div>
              </div>
            ) : (
              <></>
            )}
            {materialSet.type === SetType.Sets ? (
              <div className="info_box material_search no_hover">
                <div>{t('searchItem.setTypeSets')}</div>
              </div>
            ) : (
              <></>
            )}
            {materialSet.type === SetType.Medicals ? (
              <div className="info_box material_search no_hover">
                <div>{t('searchItem.setTypeMedicals')}</div>
              </div>
            ) : (
              <></>
            )}
            {materialSet.type === SetType.RentalMaterials ? (
              <div className="info_box material_search no_hover">
                <div>{t('searchItem.setTypeRentalMaterials')}</div>
              </div>
            ) : (
              <></>
            )}
            {showButton && (
              <div onClick={handleSelect}>
                <Button className="btn_small_flyout">
                  <div className="txt_dropout_1">{searchStore.actionButtonLabel}</div>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default SearchMaterialSetItem;
