import React from 'react';
import { PictureDTO } from 'dto/file';
import PackageImageThumbnail from './PackageImageThumbnail';

interface Props {
  images: PictureDTO[];
  onImageDelete: (imageId: string) => void;
}

const PackageImagesThumbnails = ({ images, onImageDelete }: Props) => {
  return (
    <>
      {images.map((image, index) => {
        // skip first image
        if (index === 0) {
          return null;
        }
        return <PackageImageThumbnail image={image} onImageDelete={onImageDelete} key={image.pictureFileId} />;
      })}
    </>
  );
};

export default PackageImagesThumbnails;
