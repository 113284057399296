import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import ImageCircle from 'components/ImageCircle';
import './DepartmentLead.css';

const DepartmentLead = observer(() => {
  const { t } = useTranslation('department');
  const { domainStore } = useStores();

  useEffect(() => {
    domainStore.getDepartmentMaintainerLeadForDepartment(domainStore.currentDepartment.id);
  }, [domainStore, domainStore.currentDepartment.id]);

  return (
    <>
      <div className="h3">{t('departmentLead')}</div>
      <div className="div-block-copy empty-container-height">
        {domainStore.currentDepartmentLead && domainStore.currentDepartmentLead.user ? (
          <>
            <ImageCircle user={domainStore.currentDepartmentLead?.user} size="50" highlight={false} />
            <div className="item_name">
              <div className="t2 bottom-margin">{domainStore.currentDepartmentLead?.user.title}</div>
              <div>{`${domainStore.currentDepartmentLead?.user.lastName}, ${domainStore.currentDepartmentLead?.user.firstName}`}</div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
});

export default DepartmentLead;
