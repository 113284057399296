import { observable, action } from 'mobx';

export default class AnimationStore {
  @observable
  elementIdsToShake: string[] = [];

  @action
  setElementIdToShake(elementIdsToShake: string[]) {
    this.elementIdsToShake = elementIdsToShake;
  }

  @action
  resetElementIdToShake() {
    this.elementIdsToShake = [];
  }
}
