import React, { forwardRef, useImperativeHandle, useState, useMemo } from 'react';
import { useDropzone, DropEvent } from 'react-dropzone';
import { ContentType } from 'dto/file';
import { allowedFileEndings } from 'util/fileUtils';

/**
 * @returns A promise that resolves or rejects based on the success. This information is used to apply a style.
 */
export type OnDropCallback = <T extends File>(acceptedFiles: T[], rejectedFiles: T[], event: DropEvent) => Promise<void>;

interface Props {
  className?: string;
  accept?: ContentType[];
  onDrop?: OnDropCallback;
  children: JSX.Element[] | JSX.Element;
}
const baseStyle = {
  marginLeft: '-2px',
  marginRight: '-2px',

  borderStyle: 'dashed',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'transparent',
  transition: 'border .24s ease-in-out'
};

const activeStyle: React.CSSProperties = {
  ...baseStyle,
  backgroundColor: '#fafafa',
  borderColor: '#2196f3'
};

const rejectStyle: React.CSSProperties = {
  ...baseStyle,
  borderColor: '#ff1744'
};

const DropzoneContainer = forwardRef(({ className, children, onDrop, accept }: Props, ref) => {
  const [style, setStyle] = useState<React.CSSProperties>(baseStyle);

  // applies a new style and then resets to the baseStyle after some time
  const applyTempStyle = (newStyle: React.CSSProperties) => {
    setStyle(newStyle);
    setTimeout(() => {
      setStyle(baseStyle);
    }, 500);
  };

  const toAccept = accept;

  // calculate the possible file endings out of the passed types
  const fileEndings = useMemo(() => {
    let acceptedTypes = toAccept;

    if (acceptedTypes === undefined) {
      // if undefined, just allow all possible types
      acceptedTypes = Array.from(allowedFileEndings.keys());
    }

    return acceptedTypes.reduce((prev, type) => {
      const endings = allowedFileEndings.get(type);
      if (endings !== undefined) {
        prev.push(...endings);
      }
      return prev;
    }, [] as string[]);
  }, [toAccept]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles, event): void => {
      // Handle style changes in onDrop because the fileName is not available while dragging.
      // Its only available after drop.

      if (acceptedFiles.length <= 0) {
        applyTempStyle(rejectStyle);
        return;
      }

      const fileNameParts = acceptedFiles[0].name.split('.');
      if (fileNameParts.length <= 1) {
        // we do not allow names without any file-ending
        applyTempStyle(rejectStyle);
        return;
      }

      if (fileEndings.indexOf(fileNameParts[fileNameParts.length - 1].toLowerCase()) === -1) {
        // file ending not allowed
        applyTempStyle(rejectStyle);
        return;
      }

      if (onDrop !== undefined) {
        onDrop(acceptedFiles, rejectedFiles, event)
          .then(() => applyTempStyle(activeStyle))
          .catch(() => applyTempStyle(rejectStyle));
      }
    },
    noClick: true,
    multiple: false
  });

  useImperativeHandle(ref, () => ({
    open() {
      open();
    }
  }));

  return (
    <div
      className={className}
      {...getRootProps({
        style: isDragActive ? activeStyle : style
      })}
    >
      <input {...getInputProps()} maxLength={255} />
      {children}
    </div>
  );
});

export default DropzoneContainer;
