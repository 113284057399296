import { useGetGroupMaterials } from 'api/groupMaterialHooks';
import { updateSetGroup } from 'api/setGroup';
import MaterialValueTags from 'components/MaterialValueTags';
import { SetGroupWithTagsDTO } from 'dto/setGroup';
import { ItemPicture, MaterialListItem } from 'modules/material/ListItems';

import Tags from 'components/Tags';
import { SetGroupListItemMenu } from 'modules/materialSet/Menu/SetGroupListItemMenu';
import { SetCheck } from 'modules/materialSet/SetCheck/SetCheck';
import { useMaterialSetListContext } from 'modules/materialSet/materialSetListContext';
import React, { useCallback, useMemo, useState } from 'react';
import { LoadingType } from 'stores/loadingStore';
import { useStores } from 'util/mobx/stores';
import { GroupMaterialList } from './GroupMaterialList';

interface Props {
  setGroup: SetGroupWithTagsDTO;
  level?: number;
}

export const SetGroupItem = ({ setGroup, level = 0 }: Props) => {
  const { onReload, onTagCreate, onTagDelete, onTagUpdate, groupType, checksEnabled, readonly } = useMaterialSetListContext();
  const { loadingStore } = useStores();
  const [enabled, setEnabled] = useState(false);

  const handleExpand = useCallback(() => {
    setEnabled(true);
  }, []);

  const { data, isInitialLoading } = useGetGroupMaterials(setGroup.setGroupId, {
    enabled: enabled || checksEnabled,
    loadingType: LoadingType.ONLY_LOADING
  });

  const groupMaterials = useMemo(() => {
    return <GroupMaterialList level={level + 1} groupMaterials={data} groupType={groupType} setGroupId={setGroup.setGroupId} />;
  }, [data, groupType, level, setGroup.setGroupId]);

  const handleUpdateFile = async (fileId: string) => {
    await loadingStore.withLoadingBar(() => updateSetGroup({ setGroupId: setGroup.setGroupId, pictureFileId: fileId }));
    if (onReload) {
      await onReload();
    }
  };

  return (
    <div>
      <MaterialListItem
        forceExpand={checksEnabled}
        classNamePrefix="set"
        level={level}
        name={setGroup.name}
        picture={<ItemPicture picture={setGroup} onUpdateFile={handleUpdateFile} />}
        onDropdownExpand={handleExpand}
        dropdownItems={groupMaterials}
        isInitialLoading={isInitialLoading}
        instructions={
          <Tags
            editable={!readonly}
            tags={setGroup.tags}
            onTagCreate={onTagCreate && onTagCreate({ setGroupId: setGroup.setGroupId })}
            onTagUpdate={onTagUpdate}
            onTagDelete={onTagDelete}
          />
        }
        menu={<SetGroupListItemMenu setGroup={setGroup} />}
        end={<MaterialValueTags setGroupItemCount={setGroup.itemCount} fullInfo />}
        prefix={<SetCheck setGroupId={setGroup.setGroupId} />}
      />
    </div>
  );
};
