import React from 'react';

import { UserDTO } from 'dto/user';
import GuideOwnerListItem from './GuideOwnerListItem';

interface Props {
  guideOwnerList: UserDTO[];
  isCloneGuideFlyout?: boolean;
}

const GuideOwnerList = (props: Props) => (
  <>
    {props.guideOwnerList.map(guideOwner => (
      <GuideOwnerListItem
        key={guideOwner.userId}
        user={guideOwner}
        className={props.isCloneGuideFlyout ? 'list_item_name in-flyout' : 'list_item_name'}
        isCloneGuideFlyout={props.isCloneGuideFlyout}
      />
    ))}
  </>
);

export default GuideOwnerList;
