import { GroupMaterialDTO } from 'dto/groupMaterial';
import { GenericMultiTemplateItem } from 'modules/material/ListItems/Instrument/GenericMultiTemplateItem';
import { TemplateMenuRenderer } from 'modules/material/ListItems/Instrument/TemplateMenuRenderer';
import { SetCheck } from 'modules/materialSet/SetCheck/SetCheck';
import { useMaterialSetListContext } from 'modules/materialSet/materialSetListContext';
import React from 'react';
import { InstrumentRendererParentType } from 'modules/material/ListItems/Instrument/InstrumentRendererParentType';
import { GroupMaterialsListItemRenderer } from '../GroupMaterialsListItem';
import { InstructionRender } from '../InstructionRenderer';

interface Props {
  setGroupId: string;
  groupMaterial: GroupMaterialDTO;
  menu?: TemplateMenuRenderer<GroupMaterialDTO>;
  level?: number;
  prefix?: React.ReactElement;
  classNamePrefix?: string;
}

export const MultiTemplateItem = ({ setGroupId, groupMaterial, menu, level = 1, prefix, classNamePrefix }: Props) => {
  if (!groupMaterial.template) {
    throw new Error('the groupMaterial must have a template');
  }

  const { onClickItem } = useMaterialSetListContext();

  return (
    <GenericMultiTemplateItem<InstrumentRendererParentType<GroupMaterialDTO>>
      classNamePrefix={classNamePrefix}
      level={level}
      ListItemRenderer={GroupMaterialsListItemRenderer}
      parentEntity={groupMaterial}
      template={groupMaterial.template}
      InstructionRenderer={InstructionRender}
      MenuRenderer={menu}
      onClick={onClickItem}
      prefix={prefix || <SetCheck groupMaterialId={groupMaterial.groupMaterialId} setGroupId={setGroupId} />}
    />
  );
};
