import { FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import BoardInput from 'components/Form/BoardInput';
import { ProcessingStatus } from 'dto/file';
import Button from 'components/Form/Button';
import { useStores } from 'util/mobx/stores';
import { MixedContentElementMediaDTO } from 'components/UploadMultipleFiles/MixedContentElementMediaDTO';

interface Props {
  form: FormikProps<FormikValues>;
  setIsExpanded: (isExpanded: boolean | null) => void;
  isExpanded: boolean | null;
}

const DialogFormContent = ({ form, setIsExpanded, isExpanded }: Props) => {
  const { t } = useTranslation('board');
  const { postStore } = useStores();

  const [uploadedFiles, setUploadedFiles] = useState<MixedContentElementMediaDTO[]>(form.values.contentElementMedias);

  const onClose = () => {
    form.resetForm();
    postStore.resetDialogForm();
    setIsExpanded(false);
  };

  useEffect(() => {
    if (postStore.isDialogInEditMode && postStore.selectedDialogPost) {
      form.resetForm({
        values: {
          description: postStore.selectedDialogPost.contentElement.description || '',
          contentElementMedias: postStore.selectedDialogPost.contentElement.contentElementMedias || []
        }
      });
    } else {
      form.resetForm({
        values: {
          description: '',
          contentElementMedias: []
        }
      });
    }
    // form would create a cyclic dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postStore.isDialogInEditMode, postStore.selectedDialogPost]);

  return (
    <>
      <BoardInput
        isExpanded={isExpanded}
        onExpand={() => setIsExpanded(true)}
        meta={form}
        name="description"
        placeholder={t('dialog.inputPlaceholder')}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
      />
      <div className="div-block-74 in_board">
        <Button className="btn_msg_cancel" type="button" onClick={onClose}>
          {t('dialog.buttons.abort')}
        </Button>
        <Button
          disabled={
            !form.dirty ||
            !form.values.description ||
            (uploadedFiles && uploadedFiles.filter(uF => uF.status === ProcessingStatus.Uploading).length > 0)
          }
          className="btn_msg_send"
        >
          {t('dialog.buttons.send')}
        </Button>
      </div>
    </>
  );
};

export default DialogFormContent;
