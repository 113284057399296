import Button from 'components/Button';
import EmptyIndicator from 'components/EmptyIndicator';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import { SurgeryProcedureDTO, UpdateSortSurgeryGuideProceduresDTO } from 'dto/surgeryGuideProcedure';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import SurgeryProceduresList from './SurgeryProceduresList';
import SurgeryProceduresRightMenu from './SurgeryProceduresRightMenu';
import UsedGuide from './UsedGuide';

import './SurgeryProcedures.css';

interface Props extends OptionalLazyLoadProps {
  surgeryGuideId: string;
}

const SurgeryProcedures = observer(({ surgeryGuideId, lazyLoadScrollContainer }: Props) => {
  const { appNavigationStore, surgeryGuideStore } = useStores();
  const { t } = useTranslation('briefing');

  useEffect(() => {
    if (surgeryGuideStore.surgeryGuide) {
      surgeryGuideStore.loadGuideProcedures(surgeryGuideId);
    }
    return () => surgeryGuideStore.clearProcedures();
  }, [surgeryGuideStore, surgeryGuideStore.surgeryGuide, surgeryGuideId]);

  appNavigationStore.useSubPageIdSetter('procedures');

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    let procedureToBeMoved: SurgeryProcedureDTO;
    let newGuideProcedures = surgeryGuideStore.groupedGuideProcedures.map(guideProcedureGroup => {
      if (result.source?.droppableId === guideProcedureGroup.chapterId) {
        const draggableItem = guideProcedureGroup.surgeryGuideProcedures.find(p => p.surgeryGuideProcedureId === result.draggableId);
        procedureToBeMoved = draggableItem || procedureToBeMoved;
        return {
          ...guideProcedureGroup,
          surgeryGuideProcedures: guideProcedureGroup.surgeryGuideProcedures.filter(p => p.surgeryGuideProcedureId !== result.draggableId)
        };
      }
      return guideProcedureGroup;
    });
    newGuideProcedures = newGuideProcedures.map(guideProcedureGroup => {
      if (result.destination?.droppableId === guideProcedureGroup.chapterId) {
        guideProcedureGroup.surgeryGuideProcedures.splice(result.destination.index, 0, procedureToBeMoved);
        return {
          ...guideProcedureGroup,
          surgeryGuideProcedures: guideProcedureGroup.surgeryGuideProcedures
        };
      }
      return guideProcedureGroup;
    });
    const positions: UpdateSortSurgeryGuideProceduresDTO[] = newGuideProcedures.map(guideProcedureGroup => {
      const surgeryGuideProceduresIds = guideProcedureGroup.surgeryGuideProcedures.map(p => p.surgeryGuideProcedureId);
      return {
        chapterId: guideProcedureGroup.chapterId,
        surgeryGuideProceduresIds
      };
    });
    surgeryGuideStore.reorderProcedures(positions);
  };

  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <SurgeryProceduresRightMenu />;
    });
  }, [appNavigationStore]);

  const refresh = useCallback(() => {
    surgeryGuideStore.loadGuide(surgeryGuideId);
  }, [surgeryGuideId, surgeryGuideStore]);

  // Auto refresh if the procedures are not initialized yet.
  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (!surgeryGuideStore.surgeryGuide?.initialized) {
      interval = setInterval(() => {
        refresh();
      }, 10000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [refresh, surgeryGuideStore.surgeryGuide]);

  return (
    <EmptyIndicator
      message={
        <div style={{ whiteSpace: 'pre' }}>
          {t('proceduresNotInitialized')}
          <Button onClick={refresh}>{t('reloadProcedures')}</Button>
        </div>
      }
      isEmpty={!surgeryGuideStore.surgeryGuide?.initialized}
    >
      <>
        <div className="div-block-102_fixed in_proz_plan" />
        <div className="div-block-102_white in_proz_plan" />
        <div className="single_colum_content">
          <UsedGuide />
          <DragDropContext onDragEnd={onDragEnd}>
            {surgeryGuideStore.groupedGuideProcedures.map((procedureItem, index) => (
              <Droppable droppableId={procedureItem.chapterId} key={procedureItem.chapterId}>
                {provided => (
                  <div ref={provided.innerRef}>
                    <div id={procedureItem.chapter}>
                      <h1 className={index > 0 ? 'top-margin' : ''}>{procedureItem.chapter}</h1>
                      <SurgeryProceduresList
                        lazyLoadScrollContainer={lazyLoadScrollContainer}
                        chapterId={procedureItem.chapterId}
                        procedures={procedureItem.surgeryGuideProcedures}
                      />
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
        </div>
      </>
    </EmptyIndicator>
  );
});

export default SurgeryProcedures;
