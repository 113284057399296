import React, { useState, useRef, useEffect } from 'react';
import { TagDTO } from 'dto/tag';
import './Tag.css';

interface Props {
  tag: TagDTO;
  editable?: boolean;
  inFocus?: boolean;
  onClick?: (tag: TagDTO) => void;
  onTagUpdate: (tag: TagDTO) => Promise<TagDTO> | undefined;
  onTagDelete?: (tagId: string) => void;
  onFocusOut?: () => void;
}

const Tag = ({ tag, editable = false, inFocus = false, onClick, onTagUpdate, onTagDelete, onFocusOut }: Props) => {
  const [tagName, setTagName] = useState(tag.name);
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const onInputChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement;
    setTagName(target.value);
  };

  const onTagClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    if (onClick) {
      onClick(tag);
    }
  };

  useEffect(() => {
    if (inputRef.current && inFocus) {
      inputRef.current.focus();
    }
    if (!inFocus && onTagUpdate && tagName && tagName !== tag.name) {
      onTagUpdate({
        tagId: tag.tagId,
        name: tagName
      });
    }
    if (!inFocus && onTagDelete && !tagName) {
      onTagDelete(tag.tagId);
    }
  }, [inFocus, tag, onTagUpdate, onTagDelete, tagName]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      if (onTagUpdate && tagName) {
        onTagUpdate({
          tagId: tag.tagId,
          name: tagName
        });
        if (inputRef.current && onFocusOut) {
          onFocusOut();
          inputRef.current.blur();
        }
      }
      if (onTagDelete && !tagName) {
        onTagDelete(tag.tagId);
      }
    }
  };

  return (
    <div className={`${editable ? 'material_note_edit tag-item' : 'material_note'}`} key={tag.tagId} onClick={onTagClick}>
      {inFocus ? (
        <input value={tagName} type="text" onChange={onInputChange} ref={inputRef} onKeyDown={onKeyDown} />
      ) : (
        <>
          <div className="tag-name">{tagName}</div>
          {editable && (
            <div className="div-block-109 edit-icon-box">
              <img src="images/icon_c-edit_12.svg" alt="" className="icon_edit" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Tag;
