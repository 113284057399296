import React from 'react';

interface Props {
  headline: string;
}

const NavSectionHeadline = (props: Props) => {
  return <div className="txt_section_nav for_mobile">{props.headline}</div>;
};

export default NavSectionHeadline;
