import React from 'react';
import { HistoryActionType } from 'dto/history';

interface Props {
  action: HistoryActionType;
  field?: string;
}

export enum GuideFields {
  amount = 'amount',
  category = 'guideMaterialCategoryId'
}

const IconCreate = () => <img src="images/icon_history_addnew.jpg" alt="" />;
const IconDelete = () => <img src="images/icon_history_delete.jpg" alt="" />;
const IconUpdateAmount = () => <img src="images/icon_history_amount.jpg" alt="" />;
const IconUpdateMaterialCategory = () => <img src="images/icon_history_no-location.jpg" alt="" />;

const GuideHistoryEventIconsFactory = ({ action, field }: Props) => {
  const getIcon = (actionType: HistoryActionType, fieldName?: string) => {
    if (actionType === HistoryActionType.create) {
      return <IconCreate />;
    }
    if (actionType === HistoryActionType.delete) {
      return <IconDelete />;
    }
    if (actionType === HistoryActionType.update && fieldName === GuideFields.amount) {
      return <IconUpdateAmount />;
    }
    if (actionType === HistoryActionType.update && fieldName === GuideFields.category) {
      return <IconUpdateMaterialCategory />;
    }
    return null;
  };

  return <div className="icon_no_location">{getIcon(action, field)}</div>;
};

export default GuideHistoryEventIconsFactory;
