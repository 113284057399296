import React from 'react';
import Button from 'components/Form/Button';
import { useTranslation } from 'react-i18next';
import Lottie, { LottieProps } from 'react-lottie';
import animationData from './animationData.json';
import './AuthMessage.css';

interface Props {
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAuthMessage: React.Dispatch<React.SetStateAction<boolean>>;
  withPasswordResetMessage: boolean;
  withSignupMessage: boolean;
}

const defaultOptions: LottieProps['options'] = {
  loop: false,
  autoplay: true,
  animationData
};

const AuthMessage = ({ setShowLogin, setShowAuthMessage, withPasswordResetMessage = false, withSignupMessage = false }: Props) => {
  const { t: translationSignup } = useTranslation('signup');
  const { t: translationLogin } = useTranslation('login');

  const switchToLogin = () => {
    setShowAuthMessage(false);
    setShowLogin(true);
  };

  return (
    <div className="div-block-170">
      <div className="div-block-178">
        <div className="div-block-171">
          <img src="images/logo_klinik-puls.svg" alt="" />
          <div className="txt_logo-copy">
            <strong>{translationLogin('logoTextFirstPart')}</strong>
            {translationLogin('logoTextSecondPart')}
          </div>
        </div>

        <div className="div-block-181">
          <div className="reg_succ not-animated">
            <div className="lottie-animation-2">
              <Lottie options={defaultOptions} direction={1} speed={1}>
                <img src="images/registration_success.svg" alt="" />
              </Lottie>
            </div>
            <div className="text-block-27">
              <div>
                <span className="text-span-7">
                  {withSignupMessage && translationSignup('signupSuccess.successMessage')}
                  {withPasswordResetMessage && translationLogin('resetPassword.successMessage')}
                </span>
                <br />
                {withSignupMessage && translationSignup('signupSuccess.infoMessage')}
                {withPasswordResetMessage && translationLogin('resetPassword.infoMessage')}
              </div>
            </div>
            <div className="button_bar-copy">
              <Button className="btn_big_done-copy" onClick={switchToLogin}>
                {translationSignup('signupSuccess.submit')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthMessage;
