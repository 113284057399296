const CHART_COLORS = {
  LOW: '#fc6b0b',
  MEDIUM: '#d6ae1c',
  HIGH: '#1dd57e'
};

export const isRatioLow = (ratio: number) => {
  return ratio < 0.5 && ratio > 0;
};

export const isRatioMedium = (ratio: number) => {
  return ratio >= 0.5 && ratio < 0.9;
};

export const isRatioHigh = (ratio: number) => {
  return ratio >= 0.9;
};

export const getColorByRatio = (ratio: number): string => {
  if (isRatioLow(ratio)) {
    return CHART_COLORS.LOW;
  }
  if (isRatioMedium(ratio)) {
    return CHART_COLORS.MEDIUM;
  }
  return CHART_COLORS.HIGH;
};
