import { MultiPackageDTO } from 'dto/package';
import React, { useCallback, useMemo, useState } from 'react';
import { useStores } from 'util/mobx/stores';
import { useGetInstruments } from 'api/instrumentHooks';
import { LoadingType } from 'stores/loadingStore';
import { updatePackage } from 'api/package';
import MaterialValueTags from 'components/MaterialValueTags';
import { ListItemRendererProps } from '../MaterialListItem';
import { ItemPicture } from '../ItemPicture';
import { GenericInstrumentItem } from './GenericInstrumentItem';

interface Props<T> {
  parentEntity: T;
  ListItemRenderer: React.FC<ListItemRendererProps<T>>;

  level?: number;
  prefix?: React.ReactElement;
  pack: MultiPackageDTO;
  onClick?: (parent: T, id: { packageId?: string; materialId?: string }) => void;
  onReload?: () => Promise<void>;

  MenuRenderer?: React.FC<{ parent: T; specificId: { templateId?: string; packageId?: string; instrumentId?: string } }>;
}

export function GenericMultiPackageItem<T extends { currentPackageId?: string; currentInstrumentId?: string }>({
  parentEntity,
  ListItemRenderer,
  pack,
  level = 1,
  prefix,
  onClick,
  onReload,
  MenuRenderer
}: Props<T>) {
  const { loadingStore } = useStores();
  const [enabled, setEnabled] = useState(false);

  const handlePackageClick = useCallback(() => {
    if (!onClick) {
      return;
    }
    onClick(parentEntity, { packageId: pack.packageId });
  }, [onClick, pack.packageId, parentEntity]);

  const handleExpand = useCallback(() => {
    setEnabled(true);
  }, []);

  const { data, isInitialLoading } = useGetInstruments(pack.packageId, {
    enabled,
    loadingType: LoadingType.ONLY_LOADING
  });

  const packageInstruments = useMemo(() => {
    if (!data || !enabled) {
      return [];
    }
    return data.instruments.map(instrument => {
      return (
        <GenericInstrumentItem
          key={instrument.instrumentId}
          ListItemRenderer={ListItemRenderer}
          instrument={instrument}
          parentEntity={parentEntity}
          MenuRenderer={MenuRenderer}
          level={level + 1}
          onClick={onClick}
          onReload={onReload}
        />
      );
    });
  }, [ListItemRenderer, MenuRenderer, data, enabled, level, onClick, onReload, parentEntity]);

  const handleUpdateFile = async (fileId: string) => {
    const pictureFileIds = pack.pictures.map((file, index) => {
      if (index === 0) {
        return fileId;
      }
      return file.pictureFileId;
    });
    if (pictureFileIds.length === 0) {
      pictureFileIds.push(fileId);
    }
    await loadingStore.withLoadingBar(() => updatePackage({ packageId: pack.packageId, pictureFileIds }));
    if (onReload) {
      await onReload();
    }
  };

  return (
    <ListItemRenderer
      parentEntity={parentEntity}
      level={level}
      onDropdownExpand={handleExpand}
      dropdownItems={packageInstruments}
      isInitialLoading={isInitialLoading}
      name={pack.name}
      onClick={handlePackageClick}
      picture={<ItemPicture picture={pack.picture} onUpdateFile={handleUpdateFile} />}
      end={<MaterialValueTags simplePack={pack} />}
      prefix={prefix}
      menu={MenuRenderer && <MenuRenderer parent={parentEntity} specificId={{ templateId: pack.templateId, packageId: pack.packageId }} />}
    />
  );
}
