import React from 'react';
import { useStores } from 'util/mobx/stores';
import CollapsibleBox from 'components/CollapsibleBox';
import { useTranslation } from 'react-i18next';
import { StorageLocationPath, ErpStorageLocations, StorageLocationPathInMaterial } from 'components/StorageLocationItems';
import { observer } from 'mobx-react';
import { LocationSource } from 'dto/storageLocation';

interface Props {
  showFirstAsFake?: boolean;
  erpLocationPathHeadline: string;
}

const DrawerStorageLocationContent = observer(({ showFirstAsFake = false, erpLocationPathHeadline }: Props) => {
  const { guideDetailDrawerStore, guideStore, domainStore } = useStores();
  const { t } = useTranslation('material');
  if (guideDetailDrawerStore.storageLocationPath && guideDetailDrawerStore.storageLocationPath.storageLocations.length !== 0) {
    return (
      <CollapsibleBox
        isExpandedByDefault
        head={
          <>
            <div>{t('drawer.storageLocations')}</div>
          </>
        }
      >
        <StorageLocationPath path={guideDetailDrawerStore.storageLocationPath} />
      </CollapsibleBox>
    );
  }
  if (guideDetailDrawerStore.materialStorageLocations && guideDetailDrawerStore.materialStorageLocations.length !== 0) {
    const erpStorageLocations = guideDetailDrawerStore.materialStorageLocations.filter(
      st => st.originStorageLocation.locationSource === LocationSource.ERP
    );

    if (!showFirstAsFake) {
      if (erpStorageLocations.length !== 0) {
        return (
          <>
            <CollapsibleBox
              isExpandedByDefault
              head={
                <>
                  <div>{t('drawer.storageLocations')}</div>
                </>
              }
            >
              <ErpStorageLocations materialStorageLocations={erpStorageLocations} locationPathHeadline={erpLocationPathHeadline} />
            </CollapsibleBox>
          </>
        );
      }
    } else {
      const kpsStorageLocations = guideDetailDrawerStore.materialStorageLocations.filter(
        st =>
          st.originStorageLocation.locationSource === LocationSource.KPS &&
          st.originStorageLocation.functionalAreaId === (guideStore.selectedGuide?.functionalAreaId || domainStore.currentFunctionalArea.id)
      );
      return (
        <>
          <CollapsibleBox
            isExpandedByDefault
            head={
              <>
                <div>{t('drawer.storageLocations')}</div>
              </>
            }
          >
            {kpsStorageLocations.length !== 0 && guideDetailDrawerStore.materialStorageLocationPaths ? (
              <StorageLocationPathInMaterial materialStorageLocations={guideDetailDrawerStore.materialStorageLocationPaths} />
            ) : (
              <></>
            )}
            {erpStorageLocations.length !== 0 ? (
              <ErpStorageLocations materialStorageLocations={erpStorageLocations} locationPathHeadline={erpLocationPathHeadline} />
            ) : (
              <></>
            )}
          </CollapsibleBox>
        </>
      );
    }
  }

  return (
    <CollapsibleBox
      head={
        <>
          <div>{t('drawer.storageLocations')}</div>
        </>
      }
    >
      <div className="post_item no_margin">
        <div className="post_box material_detail">
          <div className="flex_horizontal margin_bottom">
            <div>{t('drawer.noLocations')}</div>
          </div>
        </div>
      </div>
    </CollapsibleBox>
  );
});

export default DrawerStorageLocationContent;
