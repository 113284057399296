import React from 'react';
import 'moment/locale/de';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { FormikValues, FormikProps } from 'formik';
import { parseFromDateTime, getCurrentDate } from 'util/date';

const styles = () => ({
  input: {
    color: '#7a7a7a',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    paddingLeft: '10px',
    fontWeight: 400,
    marginTop: '20px',
    background: 'white'
  }
});

interface Props extends WithStyles<typeof styles> {
  meta: FormikProps<FormikValues>;
  name: string;
}

const TimePickerComponent = ({ meta, name, classes, ...rest }: Props) => {
  const onSelectDate = (newDateTime: MaterialUiPickersDate) => {
    if (!newDateTime) return;

    // Just set the values directly. Inside of this component no
    // localtime or utc exists. the Value itself counts.
    // Needs to be converted to the desired value on submit.
    meta.setFieldValue(name, {
      hour: newDateTime.hour(),
      minute: newDateTime.minute(),
      second: 0
    });
  };

  return (
    <KeyboardTimePicker
      {...rest}
      autoOk
      ampm={false}
      value={meta.values[name] ? parseFromDateTime(getCurrentDate(), meta.values[name], true) : null}
      onChange={onSelectDate}
      variant="inline"
      inputVariant="outlined"
      PopoverProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'left' } }}
      InputProps={{ className: classes.input, notched: true }}
    />
  );
};

const TimePickerComponentWithStyles = withStyles(styles)(TimePickerComponent);

export default TimePickerComponentWithStyles;
