import React from 'react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Button from 'components/Form/Button';
import ListByIndex from '../ListByIndex';
import OriginStorageLocationsList from '../OriginStorageLocationsList';
import EndStorageLocationsList from '../EndStorageLocationsList';
import { getOriginLocations, getEndStorageLocations } from './storageLocationItemsUtils';
import './StorageLocationsList.css';

interface Props {
  assignedToMaterial?: boolean;
  inFlyout?: boolean;
}

const StorageLocationsList = observer(({ assignedToMaterial = false, inFlyout = false }: Props) => {
  const { storageLocationStore } = useStores();
  const { t } = useTranslation('storageLocations');
  const getAnimationClass = () => {
    return storageLocationStore.selectedStorageLocation ? 'expanded' : 'collapsed';
  };

  return (
    <div
      className={`location-administration-container colum_left border left ${inFlyout ? 'in-flyout' : ''} ${
        !assignedToMaterial ? 'not-assigned-to-material' : ''
      }`}
    >
      {!assignedToMaterial && (
        <div className={`${inFlyout ? 'lageorte_nav_inflyout' : 'lageorte_nav'} location-navigation ${getAnimationClass()}`}>
          <Button
            className={`${inFlyout ? 'btn_back_lvl1-in_flyout' : 'btn_back_lvl1'} btn-back`}
            type="button"
            onClick={() => storageLocationStore.goToPreviousIndex()}
          >
            <img src="images/icon_left-arrow_16.svg" alt="" className="image_arrow_left" />
            <div>{t('button.back')}</div>
          </Button>
        </div>
      )}
      <div className={`spacer location-spacer ${getAnimationClass()}`} />
      <div
        className={`storage-location-list ${storageLocationStore.selectedStorageLocation ? 'expanded' : ''} ${
          inFlyout ? 'in-flyout' : ''
        } ${!assignedToMaterial ? 'not-assigned-to-material' : ''}`}
      >
        {assignedToMaterial ? (
          <div className="div-block-143">
            <OriginStorageLocationsList items={getOriginLocations(storageLocationStore.materialStorageLocations)} inFlyout={inFlyout} />
            <EndStorageLocationsList
              items={getEndStorageLocations(
                storageLocationStore.materialStorageLocations,
                storageLocationStore.selectedMaterialStorageLocation
              )}
              inFlyout={inFlyout}
            />
          </div>
        ) : (
          <div className="div-block-143">
            <ListByIndex
              noHeight={inFlyout}
              hideAddButtons={inFlyout}
              noDragDrop={inFlyout}
              whiteBorder
              index={storageLocationStore.currentIndex}
            />
            {storageLocationStore.selectedStorageLocation && (
              <ListByIndex
                noHeight={inFlyout}
                hideAddButtons={inFlyout}
                noDragDrop={inFlyout}
                whiteBorder
                index={storageLocationStore.currentIndex + 1}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
});

export default StorageLocationsList;
