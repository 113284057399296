import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from 'util/mobx/stores';
import Safe from 'components/Safe';
import Page from 'components/Page';
import AppLayout from 'components/AppLayout';
import { AppBarHeaderText, AppBarPageIcon } from 'components/AppLayout/AppBar';
import { ChatConfigurationTypes } from 'components/Chat/Chat';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import SingleMaterials from 'pages/singleMaterials';
import Instruments from 'pages/instruments';
import Groups from 'pages/groups';
import CreateSingleMaterialFlyout from 'pages/singleMaterials/CreateSingleMaterialFlyout';
import GuidesContent from './GuidesContent';
import GuidesNavigation from './GuidesNavigation';

import './Guides.css';

enum GuidesRoutes {
  main = '/guides',
  materials = '/guides/materials',
  instruments = '/guides/instruments',
  groups = '/guides/groups'
}

export default observer(() => {
  const { domainStore, guideStore } = useStores();
  const { t } = useTranslation('guide');
  const { shortName: locationShortName } = domainStore.currentLocation;
  const { shortName: functionAreaShortName } = domainStore.currentFunctionalArea;
  const { name: departmentName } = domainStore.currentDepartment;
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;

  useEffect(() => {
    guideStore.setSelectedGuide(undefined);
  });
  return (
    <Safe>
      <>
        <AppLayout
          appBarIcon={
            <AppBarPageIcon
              src="images/icon_active_book.jpg"
              onClick={() => domainStore.setShowSwitchDomainFlyout(true)}
              className="title-icon-pointer"
            />
          }
          appBarChildren={
            <AppBarHeaderText
              subHeadline={`${locationShortName} | ${functionAreaShortName}`}
              headline={departmentName}
              onClick={() => domainStore.setShowSwitchDomainFlyout(true)}
            />
          }
          boardFlyout={<BoardFlyout />}
          flyout={
            <>
              <CreateSingleMaterialFlyout />
            </>
          }
        >
          <Page
            disableBackButton
            headline={t('subMenu.headline')}
            idPrefix="guideslist"
            navigation={<GuidesNavigation currentPath={currentPath} />}
            chatConfigurationType={ChatConfigurationTypes.department}
            wrapperContentClass="guide-wrapper-content"
          >
            <Route path={GuidesRoutes.materials} component={SingleMaterials} />
            <Route path={GuidesRoutes.instruments} component={Instruments} />
            <Route path="/guides/groups/:groupType" component={() => <Groups />} />
            {currentPath === GuidesRoutes.main ? <GuidesContent /> : <></>}
          </Page>
        </AppLayout>
      </>
    </Safe>
  );
});
