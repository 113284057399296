import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import ScrollPositionManager from 'components/ScrollPositionManager';
import DepartmentLead from 'components/DepartmentLead';
import ListItemText from 'components/ListItemText';
import EmptyIndicator from 'components/EmptyIndicator';
import Button from 'components/Button';
import TemplatesList from './TemplatesList';
import InstrumentsRightMenu from './InstrumentsRightMenu';

import './Instruments.css';

const Instruments = observer(() => {
  const { appNavigationStore, domainStore, instrumentStore, loadingStore } = useStores();
  const [filterListBy, setfilterListBy] = useState(domainStore.currentDepartment.id);
  const { t } = useTranslation('instruments');

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <InstrumentsRightMenu />;
    });
  }, [appNavigationStore]);

  useEffect(() => {
    return () => {
      instrumentStore.clear();
    };
  }, [instrumentStore]);

  const onSelectDepartment = (id: string) => setfilterListBy(id);

  const loadTemplates = useCallback(() => {
    if (filterListBy === domainStore.currentDepartment.id) {
      instrumentStore.loadTemplates(domainStore.currentDepartment.id);
    }
    if (filterListBy === 'all') {
      instrumentStore.loadTemplates();
    }
  }, [instrumentStore, domainStore.currentDepartment.id, filterListBy]);

  return (
    <div className="w-layout-grid grid_colum_content pb-80-desktop">
      <div id="grid-guides-colum_left" className="colum_left">
        <DepartmentLead />
        <div className="line" />
        <ListItemText
          text={t('categoryList.usedInDepartments')}
          isSelected={domainStore.currentDepartment.id === filterListBy}
          onClick={() => onSelectDepartment(domainStore.currentDepartment.id)}
        />
        {/* TODO: disabled due to low performance <ListItemText
          text={t('categoryList.allInstruments')}
          isSelected={filterListBy === 'all'}
          onClick={() => onSelectDepartment('all')}
        /> */}
      </div>
      {!loadingStore.isLoading && (
        <ScrollPositionManager
          scrollKey="grid-guides-colum-right"
          id="grid-guides-colum_right"
          className="colum_right materials colum-scroll mobile-overflow-x "
          style={{ gridColumnStart: '2' }}
        >
          <EmptyIndicator
            message={
              <>
                {t('notLoadedYet')}
                <Button onClick={loadTemplates}>{t('loadingButton')}</Button>
              </>
            }
            isEmpty={instrumentStore.templates === undefined}
          >
            <TemplatesList templates={instrumentStore.templates || []} filterListBy={filterListBy} />
          </EmptyIndicator>
        </ScrollPositionManager>
      )}
    </div>
  );
});

export default Instruments;
