import React from 'react';
import { observer } from 'mobx-react';

import GenderIcon from 'components/GenderIcon';
import { parseFromDateTime, dateFormat } from 'util/date';
import { useStores } from 'util/mobx/stores';

const BriefingBarHeader = observer(() => {
  const { surgeryGuideStore } = useStores();
  if (!surgeryGuideStore.briefing?.surgery) {
    return null;
  }
  const { surgery } = surgeryGuideStore.briefing;
  return (
    <>
      {/* Add patient picture later */}
      <div className=" app-bar-header-text title_info">
        <div>
          <strong>{surgeryGuideStore.briefing.surgery.name}</strong>
        </div>
        <div className="div-block-134">
          <div className="t2 top-margin">
            {surgery.lastName}
            {surgery.lastName && surgery.firstName && <>,</>} {surgery.firstName}{' '}
            {!!surgery.birthday && <>*{parseFromDateTime(surgery.birthday)?.format(dateFormat)}</>}
            <GenderIcon gender={surgery.gender} className="size_16 image-arrow-list" isGrey />
          </div>
        </div>
      </div>
    </>
  );
});

export default BriefingBarHeader;
