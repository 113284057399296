import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, DropResult, Draggable } from 'react-beautiful-dnd';
import { StorageLocationDTO } from 'dto/storageLocation';
import { useStores } from 'util/mobx/stores';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import { StorageLocationItem } from 'components/ListItems';
import { getItemStyle } from 'util/dragAndDrop';
import { createStorageItem } from '../locationAdministrationUtils';

interface Props {
  inDrawer?: boolean;
  selectedStorageLocation: StorageLocationDTO;
}

const StorageLocationItems = observer(({ selectedStorageLocation }: Props) => {
  const { t } = useTranslation('storageLocations');
  const { t: searchTranslation } = useTranslation('materialSearch');
  const { storageLocationStore, searchStore } = useStores();
  const [storageItems, setStorageItems] = useState(selectedStorageLocation?.storageItems || []);

  useEffect(() => {
    setStorageItems(selectedStorageLocation.storageItems);
  }, [selectedStorageLocation]);

  const handleShowSearchPanel = () => {
    searchStore.openSearchPanel(
      m => createStorageItem(storageLocationStore, selectedStorageLocation, m),
      searchTranslation('searchItem.addItem')
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const storageItemToBeMoved = storageItems.find(s => s.storageItemId === result.draggableId);
    const oldStorageItems = storageItems.filter(s => s.storageItemId !== result.draggableId);

    if (storageItemToBeMoved && selectedStorageLocation) {
      oldStorageItems.splice(result.destination.index, 0, storageItemToBeMoved);
      storageLocationStore.reorderStorageItems({ storageItemIds: oldStorageItems.map(s => s.storageItemId) });
      setStorageItems(oldStorageItems);
    }
  };
  return (
    <div className="list_item_dropdown">
      <ListItemAdd className="post_item_add in_mat_list lagerorte" onClick={handleShowSearchPanel}>
        <InfoBox label={t('button.addMaterial')} />
      </ListItemAdd>
      <div className="list_head">
        <div className="div-block-145">
          <div className="h3 no_margin">{t('listHead.targetNumber')}</div>
        </div>
        <div className="h3 no_margin">{t('listHead.name')}</div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="procedureFlyout" direction="vertical">
          {provided => (
            <div ref={provided.innerRef}>
              {storageItems.map((storageItem, index) => {
                return (
                  <Draggable draggableId={storageItem.storageItemId} index={index} key={storageItem.storageItemId}>
                    {(draggableProvided, snapshot) => {
                      return (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, draggableProvided.draggableProps.style)}
                        >
                          <StorageLocationItem storageItem={storageItem} key={storageItem.storageItemId} />
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
});

export default StorageLocationItems;
