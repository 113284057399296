import React from 'react';
import { validate } from 'api/validation';
import { QRUrlOptions } from 'stores/materialSetsStore';
import QRScanButton from 'components/QRScanButton';

interface Props {
  className?: string;
  label?: string;
  onScan?: () => void;
}
const MaterialSetQRScanButton = ({ className = '', label, onScan }: Props) => {
  const transform = (data: phonegapBarcode.BarcodeScanResult) => {
    if (data.cancelled) {
      return Promise.resolve(undefined);
    }

    return validate(QRUrlOptions, JSON.parse(data.text)).then(options => {
      return `/group/${options.groupType}/list?materialSetId=${options.materialSetId}`;
    });
  };

  return <QRScanButton className={className} onScan={onScan} label={label} transformToURL={transform} />;
};
export default MaterialSetQRScanButton;
