import React, { useState, useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import GlobalFunctions from 'components/GlobalFunctions';
import { useTranslation } from 'react-i18next';
import SingleInstrumentGlobalFunctionsRightMenu from './SingleInstrumentGlobalFunctionsRightMenu';

const SingleInstrumentGlobalFunctions = () => {
  const [loading, setLoading] = useState(false);
  const { materialStore, appNavigationStore, instrumentStore } = useStores();

  const { t } = useTranslation('singleInstruments');

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <SingleInstrumentGlobalFunctionsRightMenu />;
    });
  }, [appNavigationStore]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      if (instrumentStore.fullPackageItem) {
        const { templateId } = instrumentStore.fullPackageItem.template;
        await instrumentStore.loadTemplateGuides(templateId);
      }
      setLoading(false);
    }
    loadData();
  }, [materialStore, instrumentStore]);

  appNavigationStore.useSubPageIdSetter('functions');

  if (!loading && instrumentStore.templateGuides) {
    return <GlobalFunctions type="singleInstrument" filterButtonLabel={t('globalFunctions.button.containingGuidesFilter')} />;
  }
  return null;
};

export default SingleInstrumentGlobalFunctions;
