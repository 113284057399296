import React from 'react';
import { useTranslation } from 'react-i18next';
import { PostDTO } from 'dto/post';
import { GuidePost } from '../BoardPost';

interface Props {
  posts?: PostDTO[];
}

const ManualUpdates = ({ posts = [] }: Props) => {
  const { t } = useTranslation('board');
  return (
    <div className="board_content_wrapper_right board-content-wrapper-right">
      <div className="board_header small">
        <img src="images/icon_bookmark_chat_24.svg" alt="" className="board_icon_chatbox small" />
        <div>{t('label.guidePosts')}</div>
      </div>
      <div className="board_inner_wrapper">
        {posts.map(post => {
          return <GuidePost post={post} />;
        })}
      </div>
    </div>
  );
};

export default ManualUpdates;
