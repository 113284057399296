import { TagDTO, CreateTagDTO, UpdateTagDTO } from 'dto/tag';
import { post, patch, del } from './common';
import { validate, validateBool } from './validation';

export const createTag = async (tag: CreateTagDTO): Promise<TagDTO> => {
  const newTag = await post('/tag', tag).then(response => response.data);
  return validate(TagDTO, newTag);
};

export const updateTag = async (tagUpdateFields: UpdateTagDTO): Promise<TagDTO> => {
  const updatedMaterial = await patch(`/tag`, tagUpdateFields).then(response => response.data);
  return validate(TagDTO, updatedMaterial);
};

export const deleteTag = async (tagId: string): Promise<boolean> => {
  return del('/tag', { tagId }).then(response => validateBool(response.data));
};
