import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStores } from 'util/mobx/stores';
import BaseSetDepartmentFlyout, { BaseSetDepartmentFlyoutProps } from './BaseSetDepartmentFlyout';

export const SetDepartmentLeaderFlyout = (props: BaseSetDepartmentFlyoutProps) => {
  const { t } = useTranslation('accountManagement');
  const { userStore, domainStore } = useStores();

  const onSetDepartmentLeader = (departmentId: string, isLeader: boolean, isMaintainer: boolean, hasOtherLeader: boolean) => {
    if (hasOtherLeader) {
      return;
    }
    if (!userStore.selectedUser?.userId) {
      return;
    }
    if (!isLeader) {
      userStore.setUserAsDepartmentMaintainer({ isLead: true, userId: userStore.selectedUser.userId, departmentId }).then(() => {
        domainStore.loadAllDepartments();
      });
    } else {
      userStore.removeUserAsDepartmentMaintainer({ userId: userStore.selectedUser.userId, departmentId }).then(() => {
        domainStore.loadAllDepartments();
      });
    }
  };

  return (
    <BaseSetDepartmentFlyout
      {...props}
      flyoutHeadline={t('userDetail.setDepartmentCharge.setChargeHeadline')}
      hasOtherLeaderClass="leader-taken"
      isLeaderClass="leader-current"
      isMaintainerClass=""
      hasOtherLeaderText="Vergeben"
      isLeaderText="Ausgewählt"
      isMaintainerText=""
      onSelectDepartment={onSetDepartmentLeader}
    />
  );
};

export const SetDepartmentMaintainerFlyout = (props: BaseSetDepartmentFlyoutProps) => {
  const { t } = useTranslation('accountManagement');
  const { userStore, domainStore } = useStores();

  const onSetDepartmentMaintainer = (departmentId: string, isLeader: boolean, isMaintainer: boolean) => {
    if (isLeader) {
      return;
    }
    if (!userStore.selectedUser?.userId) {
      return;
    }
    if (!isMaintainer) {
      userStore.setUserAsDepartmentMaintainer({ isLead: false, userId: userStore.selectedUser.userId, departmentId }).then(() => {
        domainStore.loadAllDepartments();
      });
    } else {
      userStore.removeUserAsDepartmentMaintainer({ userId: userStore.selectedUser.userId, departmentId }).then(() => {
        domainStore.loadAllDepartments();
      });
    }
  };

  return (
    <BaseSetDepartmentFlyout
      {...props}
      flyoutHeadline={t('userDetail.setDepartmentCharge.setRightsHeadline')}
      hasOtherLeaderClass=""
      isLeaderClass="maintainer-leader-current"
      isMaintainerClass="maintainer-current"
      hasOtherLeaderText=""
      isLeaderText="Ihre Hauptverantwortung"
      isMaintainerText="Ausgewählt"
      onSelectDepartment={onSetDepartmentMaintainer}
    />
  );
};
