/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import InstrumentListItem from 'components/ListItems/InstrumentListItem';

const PackageInstrumentsList = observer(() => {
  const { appNavigationStore, instrumentStore } = useStores();

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.withoutMenu();
  }, [appNavigationStore]);

  appNavigationStore.useSubPageIdSetter('list');

  return (
    <div className="single_colum_content with_list">
      <div className="list_item_dropdown">
        {instrumentStore.fullPackageItem?.pack.instruments.map(instrument => (
          <InstrumentListItem key={instrument.instrumentId} instrument={instrument} />
        ))}
      </div>
    </div>
  );
});

export default PackageInstrumentsList;
