import Instructions from 'components/Instructions/Instructions';
import { GuideMaterialLikeFlatDTO } from 'dto/guide';
import { PackagingType } from 'dto/package';
import { GenericMultiTemplateItem } from 'modules/material/ListItems/Instrument/GenericMultiTemplateItem';
import { ListItemRendererType } from 'modules/material/ListItems/MaterialListItem';
import React from 'react';
import { InstrumentRendererParentType } from 'modules/material/ListItems/Instrument/InstrumentRendererParentType';
import { GuideMaterialCheckPrefixRenderer } from '../checkRenderer';
import './TemplateItem.css';

interface Props {
  guideMaterial: GuideMaterialLikeFlatDTO;
  prefix?: React.ReactElement;
  ListItemRenderer: ListItemRendererType<GuideMaterialLikeFlatDTO>;
  PackagePrefix?: GuideMaterialCheckPrefixRenderer;
  onClick: (parent: GuideMaterialLikeFlatDTO, id: { packageId?: string; instrumentId?: string }) => void;
}

export const TemplateItem = ({ guideMaterial, ListItemRenderer, prefix, PackagePrefix, onClick }: Props) => {
  if (!guideMaterial.template) {
    throw new Error('the guideMaterial must have a template');
  }
  if (guideMaterial.template.packagingType !== PackagingType.Multi) {
    throw new Error('the guideMaterial must have a multi-template');
  }

  const template = guideMaterial.template;

  return (
    <div className="template-item">
      <GenericMultiTemplateItem<InstrumentRendererParentType<GuideMaterialLikeFlatDTO>>
        level={0}
        ListItemRenderer={ListItemRenderer}
        parentEntity={guideMaterial}
        template={template}
        InstructionRenderer={() => <Instructions notes={guideMaterial.notes} />}
        PackagePrefixRenderer={({ parent, pack }) =>
          PackagePrefix ? <PackagePrefix guideMaterialId={parent.guideMaterialId} packageId={pack.packageId} /> : <></>
        }
        amount={guideMaterial.amount}
        prefix={prefix}
        onClick={onClick}
        enablePrefixExpand
      />
    </div>
  );
};
