import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import Alert from 'components/Alert';
import { observer } from 'mobx-react';

export default observer(() => {
  const { errorStore } = useStores();

  const { t } = useTranslation('errors');

  return errorStore.currentError !== undefined ? (
    <Alert
      hide={errorStore.currentError === null}
      headline={t('defaultHeadline')}
      message={`${t('defaultMessage')} ${errorStore.currentError?.code !== undefined ? errorStore.currentError?.code : ''}${
        errorStore.currentError?.errorInstanceId !== undefined ? `-${errorStore.currentError?.errorInstanceId}` : ''
      }`}
      onClick={() => errorStore.removeCurrentError()}
      icon="images/icon_c-warning_64_red.svg"
    />
  ) : (
    <></>
  );
});
