import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { saveFile } from 'api/file';
import FlyoutContainer from 'components/FlyoutContainer';
import UploadFile from 'components/UploadFiles';
import CustomInput from 'components/CustomInput';
import { useStores } from 'util/mobx/stores';
import { CreateMaterialDTO } from 'dto/material';
import { ContentType } from 'dto/file';

const CreateSingleMaterialFlyout = observer(() => {
  const { t } = useTranslation('guideSingleMaterial');
  const { materialStore, domainStore, flyoutStore } = useStores();
  const [name, setName] = useState('');
  const [articleNumber, setArticleNumber] = useState('');
  const [imageId, setImageId] = useState('');

  const closePopUp = () => flyoutStore.setIsCreateSingleMaterialFlyoutOpen(false);

  useEffect(() => {
    setImageId('');
    setArticleNumber('');
    setName('');
  }, [flyoutStore.isCreateSingleMaterialFlyoutOpen]);

  const onSubmit = async () => {
    const material: CreateMaterialDTO = { manufacturerArticleNumber: articleNumber, name, pictureFileId: imageId || undefined };
    if (name && articleNumber) {
      await materialStore.createMaterial(material);
      closePopUp();
      materialStore.loadLatestManualMaterials(domainStore.currentDepartment.id);
    }
  };
  const isAllowedToSubmit = () => {
    if (domainStore.isMobile && name && articleNumber && imageId) {
      return true;
    }
    if (name && articleNumber) return true;
    return false;
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_new_single_material.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={flyoutStore.isCreateSingleMaterialFlyoutOpen}
      closePopUp={closePopUp}
      cancelLabel={t('createMaterial.button.cancel')}
      onSubmit={onSubmit}
      submitLabel={t('createMaterial.button.submit')}
      isAllowedToSubmit={isAllowedToSubmit()}
      title={t('createMaterial.headline')}
    >
      <div className="flyout_box">
        <div>{t('createMaterial.label.materialName')}</div>
        <CustomInput onChange={event => setName(event.target.value)} placeholder={t('createMaterial.label.materialNamePlaceholder')} />
      </div>
      <div className="flyout_box">
        <div>{t('createMaterial.label.articleNumber')}</div>
        <CustomInput
          onChange={event => setArticleNumber(event.target.value)}
          placeholder={t('createMaterial.label.articleNumberPlaceholder')}
        />
      </div>
      <div className="flyout_box">
        <div>{t('createMaterial.label.media')}</div>
        <UploadFile
          setImageId={setImageId}
          cameraImageButtonDescription={t('createMaterial.imageButton')}
          cameraImageButtonDescriptionAlready={t('createMaterial.imageButtonImageAlreadyThere')}
          saveFile={saveFile}
          picturesOnly
          accept={[ContentType.Picture]}
        />
      </div>
    </FlyoutContainer>
  );
});
export default CreateSingleMaterialFlyout;
