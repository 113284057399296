import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import { observer } from 'mobx-react';
import AppLayout from 'components/AppLayout';
import { AppBarPageIcon, AppBarHeaderText } from 'components/AppLayout/AppBar';
import SurgeryPlannerDrawer from 'pages/flyouts/SurgeryPlannerDrawer';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import Page from 'components/Page';
import Safe from 'components/Safe';
import { ChatConfigurationTypes } from 'components/Chat/Chat';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import SurgeryBriefing from 'pages/surgeryBriefing';
import SelectGuideFlyout from 'pages/surgeryBriefing/SelectGuideFlyout';
import ProcedureHistoryFlyout from 'pages/surgeryBriefing/SurgeryChatAndHistory/ProcedureHistoryFlyout';
import SurgeryNavigation from './SurgeryNavigation';
import './Surgery.css';

type Props = RouteComponentProps & {
  location: {
    pathname: string;
    search: string;
  };
};

const Surgery = withRouter(
  observer(({ location }: Props) => {
    const { domainStore } = useStores();
    const { t } = useTranslation('surgery');

    const { shortName: locationShortName } = domainStore.currentLocation;
    const { shortName: functionAreaShortName } = domainStore.currentFunctionalArea;

    const surgeryId = qs.parse(location.search).surgeryId as string;

    return (
      <AppLayout
        appBarIcon={<AppBarPageIcon src="images/image_operationen.jpg" />}
        drawers={<SurgeryPlannerDrawer />}
        flyout={
          <>
            <SelectGuideFlyout />
            <ProcedureHistoryFlyout />
          </>
        }
        boardFlyout={<BoardFlyout />}
        appBarChildren={
          <AppBarHeaderText
            onClick={() => domainStore.setShowSwitchDomainFlyout(true)}
            headline={t('pageTitle', { functionalAreaShortName: domainStore.currentFunctionalArea.shortName })}
            subHeadline={`${locationShortName} | ${functionAreaShortName}`}
          />
        }
      >
        <Page
          disableBackButton
          navigationClassName="in_operation surgery-navigation"
          navigation={<SurgeryNavigation />}
          idPrefix="briefing" // reused ID from webflow
          gridClass="grid_main"
          wrapperContentClass="in_operationen in-operation"
          chatConfigurationType={ChatConfigurationTypes.briefing}
        >
          <SurgeryBriefing surgeryId={surgeryId} />
        </Page>
      </AppLayout>
    );
  })
);

export default () => (
  <Safe>
    <Surgery />
  </Safe>
);
