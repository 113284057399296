import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import NavSectionButton from 'components/NavSection/NavSectionButton';
import { useLocation } from 'react-router-dom';

interface Props {
  packageId: string;
}

const PackageNavSection = observer(({ packageId }: Props) => {
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;
  const { t } = useTranslation('packages');

  return (
    <>
      <NavSectionButton
        icon="icon_single-content-02_16"
        label={t('subMenu.overview')}
        routePath={`/single-instrument/overview?packageId=${packageId}`}
        currentPath={currentPath}
      />
      <NavSectionButton
        icon="icon_data-table_16"
        label={t('subMenu.baseData')}
        routePath={`/single-instrument/detail?packageId=${packageId}`}
        currentPath={currentPath}
      />
      <NavSectionButton
        icon="icon_bulb-62_16"
        label={t('subMenu.knowledge')}
        routePath={`/single-instrument/content?packageId=${packageId}`}
        currentPath={currentPath}
      />
      <NavSectionButton
        icon="icon_globe_16"
        label={t('subMenu.globalFunctions')}
        routePath={`/single-instrument/functions?packageId=${packageId}`}
        currentPath={currentPath}
      />
      <NavSectionButton
        icon="icon_pin-3_16"
        label={t('subMenu.locations')}
        routePath={`/single-instrument/locations?packageId=${packageId}`}
        currentPath={currentPath}
      />
    </>
  );
});

export default PackageNavSection;
