import { GroupMaterialDTO } from 'dto/groupMaterial';
import { SetGroupWithTagsDTO } from 'dto/setGroup';
import { TagDTO } from 'dto/tag';
import React, { useContext } from 'react';

export type OnGroupMaterialClick = (groupMaterial: GroupMaterialDTO, specific?: { packageId?: string; materialId?: string }) => void;

export interface TagParent {
  setGroupId?: string;
  groupMaterialId?: string;
}

export interface MaterialSetListState {
  groupType: string;

  /**
   * This can be used to check if the "checks" are currently shown.
   * Use it to disable certain functionality
   */
  checksEnabled: boolean;
  onReload?: () => Promise<void>;
  lazyLoadScrollContainer?: string;

  onTagCreate?: (parentId: TagParent) => (tagName: string) => Promise<TagDTO>;
  onTagUpdate?: (tag: TagDTO) => Promise<TagDTO>;
  onTagDelete?: (tagId: string) => Promise<boolean>;

  onSetGroupUpdate?: (setGroup: SetGroupWithTagsDTO) => void;
  onSetGroupDelete?: (setGroupId: string) => void;

  onClickItem?: OnGroupMaterialClick;
  onDeleteGroupMaterial?: (setGroupId: string, groupMaterialId: string) => void;

  readonly: boolean;
}

export const MaterialSetListContext = React.createContext<MaterialSetListState | undefined>({
  checksEnabled: false,
  groupType: '',
  readonly: true
});

export function useMaterialSetListContext() {
  const state = useContext(MaterialSetListContext);
  if (state === undefined) {
    throw new Error('use of MaterialSetListContext without provider');
  }

  return state;
}
