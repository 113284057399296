import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import ScrollPositionManager from 'components/ScrollPositionManager';
import DepartmentLead from 'components/DepartmentLead';
import CategoryList from './CategoryList';
import MaterialList from './MaterialList';
import CreateNewMaterialRightMenu from './SingleMaterialsRightMenu';

const SingleMaterials = observer(() => {
  const { materialStore, appNavigationStore, domainStore, loadingStore } = useStores();

  useEffect(() => {
    materialStore.loadLatestManualMaterials(domainStore.currentDepartment.id);
  }, [materialStore, domainStore.currentDepartment.id]);

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <CreateNewMaterialRightMenu />;
    });
  }, [appNavigationStore]);

  return (
    <div className="w-layout-grid grid_colum_content pb-80-desktop">
      <div id="grid-guides-colum_left" className="colum_left">
        <DepartmentLead />
        <div className="line" />
        <CategoryList />
      </div>
      {!loadingStore.isLoading && (
        <ScrollPositionManager
          scrollKey="grid-guides-colum-right"
          id="grid-guides-colum_right"
          className="colum_right materials colum-scroll"
          style={{ gridColumnStart: '2' }}
        >
          <MaterialList materials={materialStore.latestMaterials} />
        </ScrollPositionManager>
      )}
    </div>
  );
});

export default SingleMaterials;
