import { updateMaterial } from 'api/material';
import MaterialValueTags from 'components/MaterialValueTags/MaterialValueTags';
import { InstrumentDTO } from 'dto/instrument';
import React, { useCallback } from 'react';
import { useStores } from 'util/mobx/stores';
import { ItemPicture } from '../ItemPicture';
import { ListItemRendererProps } from '../MaterialListItem';

interface Props<T> {
  parentEntity: T;
  ListItemRenderer: React.FC<ListItemRendererProps<T>>;

  level?: number;
  instrument: InstrumentDTO;

  MenuRenderer?: React.FC<{ parent: T; specificId: { templateId?: string; packageId?: string; instrumentId?: string } }>;

  onClick?: (parent: T, id: { packageId?: string; materialId?: string }) => void;
  onReload?: () => Promise<void>;
}

export function GenericInstrumentItem<T extends { currentPackageId?: string; currentInstrumentId?: string }>({
  parentEntity,
  ListItemRenderer,
  level = 2,
  instrument,
  MenuRenderer,
  onClick,
  onReload
}: Props<T>) {
  const { loadingStore } = useStores();

  const handleInstrumentClick = useCallback(() => {
    if (!onClick) {
      return;
    }
    onClick(parentEntity, { materialId: instrument.material.materialId });
  }, [instrument.material.materialId, onClick, parentEntity]);

  const handleUpdateFile = useCallback(
    async (fileId: string) => {
      await loadingStore.withLoadingBar(() => updateMaterial({ materialId: instrument.material.materialId, pictureFileId: fileId }));
      if (onReload) {
        onReload();
      }
    },
    [instrument.material.materialId, loadingStore, onReload]
  );

  return (
    <ListItemRenderer
      parentEntity={{ ...parentEntity, currentInstrumentId: instrument.instrumentId }}
      level={level}
      noExpandIconSpace
      amount={instrument?.amount || 0}
      name={instrument.material.name}
      picture={<ItemPicture picture={instrument.material} onUpdateFile={handleUpdateFile} />}
      onClick={handleInstrumentClick}
      end={<MaterialValueTags material={instrument.material} />}
      menu={MenuRenderer && <MenuRenderer parent={parentEntity} specificId={{ instrumentId: instrument.instrumentId }} />}
    />
  );
}
