import React from 'react';
import { Field } from 'formik';
import './Input.css';

interface Props {
  placeholder?: string;
  name: string;
  viewOnly: boolean;
  type?: string;
  className?: string;
}

const Input = ({ name, placeholder, viewOnly, className = 'input_field top-margin', ...rest }: Props) => {
  return (
    <div className={`${className} ${viewOnly ? 'without_input' : 'with-input'}`}>
      <Field
        placeholder={!viewOnly && placeholder}
        style={{ width: '100%', outline: 'none', border: '0', color: 'black' }}
        name={name}
        disabled={viewOnly}
        maxLength={255}
        {...rest}
      />
      {!viewOnly && (
        <img src="images/icon_s-edit_16.svg" style={{ filter: 'saturate(0%) brightness(167%)' }} alt="" className="image_write" />
      )}{' '}
    </div>
  );
};
export default Input;
