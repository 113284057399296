import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';

import FlyoutContainer from 'components/FlyoutContainer';
import GenderIcon from 'components/GenderIcon';
import Picture from 'components/Picture';

const DeleteSurgeryFlyout = observer(() => {
  const { t } = useTranslation('surgery');
  const { surgeryStore, flyoutStore } = useStores();
  const closePopUp = () => surgeryStore.setIsDeleteSurgeryFlyoutOpen(false);
  const onSubmit = async () => {
    if (surgeryStore.selectedSurgeryToEdit?.surgeryId) {
      await surgeryStore.deleteSurgery(surgeryStore.selectedSurgeryToEdit.surgeryId);
    }
    closePopUp();
  };

  const handleAddImagesToView = () => {
    if (surgeryStore?.selectedSurgeryToEdit?.patientPicture) {
      flyoutStore.setImagesToView(surgeryStore?.selectedSurgeryToEdit?.patientPicture.picture);
    }
  };
  return (
    <FlyoutContainer
      icon={<img src="images/icon_delete_prompt.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={surgeryStore.isDeleteSurgeryFlyoutOpen}
      closePopUp={closePopUp}
      cancelLabel={t('deleteSurgeryFlyout.buttons.cancel')}
      onSubmit={onSubmit}
      submitLabel={t(`deleteSurgeryFlyout.buttons.submit`)}
      isAllowedToSubmit
      title={t(`deleteSurgeryFlyout.headline`)}
      warning
      iosMarginTop
    >
      <div className="flyout_box">
        <div className="h4">{t(`deleteSurgeryFlyout.description`)}</div>
        {surgeryStore.selectedSurgeryToEdit ? (
          <div className="div-block-111">
            <div className="image_wrapper_50" onClick={handleAddImagesToView}>
              <div className="image_border image-border" />
              <Picture src={surgeryStore.selectedSurgeryToEdit.patientPicture?.pictureThumbnail} />
            </div>
            <div className="operation_info">
              <div className="operation_detail with_hover">
                <div className="patient_info_wrapper">
                  <div>
                    {surgeryStore.selectedSurgeryToEdit.lastName && surgeryStore.selectedSurgeryToEdit.firstName && (
                      <>
                        {surgeryStore.selectedSurgeryToEdit.lastName}, {surgeryStore.selectedSurgeryToEdit.firstName}
                      </>
                    )}
                    {surgeryStore.selectedSurgeryToEdit.lastName &&
                      surgeryStore.selectedSurgeryToEdit.firstName &&
                      surgeryStore.selectedSurgeryToEdit.birthday && <> | </>}
                    {surgeryStore.selectedSurgeryToEdit.birthday && (
                      <>*{moment(surgeryStore.selectedSurgeryToEdit.birthday).format('DD.MM.YYYY')}</>
                    )}
                  </div>
                  <GenderIcon gender={surgeryStore.selectedSurgeryToEdit.gender} />
                </div>
                <div>{surgeryStore.selectedSurgeryToEdit.name}</div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </FlyoutContainer>
  );
});
export default DeleteSurgeryFlyout;
