import React from 'react';
import { observer } from 'mobx-react';

import FlyoutContainer from 'components/FlyoutContainer';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import PersonalizedHistoryItem from 'components/History/PersonalizedHistoryItem';
import { HistoryActionType, HistoryDTO } from 'dto/history';
import { TFunction } from 'i18next';
import GuideHistoryEventIcons, { GuideFields } from './GuideHistoryEventIcons';

const getHistoryDescription = (translate: TFunction, item: HistoryDTO, field?: string) => {
  if (item.action === HistoryActionType.create) {
    return translate('guideHistoryFlyout.textCreate');
  }
  if (item.action === HistoryActionType.delete) {
    return translate('guideHistoryFlyout.textDelete');
  }
  if (item.action === HistoryActionType.update && field === GuideFields.category) {
    return translate('guideHistoryFlyout.textUpdateCategory', { oldValue: item.previousDataValue, newValue: item.dataValue });
  }
  if (item.action === HistoryActionType.update && field === GuideFields.amount) {
    return translate('guideHistoryFlyout.textUpdateAmount', { oldValue: item.previousDataValue, newValue: item.dataValue });
  }
  return '';
};

const GuideHistoryFlyout = observer(() => {
  const { t } = useTranslation('guideMaterials');
  const { guideStore, flyoutStore } = useStores();

  const closePopUp = () => {
    flyoutStore.setIsHistoryFlyoutOpen(false);
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_history.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={flyoutStore.isHistoryFlyoutOpen}
      closePopUp={closePopUp}
      cancelLabel={t('guideHistoryFlyout.button.conclude')}
      title={t('guideHistoryFlyout.headline')}
    >
      <div className="flyout_scroll_wrapper">
        <div className="flyout_box">
          <div>{t('guideHistoryFlyout.label.processingHistory')}</div>
          <div className="menu_list">
            {guideStore.history?.map(historyItem => (
              <PersonalizedHistoryItem
                icon={<GuideHistoryEventIcons action={historyItem.action} field={historyItem.field} />}
                item={historyItem}
                description={getHistoryDescription(t, historyItem, historyItem.field)}
                key={historyItem.historyId}
              />
            ))}
          </div>
        </div>
      </div>
    </FlyoutContainer>
  );
});

export default GuideHistoryFlyout;
