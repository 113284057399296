/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import SingleInstrumentDetailForm from './SingleInstrumentDetailForm';

const SingleInstrumentDetail = observer(() => {
  const { instrumentStore, appNavigationStore } = useStores();

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.withoutMenu();
  }, [appNavigationStore]);

  appNavigationStore.useSubPageIdSetter('detail');

  if (instrumentStore.fullPackageItem) {
    return <SingleInstrumentDetailForm packageDetail={instrumentStore.fullPackageItem} />;
  }
  return null;
});

export default SingleInstrumentDetail;
