import React from 'react';
import { Gender } from 'dto/user';

interface Props {
  gender?: Gender;
  className?: string;
  isGrey?: boolean;
}

const GenderIcon = ({ gender, className, isGrey = false }: Props) => {
  if (gender === Gender.Male) {
    return (
      <img
        src={isGrey ? 'images/icon_man-20_16_grey.svg' : 'images/icon_man-20_16_blue.svg'}
        alt=""
        className={`image_patient_icon ${className}`}
      />
    );
  }
  if (gender === Gender.Female) {
    return (
      <img
        src={isGrey ? 'images/icon_woman-21_grey.svg' : 'images/icon_woman-21_16_blue.svg'}
        alt=""
        className={`image_patient_icon ${className}`}
      />
    );
  }
  if (gender === Gender.Diverse) {
    return (
      <img
        src={isGrey ? 'images/icon_divers_16_grey.svg' : 'images/icon_divers_16_blue.svg'}
        alt=""
        className={`image_patient_icon ${className}`}
      />
    );
  }
  return null;
};

export default GenderIcon;
