import { ErrorCode } from 'dto/errorCodes';

export class ApiError extends Error {
  code?: ErrorCode;

  errorInstanceId?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reason?: any;

  stack?: string;

  constructor(message?: string | undefined, code?: ErrorCode, errorInstanceId?: string, stack?: string) {
    super(`${message} ${code}-${errorInstanceId}`);
    this.code = code !== undefined ? code : ErrorCode.UnknownError;
    this.errorInstanceId = errorInstanceId !== undefined ? errorInstanceId : '0';
    this.stack = stack;
  }
}
