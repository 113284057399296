import React, { Fragment } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { getItemStyle } from 'util/dragAndDrop';
import { SurgeryProcedureDTO } from 'dto/surgeryGuideProcedure';
import { observer } from 'mobx-react';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import SurgeryProcedureListItem from '../SurgeryProcedureListItem';

interface Props extends OptionalLazyLoadProps {
  procedures: SurgeryProcedureDTO[];
  hover?: boolean;
  chapterId: string;
}

const SurgeryProcedureList = observer(({ procedures, lazyLoadScrollContainer }: Props) => {
  const procedureList = (procedure: SurgeryProcedureDTO) => (
    <SurgeryProcedureListItem lazyLoadScrollContainer={lazyLoadScrollContainer} procedure={procedure} />
  );
  return (
    <>
      {procedures &&
        procedures.map((procedure, index) => (
          <Fragment key={procedure.surgeryGuideProcedureId}>
            <Draggable key={procedure.surgeryGuideProcedureId} draggableId={procedure.surgeryGuideProcedureId} index={index}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                  {procedureList(procedure)}
                </div>
              )}
            </Draggable>
          </Fragment>
        ))}
    </>
  );
});

export default SurgeryProcedureList;
