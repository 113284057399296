import React from 'react';
import ImageCircle from 'components/ImageCircle';
import { UserDTO } from 'dto/user';

interface Props {
  user?: UserDTO;
  picture?: string;
}

const GuideAppBarIcon = (props: Props) => (
  <ImageCircle highlight={false} user={props.user} builtinPictureName={props.picture} size="40" className="title_icon" marginRight={0} />
);

export default GuideAppBarIcon;
