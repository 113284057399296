/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { ContentType } from 'dto/file';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import DownloadButton from 'components/DownloadButton';
import { useStores } from 'util/mobx/stores';

interface Props {
  type: ContentType;
  title?: string;
  isSmall?: boolean;
  file: ContentElementMediaDTO;
  showDownloadButton?: boolean;
  noPreview?: boolean;
}

const DOCUMENT_ICONS = {
  pdf: 'images/icon_pdf_48.svg',
  xsl: 'images/icon_xlsx_48.svg',
  word: 'images/icon_doc_48.svg',
  default: 'images/todo.svg'
};

const getImageByDocumentType = (type: ContentType) => {
  switch (type) {
    case ContentType.PDFDocument:
      return DOCUMENT_ICONS.pdf;
    case ContentType.ExcelDocument:
      return DOCUMENT_ICONS.xsl;
    case ContentType.WordDocument:
      return DOCUMENT_ICONS.word;
    default:
      return DOCUMENT_ICONS.default;
  }
};

const Document = ({ type, title = '', isSmall = false, file, showDownloadButton = true, noPreview = false }: Props) => {
  const { flyoutStore } = useStores();

  // PDF Documents
  if (file.contentType === ContentType.PDFDocument) {
    if (isSmall) {
      return (
        <>
          {showDownloadButton && !noPreview && <DownloadButton file={file} />}
          <div
            className="data_post data-post"
            onClick={e => {
              if (!noPreview) {
                flyoutStore.setPdfToView(file);
              }
              e.stopPropagation();
            }}
          >
            <div className="b_box_border" />
            <img
              src={getImageByDocumentType(type)}
              alt=""
              className={isSmall ? 'image_data' : 'image_file'}
              onClick={e => {
                if (!noPreview) {
                  flyoutStore.setPdfToView(file);
                }
                e.stopPropagation();
              }}
            />
            <div>{title}</div>
          </div>
        </>
      );
    }
    return (
      <>
        {showDownloadButton && !noPreview && <DownloadButton file={file} />}
        <img
          src={getImageByDocumentType(type)}
          alt=""
          className={isSmall ? 'image_data' : 'image_file'}
          onClick={e => {
            if (!noPreview) {
              flyoutStore.setPdfToView(file);
            }
            e.stopPropagation();
          }}
        />
        <div
          className="document-title"
          onClick={e => {
            if (!noPreview) {
              flyoutStore.setPdfToView(file);
            }
            e.stopPropagation();
          }}
        >
          {title}
        </div>
      </>
    );
  }

  // Other Documents
  if (isSmall) {
    return (
      <>
        {showDownloadButton && !noPreview && <DownloadButton file={file} />}
        <div className="data_post data-post">
          <div className="b_box_border" />
          <img src={getImageByDocumentType(type)} alt="" className={isSmall ? 'image_data' : 'image_file'} />
          <div className="document-title">{title}</div>
        </div>
      </>
    );
  }
  return (
    <>
      {showDownloadButton && !noPreview && <DownloadButton file={file} />}
      <img src={getImageByDocumentType(type)} alt="" className={isSmall ? 'image_data' : 'image_file'} />
      <div className="document-title">{title}</div>
    </>
  );
};

export default (props: Props) => {
  if (props.showDownloadButton || !props.noPreview) {
    return (
      <DownloadButton file={props.file} wrapChildrenWithDownload={props.file.contentType !== ContentType.PDFDocument}>
        <div className="div-block-51">
          <Document {...props} />
        </div>
      </DownloadButton>
    );
  }
  return <Document {...props} />;
};
