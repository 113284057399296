import { observable, runInAction, action, computed } from 'mobx';
import moment from 'moment';
import { RoomSurgeriesDTO, CreateSurgeryDTO, UpdateSurgeryDTO, DateDTO, UpdateSortSurgeryDTO, SurgeryDTO } from 'dto/surgery';
import { BriefingHistoryDTO, HistoryDTO, SurgeryGuideHistoryDTO } from 'dto/history';
import {
  getSurgeries,
  createSurgery,
  updateSurgery,
  getSurgeryRooms,
  deleteSurgery,
  getSurgeryBriefing,
  reorderSurgeries
} from 'api/surgery';
import { BriefingDTO } from 'dto/briefing';
import { addSurgeryGuide, deleteSurgeryGuide, getBriefingHistory } from 'api/briefing';
import { parseFromDateTime, parseToDate } from 'util/date';
import { SurgeryRoomDTO } from 'dto/surgeryRoom';
import { PictureDTO } from 'dto/file';
import LoadingStore from './loadingStore';
import DomainStore from './domainStore';

export interface SurgeryFormValues extends CreateSurgeryDTO {
  surgeryId?: string;
  patientPicture?: PictureDTO;
}

export enum SurgeryTabs {
  chat = 'chat',
  history = 'history'
}

export default class SurgeryStore {
  @observable
  private domainStore: DomainStore;

  @observable
  private loadingStore: LoadingStore;

  @observable
  surgeries: RoomSurgeriesDTO[] = [];

  @observable
  rooms: SurgeryRoomDTO[] = [];

  @observable
  isPlannerDrawerOpen = false;

  @observable
  isSurgeryFormFlyoutOpen = false;

  @observable
  selectedSurgeryToEdit?: SurgeryFormValues;

  @observable
  selectedSurgeryBriefing?: BriefingDTO;

  @observable
  selectedRoom?: SurgeryRoomDTO;

  @observable
  surgeriesInSelectedRoom?: SurgeryDTO[];

  @observable
  selectedFilterDate: DateDTO = parseToDate(moment());

  @observable
  briefingHistory?: BriefingHistoryDTO;

  @observable
  isDeleteSurgeryFlyoutOpen = false;

  @observable
  isSelectGuideFlyoutOpen = false;

  @observable
  isHistoryProcedureFlyoutOpen = false;

  @observable
  selectedHistoryItem?: HistoryDTO;

  @observable
  selectedHistoryGuideItem?: SurgeryGuideHistoryDTO;

  constructor(domainStore: DomainStore, loadingStore: LoadingStore) {
    this.domainStore = domainStore;
    this.loadingStore = loadingStore;
  }

  async loadSurgeries() {
    const surgeries: RoomSurgeriesDTO[] = await this.loadingStore.withLoadingBar(() => {
      if (this.selectedFilterDate) {
        return getSurgeries({
          functionalAreaId: this.domainStore.currentFunctionalArea.id,
          includeStatistic: true,
          ...this.selectedFilterDate
        });
      }
      return Promise.resolve([]);
    });

    runInAction(() => {
      this.surgeries = surgeries;
    });
  }

  async loadSurgeriesByDate(date: DateDTO) {
    const surgeries: RoomSurgeriesDTO[] = await this.loadingStore.withLoadingBar(() => {
      return getSurgeries({
        functionalAreaId: this.domainStore.currentFunctionalArea.id,
        includeStatistic: true,
        ...date
      });
    });

    runInAction(() => {
      this.surgeries = surgeries;
    });
  }

  async loadDataWithPreselectedBriefing(surgeryId: string) {
    const surgeryBriefing = await getSurgeryBriefing({
      functionalAreaId: this.domainStore.currentFunctionalArea.id,
      surgeryId,
      includeStatistic: true
    });
    const room = this.rooms.find(r => r.surgeryRoomId === surgeryBriefing.surgery.surgeryRoomId);
    const localTimeDateMoment = parseFromDateTime(surgeryBriefing.surgery.surgeryDate, surgeryBriefing.surgery.surgeryTimeStart);
    if (localTimeDateMoment) {
      await this.loadSurgeriesByDate(parseToDate(localTimeDateMoment));
    }

    runInAction(() => {
      this.selectedSurgeryBriefing = surgeryBriefing;
      this.selectedRoom = room;
      this.isPlannerDrawerOpen = false;
      if (localTimeDateMoment) {
        this.selectedFilterDate = parseToDate(localTimeDateMoment);
      }
      this.surgeriesInSelectedRoom = this.surgeries.find(s => s.room.surgeryRoomId === surgeryBriefing.surgery.surgeryRoomId)?.surgeries;
    });
  }

  async loadRooms() {
    const rooms: SurgeryRoomDTO[] = await this.loadingStore.withLoadingBar(() =>
      getSurgeryRooms({
        functionalAreaId: this.domainStore.currentFunctionalArea.id
      })
    );
    runInAction(() => {
      this.rooms = rooms;
    });
  }

  async addSurgeryGuide(sourceGuideId: string) {
    /** using with laodingStore.withLoadingBar instead of withFlyoutLoadingBar here... even
     * tho this is in a Flyout because when adding a Surgery the flyout closes and the loading
     * bar is not visible
     */
    await this.loadingStore.withLoadingBar(async () => {
      if (!this.selectedSurgeryBriefing) {
        return undefined;
      }
      return addSurgeryGuide({ sourceGuideId, briefingId: this.selectedSurgeryBriefing.briefingId });
    });
    this.refreshBriefing();
    this.loadSurgeries();
    this.refreshSurgeriesInSelectedRoom();
  }

  async deleteSurgeryGuide(surgeryGuideId: string) {
    await this.loadingStore.withLoadingBar(() => {
      return deleteSurgeryGuide({ surgeryGuideId });
    });
    this.refreshBriefing();
    this.refreshSurgeriesInSelectedRoom();
  }

  async loadBriefingHistory(briefingId: string) {
    const briefingHistory = await this.loadingStore.withLoadingBar(() => getBriefingHistory({ briefingId }));
    runInAction(() => {
      this.briefingHistory = briefingHistory;
    });
  }

  @computed
  get roomsDropdownOptions() {
    return this.rooms.map(room => ({
      label: room.name,
      value: room.surgeryRoomId
    }));
  }

  async createSurgery(surgery: CreateSurgeryDTO) {
    const createdSurgery = await this.loadingStore.withLoadingBar(() => createSurgery(surgery));
    await this.loadSurgeries();
    this.selectSurgeryAndRoom(createdSurgery.surgeryId);
    return createdSurgery.surgeryId;
  }

  async updateSurgery(surgery: UpdateSurgeryDTO) {
    await this.loadingStore.withLoadingBar(() => updateSurgery(surgery));
    this.loadSurgeries();
    this.refreshBriefing();
    this.refreshSurgeriesInSelectedRoom();
  }

  async deleteSurgery(surgeryId: string) {
    await this.loadingStore.withLoadingBar(() => deleteSurgery(surgeryId));
    if (surgeryId === this.selectedSurgeryBriefing?.surgery.surgeryId) {
      runInAction(() => {
        this.selectedSurgeryBriefing = undefined;
      });
    }
    this.loadSurgeries();
  }

  async selectSurgeryAndRoom(surgeryId: string) {
    const surgeryBriefing = await this.loadingStore.withLoadingBar(() =>
      getSurgeryBriefing({
        functionalAreaId: this.domainStore.currentFunctionalArea.id,
        surgeryId,
        includeStatistic: true
      })
    );
    const room = this.rooms.find(r => r.surgeryRoomId === surgeryBriefing.surgery.surgeryRoomId);
    runInAction(() => {
      this.selectedSurgeryBriefing = surgeryBriefing;
      this.selectedRoom = room;
      this.isPlannerDrawerOpen = false;
      this.surgeriesInSelectedRoom = this.surgeries.find(s => s.room.surgeryRoomId === surgeryBriefing.surgery.surgeryRoomId)?.surgeries;
    });
  }

  async refreshSurgeriesInSelectedRoom() {
    if (!this.selectedSurgeryBriefing && !this.selectedRoom) {
      return;
    }
    await this.loadSurgeries();
    runInAction(() => {
      this.surgeriesInSelectedRoom = this.surgeries.find(s => s.room.surgeryRoomId === this.selectedRoom?.surgeryRoomId)?.surgeries;
    });
  }

  async refreshBriefing() {
    const selectedSurgeryBriefing = await this.loadingStore.withLoadingBar(() => {
      if (!this.selectedSurgeryBriefing) return Promise.resolve(undefined);
      return getSurgeryBriefing({
        functionalAreaId: this.domainStore.currentFunctionalArea.id,
        surgeryId: this.selectedSurgeryBriefing.surgery.surgeryId,
        includeStatistic: true
      });
    });
    runInAction(() => {
      this.selectedSurgeryBriefing = selectedSurgeryBriefing;
    });
  }

  @action
  async reorderSurgeries(surgeries: RoomSurgeriesDTO[]) {
    this.surgeries = surgeries;

    const positions: UpdateSortSurgeryDTO[] = surgeries.map(surgeriesRoom => {
      const surgeryIds: string[] = surgeriesRoom.surgeries.map(s => s.surgeryId);

      return {
        surgeryRoomId: surgeriesRoom.room.surgeryRoomId,
        surgeryIds
      };
    });

    // send new order to server
    await this.loadingStore.withLoadingBar(() => reorderSurgeries(positions));
    this.refreshSurgeriesInSelectedRoom();
  }

  @action
  setIsPlannerDrawerOpen(isPlannerDrawerOpen: boolean) {
    if (isPlannerDrawerOpen) {
      this.loadSurgeries();
    }
    this.isPlannerDrawerOpen = isPlannerDrawerOpen;
  }

  @action
  setIsSurgeryFormFlyoutOpen(isSurgeryFormFlyoutOpen: boolean) {
    this.isSurgeryFormFlyoutOpen = isSurgeryFormFlyoutOpen;
    if (!isSurgeryFormFlyoutOpen) {
      this.selectedSurgeryToEdit = undefined;
    }
  }

  @action
  setIsDeleteSurgeryFlyoutOpen(isDeleteSurgeryFlyoutOpen: boolean) {
    this.isDeleteSurgeryFlyoutOpen = isDeleteSurgeryFlyoutOpen;
    if (!isDeleteSurgeryFlyoutOpen) {
      this.selectedSurgeryToEdit = undefined;
    }
  }

  @action
  setIsSelectGuideFlyoutOpen(isSelectGuideFlyoutOpen: boolean) {
    this.isSelectGuideFlyoutOpen = isSelectGuideFlyoutOpen;
  }

  @action
  selectSurgeryToEdit(selectedSurgeryToEdit: SurgeryFormValues) {
    this.selectedSurgeryToEdit = selectedSurgeryToEdit;
  }

  @action
  selectFilterDate(selectedFilterDate: DateDTO) {
    this.selectedFilterDate = selectedFilterDate;
  }

  @action
  clearSelectedSurgeryBriefing() {
    this.selectedSurgeryBriefing = undefined;
    this.selectedRoom = undefined;
  }

  getSelectedSurgeryIndex() {
    if (!this.surgeriesInSelectedRoom) {
      return undefined;
    }
    let selectedSurgeryIndex = 0;
    this.surgeriesInSelectedRoom.forEach((s, i) => {
      if (s.surgeryId === this.selectedSurgeryBriefing?.surgery.surgeryId) {
        selectedSurgeryIndex = i;
      }
    });
    return selectedSurgeryIndex;
  }

  @action
  goToNextSurgery() {
    const selectedSurgeryIndex = this.getSelectedSurgeryIndex();
    if (!this.surgeriesInSelectedRoom || !this.selectedSurgeryBriefing || !this.selectedRoom || selectedSurgeryIndex === undefined) {
      return;
    }
    if (this.surgeriesInSelectedRoom[selectedSurgeryIndex + 1]) {
      this.selectSurgeryAndRoom(this.surgeriesInSelectedRoom[selectedSurgeryIndex + 1].surgeryId);
    }
  }

  @action
  goToPreviousSurgery() {
    const selectedSurgeryIndex = this.getSelectedSurgeryIndex();
    if (!this.surgeriesInSelectedRoom || !this.selectedSurgeryBriefing || !this.selectedRoom || selectedSurgeryIndex === undefined) {
      return;
    }
    if (this.surgeriesInSelectedRoom[selectedSurgeryIndex - 1]) {
      this.selectSurgeryAndRoom(this.surgeriesInSelectedRoom[selectedSurgeryIndex - 1].surgeryId);
    }
  }

  @action
  setIsHistoryProcedureFlyoutOpen(status: boolean) {
    this.isHistoryProcedureFlyoutOpen = status;
    if (!status) {
      this.selectedHistoryItem = undefined;
      this.selectedHistoryGuideItem = undefined;
    }
  }

  @action
  selectHistoryItemAndGuide(selectedHistoryItem: HistoryDTO, selectedHistoryGuideItem: SurgeryGuideHistoryDTO) {
    this.selectedHistoryItem = selectedHistoryItem;
    this.selectedHistoryGuideItem = selectedHistoryGuideItem;
  }
}
