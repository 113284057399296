import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import StorageLocationDetails from '../StorageLocationDetails';

const StorageLocationDetailDrawer = observer(() => {
  const { storageLocationStore } = useStores();
  return (
    <CSSTransition in={storageLocationStore.isDetailDrawerOpen} timeout={500} classNames="drawer" unmountOnExit>
      <div className="op_etage_2_mobile op-etage-mobile">
        {storageLocationStore.selectedStorageLocation && (
          <StorageLocationDetails inDrawer selectedStorageLocation={storageLocationStore.selectedStorageLocation} />
        )}
        {storageLocationStore.selectedMaterialStorageLocation && (
          <StorageLocationDetails
            inDrawer
            isMaterialLikeView
            selectedStorageLocation={storageLocationStore.selectedMaterialStorageLocation}
          />
        )}
      </div>
    </CSSTransition>
  );
});

export default StorageLocationDetailDrawer;
