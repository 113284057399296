import React from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import ChangeChapterDropdownButton from '../ChangeChapterDropdownButton';
import ProcedureContentDrawerBox from '../ProcedureContentDrawerBox';

const SurgeryProcedureDrawerMainContent = observer(() => {
  const { surgeryGuideStore } = useStores();

  const group = surgeryGuideStore.selectedGroup;

  return (
    <div className="div-block-162 pilot-main-content">
      <div className="infobox_op-pilot">
        {group && <div>{group.chapter}</div>}

        <ChangeChapterDropdownButton />
      </div>
      <div className="div-block-163">
        <div className="line_op-pilot" />
        <div className="div-block-168 procedure-content-box">{group && <ProcedureContentDrawerBox group={group} />}</div>
      </div>
    </div>
  );
});

export default SurgeryProcedureDrawerMainContent;
