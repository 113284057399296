import React from 'react';
import { observer } from 'mobx-react';
import Button from 'components/Form/Button';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { useStores } from 'util/mobx/stores';
import VersionNumber from 'components/VersionNumber/VersionNumber';

const SettingsBackup = observer(() => {
  const { t } = useTranslation('settings');
  const { guideStore } = useStores();
  const exportGuides = async () => {
    const data = await guideStore.exportAllGuides();
    if (data) {
      saveAs(data?.url, data?.fileName);
    }
  };
  return (
    <>
      <div className="single_colum_content-copy" onClick={exportGuides}>
        <Button className="btn_msg_send">{t('backup.export')}</Button>
      </div>
      <VersionNumber type="mobile" />
    </>
  );
});

export default SettingsBackup;
