import { useInvalidateAnyGuide } from 'api/invalidate';
import { reorderSurgeryGuideMaterialsV2 } from 'api/surgeryGuide';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';
import { GuideMaterialLikeFlatDTO, UpdateSortGuideMaterialsV2DTO } from 'dto/guide';
import { observer } from 'mobx-react';
import { GuideMaterialList } from 'modules/guideMaterial';
import { CategorySurgeryMaterialList } from 'modules/guideMaterial/CategoryMaterialList';
import React, { useCallback, useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import { useInvalidateUsedSurgeryGuideMaterials } from 'api/surgeryGuideHooks';
import { SurgeryGuideMaterialRightMenu } from './SurgeryGuideMaterialRightMenu';

interface Props extends OptionalLazyLoadProps {
  surgeryGuideId: string;
}

export const SurgeryGuideMaterialsV2 = observer(({ lazyLoadScrollContainer, surgeryGuideId }: Props) => {
  const { guideDetailDrawerStore, appNavigationStore, loadingStore, domainStore } = useStores();
  const invalidateGuide = useInvalidateAnyGuide();

  useEffect(() => {
    guideDetailDrawerStore.enableSettings();
    return () => {
      guideDetailDrawerStore.disableSettings();
    };
  });

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <SurgeryGuideMaterialRightMenu />;
    });
  }, [appNavigationStore]);

  appNavigationStore.useSubPageIdSetter('materials');

  const invalidateUsedMaterial = useInvalidateUsedSurgeryGuideMaterials();
  const handleGuideMaterialClick = useCallback(
    (guideMaterial: GuideMaterialLikeFlatDTO, specific) => {
      guideDetailDrawerStore.openWithSurgeryGuideMaterial(guideMaterial.guideMaterialId, specific, false, true);
    },
    [guideDetailDrawerStore]
  );

  const handleGuideMaterialReorder = useCallback(
    async (reorder: UpdateSortGuideMaterialsV2DTO) => {
      await loadingStore.withLoadingBar(async () => {
        await reorderSurgeryGuideMaterialsV2({
          guideMaterialCategoryId: reorder.guideMaterialCategoryId,
          index: reorder.index,
          surgeryGuideId: reorder.guideId,
          surgeryGuideMaterialId: reorder.guideMaterialId
        });
      });
    },
    [loadingStore]
  );

  useEffect(() => {
    return () => invalidateUsedMaterial();
  }, [invalidateUsedMaterial]);

  return (
    <div className="single_colum_content">
      <GuideMaterialList
        CategoryMaterialListElement={CategorySurgeryMaterialList}
        functionalAreaId={domainStore.currentFunctionalArea.id}
        lazyLoadScrollContainer={lazyLoadScrollContainer}
        onGuideMaterialClick={handleGuideMaterialClick}
        onReorder={handleGuideMaterialReorder}
        onReload={invalidateGuide}
        guideLikeId={surgeryGuideId}
      />
    </div>
  );
});
