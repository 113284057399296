import React from 'react';
import LinkWrapper from 'components/LinkWrapper';
import { useStores } from 'util/mobx/stores';
import './AppMenuPrimaryButton.css';
import Button from 'components/Form/Button';

interface Props {
  icon: string;
  label: string;
  routePath?: string;
  currentPath?: string;
  additionalPathsOfGroup?: string[];
  onClick?: () => void;
  active?: boolean;
}

const iconUrl = (iconName: string) => `images/${iconName}.svg`;

const AppMenuPrimaryButton = ({ icon, label, routePath, currentPath, additionalPathsOfGroup, onClick, active }: Props) => {
  const { appNavigationStore } = useStores();

  const onClickHandler = () => {
    if (onClick) onClick();
    appNavigationStore.closeMenu();
  };
  const isActive = () => {
    if (active) {
      return true;
    }
    if (routePath && currentPath && routePath === currentPath) {
      return true;
    }
    if (additionalPathsOfGroup && additionalPathsOfGroup.includes(currentPath || '')) {
      return true;
    }
    if (routePath && currentPath && routePath.includes(currentPath)) {
      return true;
    }
    return false;
  };

  const className = isActive() ? 'icon_wrapper active' : 'icon_wrapper';

  const renderButton = () => {
    return (
      <Button className="btn_main btn-main w-inline-block" onClick={onClickHandler}>
        <div className={className}>
          <img src={iconUrl(icon)} alt="" />
        </div>
        <div>{label}</div>
      </Button>
    );
  };

  if (routePath) {
    return <LinkWrapper routePath={routePath}>{renderButton()}</LinkWrapper>;
  }
  return renderButton();
};

export default AppMenuPrimaryButton;
