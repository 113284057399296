import { useQuery } from '@tanstack/react-query';
import { LoadingType } from 'stores/loadingStore';
import { useStores } from 'util/mobx/stores';
import { ApiQueryOptions } from './query';
import { getGroupMaterials } from './groupMaterial';

export const useGetGroupMaterials = (setGroupId: string, options: ApiQueryOptions = {}) => {
  const { loadingStore } = useStores();
  const { loadingType, ...queryOptions } = options;
  return useQuery({
    queryKey: ['material-like', 'material-set', 'set-group', { setGroupId }],
    queryFn: async () => {
      return loadingStore.withSpecificLoadingBar(loadingType || LoadingType.DEFAULT, () => getGroupMaterials(setGroupId));
    },
    ...queryOptions
  });
};
