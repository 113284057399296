import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './AddTag.css';

interface Props {
  onAddTagInputBlur: (tagName: string) => void;
}

const AddTag = ({ onAddTagInputBlur }: Props) => {
  const { t } = useTranslation('tags');
  const [isInputInFocus, setIsInputInFocus] = useState(false);
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [tagName, setTagName] = useState('');
  const onInputChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement;
    setTagName(target.value);
  };

  const onTagAdd = useCallback(
    (name: string) => {
      if (name) {
        onAddTagInputBlur(name);
      }
      setTagName('');
      setIsInputInFocus(false);
    },
    [onAddTagInputBlur]
  );

  const onFocusIn = () => setIsInputInFocus(true);
  const onFocusOut = async () => {
    onTagAdd(tagName);
  };

  const handleFinish = useCallback(
    (event?: React.SyntheticEvent) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      onTagAdd(tagName);
    },
    [onTagAdd, tagName]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleFinish();
      }
    },
    [handleFinish]
  );

  const onInputClick = (event: React.SyntheticEvent) => {
    setIsInputInFocus(true);
    event.stopPropagation();
  };

  useEffect(() => {
    if (inputRef.current && isInputInFocus) {
      inputRef.current.focus();
    }
  }, [isInputInFocus, tagName]);

  return (
    <div className={`material_note_add add-new-tag ${isInputInFocus ? 'in-focus' : ''}`}>
      <input
        ref={inputRef}
        onClick={onInputClick}
        value={tagName}
        onChange={onInputChange}
        onFocus={onFocusIn}
        onBlur={onFocusOut}
        type="text"
        className="txt_add txt-add"
        placeholder={t('createNew')}
        onKeyDown={onKeyDown}
      />
      <div className="div-block-109">
        <button type="button" style={{ padding: 0 }} onClick={handleFinish}>
          <img src="images/icon_c-add_12.svg" alt="" className="icon_edit" />
        </button>
      </div>
    </div>
  );
};

export default AddTag;
