import React from 'react';
import ListItemText from 'components/ListItemText';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';

interface Props {
  onSelect: (id: string) => void;
  selectedDepartmentId: string;
  groupType: string;
}

const FiltersByDepartment = observer(({ onSelect, selectedDepartmentId, groupType }: Props) => {
  const { t } = useTranslation(groupType);
  const { domainStore } = useStores();
  return (
    <div>
      <ListItemText text={t('filtersByDepartment.all')} isSelected={selectedDepartmentId === 'all'} onClick={() => onSelect('all')} />
      {domainStore.departmentsByCurrentFunctionArea?.departments.map(department => (
        <ListItemText
          text={department.name}
          key={department.departmentId}
          onClick={() => onSelect(department.departmentId)}
          isSelected={selectedDepartmentId === department.departmentId}
        />
      ))}
    </div>
  );
});

export default FiltersByDepartment;
