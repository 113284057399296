import React from 'react';
import './guideCheck.css';
import { UsedMaterialStatus } from 'dto/usedMaterial';
import { Check } from 'components/Check/Check';
import { GuideCheckMenu } from './GuideCheckMenu/GuideCheckMenu';

interface Props {
  onClick?: () => void;
  checked?: boolean;
  status?: UsedMaterialStatus;
  setStatus: (status: UsedMaterialStatus) => void;
  groupMaterialId?: string;
  onAddLotNumber: () => void;
}

export const GuideCheck: React.FC<Props> = ({ onClick, checked = false, status, setStatus, groupMaterialId, onAddLotNumber }) => {
  return (
    <div className="guide-check">
      {status && status !== UsedMaterialStatus.Prepared ? <></> : <Check onClick={onClick} empty={!!groupMaterialId} checked={checked} />}

      <GuideCheckMenu status={status} onSetStatus={setStatus} groupMaterialId={groupMaterialId} onAddLotNumber={onAddLotNumber} />
    </div>
  );
};
