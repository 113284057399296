import {
  MaterialChapterDTO,
  CreateMaterialChapterDTO,
  UpdateMaterialChapterDTO,
  UpdateSortMaterialChaptersDTO,
  GetMaterialChapterDTO
} from 'dto/materialChapter';
import { get, post, patch, put, del } from './common';
import { validateArray, validate, validateBool } from './validation';

export const getMaterialChapters = async (getMaterialChaptersOptions: GetMaterialChapterDTO) => {
  const materialChapters = await get('/material-chapters', getMaterialChaptersOptions).then(response => response.data);
  return validateArray(MaterialChapterDTO, materialChapters);
};

export const createMaterialChapter = async (getMaterialChaptersOptions: CreateMaterialChapterDTO) => {
  const materialChapter = await post('/material-chapter', getMaterialChaptersOptions).then(response => response.data);
  return validate(MaterialChapterDTO, materialChapter);
};

export const updateMaterialChapter = async (getMaterialChaptersOptions: UpdateMaterialChapterDTO) => {
  const materialChapter = await patch('/material-chapter', getMaterialChaptersOptions).then(response => response.data);
  return validate(MaterialChapterDTO, materialChapter);
};

export const reorderMaterialChapters = async (reorderedMaterialChapters: UpdateSortMaterialChaptersDTO) => {
  return put('/material-chapter/positions', reorderedMaterialChapters).then(response => validateBool(response.data));
};

export const deleteMaterialChapter = async (materialChapterId: string) => {
  return del('/guides/material', { materialChapterId }).then(response => validateBool(response.data));
};
