/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsString, IsUUID, ValidateNested, IsNotEmptyObject } from 'class-validator';
import 'reflect-metadata';
import { Type } from 'class-transformer';
import { UserDTO } from './user';
import { MaterialLikeIdOrPackageIdDTO } from './material';

export class SynonymDTO extends MaterialLikeIdOrPackageIdDTO {
  @IsUUID()
  id!: string;

  @IsString()
  name!: string;

  @IsUUID()
  userId!: string;

  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => UserDTO)
  user!: UserDTO;
}

export class CreateSynonymDTO extends MaterialLikeIdOrPackageIdDTO {
  @IsString()
  name!: string;
}

export class GetMaterialSynonymDTO extends MaterialLikeIdOrPackageIdDTO {}

export class UpdateMaterialSynonymDTO {
  @IsUUID()
  synonymId!: string;

  @IsString()
  newName!: string;
}

export class DeleteMaterialSynonymDTO {
  @IsUUID()
  synonymId!: string;
}
