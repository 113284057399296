/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsUUID, IsString, IsOptional, ValidateNested, IsInt, IsNumber } from 'class-validator';
import 'reflect-metadata';
import { Type } from 'class-transformer';
import { GroupMaterialDTO } from './groupMaterial';
import { TagDTO } from './tag';
import { OptionalPictureDTO } from './file';
import { PagingCountDTO } from './generic';

export class SetGroupIdDTO {
  @IsUUID()
  setGroupId!: string;
}

export class SimpleSetGroupDTO extends SetGroupIdDTO {
  @IsString()
  name!: string;
}

export class SetGroupWithTagsDTO extends OptionalPictureDTO {
  @IsUUID()
  setGroupId!: string;

  @IsString()
  name!: string;

  @ValidateNested({ each: true })
  @Type(() => TagDTO)
  tags!: TagDTO[];

  @IsNumber()
  itemCount!: number;
}

export class SetGroupsWithTagsDTO extends PagingCountDTO {
  @ValidateNested({ each: true })
  @Type(() => SetGroupWithTagsDTO)
  setGroups!: SetGroupWithTagsDTO[];
}

/**
 * includes also the materials
 */
export class FullSetGroupDTO extends SetGroupWithTagsDTO {
  @ValidateNested({ each: true })
  @Type(() => GroupMaterialDTO)
  groupMaterials!: GroupMaterialDTO[];

  @IsOptional()
  @IsUUID()
  materialSetId?: string;
}

export class CreateSetGroupDTO {
  @IsString()
  name!: string;

  @IsUUID()
  materialSetId!: string;

  @IsInt()
  position!: number;

  @IsUUID()
  @IsOptional()
  pictureFileId?: string;
}

export class UpdateSetGroupDTO {
  @IsUUID()
  setGroupId!: string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsUUID()
  @IsOptional()
  pictureFileId?: string;
}

export class UpdateSortSetGroupsDTO {
  @IsUUID(undefined, { each: true })
  setGroupIds!: string[];
}
