/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import 'reflect-metadata';
import { IsUUID, ValidateNested, IsNotEmptyObject, IsBoolean, IsOptional } from 'class-validator';
import { Type } from 'class-transformer';
import { SurgeryDTO } from './surgery';
import { SurgeryGuideDTO } from './surgeryGuide';

export class GetBriefingDTO {
  @IsUUID()
  @IsOptional()
  briefingId?: string;

  @IsUUID()
  @IsOptional()
  functionalAreaId?: string;

  @IsUUID()
  @IsOptional()
  surgeryId?: string;

  @IsBoolean()
  includeStatistic!: boolean;
}

export class BriefingDTO {
  @IsUUID()
  briefingId!: string;

  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => SurgeryDTO)
  surgery!: SurgeryDTO;

  @ValidateNested({ each: true })
  @Type(() => SurgeryGuideDTO)
  surgeryGuides!: SurgeryGuideDTO[];
}
