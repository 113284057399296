import React from 'react';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import Video from 'components/Video/Video';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';

interface Props extends OptionalLazyLoadProps {
  file: ContentElementMediaDTO;
  inDrawer?: boolean;
  viewOnly?: boolean;
}

export default ({ file, inDrawer, viewOnly = false, lazyLoadScrollContainer }: Props) => {
  if (inDrawer) {
    return (
      <div className="image_post_wide in_board">
        <Video lazyLoadScrollContainer={lazyLoadScrollContainer} file={file} viewOnly={viewOnly} />
      </div>
    );
  }

  return (
    <div className="post_image">
      <div className="image_post_wide">
        <Video lazyLoadScrollContainer={lazyLoadScrollContainer} file={file} viewOnly={viewOnly} />
      </div>
      <div className="post_image_info">
        <div className="txt_post_image">{file.title}</div>
      </div>
    </div>
  );
};
