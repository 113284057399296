import React from 'react';

interface Props {
  hover?: boolean;
}

export default (props: Props) => {
  const arrowStyle = !props.hover ? { filter: 'saturate(0%) brightness(167%)' } : { filter: 'saturate(100%) brightness(100%)' };

  return <img src="images/icon_arrow_normal.svg" style={arrowStyle} alt="" className="image_arrow_list" />;
};
