import { ContentType } from 'dto/file';

export const getFileName = (name: string, type: string) => {
  if (type.includes('image') || type.includes('video')) return undefined;
  return name.replace(/\.[^/.]+$/, '');
};

export const getFileExtension = (name: string) => {
  const splitName = name.split('.');
  if (splitName.length === 1) {
    return '';
  }

  return splitName[splitName.length - 1];
};

// IMPORTANT: always keep in syn with backend (utils/index.ts)
export const allowedFileEndings: Map<ContentType, string[]> = new Map([
  [ContentType.Picture, ['png', 'gif', 'jpeg', 'jpg']],
  [ContentType.Video, ['avi', 'webm', 'mpg', 'mp4', 'mov', '3gpp', '3gp', 'wmv', 'qt', 'm3u8', 'mkv']],
  [ContentType.WordDocument, ['doc', 'docx', 'dot', 'dotx', 'dotm', 'docb']],
  [ContentType.ExcelDocument, ['xls', 'xlsx', 'xlsm', 'xlt', 'xltx', 'xltm', 'csv', 'tsv', 'psv']],
  [ContentType.PowerPointDocument, ['pptx', 'pptm', 'ppt']],
  [ContentType.AccessDocument, ['accdb', 'accde', 'accdt', 'accdr']],
  [ContentType.PDFDocument, ['pdf']]
]);

export function getMediaType(ext: string): ContentType {
  const extLow = ext.toLowerCase();
  let result = ContentType.Unknown;

  allowedFileEndings.forEach((endings, type) => {
    if (endings.indexOf(extLow) !== -1) {
      result = type;
    }
  });

  return result;
}
